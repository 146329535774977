import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from 'react-redux'
import PlacesAutoComplete from "../../components/AddressAutoComplete";
import * as Registration from "../../store/ducks/registration.duck";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useMediaQuery } from '@material-ui/core';
import { toAbsoluteUrl } from "../../../_metronic";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline';
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../_metronic/_assets/css/app2.css';

export default function PsychologistInvitationPage() {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');
  const { programid } = useParams()

  const dispatch = useDispatch()

  const psychologistInvite = {
    firstname: '', 
    surname: '',
    providernumber : '',
    email: '', 
    phone: '',
    education: '',
    locality: '',
    latitude: '',
    longitude: '',
    postcode: '',
    comments: '',
    aapimemberno: '',
    providertype: 'Psychologist'
  }

  const [status, setStatus] = useState('Not Started');
  // const [postData, setPostData] = useState();
  // const [showTerms, setShowTerms] = useState(false);

  // Sets size of pdf to pass to viewer
  // const [numPages, setNumPages] = useState(null);  
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  // when the invite is completed, show the confirmation message
  if(status === 'Submitted') {
    return <PsychologistConfirmation />
  }
  // otherwise, load the client registration form and all its hooplah...
  else 
  {

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }
  
  function submitForm(data){
    dispatch(Registration.actions.postPsychologistInvite({ ...data, invitetoken: uuidv4(), specialisttype : programid }))
    setStatus('Submitted')
  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("Surname is required"),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    phone:  Yup.string()
    .min(2,"Too Short")
    .max(12,"Too Long")
    .required("Phone Number is required"),
    providernumber:  Yup.string()
    .min(4,"Too Short")
    .max(50,"Too Long")
    .required("Provider Number is required"),
    locality:  Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .required("Town/Locality is required"),    
  });

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                padding: 20,
                maxWidth: 320,
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/auth/login" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-3.png")}
                    style={{ marginLeft: -10 }}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                  <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                    Welcome to the Provider Registration page
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2024 Rural Health Connect
                  </div>
                  <div className="kt-login__menu">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
              
              <Formik
                enableReinitialize={true}
                initialValues={psychologistInvite}
                validationSchema = {validationSchema}
                onSubmit={(values, actions) => {
                  submitForm(values);
                  actions.setSubmitting(false);
                }}
              >
                {props => {
                  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = props;
                  const change = (name,e) => { e.persist();  handleChange(e); };
                  return (
                    <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >

            <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20, maxWidth: 800 }}>
              <Row>
                <Col md="12">
                    {programid === 'nthqldphn' ?
                      <Alert variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7" }}>
                        <InfoOutlinedIcon fontSize="large" style={{ color: "#7FA3A5", marginLeft: -10, marginRight: 15 }} /> Welcome to the North Queensland PHN Program, <br />Please complete the Registration of Interest Form below. 
                      </Alert>
                    :
                      null
                    }
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid item md={9} xs={12}>
                      <h2>Provider Registration of Interest Form</h2>
                    </Grid>
                    <Grid item md={3} xs={12} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                      * indicates required fields
                    </Grid>
                  </Grid>
                </Col>
              </Row>
              <hr style={{ marginTop: 4 }}></hr>
              <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Select your role</Form.Label>
                <Form.Control as="select" name="providertype" value={values.providertype} onChange={change.bind(null, "providertype")} >
                  <option value="Psychologist">Psychologist</option>
                  {/* <option value="Financial">Financial Counsellor</option> */}
                  <option value="Clinical">Clinical Psychologist</option>
                  <option value="GP">GP</option>
                  <option value="Provisional">Provisional Psychologist</option>
                  <option value="Social">Social Worker</option>
                  <option value="Nurse">Mental Health Nurse</option>
                  <option value="Occupational">Occupational Therapist</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
              <Row>
                <Col md="4">
                  <Form.Group >
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group >
                    <Form.Label>Surname *</Form.Label>
                    <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                    <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group >
                    <Form.Label>Provider Number *</Form.Label>
                    <Form.Control type="text" name="providernumber" value={values.providernumber} onChange={change.bind(null, "providernumber")}  autoComplete='new-password' isInvalid={touched.providernumber && !!errors.providernumber} isValid={touched.providernumber && !errors.providernumber}/>
                    <Form.Control.Feedback type="invalid">{errors.providernumber}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
                  <Row>
                    <Col md="8">
                      <Form.Group >
                        <Form.Label>Email *</Form.Label>
                        <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Phone Number *</Form.Label>
                        <Form.Control type="text" name="phone" value={values.phone} onChange={change.bind(null, "phone")} isInvalid={touched.phone && !!errors.phone} isValid={touched.phone && !errors.phone} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>                
                  </Row>
                  <Row>
                    <Col md="8">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>Town/Locality (start typing to search) *</Form.Label>
                        <PlacesAutoComplete 
                          currentID={0}
                          currentfulladdress={values.locality}
                          setcurrentfulladdress = {(value) => setFieldValue("locality", value)}
                          setcurrentLatitude = {(value) => setFieldValue("latitude", value)} 
                          setcurrentLongitude = {(value) => setFieldValue("longitude", value)}
                          setcurrentPostCode = {(value) => setFieldValue("postcode", value)}
                          isInvalid={touched.locality && !!errors.locality} 
                          isValid={touched.locality && !errors.locality} 
                        />
                        {touched.locality && !!errors.locality ? (<p className='error-help'>{errors.locality}</p>) : ''}
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Education</Form.Label>
                        <Form.Control type="text" name="education" value={values.education} onChange={change.bind(null, "education")} autoComplete='new-password' />
                      </Form.Group>
                    </Col> 
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Comments/Questions</Form.Label>
                        <Form.Control as="textarea" rows={3} type="text" name="comments" value={values.comments}  onChange={change.bind(null, "comments")} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row>
                  {programid !== 'nthqldphn' ?
                    <Alert variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7" }}>
                      <InfoOutlinedIcon fontSize="large" style={{ color: "#7FA3A5", marginLeft: -10, marginRight: 15 }} /> Rural Health Connect is a proud partner of the Australian Association of Psychologists Inc and provides a discount to members. 
                    </Alert>
                  : 
                    null
                  }
                  {/* <Row>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>AAPI Membership No</Form.Label>
                        <Form.Control type="text" name="aapimemberno" value={values.aapimemberno} onChange={change.bind(null, "aapimemberno")} autoComplete='new-password' />
                      </Form.Group>
                    </Col> 
                  </Row> */}
                  <Row>
                    <Col md="12">
                      <Grid container direction="row" justifyContent="space-between"> 
                        <Grid item md={4} xs={8} style={{ fontWeight: 500 }}>
                        {programid === 'nthqldphn' ?
                          <a href="https://files.ruralhealthconnect.com.au/fileStoreRHC/RuralHealthConnect-NQPHNServicesAgreement.pdf" target="_Invitations" >
                              Read our Licence Agreement Here
                          </a>
                        :
                          <a href="https://files.ruralhealthconnect.com.au/fileStoreRHC/RuralHealthConnect-ServicesAgreement-2022-02-14.pdf" target="_Invitations" >
                            Read our Licence Agreement Here
                          </a>
                        }
                        </Grid>
                        <Grid item md={2} xs={6} style={{ textAlign: "right"}}>
                          <Button variant="primary" type="submit">
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {programid === 'nthqldphn' ?
                        <Alert severity="info" variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginTop: 20 }}>
                          <HelpOutlinedIcon fontSize="large" style={{ color: "#7FA3A5", marginLeft: -10, marginRight: 15 }} />Please email &nbsp;<a href='info@ruralhealthconnect.com.au'>info@ruralhealthconnect.com.au</a>&nbsp; if you have any questions or issues submitting this form.. 
                        </Alert>
                      :
                        null
                      }
                      </Col>
                  </Row>
                </Paper>
            </form>
          )
          }}
        </Formik>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
}

function PsychologistConfirmation() {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');
  return (

    <>
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
            }}
          >
            <div className="kt-grid__item">
              <Link to="/" className="kt-login__logo">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-3.png")}
                  style={{ marginLeft: -10 }}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                  Welcome to the Provider Registration page
                </h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info">
                <div className="kt-login__copyright">
                  &copy; 2024 Rural Health Connect
                </div>
              </div>
            </div>
          </div>

          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
            
          <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
            <h3>
              Registration of Interest successful.
            </h3>
            <p style={{ marginTop: 30 }}>
              Thank you for submitting your information. We will be in touch with you soon regarding your registration.
            </p>
          </Paper>
          </div>
          </div>
        </div>
      </div>
    </>

  );
}

