import { put, takeLatest, all, select } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";
import isCached from "../../components/Cache";

export const BASE_URL = process.env.REACT_APP_APIIP + "/reference";

const cache = (state) => state.admin.cache;

export const actionTypes = {
  // Init actiontypes to build reference data 
  SET_INITIAL: "SET_INITIAL",
  SET_CONSTANTS: "SET_CONSTANTS",
  FETCH_CONSTANTS: "FETCH_CONSTANTS",
  SET_TIMEZONE: "SET_TIMEZONE",
  FETCH_TIMEZONE: 'FETCH_TIMEZONE',
  SET_AREASOFINTEREST : "SET_AREASOFINTEREST",
  FETCH_AREASOFINTEREST : "FETCH_AREASOFINTEREST",
  FETCH_SUPERVISIONAREAS : "FETCH_SUPERVISIONAREAS",
  SET_SUPERVISIONAREAS : "SET_SUPERVISIONAREAS",
  SET_MBSSERVICES : "SET_MBSSERVICES",
  FETCH_MBSSERVICES : "FETCH_MBSSERVICES",
  SET_FUNDINGTYPES : "SET_FUNDINGTYPES",
  FETCH_FUNDINGTYPES : "FETCH_FUNDINGTYPES",
  FETCH_SUNDAYTHISWEEK : "FETCH_SUNDAYTHISWEEK",
  SET_SUNDAYTHISWEEK: "SET_SUNDAYTHISWEEK",
  SET_MEDICALPRACTICES : "SET_MEDICALPRACTICES",
  FETCH_MEDICALPRACTICES : "FETCH_MEDICALPRACTICES",
  SET_MEDICALPRACTITIONERS : "SET_MEDICALPRACTITIONERS",
  FETCH_MEDICALPRACTITIONERS : "FETCH_MEDICALPRACTITIONERS",
  FETCH_TAGS: "FETCH_TAGS",
  FETCH_CONTEXTS: "FETCH_CONTEXTS",
  PUT_TAG: "PUT_TAG",
  FETCH_USERS: "FETCH_USERS",
  PUT_USER: "PUT_USER",
  RUN_ETL: "RUN_ETL"
  
};

const initialState = {
  Contexts: [],
  Tags: [],
  TimeZone: [],
  AreasOfInterest: [],
  SupervisionAreas: [],
  MBSServices: [],
  Constants: [],
  FundingTypes: [],
  SundayThisWeek: '',
  Users: [],
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_LOCAL_REFERENCE': {
      return {
        ...state,
        [action.payload.referenceName]: action.payload.grid, 
      };
    }
    case 'SET_SUNDAYTHISWEEK': {
      // console.log(action.payload);
      return {
        ...state,
        SundayThisWeek : action.payload, 
      };
    }
    case 'SET_TIMEZONE': {
      // console.log(action.payload);
      return {
        ...state,
        TimeZone : action.payload, 
      };
    }
    case 'SET_AREASOFINTEREST': {
      // console.log(action.payload);
      return {
        ...state,
        AreasOfInterest: action.payload, 
      };
    }
    case 'SET_SUPERVISIONAREAS': {
      // console.log(action.payload);
      return {
        ...state,
        SupervisionAreas: action.payload, 
      };
    }
    case 'SET_MBSSERVICES': {
      // console.log(action.payload);
      return {
        ...state,
        MBSServices: action.payload, 
      };
    }
    case 'SET_FUNDINGTYPES': {
      // console.log(action.payload);
      return {
        ...state,
        FundingTypes: action.payload, 
      };
    }
    case 'SET_CONSTANTS': {
      // console.log(action.payload);
      return {
        ...state,
        Constants: action.payload, 
      };
    }
    case 'SET_INITIAL': {
      return initialState;
    }
    default:
      return state;
  }
}

// Fetch's Below 

export function* fetchTimeZone(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/timezone";
      const response = yield axios.get(newURL);
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchTimeZone (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_TIMEZONE', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchTimeZone (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
      }
    } 
    catch (error) 
    {
      // Report Error 
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchTimeZone (E3)', ErrorType : 'REDUX Function', Message : error.message } });
    }
  }
}

export function* fetchAreasOfInterest(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/areasofinterest/" + action.payload;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(`${action.type}: ${newURL}`)
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response.data);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("Areas of Interest Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchAreasOfInterest (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_AREASOFINTEREST', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("Areas of Interest Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchAreasOfInterest (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("Areas of Interest Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchAreasOfInterest (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchSupervisionAreas(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/supervisionareas";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(`${action.type}: ${newURL}`)
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response.data);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("Areas of Interest Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSupervisionAreas (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_SUPERVISIONAREAS', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("Areas of Interest Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSupervisionAreas (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("Areas of Interest Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSupervisionAreas (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchMBSServices(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/mbsservices";
      const response = yield axios.get(newURL);
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("MBS Services Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchMBSServices (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_MBSSERVICES', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("MBS Services Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchMBSServices (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("MBS Services Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchMBSServices (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchConstants(action){
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/constants";
      const response = yield axios.get(newURL);
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("Constants Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchConstants (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          var object = retData.data.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {} );
          // console.log(object);
          yield put({ type: 'SET_CONSTANTS', payload: object});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("Constants Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchConstants (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("Constants Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchConstants (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchFundingTypes(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/fundingtypes";
      const response = yield axios.get(newURL);
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("Funding Types Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchFundingTypes (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_FUNDINGTYPES', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("Funding Types Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchFundingTypes (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("Funding Types Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchFundingTypes (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchSundayThisWeek(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/sundayThisWeek";
      const response = yield axios.get(newURL);
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log(response);
      }
      // Check response
      if ((response.status) && (response.status === 200))
      {
        const retData = response;
        if (retData.returnStatus === 'Error') 
        {
          // Report Error
          // Not reporting failed fetch's information to user - at this stage 
          // toast.error("Funding Types Load Failed (E1)");
          yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSundayThisWeek (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
          // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText);
        }
        else
        {
          // Report Success and gather data 
          yield put({ type: 'SET_SUNDAYTHISWEEK', payload: retData.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
      }
      else 
      {
        // Report Error
        const retData = response;
        // Not reporting failed fetch's information to user - at this stage 
        // toast.error("Funding Types Load Failed (E2)");
        yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSundayThisWeek (E1)', ErrorType : 'REDUX Function', Message : retData.data.returnText } });
        // console.log('error : Warning Data Cannot be Loaded (Code - ' & response.statusText );
      }
    } 
    catch (error) 
    {
      // Report Error 
      // Not reporting failed fetch's information to user - at this stage 
      // toast.error("Funding Types Load Failed (E3)");
      yield put({ type: 'PUT_ERROR', payload: {Request: 'fetchSundayThisWeek (E3)', ErrorType : 'REDUX Function', Message : error.message } });
      // console.log('error: Warning Cannot load Reference ' & referenceName & ' (Code - SVR)');
    }
  }
}

export function* fetchContexts(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    // Note - these are also setup as an enum list in C# in the API - and also in the Contexts table
    const contexts = [
      { ContextID: 1, Context: 'Fax' }, 
      { ContextID: 2, Context: 'Psychologist' }, 
      { ContextID: 3, Context: 'GP' }, 
      { ContextID: 4, Context: 'Financial_Counsellor' }, 
      { ContextID: 5, Context: 'Client' }, 
      { ContextID: 6, Context: 'Booking' }, 
      { ContextID: 7, Context: 'Invoice' }, 
      { ContextID: 8, Context: 'Client_Registration' }, 
    ]
    yield put({ type: 'SET_LOCAL_REFERENCE', payload: {referenceName: 'Contexts', grid: contexts } });
    yield put({ type: 'SET_CACHE', payload: { caller: action }});
  }
}

export function* fetchUsers(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/adminusers/"
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
        process.env.REACT_APP_DEBUG && console.log(response.data);
      yield put({ type: 'SET_LOCAL_REFERENCE', payload: {referenceName: 'Users', grid: response.data } });
      yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        process.env.REACT_APP_DEBUG && console.log(response);
        toast.error(`Failed: ${action.type}`);
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }  
    } 
    catch (error) // API call itself has errored out
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message, actionPayload: action.payload ? action.payload : 'No payload' } });
    }
  }
}

export function* runETL(action) {
  try 
  {
    const newURL = BASE_URL + "/runetl/"
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.get(newURL);  
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      process.env.REACT_APP_DEBUG && console.log(response.data);
      toast.success("ETL Update Started",{ autoClose:5000 });

    }
    else // API completed with 200, however there is an error message
    {
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.error('ETL Update Not Started');
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error('ETL Update Not Started');
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message, actionPayload: action.payload ? action.payload : 'No payload' } });
  }
}

export function* putUser(action) {
  try 
  {
    const newURL = BASE_URL + "/adminuser/"
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload);  
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      process.env.REACT_APP_DEBUG && console.log(response.data);
      yield put({ type: 'FETCH_USERS', payload: { popCache: true } });
    }
    else // API completed with 200, however there is an error message
    {
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message, actionPayload: action.payload ? action.payload : 'No payload' } });
  }
}

export function* fetchTags(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/tags/"
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
        process.env.REACT_APP_DEBUG && console.log(response.data);
        yield put({ type: 'SET_LOCAL_REFERENCE', payload: {referenceName: 'Tags', grid: response.data } });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        process.env.REACT_APP_DEBUG && console.log(response);
        toast.error(`Failed: ${action.type}`);
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }  
    } 
    catch (error) // API call itself has errored out
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* putTag(action) {
  try 
  {
    const newURL = BASE_URL + "/tag/"
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload);  
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      process.env.REACT_APP_DEBUG && console.log(response.data);
      yield put({ type: 'FETCH_TAGS', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }  
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export const actions = {
  fetchTimeZone:                  (payload) => ({ type: actionTypes.FETCH_TIMEZONE, payload : payload }),
  fetchAreasOfInterest:           (payload) => ({ type: actionTypes.FETCH_AREASOFINTEREST, payload : payload }),
  fetchSupervisionAreas:          (payload) => ({ type: actionTypes.FETCH_SUPERVISIONAREAS, payload : payload }),
  fetchMBSServices:               (payload) => ({ type: actionTypes.FETCH_MBSSERVICES, payload : payload }),
  fetchConstants:                 (payload) => ({ type: actionTypes.FETCH_CONSTANTS, payload : payload }),
  fetchFundingTypes:              (payload) => ({ type: actionTypes.FETCH_FUNDINGTYPES, payload : payload }),
  fetchSundayThisWeek:            (payload) => ({ type: actionTypes.FETCH_SUNDAYTHISWEEK, payload : payload }),
  fetchTags:                      (payload) => ({ type: actionTypes.FETCH_TAGS, payload : payload }),
  fetchContexts:                  (payload) => ({ type: actionTypes.FETCH_CONTEXTS, payload : payload }),
  putTag:                         (payload) => ({ type: actionTypes.PUT_TAG, payload: payload }),
  fetchUsers:                     (payload) => ({ type: actionTypes.FETCH_USERS, payload : payload }),
  putUser:                        (payload) => ({ type: actionTypes.PUT_USER, payload: payload }),
  runETL:                         (payload) => ({ type: actionTypes.RUN_ETL, payload : payload }),
};

function* actionWatcher() {
  yield takeLatest('FETCH_TIMEZONE',fetchTimeZone);
  yield takeLatest('FETCH_AREASOFINTEREST',fetchAreasOfInterest);
  yield takeLatest('FETCH_SUPERVISIONAREAS',fetchSupervisionAreas);
  yield takeLatest('FETCH_MBSSERVICES',fetchMBSServices);
  yield takeLatest('FETCH_CONSTANTS',fetchConstants);
  yield takeLatest('FETCH_FUNDINGTYPES',fetchFundingTypes);
  yield takeLatest('FETCH_SUNDAYTHISWEEK',fetchSundayThisWeek);
  yield takeLatest('FETCH_TAGS',fetchTags)
  yield takeLatest('FETCH_CONTEXTS',fetchContexts)
  yield takeLatest('PUT_TAG',putTag);
  yield takeLatest('FETCH_USERS', fetchUsers);
  yield takeLatest('PUT_USER', putUser);
  yield takeLatest('RUN_ETL', runETL);
}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 
}


