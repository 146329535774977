import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Reference from "../../../store/ducks/reference.duck";
import * as Auth from "../../../store/ducks/auth.duck";
import { Formik } from 'formik';
import * as Yup from "yup";
import { ToastContainer, Flip } from "react-toastify";
import { DataGrid } from '@material-ui/data-grid';
import { Paper, Grid } from '@material-ui/core';
import { FormControl, FormControlLabel, Checkbox, FormGroup, Select, MenuItem, makeStyles } from '@material-ui/core';
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { PopoverPicker } from "../../../components/PopoverPicker";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';
import { format } from "date-fns";

export default function MetaDataConfig() {
  
  const filters = useSelector(state => state.admin.Filters)
  const contexts = useSelector(state => state.reference.Contexts)
  const tags = useSelector(state => state.reference.Tags)

  const dispatch = useDispatch() 
  useEffect( () => { (contexts && contexts.length < 1) && dispatch(Reference.actions.fetchContexts()) }, [dispatch, contexts] )
  useEffect( () => { (tags && tags.length < 1) && dispatch(Reference.actions.fetchTags()) }, [dispatch, tags] )

  var [show, setShow] = useState(false);

  const initTag = () => {
    return {      
      tagID: 0,
      tagName: '',
      description: '',
      contexts: [],
      dataType: 'List',
      allowMultipleValues: false,
      allowAdHocValues: false,
      colour: '',
      updatedOn: new Date(),
      options: []
    }
  }

  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
   }
   return result.join('');
  }

  const tagDataTypes = ['List','General']
  // const tagDisplayLists = ['','Clients - 1','Clients - 2','Clients - 3','Psychologists']

  const [tag, setTag] = useState(initTag())
  const [items, setItems] = useState();
  const [newTagOption, setNewTagOption] = useState(false);
  const newTagValueRef = useRef(null);

  // set focus to new tag value on tag selection
  useEffect(() => {
    if (newTagOption) {
      newTagValueRef.current.focus();
      // console.log('focus me')
      // console.log(newTagOption)
    }
  }, [newTagOption]);

  const loadTag = (thisTag) => {
    setTag({ ...thisTag });
    setItems(thisTag.options);
    setColour(thisTag.colour);
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  function handleContextChange(refContext, checked) {
    var tempContexts = []
    if(checked)
    {
      tempContexts = [ ...tag.contexts ];
      tempContexts.push({ id: 0, contextID: refContext.ContextID });
      setTag({ ...tag, contexts: tempContexts });
    }
    else
    {      
      tempContexts = tag.contexts.filter((row) => row.contextID !== refContext.ContextID);
      setTag({ ...tag, contexts: tempContexts });
    }
  }

  // create a new item and add it to the items array
  function addTagOption(value) {
    if(value.length > 0)
    {
      var newItem = { id: makeid(6), tagValue: value }
      var tempItems = [ ...items ];
      tempItems.push(newItem);
      setItems(tempItems);
      setNewTagOption(true);
      // console.log('set focas on...')
    }
  }

  // merge in new item into items array
  function updateTagOption(id, value) {
    var tempItems = [ ...items ]
    tempItems.forEach(function(obj) {
      if (obj.id.toString() === id.toString()) {
          obj.tagValue = value;
      }
     });
     setItems(tempItems);
  }

  function deleteTagOption(deleteid) {
    var tempItems = items.filter((row) => row.id !== deleteid);
    setItems(tempItems);
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true},
    { field: 'tagName', headerName: 'Tag Name', width: 280,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadTag(tags.find(row => row.id === params.row.id))} className="cell-link">
          {params.row.tagName}
        </div>
      </strong>
    ), },
    { field: 'dataType', headerName: 'Type', width: 150 },
    { field: 'tagCount',  headerName: 'Used', width: 100 },
    { field: 'updatedOn', headerName: 'Updated', width: 130, valueFormatter: ({ value }) => format(new Date(value), "dd-MM-yyyy HH:mm") }
  ];

  const validationSchema = Yup.object().shape({
    tagName:  Yup.string()
    .min(4,"Too Short")
    .max(160,"Too Long")
    .required("Template Name is required")
  });

  function AddOption() {
    var [newOptionText, setNewOptionText] = useState('') 
    return (  
      <Form.Group style={{ marginTop: 10 }}>
        <Form.Label>Add Option:</Form.Label>              
        <div className="checklist-item">                                          
          <InputGroup size="sm">
            <Form.Control 
              ref={newTagValueRef} 
              style={{ height: 40 }} 
              type="text" 
              id="newTagOption" 
              name="newTagOption" 
              value={newOptionText} 
              onChange={(event) => setNewOptionText(event.target.value)} 
              onBlur={(event) => addTagOption(event.target.value)} autoComplete='new-password'/>
          </InputGroup>
        </div>
      </Form.Group>
    )
  }

  function EditOption(props) {
    var [item, setItem] = useState(props.item) 
    return (  
      <InputGroup size="sm" key={`edit_option_input_key_${props.item.id}`}>
        <Form.Control 
          style={{ height: 40, marginBottom: 5 }} 
          key={`edit_option_key_${props.item.id}`}
          type="text" 
          id={item.id} 
          name="tagOption" 
          value={item.tagValue} 
          onChange={(event) => setItem({ ...item, tagValue: event.target.value })}  
          onBlur={(event) => updateTagOption(event.target.id, event.target.value)}  
          autoComplete='new-password'/> 
        <InputGroup.Append
          style={{ height: 40, marginBottom: 5 }} >
          <InputGroup.Text onClick={() => deleteTagOption(item.id)}>
            <DeleteOutlineIcon />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    )
  }

  // general function to capture Enter key press (to align it with tab/onBlur) - this will prevent the form being submitted and run relevant update/save methods instead
  function onKeyDown(event) {
    if ((event.charCode || event.keyCode) === 13) {

      // new tag option
      if(event.target.name === 'newTagOption')
      {
        addTagOption(event.target.value)
      }
      
      // existing tag option
      if(event.target.name === 'tagOption')
      {
        updateTagOption(event.target.id, event.target.value)
      }

      event.preventDefault();
    }
  }

  const submitForm = (values) => {
    setShow(false);
    dispatch(Reference.actions.putTag({ ...values, colour: colour, contexts: tag.contexts, options: items }))
  }

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    formControl: {
      margin: theme.spacing(2)
    },
    formControlNoTopMargin: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(2)
    },
    formControlLabelNoBottomMargin: {
      marginBottom: theme.spacing(0),
    },
    myCheckbox: {
      marginRight: 12
    }
  }));

  const classes = useStyles();

  const [colour, setColour] = useState('#ffffff');

  return (
    <div className='container-fluid'>
      <h4>
          Meta Data Tags
      </h4>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
              <Grid container>
                <Grid item>             
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control                  
                        type="text"
                        value={filters.tagsTerm}
                        onChange={(event) => dispatch(Auth.actions.setFilters({ filterName: 'tagsTerm', filterValue: event.target.value }))}
                        autoComplete='OFF'
                        style={{ maxWidth: 170, height: 36, backgroundColor: filters.tagsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                      />
                    <InputGroup.Append onClick={() => dispatch(Auth.actions.setFilters({ filterName: 'tagsTerm', filterValue: '' }))}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: filters.tagsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Grid>
              
              </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadTag(initTag())}>
              Add Tag
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 800 }}>
        <DataGrid rows={tags} columns={columns} checkboxSelection={false} rowHeight={38} pageSize={15} />
        {/* sortModel={[{ field: 'tagName', sort: 'asc' }]}  */}
      </Paper>

      <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal show={show} onHide={handleClose} dialogClassName="edit-tags-modal">
        <Modal.Header closeButton className="edit-tags-modal">
          <Modal.Title>Edit Tag</Modal.Title>
        </Modal.Header>         
        <Formik
          enableReinitialize={false}
          initialValues={tag}
          validationSchema = {validationSchema}
          onSubmit={(values, actions) => {
            submitForm(values);
            // actions.setSubmitting(false);
          }}
        >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = props; 
          const change = (name,e) => { e.persist();  handleChange(e); };
          return (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} onKeyDown={onKeyDown}> 
            <Modal.Body className="edit-tags-modal">                
              <Row>
                <Col md="4">
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Tag Name &amp; Colour</Form.Label>
                        <InputGroup>
                          <Form.Control type="text" id="tagName" name="tagName" value={values.tagName} onChange={change.bind(null, "tagName")} autoComplete='new-password' isInvalid={touched.tagName && !!errors.tagName} isValid={touched.tagName && !errors.tagName}/>
                          <Form.Control.Feedback type="invalid">{errors.tagName}</Form.Control.Feedback>
                          <InputGroup.Append style={{ cursor: 'pointer' }}>
                            <InputGroup.Text style={{ backgroundColor: colour ? colour : '#ffffff', padding: 0 }}>
                              {/* <PaletteOutlinedIcon /> */}
                              <PopoverPicker color={colour ? colour : '#ffffff'} onChange={setColour} />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label>Tag Context</Form.Label>
                        <br></br>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                        <FormGroup>
                          {contexts.map((refContext, index) => {
                            return (
                              <FormControlLabel 
                                key={`key_cl_${index}`}
                                className={classes.formControlLabelNoBottomMargin}                                
                                control={
                                  <Checkbox
                                    key={`key_${index}`}
                                    name="tagContexts"
                                    checked={tag.contexts.some(row => row.contextID === refContext.ContextID)}
                                    onChange={(event) => handleContextChange(refContext, event.target.checked)}
                                    value={true}
                                    className={classes.myCheckbox}
                                  />
                                }
                                label={refContext.Context}
                              />
                            )
                          })}
                        </FormGroup>
                        </FormControl>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Data Type</Form.Label>
                        <br></br>
                        <FormControl style={{ minWidth: 130 }}>
                          <Select
                              id="dataType"
                              name="dataType"
                              variant="outlined"
                              margin="dense"
                              className="select-wide"
                              value={values.dataType}
                              onChange={(event) => setFieldValue('dataType', event.target.value)}
                              readOnly={false}
                              >
                              {tagDataTypes.map((row, index) => {
                                  return (<MenuItem key={`type_key_${index}`} value={row} >{row}</MenuItem>)
                                })
                              }
                          </Select>
                        </FormControl>
                      </Form.Group>
                    </Col>
                    <Col md="8">
                      <Form.Group>
                        <Form.Label>Data Entry</Form.Label>
                        <br></br>
                        <FormControl
                          component="fieldset"
                          className={classes.formControlNoTopMargin}
                        >
                        <FormControlLabel 
                            className={classes.formControlLabelNoBottomMargin} 
                            control={
                              <Checkbox
                              // key={value}
                              name="allowMultipleValues"
                              checked={values.allowMultipleValues}
                              onChange={(event) => setFieldValue('allowMultipleValues', !values.allowMultipleValues)}
                              value={true}
                              className={classes.myCheckbox}
                              />
                            }
                            label="Allow multiple selections"
                          />
                        </FormControl>
                      </Form.Group>
                    </Col>
                    {values.dataType === 'List' ?
                      <Col md="12">
                        <Form.Group>
                          <Form.Label>Tag Options</Form.Label>
                          <br></br>
                          {items.map((item) => {
                            return (
                              <EditOption key={`option_key_${item.id}`} item={item} />
                            )}
                          )}                        
                          <AddOption />
                        </Form.Group>
                      </Col>
                    : 
                      null
                    }
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="edit-tags-modal">
              <Grid container justifyContent="space-between">
                <Grid item>
                </Grid>
                <Grid item>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" style={{ marginLeft: 8 }}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Modal.Footer>
          </form>)
        }}
      </Formik>
    </Modal>
    </div>
  )

}
