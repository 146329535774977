import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Admin from "../../../store/ducks/admin.duck";
import { DataGrid } from '@material-ui/data-grid';
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Paper, Grid, TextField } from '@material-ui/core';
import { format } from "date-fns";


export default function InvoiceLog(props) {

  const dispatch = useDispatch()

  const invoicelog = useSelector(state =>  state.admin.InvoiceLog)
  const filters = useSelector(state => state.admin.Filters)
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists)
  const registeredWithClients = registered.filter(psychologist => psychologist.referralCount > 0)

  useEffect( () => { dispatch(Admin.actions.fetchInvoiceLog()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const searchInvoices = (invlog) => {
    if( true
        && (invlog.clientName.toLowerCase().search(filters.invoicelogTerm.toLowerCase()) > -1 || invlog.providerName.toLowerCase().search(filters.invoicelogTerm.toLowerCase()) > -1 || filters.invoicelogTerm.length === 0)
        && (!filters.invoicesPsychologist || (filters.invoicesPsychologist && invlog.psychologistid === filters.invoicesPsychologist.id))
      )
    {
      return invlog
    }  
  }

  const filteredInvoiceLog = invoicelog.filter(invlog => searchInvoices(invlog))

  var cols = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'invoiceDate', type: 'date', headerName: 'Date', flex: 1, renderCell: (params) => (format(new Date(params.value ? params.value : null), "dd-MM-yyyy"))},
    { field: 'providerName', headerName: 'Provider', flex: 2 }, 
    { field: 'clientName', headerName: 'Client', flex: 2 }, 
    { field: 'clientAddress', headerName: 'Address', flex: 2 }, 
    { field: 'psychologistDate', type: 'datetime', headerName: 'Booking Date', flex: 1, renderCell: (params) => (format(new Date(params.value ? params.value : null), "dd-MM-yyyy hh:mm aa"))},
    { field: 'total', type: 'currency',headerName: 'Amount', flex: 0.7 },
  ]

    return(
      <>
        <Paper> 
          <Grid container style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, paddingLeft: 2 }} md={3}>
              <InputGroup size="sm" >
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                  <Form.Control 
                    type="text"
                    value={filters.invoicelogTerm}
                    onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invoicelogTerm', filterValue: event.target.value }))}
                    autoComplete='OFF'
                    style={{ maxWidth: 170, height: 35, backgroundColor: filters.invoicelogTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                  />
                <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'invoicelogTerm', filterValue: '' }))}>
                  <InputGroup.Text>
                    <ClearIcon fontSize="small" style={{ color: filters.invoicelogTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Grid>
            <Grid item style={{ marginTop: 2, paddingLeft: 10 }} md={9}>
              <Grid container>
                <Grid item>
                  <Autocomplete
                  id="searchPsychologist"
                  variant="outlined"
                  options={registeredWithClients}
                  getOptionLabel={(option) => option.fullname}
                  value={filters.invoicesPsychologist}
                  onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invoicesPsychologist', filterValue: newValue }))}
                  style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Provider"
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="PsychologistSelector"
                      style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ height: 660 }}>
            <DataGrid 
              rows={filteredInvoiceLog} 
              columns={cols} 
              pageSize={10} 
              rowsPerPageOptions={[10]} 
              checkboxSelection={false} 
              sortModel={[{ field: 'invoiceDate', sort: 'asc' }]} />
        </Paper>
      </>
    )
  }

