import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as admin from "./ducks/admin.duck";
import * as reference from "./ducks/reference.duck";
import * as registration from "./ducks/registration.duck";
import * as forms from "./ducks/forms.duck";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  admin: admin.reducer,
  reference: reference.reducer,
  registration: registration.reducer,
  forms: forms.reducer
});

export function* rootSaga() {
  yield all([auth.saga(),admin.saga(),reference.saga(),registration.saga(),forms.saga()]);
}
