import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Files from "../../../components/Files";
import * as Admin from "../../../store/ducks/admin.duck";

export default function FilesClient() {

  const dispatch = useDispatch()
  const clients = useSelector(state => state.admin.Clients);
  const client = useSelector(state => state.admin.Client);
  
  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { clients.length && dispatch(Admin.actions.fetchClient( { clientID: clients[0].id, type: clients[0].clienttype } )) }, [dispatch, clients] )
  
  if(client.id) {
    return (
      <>
        <h4>Shared Files</h4>
        <Files type="Client" subtype={client.paymentType} pkid={client.id} />
      </>
    )
  }
  else
  {
    return <div>Loading...</div>
  }

}
