/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePageAdmin from "../pages/home/HomePageAdmin";
import HomePagePsychologist from "../pages/home/HomePagePsychologist";
import HomePageClient from "../pages/home/HomePageClient";
import HomePageReferrer from "../pages/home/HomePageReferrer";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import ClientRegistrationPage from "../pages/registration/ClientRegistrationPage";
import { EAPRegistrationPage } from "../pages/registration/ClientRegistrationPage";
import { NDISRegistrationPage } from "../pages/registration/ClientRegistrationPage";
import PsychologistRegistrationPage from "../pages/registration/PsychologistRegistrationPage";
import PsychologistInvitationPage from "../pages/registration/PsychologistInvitationPage";
import ReferrerInvitationPage from "../pages/registration/ReferrerInvitationPage";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '97cbd13c9ca1843df6f1a65216673c4bT1JERVI6NDM0MTAsRVhQSVJZPTE2ODM3ODI3NjYwMDAsS0VZVkVSU0lPTj0x',
);

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const pathname = useLocation().pathname;												// RHC-DASH-STYLE: add 'useLocation'
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, isTotp, roleDesc } = useSelector( 
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.authToken != null,
      isTotp: (auth.totp && auth.totp.length > 5 && auth.authToken === null) || false,
      roleDesc: auth.roleDesc === '' ? 'Basic' : auth.roleDesc,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );
 
  function roleCheckLayout() {
    // console.log('roleCheckLayout')
    // console.log(roleDesc)
    if (roleDesc === 'Admin') 
    {
      return (
        <Layout>
          <HomePageAdmin userLastLocation="/dashboard" />
        </Layout>
      )
    } 
    else if (roleDesc === 'Psychologist') 
    {
      const attr = {}																	// RHC-DASH-STYLE: Only apply custom styles to psychologist dashboard specifically.
      if(pathname === '/dashboard'){									// RHC-DASH-STYLE
        attr['customiserClass'] = 'custom-rhc'				// RHC-DASH-STYLE: add custom-rhc selector
      }																				        // RHC-DASH-STYLE
      return (
        <Layout {...attr} > 													{/* RHC-DASH-STYLE: inject optinoal attributes */}
          <HomePagePsychologist userLastLocation="/dashboard"  />
        </Layout>
      )
    } 
    else if (roleDesc === 'Referrer') 
    {
        return (
          <Layout>
            <HomePageReferrer userLastLocation="/dashboard" />
          </Layout>
        )
    } 
    else 
    {
      return (
        <Layout>
          <HomePageClient userLastLocation='/dashboard' />
        </Layout>
      )
    }
  }
  
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
          
        {/* Anonymous pages */}
        <Route path="/clientregistration/ndis" component={NDISRegistrationPage} />
        <Route path="/clientregistration/:invitetoken" component={ClientRegistrationPage} />
        <Route path="/providerregistration/:invitetoken" component={PsychologistRegistrationPage} />
        <Route path="/psychologistregistration/:invitetoken" component={PsychologistRegistrationPage} />
        <Route path="/eap" component={EAPRegistrationPage} />
        <Route path="/psychologistinvitation/:programid?" component={PsychologistInvitationPage} />
        <Route path="/referrerinvitation" component={ReferrerInvitationPage} />

        {isTotp ? (
            <Redirect from='/auth/login' to='/auth/totp' />
          ) : (
            null
          )
        }

        {!isAuthorized ? (
            // Render auth page when user at `/auth` and not authorized.
            <Route path="/auth" component={AuthPage} />
          ) : (
              <Redirect from="/auth" to='/dashboard' />
          )
        }

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? (
          /* Redirect to `/auth/login` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          // Otherwise load the appropriate homepage with routes composed for each role
          roleCheckLayout()          
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
