import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Paper, Grid, TextField, MenuItem, Select, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { ToastContainer, Flip } from "react-toastify";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';
import { format } from "date-fns";
import SendLetter from "../../../components/SendLetter";
import { saveAs } from 'file-saver';

// load worker thread for pdf operations
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));

export default function Faxes() {

  // UI state controls
  const [showFax, setShowFax] = useState(false)
  const [showGPLetter, setShowGPLetter] = useState(false)
  const [inviteError, setInviteError] = useState('')
  const [exportPDFStatus, setExportPDFStatus] = useState('Not Started')

  var [prev, setPrev] = useState(0)
  var [next, setNext] = useState(0)

  const userTZ = useSelector(state => state.auth.userTZ)
  const filters = useSelector(state => state.admin.Filters)

  // Init methods to create valid objects for passing to the API when posting
  const initFax = () => {
    return { id: 0, faxNumber: '', receivedOn: '' }
  }

  const initSource = useCallback(() => {
    return { id: 0, firstName: '', surname: '', phone: '', email: '', organisationname: '',description: '', status: 'Active' }
  },[])

  var [currentFax, setcurrentFax] = useState(initFax()); 
  var [currentLoadingFax, setcurrentLoadingFax] = useState();
  var [currentinviteid,setcurrentinviteid] = useState(0);
  var [currentFirstName,setcurrentFirstName] = useState();
  var [currentPreferredName,setcurrentPreferredName] = useState();
  var [currentSurname,setcurrentSurname] = useState();
  var [currentEmail,setcurrentEmail] = useState();
  var [currentMobile,setcurrentMobile] = useState();
  var [currentMessage, setcurrentMessage] = useState('')
  var [currentAreasOfInterest, setcurrentAreasOfInterest]  = useState([]);
  var [currentPsychologist, setcurrentPsychologist] = useState();
  var [currentTimeZone, setcurrentTimeZone] = useState(userTZ ? userTZ : 'AUS Eastern Standard Time');
  var [currentSlot, setcurrentSlot] = useState();
  var [currentAppointmentType, setcurrentAppointmentType] = useState('0');
  var [currentProviderType, setcurrentProviderType] = useState();


  // All state hooks required
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchFaxes()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClientInvites({})) }, [dispatch] )
  
  useEffect( () => { dispatch(Admin.actions.fetchMatching( { providertype:'any', invite : false, availabilitytype : 'New' })) }, [dispatch] )

  const faxes = useSelector(state =>  state.admin.Faxes)


  // const filteredFaxes = faxes.filter(fax => (fax.status === filters.faxesStatus || filters.faxesStatus === 'All' ) &&  (fax.clientname.toLowerCase().search(filters.faxTerm.toLowerCase()) > -1 || filters.faxTerm.length === 0));

  const searchFaxes = (fax) => {
    if( true
        && (fax.status === filters.faxesStatus || filters.faxesStatus === 'All' )
        && (fax.clientname.toLowerCase().search(filters.faxTerm.toLowerCase()) > -1 || fax.flagClean.toLowerCase().search(filters.faxTerm.toLowerCase()) > -1 || filters.faxTerm.length === 0)
      )
    {
      return fax
    }  
  }
  
  const filteredFaxes = faxes.filter(fax => searchFaxes(fax))

  const invites = useSelector(state =>  state.admin.ClientInvites);
  const psychologists = useSelector(state => state.admin.Matching)
  // Setup variables for Psychology and Financial Lists of Psychologists 
  const MatchingPsychologist = psychologists.filter(matching => (matching.providertype === 'Psychologist' || matching.providertype === 'Clinical' || matching.providertype === 'Occupational'))
  const MatchingFinancial = psychologists.filter(matching => matching.providertype === 'Financial')
  const MatchingGP = psychologists.filter(matching => matching.providertype === 'GP') 
  
  const faxPDF = useSelector(state => state.admin.FaxPDF)
  const areasOfInterest = useSelector(state => state.reference.AreasOfInterest)
  // Setup variables for Psychology and Financial Areas of Interest (note GP's do not have areas of interest)
  const PsychologyAreasOfInterest = areasOfInterest.filter(area => area.providertype === 'Psychologist')
  const FinancialAreasOfInterest = areasOfInterest.filter(area => area.providertype === 'Financial')
  
  const timeZone = useSelector(state => state.reference.TimeZone)
  const matchingAvailability = useSelector(state => state.admin.MatchingAvailability)
  
//   useEffect( () => { 
//     if (psychologists && psychologists.length > 0)
//     {
//       setcurrentPsychologist(psychologists[0])
//       console.log(psychologists[0])
//       setcurrentProviderType(psychologists[0].providertype)
//       // console.log('setting psychologist - done')
//     }
//  }, [psychologists] )


  // Paginaton controls
  const navFax = (index) => {
    loadFax(filteredFaxes[index])
  }

  // Load a fax from the list
  const loadFax = (thisFax) => {
    var index = filteredFaxes.indexOf(thisFax);
    setPrev(index > 0 ? index-1 : null);
    setNext(index < filteredFaxes.length -1 ? index+1 : null);

    setcurrentFax(thisFax)
    setcurrentLoadingFax(false);
    if(thisFax.faxBlobLength) {
      dispatch(Admin.actions.fetchFaxPDF(thisFax.id))
    }
    var tempInvite = invites.find(v => v.id === thisFax.clientregistrationid);
    loadInvite(tempInvite ? tempInvite : initInvite())
    setShowFax(true);
  }

  const saveFax = (thisFax) => {
    setcurrentFax(thisFax)
    // console.log('In Save Fax');
    if(exportPDFStatus === 'Not Started')
    {
      dispatch(Admin.actions.fetchFaxPDF(thisFax.id))
      setExportPDFStatus('Fetching')
    }

  }

  // grab the blob from state and tell the browser to download
  const downloadExport = useCallback(() => {
    // console.log('In Download Export');
    let exportDocName = '';
    if (currentFax.faxNumber.length === 0)
    {
      exportDocName = 'RHC-Fax-NoNumber';
    }
    else
    {
      exportDocName = 'RHC-Fax-' + currentFax.faxNumber;
    }
    if(faxPDF && faxPDF !== '')
    {
      const file = new Blob(
      [Buffer.from(faxPDF,'base64')], 
      {type: 'application/pdf'});
      saveAs(file, `${exportDocName}.pdf`);
    }
  },[faxPDF,currentFax.faxNumber])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect( () => {
    if(exportPDFStatus === 'Fetching' && faxPDF && faxPDF !== '')
    {
      // console.log('In UseEffect');
      downloadExport()
      setExportPDFStatus('Not Started')
    }
  }, [exportPDFStatus, faxPDF,downloadExport])

  const loadLetter = (thisFax) => {
    setcurrentFax(thisFax)
    setcurrentLoadingFax(false);
    if(thisFax.faxBlobLength) {
      dispatch(Admin.actions.fetchFaxPDF(thisFax.id))
    }
    setShowGPLetter(true);
  }

  const initInvite = () => {
    return { 
      id: 0,
      firstname: '',
      preferredname: '',
      surname: '',
      email: '',
      phone: '',
      message: '',
      clientAreasOfInterest: [],
      psychologistid: undefined,
      providertype:'Psychologist',
      appointmenttype: '0',
      status: '' }
  }

  const loadInvite = (thisInvite) => {
    setcurrentinviteid(thisInvite.id)
    setcurrentFirstName(thisInvite.firstname)
    setcurrentPreferredName(thisInvite.preferredname)
    setcurrentSurname(thisInvite.surname)
    setcurrentEmail(thisInvite.email)
    setcurrentMobile(thisInvite.phone)
    setcurrentMessage(thisInvite.message)
    var aoi = areasOfInterest.filter(( {issue} ) => thisInvite.clientAreasOfInterest.includes(issue))
    setcurrentAreasOfInterest(aoi)
    setcurrentTimeZone(thisInvite.timezone)
    setcurrentSlot(thisInvite.bookingdate)
    setcurrentAppointmentType(thisInvite.appointmenttype)
    setcurrentPsychologist(psychologists.find(row => row.id === thisInvite.psychologistid))
    setcurrentProviderType(thisInvite.providertype)
    setInviteError('')
  }

  // to generate token for invitation
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }

  
  function setFlag(event, id) {
    dispatch(Admin.actions.putFaxFlag({ id: id, flag: event.target.value }));
  }

  function setClientName(event, id) {
    dispatch(Admin.actions.putFaxClientName({ id: id, clientname: event.target.value }));
  }

  function handleFaxClose() {
    setShowFax(false);
  }

  function handleGPLetterClose() {
    setShowGPLetter(false);
  }

  // when changing psychologists, fetch their availability
  function handleMatchingAutocompleteChange(newValue) {  
    if (newValue) {
      setcurrentPsychologist(newValue);
      setcurrentTimeZone(newValue.timezone);
      setcurrentSlot();
      dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: newValue.id, userTZ: newValue.timezone, availabilityType : 'New', invite : false }));
    }
  }

  function handleTimeZoneChange(value) {
    currentPsychologist && dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: currentPsychologist.id, userTZ: value, availabilityType : 'New', invite : false }));
    setcurrentTimeZone(value);
    setcurrentSlot();
  }

  function handleAppointmentTypeChange(value) {
    setcurrentAppointmentType(value);
  }


  // Sets size of fax pdf to pass to viewer
  const [numPages, setNumPages] = useState(null);  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  

  
  // For a language that is designed to work with API it is primitive that I need to set a flag to stop the React_PDF from erroring when a PDF is not loaded 
  if (!currentLoadingFax && faxPDF.length > 5)
  {
    setcurrentLoadingFax(true);
  }

  function validateEmail(email) 
  {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
  }

  // Action buttons
  function saveInvite(action) {
    // console.log('Saving');
    if (
      (currentFirstName === '' )
      || (currentMobile === '' )
      || (currentEmail.trim.length > 1 && !validateEmail(currentEmail)) 
      || (currentEmail.trim.length > 1 && currentEmail.includes('mailto'))
      )
    { 
      setInviteError('You must provide a first name and a valid mobile phone number (and a valid email address if one has been included).')
    }
    else 
    {
      setInviteError('')
      var payload = {
        id: currentinviteid,
        faxid: currentFax.id,
        firstname: currentFirstName,
        preferredname: currentPreferredName,
        surname: currentSurname,
        email: currentEmail,
        phone: currentMobile,
        message: currentMessage,
        appointmenttype : currentAppointmentType,
        clientAreasOfInterest: currentAreasOfInterest.map(aoi => aoi.issue).join(","),
        psychologistid: currentPsychologist ? currentPsychologist.id : undefined,
        timezone: currentTimeZone ? currentTimeZone : userTZ,
        bookingdate: currentSlot,
        providertype: currentProviderType,
        invitetoken: uuidv4(),
        status: 'Saved',
        source: initSource()
      }
      if(currentinviteid === 0) {
        dispatch(Admin.actions.postClientInvite(payload));
      } else {
        dispatch(Admin.actions.putClientInvite(payload));
      }
      // if(action === 'SaveAndSend'){
      //   dispatch(Admin.actions.sendClientInvite(payload));
      // }
      setShowFax(false)
    }
  }

  const sendInvite = (id) => {
    dispatch(Admin.actions.sendClientInvite(invites.find(row => row.id === id)));
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'clientregistrationid', headerName: 'clientregistrationid', hide: true },
    { field: 'faxNumber', headerName: 'Fax No', width: 130,
      renderCell: (params) => (
        <strong>
          <div onClick={() => loadFax(faxes.find(fax => fax.id === params.row.id))} className="cell-Link">
            {params.value.length ? params.value : 'No number'}
          </div>
        </strong>
      ), },
    { field: 'flag', headerName: 'Follow-up', width: 250,
      cellClassName: (params) => params.row.flag.length > 0 ? 'flag-full-cell' : 'flag-empty-cell',
      renderCell: (params) => (
        <>
        <TextField 
          variant="outlined"
          name={`flag`} 
          defaultValue={params.row.flag} 
          onBlur={(event) => setFlag(event, params.row.id)}
          InputProps={{ classes: { notchedOutline:classes.noBorder, input: params.row.flag.length > 0 ? 'flag-full' : 'flag-empty' }, }}
          multiline
          maxRows={4}
          style= {{ width: '100%'}}
          onFocus={event => {
            event.target.select();
          }}>
        </TextField>
        </>
      )
    },
    { field: 'clientname', headerName: 'Client Name', width: 250,
      cellClassName: (params) => params.row.clientname.length > 0 ? 'flag-full-cell' : 'flag-empty-cell',
      renderCell: (params) => (
        <>
        <TextField 
          variant="outlined"
          name={`clientname`} 
          defaultValue={params.row.clientname} 
          onBlur={(event) => setClientName(event, params.row.id)}
          InputProps={{ classes: { notchedOutline:classes.noBorder, input: params.row.clientname.length > 0 ? 'flag-full' : 'flag-empty' }, }}
          style= {{ width: '100%'}}
          onFocus={event => {
            event.target.select();
          }}>
        </TextField>
        </>
      )
    },
    { field: 'receivedOn', type: 'dateTime', headerName: 'Received', width: 140, valueFormatter: ({ value }) => format(new Date(value), "dd-MM-yyyy HH:mm") },
    { field: 'initialPages', headerName: 'Pages', width: 90, sortable: false },
    { field: 'status', headerName: 'Status', width: 90, sortable: false },
    { field: 'clientFullName', type: 'date', headerName: 'Client', width: 160 },
    { field: 'none', headerName: 'Actions', width: 220,
      renderCell: (params) => (
        <>
          {params.row.clientregistrationid > 0 && params.row.status !== 'Registered' &&
            <>
              <Button variant="secondary" size="sm" onClick={()=>sendInvite(params.row.clientregistrationid)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Resend
              </Button>
            </>
          }
          {(params.row.status === 'New' || params.row.status === 'GP Faxed') &&
            <>
              <Button variant="secondary" size="sm" onClick={()=>loadLetter(faxes.find(fax => fax.id === params.row.id))} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Send GP Fax
              </Button>
            </>
          }
          <Button variant="secondary" size="sm" onClick={()=>saveFax(faxes.find(fax => fax.id === params.row.id))} style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }}  >
            Download
          </Button>
        </>
      ) 
    }
  ];

  const classes = useStyles();

  return (
    <>
    <h4>
      Faxes
    </h4>

    <Paper> 
      <Grid container justifyContent="space-between" style={{ padding: 10 }}>
        <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
          <Grid container>
            <Grid item>             
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control                  
                    type="text"
                    value={filters.faxTerm}
                    onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'faxTerm', filterValue: event.target.value }))}
                    autoComplete='OFF'
                    style={{ maxWidth: 170, height: 35, backgroundColor: filters.faxTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                  />
                <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'faxTerm', filterValue: '' }))}>
                  <InputGroup.Text>
                    <ClearIcon fontSize="small" style={{ color: filters.faxTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>  
              <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.faxesStatus} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'faxesStatus', filterValue: event.target.value }))}>
                <option value="All">All</option>
                <option value="New">New</option>
                <option value="Sent">Sent</option>
                <option value="Registered">Registered</option>
                <option value="Deleted">Deleted</option>
              </Form.Control>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <Paper style={{ height: 800 }}>
      <DataGrid rows={filteredFaxes} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'receivedOn', sort: 'desc' }]} />
    </Paper>

    <Modal show={showFax} onHide={handleFaxClose} dialogClassName="editInboundFaxDialog-modal">
      <Modal.Header closeButton className="editInboundFax-modal" style={{ padding: 10 }}>
        <Modal.Title style={{ marginTop: 6, width: "75%", }}>
          Fax {currentFax.faxNumber}  on {format(new Date(currentFax.receivedOn ? currentFax.receivedOn : null), "dd-MM-yyyy HH:mm")} 
        </Modal.Title>
        <div style={{ width: "50%", textAlign: "right"}}>
        {prev || prev === 0 ? <Button className="lightButtonOutlined" variant="light" size="sm" onClick={()=>navFax(prev)} style={{ marginLeft: 20 }}>&lt; Prev </Button> : null}
        {next ? <Button className="lightButtonOutlined"  variant="light" size="sm" onClick={()=>navFax(next)} style={{ marginLeft: 20 }}>Next &gt;</Button> : null}
        </div>
      </Modal.Header>
      <Modal.Body className="editInboundFax-modal" style={{ height: 700  }}>
          <Grid container spacing={2}>
            <Grid item md={7} style={{ height: 680, overflowY: "scroll", backgroundColor: "white" }}>
              {currentLoadingFax ? 
                <Document
                  file={'data:application/pdf;base64,'+faxPDF}
                  onLoadSuccess={onDocumentLoadSuccess}
                  >
                  {Array.from(
                    new Array(numPages),
                    (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ),
                  )}
                </Document> : <div style={{ marginLeft: 15, marginTop: 15 }}>No fax document available</div>}
            </Grid>

            <Grid item md={5} >
              <h3>Client Invite</h3>
              <hr></hr>
              {inviteError !== '' ?
              <Alert severity="error" style={{ marginBottom: 14 }}>
                {inviteError}
              </Alert>
              :
              null }
              <Row>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" name="profileFirstName" value={currentFirstName} onChange={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>Preferred Name</Form.Label>
                    <Form.Control type="text" name="profilePreferredName" value={currentPreferredName} onChange={(event) => setcurrentPreferredName(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Surname</Form.Label>
                    <Form.Control type="text"  name="profileSurname" value={currentSurname} onChange={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" name="profileEmail" value={currentEmail} onChange={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Mobile Phone Number</Form.Label>
                    <Form.Control type="text" name="profileMobile" value={currentMobile} onChange={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Provider Type</Form.Label>
                    <Form.Control as="select" defaultValue={currentProviderType} onChange={(event) => setcurrentProviderType(event.target.value)}>
                      <option value="Psychologist">Psychologist</option>
                      <option value="Financial">Financial Counsellor</option>
                      <option value="Occupational">Occupational Therapist</option>
                      <option value="Clinical">Clinical Psychologist</option>
                      <option value="GP">GP</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Message</Form.Label>
                    <Form.Control type="text" name="currentMessage" value={currentMessage} onChange={(event) => setcurrentMessage(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row>
              {(currentProviderType !== 'GP') ?
                  <>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                      <Form.Label>Please select the area(s) of interest your client is wanting to address (or leave blank and they can select)</Form.Label>
                        <Autocomplete
                          id="aoi"
                          variant="outlined"
                          multiple
                          options={(currentProviderType === 'Financial') ? FinancialAreasOfInterest : PsychologyAreasOfInterest }
                          getOptionLabel={(option) => option.issue }
                          value={currentAreasOfInterest}
                          onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="AOISelector"
                              style={{backgroundColor: "", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              :
                null 
              }
              <Row>
                <Col md="12">
                  <Form.Group >
                    <Form.Label>Psychologist (type to search)</Form.Label>
                    <Autocomplete
                      id="psychologist"
                      variant="outlined"
                      options={(currentProviderType === 'Financial') ? MatchingFinancial : (currentProviderType === 'GP') ? MatchingGP : MatchingPsychologist }
                      getOptionLabel={(option) => option.oneline }
                      value={currentPsychologist}
                      onChange={(event, newValue) => handleMatchingAutocompleteChange(newValue)}
                      style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="PsySelector"
                          style={{backgroundColor: "", margin: 0, padding: 0}}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <Form.Group >
                    <Form.Label>Timezone</Form.Label>
                    <Select
                      variant="outlined"
                      margin="dense"
                      value={currentTimeZone}
                      onChange={(event) => handleTimeZoneChange(event.target.value)}
                      style={{ width: '100%', backgroundColor: "#ffffff" }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {timeZone && timeZone.map(row => (
                        <MenuItem key={row.systemName} value={row.systemName} label={row.name}>{row.name}</MenuItem>
                      ))}
                      <MenuItem value={undefined}></MenuItem>
                    </Select>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group >
                    <Form.Label>Appointment Type</Form.Label>
                    <Select 
                      variant="outlined"
                      margin="dense"
                      id='appointmentType'
                      aria-label='Select Appointment Type'
                      name='appointmentType'
                      value={currentAppointmentType} 
                      onChange={(event) => handleAppointmentTypeChange(event.target.value)}
                      style={{ width: '100%', backgroundColor: "#ffffff" }} >
                      <MenuItem key='Video' value='0'>Video Call</MenuItem>
                      <MenuItem key='Phone' value='1'>Phone Call</MenuItem>
                    </Select>
                  </Form.Group>
                </Col>
              </Row>              
              <Row>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Booking Slot</Form.Label> 
                    <Select
                      variant="outlined"
                      margin="dense"
                      value={currentSlot}
                      onChange={(event)=>setcurrentSlot(event.target.value)}
                      style={{ minWidth: 300, backgroundColor: "#ffffff" }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {currentPsychologist && matchingAvailability.map(row => (
                        <MenuItem value={row.fromDate}>{format(new Date(row.fromDate), "dd/MM/yyyy h:mm aaaa")}</MenuItem>
                      ))}
                      <MenuItem value={undefined}></MenuItem>            
                    </Select>
                  </Form.Group>
                </Col>
              </Row>
            </Grid>          
          </Grid> {/* End container for fax and details forms */} 
      </Modal.Body> 

      <Modal.Footer className="addresource-modal">        
        <Button variant="secondary" onClick={handleFaxClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => saveInvite('Save')}>
          Save
        </Button>
      </Modal.Footer>
      
    </Modal>

    <Modal show={showGPLetter} onHide={handleGPLetterClose} dialogClassName="editInboundFaxDialog-modal">
      <Modal.Header closeButton className="editInboundFax-modal" style={{ padding: 10 }}>
        <Modal.Title style={{ marginTop: 6, width: "75%", }}>
          Fax {currentFax.faxNumber}  on {format(new Date(currentFax.receivedOn ? currentFax.receivedOn : null), "dd-MM-yyyy HH:mm")} 
        </Modal.Title>
        <div style={{ width: "50%", textAlign: "right"}}>
        {prev || prev === 0 ? <Button className="lightButtonOutlined" variant="light" size="sm" onClick={()=>navFax(prev)} style={{ marginLeft: 20 }}>&lt; Prev </Button> : null}
        {next ? <Button className="lightButtonOutlined"  variant="light" size="sm" onClick={()=>navFax(next)} style={{ marginLeft: 20 }}>Next &gt;</Button> : null}
        </div>
      </Modal.Header>
      <Modal.Body className="editInboundFax-modal" style={{ height: 700  }}>
          <Grid container spacing={2}>
            <Grid item md={7} style={{ height: 680, overflowY: "scroll", backgroundColor: "white" }}>
              {currentLoadingFax ? 
                <Document
                  file={'data:application/pdf;base64,'+faxPDF}
                  onLoadSuccess={onDocumentLoadSuccess}
                  >
                  {Array.from(
                    new Array(numPages),
                    (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ),
                  )}
                </Document> : <div style={{ marginLeft: 15, marginTop: 15 }}>No fax document available</div>}
            </Grid>

            <Grid item md={5} >
              <h3>Send Fax to Referring GP</h3>
              <SendLetter type="Client" form="Fax to GP Referrer from Admin" faxID={currentFax.id} closeModal={setShowGPLetter} />
            </Grid>
          </Grid> {/* End container for fax and details forms */} 
      </Modal.Body> 

      <Modal.Footer className="addresource-modal">        
        <Button variant="secondary" onClick={handleGPLetterClose}>
          Close
        </Button>
      </Modal.Footer>
      
    </Modal>

    <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>      
    <ToastContainer
      position="bottom-right"
      transition={Flip}
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    </div>
    </>
  )
}
