
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Forms from "../../../store/ducks/forms.duck";
import * as Admin from "../../../store/ducks/admin.duck";
import { Grid, makeStyles, MenuItem, Select, RadioGroup, FormControl, FormGroup, FormHelperText, FormControlLabel, Radio, Checkbox, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Form, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea } from 'material-ui-dropzone';
import Interweave from 'interweave';
import SignaturePad from 'react-signature-canvas';
import { format } from "date-fns";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app2.css';
import '../../../../_metronic/_assets/css/app.css';

// load worker thread for pdf operations
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


export default function FormDataEntry({ formID, formDataID, setShowFormData, contextName, contextID, pdfFlag, pdfGen, gpFax, preview }) {

  const classes = useStyles();
  const form = useSelector(state => state.forms.Form);
  const formData = useSelector(state => state.forms.FormData);
  const dispatch = useDispatch();
  const [currentLoadingPreview, setcurrentLoadingPreview] = useState();
  const previewPDF = useSelector(state => state.forms.PreviewPDF)
  
  useEffect( () => { dispatch(Forms.actions.fetchForm(formID)) }, [dispatch, formID] )
  useEffect( () => { dispatch(Forms.actions.fetchFormData({ formDataID : formDataID, formID : formID, contextName : contextName, contextID : contextID})) }, [dispatch, formDataID, formID, contextName, contextID] )

  
  useEffect( () => { 
    if (previewPDF && previewPDF.length > 0 && currentLoadingPreview)
    {
      // var byteCharacters = previewPDF
      // var byteNumbers = new Array(byteCharacters.length);
      // for (var i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // var byteArray = new Uint8Array(byteNumbers);
      // var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      // var fileURL = URL.createObjectURL(file);
      let pdfWindow = window.open('',"Preview PDF")
      pdfWindow.document.write("<html<head><title>PDF Preview</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
      pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(previewPDF)+"#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>");
      setTimeout(function(){ pdfWindow.stop() }, 1000);
      setcurrentLoadingPreview(false)
      dispatch(Forms.actions.clearPreviewPDF())
    }
  }, [previewPDF, currentLoadingPreview, dispatch] )


  const readonly = (formData.formDataStatus === 'Complete');
 
  let fileUpload = '';
  let nfileUpload = '';
  let fileHandler = [];

  // Sets size of fax pdf to pass to viewer
  // const [numPages, setNumPages] = useState(null);  
  // function onDocumentLoadSuccess({ numPages }) {
  //  setNumPages(numPages);
  // }

  // For a language that is designed to work with API it is primitive that I need to set a flag to stop the React_PDF from erroring when a PDF is not loaded 
  if (!currentLoadingPreview && previewPDF && previewPDF.length > 5)
  {
    setcurrentLoadingPreview(true);
  }

  function showPDF(data)
  {
    setcurrentLoadingPreview(false)
    dispatch(Forms.actions.clearPreviewPDF())
    var result = Object.keys(data).map((key) => { return { questionID: parseInt(key.substring(1)), dataValue: data[key] } } );
      // Filter out empty elements with no questionID, then add in the question type for simpler processing
      result = result.filter(q => !isNaN(q.questionID))
        .map(row => {
        var type = row.questionID && form.formQuestions.find(q => { return q.questionID === row.questionID } ).type;
        return { ...row, type: type }
      });
    dispatch(Forms.actions.fetchPreviewPDF({answers : result, formID : formID}))
  }

  function generateFileHandle() 
  { 
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
  }
     


  function FormInput({ question, handleChange, setFieldValue, values, errors }) {
    switch(question.type) {
      case 'VARCHAR':
        return  <FormVarchar question={question} handleChange={handleChange} values={values} errors={errors}  />
      case 'TEXT':
        return <FormText question={question} handleChange={handleChange} values={values} errors={errors}  />
      case 'STATEMENT':
        return <FormStatement question={question} />
      case 'STATEMENTPDF':
        return <FormStatementPDF question={question} />
      case 'OPTION':
        if(question.typeInput === 'radio')
        {
          return <FormOptionRadio question={question} handleChange={handleChange} values={values} errors={errors}/>
        }
        else // question.typeInput === 'radio'
        {
          return <FormOptionSelect question={question} handleChange={handleChange} values={values} errors={errors}/>
        }
      case 'MULTIOPTION':
        return <FormOptionCheckbox question={question} setFieldValue={setFieldValue} values={values} />
      case 'DATE':
        return <FormDate question={question} handleChange={handleChange} values={values} />
      case 'FILE':
        return <FormFile question={question} handleChange={handleChange} values={values} setFieldValue={setFieldValue} />
      case 'SIGNATURE':
        return <FormSignature question={question} handleChange={handleChange} values={values} setFieldValue={setFieldValue} />
      default :
        return null
    }
  }
  function FormVarchar({question, handleChange, values, errors}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    if(readonly)
      { 
        return <div className="form-textinput-readonly">{values[qname] || ''}</div>
      }
      else
      {
        return (
          <>
            <Form.Control type="text" id={qname} name={qname} value={values[qname] || ''} onChange={handleChange(qname)} autoComplete='new-password' placeholder={question.questionPlaceHolderText} />
            <span className="field-error">{errors[qname] ? errors[qname] : ''}</span>
          </>
        )
      }   
  }

  function FormText({question, handleChange, values, errors}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;   
    if(readonly)
      { 
        return <div className="form-textinput-readonly">{values[qname] || ''}</div>
      }
      else
      {
        return (
          <>
            <Form.Control as="textarea" rows={4} type="text" id={qname} name={qname} value={values[qname] || ''} onChange={handleChange(qname)}  autoComplete='new-password' placeholder={question.questionPlaceHolderText} />    
            <span className="field-error">{errors[qname] ? errors[qname] : ''}</span>
          </>
        )
      }      
  }

  function FormStatement({question}) {
    return <Interweave content={question.questionStatement} />
  }

  function FormStatementPDF({question}) {
    const [questionPDF, showQuestionPDF] = useState(false);
    const [numPages, setNumPages] = useState(null);  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
      <>
        <Button onClick={()=>showQuestionPDF(true)}>{question.questionStatementPDFFileName}</Button>
        
        <Modal show={questionPDF} onHide={()=>showQuestionPDF(false)} dialogClassName="viewQuestionPDF-dialog-modal">
          <Modal.Header closeButton className="viewQuestionPDF-modal" style={{ padding: 10 }}>
            <Modal.Title style={{ marginTop: 6, width: "75%", }}>
              {question.questionStatementPDFFileName} - {numPages} pages
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="viewQuestionPDF-modal" style={{ height: 700  }}>
            <Grid container spacing={2}>
              <Grid item md={12} style={{ height: 680, overflowY: "scroll", backgroundColor: "white" }}>
                {question.questionStatementPDF.length > 5 ? 
                  <Document
                    file={'data:application/pdf;base64,'+ question.questionStatementPDF}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    {Array.from(
                      new Array(numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          scale={1.5}
                        />
                      ),
                    )}
                  </Document> : <div style={{ marginLeft: 15, marginTop: 15 }}>No PDF Attached.</div>}
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  function FormOptionSelect({question, handleChange, values, errors}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return ( 
      <>
        <Select 
          variant="outlined"
          margin="dense"
          id={qname}
          aria-label='Type'
          name={qname}
          disabled={readonly}
          value=''
          style={{ backgroundColor: "#ffffff" }} >
            <MenuItem value=''>&nbsp;</MenuItem>
            {question.formOptions ? question.formOptions.map(options => {
              return (
                <MenuItem 
                  key={options.optionID}
                  value={options.optionID}
                  //className={readonly && values[qname] === options.optionID ? 'form-radio-label-readonly' : 'form-radio-label'}
                  >
                  {options.optionLabel}
                </MenuItem>
              )
              })
            : null}
        </Select>
        <span className="field-error">{errors[qname] ? errors[qname] : ''}</span>
      </>     
    )
  }

  function FormOptionRadio({question, handleChange, values, errors}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    //<Form.Control type="text" id={qname} name={qname} autoComplete='new-password' placeholder={question.questionPlaceHolderText}/>  
    return (
      <Grid item xs={12} md={12}>
        <RadioGroup 
          aria-label={question.questionText} 
          style={{ paddingLeft: 8 }}
          name={qname} 
          value={values[qname] || ''} 
          row={question.answerLayout === 'row' ? true : false}
          >
          {question.formOptions ? 
            question.formOptions.map(options => {
              return (
                <FormControlLabel
                  key={options.optionID}
                  value={options.optionID}
                  control={
                    readonly 
                      ? <Radio className="form-radio-input-readonly" value={options.optionID} />
                      : <Radio className="form-radio-input" value={options.optionID} onChange={handleChange(qname)} />
                    }
                  label={<Typography className={readonly && values[qname] === options.optionID ? 'form-radio-label-readonly' : 'form-radio-label'}>{options.optionLabel}</Typography>}
                  style={{ marginBottom: 0, cursor: readonly ? 'default' : 'pointer'  }}
                />
              )
            })
            : ('None')
          }
        </RadioGroup>
        <span className="field-error">{errors[qname] ? errors[qname] : ''}</span>
      </Grid>
    )    
  }

  function FormOptionCheckbox({question, setFieldValue, values}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    const myValues = values[qname] || [];

    const handleMyChange = event => {
      if(event.target.checked)
      {
        myValues.push(event.target.value)
      }
      else
      {
        var i = myValues.indexOf(event.target.value);
        myValues.splice(i, 1)
      }
      setFieldValue(qname, myValues);
    };

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup  row={question.answerLayout === 'row' ? true : false}>
          {question.formOptions ? 
              question.formOptions.map(options => {
                return (
                  <FormControlLabel
                    key={options.optionID}
                    control={
                      readonly 
                        ? <Checkbox name={qname} value={options.optionID} checked={myValues.some(v => v === options.optionID)} className="form-radio-input-readonly" />
                        : <Checkbox name={qname} value={options.optionID} checked={myValues.some(v => v === options.optionID)} onChange={handleMyChange} className="form-radio-input" />
                    }
                    label={<Typography className={readonly && myValues.some(v => v === options.optionID) ? 'form-radio-label-readonly' : 'form-radio-label'} >{options.optionLabel}</Typography>}
                    style={{ marginBottom: 0, cursor: readonly ? 'default' : 'pointer'  }}
                    // disabled={readonly}
                  />
                )
              })
              : ('None')
            }
        </FormGroup>
        {/* <FormHelperText>{question.questionHelpText}</FormHelperText> */}
      </FormControl>
    ) 
  }

  function FormDate({question, handleChange, values}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return (      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            name={qname}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="dense"
            id={qname}
            autoOk={true}
            size="small"
            onChange={() => null}
            disabled={readonly}
            className="formquestion-datepicker summary-date"
        />
      </MuiPickersUtilsProvider>
    )   
  }

  function FormFile({question, handleChange, values}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    // const qname = 'Q' + question.questionID;
    return (
      <Grid item xs={12} md={12} style={{ marginTop: 12 }}> 
        <DropzoneArea
          acceptedFiles={['image/*,.pdf']}
          showPreviews={true}
          maxFileSize={4000000}
          showPreviewsInDropzone={false}
          dropzoneText={"Drag and drop files here, or use your phone camera."}
          dropzoneClass="drop-zone-area"
          onChange={() => null}
        />
      </Grid> 
    )  
  }

  function FormSignature({ question, handleChange, setFieldValue, values }) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    // const [sigData, setSigData] = useState({}); 
    var sigPad = {}
    var trim = () => {
      // setSigData({trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png')});
      // setFieldValue(qname, {trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL('image/png')});
      setFieldValue(qname, sigPad.getTrimmedCanvas().toDataURL('image/png'));
    }
    var clear = () => {
      sigPad.clear();
      trim();
    }
    return (
      <>
        {readonly ?
          <div className="formSignature-readonly">
            <img src={values[qname]} alt="Signature" />
          </div>
        :
        <div className="sigOuterContainer" style={{marginTop: 10}}>
          <div className="sigContainer" onMouseLeave={trim} onTouchEnd={trim}>
            <SignaturePad canvasProps={{ className: "sigPad" }}
              ref={(ref) => { sigPad = ref }} />  
          </div>
          <Grid container justifyContent="space-between" spacing={3} style={{ textAlign: 'right' }}>
            <Grid item>
            </Grid>
            <Grid item style={{ paddingRight: 60 }}>
              <span onClick={clear} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                Start Again
              </span>
            </Grid>
          </Grid>
        </div>
        }
          {/* {sigData.trimmedDataURL
          ? <img src={sigData.trimmedDataURL} />
          : null} */}
      </>
    )   
  }

  const [showConfirmation, setShowConfirmation] = useState(false);

  function Savesubmit(data) 
  {
    // **********************************************************************************************************
    // Note this doesn't currently support file uploads - coming soon with the secureform module
    //
    // **********************************************************************************************************
    // Check for file upload question 
    if (fileUpload.length > 0 && data[fileUpload].length)
    {
      // Ok there is a file upload question (will need to revisit this for forms with multiple file upload questions)
      // Create structure to load file 
      const formData = new FormData();
      // Lets loop through 
      let arrayPointer = 0;
      for (const fileUploadItem of data[fileUpload])
      {
        // Generate unique-ish file handle to be used to connect the file and request 
        const fileHandle = generateFileHandle();
        // Now add answer of file upload question - which is the file - to formData 
        // Note the name files - must match the API otherwise nothing is received 
        formData.append('files',fileUploadItem);
        // Now that file is loaded into formData, set fileupload question answer to the handle so the API can connect the file with the request 
        data[fileUpload] = "";
        nfileUpload = fileUpload + '-' + arrayPointer.toString(); 
        data[nfileUpload] = "";
        // Set question answer to GUID i.e. Q147-0 = GUID
        data[nfileUpload] = fileHandle;
        // Store GUID as it will be needed to reassociate files with questions at the Valhalla end 
        fileHandler[arrayPointer] = fileHandle;
        arrayPointer = arrayPointer + 1;
      }
      // Some debugging
      if (process.env.REACT_APP_DEBUG) {
        console.log('Form here:');
        console.log(data);
      }
      // Save request/form 
      dispatch(Forms.actions.putFormData({data, formData, fileHandler}));
      // Save file 
    }
    else
    {
      //
      // All traffic is coming this way 
      //
      // Save request/form
      data[fileUpload] = "";
      // data = {...data, [fileUpload]: ""}
      // convert Formik values collection to an array for the API, and remove the 'Q' from the QuestionID
      var result = Object.keys(data).map((key) => { return { questionID: parseInt(key.substring(1)), dataValue: data[key] } } );
      // Filter out empty elements with no questionID, then add in the question type for simpler processing
      result = result.filter(q => !isNaN(q.questionID))
        .map(row => {
        var type = row.questionID && form.formQuestions.find(q => { return q.questionID === row.questionID } ).type;
        return { ...row, type: type }
      });
      process.env.REACT_APP_DEBUG && console.log(result);
      // Handle form in files tab
      if (pdfFlag)
      {
        setShowFormData(false);
      }
      else
      {
        setShowConfirmation(true)
      }
      dispatch(Forms.actions.putFormData( { formID: formID, formDataID: formDataID, contextID : contextID, contextName : contextName, data: result }));
      // Check if a fax status needs to be updated 
      if (gpFax)
      {
        dispatch(Admin.actions.putFaxStatus( { faxID: gpFax, status: 'GP Faxed' }));
        setShowFormData(false)
      }

    }
  }

  var tempSchema = { }
  
  form && form.formQuestions && form.formQuestions.map(question => tempSchema = {
    ...tempSchema, 
    [`Q${question.questionID}`]: question.mandatory ? 
            (question.type === 'VARCHAR' || question.type === 'TEXT') ?
              Yup.string().required("Answer is required")
          : 
            question.type === 'MULTIOPTION'  ? 
              Yup.string().required(`Answer is required to ${question.reference}`) 
            : 
              question.type === 'OPTION' ?
                Yup.string().required("Answer is required for this question")
              :
                null
      : 
        null
  })

  const validationSchema = Yup.object().shape(tempSchema);



  if(showConfirmation)
  {
    return(
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <h5>Form submitted successfully.</h5>
          <hr />
          <p>{form.confirmationText}</p>
        </Grid>
      </Grid>
    )
  }
  else
  {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={formData.values}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          Savesubmit(values);
          // actions.setSubmitting(false);
        }}
      >
        {props => {
          const {
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
          } = props;
                    
          return (
  
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <div className='container-fluid'>
              <Grid container spacing={1}>
                {formData.formDataStatus === 'Complete' ?
                  <Grid item xs={12} md={12} style={{ fontStyle: 'italic', fontWeight: 400  }}>
                    Form completed on {format(new Date(formData.editedOn ? formData.editedOn : null), "dd-MM-yyyy")} at {format(new Date(formData.editedOn ? formData.editedOn : null), "h:mm aa")}.
                  </Grid>
                  : null}
                <Grid item xs={12} md={12}>
                  {form.formInstructions}
                </Grid>
                {/* <Grid container spacing={1}> */}
                  {form.formQuestions && form.formQuestions.filter(question => !(question.adminUseOnly && formData.callerRole === 'Client')).map((question, index) => {
                    return (                
                      <Grid item xs={12} md={12} key={question.questionID} style={{ marginBottom: 15 }}>
                        {question.type !== 'STATEMENT'
                          ? <Form.Label style={{ width: '100%', fontWeight: 600 }}>{question.questionText}{question.mandatory ? '*' : null}</Form.Label>
                          : null}
                        <FormInput question={question} handleChange={handleChange} setFieldValue={setFieldValue} values={values} errors={errors}  />
                        <FormHelperText>{question.questionHelpText}</FormHelperText>
                      </Grid>
                    );
                  })}
                {/* </Grid> */}
                <Grid container direction="row" justifyContent="space-between">
                  {preview ?
                    <Grid item md={8}>
                      <Button variant="outlined" style={{ background: '#fff0ed' }} onClick={() => showPDF(values)}>
                        Preview
                      </Button>
                    </Grid>
                  :  
                    null
                  }
                  <Grid item md={2} style={{ textAlign: 'right' }}>
                    <Button variant="outlined" style={{ background: "#eeeeee" }} onClick={() => setShowFormData(false)}> 
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item md={2} style={{ textAlign: 'right' }}>
                    {!readonly && ((formDataID && formDataID > 0) || pdfFlag) ?
                      <Button variant="outlined" color="secondary" type="submit" style={{ background: "#daeaf5" }}>
                        {pdfFlag ?
                          <>
                            {pdfGen ? 
                              'Fax PDF'
                            :
                              'Generate PDF'
                            } 
                          </>
                        : 
                          'Save'
                        }
                      </Button>
                    :
                      null
                    }
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
          )
        }}
      </Formik>

    )
  }
}

const useStyles = makeStyles(theme => ({  
  root: {
    maxWidth: 550,
  },
  cardHeader: {
    borderBottom: "solid 1px silver",
    backgroundColor: "#daeaf5",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    marginLeft: 8
  },
  dropzoneQuestion: {
    height: 100
  }
}));
