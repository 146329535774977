import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap"
import SearchIcon from '@material-ui/icons/Search'
import * as Admin from "../store/ducks/admin.duck"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, Paper, TextField, Typography, withStyles } from '@material-ui/core'
import { ToastContainer, Flip } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// In the world of confusion - please note the following invoicing process 
//
// Today we are billing per booking using the Services Table to define the prices - each booking has a billable type that is set (or defaulted) when a booking is made
//
// Process 
// 
// GatherBillable.js  - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to email providers a list of those to be billed at the end of the month if still eliglible. 
//                    - Can toogle bookings to cancelled/noshow as needed 
// Invoices.js        - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to create Invoices in Xero for these providers. 
//                    - This essentially a copy of GatherBillable though expect this will change at client acceptance occurs. 
// InvoiceLog.js      - This is a searchable log of invoices/clients/providers 
// 
// PlatformPsychologist.js - Lists unbilled boookings 
//                         - Only list bookings that have a booking charged field of null and a booking of status Active
//                         - Can toogle bookings to cancelled/noshow as needed //


const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important"
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal"
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important"
    }
  }
})(DataGrid);

export default function GatherBillableDetail({billablestatus, clients, psychologistID, closeClients, providerFee, providerPayments, displayNote}) 
{
  // console.log(displayNote)
  const dispatch = useDispatch()
  const [showBooking, setShowBooking] = useState(false);
  const [clientName, setClientName] = useState();
  const [bookingID,setBookingID] = useState();
  const [status,setStatus] = useState();
  // const [contractid,setContractID] = useState();
  const [service,setService] = useState();

  const initContract = () => {
    return { id:0, name: ''}
  }

  const [currentContract, setcurrentContract] = useState(initContract());
 

  const loadContract = (thisContract) => {
    setcurrentContract(thisContract ? thisContract : initContract())
  }

  const contracts = useSelector(state => state.admin.Contracts)
  useEffect( () => { dispatch(Admin.actions.fetchContracts()) }, [dispatch] )
  const [searchTerm, setSearchTerm] = useState('')


  const searchClients = (client) => {
    if ( true

        && (client => client.clientName.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )
        && (!billablestatus || billablestatus === 'All' || client.billingstatus === billablestatus)
      )
      {
        return client
      }  
  }
 
  const filteredClients = clients.filter(client => searchClients(client))

  const loadBooking = (thisBooking) => {
    setClientName(thisBooking.clientName);
    setBookingID(thisBooking.bookingid);
    setStatus(thisBooking.status);
    // setContractID(thisBooking.contractid);
    setcurrentContract(contracts.find(contract => contract.id === thisBooking.contractid));
    setService(thisBooking.service);
    // console.log(thisBooking);
    // console.log(currentContract);
    setShowBooking(true);
  }


  function UpdateBooking() {
    // Create payload to send to API 
    var payload = {
        id : bookingID,
        paymenttype: service,
        contractid : currentContract && currentContract.id,
        status : status
    }
    //
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.updateBookingBilling(payload));
    setShowBooking(false);
    closeClients(false);
  }

  const columns = [
    { field: 'id', headerName: 'BookingID', width: 100,  hide: true },
    { field: 'contractid', headerName: 'ContractID', width: 100,  hide: true },
    { field: 'clientName', headerName: 'Name', width: providerFee ? 200 : 200,  
      renderCell: (params) => (
        <>
          {!providerPayments ?
            <strong>
              <div onClick={() => loadBooking(clients.find(client => client.id === params.row.id))} className="cell-Link" >
                {params.value}
              </div>
            </strong>
          :
            <div >
              {params.value}
            </div>
          }
        </>
      ),  
    },
    { field: 'bookingDate', headerName: 'Booking Date', width: 160 },
    { field: 'cost', headerName: 'Session Fee', width: 150 },
    { field: 'providerfee', headerName: 'Fee to Provider', width: 170, hide: providerFee ? false : true}, 
    { field: 'service', headerName: 'Payment Type', hide: providerPayments ? true : false, width: service === 'Contract' ? 330 : 180,
      renderCell: (params) => (
        <div>
            {params.value === 'Contract' ?
              <>
                {params.value}
                -
                {contracts && contracts.length > 1 && contracts.find(contract => contract.id === params.row.contractid).displayname}
              </>
            :
              <>
              {params.value}
              </>
            }
        </div>
      ),
    },
    { field: 'status', headerName: 'Status', width: 110 },
    { field: 'billingstatus', headerName: 'Billing', width: 120 },
    { field: 'approvedstatus', headerName: 'Approved', width: 130 },
    { field: 'note', headerName: 'Provider Note', hide: displayNote ? false : true, width: 220,
      renderCell: (params) => (
        <div>
          <Typography>{params.value}</Typography>
        </div>
      )
    }
    
  ];


  const getContractOption = (option, value) => {
    // console.log(option);
    // console.log(value);
    if (option.id === value.id) {
      return option
    } else {
      return initContract();
    }
  }


  function handleBookingClose() {
    setShowBooking(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
        </Grid>
      </Paper>
      { clients ? 
        <Paper style={{ height: 700 }}>
          <StyledDataGrid rows={filteredClients} columns={columns} pageSize={10} checkboxSelection={false} sortModel={[{ field: 'bookingDate', sort: 'asc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showBooking} onHide={handleBookingClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Update Booking Status and Funding - {clientName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <Row>
            <Col md="3">
              <Form.Group style={{width: "100%"}}>
                <Form.Label style={{ color: 'green', fontWeight: 500 }}>Booking Status</Form.Label>                        
                <Form.Control as="select" defaultValue={status} onChange={(event) => setStatus(event.target.value)}>
                  <option value="Active">Billable</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="NoShow">No Show</option>
                  <option value="Deleted" disabled>Deleted</option>                  
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group style={{width: "100%"}}>
                <Form.Label style={{ color: 'green', fontWeight: 500 }}>Funding Type</Form.Label>                        
                <Form.Control as="select" defaultValue={service} onChange={(event) => setService(event.target.value)}>
                  <option value="Bulk Billed">Bulk Billed</option>
                  <option value="Reduced Gap">Reduced Gap (+$20)</option>
                  <option value="Full Fee">Full Fee</option>
                  <option value="NDIS">NDIS</option>
                  <option value="Contract">Contract</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {service === 'Contract' ? 
              <Col md="6">
                <Form.Group style={{width: "100%"}}>
                  <Form.Label style={{ color: 'green', fontWeight: 500 }}>Contract</Form.Label>  
                    <Autocomplete
                      id="contractID"
                      variant="outlined"
                      options={contracts}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => getContractOption(option, value)}
                      value= {currentContract}
                      onChange={(event, newValue) => loadContract(newValue)}
                      style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="currentContactID"
                          style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 400  }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                </Form.Group>
              </Col>
            :
              null
            }
          </Row>
        </Modal.Body>
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleBookingClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={UpdateBooking}>
            Save
          </Button>
        </Modal.Footer>

      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
