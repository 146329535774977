
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { Formik } from 'formik';
import * as Yup from "yup";
import { Grid } from '@material-ui/core';
import { FormGroup, FormControl, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { Button, Form, Alert, Badge } from "react-bootstrap";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastContainer, Flip } from "react-toastify";
import { format } from "date-fns";
import CancelIcon from '@material-ui/icons/Cancel';
import ReplyIcon from '@material-ui/icons/Reply';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import "react-toastify/dist/ReactToastify.css";
import '../../_metronic/_assets/css/app.css';
import '../../_metronic/_assets/css/app2.css';

export default function ToDos({ clientID, setShowToDo, tagValues, clientType }) {

  const dispatch = useDispatch();  
  // const filters = useSelector(state => state.admin.Filters)
  const todos = useSelector(state => state.admin.ToDos);
  const roleDesc = useSelector(state => state.auth.roleDesc)

  const initToDo = () => {
    return {
	    id: 0,
	    todoID: 0,
	    clientID: clientID,
	    label: '',
	    colour: '',
	    dueDate: null,
      shareWith: '',
	    complete: false,
      needsGPBooking: 0,
      ppwFollowUp: 0
	  }
  }
    
  const [todo, setToDo] = useState(initToDo())
  const [replyID, setReplyID] = useState()
  const [replyText, setReplyText] = useState('')
  const [replyShareWith, setReplyShareWith] = useState('')
    
  useEffect( () => { dispatch(Admin.actions.fetchToDos({ clientID: clientID })) }, [dispatch,clientID] )

  // const filteredtodos = todos.filter(row => filters.todoStatus === 'All' || (filters.todoStatus === 'Active' && row.complete === false))

  const validationSchema = Yup.object().shape({
    label: Yup.string()
    .when(['ppwFollowUp','needsGPBooking'],
    {
      is: (ppwFollowUp,needsGPBooking) => (ppwFollowUp !== '1' && needsGPBooking !== '1'),
      then: Yup.string().required("Label is required"),	
    }),
  });

  const submitForm = (values) => {
    // Add label for ppwFollowUp
    if (values.ppwFollowUp === '1' && values.label.length === 0)
    {
      values.label = 'PPW Followup'
    }
    // Add label for needsGPBooking
    if (values.needsGPBooking === '1' && values.label.length === 0)
    {
      values.label = 'Needs GP Booking'
    }
	  var payload = {
		  todo: values,
      clientType: clientType,
	  }
    dispatch(Admin.actions.putToDo(payload))
    setToDo(initToDo())
  }

  const completeToDo = (item) => {
	  var payload = {
		  todo: { ...item, complete: true },
      tagValues: tagValues, 
      clientType: clientType,
	  }
    dispatch(Admin.actions.putToDo(payload))
  }

  const replyToDo = (id, share) => {
    setReplyID(id)
    setReplyShareWith(share)
  }

  const clearReply = () => {
    setReplyID()
    setReplyText('')
    setReplyShareWith('')
  }

  const sendReply = () => {    
	  var payload = { 
      ...todo, 
      replyToID: replyID,
      label: replyText, 
      shareWith: replyShareWith 
	  }
    submitForm(payload)
    setReplyText('')
    setReplyID()
  }

  return (
    <div className='container-fluid'>     
			<Formik
			  enableReinitialize={true}
			  initialValues={todo}
			  validationSchema = {validationSchema}
			  onSubmit={(values, actions) => {
				submitForm(values);
				actions.resetForm();
			  }}
			>
			{props => {
			const { values, handleChange, setFieldValue, handleSubmit } = props; 
			const change = (name,e) => { e.persist();  handleChange(e); };
      const handleCheckboxChange = (event) => {
        if(event.target.checked)
        {
          setFieldValue(event.target.name, event.target.value);
        }
        else
        {
          setFieldValue(event.target.name, '');
        }
      };    
			return (
        <>
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <label>Todo Label</label>
              <Form.Control type="text" as="textarea" rows={2} id="label" name="label" value={values.label} onChange={change.bind(null, "label")} style={{ minHeight: 37, borderColor: 'rgb(210 210 210)' }} autoComplete='new-password'/>
            </Grid>
            <Grid item md={12}>
              <label>Additional Information (if needed)</label>
              <Form.Control type="text" as="textarea" rows={2} id="label" name="label" value={values.additional} onChange={change.bind(null, "additional")} style={{ minHeight: 37, borderColor: 'rgb(210 210 210)' }} autoComplete='new-password'/>
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name='dueDate'
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      margin="dense"
                      id="dueDate"
                      value={values.dueDate} 
                      onChange={(propertyValue) => setFieldValue("dueDate", propertyValue)}
                      autoOk={true}
                      className="ev-form-control-inputDate summary-dense date-small"
                      label="Deadline"
                      InputProps={{ classes: { input: 'input-date-picker' }, }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                {/* className={classes.formControl} */}
                <FormControl component="fieldset" style={{ marginTop: 6 }}>
                  <FormGroup row={true}>
                    <FormControlLabel
                      control={<Checkbox name="shareWith" value={roleDesc === 'Admin' ? 'Psychologist' : 'Admin'} checked={values.shareWith !== ''} onChange={handleCheckboxChange} className="form-radio-input" />}
                      label={<Typography className='form-radio-label'>Send to {roleDesc === 'Admin' ? 'Psychologist' : 'Admin'}</Typography>}
                      style={{ marginBottom: 0, cursor: 'pointer' }}
                    />
                    <FormControlLabel
                      control={<Checkbox name="ppwFollowUp" value={'1'} checked={values.ppwFollowUp === '1'} onChange={handleCheckboxChange} className="form-radio-input" />}
                      label={<Typography className='form-radio-label'>PPW Followup</Typography>}
                      style={{ marginBottom: 0, cursor: 'pointer' }}
                    />
                    <FormControlLabel
                      control={<Checkbox name="needsGPBooking" value={'1'} checked={values.needsGPBooking === '1'} onChange={handleCheckboxChange} className="form-radio-input" />}
                      label={<Typography className='form-radio-label'>Need GP Appt.</Typography>}
                      style={{ marginBottom: 0, cursor: 'pointer' }}
                    />
                  </FormGroup>
                </FormControl>
                </Grid>
                <Grid item>
                  <Button size="sm" style={{ marginTop: 2, height: 35 }} type="submit">Add</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
          </>
			)
			}}
			</Formik>

      <hr/>
      {todos.map(item => 
      <div key={item.id}>
        <Alert variant="light" onClose={() => completeToDo(item.id)} style={{ paddingRight: 0, paddingLeft: 10, paddingTop: 10, paddingBottom: 10, marginTop: replyID && replyID === item.id ? 10 : 20 }}>
          {/* replyID && replyID === item.id ? 0 : 10 */}
          <Grid container justifyContent="space-between">
            <Grid item md={11}>
              <Grid container>
                {item.dueDate && 
                  <Grid item md={12} style={{ marginBottom: 6 }}>
                    <Badge variant={(new Date(item.dueDate) - new Date()) < 0 ? 'danger' : 'warning'} style={{ marginRight: 6 }}>Due:  {format(new Date(item.dueDate), "dd-MM-yyyy")} </Badge>
                  </Grid>
                }                
                <Grid item md={12}>
                  <div className='todo-message-date'>
                    {format(new Date(item.createdOn), "dd/MM/yy HH:mm")}

                    {item.ppwFollowUp && <BookmarkIcon className='todo-flag-ppw' />}
                    {item.needsGPBooking && <BookmarkIcon className='todo-flag-gp' />}
                    {item.shareWith !=='' && <BookmarkIcon className='todo-flag-shared' />}

                  </div>
                  {(item.shareWith !=='') && <Badge variant={item.createdByMe ? 'secondary' : 'primary'} className="todo-from">{item.toDoCreatedbyName} : </Badge>}
                  <span className='todo-message-content'>{item.label}</span>
                </Grid>

              </Grid>
            </Grid>
            <Grid item md={1} style={{ textAlign: 'right' }}>
              {item.replies.length === 0 && <ReplyIcon onClick={() => replyToDo(item.id, item.shareWith !=='' ? item.shareWith : '')} style={{ marginRight: 4 }} className="todo-close-icon" />}
              <CancelIcon onClick={() => completeToDo(item)} style={{ marginRight: 5 }} className="todo-close-icon" />
              {/* roleDesc === 'Admin' ? 'Psychologist' : 'Admin' */}
            </Grid>
          </Grid>
        </Alert>
          {item.replies.map((reply, replyindex) => 
            <Grid key={`reply-${reply.id}`} container justifyContent="flex-end">
              <Grid item md={1}>
              </Grid>
              <Grid item md={11} >
                <Alert variant="light" onClose={() => completeToDo(reply.id)} style={{ paddingRight: 0, paddingLeft: 10, paddingTop: 10, marginTop: 4, width: '100%' }}>
                  <Grid container justifyContent="space-between">
                    <Grid item md={11}>
                      <Grid container>
                        {reply.dueDate && 
                        <Grid item md={12} style={{ marginBottom: 6 }}>
                          <Badge variant={(new Date(reply.dueDate) - new Date()) < 0 ? 'danger' : 'warning'} style={{ marginRight: 6 }}>Due:  {format(new Date(reply.dueDate), "dd-MM-yyyy")} </Badge>
                        </Grid>}
                        
                        <Grid item md={12}>
                          <div className='todo-message-date'>{format(new Date(reply.createdOn), "dd/MM/yy HH:mm")}</div>
                          {((reply.createdByMe && reply.shareWith !=='') || (!reply.createdByMe && reply.shareWith !=='')) && <Badge variant={reply.createdByMe ? 'secondary' : 'primary'} className="todo-from">{reply.toDoCreatedbyName} : </Badge>}
                          <span className='todo-message-content'>{reply.label}</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={1} style={{ textAlign: 'right' }}>
                      {(replyindex + 1) === item.replies.length && <ReplyIcon onClick={() => replyToDo(item.id, item.shareWith !=='' ? item.shareWith : '')} style={{ marginRight: 4 }} className="todo-close-icon" />}
                      <CancelIcon onClick={() => completeToDo(reply)} style={{ marginRight: 5 }} className="todo-close-icon" />
                    </Grid>
                  </Grid>
                </Alert>
              </Grid>
            </Grid>
          )}

        {replyID && replyID === item.id && 
          <Grid container justifyContent="flex-end">
            <Grid item md={1}>
            </Grid>
            <Grid item md={11} style={{ textAlign: 'right' }} >
              <Form.Control type="text" as="textarea" rows={2} id="label" name="label" value={replyText} onChange={(event)=>setReplyText(event.target.value)} style={{ minHeight: 37, borderColor: 'rgb(210 210 210)' }} autoComplete='new-password'/>
              <Button size="sm" variant="secondary" style={{ height: 35, marginTop: 6, marginBottom: 15, marginRight: 10 }} onClick={()=>clearReply()} >Cancel</Button>
              <Button size="sm" style={{ height: 35, marginTop: 6, marginBottom: 15 }} onClick={()=>sendReply()} >Send</Button>
            </Grid>
          </Grid>
        }
      </div>
      )}

      <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )

}

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 	  margin: 0,
// 	  minWidth: 120,
// 	},
// 	formControlWide: {
// 	  margin: 0,
// 	  minWidth: '100%',
// 	},
// 	selectEmpty: {
// 	  marginTop: theme.spacing(2),
// 	},
// 	label: {
// 	  backgroundColor: '#fff',
// 	  paddingLeft: 3,
// 	  paddingRight: 3,
// 	}
//   }));
  