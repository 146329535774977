import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Form, InputGroup, Col } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';

import * as Admin from "../../../store/ducks/admin.duck";
import { ToastContainer, Flip } from "react-toastify";
import KTUtil from "../../../../_metronic/_assets/js/util";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export default function VerifyXero() {

  // In the world of confusion - please note the following
  //
  // Entering this window runs a simple process of validation all providers (psychologists) with Xero. 
  // Those that cannot be found in Xero are displayed for follow up 
  //
  // This process inserts/updates the paymentmethods table with the Xero Contact GUID (in Xero RHC Customers are called Contacts)
  //
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const xerondislinkfailures = useSelector(state => state.admin.XeroNDISLinkFailures)
  const filteredxerondislinkfailures = xerondislinkfailures.filter(xerondislinkfailures => (xerondislinkfailures.ndisplanmanagementprovider.toLowerCase().search(searchTerm.toLocaleLowerCase()) > -1  || searchTerm.length === 0))
  
  // Note this currently a put - not sure it will stay this way
  useEffect( () => { dispatch(Admin.actions.putXeroNDISContactLink()) }, [dispatch] )

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'ndisplanmanagementprovider', headerName: 'Plan Management Provider', width: 250 },
    { field: 'ndisemailforinvoices', headerName: 'Invoice Email', width: 350 },
    { field: 'supportcoordinator', headerName: 'Support Coordinator', width: 200 },
    { field: 'supportcoordinatorphone', headerName: 'Coordinator Phone', width: 200 },
    { field: 'ndiserror', headerName: 'Error', width: 450 },
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }

  if (KTUtil.isEmpty(xerondislinkfailures))
  {
    return('Looking - Takes a few minutes to populate');
  }  

  return (
    <>
     
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <Grid container direction="row">
              <Grid item>
                <InputGroup size="sm" >
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control                  
                      type="text"
                      value={searchTerm}
                      onChange={(event) => handleSearch(event)}
                      autoComplete='OFF'
                    />
                  </InputGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Col md="6">
              <Form.Group >
                <Form.Label>These NDIS Providers need to be created in Xero or removed from Active clients</Form.Label>
              </Form.Group>
            </Col>
          </Grid>                
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredxerondislinkfailures} columns={columns} pageSize={10} checkboxSelection={false} sortModel={[{ field: 'ndisplanmanagementprovider', sort: 'desc' }]} />
      </Paper>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

