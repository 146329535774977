import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Paper  } from '@material-ui/core';



export default function StatisticsProviderDay({providerStatisticsDay}) 
{
  
 
  const columns = [
    { field: 'id', headerName: 'id', width: 100,  hide: true },
    { field: 'statisticsSortDay', headerName: 'SortDay', width: 200, hide: true  },
    { field: 'statisticsDay', headerName: 'Day', width: 200  },
    { field: 'dailyProviderBookingsTotal', headerName: 'Bookings', width: 130},
    { field: 'dailyProviderSlotsTotal', headerName: 'Slots', width: 120},
    { field: 'dailyProviderFreeSlotsTotal', headerName: 'Free Slots (Total)', width: 200},
    { field: 'dailyProviderAnyFreeSlotsTotal', headerName: 'Free Slots (Any', width: 200},
    { field: 'dailyProviderExistingFreeSlotsTotal', headerName: 'Existing', width: 120},
    { field: 'dailyProviderSupervisionFreeSlotsTotal', headerName: 'Supervision)', width: 150}
  ];

  // public string weekProviderStart { get; set; }

  return (
    <>
      { providerStatisticsDay ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={providerStatisticsDay} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} sortModel={[{ field: 'statisticsSortDay', sort: 'asc' }]} />
        </Paper>
      : ('None')
      }
    </>
  )
}
