import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "react-bootstrap";
import { TextField } from '@material-ui/core';
import * as Admin from "../store/ducks/admin.duck";
import '../../_metronic/_assets/css/app2.css';
export default function InvoiceDetail(props) {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchMedipassCodes()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedipassClients()) }, [dispatch] )

  const medipassCodes = useSelector(state => state.admin.MedipassCodes)
  const medipassBookingDates = useSelector(state => state.admin.MedipassBookingDates)
  const providerClients = useSelector(state => state.admin.MedipassProviderClients)
  const editField = "inEdit";
  const [clientSelect, setClientSelect] = useState(props.clientSelect);
  // const [defaultClient, setDefaultClient] = useState();
  const [invoiceItems, setInvoiceItems] = useState(props.invoiceitems);
  const [lineNumber, setLineNumber] = useState(props.lineno);
  const [newEntry, setNewEntry] = useState(true);
  const [submitError,setSubmitError] = useState('');
  const editable = props.invoiceeditable;
  
  // Handle Client Autocmplete selection 
  const onChangeProviderClients = (newValue) => {
    // console.log(newValue)
    // Find descriptor 
    // const client = clientNames.find(p => p.text === event.value);
    // Check if something was found - as it is an autocomplete this fires relentlessly 
    if (newValue)
    {
      props.setClientID(newValue.id);
      dispatch(Admin.actions.fetchMedipassBookingDates(newValue.id));
    }
  };

  // Handle loading of invoice items from API 
  //
  if (!clientSelect && invoiceItems.length > 0) 
  {
    // Prevent altering the client name if there are invoice items 
    setClientSelect(true);
  }

  // Populate drop down of clients 
  const [clientNames, setClientNames] = useState(); 
  const [prevProviderClients, setPrevProviderClients] = useState();
  const [currentProviderClients, setCurrentProviderClients] = useState();  
  useEffect( () => {
    if ((JSON.stringify(providerClients) !== JSON.stringify(prevProviderClients)) && providerClients.length > 0)
    {
      setPrevProviderClients(providerClients)
      setCurrentProviderClients(providerClients)
      //
      let clientNames = []; 
      for (var i = 0, l = providerClients.length; i < l; i++) {
        // Add to array
        clientNames.push(
          {
            text : providerClients[i].clientName + ' (' + providerClients[i].suburb + ')',
            id : providerClients[i].id
          } 
        );
        // Check if pre-existing invoice client name is in the client names list as the clientnames API call only includes those with uninvoiced bookings thus an existing invoice may have a client without any bookings uninvoiced 
        // Confusing isn't it
        if (props.clientID !== 0)
        {
          const specificName = clientNames.find(p => p.id === props.clientID);
          // console.log(specificName);
          if (!specificName)
          {

            // Cannot find it - thus add it 
            clientNames.push(
            {
                text : props.clientName + ' (' +  props.clientSuburb + ')',
                id : props.clientID
            } 
            );
          }
        }
      }
      setClientNames(clientNames);
    }
  }, [prevProviderClients, currentProviderClients, providerClients, clientNames, clientSelect, props])

  // Populate drop down of Tyro Codes
  const [codes, setCodes] = useState(); 
  const [prevCodes, setPrevCodes] = useState();
  const [currentCodes, setCurrentCodes] = useState();  
  useEffect( () => {
    if ((JSON.stringify(medipassCodes) !== JSON.stringify(prevCodes)) && medipassCodes.length > 0)
    {
      // console.log('Loading Codes');
      setPrevCodes(medipassCodes)
      setCurrentCodes(medipassCodes)
      let newCodes = []; 
      // Define the defaults 
      newCodes.push(
        {
          text : '',
          value : 'Unselected'
        } 
      );
      for (var i = 0, l = medipassCodes.length; i < l; i++) {
        newCodes.push(
          {
            text : medipassCodes[i].code,
            value : medipassCodes[i].code
          } 
        );
      }
      setCodes(newCodes);
    }
  }, [prevCodes, currentCodes, medipassCodes, codes])
  
  // Populate drop down of Tyro Booking Dates
  const [bookingDates, setBookingDates] = useState(); 

  
  useEffect( () => {
    if (bookingDates)
    {
      // Check if unselected default has been added to bookingDates 
      const specificDate= bookingDates.find(p => p.value === 'Unselected');
      let bookingTotal = 0;
      if (specificDate)
      {
        bookingTotal = bookingDates.length - 1;  
      }
      else
      {
        bookingTotal = bookingDates.length;  
      }
      if (invoiceItems.length === (bookingTotal))
      {
        // If bookingDates = Invoiceitems then no more
        setNewEntry(false);
      }
      else
      {
        setNewEntry(true);
      }
    }
  }, [newEntry,invoiceItems,bookingDates])


  const [prevBookingDates, setPrevBookingDates] = useState();
  const [currentBookingDates, setCurrentBookingDates] = useState();  
  useEffect( () => {
    if ((JSON.stringify(medipassBookingDates) !== JSON.stringify(prevBookingDates)) && medipassBookingDates.length > 0)
    {
      setPrevBookingDates(medipassBookingDates);
      setCurrentBookingDates(medipassBookingDates);
      // console.log('Adding API Booking Dates');
      let newBookingDates = []; 
      // Define the defaults 
      newBookingDates.push(
        {
          text : '',
          value : 'Unselected'
        } 
      );
      for (var i = 0, l = medipassBookingDates.length; i < l; i++) {
        newBookingDates.push(
          {
            text : medipassBookingDates[i].bookingdate,
            value : medipassBookingDates[i].bookingdate,
            id : medipassBookingDates[i].id
          } 
        );
      }
      // So we have a problem when loading a previously saved invoice that has invoice items that will not have been retrieved by the API call as they are already marked as invoiced 
      // So lets try just adding them to the dropdown 
      for (var i2 = 0, l2 = invoiceItems.length; i2 < l2; i2++) {
        newBookingDates.push(
          {
            text : invoiceItems[i2].booking,
            value : invoiceItems[i2].booking,
            id : invoiceItems[i2].bookingid
          } 
        );
      }
      setBookingDates(newBookingDates);
    }
  }, [prevBookingDates, currentBookingDates, medipassBookingDates, bookingDates,invoiceItems])

  // Need to handle the situation where there are no uninvoiced bookings for a client hence the API call returns zero however I need to load the already invoiced ones - confusing isn't it
  useEffect( () => {
    if ((medipassBookingDates && medipassBookingDates.length === 0) && (invoiceItems && invoiceItems.length > 0) && !bookingDates)
    {
      // console.log('Adding Existing Booking Dates');
      let newBookingDates = []; 
      // So we have a problem when loading a previously saved invoice that has invoice items that will not have been retrieved by the API call as they are already marked as invoiced 
      // So lets try just adding them to the dropdown 
      for (var i = 0, l = invoiceItems.length; i < l; i++) {
        newBookingDates.push(
          {
            text : invoiceItems[i].booking,
            value : invoiceItems[i].booking,
            id : invoiceItems[i].bookingid
          } 
        );
      }
      setBookingDates(newBookingDates);
      // As this useeffect is running all there cannot be any uninvoiced bookings for this client thus prevent adding a new one 
      // setNewEntry(false);
    }
  }, [medipassBookingDates, bookingDates,invoiceItems])

  // Time for more useeffect - need to load bookingdates if client is set 
  const [prevClientID, setPrevClientID] = useState();
  useEffect( () => {
    if (props.clientID !== prevClientID && clientSelect)
    {
      // console.log('Attempting to load booking dates for existing client')
      // Load booking dates 
      setPrevClientID(props.clientID);
      // Load booking dates 
      dispatch(Admin.actions.fetchMedipassBookingDates(props.clientID));
    }
  }, [invoiceItems, medipassBookingDates, clientSelect, props.clientID, prevClientID, dispatch])


  // Handle Booking Dropdown 
  const BookingDropDownCell = props => {
    // 
    // console.log(bookingDates);
    const handleChangeBooking = e => {
        if (props.onChange) {
        props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.value,
        });
        }
    };
    //
    const {dataItem} = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];
    return <td>
      {dataItem.inEdit ? <DropDownList style={{
        width: "185px"
      }} onChange={handleChangeBooking} value={bookingDates.find(c => c.value === dataValue)} data={bookingDates} textField="text" /> : dataValue.toString()}
      </td>;
  };


  // Handle Tyro Code Dropdown 
  const MedipassCodeDropDownCell = props => {
    // 
    const handleChangeMedipassCode = e => {
        if (props.onChange) {
        props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.value,
        });
        }
    };
    //
    const {dataItem} = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];
    return <td>
      {dataItem.inEdit ? <DropDownList style={{
        width: "85px"
      }} onChange={handleChangeMedipassCode} value={codes.find(c => c.value === dataValue)} data={codes} textField="text" /> : dataValue.toString()}
      </td>;
  };

  // Handle row buttons 
  const MyCommandCell = props => {
    //
    const {dataItem} = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.lineno === undefined;
    return inEdit ? <td className="k-command-cell">
        <button className="k-button k-grid-save-command" onClick={() => isNewItem ? props.add(dataItem) : props.update(dataItem)}>
          {isNewItem ? "Add" : "Update"}
        </button>
      </td> : <td className="k-command-cell">
        <button className="k-primary k-button k-grid-edit-command" onClick={() => props.edit(dataItem)}>
          Edit
        </button>
        <button className="k-button k-grid-remove-command" onClick={() => window.confirm("Confirm deleting Line : " + dataItem.lineno) && props.remove(dataItem)}>
          Remove
        </button>
      </td>;
  };

  const remove = dataItem => {
    let index = invoiceItems.findIndex(record => record.lineno === dataItem.lineno);
    // State is nonsense - shallow copy ? If I wanted I shallow copy I will tell you 
    let newData = [...invoiceItems];
    newData.splice(index, 1);
    // Need special handling if the grid will be empty when this is deleted otherwise it is error city
    if (invoiceItems.length === 0)
    {
      setInvoiceItems([]);
      setLineNumber(0); 
    }
    else
    {
      setInvoiceItems(newData);
    }
  };

  const generateID = invoiceItems => invoiceItems.reduce((acc, current) => Math.max(acc, current.lineno), 0) + 1;

  const add = dataItem => {
    dataItem.inEdit = true;    
    dataItem.lineno = generateID(invoiceItems);
    dataItem.inEdit = false;
    let newData = invoiceItems;
    newData.unshift(dataItem);
    setInvoiceItems(newData);
  };

  const update = dataItem => {
    if(dataItem.booking !== 'Unselected' && dataItem.code !== 'Unselected'){
      setInvoiceItems(invoiceItems.map(item => item.lineno === dataItem.lineno ? { ...item,
        inEdit: false
      } : item));
    }
    // Need to remove the booking date that was inserted - reported to Telerik
    // let bookingIndex = bookingDates.findIndex(record => record.value === dataItem.booking);
    // if (bookingIndex != 0)
    // { 
    //   let newBookingData = bookingDates
    //   newBookingData.splice(bookingIndex, 1);
    //   setBookingDates([newBookingData]);
    // }
  };

  const discard = dataItem => {
    const newData = [...invoiceItems];
    newData.splice(0, 1);
    setInvoiceItems(newData);
  };

  const cancel = dataItem => {
    const originalItem = invoiceItems.find(p => p.lineno === dataItem.lineno);
    const newData = invoiceItems.map(item => item.lineno === originalItem.lineno ? originalItem : item);
    setInvoiceItems(newData);
  };

  const enterEdit = dataItem => {
    setInvoiceItems(invoiceItems.map(item => item.lineno === dataItem.lineno ? { ...item,
      inEdit: true
    } : item));
  };

  const itemChange = event => {
    let newData = invoiceItems;
    if (event.field === 'code')
    {
      if(event.value !== 'Unselected'){
        // If the code is being changed then update the description and cost too. 
        const specificCode = medipassCodes.find(p => p.code === event.value);
        // Need to limit the description to first 100 characters as they appear to be very, very long 
        const description = specificCode.description.substring(0,100) + '...';
        const cost = specificCode.cost;
        newData = invoiceItems.map(item => item.lineno === event.dataItem.lineno ? { ...item,
          'code' : event.value,
          'description' : description,
          'cost' : cost,
        } : item);
      }
    }
    else
    {
      if (event.field === 'booking')
      {
        // if the booking is being set need to do a few things 
        // firstly get the item from the list 
        const booking = bookingDates.find(p => p.value === event.value);
        //
        newData = invoiceItems.map(item => item.lineno === event.dataItem.lineno ? { ...item,
          'booking' : event.value,
          'bookingid' : booking.id,
        } : item);
      }
      else
      {
        // Otherwise - build a bridge and get over it 
        newData = invoiceItems.map(item => item.lineno === event.dataItem.lineno ? { ...item,
          [event.field || '']: event.value,
        } : item);
      }
    }
    // Update the main table 
    setInvoiceItems(newData);
  };

  const addNew = () => {
    setLineNumber(lineNumber + 1);
    let lineN = lineNumber + 1;
    // Default new line item     
    const newDataItem = {
      inEdit: true,
      status: 'UnSaved',
      lineno: lineN,
      booking: 'Unselected',
      code: 'Unselected',
      description: 'Unselected',
      cost: '0.00'
    };
    setInvoiceItems([...invoiceItems,newDataItem]);
  };

  const saveInvoice = (event) => {
    // console.log('Save Invoice');
    // Need to check if invoice items are fully completed - especially the code and the booking (yes one of the psychologists cannot remember to select the booking - repeatedly)
    // console.log(invoiceItems);
    const codeProblem = invoiceItems.find(i => i.code === 'Unselected');
    const bookingProblem = invoiceItems.find(i => i.booking === 'Unselected');
    if (!(codeProblem) && !(bookingProblem))
    {
      dispatch(Admin.actions.putMedipassInvoice({id: props.invoiceID, clientid: props.clientID, items: invoiceItems}));
      props.setClientID(0)
      setInvoiceItems([]);
      props.closeModal();
    }
    else
    {
      if (codeProblem)
      {
        setSubmitError('Invalid Medicare Code - Check rows below');
      }
      if (bookingProblem)
      {
        setSubmitError('Invalid Booking Selection - Check rows below');
      }
    }
  };
    
  const cancelModal = (event) => {
    props.setClientID(0)
    setInvoiceItems([]);
    props.closeModal();
  }
  // Define Row Level Actions 
  const CommandCell = props => <MyCommandCell {...props} edit={enterEdit} remove={remove} add={add} discard={discard} update={update} cancel={cancel} editField={editField} />;


  return(
    <div className="row example-wrapper">
      <div className="col-xs-12 col-sm-12 offset-sm-12 example-col">
        <div className="card">
          <div className="card-block" style={{ padding: 10 }}>
              <div className="mb-3" style={{ width: 440 }}>
                {/* <span>To begin, select a client. Start typing to search.</span> */}
                {!clientSelect ?
                <>
                  <span style={{ fontWeight: 600 }}>To begin, select a client:</span>
                  <Autocomplete
                    id="clientName"
                    variant="outlined"
                    disableClearable={true}
                    options={clientNames ? clientNames : []}
                    getOptionLabel={(option) => option.text }
                    value={clientNames && clientNames.find(cn => cn.id === props.clientID) ? clientNames.find(cn => cn.id === props.clientID) : null}
                    onChange={(event, newValue) => onChangeProviderClients(newValue)}
                    style={{ display: 'flex', wrap: 'nowrap', backgroundColor: "white", marginTop: 10, marginBottom: 30 }}
                    disabled={clientSelect}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        type="text"
                        label="Start typing to search..."
                        variant="outlined"
                        margin="dense"
                        name="clientNameSelector"
                        style={{backgroundColor: "", margin: 0, padding: 0}}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password'}}
                      />}
                    />
                  </>
                  : 
                  <>
                  <h5><span style={{ fontWeight: 200 }}>Client: </span> {clientNames && clientNames.find(cn => cn.id === props.clientID) && clientNames.find(cn => cn.id === props.clientID).text}</h5>
                  </>
                }
              </div>
              {props.clientID ? 
                <div style={{ marginTop: 10 }}>
                  <h5>Service Items:</h5>
                  <Grid
                    style={{height: "700px",}}
                    data={invoiceItems}
                    onItemChange={itemChange} 
                    dataItemKey={'lineno'}
                    editField={editField}                    
                  >
                    <GridToolbar>
                      {editable ?
                        <>
                          {newEntry ?
                            <Button variant="primary" size="sm" onClick={addNew}>
                              Add New
                            </Button>
                          :
                            null
                          }
                          <Button variant="success" size="sm" onClick={saveInvoice}>
                            Save
                          </Button>
                        </>
                      :
                        null
                      }
                      <Button variant="secondary" size="sm" onClick={cancelModal}>
                        Cancel
                      </Button>
                      <span style={{color:'red', fontSize: 14, fontWeight: 600}}> {submitError}</span>
                    </GridToolbar>
                    <GridColumn field="lineno" title="#" width="50px" editable={false} /> 
                    <GridColumn field="booking" title="Consultation" cell={BookingDropDownCell} width="210px" /> 
                    <GridColumn field="code" title="Code" cell={MedipassCodeDropDownCell} width="110px"/> 
                    <GridColumn field="description" title="Description" width="400px" editable={false}/>
                    <GridColumn field="cost" title="Cost" width="100px" className="kendo-grid-col-right" /> 
                    <GridColumn field="payment" title="Payment" width="100px" editable={false} className="kendo-grid-col-right" /> 
                    <GridColumn field="status" title="Status" width="100px" editable={false}/>
                    {editable ?
                      <GridColumn title="Actions" cell={CommandCell} width="140px" />
                    :
                      null
                    }
                  </Grid>
                </div>
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
  
