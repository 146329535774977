import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PlacesAutoComplete from "../../../components/AddressAutoComplete";

import * as Admin from "../../../store/ducks/admin.duck";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';
import  KTUtil  from "../../../../_metronic/_assets/js/util";

export default function MedicalPractices() {

  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState('Psychologist')
  var [show, setShow] = useState(false)

    
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'All' })) }, [dispatch] )
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.All)
  const filteredMedicalPractices = medicalPractices.filter(practice => ((practice.practiceType === filterType || filterType === 'All') && (practice.practicename.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0)) )

  function handleClose() {
    setShow(false);
  }  

  var [currentMedicalPracticeID, setcurrentMedicalPracticeID] = useState(null);
  var [currentID, setcurrentID] = useState(null);
  var [currentpracticename, setcurrentpracticename] = useState('')
  var [currentAbn, setcurrentAbn] = useState('');
  var [currentPhone, setcurrentPhone] = useState('');
  var [currentFax, setcurrentFax] = useState('');
  var [currentfulladdress, setcurrentfulladdress] = useState('');
  var [currentEmail, setcurrentEmail] = useState('');
  var [currentWebsite, setcurrentWebsite] = useState('');
  var [currentLogoURL, setcurrentLogoURL] = useState('');
  var [currentPracticeType, setcurrentPracticeType] = useState('');
  var [currentPostCode, setcurrentPostCode] = useState('');
  var [currentLongitude, setcurrentLongitude] = useState('');
  var [currentLatitude, setcurrentLatitude] = useState('');

  const loadPractice = (thisPractice) => {
    setcurrentMedicalPracticeID(thisPractice.id);
    setcurrentID(thisPractice.id);
    setcurrentpracticename(thisPractice.practicename);
    setcurrentAbn(thisPractice.abn);
    setcurrentPhone(thisPractice.phone);
    setcurrentFax(thisPractice.fax);
    setcurrentEmail(thisPractice.email);
    setcurrentfulladdress(thisPractice.address1);
    setcurrentWebsite(thisPractice.website);
    setcurrentLogoURL(thisPractice.logoURL);
    setcurrentPracticeType(thisPractice.practiceType);
    setcurrentLongitude(thisPractice.longitude);
    setcurrentLatitude(thisPractice.latitude);
    setcurrentPostCode(thisPractice.postCode);
    // Load Form
    setShow(true);
  }

  const loadNew = () => {
    setcurrentMedicalPracticeID(0);
    setcurrentID(0);
    setcurrentpracticename('');
    setcurrentAbn('');
    setcurrentPhone('');
    setcurrentFax('');
    setcurrentEmail('');
    setcurrentfulladdress('');
    setcurrentWebsite('');
    setcurrentLogoURL('');
    setcurrentPostCode('');
    setcurrentLongitude('');
    setcurrentLatitude('');
    setcurrentPracticeType(filterType);
    setShow(true);
  }

  function putPractice() {
    if (process.env.REACT_APP_DEBUG) 
    {
      console.log ('Saving to FormData');
    }
    // Move from useState to FormData 
    const formData = new FormData();
    // Add fields 
    formData.append('id',currentMedicalPracticeID);
    formData.append('abn',currentAbn);
    formData.append('practicename',currentpracticename);
    formData.append('phone',currentPhone);
    formData.append('address1',currentfulladdress);
    formData.append('email',currentEmail);
    formData.append('website',currentWebsite);
    formData.append('fax',currentFax);
    formData.append('logoURL',currentLogoURL);
    formData.append('practiceType',currentPracticeType);
    formData.append('postcode',currentPostCode);
    formData.append('longitude',currentLongitude);
    formData.append('latitude',currentLatitude);
    // Put update 
    process.env.REACT_APP_DEBUG && console.log ('Putting (Update)');
    dispatch(Admin.actions.putMedicalPractice({formData}));
    // Close form
    setShow(false);
  }

  if (KTUtil.isEmpty(medicalPractices))
  {
    return(null);
  }  

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'practicename', headerName: 'Practice Name', width: 400,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadPractice(medicalPractices.find(practice => practice.id === params.row.id))} className="cell-Link">
          {params.value}
        </div>
      </strong>
    ), },
    { field: 'address1', headerName: 'Address', width: 400 },
    { field: 'createdOn', type: 'date', headerName: 'Created', width: 100 },
  
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }

  return (
    <>
    <h4>
      Medical Practices
    </h4>

    <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>

          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <Grid container justifyContent="space-between" style={{ padding: 0 }}>
              <Grid item md={6} style={{ paddingRight: 10 }}>              
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control                  
                      type="text"
                      value={searchTerm}
                      onChange={(event) => handleSearch(event)}
                      autoComplete='OFF'
                    />
                </InputGroup>
              </Grid>
              <Grid item md={6}>  
                <Form.Control as="select" size="sm" defaultValue={filterType} onChange={(event) => setFilterType(event.target.value)}>
                  <option value="All">All</option>
                  <option value="Psychologist">Psychologist</option>
                  <option value="GeneralPractitioner">General Practitioner</option>
                </Form.Control>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadNew()}>
              Add Practice
            </Button>
          </Grid>

        </Grid>
      </Paper>

    <Paper style={{ height: 700 }}>
      <DataGrid rows={filteredMedicalPractices} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'practicename', sort: 'asc' }]} />
    </Paper>

    <Modal show={show} onHide={handleClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Edit Practice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <Form onSubmit={putPractice} autoComplete="OFF">
            <Row>
              <Col md="6">
                <Form.Group >
                <Form.Label>Clinic Name</Form.Label>
                  <Form.Control type="text" name="practicename" defaultValue={currentpracticename} onBlur={(event) => setcurrentpracticename(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                <Form.Label>ABN</Form.Label>
                  <Form.Control type="text" name="abn" defaultValue={currentAbn} onBlur={(event) => setcurrentAbn(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Contact Phone</Form.Label>
                  <Form.Control type="text" name="phone" defaultValue={currentPhone} onBlur={(event) => setcurrentPhone(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group >
                  <Form.Label>Clinic Address</Form.Label>
                      <PlacesAutoComplete 
                        currentID={currentID}
                        currentfulladdress={currentfulladdress}
                        setcurrentfulladdress = {setcurrentfulladdress}
                        setcurrentLatitude = {setcurrentLatitude} 
                        setcurrentLongitude = {setcurrentLongitude}
                        setcurrentPostCode = {setcurrentPostCode}
                      />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Clinic Fax</Form.Label>
                  <Form.Control type="text" name="fax" defaultValue={currentFax} onBlur={(event) => setcurrentFax(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Clinic Email</Form.Label>
                  <Form.Control type="text" name="phone" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group >
                  <Form.Label>Website</Form.Label>
                  <Form.Control type="text" name="website" defaultValue={currentWebsite} onBlur={(event) => setcurrentWebsite(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Logo</Form.Label>
                  <Form.Control type="text" name="logoURL" defaultValue={currentLogoURL} onBlur={(event) => setcurrentLogoURL(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Type</Form.Label>
                  <Form.Control as="select" defaultValue={currentPracticeType} onBlur={(event) => setcurrentPracticeType(event.target.value)}>
                    <option value="Psychologist">Psychologist</option>
                    <option value="General Practitioner">General Practitioner</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
  
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putPractice}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>
        
        <ToastContainer
          position="bottom-right"
          transition={Flip}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

