import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Paper, Grid, MenuItem, Select } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer, Flip } from "react-toastify";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';

export default function Referrers() {

  const dispatch = useDispatch()

  useEffect( () => { dispatch(Admin.actions.fetchReferrers()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )

  const userTZ = useSelector(state => state.auth.userTZ)
  const timeZone = useSelector(state => state.reference.TimeZone)
  const filters = useSelector(state => state.admin.Filters)
  const referrers = useSelector(state =>  state.admin.Referrers)
  const filteredreferrers = referrers.filter(referrer => (referrer.fullname.toLowerCase().search(filters.referrersTerm.toLowerCase()) > -1 || filters.referrersTerm.length === 0))

  var [showReferrer, setShowReferrer] = useState(false)
  var [referrerError, setReferrerError] = useState('')

  function handleReferrerClose() {
    setShowReferrer(false);
  }  

  function loadNewReferrer() {
    setcurrentModalHeader('Adding Referrer');
    setcurrentPasswordHeader('Please enter password for new login');
    setcurrentID(0);
    setcurrentFirstName('');
    setcurrentSurname('');
    setcurrentEmail('');
    setcurrentMobile('');
    setcurrentOrganisation('');
    setcurrentStatus('Active');
    setReferrerError('');
    setShowReferrer(true);
    setcurrentNewPassword(null);
    setcurrentNewPasswordConfirmation(null);
  }

  const loadExistingReferrer = (thisReferrer) => {
    setcurrentModalHeader('Updating ' + thisReferrer.firstname + ' ' + thisReferrer.surname);
    setcurrentPasswordHeader('Password Update (Leave blank unless resetting)');
    setcurrentID(thisReferrer.id);
    setcurrentFirstName(thisReferrer.firstname);
    setcurrentSurname(thisReferrer.surname);
    setcurrentEmail(thisReferrer.email);
    setcurrentMobile(thisReferrer.mobilephone);
    setcurrentOrganisation(thisReferrer.organisationname);
    setcurrentStatus(thisReferrer.status);
    setcurrentTimeZone(thisReferrer.timezone);
    setcurrentNewPassword(null);
    setcurrentNewPasswordConfirmation(null);
    setReferrerError('');
    setShowReferrer(true);
  }

  function postReferrer() {
    let validation = true;
    // Check if passwords are the same
    if (currentNewPassword)
    {
      // Check passwords are the same
      if (currentNewPassword !== currentNewPasswordConfirmation)
      {
        setReferrerError('Passwords do not match')
        validation = false;
      } 
    }
    if (currentFirstName === ''|| currentSurname === '' || currentEmail === '' || currentMobile === '' || currentOrganisation === '') 
    { 
      setReferrerError('You must complete all fields.')
      validation = false;
    } 
    //
    // Submit if no errors 
    if (validation)
    {
      setReferrerError('')
      var payload = {
        id: currentID,
        firstname: currentFirstName,
        surname: currentSurname,
        email: currentEmail,
        mobilephone: currentMobile,
        organisationname: currentOrganisation,
        status: currentStatus,
        timezone: currentTimeZone,
        newpassword: currentNewPassword,
        newpasswordconfirmation: currentNewPasswordConfirmation
      }
      dispatch(Admin.actions.putReferrer(payload));
      setShowReferrer(false)
    }
  }

  var [currentID,setcurrentID] = useState();
  var [currentFirstName,setcurrentFirstName] = useState();
  var [currentSurname,setcurrentSurname] = useState();
  var [currentEmail,setcurrentEmail] = useState();
  var [currentMobile,setcurrentMobile] = useState();
  var [currentOrganisation,setcurrentOrganisation] = useState(); 
  var [currentNewPasswordConfirmation,setcurrentNewPasswordConfirmation] = useState();
  var [currentNewPassword,setcurrentNewPassword] = useState();
  var [currentStatus,setcurrentStatus] = useState(); 
  var [currentModalHeader,setcurrentModalHeader] = useState(); 
  var [currentPasswordHeader,setcurrentPasswordHeader] = useState();
  var [currentTimeZone, setcurrentTimeZone] = useState(userTZ ? userTZ : 'AUS Eastern Standard Time');

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', width: 190,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadExistingReferrer(referrers.find(referrer => referrer.id === params.row.id))} className="cell-Link">
          {params.value}
        </div>
      </strong>
    ),  },
    { field: 'email', headerName: 'Email', width: 220,
      renderCell: (params) => (
        <CopyToClipboard text={params.row.email}>
          <span  className="cell-Link" style={{ fontWeight: 500}}>{params.row.email}</span>
        </CopyToClipboard>
      ) 
    },
    { field: 'mobilephone', headerName: 'Phone', width: 110 },
    { field: 'organisationname', headerName: 'Organisation', width: 150 },
    { field: 'status', headerName: 'Status', width: 115 }    
  ];


  return (
    <>
      <h4>
        Referrers
      </h4>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
              <Grid container>
                <Grid item>             
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control                  
                        type="text"
                        value={filters.referrersTerm}
                        onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'referrersTerm', filterValue: event.target.value }))}
                        autoComplete='OFF'
                        style={{ maxWidth: 170, height: 35, backgroundColor: filters.referrersTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                      />
                    <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'referrersTerm', filterValue: '' }))}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: filters.referrersTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Grid>
              </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadNewReferrer()} style={{ marginRight: 20 }}>
              Add Referrer
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredreferrers} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'fullname', sort: 'desc' }]}  />
      </Paper>

      <Modal show={showReferrer} onHide={handleReferrerClose} dialogClassName="invite-modal">
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{currentModalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal">
          {referrerError !== '' ?
          <Alert severity="error" style={{ marginBottom: 14 }}>
            {referrerError}
          </Alert>
          :
          null }
          <h4>Personal Details</h4>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" name="profileFirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text"  name="profileSurname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" name="profileEmail" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Mobile Phone Number</Form.Label>
                <Form.Control type="text" name="profileMobile" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Timezone</Form.Label>
                <Select
                  variant="outlined"
                  margin="dense"
                  value={currentTimeZone}
                  onChange={(event) => setcurrentTimeZone(event.target.value)}
                  className="select-edit"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {timeZone && timeZone.map((row, index) => (
                    <MenuItem key={`tz_${index}`} value={row.systemName}>{row.name}</MenuItem>
                  ))}
                  <MenuItem value={undefined}></MenuItem>            
                </Select>
              </Form.Group>
            </Col>
          </Row>
          <h4>Organisation</h4>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="profileOrgnisationName" defaultValue={currentOrganisation} onBlur={(event) => setcurrentOrganisation(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <h4>{currentPasswordHeader}</h4>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" name="newpassword" defaultValue={currentNewPassword} onBlur={(event) => setcurrentNewPassword(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>New Confirm Password</Form.Label>
                <Form.Control type="password" name="newpasswordconfirmation" defaultValue={currentNewPasswordConfirmation} onBlur={(event) => setcurrentNewPasswordConfirmation(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setcurrentStatus(event.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Archived">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleReferrerClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postReferrer}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>
        
        <ToastContainer
          position="bottom-right"
          transition={Flip}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

