import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutoComplete from "../../../components/AddressAutoComplete";
import * as Reference from "../../../store/ducks/reference.duck";
import * as Admin from "../../../store/ducks/admin.duck";
import { Button, Form, Row, Col, } from "react-bootstrap";
import { Paper, TextField, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import { ToastContainer, Flip } from "react-toastify";
import { differenceInYears } from "date-fns";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../../_metronic/_assets/css/app2.css';

export default function ProfileClient() {

  const dispatch = useDispatch()
  const clients = useSelector(state => state.admin.Clients);
  const thisClient = useSelector(state => state.admin.Client);
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)  
  const PsychologyAreasOfInterest = areasOfInterestReference.filter(area => area.providertype === 'Psychologist') 
  const FinancialAreasOfInterest = areasOfInterestReference.filter(area => area.providertype === 'Financial')
  const ProvisionalAreasOfInterest = areasOfInterestReference.filter(area => (area.issue === 'Anxiety' || area.issue === 'Depression' || area.issue === 'Managing Stress' || area.issue === 'Grief & Loss'))
  const validateMedicareNumber = require('validate-medicare-number');
  
  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { clients.length > 0 && dispatch(Admin.actions.fetchClient( { clientID: clients[0].id, type: clients[0].clienttype } )) }, [dispatch, clients] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  
  // now init the fields with values so that Formik can manage the touched state
  const clientInit = {
    firstname: '', 
    surname: '', 
    preferredname: '',
    email: '', 
    mobilephone: '',
    clientFundingTypeID: 1,
    dob: undefined,
    gender: '',
    occupation: '',
    fulladdress: '',
    latitude: '',
    longitude: '',
    postcode: '',
    timezone: 'AUS Eastern Standard Time',
    clientAreasOfInterest: [],
    hopeToGetFromTreatment: '',
    emergencycontactname1: '',
    emergencycontactphone1: '',
    emergencycontactrelation1: '',
    emergencycontactname2: '',
    emergencycontactphone2: '',
    emergencycontactrelation2: '',
    profilePassword: '',
    profilePasswordConfirmation: '',
    medicareno: '',
    medicarereferenceno: '',
    medicareexpirydate: '',
    appointmenttype: '0',
    providertype: '',
    pronouns: '',
    indigenous: 'N',
    id : 0
  }

  

  // get the invite (if any) from state and merge with the initialised fields
  var client = {...clientInit, ...thisClient};

  var aoi = areasOfInterestReference.filter(( {issue} ) => client.clientAreasOfInterest.includes(issue));
  // And merge those back into the primary record
  client = {...client, 
    clientAreasOfInterest: aoi,
  }

  function submitForm(data){
    // add in gp and practice blanks for now, ui and real data to come later. The API will skip inserting or updating these.
    const thisMedicalPractice = { 
      id: 0,
      practicename: ''
    }
    const thisMedicalPractitioner = { 
      id: 0,
      firstName: ''
    }
    data = {...data,
      medicalPractice: thisMedicalPractice,
      medicalPractitioner: thisMedicalPractitioner,
      clientAreasOfInterest: data['clientAreasOfInterest'].map(aoi => aoi.issue).join(","),
    }
    dispatch(Admin.actions.putClient(data))
  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .required("Surname is required"),
    preferredname: Yup.string()
    .max(200,"Too Long"),
    dob:  Yup.string()
    .when(['providertype'],
    {
      is: (providertype) => (providertype !== 'Supervision'),
      then: Yup.string().required("Date of Birth is required")
                        .test("dob", "Please enter a valid date", function (value) {
                            return ((differenceInYears(new Date(), new Date(value)) >= 1) && (differenceInYears(new Date(), new Date(value)) <= 100))  
                        }),
    }),
    pronouns: Yup.string()
    .max(20,"Too Long"),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    mobilephone:  Yup.string()
    .min(2,"Too Short")
    .max(12,"Too Long")
    .required("Phone Number is required"),
    fulladdress:  Yup.string()
    .min(4,"Too Short")
    .max(80,"Too Long")
    .required("Address is required"),
    profilePassword:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('profilePasswordConfirmation'), null], 'Passwords must match'),
    areasOfInterest:  Yup.array()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Financial',
      then: Yup.array().min(1, "Please select at least one area of interest"),
    }),    
    emergencycontactname1:  Yup.string()
    .nullable()
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact is required"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Provisional',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact is required"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Social',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact is required"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact is required"),
      })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact is required"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact is required"),
      }),
  emergencycontactrelation1:  Yup.string()
    .nullable()
    .when(['providertype'],
    {
      is: (providerType) => (providerType === 'Psychologist'),
      then: Yup.string().min(6,"Too Short")
                        .max(50,"Too Long")
                        .required("Emergency Contact Relationship is required"),
    })
    .when(['providertype'],
      {
        is: (providerType) => (providerType === 'Clinical'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })    
    .when(['providertype'],
      {
        is: (providerType) => (providerType === 'NDIS'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })    
    .when(['providertype'],
      {
        is: (providerType) => (providerType === 'Provisional'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })    
    .when(['providertype'],
    {
      is: (providerType) => (providerType === 'Social'),
      then: Yup.string().min(6,"Too Short")
                        .max(50,"Too Long")
                        .required("Emergency Contact Relationship is required"),
    })
    .when(['providertype'],
      {
        is: (providerType) => (providerType === 'Nurse'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })
    .when(['providertype'],
    {
      is: (providerType) => (providerType === 'Occupational'),
      then: Yup.string().min(6,"Too Short")
                        .max(50,"Too Long")
                        .required("Emergency Contact Relationship is required"),
    }),    
  emergencycontactphone1:  Yup.string()
    .nullable()
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact phone is required"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Provisional',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact phone is required"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Social',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact phone is required"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact phone is required"),
      })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().min(6,"Too Short")
                        .max(20,"Too Long")
                        .required("Emergency Contact phone is required"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact phone is required"),
      }),    
  emergencycontactname2:  Yup.string()
    .nullable()
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Provisional',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Social',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().nullable()
                          .min(6,"Too Short")
                          .max(20,"Too Long"),
      })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().nullable()
                          .min(6,"Too Short")
                          .max(20,"Too Long"),
      }),  
  emergencycontactrelation2:  Yup.string()
    .nullable()
    .when(['providerType'],
    {
      is: (providerType) => (providerType === 'Psychologist'),
      then: Yup.string().min(6,"Too Short")
                        .max(50,"Too Long"),
    })
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Clinical'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    })    
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'NDIS'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    })    
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Provisional'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    })
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Social'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    })    
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Nurse'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    })    
    .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Occupational'),
        then: Yup.string().min(6,"Too Short")
                          .max(50,"Too Long"),
    }),                    
  emergencycontactphone2:  Yup.string().nullable()
    .nullable()
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Provisional',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Social',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().nullable()
                          .min(6,"Too Short")
                          .max(20,"Too Long"),
      })
    .when(['providertype'],
    {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().nullable()
                        .min(6,"Too Short")
                        .max(20,"Too Long"),
    })
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().nullable()
                          .min(6,"Too Short")
                          .max(20,"Too Long"),
    }),    
    profilePasswordConfirmation:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('profilePassword'), null], 'Passwords must match'),
    medicareno:  Yup.string()
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(10,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Card Number is required")
                          .test('cardValid','A Valid Medicare Number is required',(value) => {
                            if (value)
                            {
                              return validateMedicareNumber(value)
                            }
                            else
                            {
                              return false;
                            }
                          }),
      })
      .when(['providertype'],
        {
          is: (providertype) => providertype === 'Clinical',
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        })    
        .when(['providertype'],
        {
          is: (providertype) => providertype === 'Social',
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        })    
        .when(['providertype'],
          {
            is: (providertype) => providertype === 'Nurse',
            then: Yup.string().min(10,"Too Short")
                              .max(10,"Too Long")
                              .required("Medicare Card Number is required")
                              .test('cardValid','A Valid Medicare Number is required',(value) => {
                                if (value)
                                {
                                  return validateMedicareNumber(value)
                                }
                                else
                                {
                                  return false;
                                }
                              }),
          })    
        .when(['providertype'],
        {
          is: (providertype) => providertype === 'Occupational',
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        })    
      .when(['providertype'],
        {
          is: (providertype) => providertype === 'GP',
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        }),            
    medicarereferenceno:  Yup.number()
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Social',
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providertype'],
        {
          is: (providertype) => providertype === 'Nurse',
          then: Yup.number().typeError('you must specify a number')
                            .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                            .required("Medicare Reference Number is required"),
        })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      }),
    medicareexpirydate:  Yup.string()
    .when(['providertype'],
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Social',
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providertype'],
        {
          is: (providertype) => providertype === 'Nurse',
          then: Yup.string().min(5,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Expiry is required"),
        })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providertype'],
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      }),
  });

  return (
    <>
      {client && client.id > 0 ?
      <Formik
        enableReinitialize={true}
        initialValues={client}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting } = props;
          const change = (name,e) => { e.persist();  handleChange(e); };
          return (
            <form noValidate={true} autoComplete="off"  onSubmit={!isSubmitting ? handleSubmit : undefined}>

      <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
        <Row>
          <Col md="12">
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item md={6} xs={12}>
                <h3>Client Profile</h3>
              </Grid>
              <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                * indicates required fields
              </Grid>
            </Grid>
          </Col>
        </Row>
        <Row>
          <Col md="12">                  
            {values.message ? (
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                {values.message}
              </Alert>) 
              : null
            }
          </Col>
        </Row>
        <hr style={{ marginTop: 4 }}></hr>
        <Row>
          <Col md="3">
            <Form.Group >
              <Form.Label>First Name *</Form.Label>
              <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
              <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group >
              <Form.Label>Preferred Name</Form.Label>
              <Form.Control type="text" name="preferredname" value={values.preferredname} onChange={change.bind(null, "preferredname")} autoComplete='new-password' isInvalid={touched.preferredname && !!errors.preferredname} isValid={touched.preferredname && !errors.preferredname}/>
              <Form.Control.Feedback type="invalid">{errors.preferredname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group >
              <Form.Label>Surname *</Form.Label>
              <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
              <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          { client.providertype !== 'Supervision'? 
            <Col md="2">
              <Form.Group >
                <Form.Label>Gender</Form.Label>
                <Form.Control as="select" name="gender" value={values.gender} onChange={change.bind(null, "gender")} >
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          :
            null
          }
          <Col md="2">
            <Form.Group >
              <Form.Label>Pronouns</Form.Label>
              <Form.Control type="text"  name="pronouns" value={values.pronouns} onChange={change.bind(null, "pronouns")}  autoComplete='new-password' isInvalid={touched.pronouns && !!errors.pronouns} isValid={touched.pronouns && !errors.pronouns}/>
              <Form.Control.Feedback type="invalid">{errors.pronouns}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          { client.providertype !== 'Supervision'? 
            <Col md="2">
              <Form.Group >
                <Form.Label>Date of Birth *</Form.Label>
                <Form.Control type="date" name="dob" value={values.dob} onChange={change.bind(null, "dob")} isInvalid={touched.dob && !!errors.dob} isValid={touched.dob && !errors.dob} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          :
            null
          }
        </Row>
            <Row>
              <Col md="6">
                <Form.Group >
                  <Form.Label>Email *</Form.Label>
                  <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Phone Number *</Form.Label>
                  <Form.Control type="text" name="mobilephone" value={values.mobilephone} onChange={change.bind(null, "mobilephone")} isInvalid={touched.mobilephone && !!errors.mobilephone} isValid={touched.mobilephone && !errors.mobilephone} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.mobilephone}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Occupation</Form.Label>
                  <Form.Control type="text" name="occupation" value={values.occupation} onChange={change.bind(null, "occupation")} autoComplete='new-password' />
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
              <Col md="6">
                <Form.Group style={{width: "100%"}}>
                  <Form.Label>Home Address (start typing to search) *</Form.Label>
                  <PlacesAutoComplete 
                    currentID={client.id}
                    currentfulladdress={client.fulladdress}
                    setcurrentfulladdress = {(value) => setFieldValue("fulladdress", value)}
                    setcurrentLatitude = {(value) => setFieldValue("latitude", value)} 
                    setcurrentLongitude = {(value) => setFieldValue("longitude", value)}
                    setcurrentPostCode = {(value) => setFieldValue("postcode", value)}
                    isInvalid={touched.fulladdress && !!errors.fulladdress} 
                    isValid={touched.fulladdress && !errors.fulladdress} 
                  />
                  {touched.fulladdress && !!errors.fulladdress ? (<p className='error-help'>{errors.fulladdress}</p>) : ''}
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                  <div style={{ fontSize: 10, fontWeight: 400, paddingTop: 4, paddingLeft: 4 }}>(Leave blank unless changing)</div>
                  <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Confirm Password </Form.Label>
                  <Form.Control type="password" name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Group >
                  <Form.Label>Do you identify as Aboriginal and/or Torres Strait islander</Form.Label>
                  <Form.Control as="select" name="indigenous" value={values.indigenous} onChange={change.bind(null, "indigenous")} >
                    <option value="N">No</option>
                    <option value="Y">Yes</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {client.providertype !== 'GP' && client.providertype !== 'Social' && client.providertype !== 'Occupational' &&  client.providertype !== 'Supervision' &&  client.providertype !== 'Nurse' ? 
              <>
                <Row>
                  <Col md="12">
                    <Form.Group >
                      <Form.Label>Areas of Interest  (Select all that apply) *</Form.Label>
                      <Autocomplete
                        id="clientAreasOfInterest"
                        variant="outlined"
                        multiple
                        options={(client && (client.providertype === 'Financial')) ? FinancialAreasOfInterest : (client && (client.providertype === 'Provisional')) ? ProvisionalAreasOfInterest : PsychologyAreasOfInterest }
                        getOptionLabel={(option) => option.issue }
                        value={values.clientAreasOfInterest}
                        // onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                        onChange={(e, newValue) => {
                          setFieldValue("clientAreasOfInterest", newValue);
                        }}
                        style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "", }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            type="text"
                            variant="outlined"
                            margin="dense"
                            name="AOISelector"
                            style={{backgroundColor: "white", margin: 0, padding: 0}}
                            error={touched.clientAreasOfInterest && !!errors.clientAreasOfInterest} 
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'}}
                          />}
                      />
                      {touched.clientAreasOfInterest && !!errors.clientAreasOfInterest ? (<p className='error-help'>{errors.clientAreasOfInterest}</p>) : ''}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            :
              null
            }
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>What are you hoping to get out of your sessions?</Form.Label>
                  <Form.Control as="textarea" rows={4} type="text" name="hopeToGetFromTreatment" value={values.hopeToGetFromTreatment}  onChange={change.bind(null, "hopeToGetFromTreatment")} autoComplete='new-password' />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            </Row>
            {client.providertype !== 'Financial' &&  client.providertype !== 'Supervision'  ? 
              <>
                <Row>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Emergency Contact 1 *</Form.Label>
                      <Form.Control type="text" name="emergencycontactname1" value={values.emergencycontactname1 || ''} onChange={change.bind(null, "emergencycontactname1")} isInvalid={touched.emergencycontactname1 && !!errors.emergencycontactname1} isValid={touched.emergencycontactname1 && !errors.emergencycontactname1} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactname1}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Relationship*</Form.Label>
                      <Form.Control type="text" name="emergencycontactrelation1" value={values.emergencycontactrelation1 || ''} onChange={change.bind(null, "emergencycontactrelation1")} isInvalid={touched.emergencycontactrelation1 && !!errors.emergencycontactrelation1} isValid={touched.emergencycontactrelation1 && !errors.emergencycontactrelation1} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactrelation1}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Phone *</Form.Label>
                      <Form.Control type="text"  name="emergencycontactphone1" value={values.emergencycontactphone1 || ''} onChange={change.bind(null, "emergencycontactphone1")} isInvalid={touched.emergencycontactphone1 && !!errors.emergencycontactphone1} isValid={touched.emergencycontactphone1 && !errors.emergencycontactphone1} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactphone1}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Emergency Contact 2</Form.Label>
                      <Form.Control type="text" name="emergencycontactname2" value={values.emergencycontactname2 || ''} onChange={change.bind(null, "emergencycontactname2")} isInvalid={touched.emergencycontactrelation2 && !!errors.emergencycontactrelation2} isValid={touched.emergencycontactrelation2 && !errors.emergencycontactrelation2} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactname2}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Relationship</Form.Label>
                      <Form.Control type="text" name="emergencycontactrelation2" value={values.emergencycontactrelation2 || ''} onChange={change.bind(null, "emergencycontactrelation2")} isInvalid={touched.emergencycontactrelation2 && !!errors.emergencycontactrelation2} isValid={touched.emergencycontactrelation2 && !errors.emergencycontactrelation2} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactrelation2}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="text"  name="emergencycontactphone2" value={values.emergencycontactphone2 || ''}  onChange={change.bind(null, "emergencycontactphone2")} isInvalid={touched.emergencycontactphone2 && !!errors.emergencycontactphone2} isValid={touched.emergencycontactphone2 && !errors.emergencycontactphone2} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.emergencycontactphone2}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            :
              null
            }
            <Row>
              {client.providertype !== 'Financial' &&  client.providertype !== 'Supervision'  ? 
                <>
                  <Col md="3">
                    <Form.Group >
                      {client.providerType && client.providerType === 'Provisional' ?
                        <Form.Label>Medicare Card Number (Optional)</Form.Label>
                     :
                        <Form.Label>Medicare Card Number</Form.Label>
                      }
                      <Form.Control type="text" name="medicareno" value={values.medicareno || ''} onChange={change.bind(null, "medicareno")} isInvalid={touched.medicareno && !!errors.medicareno} isValid={touched.medicareno && !errors.medicareno} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.medicareno}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      {client.providerType && client.providerType === 'Provisional' ?
                        <Form.Label>Order on Card (Optional)</Form.Label>
                      :
                        <Form.Label>Order on Card</Form.Label>
                      }
                      <Form.Control type="text"  name="medicarereferenceno" value={values.medicarereferenceno || ''} onChange={change.bind(null, "medicarereferenceno")} isInvalid={touched.medicarereferenceno && !!errors.medicarereferenceno} isValid={touched.medicarereferenceno && !errors.medicarereferenceno} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.medicarereferenceno}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      {client.providerType && client.providerType === 'Provisional' ?
                        <Form.Label>Medicare Expiry Date (MM/YY) (Optional)</Form.Label>
                      :
                        <Form.Label>Medicare Expiry Date (MM/YY)</Form.Label>
                      }
                      <Form.Control type="text"  name="medicareexpirydate" value={values.medicareexpirydate || ''} onChange={change.bind(null, "medicareexpirydate")} isInvalid={touched.medicareexpirydate && !!errors.medicareexpirydate} isValid={touched.medicareexpirydate && !errors.medicareexpirydate} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.medicareexpirydate}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
              :
                null
              }
              {/* {client.providertype !== 'GP' ? 
                <>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Appointment Type</Form.Label>
                      <Form.Control as="select" name="appointmenttype" value={values.appointmenttype} onChange={change.bind(null, "appointmenttype")} >
                        <option value='0'>Video Call</option>
                        <option value='1'>Phone Call</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>   
                </>
              :
                null
              } */}
              <Col md="12">
                <p><strong>Note</strong> Sessions are done via video call. If you need your session to be done via phone call instead please call 0493 201 005 or 0493 432 144.</p>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item md={3} xs={6}>
                    
                  </Grid>
                  <Grid item md={1} xs={6} style={{ textAlign: "right"}}>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </Paper>
      </form>
      )
      }}
    </Formik>
      : 'loading...' }
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

