import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import * as Admin from "../../../store/ducks/admin.duck";
import { Grid, Paper } from '@material-ui/core';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export default function Contracts() {

  const [searchTerm, setSearchTerm] = useState('')
  const contracts = useSelector(state => state.admin.Contracts)
  const filteredContracts = contracts.filter(contract => contract.name.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchContracts()) }, [dispatch] )


  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }


  var [show, setShow] = useState(false);
  var [currentContractID,setCurrentContractID] = useState();
  var [currentName,setCurrentName] = useState();
  var [currentOrganisationName,setCurrentOrganisationName] = useState();
  var [currentLocations,setCurrentLocations] = useState();
  var [currentStatus,setCurrentStatus] = useState();
  var [currentEmail,setCurrentEmail] = useState();
  var [currentCost,setCurrentCost] = useState();
  var [currentProviderFee,setCurrentProviderFee] = useState();
  var [currentRHCFee,setCurrentRHCFee] = useState();
  var [currentType,setCurrentType] = useState();
  var [currentItemCode,setCurrentItemCode] = useState(); 
  var [currentContractToken,setCurrentContractToken] = useState();
  var [currentNewClientManagedByRHC, setCurrentNewClientManagedByRHC] = useState('0')
  var [currentContractColour,setCurrentContractColour] = useState('')


  const loadContractDetail = (thisContract) => {
    setCurrentContractID(thisContract.id)
    setCurrentName(thisContract.name)
    setCurrentOrganisationName(thisContract.organisationname)
    setCurrentLocations(thisContract.locations)
    setCurrentStatus(thisContract.status)
    setCurrentEmail(thisContract.contactemail)
    setCurrentCost(thisContract.cost)
    setCurrentProviderFee(thisContract.providerfee)
    setCurrentRHCFee(thisContract.rhcfee)
    setCurrentType(thisContract.type)
    setCurrentItemCode(thisContract.itemcode)
    setCurrentContractToken(thisContract.contracttoken)
    setCurrentNewClientManagedByRHC(thisContract.newclientmanagedbyrhc)
    setCurrentContractColour(thisContract.colour)
    setShow(true)
  }

  const createNewContract = (thisContract) => {
    setCurrentContractID(0)
    setCurrentName()
    setCurrentOrganisationName()
    setCurrentLocations()
    setCurrentEmail()
    setCurrentStatus('Active')
    setCurrentCost()
    setCurrentProviderFee()
    setCurrentRHCFee()
    setCurrentType('Contract')
    setCurrentItemCode()
    setCurrentContractToken(uuidv4())
    setCurrentNewClientManagedByRHC(0)
    setCurrentContractColour('#00B3BE')
    setShow(true)
  }

  function putContract() {
    // Create payload to send to API 
    var payload = {
        id : currentContractID,
        name : currentName,
        organisationname : currentOrganisationName,
        locations : currentLocations,
        status : currentStatus,
        email : currentEmail,
        cost : currentCost,
        providerfee: currentProviderFee,
        rhcfee: currentRHCFee,
        type : currentType,
        itemcode: currentItemCode,
        contracttoken : currentContractToken,
        newclientmanagedbyrhc : currentNewClientManagedByRHC,
        colour : currentContractColour
    }
    //
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.putContract(payload));
    setShow(false);
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'displayname', headerName: 'Name', width: 300,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => loadContractDetail(contracts.find(contract => contract.id === params.row.id))} className="cell-Link" >
              {params.value}
            </div>
        </strong>
      ),  
    },
    { field: 'organisationname', headerName: 'Organisation', width: 300 },
    { field: 'type', headerName: 'Type', width: 300 },
    { field: 'cost', headerName: 'Cost', width: 120 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'none', headerName: 'Actions', width: 120,
      renderCell: (params) => (
        <>
          {params.row.type === 'EAP' && 
            <>
              <a href={`/eap/${params.row.contracttoken}`} target="RHC">
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }} >
                  Test EAP Link
                </Button>
              </a>
            </>
          }
        </>
      ) 
    }
  ];

  
  function handleClose() {
    setShow(false);
  }  

  function handleNewClientManagedByRHCChange(value) {
    setCurrentNewClientManagedByRHC(value);
  }

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Contract Maintenance</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => createNewContract()}>
              Create Contract
            </Button>
          </Grid>
        </Grid>
      </Paper>


      { contracts ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredContracts} columns={columns} pageSize={20} checkboxSelection={false} rowHeight={40} />
        </Paper>
      : ('None')
      }

      <Modal show={show} onHide={handleClose} dialogClassName="contracts-modal" >
        <Modal.Header closeButton className="contracts-modal">
          <Modal.Title>Contract Maintenance </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contracts-modal">
        <Row>
            <Col md="4">
              <Form.Group >
                <Form.Label>Contract Type</Form.Label>
                <Form.Control as="select" defaultValue={currentType} onBlur={(event) => setCurrentType(event.target.value)}>
                    <option value="Contract">Contract</option>
                    <option value="EAP">EAP</option>
                </Form.Control>
              </Form.Group>
            </Col>            
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="Name" defaultValue={currentName} onBlur={(event) => setCurrentName(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Organisation</Form.Label>
                <Form.Control type="text" name="OrganisationName" defaultValue={currentOrganisationName} onBlur={(event) => setCurrentOrganisationName(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Location</Form.Label>
                <Form.Control type="text" name="Locations" defaultValue={currentLocations} onBlur={(event) => setCurrentLocations(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Contact Email (used for Xero Link)</Form.Label>
                <Form.Control type="text" name="Email" defaultValue={currentEmail} onBlur={(event) => setCurrentEmail(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Colour</Form.Label>
                <Form.Control as="select" defaultValue={currentContractColour} onBlur={(event) => setCurrentContractColour(event.target.value)}>
                  <option value="#00B3BE">Aqua</option>
                  <option value="#A33383">Magenta</option>
                  <option value="#D2B12B">Yellow</option>
                  <option value="#93A33A">Green</option>
                </Form.Control>
              </Form.Group>
            </Col>          
          </Row>                  
          <Row>
            <Col md="3">
              <Form.Group >
                <Form.Label>Session Cost</Form.Label>
                <Form.Control type="text" name="Cost" defaultValue={currentCost} onBlur={(event) => setCurrentCost(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>RHC Fee</Form.Label>
                <Form.Control type="text" name="RHCFee" defaultValue={currentRHCFee} onBlur={(event) => setCurrentRHCFee(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Provider Fee</Form.Label>
                <Form.Control type="text" name="ProviderFee" defaultValue={currentProviderFee} onBlur={(event) => setCurrentProviderFee(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Invoicing Item Code</Form.Label>
                <Form.Control type="text" name="ItemCode" defaultValue={currentItemCode} onBlur={(event) => setCurrentItemCode(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>  
          <Row>
            <Col md="9">
              <Form.Group >
                <Form.Label >New Clients Created for this Contract will be Managed by RHC</Form.Label>
                <Select 
                  variant="outlined"
                  margin="dense"
                  id='NewClientManagedByRHC'
                  aria-label='New Clients Created for this Contract will be Managed by RHC'
                  name='NewClientManagedByRHC'
                  value={currentNewClientManagedByRHC} 
                  onChange={(event) => handleNewClientManagedByRHCChange(event.target.value)}
                  style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                  <MenuItem key='NewClientManagedByRHCNo' value='0'>No</MenuItem>
                  <MenuItem key='NewClientManagedByRHCYes' value='1'>Yes</MenuItem>
                </Select>
              </Form.Group>
            </Col>                    
            <Col md="3">
              <Form.Group >
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setCurrentStatus(event.target.value)}>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                </Form.Control>
              </Form.Group>
            </Col>     
          </Row>
          {currentType === 'EAP' && currentContractID !== 0 ?
            <Row>
              <Col md="12">
                <Form.Label>EAP Link</Form.Label>
                <Form.Control as="textarea" rows={2} type="text" name="contracttoken" defaultValue={`https://portal.ruralhealthconnect/eap/${currentContractToken}`} />
              </Col>
            </Row>
          :
            null
          }
        </Modal.Body>
        <Modal.Footer className="contracts-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putContract}>
            Save
          </Button>
        </Modal.Footer>

      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
