import React from 'react'
// import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

function RHCHeader() {
   
  
  const firstName = useSelector(state => state.auth.firstName)
  const preferredName = useSelector(state => state.auth.preferredName)
  const surname = useSelector(state => state.auth.surname)

  let first = ''
  if (preferredName && preferredName.length > 2) {
    first = preferredName
  } else {
    first = firstName
  }

  return (
    <div className={`bc_header`}>
      <h2 className="kt-subheader__title">
        Hello {(first || surname) && ` ${first} ${surname} `}
      </h2>
    </div>
  )
}

export default RHCHeader
