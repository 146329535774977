import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Paper, Grid, TextField, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer, Flip } from "react-toastify";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import * as Admin from "../../../store/ducks/admin.duck";
import  KTUtil  from "../../../../_metronic/_assets/js/util";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));


export default function InterestedPsychologists() {

  const dispatch = useDispatch()
  const classes = useStyles();

  useEffect( () => { dispatch(Admin.actions.fetchInterested()) }, [dispatch] )
  
  const filters = useSelector(state => state.admin.Filters)
  const interested = useSelector(state =>  state.admin.InterestedPsychologists)
  const filteredInterested = interested.filter(psychologist => 
      (psychologist.fullname.toLowerCase().search(filters.interestedPsychologistsTerm.toLowerCase()) > -1 || psychologist.flagClean.toLowerCase().search(filters.interestedPsychologistsTerm.toLowerCase()) > -1  || filters.interestedPsychologistsTerm.length === 0) 
      && (psychologist.providertype === filters.interestedProviderType || filters.interestedProviderType === 'All') 
      && (psychologist.status === filters.interestedPsychologistsStatus || filters.interestedPsychologistsStatus === 'All') 
      && (filters.interestedPsychologistsType === 'All' || (psychologist.specialisttype === filters.interestedPsychologistsType)) 

  )

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }

  var [showInvitation, setShowInvitation] = useState(false)
  var [inviteError, setInviteError] = useState('')

  function handleInvitationClose() {
    setShowInvitation(false);
  }  

  function setFlag(event, id) {
    dispatch(Admin.actions.putInterestedProviderFlag({ id: id, flag: event.target.value }));
  }


  function loadNewInvite() {
    setcurrentID(0)
    setcurrentFirstName('');
    setcurrentSurname('');
    setcurrentEmail('');
    setcurrentMobile('');
    setcurrentMessage('');
    setcurrentProviderType('Psychologist');
    setInviteError('')
    setShowInvitation(true)
    setcurrentComments('')
    setcurrentSpecialistType('Standard')
  }

  function putInvite() {
    if(currentFirstName === '' || (currentEmail === '' && currentMobile === ''))
    { 
      setInviteError('You must provide a first name and either a mobile phone number or an email address.')
    } else {
      // Note message has been deprecated as the message is now canned 
      setInviteError('')
      var payload = {
        registrationid : currentID,
        firstname: currentFirstName,
        surname: currentSurname,
        email: currentEmail,
        mobilephone: currentMobile,
        providertype: currentProviderType,
        message: currentMessage,
        specialisttype: currentSpecialistType,
        invitetoken: uuidv4(),
        status: 'New by Admin'
      }
      dispatch(Admin.actions.postPsychologistInvite(payload));
      // dispatch(Admin.actions.sendPsychologistInvite(payload));
      setShowInvitation(false)
    }
  }

  const deleteInvite = (id) => {
    dispatch(Admin.actions.deletePsychologistInvite(id));
  }

  const sendInvite = (id) => {
    dispatch(Admin.actions.sendPsychologistInvite(filteredInterested.find(row => row.id === id)));
  }

  var [currentFirstName,setcurrentFirstName] = useState()
  var [currentSurname,setcurrentSurname] = useState()
  var [currentEmail,setcurrentEmail] = useState()
  var [currentMobile,setcurrentMobile] = useState()
  var [currentProviderType,setcurrentProviderType] = useState()
  var [currentSpecialistType,setcurrentSpecialistType] = useState()
  var [currentMessage, setcurrentMessage] = useState('')
  var [currentComments,setcurrentComments] = useState()  
  var [currentID, setcurrentID] = useState()
  var [currentTownOrSuburb, setcurrentTownOrSuburb] = useState()

  const loadInvite  = (thisinterested) => {
    // console.log(thisinterested)
    setcurrentID(thisinterested.id);
    setcurrentFirstName(thisinterested.firstname);
    setcurrentSurname(thisinterested.surname)
    setcurrentEmail(thisinterested.email)
    setcurrentMobile(thisinterested.mobilephone)
    setcurrentMessage(thisinterested.message)
    setcurrentProviderType(thisinterested.providertype)
    setcurrentSpecialistType(thisinterested.specialisttype)
    setcurrentComments(thisinterested.comments)
    setcurrentTownOrSuburb(thisinterested.townorsuburb)
    setShowInvitation(true)
  }



  if (KTUtil.isEmpty(interested))
  {
    // return('No Interest Psychologists Found');
    return(null);
  }  

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', width: 190,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadInvite(interested.find(invite => invite.id === params.row.id))} className="cell-Link">
          {params.value}
        </div>
      </strong>
    ),  },
    { field: 'flag', headerName: 'Follow-up', width: 200,
      cellClassName: (params) => params.row.flag && params.row.flag.length > 0 ? 'flag-full-cell' : 'flag-empty-cell',
      renderCell: (params) => (
        <>
        <TextField 
          variant="outlined"
          name={`flag`} 
          defaultValue={params.row.flag} 
          onBlur={(event) => setFlag(event, params.row.id)}
          InputProps={{ classes: { notchedOutline:classes.noBorder, input: params.row.flag && params.row.flag.length > 0 ? 'flag-full' : 'flag-empty' }, }}
          multiline
          maxRows={4}
          style= {{ width: '100%'}}
          onFocus={event => {
            event.target.select();
          }}>
        </TextField>
        </>
      )
    },
    { field: 'email', headerName: 'Email', width: 220,
      renderCell: (params) => (
        <CopyToClipboard text={params.row.email}>
          <span  className="cell-Link" style={{ fontWeight: 500}}>{params.row.email}</span>
        </CopyToClipboard>
      ) 
    },
    { field: 'providernumber', headerName: 'Provider No.', width: 140 },
    { field: 'mobilephone', headerName: 'Phone', width: 110 },
    // { field: 'postcode', headerName: 'Postcode', width: 70 },
    { field: 'providertype', headerName: 'Type', width: 120 },
    { field: 'comments', headerName: 'Question/Comments', width: 190, 
      renderCell: (params) => (
        <>
          {params.row.comments && params.row.comments.length > 0 ?
            <div onClick={() => loadInvite(interested.find(invite => invite.id === params.row.id))} className="cell-Link">
              View
            </div>
          :
            <div>
              None
            </div>
          }
        </>
      )
    },
    { field: 'status', headerName: 'Status', width: 115 },    
    { field: 'createdon', type: 'date', headerName: 'Created', width: 140, valueFormatter: ({ value }) => format(new Date(value), "dd-MM-yyyy HH:mm") },
    { field: 'none', headerName: 'Actions', width: 270,
      renderCell: (params) => (
        <>
          {params.row.status !== 'Registered' && 
            <a href={`/psychologistregistration/${params.row.invitetoken}`} target="RHC">
              <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }} >
                Test Invite
              </Button>
            </a>        
          }
          {params.row.status !== 'Registered' && 
            <Button variant="secondary" size="sm" onClick={()=>sendInvite(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
              {getAction(params.row.status)}
            </Button>
          }
          {params.row.status !== 'Registered' && 
            <Button variant="secondary" size="sm" onClick={()=>deleteInvite(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} >
              Delete
            </Button>
          }
        </>
      ) 
    }
  ];

  const getAction = (status) => {
    switch(status) {
      case 'New':
      case 'New by Admin':
        return 'Send'
      case 'Sent':
        return 'Resend'
      case 'Resent':
        return ''
      default :
        return
    }
  }

  return (
    <>
      <h4>
        Interested Providers
      </h4>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
              <Grid container>
                <Grid item>             
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control                  
                        type="text"
                        value={filters.interestedPsychologistsTerm}
                        onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'interestedPsychologistsTerm', filterValue: event.target.value }))}
                        autoComplete='OFF'
                        style={{ maxWidth: 170, height: 35, backgroundColor: filters.interestedPsychologistsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                      />
                    <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'interestedPsychologistsTerm', filterValue: '' }))}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: filters.interestedPsychologistsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.interestedProviderType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'interestedProviderType', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="Psychologist">Psychologist</option>
                      <option value="Financial">Financial Counsellor</option>
                      <option value="Clinical">Clinical Psychologist</option>
                      <option value="GP">GP</option>
                      <option value="Provisional">Provisional Psychologist</option>
                      <option value="Social">Social Worker</option>
                      <option value="Nurse">Mental Health Nurse</option>
                      <option value="Occupational">Occupational Therapist</option>
                    </Form.Control>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.interestedPsychologistsStatus} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'interestedPsychologistsStatus', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="New">New</option>
                      <option value="New by Admin">New by Admin</option>
                      <option value="Sent">Sent</option>
                      <option value="Registered">Registered</option>
                    </Form.Control>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.interestedPsychologistsType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'interestedPsychologistsType', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="nthqldphn">Nth QLD PHN</option>
                    </Form.Control>
                </Grid>
              </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadNewInvite()} style={{ marginRight: 20 }}>
              Invite Provider
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredInterested} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'createdon', sort: 'desc' }]}  />
      </Paper>

      <Modal show={showInvitation} onHide={handleInvitationClose} dialogClassName="invite-modal">
        <Modal.Header closeButton className="invite-modal">
        {currentID === 0 ?
          <Modal.Title>Invite New Provider</Modal.Title>
        :
          <Modal.Title>Interested Provider Detail</Modal.Title>
        }
        </Modal.Header>
        <Modal.Body className="invite-modal">
          {inviteError !== '' ?
          <Alert severity="error" style={{ marginBottom: 14 }}>
            {inviteError}
          </Alert>
          :
          null }
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" name="profileFirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text"  name="profileSurname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" name="profileEmail" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Mobile Phone Number</Form.Label>
                <Form.Control type="text" name="profileMobile" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Provider Type</Form.Label>
                <Form.Control as="select" defaultValue={currentProviderType} onBlur={(event) => setcurrentProviderType(event.target.value)}>
                  <option value="Psychologist">Psychologist</option>
                  <option value="Financial">Financial Counsellor</option>
                  <option value="Clinical">Clinical Psychologist</option>
                  <option value="GP">GP</option>
                  <option value="Provisional">Provisional Psychologist</option>
                  <option value="Social">Social Worker</option>
                  <option value="Nurse">Mental Health Nurse</option>
                  <option value="Occupational">Occupational Therapist</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Specialist Type</Form.Label>
                <Form.Control as="select" defaultValue={currentSpecialistType} onBlur={(event) => setcurrentSpecialistType(event.target.value)}>
                  <option value="Standard">Standard</option>
                  <option value="nthqldphn">Nth QLD PHN</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Form.Group>
                <Form.Label>Town or Suburb (if entered on Registration of Interest Form)</Form.Label>
                <Form.Control type="text" name="townorsuburb" readOnly defaultValue={currentTownOrSuburb}  />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group>
                <Form.Label>Comments/Questions</Form.Label>
                <Form.Control as="textarea" rows={3} type="text" name="comments" defaultValue={currentComments} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleInvitationClose}>
            Close
          </Button>
          <Button variant="primary" onClick={putInvite}>
            Save 
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>
        
        <ToastContainer
          position="bottom-right"
          transition={Flip}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

