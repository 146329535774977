
import React, { useState } from 'react';
import ManageForms from "../../home/admin/ManageForms";
import FormQuestions from "../../home/admin/FormQuestions";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export default function FormConfig() {

  const [formID, setFormID] = useState();

  if(!formID)
  {
    return <ManageForms setFormID={setFormID} />
  }
  else
  {
    return <FormQuestions setFormID={setFormID} formID={formID} />
  }

}