import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Form, InputGroup } from "react-bootstrap";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import * as Admin from "../../../store/ducks/admin.duck";
import { Grid, Paper } from '@material-ui/core';

export default function SearchBookingData(props) {

  const dispatch = useDispatch()
  const filters = useSelector(state => state.admin.Filters)
  const searchResults = useSelector(state => state.admin.SearchBookingResults)
  useEffect( () => { dispatch(Admin.actions.searchBookingData() )}, [dispatch] )


  const searchSearchResults = (searchResult) => {
    //
    if( true

        && (!filters.searchClientDataType || filters.searchClientDataType === 'All' || (filters.searchClientDataType && searchResult.entity === filters.searchClientDataType))
        && (
            !filters.searchClientData 
            || filters.searchClientData.length === 0 
            || (searchResult.searchString.length > 0 &&  searchResult.searchString.match(/^[a-zA-Z0-9&@ ]*$/) !== null && filters.searchClientData.split(" ").every(word => searchResult.searchString.toLowerCase().search(word.toLowerCase()) !== -1))
          )
      )
    {
      return searchResult
    }  
  }

  const filteredSearchResults = searchResults.filter(searchResult => searchSearchResults(searchResult))
  
  const linkPage = (uri,id,entity) => {
    //
    if (entity === 'Clients')
    {
      dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: id }))  
      props.history.push(uri)                
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clientid', headerName: 'Client ID', width: 100, hide: true },
    { field: 'clientName', headerName: 'Name (Booking Date/Status)', width: 450 },  
    { field: 'searchString', headerName: 'Matching', width: 150 },
    { field: 'entity', headerName: 'Area', width: 120 },
    { field: 'uri', headerName: 'Link', width: 120,
      renderCell: (params) => (
        <strong>
          <div onClick={() => linkPage(params.row.uri,params.row.clientid,params.row.entity)} className="cell-Link" >
            View 
          </div>
        </strong>
      ),  
    }
  ];


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Global Client Booking Search</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  type="text"
                  value={filters.searchClientData}
                  onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'searchClientData', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.clientsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'searchClientData', filterValue: '' }))}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.clientsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
        </Grid>
      </Paper>


      <Paper style={{ height: 700 }}>
        <DataGridPro rows={filteredSearchResults} columns={columns} checkboxSelection={false} rowHeight={40} />
      </Paper>

    </>
  )
}
