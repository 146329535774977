import React from "react";

export default function QuickStat({ value, desc, color }) {
  
  return (
    <div className="kt-widget26">
      <div className="kt-widget26__content" style={{ paddingBottom: "0.35rem", position: 'relative' }}>
        <span className="kt-widget26__number" style={{ fontSize: "2.4rem" }}>{value}</span>
        <span className="kt-widget26__desc">{desc}</span>
      </div>
    </div>
  );
}
