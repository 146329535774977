import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../../../store/ducks/admin.duck";
import * as Auth from "../../../store/ducks/auth.duck";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import MFAForm from "../../../components/MFAForm";
import { ToastContainer, Flip } from "react-toastify";
import  KTUtil  from "../../../../_metronic/_assets/js/util";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../../_metronic/_assets/css/app2.css';

export default function ProviderSecurity() {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const loadMFAInit = () => {
    // Load Form
    setShowMFA(true);
  }

  function handleMFAClose() {
    setShowMFA(false);
  }  

  function DeactivateMFA() {
    // Ok Time to Dispatch 
    dispatch(Auth.actions.postDeactivateMFA({type : 'Provider'}));
  }

  // now init the fields with values so that Formik can manage the touched state
  const psychologistInit = {
    psychologistid : 0,
    profilePassword: '',
    profileExistingPassword: '',
    profilePasswordConfirmation: '',
  }

  // get the psychologist from state and merge with the initialised fields
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  var psychologist = {...psychologistInit, ...registered};
  var [showMFA, setShowMFA] = useState(false);

  function submitForm(data){
    //
    var payload = {
        registeredid : psychologist.id,
        registeredexistingpassword : data['profileExistingPassword'],
        registeredpassword: data['profilePassword'],
        registeredpasswordconfirmation: data['profilePasswordConfirmation']
    }
    // Save new password for provider
    dispatch(Admin.actions.putProviderPassword(payload));
  }

  const validationSchema = Yup.object().shape({
    profileExistingPassword:  Yup.string()
    .required("Existing Password is required"),
    profilePassword:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('profilePasswordConfirmation'), null], 'Passwords must match'),
    profilePasswordConfirmation:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('profilePassword'), null], 'Passwords must match'),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={psychologist}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit } = props;
          const change = (name,e) => { e.persist();  handleChange(e); }; 
          return (
            <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >
              <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
                <h4>Update Password</h4>
                <hr></hr>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Existing Password</Form.Label>
                      <Form.Control type="password" name="profileExistingPassword" value={values.profileExistingPassword} autoComplete='new-password' onChange={change.bind(null, "profileExistingPassword")}  isInvalid={touched.profileExistingPassword && !!errors.profileExistingPassword} isValid={touched.profileExistingPassword && !errors.profileExistingPassword} />
                      <Form.Control.Feedback type="invalid">{errors.profileExistingPassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password" name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                      <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Confirm Password</Form.Label>
                      <Form.Control type="password" name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item md={3} xs={6}>
                        
                      </Grid>
                      <Grid item md={3} xs={6} style={{ textAlign: 'right' }}>
                        {!KTUtil.isEmpty(errors) && <div style={{ color: 'red', padding: 8 }}>There are incomplete fields above!</div>}
                        <Button variant="primary" type="submit" >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Paper>
            </form>
          )
        }}
      </Formik>
      <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20, marginTop:20 }} >
        <h4>Multi Factor Authentication</h4>
        <hr></hr>
        <Row>
          <Col md="3">
            <Form.Group >
              <Form.Label> Status</Form.Label>
              <Form.Control type="text" name="MFAConfigured" value={psychologist.mfaconfigured} readOnly />
            </Form.Group>
          </Col>
          {psychologist.mfaconfigured !== 'Configured' ?
            <Col md="3" style={{ paddingTop: 29}}>  
              <Button 
                variant="primary" 
                size="sm" 
                style={{ marginLeft: 5, }} 
                onClick={() => loadMFAInit()}
              >
                Enable 
              </Button>
            </Col>
          :
            <Col md="3" style={{ paddingTop: 29}}>  
              <Button 
                variant="danger" 
                size="sm" 
                style={{ marginLeft: 5, }} 
                onClick={() => DeactivateMFA()}
              >
                Disable 
              </Button>
            </Col>
          }
        </Row>
      </Paper>

      <Modal show={showMFA} onHide={handleMFAClose} centered dialogClassName="mfa-modal-dialog">
        <Modal.Header closeButton >
          <Modal.Title>Configure MFA</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mfa-modal">
          <MFAForm 
            referenceID={psychologist.id}
            onCloseMFA={handleMFAClose}
            type={'Provider'}
          />
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  );
}

