import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { eventStatuses, eventTypes, eventTypesEAP, eventTypesNthQLDPHN, eventTypesAdmin, paymentTypes, bookingTypes } from './data'

export const TitleLabel = props => {
  return null;
};

export const TitleEditor = props => {
  return null;
};

export const StartTimezoneCheckedLabel = props => {
  return null;
};

export const StartTimezoneCheckedEditor = props => {
  return null;
};

export const DescriptionLabel = props => {
  return null;
};

export const DescriptionEditor = props => {
  return null;
};

export const EventTypeEditor = props => {
  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value.value
      });
    }
  };

  return <DropDownList 
    onChange={handleChange} 
    value={props.admin ? eventTypesAdmin.find(t => t.value === props.value) : props.nthqldphn ? eventTypesNthQLDPHN.find(t => t.value === props.value) : props.eapcontractndis ? eventTypesEAP.find(t => t.value === props.value) : eventTypes.find(t => t.value === props.value)} 
    data={props.admin ? eventTypesAdmin : props.nthqldphn ? eventTypesNthQLDPHN : props.eapcontractndis ? eventTypesEAP :  eventTypes} 
    dataItemKey={'value'} 
    textField={'text'} 
    style={{ width: 250 }} />
};

export const ClientEditor = props => {
  //console.log(props.value)
  const role = useSelector(state =>  state.auth.roleDesc)
  const clients = useSelector(state => state.admin.Clients)
  const handleChange = event => {
    //console.log(event)
    const selectedClient = clients.find(client => client.fullname === event.value.fullname)
    //console.log(selectedClient)
    if (selectedClient)
    {
      const id = selectedClient.id
      //console.log(id)
      if (props.onChange) {
        props.onChange.call(undefined, {
          value: id
        });
      }
    }
  };

  function handleClientSelectChange(propertyName, event, newValue) { 
    // console.log('NewValue')
    // console.log(newValue);
   //  console.log(props);
    if (newValue) 
    {
      //console.log(newValue)
      if (props.onChange) {
        props.onChange.call(undefined, {
        value: newValue.id
      });
      }
      //
      // formRenderProps.onChange('paymentType', {
      //   value: newValue.paymentType
      // });
    }
  } 

  if (role === 'Admin')
  {
    return <DropDownList onChange={handleChange} value={clients.find(client => client.id === props.value)} data={clients} dataItemKey={'id'} textField={'fullname'} disabled={props.disabled ? true : false} style={{ width: 240 }} />;     
  }
  else
  {
    return <Autocomplete
      id="clientSelect"
      variant="outlined"
      options={clients}
      getOptionLabel={(option) => option.fullname}
      value={clients.find(client => client.id === props.value) ? clients.find(client => client.id === props.value) :  null} 
      onChange={(event, newValue) => handleClientSelectChange('clientSelect', event, newValue)}
      style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', position: 'relative'}}
      renderInput={(params) => 
        <TextField 
          {...params} 
          type="text"
          variant="outlined"
          margin="dense"
          name="clientSelector"
          style={{backgroundColor: "white", margin: 0, padding: 0,zIndex: '20000' }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'}}
        />}
    />
  }

};

export const PaymentTypeEditor = props => {  
  const handleChange = event => {
    if (props.onChange) {
      // Check if Payment Type is being changed to Contract and bounce it - this must be changed by Admin 
      // Kendo React has no disabled support in DropDownList - though it exists in Angular - Telerik for the win. 
      if (event.value.value && event.value.value !== 'Contract')
      {
        props.onChange.call(undefined, {
          value: event.value.value
        });
      }
    }
  };
  return <DropDownList onChange={handleChange}  itemDisabled='Contract' value={paymentTypes.find(t => t.value === props.value)} data={paymentTypes} dataItemKey={'value'} textField={'text'} style={{ width: 200 }} />;
};

export const StatusEditor = props => {
  return <DropDownList value={eventStatuses.find(t => t.value === props.value)} readOnly data={[eventStatuses.find(t => t.value === props.value)]} dataItemKey={'value'} textField={'text'} />;
};

export const BookingTypeEditor = props => {
  const handleChange = event => {
    if (props.onChange) {
      props.onChange.call(undefined, {
        value: event.value.value
      });
    }
  };
  return <DropDownList onChange={handleChange}  value={bookingTypes.find(t => t.value === props.value)} data={bookingTypes} dataItemKey={'value'} textField={'text'} style={{ width: 200 }} />;
};

export const CustomEndEditor = (props) => {

  // We need to add 30 mins to the end time for new bookings

  const defaultEndTime = React.useRef(props.value)
  const defaultEndTimeCount = React.useRef(0)
  const [defaultOffset, setDefaultOffset] = useState(false);

  React.useEffect(() => {
    if(!defaultOffset && props.eventType && props.eventType === 'booking')
    {
      defaultEndTime.current =  defaultEndTime.current.setMinutes(props.value.getMinutes() + 30)
      // props.onChange.call(undefined, { value: defaultEndTime.current });
      defaultEndTimeCount.current = defaultEndTimeCount + 1
      setDefaultOffset(true)
    }
  }, [defaultOffset, props])
 
  return (
    <DateTimePicker {...props} defaultValue={new Date(defaultEndTime.current)}/>
  );
};
