import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest, all } from 'redux-saga/effects';
// import { getUserByToken } from '../../crud/auth.crud';
import * as routerHelpers from '../../router/RouterHelpers';
import axios from 'axios';
import { apiURL } from '../../../settings';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';


export const BASE_URL = apiURL()+'/users';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Reauth: '[Reauth] Action',
  FETCH_MFAQR: 'FETCH_MFAQR',
  SET_MFAQR: 'SET_MFAQR',
  SET_CONFIRMCODE: 'SET_CONFIRMCODE',
  POST_CONFIRMCODE: 'POST_CONFIRMCODE',
  POST_ACTIVATEMFA: 'POST_ACTIVATEMFA',
  POST_DEACTIVATEMFA: 'POST_DEACTIVATEMFA',
  SET_INITAL: 'SET_INITAL',
  SET_INITALTOTP: 'SET_INITALTOTP',
  POST_UPDATEPASSWORD: 'POST_UPDATEPASSWORD',
  SET_INITIALRECOVERY: 'SET_INITIALRECOVERY'
}

const initialAuthState = {
  MFAQR: undefined,
  CodeAccepted: undefined,
  BckupCodes: undefined,
  user: undefined,
  authToken: undefined,
  firstName: undefined,
  surname: undefined,
  preferredName: undefined,
  refreshToken: undefined,
  roleID : undefined,
  roleDesc: undefined,
  roleScope: undefined,
  userTZ: undefined,
  winbrowserTZ: undefined,
  providertype: undefined,
  recoveryStatus: undefined,
  totp: undefined
}


export const reducer = persistReducer(
    { storage, key: 'RuralHealthConnect-Auth-2022-02-16', whitelist: ['user', 'authToken', 'refreshToken', 'firstName', 'surname', 'preferredName','roleId', 'roleDesc', 'roleScope','userTZ', 'winbrowserTZ', 'providertype', 'Filters'] },
    (state = initialAuthState, action) => {
      switch (action.type) {

        case actionTypes.Login: {
          // console.log ('In Login reducer')
          // console.log (action.payload);
          const { authToken, refreshToken, firstName, surname, preferredName, roleID, roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype, recoveryStatus} = action.payload;
          // console.log(authToken);
          // console.log(refreshToken);
          // console.log(recoveryStatus);
          Sentry.setUser({ email: surname, segment: roleDesc });
          return { ...state, authToken, refreshToken, firstName, surname, preferredName, roleID, roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype, user: undefined, recoveryStatus};
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.Reauth: {
          // console.log ('In Reauth reducer')
          // console.log(action.payload);
          const { authToken, refreshToken } = action.payload;
          return { ...state, authToken, refreshToken }; 
        }

        case actionTypes.SET_MFAQR: {
          // console.log('In Reducer - SET_MFAQR')
          // console.log(action.payload)
          return {
            ...state,
            MFAQR : action.payload[0].qrCode, 
          };
        }

        case actionTypes.SET_CONFIRMCODE: {
          return {
            ...state,
            CodeAccepted : action.payload.status,
            BackupCodes: action.payload.backupCodes,
          };
        }

        case actionTypes.SET_INITIALRECOVERY:
          {
            return {
              ...state,
              recoveryStatus: undefined
            }
          }

        case actionTypes.SET_INITALTOTP:
          {
            return {
              ...state,
              totp: undefined
            }
          }

        case actionTypes.SET_INITAL: {
          return {
            ...state,
            CodeAccepted : undefined,
            CodeFailed : undefined
          };
        }

        default:
          return state;
      }
    }
);

export function* fetchMFAQR(action)
{
  try 
  {
    const newURL = BASE_URL + '/mfa';
    // Request QR Code
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== 'Error') 
    {
      yield put({ type: 'SET_MFAQR', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Action Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postConfirmCode(action)
{
  try 
  {
    // Reset Parameters 
    yield put({ type: 'SET_INITAL'});
    // Send TOTP for validation and receive backup codes 
    const newURL = BASE_URL + '/validatetotp';
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== 'Error') 
    {
      yield put({ type: 'SET_CONFIRMCODE', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Action Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postUpdatePassword(action)
{
  try 
  {
    const newURL = BASE_URL + '/updatepassword';
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== 'Error') 
    {
      toast.success("Password Updated",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error('`Password Update Failed');
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postActivateMFA(action)
{
  try 
  {
    // Advise that MFA should be activated 
    const newURL = BASE_URL + '/activatemfa';
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== 'Error') 
    {
      if(action.payload.type === 'Admin')
      {
        yield put({ type: 'FETCH_ADMINUSER', payload: { popCache: true } });
      } 
      else
      {
        yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true } });
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Action Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postDeactivateMFA(action)
{
  try 
  {
    // Advise that MFA should be deactivated 
    const newURL = BASE_URL + '/deactivatemfa';
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== 'Error') 
    {
      if(action.payload.type === 'Admin')
      {
        yield put({ type: 'FETCH_ADMINUSER', payload: { popCache: true } });
      } 
      else
      {
        yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true } });
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Action Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}



export const actions = {
  login:              (authToken,refreshToken,firstName,surname,preferredName,roleID,roleDesc,roleScope,userTZ,winbrowserTZ,totp,providertype,recoveryStatus) => ({ type: actionTypes.Login, payload: { authToken, refreshToken, firstName, surname, preferredName, roleID, roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype, recoveryStatus } }),
  logout:             () => ({ type: actionTypes.Logout }),
  reauth:             (authToken,refreshToken) => ({ type: actionTypes.Reauth, payload: { authToken, refreshToken }}),
  getMFA:             () => ({ type: actionTypes.FETCH_MFAQR }),
  postActivateMFA:    (payload) => ({type: actionTypes.POST_ACTIVATEMFA, payload : payload }),
  postDeactivateMFA:  (payload) => ({type: actionTypes.POST_DEACTIVATEMFA, payload : payload }),
  postConfirmCode:    (payload) => ({type: actionTypes.POST_CONFIRMCODE, payload : payload }),
  updatePassword:     (payload) => ({type: actionTypes.POST_UPDATEPASSWORD, payload : payload }),
  clearMFAToken:             () => ({type: actionTypes.SET_INITALTOTP }),
  clearRecovery:             () => ({type: actionTypes.SET_INITIALRECOVERY })

};

function* actionWatcher() {
  yield takeLatest('FETCH_MFAQR',fetchMFAQR);
  yield takeLatest('POST_CONFIRMCODE',postConfirmCode);
  yield takeLatest('POST_ACTIVATEMFA',postActivateMFA);
  yield takeLatest('POST_DEACTIVATEMFA',postDeactivateMFA);
  yield takeLatest('POST_UPDATEPASSWORD',postUpdatePassword);
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
   
  });
  yield all([
    actionWatcher(),
  ]); 



}
