import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Files from "../../../components/Files";
import * as Admin from "../../../store/ducks/admin.duck";

export default function FilesPsychologist() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  
  if(registered) {
    return (
      <>
        <h4>Shared Files</h4>
        <Files type="Psychologist" pkid={registered.id} />
      </>
    )
  }
  else
  {
    return <div>Loading...</div>
  }

}
