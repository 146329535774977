import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import RHCHeader from './RHCHeader'
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
  headerCommonRef = React.createRef();

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

   render() {
    const {
      menuHeaderDisplay,
      headerAttributes,
      roledesc,
      location,
      headerClasses
    } = this.props;


    //let pathname = location.pathname;
    let currentPage = location.pathname.slice(1 /* Remove leading slash. */);


    // console.log(currentPage);
    const pageTitle = () => {
      if (roledesc !== 'Psychologist')
      {
          return null
      }
      else
      {
        switch(currentPage) {
          // case 'dashboard':
          //   return 'Welcome to your Rural Health Connect dashboard. This is where you manage your calendar and bookings.'
          // case 'clientinvites':
          //   return 'You can invite your own clients onto the platform here. Can all clients referred to RHC please go via our admin. Enter their details to send a link via text and email containing the registration form. Once they have completed it, you will see them appear in your client list.'
          case 'profile':
            return 'Update your personal information here'
          // case 'platform':
          //   return 'See a list of your completed sessions here. We use this list to send out invoices at the end of each month so please remove any appointments that did not go ahead. Rural Health Connect proudly operates on trust. If we are missing any clients or appointments from this list, please let us know at info@ruralhealthconnect.com.au'
          case 'forms':
            return 'Create new forms for electronic signing here. These forms are sent to clients via the dashboard.'
          case 'codes':
            return 'Add new Medicare item numbers for billing through Tyro here'
          case 'invoices':
            return 'Process all payments via Tyro here. For help with anything regarding Tyro please use their chat icon in the bottom right corner to talk to an operator.'
          default: 
            return  null
        }
      }
    }
 

    return (
      <>
      <div className={`warning_banner_off`}> 
      {/* <div className={`warning_banner_on`}>  */}
        There is a new version of RHC available, please <a href="https://refreshyourcache.com/en/cache/" style={{ color: 'white', fontWeight: 600, }}>perform a force refresh</a> of your browser to prevent problems.
      </div>
      {pageTitle() ?
        <div className={`provider_help`}> 
          {pageTitle()}
        </div>
      :
        null
      }

      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >

        <h3 className="kt-subheader__title"><RHCHeader /></h3>
        <AnimateLoading />
        {/* <!-- begin: Header Menu --> */}
        {menuHeaderDisplay && <HMenu />}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
        {!menuHeaderDisplay && <div />}
        {roledesc !== 'Client' ? 
          <Topbar isDashStyle={this.props.isDashStyle} />			
        :
          null
        }

      </div>
    </>
    );
  }
}


const mapStateToProps = store => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid",
  roledesc : store.auth.roleDesc
});

export default withRouter(connect(mapStateToProps)(Header));
