import React, {  useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Reference from "../../../store/ducks/reference.duck";
import * as Admin from "../../../store/ducks/admin.duck";
import * as Auth from "../../../store/ducks/auth.duck";
import MFAForm from "../../../components/MFAForm";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Paper, Grid, TextField} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import  KTUtil  from "../../../../_metronic/_assets/js/util";
import { ToastContainer, Flip } from "react-toastify";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../../_metronic/_assets/css/app2.css';




export default function ProfileAdmin() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchAdminUser()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )

  var [showMFA, setShowMFA] = useState(false);
  const timeZone = useSelector(state =>  state.reference.TimeZone)
  
  
  // now init the fields with values so that Formik can manage the touched state
  const adminInit = {
    firstname: '', 
    surname: '', 
    email: '', 
  }

  const loadMFAInit = () => {
    // Load Form
    setShowMFA(true);
  }

  function handleMFAClose() {
    setShowMFA(false);
  }  

  function DeactivateMFA() {
    // Ok Time to Dispatch 
    dispatch(Auth.actions.postDeactivateMFA({type : 'Admin'}));
  }


  // get the psychologist from state and merge with the initialised fields
  const adminUsers = useSelector(state =>  state.admin.AdminUser);
  var myUser = {...adminInit, ...adminUsers};

  function submitForm(data){
    // Save data 
    dispatch(Admin.actions.putAdminUser(data))
  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("Surname is required"),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={myUser}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting } = props;
          const change = (name,e) => { e.persist();  handleChange(e); }; 
          return (
            <form noValidate={true} autoComplete="off" onSubmit={!isSubmitting ? handleSubmit : undefined} >

              <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                      <Grid item md={6} xs={12}>
                        <h2>Profile</h2>
                      </Grid>
                      <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                        * indicates required fields
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">                  
                    {values.message ? (
                      <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                        {values.message}
                      </Alert>) 
                      : null
                    }
                  </Col>
                </Row>
                <hr style={{ marginTop: 4 }}></hr>
                <Row>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                      <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Surname *</Form.Label>
                      <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                      <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                      <Form.Group >
                      <Form.Label>Email *</Form.Label>
                      <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group style={{width: "100%"}}>
                      <Form.Label>TimeZone</Form.Label>
                      <Autocomplete
                        id="profileTimeZone"
                        variant="outlined"
                        options={timeZone}
                        getOptionLabel={(option) => option.name}
                        value={timeZone.find(v => v.systemName === values.timeZone) || ''}
                        onChange={(event, newValue) => setFieldValue("timeZone", newValue.systemName)}
                        style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            type="text"
                            variant="outlined"
                            margin="dense"
                            name="TimeZoneSelector"
                            style={{backgroundColor: "white", margin: 0, padding: 0}}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'}}
                          />}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4>Multi Factor Authentication</h4>
                <hr></hr>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label> Status</Form.Label>
                      <Form.Control type="text" name="MFAConfigured" value={values.mfaconfigured} readOnly />
                    </Form.Group>
                  </Col>
                  {values.mfaconfigured !== 'Configured' ?
                    <Col md="3" style={{ paddingTop: 29}}>  
                      <Button 
                        variant="primary" 
                        size="sm" 
                        style={{ marginLeft: 5, }} 
                        onClick={() => loadMFAInit()}
                      >
                        Enable 
                      </Button>
                    </Col>
                  :
                    <Col md="3" style={{ paddingTop: 29}}>  
                      <Button 
                        variant="danger" 
                        size="sm" 
                        style={{ marginLeft: 5, }} 
                        onClick={() => DeactivateMFA()}
                      >
                        Disable 
                      </Button>
                    </Col>
                  }
                </Row>                
                <h4>Login Details (Leave blank unless updating password)</h4>
                <hr></hr>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password" name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                      <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Confirm Password</Form.Label>
                      <Form.Control type="password" name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item md={3} xs={6}>
                        
                      </Grid>
                      <Grid item md={3} xs={6} style={{ textAlign: 'right' }}>
                        {!KTUtil.isEmpty(errors) && <div style={{ color: 'red', padding: 8 }}>There are incomplete fields above!</div>}
                        <Button variant="primary" type="submit" >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Paper>
            </form>
          )
        }}
      </Formik>

      <Modal show={showMFA} onHide={handleMFAClose} centered dialogClassName="mfa-modal-dialog">
        <Modal.Header closeButton >
          <Modal.Title>Configure MFA</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mfa-modal">
          <MFAForm 
            referenceID={myUser.id}
            type={'Admin'}
            onCloseMFA={handleMFAClose}
          />
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  );
}

