import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, InputGroup } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Admin from "../../../store/ducks/admin.duck";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';
import  KTUtil  from "../../../../_metronic/_assets/js/util";

export default function MedicalPractitioners() {


  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState('Referring GP')
  // var   [show, setShow] = useState(false)

  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractitioners()) }, [dispatch] )

  const practitioners = useSelector(state =>  state.admin.MedicalPractitioners)  
  const filteredPractitioners = practitioners.filter(practice => ((practice.type === filterType || filterType === 'All') && (practice.fullname.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0)) )

  const loadNew = () => {
    // setcurrentMedicalPractitionerID(null);
    // setcurrentFirstname('');
    // setcurrentSurname('');
    // setcurrentProviderNumber('');
    // setcurrentMobilePhone('');
    // setcurrentSalutation('');
    // setcurrenttype('');
    // setShow(true);
  }

  if (KTUtil.isEmpty(practitioners))
  {
    // return('No Interest Psychologists Found');
    return(null);
  }  

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', width: 230 },
    { field: 'providernumber', headerName: 'Provider No.', width: 120 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'status', headerName: 'Status', width: 80 },    
    { field: 'created', type: 'date', headerName: 'Created', width: 100 }
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }

  return (
    <>
    <h4>
      Medical Practitioners
    </h4>

    <Paper> 
      <Grid container justifyContent="space-between" style={{ padding: 10 }}>

        <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
          <Grid container justifyContent="space-between" style={{ padding: 0 }}>
            <Grid item md={6} style={{ paddingRight: 10 }}>              
              <InputGroup size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control                  
                    type="text"
                    value={searchTerm}
                    onChange={(event) => handleSearch(event)}
                    autoComplete='OFF'
                  />
              </InputGroup>
            </Grid>
            <Grid item md={6}>  
              <Form.Control as="select" size="sm" defaultValue={filterType} onChange={(event) => setFilterType(event.target.value)}>
                <option value="All">All</option>
                <option value="Referring GP">Referring GP</option>
                <option value="Client GP">Client GP</option>
              </Form.Control>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
          <Button variant="primary" size="sm" onClick={() => loadNew()}>
            Add Practitioner
          </Button>
        </Grid>

      </Grid>
    </Paper>

    <Paper style={{ height: 700 }}>
      <DataGrid rows={filteredPractitioners} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} />
    </Paper>

      <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>
        
        <ToastContainer
          position="bottom-right"
          transition={Flip}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  )
}

