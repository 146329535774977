import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutoComplete from "../../components/AddressAutoComplete";
import * as Reference from "../../store/ducks/reference.duck"
import * as Registration from "../../store/ducks/registration.duck"
import * as Admin from "../../store/ducks/admin.duck"
import { Button, Form, Row, Col, InputGroup, Table, Modal, Popover, OverlayTrigger, Badge } from "react-bootstrap";
import { Paper, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Checkbox, Card, CardActions, CardMedia, CardContent, Chip, TextField, makeStyles, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { IconButton, Collapse } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import { useMediaQuery } from '@material-ui/core';
import { toAbsoluteUrl } from "../../../_metronic";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Masonry from 'react-masonry-css';
import clsx from 'clsx';
import { format,  differenceInYears } from "date-fns";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Interweave from 'interweave';

import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../_metronic/_assets/css/app2.css';

export function EAPRegistrationPage() {
  return <ClientRegistrationPage 
          type="EAP" 
  />;
}

export function NDISRegistrationPage() {
  return <ClientRegistrationPage 
          type="NDIS" 
  />;
}


export default function ClientRegistrationPage(props) { 
  
  const { invitetoken } = useParams();
  
  // console.log(props.type)


  const [providerType,setProviderType] = useState();
  if (!providerType)
  {
    if (props.type === 'EAP')
    {
      setProviderType('EAP');
    }
    else
    {
      switch(invitetoken)
      {
        case 'new':
          setProviderType('Psychologist');
          break;
        case 'lgbtiq':
          setProviderType('Psychologist');
          break;
        case 'perinatal':
            setProviderType('Psychologist');
            break;
        case 'newfinancial':
          setProviderType('Financial');
          break;
        case 'newgp':
          setProviderType('GP');
          break;
        case 'newprovisional':
          setProviderType('Provisional');
          break;
        case 'newsocial':
          setProviderType('Social');
          break;
        case 'newnurse':
          setProviderType('Nurse');
          break;
        case 'newoccupational':
          setProviderType('Occupational');
          break;
        case 'newsupervision':
          setProviderType('Supervision');
          break;
        default:
          setProviderType('Psychologist');
          break;
      }
    }
  }


  const dispatch = useDispatch()
  useEffect( () => { dispatch(Registration.actions.fetchClientInvite(invitetoken))  }, [dispatch, invitetoken] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Registration.actions.fetchRegisteredPublic({ inviteToken: invitetoken, providerType : providerType})) }, [dispatch, invitetoken, providerType] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => loadInviteAOI(invitetoken), )
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchPrograms()) }, [dispatch] )
  
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)
  const registered = useSelector(state => state.registration.Registered);
  const invite = useSelector(state => state.registration.ClientInvite)
  const programs = useSelector(state => state.admin.Programs)
  
  // const regstatus = useSelector(state =>  state.registration.regstatus)
  const [aoiTouched, setaoiTouched] = useState(false)
  // Determine browser timezone
  const timeZone = useSelector(state => state.reference.TimeZone)
  const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserTZMS = (timeZone && timeZone.some(tz => tz.ianaName === browserTZ)) ? timeZone.find(tz => tz.ianaName === browserTZ) : timeZone.find(tz => tz.tzIndex === '6')
  // const browserTZMS = timeZone && timeZone.find(tz => tz.ianaName === browserTZ)
 
  const [steps, setSteps] = useState({ 
    step1: { title: '1. Areas of Interest',  status: 'Not Started', data: [], search: [] }, 
    step2: { title: '2. Select Psychologist', status: 'Not Started', data: {}, 
      bookingdate: undefined, 
      providerType : providerType, 
      userTZ: browserTZMS && browserTZMS.systemName, 
      userutcOffset: '', 
      paymenttype: '',      
      healthCareCard: '',
      unemployed: '',
      naturalDisaster: '',
      bulkBilledReason: '',
      reducedGapReason: ''
    }, 
    step3: { title: '3. Registration Form',   status: 'Not Started', data: {}, providerType : providerType}, 
    step4: { title: '4. Confirmation',   status: 'Not Started', data: {},providerType : providerType }, 
    step5: { title: '4. Form Submitted',   status: 'Not Started', data: {},providerType : providerType }, 
    currentStep: 1,
    message: ''
  });

  const setStepValue = (value) => {
    setSteps({...steps, ...value})
  }


  // Check if there was an invite guid
  const loadInviteAOI = () => {
    if(invite 
      && registered.length > 0 
      && invitetoken !== 'new' 
      && invitetoken !== 'newoccupational' 
      && invitetoken !== 'newsocial' 
      && invitetoken !== 'newnurse' 
      && invitetoken !== 'newsupervision' 
      && invitetoken !== 'newprovisional' 
      && invitetoken !== 'newfinancial' 
      && invitetoken !== 'newgp' 
      && invitetoken !== 'perinatal' 
      && invitetoken !== 'lgbtiq' 
      && invitetoken !== 'nthqldphn' 
      && Object.keys(invite).length > 0  
      && !programs.find(program => program.identifier === invitetoken)
      && !aoiTouched)
    {   
      // Determine invite providertype before reviewing aoi
      // console.log('Inside If')
      setProviderType(invite.providertype);
      // add aoi from invite 
      var aoi = areasOfInterestReference.filter(( {issue} ) => invite.clientAreasOfInterest.includes(issue))
      var newSteps = {...steps, ...{ step1: { status: 'Completed', data: aoi, search: aoi }, currentStep: 2, message: invite.message }}
      // add psychologist from invite

      var selectedPsychologist = registered.find(row => row.id === invite.psychologistid)
      if (invite.psychologistid)
      {
        newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'Completed', data: {...selectedPsychologist}, paymenttype: invite.paymenttype, bulkBilledReason : invite.bulkbilledreason, bookingdate: invite.bookingdate, providerType: invite.providertype,userTZ: invite.timezone }}, currentStep: selectedPsychologist && invite.bookingdate ? 3 : 2 }}
      }
      else
      {
        // Need to default payment type for GP to Bulk Billed 
        if (invite.providertype === 'GP')
        {
          newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: 'Bulk Billed', providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
        }
        else
        {
          if (invite.providertype === 'Financial')
          {
            newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: 'Reduced Gap', providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
          }
          else
          {
            if (invite.providertype === 'Provisional')
            {
              newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: 'Provisional', providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
            }
            else
            {
              if (invite.providertype === 'Social')
              {
                newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: invite.paymenttype, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
              }
              else
              {
                if (invite.providertype === 'Occupational')
                {
                  newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: invite.paymenttype, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
                }
                else
                {
                  if (invite.providertype === 'Nurse')
                    {
                      newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: invite.paymenttype, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
                    }
                  else
                  {
                    if (invite.providertype === 'NDIS')
                    {
                      newSteps = {...newSteps, ...{ step2: { ...steps.step2, ...{ status: 'Completed', data: {}, paymenttype: 'NDIS', bookingdate: null, providerType: 'NDIS', userTZ: browserTZMS.systemName }}, currentStep: 3 }}
                    }
                    else
                    {
                      if (invite.providertype === 'Supervision')
                      {
                        newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: 'Supervision', providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
                      }
                      else
                      {
                        // console.log(invite)
                        // console.log(programs)
                        // console.log(programs.find(program => program.id === invite.programid))
                        if (invite.programid && programs.find(program => program.id === invite.programid))
                        {
                          // Ok the invite token was a program identifer
                          // console.log(invite)
                          var program = programs.find(program => program.id === invite.programid)
                          // find AOI issue label 
                          var aoiProgram = areasOfInterestReference.filter(aoi => aoi.id === program.aoi.toString())
                          // console.log(program)
                          // console.log(aoiProgram)
                          newSteps = {...steps, ...{ step1: { ...steps.step1, ...{ status: 'Completed', data: aoiProgram, search: aoiProgram }}, step2: { ...steps.step2, ...{ status: 'In Progress', program: true, fixAOI: true, data: {}, bookingdate: null, providerType: 'Psychologist', userTZ: invite.timezone }}, currentStep: 2 }}
                        }
                        else
                        {
                          // Check to see if any AOI have been selected 
                          if (aoi && aoi.length > 0)
                          {
                            // console.log('AOI Set')
                            // AOI found in invite
                            newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'In Progress', data: {}, bookingdate: null, paymenttype: invite.paymenttype, bulkBilledReason : invite.bulkbilledreason, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 2 }}
                          }
                          else
                          {
                            // console.log('AOI Not Set')
                            // No AOI found in invite
                            // newSteps = {...newSteps, ...{ step1: { ...newSteps.step1, ...{  status: 'Not Started', data: [], search: [],  bookingdate: null, paymenttype: invite.paymenttype, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 1 }}
                            newSteps = {...steps, ...{ step1: { ...steps.step1, ...{ status: 'Not Started', data: [], search: [] }}, step2: { ...steps.step2, ...{ status: 'Not Started', data: {}, bookingdate: null, paymenttype: invite.paymenttype, bulkBilledReason : invite.bulkbilledreason, providerType: invite.providertype, userTZ: invite.timezone }}, currentStep: 1 }}
                            // newSteps = {...newSteps, ...{ step2: { ...newSteps.step2, ...{ status: 'Not Started', data: {}, bookingdate: null, paymenttype: invite.paymenttype, bulkBilledReason : invite.bulkbilledreason, providerType: invite.providertype, userTZ: invite.timezone }} }}  
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      setStepValue(newSteps)
      setaoiTouched(true)
    } 
    else
    {
      // Ok Non Invite
      // Handle non invite GP
      if (invitetoken && invitetoken === 'newgp' && !aoiTouched && browserTZMS)
      {
        setProviderType('GP')
        var gpNewSteps = ({ 
          step1: { status: 'Completed', data: [], search: [] }, 
          step2: { ...steps.step2, ...{ title: '2. Select GP', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'GP', paymenttype : 'Bulk Billed', userTZ: browserTZMS.systemName, userutcOffset: '' }}
        })
        // Set the step to step 2
        gpNewSteps = {...gpNewSteps, currentStep: 2 }
        setStepValue(gpNewSteps)
        setaoiTouched(true)
      }
      // Handle non invite Supervision
      if (invitetoken && invitetoken === 'newsupervision' && !aoiTouched && browserTZMS)
      {
        setProviderType('Supervision')
        var supervisionNewSteps = ({ 
          step1: { status: 'Completed', data: [], search: [] }, 
          step2: { ...steps.step2, ...{ title: '2. Select Supervising Psychologist', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'Supervision', paymenttype : 'Supervision', userTZ: browserTZMS.systemName, userutcOffset: '' }}
        })
        // Set the step to step 2
        supervisionNewSteps = {...supervisionNewSteps, currentStep: 2 }
        setStepValue(supervisionNewSteps)
        setaoiTouched(true)
      }
      // Handle non invite Provisional
      if (invitetoken && invitetoken === 'newprovisional' && !aoiTouched && browserTZMS)
      {
        setProviderType('Provisional')
        var provisionalNewSteps = ({ 
          step1: { status: 'Completed', data: [], search: [] }, 
          step2: { ...steps.step2, ...{ title: '2. Select Provisional Psychologist', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'Provisional', paymenttype : 'Provisional', userTZ: browserTZMS.systemName, userutcOffset: '' }}
        })
        // Set the step to step 2
        provisionalNewSteps = {...provisionalNewSteps, currentStep: 2 }
        setStepValue(provisionalNewSteps)
        setaoiTouched(true)
      }
      // Handle non invite Social
      if (invitetoken && invitetoken === 'newsocial' && !aoiTouched && browserTZMS)
      {
        setProviderType('Social')
        var socialNewSteps = ({ 
          step1: { status: 'Completed', data: [], search: [] }, 
          step2: { ...steps.step2, ...{ title: '2. Select Social Worker', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'Social', paymenttype : null, userTZ: browserTZMS.systemName, userutcOffset: '' }}
        })
        // Set the step to step 2
        socialNewSteps = {...socialNewSteps, currentStep: 2 }
        setStepValue(socialNewSteps)
        setaoiTouched(true)
      }
      // Handle non invite Nurse
      if (invitetoken && invitetoken === 'newnurse' && !aoiTouched && browserTZMS)
        {
          setProviderType('Nurse')
          var nurseNewSteps = ({ 
            step1: { status: 'Completed', data: [], search: [] }, 
            step2: { ...steps.step2, ...{ title: '2. Select Mental Health Nurse', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'Nurse', paymenttype : null, userTZ: browserTZMS.systemName, userutcOffset: '' }}
          })
          // Set the step to step 2
          nurseNewSteps = {...nurseNewSteps, currentStep: 2 }
          setStepValue(nurseNewSteps)
          setaoiTouched(true)
        }
      // Handle non invite Occupational
      if (invitetoken && invitetoken === 'newoccupational' && !aoiTouched && browserTZMS)
      {
        setProviderType('Occupational')
        var occupationalNewSteps = ({ 
          step1: { status: 'Completed', data: [], search: [] }, 
          step2: { ...steps.step2, ...{ title: '2. Select Occupational Therapist', status: 'Not Started', data: {}, bookingdate: undefined,providerType: 'Occupational', paymenttype : null, userTZ: browserTZMS.systemName, userutcOffset: '' }}
        })
        // Set the step to step 2
        occupationalNewSteps = {...occupationalNewSteps, currentStep: 2 }
        setStepValue(occupationalNewSteps)
        setaoiTouched(true)
      }
    }
    // Handle LGBTIQ
    if (invitetoken && invitetoken === 'lgbtiq' && !aoiTouched && registered.length > 0)
    {
      // This simply pre populates the AOI for LGBTIQ 
      var lgbtiqAoi = areasOfInterestReference.filter(area => area.issue === 'LGBTIQA+')
      var lgbtiqNewSteps = {...steps, ...{ step1: { ...steps.step1, ...{ status: 'In Progress', data: lgbtiqAoi, search: lgbtiqAoi }}, currentStep: 1 }}
      setStepValue(lgbtiqNewSteps)
      setaoiTouched(true)
    }
    // Handle PeriNatal 
    if (invitetoken && invitetoken === 'perinatal' && !aoiTouched && registered.length > 0)
    {
      // This simply pre populates the AOI for PeriNatal
      var periNatalAoi = areasOfInterestReference.filter(area => area.issue === 'Perinatal Depression and Anxiety')
      var periNatalNewSteps = {...steps, ...{ step1: { ...steps.step1, ...{ status: 'In Progress', data: periNatalAoi, search: periNatalAoi }}, currentStep: 1 }}
      setStepValue(periNatalNewSteps)
      setaoiTouched(true)
    }
    // Handle EAP 
    if (props.type && props.type ==='EAP' && !aoiTouched && browserTZMS)
    {
      // Ok this will be exciting 
      // EAP clients access simply using a URL - they do not choose AOI though now choose a Psychologist (with all AOI) - this is all handled manually by Admin 
      // 
      setProviderType('EAP')
      var eapNewSteps = {...steps, ...{ step2: { ...steps.step2, ...{ status: 'Not Started', data: {}, paymenttype: 'EAP', bookingdate: null, providerType: 'EAP', userTZ: browserTZMS.systemName }}, currentStep: 2 }}
      // Ok all done - lets go
      setStepValue(eapNewSteps)
      setaoiTouched(true)
    }
    // Handle NDIS 
    if (props.type && props.type ==='NDIS' && !aoiTouched && browserTZMS)
    {
      // Ok this will be exciting 
      // NDIS clients access simply using a URL - they do not choose AOI or a Psychologist - this is all handled manually by Admin 
      // 
      setProviderType('NDIS')
      var ndisNewSteps = {...steps, ...{ step2: { ...steps.step2, ...{ status: 'Completed', data: {}, paymenttype: 'NDIS', bookingdate: null, providerType: 'NDIS', userTZ: browserTZMS.systemName }}, currentStep: 3 }}
      // Ok all done - lets go
      setStepValue(ndisNewSteps)
      setaoiTouched(true)
    }
    // Handle Program 
    if (invitetoken && programs.find(program => program.identifier === invitetoken) && !aoiTouched && browserTZMS && areasOfInterestReference && areasOfInterestReference.length > 0)
    {
      // Ok the invite token was a program identifer
      var programNI = programs.find(program => program.identifier === invitetoken)
      // find AOI issue label 
      var aoiProgramNI = areasOfInterestReference.filter(aoi => aoi.id === programNI.aoi.toString())
      var programNewSteps = {...steps, ...{ step1: { ...steps.step1, ...{ status: 'Completed', data: aoiProgramNI, search: aoiProgramNI }}, step2: { ...steps.step2, ...{ status: 'In Progress', program: true, fixAOI: true, data: {}, bookingdate: null, providerType: 'Psychologist', userTZ: browserTZMS.systemName }}, currentStep: 2 }}
      setStepValue(programNewSteps)
      setaoiTouched(true)
    }
  }
  // when the invite is converted to a client, show the confirmation message and link to the login page
  // Old test for registration completed - left people hanging if APi errored  ((regstatus.form === 'client' && regstatus.status === 'Success' && regstatus.newID) || (invite && invite.hasOwnProperty('clientid') && invite.clientid))
  switch(steps.currentStep) {
    case 1:
      return(
        <PageWrapper setStepValue={setStepValue} steps={steps} providerType={providerType}>
          <AreasOfInterest setStepValue={setStepValue} steps={steps} setaoiTouched={setaoiTouched} providerType={providerType} userTZ={browserTZMS} contract={invite && invite.contract}/>
        </PageWrapper>
      );
    case 2:
      return(
        <PageWrapper setStepValue={setStepValue} steps={steps} providerType={providerType} userTZ={browserTZMS} contract={invite && invite.contract}>
          <SelectPsychologist setStepValue={setStepValue} steps={steps} userTZ={browserTZMS} />
        </PageWrapper>
      );
    case 3:
      return (
        <PageWrapper setStepValue={setStepValue} steps={steps} providerType={providerType} contract={invite && invite.contract}>
          {(steps.step2.data.id && steps.step2.data.id > 0 && (steps.step1.data.length > 0 || providerType === 'GP' || providerType === 'Supervision' || providerType === 'Provisional' || providerType === 'Nurse' || providerType === 'Social' || providerType === 'Occupational') && steps.step2.userTZ && steps.step2.userTZ.length > 0 && steps.step2.bookingdate && steps.step2.bookingdate.length > 0) || (providerType  === 'EAP') || (providerType  === 'NDIS') ?
          <ClientRegistrationForm setStepValue={setStepValue} steps={steps} providerType={providerType}/>
          :
          <ClientRegistrationNotReady setStepValue={setStepValue} steps={steps} providerType={providerType} />
          }
        </PageWrapper>
      )
      case 4:
        return (
          <PageWrapper setStepValue={setStepValue} steps={steps} providerType={providerType}>
            <ClientRegistrationConfirmation steps={steps} providerType={providerType} />
          </PageWrapper>
          )
      case 5:
        return (
          <PageWrapper setStepValue={setStepValue} steps={steps} providerType={providerType}>
            <ClientSupportFormConfirmation steps={steps} providerType={providerType} />
          </PageWrapper>
          )
    
    default: 
        return 'Default';
  }
}

function AreasOfInterest (props) {
 
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 340,
      backgroundColor: theme.palette.background.paper,
      paddingRight: 20,
      marginTop: 14,
      marginLeft: 20
    },
    linkItem: {
      minWidth: 50,
    },
    link: {
      color: 'rgba(0,0,0,0.7)',
      fontSize: 12,
      fontWeight: 400,
    },
    linkSelected: {
      color: 'rgba(0,0,0,1)',
      fontSize: 12,
      fontWeight: 600,
    },
  }));

  const classes = useStyles()
  const dispatch = useDispatch()
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)
  

  // Setup variables for Psychology and Financial Areas of Interest (note GP's do not have areas of interest)
  const PsychologyAreasOfInterest = areasOfInterestReference.filter(area => area.providertype === 'Psychologist')
  const FinancialAreasOfInterest = areasOfInterestReference.filter(area => area.providertype === 'Financial')
  const ProvisionalAreasOfInterest = areasOfInterestReference.filter(area => (area.issue === 'Anxiety' || area.issue === 'Depression' || area.issue === 'Grief'))
  const aoi_focus = PsychologyAreasOfInterest.filter(aoi => aoi.focusAreaIndex !== null).length
  const aoi_other = PsychologyAreasOfInterest.filter(aoi => aoi.focusAreaIndex === null).length
  const invite = useSelector(state => state.registration.ClientInvite)   
  const [checked, setChecked] = useState([...props.steps.step1.data]);

  useEffect( () => { 
    setChecked(props.steps.step1.data);
    }, [setChecked, props.steps.step1.data] )

  const handleToggle = (value) => () => {
    const currentIndex = getIndex(value.id, checked, 'id') //checked.find(r => r.id === value.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const submitAOI = () => {
    props.setaoiTouched(true)
    // Set Title
    let title = '';
    let paymentTypeSelect = '';
    switch(props.providerType)
    {
      case 'Clinical':
      case 'Psychologist':
        title = '2. Select Psychologist';
        break;
      case 'Provisional':
        title = '2. Select Provisional Psychologist';
        paymentTypeSelect = 'Provisional';
        break;
      case 'Financial':
        title = '2. Select Financial Counsellor';
        paymentTypeSelect = 'Reduced Gap';
        break;
      case 'GP':
        title = '2. Select GP';
       break;
       case 'Social':
        title = '2. Select Social Worker';
       break;
       case 'Nurse':
        title = '2. Select Mental Health Nurse';
       break;
       case 'Occupational':
        title = '2. Select Occupational Therapist';
       break;
       case 'Supervision':
        title = '2. Select Supervising Psychologist';
       break;
      default:
        title = '2. Select Psychologist';
        break;
    }

    // Megan has requested any one who ticks 'Perinatal Grief and Loss' is only shown those providers specialising in that AOI (i.e. program)
    // All very hardcoded
    // Thus the code below 
    var specialAOI = checked.filter(aoi => aoi.issue === 'Perinatal Grief and Loss')
    if (specialAOI && specialAOI.length > 0)
    {
      // Ok we found one - lets use that 
      props.setStepValue({ 
        step1: { status: 'Completed', data: [...specialAOI], search: [...specialAOI] }, 
        step2: { ...props.steps.step2, ...{ title: title, status: 'Not Started', program: true, fixAOI: true, data: {}, bookingdate: undefined,providerType: props.providerType, paymenttype : paymentTypeSelect  } }, currentStep: 2 }, )
      // Need to get a new list of providers with the program selected 
      dispatch(Registration.actions.fetchRegisteredPublic({ inviteToken: 'pgnl', providerType : props.providerType}))
    }
    else
    {
      props.setStepValue({ 
        step1: { status: 'Completed', data: [...checked], search: [...checked] }, 
        step2: { ...props.steps.step2, ...{ title: title, status: 'Not Started', data: {}, bookingdate: undefined,providerType: props.providerType, paymenttype : paymentTypeSelect  } }, currentStep: 2 }, )
  
    }
  }

  function getIndex(value, arr, prop) {
    for(var i = 0; i < arr.length; i++) {
        if(arr[i][prop] === value) {
            return i;
        }
    }
    return -1; // where the value doesn't exist
  }

  return(

    <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
      {props.providerType && (props.providerType === 'Psychologist' || props.providerType === 'Clinical' || props.providerType === 'Occupational' || props.providerType === 'Supervision') ?
        <>
          <Row>
            <Col md="12">
              <h2>1. Areas of Interest</h2>
            </Col>
          </Row>
          <Row>
            <Col md="12">                  
              {props.steps.message ? (
                <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                  {props.steps.message}
                </Alert>) 
                : null
              }
            </Col>
          </Row>

            {!invite || (invite && invite.contractid !== 11) ?
              <Row>   
                <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>  
                  <p>If you want to claim your psychology sessions on Medicare you will need a mental health treatment plan from your GP. You can download a flyer with our referral information to show your GP <a href={`https://ruralhealthconnect.com.au/assets/RHC-client-flyer-V02.pdf`} target="ClientGPInformation" style={{ textDecoration: 'underline' }}>here.</a></p>
                </Alert>
              </Row>                
            :
              null
            }

          <hr style={{ marginTop: 4 }}></hr>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>
                  Please choose up to four areas that you are wanting to work on:
                </Form.Label>
                
                  <Grid container style={{ backgroundColor: "rgba(255,255,255,1)", marginTop: 20, paddingBottom: 15 }}>
                  {/* Column 1 */}
                  <Grid item md={6} xs={12}>            
                    <List className={classes.root}>
                      {PsychologyAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                        if(row.focusAreaIndex !== null && row.focusAreaIndex <= aoi_focus/2){
                          const labelId = `checkbox-list-label-${row.id}`;
                          var isChecked = checked.find(r => r.id === row.id);
                          return (
                            <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                              <ListItemIcon style={{ minWidth: 40 }}>
                                <Checkbox
                                  edge="start"
                                  checked={isChecked !== undefined}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                  disableTypography 
                                  primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                  className={classes.link} />
                            </ListItem>
                          );
                        }
                        else
                        {
                          return null
                        }
                      })}
                    </List>
                  </Grid>

                  {/* Column 2 */}
                  <Grid item md={6} xs={12}>            
                    <List className={classes.root}>
                      {PsychologyAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                        if(row.focusAreaIndex !== null && row.focusAreaIndex > aoi_focus/2){
                        const labelId = `checkbox-list-label-${row.id}`;
                        var isChecked = checked.find(r => r.id === row.id);
                        return (
                          <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                            <ListItemIcon style={{ minWidth: 40 }}>
                              <Checkbox
                                edge="start"
                                checked={isChecked !== undefined}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                disableTypography 
                                primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                className={classes.link} />
                          </ListItem>
                        );
                      }
                      else
                      {
                        return null
                      }
                      })}
                    </List>
                  </Grid>
                </Grid>

                <Form.Label style={{ marginTop: 20 }}>
                  Rural Health Connect is part of programs supporting key areas of need. Please tick any that apply to you.
                </Form.Label>

                <Grid container style={{ backgroundColor: "rgba(255,255,255,1)", marginTop: 20, paddingBottom: 15 }}>
                  {/* Column 1 */}
                  <Grid item md={6} xs={12}>            
                    <List className={classes.root}>
                      {PsychologyAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                        if(row.focusAreaIndex === null && row.index <= aoi_other/2){
                          const labelId = `checkbox-list-label-${row.id}`;
                          var isChecked = checked.find(r => r.id === row.id);
                          return (
                            <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                              <ListItemIcon style={{ minWidth: 40 }}>
                                <Checkbox
                                  edge="start"
                                  checked={isChecked !== undefined}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                  disableTypography 
                                  primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                  className={classes.link} />
                            </ListItem>
                          );
                        }
                        else
                        {
                          return null
                        }
                      })}
                    </List>
                  </Grid>

                  {/* Column 2 */}
                  <Grid item md={6} xs={12}>            
                    <List className={classes.root}>
                      {PsychologyAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                        if(row.focusAreaIndex === null && row.index > aoi_other/2){
                        const labelId = `checkbox-list-label-${row.id}`;
                        var isChecked = checked.find(r => r.id === row.id);
                        return (
                          <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                            <ListItemIcon style={{ minWidth: 40 }}>
                              <Checkbox
                                edge="start"
                                checked={isChecked !== undefined}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                disableTypography 
                                primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                className={classes.link} />
                          </ListItem>
                        );
                      }
                      else
                      {
                        return null
                      }
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Form.Group>
            </Col>
          </Row>
        </>
      :
        <>
          {props.providerType && props.providerType === 'Provisional' ?
            <>
              <Row>
                <Col md="12">
                  <h2>1. Areas of Interest</h2>
                </Col>
              </Row>
              <Row>
                <Col md="12">                  
                  {props.steps.message ? (
                    <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                      {props.steps.message}
                    </Alert>) 
                    : null
                  }
                </Col>
              </Row>                
              <hr style={{ marginTop: 4 }}></hr>
              <Row>
                <Col md="12">
                  <Form.Group >
                    <Form.Label>
                      Please tell us if you are part of any of these key target groups for our support, and then nominate what you are wanting to work on, below:
                    </Form.Label>
                    
                      <Grid container style={{ backgroundColor: "rgba(255,255,255,1)", marginTop: 20, paddingBottom: 15 }}>
                      {/* Column 1 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {ProvisionalAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.focusAreaIndex !== null && row.focusAreaIndex <= aoi_focus/2){
                              const labelId = `checkbox-list-label-${row.id}`;
                              var isChecked = checked.find(r => r.id === row.id);
                              return (
                                <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked !== undefined}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              );
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Grid>

                      {/* Column 2 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {ProvisionalAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.focusAreaIndex !== null && row.focusAreaIndex > aoi_focus/2){
                            const labelId = `checkbox-list-label-${row.id}`;
                            var isChecked = checked.find(r => r.id === row.id);
                            return (
                              <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                <ListItemIcon style={{ minWidth: 40 }}>
                                  <Checkbox
                                    edge="start"
                                    checked={isChecked !== undefined}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                    disableTypography 
                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                    className={classes.link} />
                              </ListItem>
                            );
                          }
                          else
                          {
                            return null
                          }
                          })}
                        </List>
                      </Grid>
                    </Grid>

                    <Grid container style={{ backgroundColor: "rgba(255,255,255,1)", marginTop: 20, paddingBottom: 15 }}>
                      {/* Column 1 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {ProvisionalAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.focusAreaIndex === null && row.index <= aoi_other/2){
                              const labelId = `checkbox-list-label-${row.id}`;
                              var isChecked = checked.find(r => r.id === row.id);
                              return (
                                <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked !== undefined}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              );
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Grid>

                      {/* Column 2 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {ProvisionalAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.focusAreaIndex === null && row.index > aoi_other/2){
                            const labelId = `checkbox-list-label-${row.id}`;
                            var isChecked = checked.find(r => r.id === row.id);
                            return (
                              <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                <ListItemIcon style={{ minWidth: 40 }}>
                                  <Checkbox
                                    edge="start"
                                    checked={isChecked !== undefined}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                    disableTypography 
                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                    className={classes.link} />
                              </ListItem>
                            );
                          }
                          else
                          {
                            return null
                          }
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </Form.Group>
                </Col>
              </Row>
            </>
          :
            <>
              <Row>
                <Col md="12">
                  <h2>1. Locations</h2>
                </Col>
              </Row>
              <Row>
                <Col md="12">                  
                  <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                    <p>This is currently a pilot covering Southern Queensland, Gippsland and North East Victoria only as part of bushfire recovery efforts, it is open to any resident in these regions. If you are outside these areas and would like to speak to a financial counsellor please call 1300 771 741.</p>
                    <p>Sessions are free of charge and are funded by the Commonwealth Department of Agriculture and the Victorian and Queensland Governments.</p> 
                    <p>There are different financial counsellors for people in different situations: "<strong>Primary Producer</strong>" counselling is for farmers, "<strong>Small Business</strong>" counselling is for small business operations and "<strong>Individual</strong>" is for people wanting help with their personal finances.</p> 
                    <p>Please choose the option and location right for you.</p>
                  </Alert> 
                </Col>
              </Row>
              <hr style={{ marginTop: 4 }}></hr>
              <Row>
                <Col md="12">
                  <Form.Group >
                    <Form.Label>
                    Please select the type of counsellor and the location that is right for you.
                    </Form.Label>

                    <Grid container style={{ backgroundColor: "rgba(255,255,255,1)", marginTop: 20, paddingBottom: 15 }}>
                      {/* Column 1 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {FinancialAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.index <= aoi_other/2){
                              const labelId = `checkbox-list-label-${row.id}`;
                              var isChecked = checked.find(r => r.id === row.id);
                              return (
                                <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked !== undefined}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              );
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Grid>

                      {/* Column 2 */}
                      <Grid item md={6} xs={12}>            
                        <List className={classes.root}>
                          {FinancialAreasOfInterest.filter(aoi => aoi.psychologistsCount > 0).map(row => {
                            if(row.index > aoi_other/2){
                            const labelId = `checkbox-list-label-${row.id}`;
                            var isChecked = checked.find(r => r.id === row.id);
                            return (
                              <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                <ListItemIcon style={{ minWidth: 40 }}>
                                  <Checkbox
                                    edge="start"
                                    checked={isChecked !== undefined}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                    disableTypography 
                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                    className={classes.link} />
                              </ListItem>
                            );
                          }
                          else
                          {
                            return null
                          }
                          })}
                        </List>
                      </Grid>
                    </Grid>

                  </Form.Group>
                </Col>
              </Row>
            </>
          }
        </>
      }
      <Row>
        <Col md="12">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={3} xs={6}>
              
            </Grid>
            <Grid item md={2} xs={6} style={{ textAlign: "right"}}>
              <Button variant="primary" type="submit" onClick={() => submitAOI()}>
                Save &amp; Next &gt;
              </Button>
            </Grid>
          </Grid>
        </Col>
      </Row>
    </Paper>

  )
}

function SelectPsychologist (props) {

  const dispatch = useDispatch()
  const { invitetoken } = useParams();
  const registered = useSelector(state => state.registration.Registered);
  const registeredFetch = useSelector(state => state.registration.RegisteredFetch);
  const matchingAvailability = useSelector(state => state.registration.MatchingAvailability)
  const timeZone = useSelector(state => state.reference.TimeZone)
  const invite = useSelector(state => state.registration.ClientInvite)   
  const searchAOI = [...props.steps.step1.search];
  const filters = useSelector(state => state.admin.Filters)
  var [currentTimeZone, setcurrentTimeZone] = useState(props.steps.step2.userTZ ? props.steps.step2.userTZ : props.userTZ && props.userTZ.systemName); 
  var [currentPsychologist, setcurrentPsychologist] = useState({...props.steps.step2.data});
  var [showNewBooking, setShowNewBooking] = useState(false);
  var [currentbookingdate, setcurrentbookingdate] = useState(props.steps.step2.bookingdate);
  var [showpopoverTimeZone, setshowpopoverTimeZone] = useState(!props.steps.step2.userTZ)
  // var [bookingConfirmed, setbookingConfirmed] = useState(!!props.steps.step2.bookingdate);
  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  var [providerType] = useState(props.steps.step2.providerType);
  const [previousPaymentType,setPreviousPaymentType] = useState('')
  const [previousReducedGapReason,setPreviousReducedGapReason] = useState('')
  const [previousBulkBilledReason,setPreviousBulkBilledReason] = useState('')
  // const [displayContactForm,setDisplayContactForm] = useState(false);
  const [displayNoProvisionalMessage,setDisplayNoProvisionalMessage] = useState(false);
  const [expanded, setExpanded] = useState(0)
  const [showProviderBio,setShowProviderBio] = useState(false)
  const [providerBioFullName,setProviderBioFullName] = useState()
  const [providerBioBio,setProviderBioBio] = useState()


  // now init the fields with values so that Formik can manage the touched state
  const clientInit = {
    firstname: '', 
    preferredname: '',
    surname: '', 
    email: '', 
    phone: '',
    clientFundingTypeID: 1,
    dob: undefined,
    gender: '',
    occupation: '',
    fulladdress: '',
    latitude: '',
    longitude: '',
    postcode: '',
    timezone: props.steps.step2.userTZ,
    clientAreasOfInterest: [...props.steps.step1.data],
    hopeToGetFromTreatment: '',
    emergencycontactname1: '',
    emergencycontactphone1: '',
    emergencycontactrelation1: '',
    emergencycontactname2: '',
    emergencycontactphone2: '',
    emergencycontactrelation2: '',
    profilePassword: '',
    profilePasswordConfirmation: '',
    medicareno: '',
    medicarereferenceno: '',
    medicareexpirydate: '',
    appointmenttype: '0',
    MHCPFile: '',
    invitetoken: invitetoken,
    agreementAcceptance:false,
    clientid : null,
    paymenttype: invitetoken && invitetoken.paymenttype ? invitetoken.paymenttype : '',
    privateClient: false,
    referrerid: null,
    abn : '',
    businessName : '',
    businessAddress : '',
    backgroundinformation : '',
    gpname : '',
    gpclinicname: '',
    healthCareCard: invitetoken && invitetoken.paymenttype === 'Bulk Billed' ? 'Y' : '',
    unemployed: 'NA',
    naturalDisaster: 'NA',
    bulkBilledReason: '',
    contract: false,
    providerType: props.providerType,
    locality: ''

  }

  const handleExpandClick = (id) => {
    if(id === expanded) {
      setExpanded(0)
    } else {
      setExpanded(id);
    }
  };

  // const handleExpandPricingDetailClick = (id) => {
  //   if(id === expandPricingDetail) {
  //     setExpandPricingDetail(0)
  //   } else {
  //     setExpandPricingDetail(id);
  //   }
  // };

  // Called by values changing in Formik - useEffect only fires for certain values
  const PopulateProviders = () => {
    const { values } = useFormikContext();
        useEffect(() => {
          if (values.reducedGapReason !== previousReducedGapReason)
          {
            if (values.reducedGapReason !== '')
            {
              // Set ReducedGapReason
              props.setStepValue({ step2: { ...props.steps.step2, reducedGapReason : values.reducedGapReason }})
              setPreviousReducedGapReason(values.reducedGapReason)
            }
          }
          // See if bulkBilledReason value has changed 
          if (values.bulkBilledReason !== previousBulkBilledReason)
          {
            if (values.bulkBilledReason !== '')
            {
              // Set BulkBilledReason
              props.setStepValue({ step2: { ...props.steps.step2, bulkBilledReason : values.bulkBilledReason }})
              setPreviousBulkBilledReason(values.bulkBilledReason)
            }
          }
          // See if the payment type value has changed 
          if (values.paymenttype !== previousPaymentType)
          {
            if (values.paymenttype !== '')
            {
              // Set Payment Type 
              props.setStepValue({ step2: { ...props.steps.step2, paymenttype : values.paymenttype, healthCareCard : values.healthCareCard, bulkBilledReason : values.bulkBilledReason, reducedGapReason : values.reducedGapReason  }})
              setPreviousPaymentType(values.paymenttype)
            }
            else
            {
              setPreviousPaymentType(values.paymenttype)
            }
 
          }
        }, [values.paymenttype,values.healthCareCard,values.reducedGapReason,values.bulkBilledReason]);
        return null;
      };
  
  useEffect( () => { dispatch(Registration.actions.fetchMatchingAvailability({ psychologistID: currentPsychologist.id, userTZ: currentTimeZone, providerType: providerType, invitetoken: invitetoken }))  }, [dispatch, currentPsychologist, currentTimeZone, providerType, props.invitetoken,invitetoken] )

  const searchPsychologist = (p) => {
    if (  true
          // match gender to select option
          // && (p.gender === searchGender || searchGender === 'Any')
          // if there is AOI selections, then return if number of matches in psychologist equals the number of options in the selection
          // && (!searchAOI || (searchAOI && searchAOI.length === 0) || (searchAOI && searchAOI.filter(( {issue} ) => p.areasOfInterest.includes(issue)).length === searchAOI.length) )
          // search years experience
          // && (p.experience > parseInt(searchExperience) || parseInt(searchExperience) === 0)
          // search fullname for search string
          && ( p.id === currentPsychologist.id || currentPsychologist.id === undefined)
          // Note the contractid 11 reference below is related to the NthQLDPHN project - if the client registering is part of this project then any providertype will do
          && ((invite && invite.contractid === 11) || (props.steps.step2.providerType !== 'Psychologist' && p.providertype === props.steps.step2.providerType)  || (props.steps.step2.providerType === 'Psychologist' && (p.providertype === 'Clinical' || p.providertype === 'Psychologist' || p.providertype === 'Occupational'))) 
    )
    {
      return p
    }
  }

  const searchAOISpecificPsychologist = (p) => {
    if (  true
          // match gender to select option
          // && (p.gender === searchGender || searchGender === 'Any')
          // if there is AOI selections, then return if number of matches in psychologist equals the number of options in the selection
          && (!searchAOI || (searchAOI && searchAOI.length === 0) || (searchAOI && searchAOI.filter(( {issue} ) => p.areasOfInterest.includes(issue)).length === searchAOI.length) )
          // search years experience
          // && (p.experience > parseInt(searchExperience) || parseInt(searchExperience) === 0)
          // search fullname for search string
          && ( p.id === currentPsychologist.id || currentPsychologist.id === undefined)
          && ((props.steps.step2.providerType !== 'Psychologist' && p.providertype === props.steps.step2.providerType)  || (props.steps.step2.providerType === 'Psychologist' && (p.providertype === 'Clinical' || p.providertype === 'Psychologist' || p.providertype === 'Occupational'))) 
    )
    {
      return p
    }
  }


  function compareAoiCount( a, b ) {
    if ( a.aoiCount < b.aoiCount ){
      return 1;
    }
    if ( a.aoiCount > b.aoiCount ){
      return -1;
    }
    return 0;
  }

  function compareStandardCost( a, b ) {
    var partA = parseFloat(a.standardFeeOutOfPocket.substring(1,a.standardFeeOutOfPocket.length-1))
    var partB = parseFloat(b.standardFeeOutOfPocket.substring(1,b.standardFeeOutOfPocket.length-1))
    if ( partA > partB ){
      return 1;
    }
    if ( partA < partB ){
      return -1;
    }
    return 0;
  }

  function compareReducedCost( a, b ) {
    var partA = parseFloat(a.reducedFeeOutOfPocket.substring(1,a.reducedFeeOutOfPocket.length-1))
    var partB = parseFloat(b.reducedFeeOutOfPocket.substring(1,b.reducedFeeOutOfPocket.length-1))
    if ( partA > partB ){
      return 1;
    }
    if ( partA < partB ){
      return -1;
    }
    return 0;
  }

   // nextAvailable
  // function compareNextAvailable( a, b ) {
  //   // console.log(a.nextAvailable)
  //   var partsA = a.nextAvailable.split("/");
  //   var dateA = new Date(parseInt(partsA[2], 10),
  //                     parseInt(partsA[1], 10) - 1,
  //                     parseInt(partsA[0], 10)).getTime()    
  //   // console.log(dateA)

  //   // console.log(b.nextAvailable)
  //   var partsB = b.nextAvailable.split("/");
  //   var dateB = new Date(parseInt(partsB[2], 10),
  //                     parseInt(partsB[1], 10) - 1,
  //                     parseInt(partsB[0], 10)).getTime()
  //   // console.log(dateB)
  //   return dateA > dateB ? 1 : -1;      
  //   // return 0;
  // }

  // var filteredPsychologists = registered.filter(p => searchPsychologist(p))
  const temp = registered.map(row => { return {aoiCount: row.areasOfInterest.filter((issue) => searchAOI.some( aoi => aoi.issue === issue)).length, ...row } })
  // If the ProviderType is EAP then display all Providers with availability other use AOI
  // If fixAOI is set then only match on that AOI - nothing else

  // Some documentation about the ever growing complexity of the provider order 
  // Condition 1 - If the ProviderType is EAP then display all Providers with availability 
  // Condition 2 - If the type is fixAOI (i.e. Perinatal Loss etc.) then only match on that AOI - nothing else 
  // Condition 3 - Sort Order - if sort order filter (clientRegistrationSort) set to cost 
  // Condition 4 - Default Sort Order (i.e. Next Available) then do the usual 
  // 
  var filteredPsychologists = 
    providerType === 'EAP' ? registered.filter(p => searchPsychologist(p)) : 
    props.steps.step2.fixAOI ? registered.filter(p => searchAOISpecificPsychologist(p)) : 
    filters.clientRegistrationSort === 'Cost' && props.steps.step2.paymenttype === 'Full Fee' ? temp.sort(compareStandardCost).filter(p => searchPsychologist(p)) :
    filters.clientRegistrationSort === 'Cost' && props.steps.step2.paymenttype === 'Reduced Gap' ? temp.sort(compareReducedCost).filter(p => searchPsychologist(p)) :
    temp.sort((a,b) => a.nextAvailable.localeCompare(b.nextAvailable)).sort(compareAoiCount).filter(p => searchPsychologist(p))

  // console.log(temp)
  useEffect( () => { 
    
    // console.log('In Use Effect')
    // console.log('FilteredPsychologists')
    // console.log(filteredPsychologists)
    // console.log('Registered')
    // console.log(registered)
    // console.log('Search')
    // console.log(props.steps.step1.search)

    if(filteredPsychologists.length < 1 && registered.length > 0)
    {
      // If fixAOI is set then only match on that AOI - nothing else
      if(props.steps.step1.search.length > 0 && !props.steps.step2.fixAOI)
      {
        var newAOI = [...props.steps.step1.search];
        var existingAOI = [...props.steps.step1.data];
        newAOI.pop();
        props.setStepValue({ step1: { status: 'Completed', data: existingAOI, search: newAOI }, currentStep: 2 }, )
      }
      else
      {
        // Ok we could not find a provider available regardless of the area of interest selection 
        // Check if Provisional - we display a contact form for them 
        if (providerType === 'Provisional')
        {
          //setDisplayContactForm(true);
          setDisplayNoProvisionalMessage(true)
        }
        // May need to add these actions to all 
      }
    }
    // Registered is fetching by provider type so it is possible there will be no registered response for Provisional 
    if(filteredPsychologists.length < 1 && registeredFetch && registered.length === 0 && providerType === 'Provisional')
    {
      //setDisplayContactForm(true);
      setDisplayNoProvisionalMessage(true)
    }
    else
    {
      setDisplayNoProvisionalMessage(false)
    }


  }, [filteredPsychologists, registered, props, providerType, registeredFetch] )

  // const handleExpandClick = (id) => {
  //   if(id === expanded) {
  //     setExpanded(0)
  //   } else {
  //     setExpanded(id);
  //   }
  // };

  function handleTimeZoneAutocompleteChange(propertyName, event, newValue) {   
    if (newValue) {
      props.setStepValue({ step2: { ...props.steps.step2, status: 'In Progress', data: {...currentPsychologist}, bookingdate: undefined, providerType : providerType,userTZ: newValue.systemName }, currentStep: 2 })
      setcurrentTimeZone(newValue.systemName);
      setshowpopoverTimeZone(false)
    }
  } 

  const submitPsychologist = () => {
    if(currentPsychologist.id !== undefined && currentbookingdate !== undefined) {
      props.setStepValue({ step2: { ...props.steps.step2, status: 'Completed', data: {...currentPsychologist}, bookingdate: currentbookingdate, providerType : providerType, userTZ: currentTimeZone }, currentStep: 3 })
    }
  }

  const isMobile = useMediaQuery('(max-width: 760px)');
  
  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: 200,
      // maxWidth: 200,
    },
    media: {
      height: 240,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));
  const classes = useStyles();

  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 

  const startNewBooking = (bookingdate) =>{
    setcurrentbookingdate(bookingdate)
    setShowNewBooking(true)
  }

  const saveAppointment = () => {
    // Need to consider this further - need to support removing appointments as well
    props.setStepValue({ step2: { ...props.steps.step2, status: 'Completed', data: {...currentPsychologist}, bookingdate: currentbookingdate, providerType : providerType, userTZ: currentTimeZone }, currentStep: 2 })
    setShowNewBooking(false)
  }

  const popoverTimeZone = (
    <Popover id="popover-basic" className="popover-instruction">
      <Popover.Title as="h3">TimeZone</Popover.Title>
      <Popover.Content>
        Next, <strong>select</strong> your timezone so that we can look up your Providers's availability. 
      </Popover.Content>
    </Popover>
  );

  // const popoverBooking = (
  //   // !bookingConfirmed ? 
  //   <Popover id="popover-basic" className="popover-instruction">
  //     <Popover.Title as="h3">Make a Booking</Popover.Title>
  //     <Popover.Content>
  //       You must select a timezone and make a booking to continue. 
  //     </Popover.Content>
  //   </Popover>
  //   // :
  //   // <div id="popover-basic" style={{ display: 'none' }} className="popover-instruction">Here</div>
  // );

  var rowcount = 0 //count of bookings displayed

  // get the invite (if any) from state and merge with the initialised fields
  const inviteInit = useSelector(state => state.registration.ClientInvite)
  const [clientInvite, setClientInvite] = useState({...clientInit, ...inviteInit, steps: props.steps});
  const [previousSteps, setPreviousSteps] = useState()

  // when user submits payment type form, we set it to the steps object, thus need to reload the formik form values
  useEffect( ()=> {
    if (previousSteps !== props.steps)
    {
      setClientInvite({ ...clientInvite, 
        steps: { ...props.steps },
        providerType: props.steps.step2.providerType,
        paymenttype: props.steps.step2.paymenttype, 
        healthCareCard: props.steps.step2.paymenttype === 'Bulk Billed' ? 'Y' : '',
        unemployed: props.steps.step2.unemployed,
        naturalDisaster: props.steps.step2.naturalDisaster,
        bulkBilledReason: props.steps.step2.bulkBilledReason,
        reducedGapReason: props.steps.step2.reducedGapReason
      })
    }
    setPreviousSteps(props.steps)
  }, [props.steps.step2,props.steps,clientInvite,previousSteps])


  const validationSchema = Yup.object().shape({
    healthCareCard: Yup.string()  
      .when(['providerType','paymenttype'],
      {
        is: (providerType,paymenttype) =>  paymenttype === 'Bulk Billed' && (providerType === 'Clinical' || providerType === 'Psychologist' || providerType === 'Occupational'),
        then: Yup.string().oneOf(['Y', 'N'])
                          .required('Please advise if you have a Health Care Card')
      }),
  });

  const moreProviderBio = (fullName, bio) => {
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

  function submitForm(data) {
    props.setStepValue({ step2: { 
      status: 'In Progress', 
      data: {}, 
      bookingdate: currentbookingdate, 
      providerType : providerType, 
      userTZ: currentTimeZone,
      paymenttype: data.paymenttype,
      healthCareCard: data.healthCareCard,
      unemployed: data.unemployed,
      naturalDisaster: data.naturalDisaster,
      bulkBilledReason: data.bulkBilledReason,
      reducedGapReason: data.reducedGapReason
     }, currentStep: 2 })
  }

  // function submitContactForm(data){
  //   dispatch(Registration.actions.postContactFormEntry(data))
  //   props.setStepValue({ step2: { ...props.steps.step2, status: 'Not Started', data: {}, bookingdate: null, providerType : providerType, userTZ: currentTimeZone }, currentStep: 5 })
  //   // setStatus('Submitted')
  // }

  // const contactForm = {
  //   firstname: '', 
  //   surname: '',
  //   email: '', 
  //   phone: '',
  //   locality: ''
  // }

  // const contactFormBValidationSchema = Yup.object().shape({
  //     firstname:  Yup.string()
  //     .min(2,"Too Short")
  //     .max(255,"Too Long")
  //     .required("First Name is required"),
  //     surname:  Yup.string()
  //     .min(2,"Too Short")
  //     .max(255,"Too Long")
  //     .required("Surname is required"),
  //     email: Yup.string()
  //     .min(2,"Too Short")
  //     .max(200,"Too Long")
  //     .email("Enter a valid email")
  //     .required("Email is required"),
  //     phone:  Yup.string()
  //     .min(2,"Too Short")
  //     .max(12,"Too Long")
  //     .required("Phone Number is required"),
  //     locality:  Yup.string()
  //     .min(2,"Too Short")
  //     .max(250,"Too Long")
  //     .required('Locality is required'),
  //   });

  if (displayNoProvisionalMessage)
  {
    return(
      <>
        <Paper style={{ padding: 20, backgroundColor: "rgb(245,245,245)" }}> 
          <Row>
            <Col md="12">
              {providerType && providerType === 'Financial' ?
                <h2>2. Financial Counsellor</h2>
              :
                <>
                  {providerType && providerType === 'GP' ? 
                    <h2>2. GP</h2>
                  :
                    <>
                      {providerType && providerType === 'Provisional'?
                        <h2>2. Provisional Psychologist</h2>
                      :
                        <>
                          {providerType && providerType === 'Social'?
                            <h2>2. Social Worker</h2>
                          :
                            <>
                            {providerType && providerType === 'Nurse'?
                              <h2>2. Mental Health Nurse</h2>
                            :
                              <>
                                {providerType && providerType === 'Occupational'?
                                  <h2>2. Occupational Therapist</h2>
                                :
                                  <>
                                    {providerType && providerType === 'Supervision'?
                                      <h2>2. Supervising Psychologist</h2>
                                    :
                                      <>
                                        {clientInvite && clientInvite.contract ?
                                          <h2>2. Practitioner</h2>
                                        :
                                          <h2>2. Psychologist</h2>
                                        }
                                      </>
                                    }
                                  </>
                                }
                              </>
                            }
                            </>
                          }  
                        </>
                      }
                    </>
                  }
                </>
              }
            </Col>
          </Row>
          <hr style={{ marginTop: 4 }}></hr>
          <Alert severity="error" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
            <h4 style={{ marginBottom: 15 }}>The Rural Recovery Provisional Psychologist program is currently at capacity.</h4>
            <p>You can book a registered psychologist under a mental health treatment plan. </p>
            <p>Book a registered psychologist <a href={`https://portal.ruralhealthconnect.com.au/clientregistration/new`} style={{ textDecoration: 'underline' }}>here.</a></p>          
            <p>Download Rural Health Connect flyer with referral information to show your GP <a href={`https://ruralhealthconnect.com.au/assets/RHC-client-flyer-V02.pdf`} target="ClientGPInformation" style={{ textDecoration: 'underline' }}>here.</a></p>
          </Alert>
          <hr style={{ marginTop: 4 }}></hr>
        </Paper>
      </>
    )
  }
  // No using the contact form at the moment - however it may be back later 
  // if (displayContactForm)
  // {
  //   return(
  //     <>
  //       <Paper style={{ padding: 20, backgroundColor: "rgb(245,245,245)" }}> 
  //         <Row>
  //           <Col md="12">
  //             {providerType && providerType === 'Financial' ?
  //               <h2>2. Financial Counsellor</h2>
  //             :
  //               <>
  //                 {providerType && providerType === 'GP' ? 
  //                   <h2>2. GP</h2>
  //                 :
  //                   <>
  //                     {providerType && providerType === 'Provisional'?
  //                       <h2>2. Provisional Psychologist</h2>
  //                     :
  //                       <>
  //                         {providerType && providerType === 'Social'?
  //                           <h2>2. Social Worker</h2>
  //                         :
  //                           <>
  //                             {providerType && providerType === 'Occupational'?
  //                               <h2>2. Occupational Therapist</h2>
  //                             :
  //                               <h2>2. Psychologist</h2>
  //                             }
  //                           </>
  //                         }  
  //                       </>
  //                     }
  //                   </>
  //                 }
  //               </>
  //             }
  //           </Col>
  //         </Row>
  //         <hr style={{ marginTop: 4 }}></hr>
  //         <Formik
  //           enableReinitialize={true}
  //           initialValues={contactForm}
  //           validationSchema = {contactFormBValidationSchema }
  //           onSubmit={(values, actions) => {
  //             submitContactForm(values);
  //             actions.setSubmitting(false);
  //           }}
  //         >
  //           {props => {
  //             const { values, errors, touched, handleChange, handleSubmit } = props;
  //             const change = (name,e) => { e.persist();  handleChange(e); };
  //             return (
  //               <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >
  //                 <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20, maxWidth: 800 }}>
  //                   {/* <Row>
  //                     <Col md="12">
  //                       <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
  //                         <Grid item md={9} xs={12}>
  //                           <h2>Referrer Registration of Interest Form</h2>
  //                         </Grid>
  //                         <Grid item md={3} xs={12} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
  //                           * indicates required fields
  //                         </Grid>
  //                       </Grid>
  //                     </Col>
  //                   </Row>
  //                   <hr style={{ marginTop: 4 }}></hr> */}
  //                   <Alert  style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
  //                     <p>Unfortunately there are no appointments available at this time. If you would like someone to call you back to arrange an appointment please enter your details here and we will be in touch:</p>
  //                   </Alert>
  //                   <hr style={{ marginTop: 4 }}></hr>
  //                   <Row>
  //                     <Col md="4">
  //                       <Form.Group >
  //                         <Form.Label>First Name *</Form.Label>
  //                         <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
  //                         <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
  //                       </Form.Group>
  //                     </Col>
  //                     <Col md="4">
  //                       <Form.Group >
  //                         <Form.Label>Surname *</Form.Label>
  //                         <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
  //                         <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
  //                       </Form.Group>
  //                     </Col>
  //                   </Row>
  //                   <Row>
  //                     <Col md="8">
  //                         <Form.Group >
  //                         <Form.Label>Email *</Form.Label>
  //                         <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
  //                         <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
  //                         </Form.Group>
  //                     </Col>
  //                     <Col md="4">
  //                         <Form.Group >
  //                         <Form.Label>Phone Number *</Form.Label>
  //                         <Form.Control type="text" name="phone" value={values.phone} onChange={change.bind(null, "phone")} isInvalid={touched.phone && !!errors.phone} isValid={touched.phone && !errors.phone} autoComplete='new-password' />
  //                         <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
  //                         </Form.Group>
  //                     </Col>                
  //                   </Row>
  //                   <Row>
  //                     <Col md="4">
  //                       <Form.Group>
  //                         <Form.Label>Locality *</Form.Label>
  //                         <Form.Control type="text" name="locality" value={values.locality} onChange={change.bind(null, "locality")} isInvalid={touched.locality && !!errors.locality} isValid={touched.locality && !errors.locality} autoComplete='new-password' />
  //                         <Form.Control.Feedback type="invalid">{errors.locality}</Form.Control.Feedback>
  //                       </Form.Group>
  //                     </Col>
  //                   </Row>
  //                   <Row>
  //                     <Col md="12">
  //                       <Grid container direction="row" justifyContent="space-between">
  //                         <Grid item md={3} xs={6}> 

  //                         </Grid>
  //                         <Grid item md={1} xs={6}>
  //                           <Button variant="primary" type="submit" >
  //                             Submit
  //                           </Button>
  //                         </Grid>
  //                       </Grid>
  //                     </Col>
  //                   </Row>
  //                 </Paper>
  //               </form>
  //             )
  //           }}
  //         </Formik>
  //       </Paper>
  //     </>
  //   )
  // }
  else
  {
    return(
      <>
        <Paper style={{ padding: 20, backgroundColor: "rgb(245,245,245)" }}> 
          <Row>
            <Col md="12">
              {providerType && providerType === 'Financial' ?
                <h2>2. Financial Counsellor</h2>
              :
                <>
                  {providerType && providerType === 'GP' ? 
                    <h2>2. GP</h2>
                    :
                    <>
                      {providerType && providerType === 'Provisional'?
                        <h2>2. Provisional Psychologist</h2>
                      :
                        <>
                          {providerType && providerType === 'Social'?
                            <h2>2. Social Worker</h2>
                          :
                            <>
                              {providerType && providerType === 'Nurse'?
                                <h2>2. Mental Health Nurse</h2>
                              :
                                <>
                                  {providerType && providerType === 'Occupational'?
                                    <h2>2. Occupational Therapist</h2>
                                  :
                                    <>
                                      {providerType && providerType === 'Supervision'?
                                        <h2>2. Supervising Psychologist</h2>
                                      :
                                        <>
                                          {clientInvite && clientInvite.contract ?
                                            <h2>2. Practitioner</h2>
                                          :
                                            <h2>2. Psychologist</h2>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                </>
                              }
                            </>
                          }  
                        </>
                      }
                    </>
                  }
                </>
              }
            </Col>
          </Row>
          <hr style={{ marginTop: 4 }}></hr>
          <Formik
            enableReinitialize={true}
            initialValues={clientInvite}
            validationSchema = {validationSchema}
            onSubmit={(values, actions) => {
              submitForm(values);
              // actions.setSubmitting(false);
            }}
          >
          {props => {
            const { values, errors, touched, handleChange, handleSubmit } = props;
            const change = (name,e) => { e.persist();  handleChange(e); };
            return (
              <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >
                <PopulateProviders />
                {providerType && (providerType === 'Psychologist' || providerType === 'Nurse' || providerType === 'Social' || providerType === 'Occupational' || providerType === 'Clinical') && (!clientInvite.contract) ?
                  <>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <Form.Label style={{ fontWeight: 500 }}>Payment Options</Form.Label>
                          <p>We want everyone be able to access a psychologist and therefore have a range of pricing options. Reduced fee places are limited so please only apply if it suits your circumstances:</p>
                          <Form.Check id="payment-fullfee" name="paymenttype" type="radio" style={{ marginBottom: 8 }}>
                            <Form.Check.Input name="paymenttype" type="radio" value="Full Fee"  checked={values.paymenttype === 'Full Fee' ? true : false} onChange={change.bind(null, "paymenttype")}  />
                            <Form.Check.Label style={{marginLeft: 12 }}>STANDARD FEE: I am employed and/or am not experiencing financial hardship </Form.Check.Label>
                          </Form.Check>
                          <Form.Check id="payment-reducedgap" name="paymenttype" type="radio" style={{ marginBottom: 8 }} >
                            <Form.Check.Input name="paymenttype" type="radio" value="Reduced Gap"  checked={values.paymenttype === 'Reduced Gap' ? true : false} onChange={change.bind(null, "paymenttype")} />
                            <Form.Check.Label style={{marginLeft: 12 }}>REDUCED FEE: I am experiencing financial hardship and would like to apply for the psychologist's reduced gap fee option.  </Form.Check.Label>
                          </Form.Check>
                          {values.paymenttype === 'Reduced Gap' ?
                            <>
                            <Row>
                                <Col md="12">
                                  <Form.Group >
                                    <Form.Label>Please tell us about your circumstances in a few sentences</Form.Label>
                                    <Form.Control as="textarea" rows={4} type="text" name="reducedGapReason" value={values.reducedGapReason}  onChange={change.bind(null, "reducedGapReason")} isInvalid={touched.reducedGapReason && !!errors.reducedGapReason} isValid={touched.reducedGapReason && !errors.reducedGapReason} autoComplete='new-password' />
                                    <Form.Control.Feedback type="invalid">{errors.reducedGapReason}</Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                          </>
                          :
                            null
                          }
                          <Form.Check id="payment-bulkbilled" name="paymenttype" type="radio" style={{ marginTop: 20, marginBottom: 8 }} >
                            <Form.Check.Input name="paymenttype" type="radio" value="Bulk Billed" checked={values.paymenttype === 'Bulk Billed' ? true : false} onChange={change.bind(null, "paymenttype")} />
                            <Form.Check.Label style={{marginLeft: 12 }}>​BULK BILL(limited sessions available) We have some bulk billed sessions available for those most in need. {props.steps && props.steps.step2 && !props.steps.step2.program ? 'For Healthcare, Pension or Disability card holders only.' : null } </Form.Check.Label>
                          </Form.Check>                      
                          {values.paymenttype === 'Bulk Billed' ?
                            <>
                            <Row>
                                <Col md="12">
                                  <Form.Group >
                                    <Form.Label>Please tell us about your circumstances in a few sentences</Form.Label>
                                    <Form.Control as="textarea" rows={4} type="text" name="bulkBilledReason" value={values.bulkBilledReason}  onChange={change.bind(null, "bulkBilledReason")} isInvalid={touched.bulkBilledReason && !!errors.bulkBilledReason} isValid={touched.bulkBilledReason && !errors.bulkBilledReason} autoComplete='new-password' />
                                    <Form.Control.Feedback type="invalid">{errors.bulkBilledReason}</Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                          </>
                          :
                            null
                          }                          
                        </Form.Group>
                      </Col>
                    </Row>               
                    <Row>
                      <Col md="12">
                        <Grid container direction="row" justifyContent="space-between">
                          <Grid item md={3} xs={6}>
                          </Grid>
                          <Grid item md={2} xs={6} style={{ textAlign: "right"}}>
                            <Button variant="primary" type="submit">
                              {values.steps.step2.status === 'Not Started'? 'Save & Next' : 'Update'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Col>
                    </Row>
                  </>
                :
                  <>
                    {providerType && providerType === 'Supervision' ?
                      <>
                        <hr style={{ marginTop: 4 }}></hr>
                        <Alert severity="info" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
                          <h5 style={{ marginBottom: 15 }}>Any income generated by Rural Health Connect through this program goes directly to programs supporting disaster impacted rural Australians. Thank you for being part of this.</h5>
                        </Alert>
                        <hr style={{ marginTop: 4 }}></hr>
                      </>
                    :
                      null
                    }
                  </>
                } 
              </form>
            )
          }}
          </Formik>
          <hr style={{ marginTop: 20, marginBottom: 20 }}></hr>
          <Row>
            <Col md="12">                  
              {props.steps.message ? (
                <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                  {props.steps.message}
                </Alert>) 
                : null
              }
            </Col>
          </Row>
          <Row>
            <Col md="12" style={{ marginBottom: 20}}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item md={3} xs={6}>
                </Grid>
                <Grid item md={2} xs={6} style={{ textAlign: "right"}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="clientRegistrationSort-label" className={classes.label}>Sort By</InputLabel>
                    <Select
                      labelId="clientRegistrationSort-label"
                      id="clientRegistrationSort"
                      label='Sort By'
                      margin="dense"
                      style={{ minWidth: 120 }}
                      value={filters.clientRegistrationSort}
                      onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientRegistrationSort', filterValue: event.target.value }))}
                    >
                      <MenuItem value='NextAvailable'>Next Available</MenuItem>
                      <MenuItem value='Cost'>Out of Pocket Cost</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Col>
          </Row>
          <Grid container spacing={3}>
            {/* {!currentPsychologist  ? */}
            <Grid item md={currentPsychologist.id !== undefined ? 4 : 12} style={{ width: "100%",  }}>
              <Masonry
                breakpointCols={isMobile || currentPsychologist.id !== undefined ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">

                {filteredPsychologists.map((row, index) => {
                  return (
                    <Card className={classes.root} variant="outlined" key={index}>
                      <CardMedia
                        className={classes.media}
                        image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                        title="Psychologist"
                      />
                      {providerType && providerType==='Financial' ? 
                        <>
                          {/* Financial Counsellor Output */}
                          <CardContent>
                            <Grid container justifyContent="space-between">
                              <Grid item sm={9}>
                                <Typography variant="h5" component="h3">
                                  {row.fullname}
                                </Typography>
                              </Grid>
                              <Grid item sm={3} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1">
                                  {row.practicename}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                                  {row.areasOfInterest.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                            </Grid>
                            <Grid container style={{ height: 220 }}>
                              <Typography variant="body2" component="p">
                                <Interweave content={row.bio} />
                                {/* {row.bio.substring(0,700)} */}
                                {row.bio.length > 700 ?
                                      <>
                                        ...
                                        <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                          more
                                        </Badge>
                                      </>
                                    :
                                      null
                                    }
                              </Typography>
                            </Grid>
                          </CardContent>
                        </>
                      :
                        <>
                          {providerType && (providerType === 'GP' || providerType === 'EAP' || providerType === 'Nurse' || providerType === 'Provisional' || providerType === 'Social' || providerType === 'Occupational' || providerType === 'Supervision') ?
                            <>
                              {/* GP, Social, Occupational, Nurse or Provisional Output i.e. no AOI */}
                              <CardContent>
                                <Grid container justifyContent="space-between">
                                  <Grid item sm={9}>
                                    <Typography variant="h5" component="h3">
                                      {row.fullname} 
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {row.providerdescription}
                                    </Typography>
                                    {clientInvite && clientInvite.facetoFaceBooking === 'Y' ?
                                      <Typography variant="subtitle2">
                                        {row.fulladdress}
                                      </Typography>
                                    :
                                      null
                                    }
                                    <Typography variant="subtitle2" style={{ marginTop:20}}>
                                      {`(Next Available Booking: ${row.nextAvailable})`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {providerType && providerType === 'Supervision' ?
                                  <>
                                    <Grid container style={{ marginTop: 10 }}>
                                      <Grid item sm={6} >
                                        <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                          Out of Pocket Cost
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={6} style={{ textAlign: "right" }}>
                                        <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                          {row.hourlyrate}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </>
                                :
                                  null
                                }
                                <Grid container style={{  marginTop: 10}}>
                                  <Typography variant="body2" component="p">
                                    {/* {row.bio.substring(0,700)} */}
                                    <Interweave content={row.bio} />
                                    {row.bio.length > 700 ?
                                      <>
                                        ...
                                        <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                          more
                                        </Badge>
                                      </>
                                    :
                                      null
                                    }
                                  </Typography>
                                </Grid>
                                {providerType && providerType === 'Supervision' ?
                                  <>
                                    <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                                      Available For
                                    </Typography>
                                    <Row>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {supervisionAreas.map(sRow => {
                                            if(sRow.index <= supervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${sRow.id}`;
                                              var isChecked = row.supervisorAreas.includes(sRow.id);
                                              return (
                                                <ListItem key={sRow.id} role={undefined} dense button >
                                                  <ListItemIcon style={{ minWidth: 40 }}>
                                                    <Checkbox
                                                      edge="start"
                                                      checked={isChecked}
                                                      tabIndex={-1}
                                                      disableRipple
                                                      inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                  </ListItemIcon>
                                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                      disableTypography 
                                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                                      className={classes.link} />
                                                </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })}
                                        </List>
                                      </Col>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {supervisionAreas.map(sRow => {
                                            if(sRow.index > supervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${sRow.id}`;
                                              var isChecked = row.supervisorAreas.includes(sRow.id);
                                              return (
                                                <ListItem key={sRow.id} role={undefined} dense button >
                                                  <ListItemIcon style={{ minWidth: 40 }}>
                                                    <Checkbox
                                                      edge="start"
                                                      checked={isChecked}
                                                      tabIndex={-1}
                                                      disableRipple
                                                      inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                  </ListItemIcon>
                                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                      disableTypography 
                                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                                      className={classes.link} />
                                                </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })}
                                        </List>
                                      </Col>
                                    </Row>
                                    <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                                      Endorsed In 
                                    </Typography>
                                    <Row>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {endorsedSupervisionAreas.map(sRow => {
                                            var isChecked = row.supervisorAreas.includes(sRow.id);
                                            const labelId = `checkbox-list-label-${sRow.id}`;
                                            if (isChecked) {
                                              return(
                                                <ListItem key={sRow.id} role={undefined} dense button >
                                                  <ListItemIcon style={{ minWidth: 40 }}>
                                                    <Checkbox
                                                      edge="start"
                                                      checked={isChecked}
                                                      tabIndex={-1}
                                                      disableRipple
                                                      inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                  </ListItemIcon>
                                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                      disableTypography 
                                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                                      className={classes.link} />
                                                </ListItem>
                                              )
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })}
                                        </List>
                                      </Col>
                                    </Row>
                                  </>
                                :
                                  null
                                }  

                              </CardContent>
                            </>
                          :
                            <>
                              {/* Psychologist and Clinical Output */}
                              <CardContent>
                                <Grid container justifyContent="space-between">
                                  <Grid item sm={9}>
                                    <Typography variant="h5" component="h3">
                                      {row.fullname} 
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                      {row.providerdescription}
                                    </Typography>
                                    {clientInvite && clientInvite.facetoFaceBooking === 'Y' ?
                                      <Typography variant="subtitle2">
                                        {row.fulladdress}
                                      </Typography>
                                    :
                                      null
                                    }
                                    <Typography variant="subtitle2" style={{ marginTop:20}}>
                                      {`(Next Available Booking: ${row.nextAvailable})`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                { props.steps.step2.paymenttype === 'Full Fee' ?
                                  <>
                                    <Grid container style={{ marginTop: 10 }}>
                                      <Grid item sm={4} >
                                        <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                          Out of Pocket Cost
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={8} style={{ textAlign: "right" }}>
                                        <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                          {row.hourlyRateOverrideGap !== '0' ? row.hourlyRateOverrideGap : row.standardFeeOutOfPocket }
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay {row.hourlyrate} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {row.hourlyRateOverrideRebate !== '0'  ? row.hourlyRateOverrideRebate : row.medicareRebate} from Medicare
                                        </Typography>                                          
                                      </Grid>
                                    </Grid>
                                  </>
                                :
                                  null
                                }
                                { props.steps.step2.paymenttype === 'Reduced Gap' ?
                                  <>
                                    {row.isReducedFee === 'Y' ? 
                                      <>
                                        <Grid container style={{ marginTop: 10 }}>
                                          <Grid item sm={4} >
                                            <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                              Out of Pocket Cost
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={8} style={{ textAlign: "right" }}>
                                            <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                              {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                            </Typography>
                                            { row.reducedFee === '0' ?
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay $0 on the day
                                              </Typography>
                                            :
                                              <>
                                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                  You pay {row.reducedFee} on the day
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                  Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                          { row.reducedFee !== '0' ?
                                            <Grid item sm={12}>
                                              <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                                Note this provider offers some flexibility, depending on client circumstances
                                              </Typography>
                                            </Grid>
                                          :
                                            null
                                          }
                                        </Grid>
                                      </>
                                    :
                                      <>
                                        <Grid container style={{ marginTop: 10 }}>
                                          <Grid item sm={4} >
                                            <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                              Out of Pocket Cost
                                            </Typography>
                                          </Grid>
                                          <Grid item sm={8} style={{ textAlign: "right" }}>
                                            <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                              {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                            </Typography>
                                            { row.reducedFee === '0' ?
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay $0 on the day
                                              </Typography>
                                            :
                                              <>
                                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                  You pay {row.reducedFee} on the day
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                  Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                      </>
                                    }
                                  </>
                                :
                                  null
                                }     
                                <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                                  {row.areasOfInterest.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                                </Grid>
                                <Grid container style={{ height: 220 }}>
                                  <Typography variant="body2" component="p">
                                    <Interweave content={row.bio.substring(0,700)} />
                                    {row.bio.length > 700 ?
                                      <>
                                        ...
                                        <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                          more
                                        </Badge>
                                      </>
                                    :
                                      null
                                    }
                                  </Typography>
                                </Grid>
                              </CardContent>
                            </>
                          }
                        </>
                      }
                      <CardActions disableSpacing>
                        {props.steps.step2.paymenttype !== '' ?
                          <>
                            {currentPsychologist.id === undefined ? 
                              (props.steps.step2.paymenttype === "Bulk Billed" && row.bulkBillingAvailable === "0" && !props.steps.step2.program) ?
                                <Button variant="secondary">
                                  Not Available to Bulk Bill
                                </Button>
                              :
                                <Button variant="primary" type="submit" onClick={() => {
                                  setcurrentPsychologist(row)
                                  props.setStepValue({ step2: { ...props.steps.step2, status: 'Completed', data: {...row},  booking: { day: '' }, providerType : providerType }, currentStep: 2 })
                                  }}>
                                  Select
                                </Button>
                                : 
                                <Button variant="secondary" type="submit" onClick={() => {
                                  setcurrentPsychologist({})
                                  props.setStepValue({ step2: { ...props.steps.step2, status: 'In Progress', data: {}, booking: { day: '' }, providerType : providerType }, currentStep: 2 })
                                  }}>
                                  Change
                                </Button>
                            }
                          </>
                          :
                            <Button variant="secondary">
                              Choose Payment Type to Select
                          </Button>
                        }
                        {providerType && providerType !== 'Supervision' ?
                          <IconButton
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: expanded,
                            })}
                            onClick={() => handleExpandClick(row.id)}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        :
                          null
                        }
                      </CardActions> 
                      {providerType && providerType !== 'Supervision' ?
                        <Collapse in={(expanded !== row.id)} timeout="auto" unmountOnExit>
                          <CardContent>                      
                            {row.therapyTypes && row.therapyTypes.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                          </CardContent>
                        </Collapse>               
                      :
                        null 
                      }  
                    </Card>           
                    )
                  }
                )}
              </Masonry>
            </Grid>
            {currentPsychologist.id !== undefined ? 
              <Grid item md={8}>
                {props.steps.step2.bookingdate ? 
                  <>
                  <h4>Your Booking:</h4>
                  <Grid container spacing={3} style={{ maxWidth: 400, marginTop: 15, marginLeft: 5, backgroundColor: "white"}}>
                    <Grid item md={3} xs={6}>
                      {providerType && providerType === 'Financial' ?
                        'Financial Counsellor:'
                      :
                        <>
                          {providerType && providerType === 'GP' ? 
                            'GP:'
                          :
                            <>
                              {providerType && providerType === 'Provisional'?
                                'Provisional Psychologist'
                              :
                                <>
                                  {providerType && providerType === 'Social'?
                                    'Social Worker'
                                  :
                                    <>
                                    {providerType && providerType === 'Nurse'?
                                      'Mental Health Nurse'
                                    :
                                      <>
                                        {providerType && providerType === 'Occupational'?
                                          'Occupational Therapist'
                                        :
                                          <>
                                            {providerType && providerType === 'Supervision'?
                                              'Supervising Psychologist'
                                            :
                                              'Psychologist'
                                            }
                                          </>
                                        }
                                      </>
                                    }
                                    </>
                                  }  
                                </>
                              }
                            </>
                          }
                        </>
                      }
                    </Grid>
                    <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                      {currentPsychologist.fullname} 
                    </Grid>
                    <Grid item md={3} xs={6}>
                      Date:
                    </Grid>
                    <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                      {props.steps.step2.bookingdate && format(new Date(props.steps.step2.bookingdate), "dd-MM-yyyy")}
                    </Grid>
                    <Grid item md={3} xs={6}>
                      Time:
                    </Grid>
                    <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                      {props.steps.step2.bookingdate && format(new Date(props.steps.step2.bookingdate), "h:mm aa")}
                    </Grid>
                    {/* <Grid item md={3} xs={6}>
                      Session URL:
                    </Grid>
                    <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                      {currentPsychologist.waitingRoomURL}
                    </Grid> */}
                    <Grid item md={12}>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={6} xs={6}>
                          <Button variant="secondary" type="submit" onClick={() => {
                              props.setStepValue({ step2: { ...props.steps.step2, status: 'Completed', data: {...currentPsychologist}, bookingdate: null },  currentStep: 2 })
                              // step2: { ...props.steps.step2, { status: 'Completed', data: {...currentPsychologist}}, bookingdate: null }
                              // setbookingConfirmed(false)
                              }}>
                            Change
                          </Button>                      
                        </Grid>
                        <Grid item md={6} xs={6} style={{ textAlign: "right"}}>
                          <Button variant="primary" type="submit" onClick={() => submitPsychologist()}>
                            Save &amp; Next &gt;
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              : 
              <>
                <h4>Available Bookings:</h4>
                <Row>
                <Col md="6">
                  <Form.Group style={{width: "100%"}}>
                    <Form.Label>TimeZone</Form.Label>
                      <OverlayTrigger defaultShow={showpopoverTimeZone} show={showpopoverTimeZone} placement="right" overlay={popoverTimeZone}>
                      <Autocomplete
                        id="profileTimeZone"
                        variant="outlined"
                        options={timeZone}
                        getOptionLabel={(option) => option.name}
                        value={timeZone.find(v => v.systemName === currentTimeZone) || timeZone.find(v => v.systemName === 'AUS Eastern Standard Time')}
                        onChange={(event, newValue) => handleTimeZoneAutocompleteChange('TimeZone', event, newValue)}
                        style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            type="text"
                            variant="outlined"
                            margin="dense"
                            name="TimeZoneSelector"
                            style={{backgroundColor: "white", margin: 0, padding: 0}}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'}}
                          />}
                      />
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>  
              <div className='register-table-responsive'>
                <Table bordered hover  style={{ backgroundColor: "white"}} className='register-table'>
                  <thead className='register-thead'>
                    <tr className='register-tr'>
                      <th>Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className='register-tbody'>
                    {matchingAvailability && matchingAvailability.map((row) => {
                        if(rowcount < 20) {
                          rowcount++
                          return(
                          <tr key={`key_${rowcount}`} className='register-tr'>
                            <td>{format(new Date(row.fromDate), "dd-MM-yyyy")}</td>
                            <td>{format(new Date(row.fromDate), "HH:mm aa")}</td>
                            <td>{format(new Date(row.toDate), "HH:mm aa")}</td>
                            <td><Button onClick={() => startNewBooking(row.fromDate)} >Book</Button></td>
                          </tr>
                          )
                        }
                        else
                        {
                        return null
                        }
                      })
                    }
                  </tbody>
                </Table>
                </div>
              </>
              }
            </Grid>
            : 
              null 
            }
            {/* <Grid item md={12} xs={12}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item md={3} xs={6}>
                </Grid>
                <Grid item md={3} xs={6} style={{ textAlign: "right"}}>
                  {!bookingConfirmed ?               
                    <Button variant={!bookingConfirmed ? "secondary" : "primary"} type="submit">
                      Save &amp; Next &gt;
                    </Button>             
                  :
                    <Button variant="primary" type="submit" onClick={() => submitPsychologist()}>
                      Save &amp; Next &gt;
                    </Button>
                  }            
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Paper>

        <Modal show={showNewBooking} onHide={()=>setShowNewBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
          <Modal.Header closeButton className="new-booking-modal">
            <Modal.Title>New Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={6}>
                {providerType && providerType === 'Financial' ?
                  'Financial Counsellor:'
                :
                  <>
                    {providerType && providerType === 'GP' ? 
                      'GP:'
                    :
                      <>
                        {providerType && providerType === 'Provisional'?
                          'Provisional Psychologist'
                        :
                          <>
                            {providerType && providerType === 'Social'?
                              'Social Worker'
                            :
                              <>
                              {providerType && providerType === 'Nurse'?
                                'Mental Health Nurse'
                              :
                                <>
                                  {providerType && providerType === 'Occupational'?
                                    'Occupational Therapist'
                                  :
                                    <>
                                      {providerType && providerType === 'Supervision'?
                                        'Supervising Psychologist'
                                      :
                                        'Psychologist'
                                      }
                                    </>
                                  }
                                </>
                              }
                              </>
                              }  
                          </>
                        }
                      </>
                    }
                  </>
                }
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {currentPsychologist.fullname} 
              </Grid>
              <Grid item md={3} xs={6}>
                Date:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {currentbookingdate && format(new Date(currentbookingdate), "dd-MM-yyyy")}
              </Grid>
              <Grid item md={3} xs={6}>
                Time:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {currentbookingdate && format(new Date(currentbookingdate), "HH:mm aa")}
              </Grid>
              {/* <Grid item md={3} xs={6}>
                Session URL:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {currentPsychologist.waitingRoomURL}
              </Grid> */}
            </Grid>
          </Modal.Body>
          <Modal.Footer className="new-booking-modal">
            <Button variant="secondary" onClick={() => setShowNewBooking(false)} className="lightButtonOutlinedLarge">
              Close
            </Button>
            <Button variant="primary" onClick={() => saveAppointment({ bookingdate: currentbookingdate, psychologistID: currentPsychologist.id, psychologistname : currentPsychologist.fullname , waitingRoomURL: currentPsychologist.waitingRoomURL, clientFirstName : '', clientEmail : ''})}>
              Book Now
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
          <Modal.Header closeButton className="invite-modal">
            <Modal.Title>{providerBioFullName}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
            <Grid container >
              <Typography variant="body2" component="p">
                <Interweave content={providerBioBio} />
              </Typography>
            </Grid>
          </Modal.Body>
          <Modal.Footer className="invite-modal">
            <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

function ClientRegistrationForm(props) {

  const { invitetoken } = useParams();
  const dispatch = useDispatch()

  const validateMedicareNumber = require('validate-medicare-number');
  const [showPassword, setShowPassword] = useState(false);
  const providerType = props.providerType;
  const timeZone = useSelector(state => state.reference.TimeZone);
  const emailDuplicate = useSelector(state => state.registration.EmailDuplicate);
  const [showEmailDuplicate, setShowEmailDuplicate] = useState(false);

  const checkEmailDuplicate = useCallback((newEmailDuplicate) => {
    // Check if email address was found
    if (newEmailDuplicate)
    {
      setShowEmailDuplicate(true);
    }
    // Clear flag
    dispatch(Registration.actions.emailDuplicateClear());
  },[dispatch])


  useEffect(() => {
    // 
    // Some Commentary about this
    // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
    // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state 
    //
    if (emailDuplicate)
    {
      checkEmailDuplicate(emailDuplicate);
    }
  },[emailDuplicate,checkEmailDuplicate]);

      
  // now init the fields with values so that Formik can manage the touched state
  const clientInit = {
    firstname: '', 
    preferredname: '',
    surname: '', 
    email: '', 
    phone: '',
    clientFundingTypeID: 1,
    dob: undefined,
    gender: '',
    occupation: '',
    eapworkplace: '',
    fulladdress: '',
    latitude: '',
    longitude: '',
    postcode: '',
    timezone: props.steps.step2.userTZ,
    clientAreasOfInterest: [...props.steps.step1.data],
    hopeToGetFromTreatment: '',
    emergencycontactname1: '',
    emergencycontactphone1: '',
    emergencycontactrelation1: '',
    emergencycontactname2: '',
    emergencycontactphone2: '',
    emergencycontactrelation2: '',
    profilePassword: '',
    profilePasswordConfirmation: '',
    medicareno: '',
    medicarereferenceno: '',
    medicareexpirydate: '',
    appointmenttype: '0',
    MHCPFile: '',
    invitetoken: invitetoken,
    agreementAcceptance:false,
    clientid : null,
    paymenttype: 'Reduced Gap',
    privateClient: false,
    referrerid: null,
    abn : '',
    businessName : '',
    businessAddress : '',
    backgroundinformation : '',
    gpname : '',
    gpclinicname: '',
    healthCareCard: props.steps.step2.paymenttype === 'Bulk Billed' ? 'Y' : '',
    unemployed: 'NA',
    naturalDisaster: 'NA',
    reducedGapReason: 'N/A',
    bulkBilledReason: 'N/A',
    consentToShareWithGP: 'N/A',
    contract: false,
    providerType: props.providerType,
    ndisnumber : '',
    ndisdatefrom : undefined,
    ndisdateto : undefined,
    ndisplanmanagementprovider : '',
    ndisemailforinvoices : '',
    ndisdiagnosishistory : '',
    ndissupportcoordinator : '',
    ndissupportcoordinatorphone : '',
    locality: '',
    pronouns: '',
    indigenous: 'N',
    program: undefined
  }

  // get the invite (if any) from state and merge with the initialised fields
  const inviteInit = useSelector(state => state.registration.ClientInvite)
  const [clientInvite, setClientInvite] = useState({...clientInit, ...inviteInit, steps: props.steps});
  const [previousSteps, setPreviousSteps] = useState()  

  // console.log('SpecialistType')
  // console.log(clientInvite && clientInvite.specialisttype)

  // when user submits payment type form, we set it to the steps object, thus need to reload the formik form values
  useEffect( ()=> {
    if (previousSteps !== props.steps)
    {
      setClientInvite({ ...clientInvite, 
        steps: { ...props.steps },
        program: props.steps.step2.fixAOI,
        paymenttype: (props.steps.step2.paymenttype === '' && providerType !== 'Provisional' && providerType !== 'GP') ?  'Reduced Gap' : providerType === 'Provisional' ? 'Provisional' : providerType === 'GP' ? 'Bulk Billed' : providerType === 'Supervision' ? 'Supervision' : props.steps.step2.paymenttype, 
        unemployed: props.steps.step2.unemployed,
        naturalDisaster: props.steps.step2.naturalDisaster,
        bulkBilledReason: props.steps.step2.bulkBilledReason,
        reducedGapReason: props.steps.step2.reducedGapReason,
        healthCareCard: props.steps.step2.paymenttype === 'Bulk Billed' ? 'Y' : ''
      })
      setPreviousSteps(props.steps)
    }
  }, [props.steps.step2,props.steps,providerType,previousSteps])



  // Define Program Question responses 
  const programPopulationOptions = [
    {
      "id" : "1",
      "name" : "Aboriginal and/or Torres Strait Islander",
    },
    {
      "id" : "2",
      "name": "Culturally and linguistically diverse",
    },
    {
      "id": "3",
      "name": "Migrant and Refugee",
    },
    {
      "id" : "4",
      "name": "Under 20",
    },
    {
      "id" : "5",
      "name": "Rural and Remote",
    }
  ]


  function submitForm(data) {
    postClient(data)
  }

  function postClient(data) {
    //
    const formData = new FormData();
    formData.append('privateClient',data['privateClient'])    
    formData.append('firstname',data['firstname'])
    formData.append('preferredname',data['preferredname'])
    formData.append('surname',data['surname'])
    formData.append('email',data['email'])
    formData.append('gender',data['gender'])
    formData.append('dob',data['dob'])
    formData.append('phone',data['phone'])
    formData.append('fulladdress',data['fulladdress'])
    formData.append('profilePassword',data['profilePassword'])
    formData.append('emergencycontactname1',data['emergencycontactname1'])
    formData.append('emergencycontactphone1',data['emergencycontactphone1'])
    formData.append('emergencycontactrelation1',data['emergencycontactrelation1'])
    formData.append('emergencycontactname2',data['emergencycontactname2'])
    formData.append('emergencycontactphone2',data['emergencycontactphone2'])
    formData.append('emergencycontactrelation2',data['emergencycontactrelation2'])
    formData.append('medicareno',data['medicareno'])
    formData.append('medicarereferenceno',data['medicarereferenceno'])
    formData.append('medicareexpirydate',data['medicareexpirydate'])
    formData.append('clientFundingTypeID',data['clientFundingTypeID'])
    formData.append('occupation',data['occupation'])
    formData.append('eapworkplace',data['eapworkplace'])
    formData.append('latitude',data['latitude'])
    formData.append('longitude',data['longitude'])
    formData.append('postcode',data['postcode'])
    formData.append('hopeToGetFromTreatment',data['hopeToGetFromTreatment'])
    formData.append('appointmenttype',data['appointmenttype'])
    formData.append('invitetoken',data['invitetoken'])
    formData.append('paymentType',data['paymenttype'])
    formData.append('MHCPFile',data['MHCPFile'])
    formData.append('psychologistid',props.steps.step2.data.id)
    formData.append('newbookingdate',props.steps.step2.bookingdate)
    formData.append('psychologistname',props.steps.step2.data.fullname)
    formData.append('waitingRoomURL',props.steps.step2.data.waitingRoomURL)
    formData.append('timezone',data['timezone'] ? data['timezone'] : props.steps.step2.userTZ)
    formData.append('providertype',props.steps.step2.providerType)
    formData.append('clientAreasOfInterest',[...props.steps.step1.data].map(aoi => aoi.issue).join(","))
    formData.append('referrerid',clientInvite.referrerid)
    formData.append('abn',data['abn'])
    formData.append('businessname', data['businessName'])
    formData.append('businessaddress',data['businessAddress'])
    formData.append('backgroundinformation',data['backgroundinformation'])
    formData.append('healthCareCard',data['healthCareCard'])
    formData.append('unemployed',data['unemployed'])
    formData.append('naturalDisaster',data['naturalDisaster'])
    formData.append('consentToShareWithGP',data['consentToShareWithGP'])
    // Depending on payment type populate with correct reason
    if (data['paymenttype'] === 'Reduced Gap')
    {
      formData.append('bulkBilledReason',data['reducedGapReason'])
    }
    else
    {
      formData.append('bulkBilledReason',data['bulkBilledReason'])
    }
    formData.append('gpname',data['gpname'])
    formData.append('gpclinicname',data['gpclinicname'])
    formData.append('ndisnumber',data['ndisnumber'])
    formData.append('ndisdatefrom',data['ndisdatefrom'])
    formData.append('ndisdateto',data['ndisdateto'])
    formData.append('ndisplanmanagementprovider',data['ndisplanmanagementprovider'])
    formData.append('ndisemailforinvoices',data['ndisemailforinvoices'])
    formData.append('ndisdiagnosishistory',data['ndisdiagnosishistory'])
    formData.append('ndissupportcoordinator',data['ndissupportcoordinator'])
    formData.append('ndissupportcoordinatorphone',data['ndissupportcoordinatorphone'])
    formData.append('pronouns',data['pronouns'])
    formData.append('indigenous',data['indigenous'])
    // console.log(data['programpopulation'])
    formData.append('programpopulation', data['programpopulation'] ? data['programpopulation'].map(pp => pp.name).join(",") : undefined)
    formData.append('specialisttype',clientInvite.specialisttype)
    // 
    // Send to API
    //
    dispatch(Registration.actions.postClientData({formData}))
    props.setStepValue({ step3: { status: 'Completed', data: data }, currentStep: 4 }) 
  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .required("First Name is required"),
    preferredname:  Yup.string()
    .max(200,"Too Long"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .required("Surname is required"),
    dob:  Yup.string()
    .when(['providerType'],
    {
      is: (providerType) => (providerType !== 'Supervision'),
      then: Yup.string().required("Date of Birth is required")
                        .test("dob", "Please enter a valid date", function (value) {
                            return ((differenceInYears(new Date(), new Date(value)) >= 1) && (differenceInYears(new Date(), new Date(value)) <= 100))  
                        }),
    }),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    phone:  Yup.string()
    .min(2,"Too Short")
    .max(12,"Too Long")
    .required("Phone Number is required"),
    fulladdress:  Yup.string()
    .when(['providerType'],
      {
        is: (providerType) => (providerType !== 'NDIS'),
        then: Yup.string().min(4,"Too Short")
                          .max(80,"Too Long")
                          .required("Address is required"),
      }),
    pronouns: Yup.string()
    .max(20,"Too Long"),
    profilePassword:  Yup.string()
    .min(8,"Too Short")
    .max(50,"Too Long")
    .required("Password is required"),
    hopeToGetFromTreatment:  Yup.string()
    .max(4000,"Too Long"),
    emergencycontactname1:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Psychologist'),
        then: Yup.string().min(3,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact is required"),
      })
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Clinical'),
        then: Yup.string().min(3,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Social'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Nurse'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Occupational'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'EAP'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact  is required"),
      }),    
    emergencycontactrelation1:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Psychologist'),
        then: Yup.string().min(3,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Clinical'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long")
                            .required("Emergency Contact Relationship is required"),
        })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long")
                            .required("Emergency Contact Relationship is required"),
        })    
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Social'),
        then: Yup.string().min(3,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Nurse'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long")
                            .required("Emergency Contact Relationship is required"),
        })
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Occupational'),
        then: Yup.string().min(3,"Too Short")
                          .max(50,"Too Long")
                          .required("Emergency Contact Relationship is required"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'EAP'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact Relationship is required"),
      }),    
    emergencycontactphone1:  Yup.string()
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Psychologist'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact phone number is required"),
        })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Clinical'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact phone number is required"),
        })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact phone number is required"),
        })    
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Social'),
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact phone number is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Nurse'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact phone number is required"),
        })
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Occupational'),
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long")
                          .required("Emergency Contact phone number is required"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'EAP'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long")
                            .required("Emergency Contact phone number is required"),
      }),    
    emergencycontactname2:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Psychologist'),
        then: Yup.string().min(3,"Too Short")
                          .max(20,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Clinical'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long"),
        })    
        .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long"),
        })    
        .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Social'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long"),
        })    
        .when(['providerType'],
          {
            is: (providerType) => (providerType === 'Nurse'),
            then: Yup.string().min(3,"Too Short")
                              .max(20,"Too Long"),
          })    
        .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Occupational'),
          then: Yup.string().min(3,"Too Short")
                            .max(20,"Too Long"),
        })
        .when(['providerType'],
          {
            is: (providerType) => (providerType === 'EAP'),
            then: Yup.string().min(6,"Too Short")
                              .max(20,"Too Long"),
        }),    
    emergencycontactrelation2:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Psychologist'),
        then: Yup.string().min(3,"Too Short")
                          .max(50,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Clinical'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Social'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Nurse'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Occupational'),
          then: Yup.string().min(3,"Too Short")
                            .max(50,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'EAP'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      }),    
    emergencycontactphone2:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => (providerType === 'Psychologist'),
        then: Yup.string().min(6,"Too Short")
                          .max(20,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Clinical'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Provisional'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Social'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Nurse'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      })    
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'Occupational'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      })
      .when(['providerType'],
        {
          is: (providerType) => (providerType === 'EAP'),
          then: Yup.string().min(6,"Too Short")
                            .max(20,"Too Long"),
      }),    
    profilePasswordConfirmation:  Yup.string()
    .min(8,"Too Short")
    .required("Password is required")
    .oneOf([Yup.ref('profilePassword'), null], 'Passwords must match'),
    medicareno:  Yup.string()
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Psychologist' && !contract),
        then: Yup.string().min(10,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Card Number is required")
                          .test('cardValid','A Valid Medicare Number is required',(value) => {
                            if (value)
                            {
                              return validateMedicareNumber(value)
                            }
                            else
                            {
                              return false;
                            }
                          }),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Social' && !contract),
        then: Yup.string().min(10,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Card Number is required")
                          .test('cardValid','A Valid Medicare Number is required',(value) => {
                            if (value)
                            {
                              return validateMedicareNumber(value)
                            }
                            else
                            {
                              return false;
                            }
                          }),
      })
      .when(['providerType','contract'],
        {
          is: (providerType,contract) => (providerType === 'Nurse' && !contract),
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Occupational' && !contract),
        then: Yup.string().min(10,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Card Number is required")
                          .test('cardValid','A Valid Medicare Number is required',(value) => {
                            if (value)
                            {
                              return validateMedicareNumber(value)
                            }
                            else
                            {
                              return false;
                            }
                          }),
      })
      .when(['providerType','contract'],
        {
          is: (providerType,contract) => (providerType === 'Clinical'  && !contract),
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        })    
        .when(['providerType','contract'],
        {
          is: (providerType,contract) => (providerType === 'GP'  && !contract),
          then: Yup.string().min(10,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Card Number is required")
                            .test('cardValid','A Valid Medicare Number is required',(value) => {
                              if (value)
                              {
                                return validateMedicareNumber(value)
                              }
                              else
                              {
                                return false;
                              }
                            }),
        }),            
    medicarereferenceno:  Yup.number()
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Psychologist' && !contract),
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Social' && !contract),
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providerType','contract'],
        {
          is: (providerType,contract) => (providerType === 'Nurses' && !contract),
          then: Yup.number().typeError('you must specify a number')
                            .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                            .required("Medicare Reference Number is required"),
        })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Occupational' && !contract),
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Clinical'  && !contract),
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'GP'   && !contract),
        then: Yup.number().typeError('you must specify a number')
                          .test('len', 'Must be 1 number', (val) => { if(val) return val.toString().length === 1; })
                          .required("Medicare Reference Number is required"),
      }),
    medicareexpirydate:  Yup.string()
    .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Psychologist'  && !contract),
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Social'  && !contract),
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providerType','contract'],
        {
          is: (providerType,contract) => (providerType === 'Nurse'  && !contract),
          then: Yup.string().min(5,"Too Short")
                            .max(10,"Too Long")
                            .required("Medicare Expiry is required"),
        })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Occupational'  && !contract),
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'Clinical'   && !contract),
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      })
      .when(['providerType','contract'],
      {
        is: (providerType,contract) => (providerType === 'GP'  && !contract),
        then: Yup.string().min(5,"Too Short")
                          .max(10,"Too Long")
                          .required("Medicare Expiry is required"),
      }),
    agreementAcceptance: Yup.boolean()
    .oneOf([true],'You must accept the Rural Health Connect Terms and Conditions'),
    abn:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => providerType === 'Financial',
        then: Yup.string().max(20,"Too Long"),
    }),
    businessName:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => providerType === 'Financial',
        then: Yup.string().max(250,"Too Long"),
      }),
    businessAddress:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => providerType === 'Financial',
        then: Yup.string().max(200,"Too Long"),
      }),
    backgroundinformation:  Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => providerType === 'Financial',
        then: Yup.string().max(5000,"Too Long"),
      }),
    eapworkplace: Yup.string()
      .when(['providerType'],
      {
        is: (providerType) => providerType === 'EAP',
        then: Yup.string().required('Workplace is required')
      }),  
    healthCareCard: Yup.string()  
      .when(['providerType','paymenttype'],
      {
        is: (providerType,paymenttype) =>  paymenttype === 'Bulk Billed' && (providerType === 'Clinical' || providerType === 'Psychologist' || providerType === 'Social' || providerType === 'Nurse' || providerType === 'Occupational'),
        then: Yup.string().oneOf(['Y', 'N'])
                          .required('Please advise if you have a Health Care Card')
      }),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={clientInvite}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          // actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
          const change = (name,e) => { e.persist();  handleChange(e); };
          return (
            <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >

              <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                      <Grid item md={6} xs={12}>
                        {providerType && (providerType === 'EAP' || providerType === 'NDIS') ?
                          <>
                            {providerType && (providerType === 'NDIS') ?
                              <h2>NDIS Registration</h2>
                            :
                              <h2>Register</h2>
                            }
                          </>
                        :
                          <h2>3. Register</h2>
                        }
                      </Grid>
                      <Grid item md={3} xs={12} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                        * indicates required fields
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                  {providerType !== 'Supervision' ?  
                    <>
                      {providerType === 'NDIS' ?  
                      <>
                        <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                          PLEASE NOTE - Please enter these details and we will be in contact to discuss needs and goals to find the most suitable psychologist.
                        </Alert> 
                      </>
                      :
                        <>
                          {values.message ? (
                            <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                              {values.message}
                            </Alert>) 
                            : null
                          }
                        </>
                      }
                    </>
                  :
                    <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                      PLEASE NOTE - Existing Providers with Rural Health Connect will need to use a different email address to access Supervison services.
                    </Alert> 
                  }
                  </Col>
                </Row>
                <hr style={{ marginTop: 4 }}></hr>
                <Row>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                      <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Surname *</Form.Label>
                      <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                      <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Preferred Name (Optional)</Form.Label>
                      <Form.Control type="text" name="preferredname" value={values.preferredname} onChange={change.bind(null, "preferredname")} autoComplete='new-password' isInvalid={touched.preferredname && !!errors.preferredname} isValid={touched.preferredname && !errors.preferredname}/>
                      <Form.Control.Feedback type="invalid">{errors.preferredname}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {providerType !== 'Supervision' ?  
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select" name="gender" value={values.gender} onChange={change.bind(null, "gender")} >
                          <option value="Prefer not to say">Prefer not to say</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  :
                    null
                  }
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Pronouns (Optional)</Form.Label>
                      <Form.Control type="text"  name="pronouns" value={values.pronouns} onChange={change.bind(null, "pronouns")}  autoComplete='new-password' isInvalid={touched.pronouns && !!errors.pronouns} isValid={touched.pronouns && !errors.pronouns}/>
                      <Form.Control.Feedback type="invalid">{errors.pronouns}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {providerType !== 'Supervision' ?  
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Date of Birth *</Form.Label>
                        <Form.Control type="date" name="dob" value={values.dob} onChange={change.bind(null, "dob")} isInvalid={touched.dob && !!errors.dob} isValid={touched.dob && !errors.dob} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  :
                    null
                  }
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group >
                      <Form.Label>Email *</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="email" 
                        value={values.email} 
                        onChange={change.bind(null, "email")} 
                        onBlur={e => {
                          handleBlur(e);
                          dispatch(Registration.actions.emailDuplicate(e.currentTarget.value));
                        }}
                        isInvalid={touched.email && !!errors.email} 
                        isValid={touched.email && !errors.email} 
                        autoComplete='new-password' 
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Phone Number *</Form.Label>
                      <Form.Control type="text" name="phone" value={values.phone} onChange={change.bind(null, "phone")} isInvalid={touched.phone && !!errors.phone} isValid={touched.phone && !errors.phone} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  { providerType !== 'Supervision' && providerType !== 'EAP' && providerType !== 'NDIS' ?
                    <Col md="3">
                      <Form.Group >
                        {providerType && providerType === 'EAP' ?
                          <Form.Label>Job Title (if relevant)</Form.Label>
                        :
                        <Form.Label>Occupation </Form.Label>
                        }
                        <Form.Control type="text" name="occupation" value={values.occupation} onChange={change.bind(null, "occupation")} autoComplete='new-password' />
                      </Form.Group>
                    </Col>                
                  :
                    null
                  }
                </Row>
                {providerType !== 'NDIS' ?
                  <Row>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Do you identify as Aboriginal and/or Torres Strait islander</Form.Label>
                        <Form.Control as="select" name="indigenous" value={values.indigenous} onChange={change.bind(null, "indigenous")} >
                          <option value="N">No</option>
                          <option value="Y">Yes</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                :
                  null
                }
                {values.program ?
                    <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Do you identify as any of the following populations that we are particularly supporting via this program? (select all that apply)</Form.Label>
                        <Autocomplete
                          id="searchProgramPopulation"
                          variant="outlined"
                          multiple
                          freeSolo
                          options={programPopulationOptions}
                          getOptionLabel={(option) => option.name}
                          value={values.programpopulation}
                          onChange={(e, newValue) => {
                            setFieldValue("programpopulation", newValue);
                          }}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="ProgramPopulationSelector"
                              style={{backgroundColor: "", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                :
                  null
                }
                <Row>
                  {providerType !== 'NDIS' ?
                    <Col md="6">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>Home Address (start typing to search) *</Form.Label>
                        <PlacesAutoComplete 
                          currentID={0}
                          currentfulladdress={values.fulladdress}
                          setcurrentfulladdress = {(value) => setFieldValue("fulladdress", value)}
                          setcurrentLatitude = {(value) => setFieldValue("latitude", value)} 
                          setcurrentLongitude = {(value) => setFieldValue("longitude", value)}
                          setcurrentPostCode = {(value) => setFieldValue("postcode", value)}
                          isInvalid={touched.fulladdress && !!errors.fulladdress} 
                          isValid={touched.fulladdress && !errors.fulladdress} 
                        />
                        {touched.fulladdress && !!errors.fulladdress ? (<p className='error-help'>{errors.fulladdress}</p>) : ''}
                      </Form.Group>
                    </Col>
                  :
                    null
                  }
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Password *</Form.Label>
                      <InputGroup>
                          <Form.Control type={showPassword ? "text" : "password"} name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                          <InputGroup.Append onClick={()=>setShowPassword(!showPassword)}>
                            <InputGroup.Text>
                              {showPassword ?
                                <VisibilityOffIcon fontSize="small" style={{ marginLeft: -4 }} />
                              : 
                                <VisibilityIcon fontSize="small" style={{ marginLeft: -4 }} /> 
                              }
                            </InputGroup.Text>
                          </InputGroup.Append>  
                          <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Confirm Password *</Form.Label>
                      <InputGroup>
                      <Form.Control type={showPassword ? "text" : "password"} name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                          <InputGroup.Append onClick={()=>setShowPassword(!showPassword)}>
                            <InputGroup.Text>
                              {showPassword ?
                                <VisibilityOffIcon fontSize="small" style={{ marginLeft: -4 }} />
                              : 
                                <VisibilityIcon fontSize="small" style={{ marginLeft: -4 }} /> 
                              }
                            </InputGroup.Text>
                          </InputGroup.Append>  
                          <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                {providerType && (providerType === 'EAP' || providerType === 'NDIS') ?
                  <>
                    <Row>
                      {providerType && providerType === 'EAP' ?
                        <>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>EAP Program Code *</Form.Label>
                              <Form.Control type="text" name="eapworkplace" value={values.eapworkplace} onChange={change.bind(null, "eapworkplace")} autoComplete='new-password' isInvalid={touched.eapworkplace && !!errors.eapworkplace} isValid={touched.eapworkplace && !errors.eapworkplace}/>
                              <Form.Control.Feedback type="invalid">{errors.eapworkplace}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Job Title (if relevant)</Form.Label>
                              <Form.Control type="text" name="occupation" value={values.occupation} onChange={change.bind(null, "occupation")} autoComplete='new-password' />
                            </Form.Group>
                          </Col>   
                        </>             
                      :
                        null
                      }
                      <Col md="6">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>TimeZone</Form.Label>
                            <Autocomplete
                              id="profileTimeZone"
                              variant="outlined"
                              options={timeZone}
                              getOptionLabel={(option) => option.name}
                              value={timeZone.find(v => v.systemName === values.timezone)}
                              onChange={(e, value) => {setFieldValue("timezone", value.systemName); }}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="TimeZoneSelector"
                                  style={{backgroundColor: "white", margin: 0, padding: 0}}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                :
                  null
                }
                <Row>
                  <Col md="12">
                    <Form.Group >
                      {providerType !== 'Supervision' ?  
                        <>
                          {providerType === 'NDIS' ?
                          <>
                            <Form.Label>Additional Information</Form.Label>
                          </>
                          :
                            <Form.Label>What are you hoping to get out of your sessions?</Form.Label>
                          }
                        </>
                      :
                        <Form.Label>Details on the type of supervision you are after, where  you are currently practicing and other relevant information.</Form.Label>
                      }
                      <Form.Control as="textarea" rows={4} type="text" name="hopeToGetFromTreatment" value={values.hopeToGetFromTreatment}  onChange={change.bind(null, "hopeToGetFromTreatment")} isInvalid={touched.hopeToGetFromTreatment && !!errors.hopeToGetFromTreatment} isValid={touched.hopeToGetFromTreatment && !errors.hopeToGetFromTreatment} autoComplete='new-password' />
                      <Form.Control.Feedback type="invalid">{errors.hopeToGetFromTreatment}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {providerType && (providerType === 'Provisional' || providerType === 'GP') ?
                  <>
                    <Form.Label>Do you have a regular GP?</Form.Label>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>GP Name *</Form.Label>
                          <Form.Control type="text" name="gpname" value={values.gpname} onChange={change.bind(null, "gpname")} autoComplete='new-password' isInvalid={touched.gpname && !!errors.gpname} isValid={touched.gpname && !errors.gpname}/>
                          <Form.Control.Feedback type="invalid">{errors.gpname}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>GP Clinic Name *</Form.Label>
                          <Form.Control type="text"  name="gpclinicname" value={values.gpclinicname} onChange={change.bind(null, "gpclinicname")}  autoComplete='new-password' isInvalid={touched.gpclinicname && !!errors.gpclinicname} isValid={touched.gpclinicname && !errors.gpclinicname}/>
                          <Form.Control.Feedback type="invalid">{errors.gpclinicname}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {providerType && providerType === 'GP' ?
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Do we have consent to share your Mental Health Treatment Plan with them?</Form.Label>
                            <Form.Control as="select" name="consentToShareWithGP" value={values.consentToShareWithGP} onChange={change.bind(null, "consentToShareWithGP")} style={{ width: 180 }} isInvalid={touched.consentToShareWithGP && !!errors.consentToShareWithGP} isValid={touched.consentToShareWithGP && !errors.consentToShareWithGP}>
                              <option value=''></option>
                              <option value='N'>No</option>
                              <option value='Y'>Yes</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.consentToShareWithGP}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      :
                        null
                      }
                    </Row>            
                  </>
                :
                  null
                }
                {providerType && providerType === 'Financial' ?
                  <>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control type="text" name="businessName" value={values.businessName || ''} onChange={change.bind(null, "businessName")} isInvalid={touched.businessName && !!errors.businessName} isValid={touched.businessName && !errors.businessName} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.businessName}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>ABN</Form.Label>
                          <Form.Control type="text"  name="abn" value={values.abn || ''} onChange={change.bind(null, "abn")} isInvalid={touched.abn && !!errors.abn} isValid={touched.abn && !errors.abn} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.abn}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>Business Address (start typing to search)</Form.Label>
                          <PlacesAutoComplete 
                            currentID={0}
                            currentfulladdress={values.businessAddress}
                            setcurrentfulladdress = {(value) => setFieldValue("businessAddress", value)}
                            isInvalid={touched.businessAddress && !!errors.businessAddress} 
                            isValid={touched.businessAddress && !errors.businessAddress} 
                          />
                          {touched.fulladdress && !!errors.fulladdress ? (<p className='error-help'>{errors.fulladdress}</p>) : ''}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Background Information</Form.Label>
                          <Form.Control as="textarea" rows={4} type="text" name="backgroundinformation" value={values.backgroundinformation}  onChange={change.bind(null, "backgroundinformation")} isInvalid={touched.backgroundinformation && !!errors.backgroundinformation} isValid={touched.backgroundinformation && !errors.backgroundinformation} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.backgroundinformation}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                :
                  null
                }
                {providerType && (providerType === 'Psychologist' || providerType === 'Clinical' || providerType === 'Occupational') ?
                  <>
                    <Row style={{ marginBottom:20}}>
                      <Col md="12">
                        <Form.Group>
                          {providerType && providerType === 'NDIS' ?
                            <Form.Label>Please Upload your NDIS Plan (if you have one):</Form.Label>
                          :
                            <Form.Label><strong>IMPORTANT NOTE:</strong> We need a referral a mental health treatment plan from your GP unless you want to pay full fee. If we contacted you, we likely already have yours on file. However, if you are signing up directly from the website please ensure you have completed one first. Upload below or your GP can fax it to us on 074573 1100. If it is multiple files then please email to us - referrals@ruralhealthconnect.com.au</Form.Label>
                          }
                          <Form.Control type="file" name="MHCPFile" onChange={(event) => setFieldValue('MHCPFile', event.currentTarget.files[0])} isInvalid={touched.MHCPFile && !!errors.MHCPFile} isValid={touched.MHCPFile && !errors.MHCPFile} className="form-control" style={{ paddingTop: 6, paddingLeft: 6, minWidth: 200, maxWidth: 300 }} />
                          <Form.Control.Feedback type="invalid">{errors.MHCPFile}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                :
                  null
                }
                {providerType && providerType !== 'Financial' && providerType !== 'Supervision' && providerType !== 'NDIS'  ?
                  <>
                    <Row>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Primary Emergency Contact*</Form.Label>
                          <Form.Control type="text" name="emergencycontactname1" value={values.emergencycontactname1 || ''} onChange={change.bind(null, "emergencycontactname1")} isInvalid={touched.emergencycontactname1 && !!errors.emergencycontactname1} isValid={touched.emergencycontactname1 && !errors.emergencycontactname1} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactname1}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Relationship*</Form.Label>
                          <Form.Control type="text" name="emergencycontactrelation1" value={values.emergencycontactrelation1 || ''} onChange={change.bind(null, "emergencycontactrelation1")} isInvalid={touched.emergencycontactrelation1 && !!errors.emergencycontactrelation1} isValid={touched.emergencycontactrelation1 && !errors.emergencycontactrelation1} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactrelation1}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Phone *</Form.Label>
                          <Form.Control type="text"  name="emergencycontactphone1" value={values.emergencycontactphone1 || ''} onChange={change.bind(null, "emergencycontactphone1")} isInvalid={touched.emergencycontactphone1 && !!errors.emergencycontactphone1} isValid={touched.emergencycontactphone1 && !errors.emergencycontactphone1} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactphone1}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Secondary Emergency Contact</Form.Label>
                          <Form.Control type="text" name="emergencycontactname2" value={values.emergencycontactname2 || ''} onChange={change.bind(null, "emergencycontactname2")} isInvalid={touched.emergencycontactname2 && !!errors.emergencycontactname2} isValid={touched.emergencycontactname2 && !errors.emergencycontactname2} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactname2}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control type="text" name="emergencycontactrelation2" value={values.emergencycontactrelation2 || ''} onChange={change.bind(null, "emergencycontactrelation2")} isInvalid={touched.emergencycontactrelation2 && !!errors.emergencycontactrelation2} isValid={touched.emergencycontactrelation2 && !errors.emergencycontactrelation2} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactrelation2}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text"  name="emergencycontactphone2" value={values.emergencycontactphone2 || ''}  onChange={change.bind(null, "emergencycontactphone2")} isInvalid={touched.emergencycontactphone2 && !!errors.emergencycontactphone2} isValid={touched.emergencycontactphone2 && !errors.emergencycontactphone2} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.emergencycontactphone2}</Form.Control.Feedback>                          
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                :
                  null
                }
                <Row>
                  {(providerType && (providerType !== 'Financial' && providerType !== 'NDIS' && providerType !== 'EAP' && providerType !== 'Supervision') && (!clientInvite.contract)) ?
                    <>
                      <Col md="3">
                        <Form.Group >
                          {providerType && providerType === 'Provisional' ?
                            <Form.Label>Medicare Card Number (Optional)</Form.Label>
                          :
                            <Form.Label>Medicare Card Number</Form.Label>
                          }
                          <Form.Control type="text" name="medicareno" value={values.medicareno || ''} onChange={change.bind(null, "medicareno")} isInvalid={touched.medicareno && !!errors.medicareno} isValid={touched.medicareno && !errors.medicareno} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.medicareno}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          {providerType && providerType === 'Provisional' ?
                            <Form.Label>Order on Card (Optional)</Form.Label>
                          :
                            <Form.Label>Order on Card</Form.Label>
                          }
                          <Form.Control type="text"  name="medicarereferenceno" value={values.medicarereferenceno || ''} onChange={change.bind(null, "medicarereferenceno")} isInvalid={touched.medicarereferenceno && !!errors.medicarereferenceno} isValid={touched.medicarereferenceno && !errors.medicarereferenceno} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.medicarereferenceno}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          {providerType && providerType === 'Provisional' ?
                            <Form.Label>Medicare Expiry Date (MM/YY) (Optional)</Form.Label>
                          :
                            <Form.Label>Medicare Expiry Date (MM/YY)</Form.Label>
                          }
                          <Form.Control type="text"  name="medicareexpirydate" value={values.medicareexpirydate || ''} onChange={change.bind(null, "medicareexpirydate")} isInvalid={touched.medicareexpirydate && !!errors.medicareexpirydate} isValid={touched.medicareexpirydate && !errors.medicareexpirydate} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{errors.medicareexpirydate}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </>
                  :
                    null
                  }
                  {/* {providerType && providerType !== 'GP' && providerType !== 'Supervision'?
                    <>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Appointment Type</Form.Label>
                          <Form.Control as="select" name="appointmenttype" value={values.appointmenttype} onChange={change.bind(null, "appointmenttype")} >
                            <option value='0'>Video Call</option>
                            <option value='1' disabled>Phone Call</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>   
                    </>
                  :
                    null
                  } */}
                  {providerType !== 'NDIS' ?
                    <Col md="12">
                      <p><strong>Note</strong> Sessions are done via video call. If you need your session to be done via phone call instead please call 0493 201 005 or 0493 432 144.</p>
                    </Col>
                  :
                    null
                  }
                </Row>
              {/* Display selected Payment type here */}
              {/* {providerType && (providerType === 'Psychologist' || providerType === 'Clinical' || providerType === 'Occupational') && (!clientInvite.contract) ?
                <>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: 500 }}>Payment Options</Form.Label>
                        <p>We want everyone be able to access a psychologist and therefore have a range of pricing options. Reduced fee places are limited so please only apply if it suits your circumstances:</p>
                        <Form.Check id="payment-fullfee" name="paymenttype" type="radio" style={{ marginBottom: 8 }}>
                          <Form.Check.Input name="paymenttype" type="radio" value="Full Fee"  checked={values.paymenttype === 'Full Fee' ? true : false} onChange={change.bind(null, "paymenttype")}  />
                          <Form.Check.Label style={{marginLeft: 12 }}>STANDARD FEE: I am employed and/or am not experiencing financial hardship </Form.Check.Label>
                        </Form.Check>
                        <Form.Check id="payment-reducedgap" name="paymenttype" type="radio" style={{ marginBottom: 8 }} >
                          <Form.Check.Input name="paymenttype" type="radio" value="Reduced Gap"  checked={values.paymenttype === 'Reduced Gap' ? true : false} onChange={change.bind(null, "paymenttype")} />
                          <Form.Check.Label style={{marginLeft: 12 }}>REDUCED FEE: I am experiencing financial hardship and would like to apply for the psychologist's the reduced gap fee option.  </Form.Check.Label>
                        </Form.Check>
                        {values.paymenttype === 'Reduced Gap' ?
                          <>
                          <Row>
                              <Col md="12">
                                <Form.Group >
                                  <Form.Label>Please tell us about your circumstances in a few sentences (A few sentences)</Form.Label>
                                  <Form.Control as="textarea" rows={4} type="text" name="bulkBilledReason" value={values.bulkBilledReason}  onChange={change.bind(null, "bulkBilledReason")} isInvalid={touched.bulkBilledReason && !!errors.bulkBilledReason} isValid={touched.bulkBilledReason && !errors.bulkBilledReason} autoComplete='new-password' />
                                  <Form.Control.Feedback type="invalid">{errors.bulkBilledReason}</Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                        </>
                        :
                          null
                        }
                        <Form.Check id="payment-bulkbilled" name="paymenttype" type="radio" style={{ marginTop: 20, marginBottom: 8 }} >
                          <Form.Check.Input name="paymenttype" type="radio" value="Bulk Billed" checked={values.paymenttype === 'Bulk Billed' ? true : false} onChange={change.bind(null, "paymenttype")} />
                          <Form.Check.Label style={{marginLeft: 12 }}>​BULK BILL(limited sessions available) We have some bulk billed sessions available for those most in need. For Healthcare, Pension or Disability card holders only. </Form.Check.Label>
                        </Form.Check>                      
                      </Form.Group>
                    </Col>
                  </Row>               
                </>
                :
                  null
                }  */}
                <Row>
                  <Col md="10">
                    <Form.Group>
                      <Checkbox aria-label='Agreement Acceptance' name="agreementAcceptance" value={values.agreementAcceptance} onChange={change.bind(null, "agreementAcceptance")} style={{ paddingTop: 6, paddingLeft: 0 }} />
                        I accept the <a href="https://files.ruralhealthconnect.com.au/filestoreRHC//RHCTermsandConditions.pdf" target="_blank" rel="noopener noreferrer" className="cell-Link">Rural Health Connect Terms and Conditions of Use</a>
                        <div className="error-help">{errors.agreementAcceptance}</div>
                        <Form.Control.Feedback type="invalid">{errors.agreementAcceptance}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item md={3} xs={6}>
                      </Grid>
                      <Grid item md={1} xs={6} style={{ textAlign: "right"}}>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </Paper>
            </form>
          )
        }}
      </Formik>
   
      <Modal show={showEmailDuplicate} onHide={()=>setShowEmailDuplicate(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
            <Grid item md={9} xs={6}  style={{ fontWeight: 600 }}>
              It looks like you already have an account. Please <a href={`/auth/login`} target="RHC">login</a> to continue
            </Grid>
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Button variant="secondary" onClick={() => setShowEmailDuplicate(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ClientSupportFormConfirmation(props) {

  return (
    <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
      <h3>
        Contact Form Submitted successfully.
      </h3>
      <p style={{ marginTop: 30 }}>
        We will be in contact shortly.
      </p>
    </Paper>
  );
}

function ClientRegistrationConfirmation(props) {

  return (
    <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
      <h3>
        Registration successful.
      </h3>
      <p style={{ marginTop: 30 }}>
        Congratulations, your Rural Health Connect account has been created.
      </p>
      <p>
        You may now login to the portal to make and manage your bookings and your profile.
      </p>
      <p style={{ marginTop: 20 }}>
      <Link to="/auth/login" className="kt-login__logo">
        <Button>
        Portal Login
        </Button>
      </Link>
      </p>
    </Paper>
  );
}

function ClientRegistrationNotReady(props) {

  return (
    <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
      <Row>
        <Col md="12">
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item md={6} xs={12}>
              <h2>3. Register</h2>
            </Grid>
            <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
              * indicates required fields
            </Grid>
          </Grid>
        </Col>
      </Row>
      <Row>
        <Col md="12">
            {(!(props.steps.step1.data.length > 0) && (props.providerType !== 'GP') && (props.providerType !== 'Supervision')) ? 
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>  
                <span>You must select at least one area of interest.<br></br></span> 
              </Alert>
            : 
              ''
            }
            {!(props.steps.step2.data.id && props.steps.step2.data.id > 0) ?
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                <span>You must select a Provider before completing the registration form.<br></br></span>
              </Alert>
            :
             ''
             }   
            {!(props.steps.step2.userTZ && props.steps.step2.userTZ.length > 0)  ? 
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                <span>You must select a Timezone before completing the registration form.<br></br></span>
              </Alert>
            : 
              ''
            }   
            {!(props.steps.step2.bookingdate && props.steps.step2.bookingdate.length > 0)  ? 
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                <span>You must book your first appointment before completing the registration form.<br></br></span>
              </Alert>
            :
             ''
            }   
        </Col>
      </Row>
      <hr style={{ marginTop: 4 }}></hr>
      <Button onClick={()=>props.setStepValue({ currentStep: 2 })}>
        &lt; Back
      </Button>
    </Paper>
  );
}

function PageWrapper(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    linkItem: {
      minWidth: 50,
    },
    linkIcon: {
      color: 'rgba(255,255,255,0.7)',
      fontSize: 24,
      fontWeight: 300,
    },
    linkIconSelected: {
      color: 'white',
      fontSize: 24,
    },
    link: {
      color: 'rgba(255,255,255,0.7)',
      fontSize: 16,
      fontWeight: 300,
    },
    linkSelected: {
      color: 'white',
      fontSize: 16,
      fontWeight: 600,
    },
  }));

  const classes = useStyles();
  
  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (

    <>
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              padding: 20,
              maxWidth: isMobile ? 1024 : 320,
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`,
              backgroundSize: "auto"
            }}
          >
            <div className="kt-grid__item">
              <Link to="/auth/login" className="kt-login__logo">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-3.png")}
                  style={{ marginLeft: -10 }}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                {(props.providerType !== 'EAP' && props.providerType !== 'NDIS') ?
                  <>
                    <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                      Welcome to the Client Registration process. There are three steps to complete: 
                    </h4>
                    <div className={classes.root}>
                      <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={()=>props.setStepValue({ currentStep: 1 })}>
                          <ListItemIcon className={classes.linkItem}>
                            <HelpOutlineIcon className={props.steps.currentStep === 1 ? classes.linkIconSelected : classes.linkIcon} />
                          </ListItemIcon>
                          <ListItemText 
                            disableTypography 
                            primary={<Typography className={props.steps.currentStep === 1 ? classes.linkSelected : classes.link}>
                              {props.providerType && props.providerType === 'Financial' ?
                                <>
                                  1. Location
                                </>
                              :
                                <>
                                  1. Areas of Interest
                                </>  
                              }
                              </Typography>} 
                            className={classes.link} />
                        </ListItem>
                        <ListItem button onClick={()=>props.setStepValue({ currentStep: 2 })}>
                          <ListItemIcon className={classes.linkItem}>
                            <GroupIcon className={props.steps.currentStep === 2 ? classes.linkIconSelected : classes.linkIcon} />
                          </ListItemIcon>
                          <ListItemText 
                            disableTypography 
                            primary={
                              <Typography className={props.steps.currentStep === 2 ? classes.linkSelected : classes.link}>
                                {props.providerType && props.providerType === 'Financial' ?
                                  <>
                                    2. Financial Counsellor
                                  </>
                                :
                                  <>
                                    {props.providerType && props.providerType === 'GP' ? 
                                      <>
                                        2. GP
                                      </>
                                    :
                                      <>
                                        {props.providerType && props.providerType === 'Provisional'?
                                          <>
                                            2. Provisional Psychologist
                                          </>
                                        :
                                          <>
                                            {props.providerType && props.providerType === 'Social'?
                                              <>
                                                2. Social Worker
                                              </>
                                            :
                                              <>
                                                {props.providerType && props.providerType === 'Nurse'?
                                                  <>
                                                    2. Mental Health Nurse
                                                  </>
                                                :
                                                  <>
                                                    {props.providerType && props.providerType === 'Occupational'?
                                                      <>
                                                        2. Occupational Therapist
                                                      </>
                                                    :
                                                      <>
                                                        {props.providerType && props.providerType === 'Supervision'?
                                                          <>
                                                            2. Supervising Psychologist
                                                          </>
                                                        :
                                                          <>
                                                            {props.contract ?
                                                              <>
                                                                2. Practitioner
                                                              </>
                                                            :
                                                              <>
                                                                2. Practitioner
                                                              </>
                                                            }
                                                          </>
                                                        }
                                                      </>
                                                    }
                                                  </>
                                                }
                                              </>
                                            }  
                                          </>
                                        }
                                      </>
                                    }
                                  </>
                                }
                              </Typography>} 
                            className={classes.link} />
                        </ListItem>
                        <ListItem button onClick={()=>props.setStepValue({ currentStep: 3 })}>
                          <ListItemIcon className={classes.linkItem}>
                            <AssignmentIcon className={props.steps.currentStep === 3 ? classes.linkIconSelected : classes.linkIcon} />
                          </ListItemIcon>
                          <ListItemText 
                            disableTypography 
                            primary={<Typography className={props.steps.currentStep === 3 ? classes.linkSelected : classes.link}>3. Register</Typography>} 
                            className={classes.link} />
                        </ListItem>
                      </List>                  
                      {/* <div style={{ color: 'white', maxWidth: 300 }}>{JSON.stringify(props.steps.step2)}</div> */}
                    </div>
                  </>
                :
                  <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                    Welcome to the Client Registration process. Please register
                  </h4>
                }
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info">
                <div className="kt-login__copyright">
                  &copy; 2024 Rural Health Connect
                </div>
              </div>
            </div>

          </div>

          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
            
            {props.children}
         
          </div>

          </div>
        </div>
      </div>
    </>

  );

}