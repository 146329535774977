import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import * as Forms from "../store/ducks/forms.duck";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import { Grid, TableContainer, FormControl, Select, MenuItem, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { format } from "date-fns";
import FormDataEntry from "../pages/home/admin/FormDataEntry";
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';

export default function Notes(props) {
    
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchNotes({ type: props.type, pkID: props.pkid })) }, [dispatch, props.type, props.pkid] )
  useEffect( () => { dispatch(Admin.actions.fetchCounter()) }, [dispatch] )
  useEffect( () => { dispatch(Forms.actions.fetchForms()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchFiles({ type: props.type, pkID: props.pkid })) }, [dispatch, props.type, props.pkid] )

  const files = useSelector(state => state.admin.Files[props.type])
  const loadFiles = files.filter(file => file.formData === null)
  const [newNote, setNewNote] = useState('')
  const [footer, setFooter] = useState(props.waitingRoomURL)
  const [newNoteHelpText, setNewNoteHelpText] = useState('')
  const [emailSubjectHelpText, setEmailSubjectHelpText] = useState('')
  const [emailSubject, setEmailSubject] = useState('')
  const [emailCC, setEmailCC] = useState('')
  const [emailCCHelpText, setEmailCCHelpText] = useState('')
  const [noteSelector, setnoteSelector] = useState(props.noteAction ? props.noteAction : 'SMS')
  const [newFFile, setNewFFile] = useState()
  const [existingFile, setExistingFile] = useState(0)
  const [attachmentHelpText, setAttachmentHelpText] = useState('')
  const [faxDestination, setFaxDestination] = useState((props.medicalPractice && props.medicalPractice.fax) || '')
  const [faxDestinationHelpText, setFaxDestinationHelpText] = useState('')
  const [sendFormID, setSendFormID] = useState(0);
  const [formHelpText, setFormHelpText] = useState('')
  const [show,setShow] = useState(false);

  const notes = useSelector(state => state.admin.Notes[props.type])
  const role = useSelector(state =>  state.auth.roleDesc)
  const forms = useSelector(state => state.forms.Forms);

  const faxStatus = useSelector(state => state.admin.FAXCounter)
  const smsStatus = useSelector(state => state.admin.SMSCounter)

  function validateEmail(email) 
  {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
  }

  function addNote() {
    // Determine if the criteria has been made 
    var error = false;
    // Check if note has been entered (note is not required for Fax)
    if ((newNote.length === 0) && noteSelector !== 'Fax' && noteSelector !== 'FaxGP')
    {
      setNewNoteHelpText("You haven't typed anything!");
      error = true;
    }
    else
    {
      setNewNoteHelpText('');
    }
    // Check if subject has been entered if note is email 
    if ((emailSubject.length === 0) && (noteSelector === 'Email' || noteSelector === 'ReferrerEmail' || noteSelector === 'GPEmail'))
    {
      setEmailSubjectHelpText('Did you forget to add a subject?');
      error = true;
    }
    else
    {
      setEmailSubjectHelpText('');
    }
    // Check if file has been added if note is fax 
    if ((existingFile === 0 && !(newFFile && newFFile.size > 0)) && (noteSelector === 'Fax' || noteSelector === 'FaxGP'))
    {
      setAttachmentHelpText('Did you forget to attach a file to be faxed?');
      error = true;
    }
    else
    {
      setAttachmentHelpText('');
    }
    // Check if form has been selected if note is form
    if ((!sendFormID) && (noteSelector === 'Form'))
    {
      setFormHelpText('You must select a form. If you do not have any forms then please contact us for help.');
      error = true;
    }
    else
    {
      setFormHelpText('');
    }
    // Check if file has been added if note is fax 
    if ((noteSelector === 'Fax' || noteSelector === 'FaxGP') && ((!faxDestination) || (faxDestination.length !== 10)))
    {
      setFaxDestinationHelpText('Did you forget to enter a correct (ie. 10 digit) receipient fax number?');
      error = true;
    }
    else
    {
      setFaxDestinationHelpText('');
    }
    // Adding catch-all to prevent errors 
    if (!noteSelector || noteSelector === '')
    {
      setNewNoteHelpText("You haven't typed anything!");
      error = true;
    }
    else
    {
      setNewNoteHelpText('');
    }
    // Check email in CC is actually an email address 
    if (emailCC.length > 1 && !validateEmail(emailCC)) 
    {
      setEmailCCHelpText('CC Email not valid');
      error = true;
    }
    else
    {
      setEmailCCHelpText('');
    }

    // Check no errors 
    if (!error)
    {
      // Ok time to go - move from useState to FormData 
      const formData = new FormData();
      // Add fields 
      var noteBody = newNote;
      if((noteSelector === 'Email' || noteSelector === 'SMS') && footer)
      {
        noteBody = newNote + ' ' + footer
      }
      formData.append('type',props.type);
      formData.append('pkid',props.pkid);
      formData.append('subject',emailSubject);
      formData.append('cc',emailCC);
      formData.append('selector',noteSelector);
      formData.append('note',noteBody);
      formData.append('faxdestination',faxDestination);
      formData.append('faxFile',newFFile);
      formData.append('existingFile',existingFile);
      formData.append('sendFormID',sendFormID);
      // Ok Time to Dispatch 
      process.env.REACT_APP_DEBUG && console.log(...formData);
      dispatch(Admin.actions.postNote({ formData:formData, type: props.type, pkID: props.pkid}));
      clearNote();
    }
  }

  function viewForm(formID)
  {
    // console.log(formID);
    setShow(true);
  }

  const clearNote = () => {
    setnoteSelector(props.noteAction ? props.noteAction : 'SMS');
    setNewNote('');
    setNewNoteHelpText('');
    setEmailSubject('');
    setEmailCC('');
    setEmailSubjectHelpText('');
    setNewFFile();
    setExistingFile(0);
    setFaxDestination('');
    setSendFormID(forms.length > 0 ? forms[0].id : 0);
  }

  function handleFileFFieldChange(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    setNewFFile(event.currentTarget.files[0]) 
  }

  const handleSelectChange = (event) => {
    setnoteSelector(event.target.value);
    //
    // Set defaults based on selection
    //
    if (event.target.value === 'FaxGP')
    {
      // Set fax destination to that of their GP (based on referral)
      setFaxDestination(props.faxGP);
    }
    if (event.target.value === 'SMSGP')
    {
      // Set custom SMS message 
      setNewNote(`Hello ${props.firstName}, Thank you for completing an appointment with Rural Health Connect. You can now book your next appointment with another practitioner. Please log in to the portal at https://portal.ruralhealthconnect.com.au to book an appointment. If you need assistance, please call us via the numbers on our website. Thank you, Rural Health Connect`)
    }
    else
    {
      if (event.target.value === 'SMSChg')
      {
        // Set custom SMS message 
        setNewNote(`Hello ${props.firstName}, If you would like to book with a different practitioner. Please log in to the portal at https://portal.ruralhealthconnect.com.au and make a selection. If you need assistance, please call us via the numbers on our website. Thank you, Rural Health Connect`)
      }
      else
      {
        if (event.target.value === 'SMSCR')
        {
          // Set custom SMS message 
          setNewNote(`Hello ${props.firstName}, This is a reminder to complete your registration. Please click on the link below and follow the steps <INVITEURL> . If you need assistance, please call us via the numbers on our website. Thank you, Rural Health Connect`)
        }
        else
        {
          if (event.target.value === 'SMSCRCL')
          {
            // Set custom SMS message
            setNewNote(`Hello ${props.firstName}, we have received a referral and been trying to connect with you to book an appointment. Can you please ring within 5 business days to discuss or complete your registration by clicking on this link <INVITEURL> . If we do not hear from you we will assume you no longer require our service and will close your referral. Please feel free to contact us at any time in the future. Kind regards, Rural Health Connect 0493 201 005 or 0493 432 144. PLEASE DO NOT REPLY TO THIS TEXT`)
          }
          else
          {
            if (event.target.value === 'SMSCRNC')
            {
              // Set custom SMS message 
              setNewNote(`Hello ${props.firstName}, This is a reminder we have a referral for you. If you would like to book an appointment please click on the following link below to register for your telehealh sessions <INVITEURL> . If you need assistance, please call us via the numbers on our website. Thank you, Rural Health Connect`)
            }
            else
            {
              if (event.target.value === 'SMSCRMR')
              {
                // Set custom SMS message 
                setNewNote(`Hello ${props.firstName}, We have received your paperwork for psychology sessions however in order for you to be eligible for a Medicare rebate, we require a Mental Health Care Plan and GP referral. They can fax it to us on 0745731100. If you require assistance, please call us on 0493 201 005 or 0493 432 144. Kind regards, Rural Health Connect. PLEASE DO NOT REPLY TO THIS MESSAGE`)
              }
              else            
              {
                if (event.target.value === 'GPEmail')
                {
                  // Set custom Email Message 
                  setNewNote(`Dear ${props.gpName},<br> Thank you for your referral of ${props.firstName}  ${props.surname} <br><br> We have made several attempts to contact them however they have not yet signed up with our program.<br> We are therefore closing this file. <br> Please feel free to direct any questions regarding this to us at info@ruralhealthconnect.com.au or
                  call us on 0427 692 377.<br> <br> Kind Regards <br> <br> Rural Health Connect.`)
                }
                else
                {
                  if(event.target.value === 'Form')
                  {
                    // set form selection to first form if not already selected
                    if(forms && forms.length > 0)
                    {
                      setSendFormID(forms[0].id)
                      setNewNote(`Hello ${props.firstName}, 
                      
              You have some paperwork that needs your attention ahead of your session on Rural Health Connect.

              Please log in to the portal at https://portal.ruralhealthconnect.com.au and complete the ${forms[0].formName} form via your dashboard.

              Thank you,

              Rural Health Connect`)
                    }
                    else
                    {
                      setNewNote(`Hello ${props.firstName}, 
                      
              You have some paperwork that needs your attention ahead of your session on Rural Health Connect.

              Please log in to the portal at https://portal.ruralhealthconnect.com.au and complete the form via your dashboard.

              Thank you,

              Rural Health Connect`)

                    }
                  }
                  else
                  {
                    setNewNote('')
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const handleSelectForm = (event) => {
    setSendFormID(event.target.value);  
    setNewNote(`Hello ${props.firstName}, 
        
You have some paperwork that needs your attention ahead of your session on Rural Health Connect.

Please log in to the portal https://portal.ruralhealthconnect.com.au and complete the ${forms.find(row => row.id === event.target.value).formName} form via your dashboard.

Thank you,

Rural Health Connect`)
    // setNewNote(`Please log in to the portal and complete the ${forms.find(row => row.id === event.target.value).formName} form via your dashboard.`)
  };

  const handleSelectFile = (event) => {
    setExistingFile(event.target.value);  
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: 200,
      // maxWidth: 200,
    },
    media: {
      height: 200,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));
  
  function EmailSubject(){
    // Check if noteSelector is set to email 
    if ((noteSelector !== 'Email') && (noteSelector !== 'ReferrerEmail') && (noteSelector !== 'GPEmail'))
    {
      return (null);
    }
    //
    return (
      <Row>
        <Col md="12">
          <Form.Group >
            <Form.Label>Subject</Form.Label>
            <Form.Control type="text" name="Subject" defaultValue={emailSubject} onBlur={(event) => setEmailSubject(event.target.value)} autoComplete='new-password' style={{ backgroundColor: 'white' }} />
            {emailSubjectHelpText.length ? (<p className='error-help'>{emailSubjectHelpText}</p>) : ''}
          </Form.Group>
        </Col>
      </Row>
    )
  }

  function EmailCC(){
    // Check if noteSelector is set to email 
    if ((noteSelector !== 'Email') && (noteSelector !== 'ReferrerEmail') && (noteSelector !== 'GPEmail'))
    {
      return (null);
    }
    //
    return (
      <Row>
        <Col md="12">
          <Form.Group >
            <Form.Label>CC</Form.Label>
            <Form.Control type="text" name="CC" defaultValue={emailCC} onBlur={(event) => setEmailCC(event.target.value)} autoComplete='new-password' style={{ backgroundColor: 'white' }} />
            {emailCCHelpText.length ? (<p className='error-help'>{emailCCHelpText}</p>) : ''}
          </Form.Group>
        </Col>
      </Row>
    )
  }

  function FaxDestination(){
    // Check if noteSelector is set to fax 
    if ((noteSelector !== 'Fax') && (noteSelector !== 'FaxGP'))
    {
      return (null);
    }
    //
    return (
      <Row>
        <Col md="12">
          <Form.Group >
            <Form.Label>Recipient Fax Number</Form.Label>
            <Form.Control type="text" name="FaxDestination" defaultValue={faxDestination} onBlur={(event) => setFaxDestination(event.target.value)} autoComplete='new-password' />
            {faxDestinationHelpText.length ? (<p className='error-help'>{faxDestinationHelpText}</p>) : ''}
            <Form.Text id="FaxDestination" muted style={{ marginLeft: 10 }}>
              {props.medicalPractice ?
                <>
                  {(props.medicalPractice && props.medicalPractice.practicename) || ''} - {(props.medicalPractice && props.medicalPractice.fax) || ''}
                </>
              :
                null
              }
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    )
  }  

  function FormSelection(){
    // Check if noteSelector is set to form 
    if (noteSelector !== 'Form')
    {
      return (null);
    }
    //
    return (
      <Row>
        <Col md="12">
          <Form.Group >
            <FormControl variant="outlined" margin="dense">
              <Form.Label>Select Form  <div onClick={() => viewForm(sendFormID)} style={{ marginLeft: 10, display: 'inline-block', cursor: 'pointer', fontWeight: 600, backgroundColor: getActionColour() }}>(<LaunchIcon fontSize="small" style={{ fontWeight: 600, paddingBottom: 4 }} /> Preview)</div></Form.Label>
              <Select 
                id='sendFormID'
                aria-label='Select Action Type'
                name='sendFormID'
                value={sendFormID} 
                onChange={handleSelectForm}
                style={{ backgroundColor: 'white' }}>
                {forms.map(row => { return (
                  <MenuItem key={`form-${row.id}`} value={row.id}>{row.formName}</MenuItem>
                  )}
                )}
                
              </Select>
            </FormControl> 
            {formHelpText.length ? (<p className='error-help'>{formHelpText}</p>) : ''}            
          </Form.Group>  
        </Col>
      </Row>
    )
  }

  function Attachment(){
    // Check if noteSelector is set to fax 
    if (noteSelector !== 'Fax' && noteSelector !== 'FaxGP' && noteSelector !== 'Email' && noteSelector !== 'ReferrerEmail' && noteSelector !== 'GPEmail')
    {
      return (null);
    }
    //
    return (
      <>
        <Row>
          <Col md="12">  
            <Form.Label>Select an Existing File or Upload</Form.Label>
              <Form.Group className="file-upload-form">                
                <FormControl variant="outlined" margin="dense">
                  <Select 
                    id='fileSelect'
                    aria-label='Select Existing File'
                    name='existingFile'
                    value={existingFile} 
                    onChange={handleSelectFile}
                    style={{ backgroundColor: 'white', width:'100%', marginBottom:20 }}>
                    <MenuItem key='file-0' value={0}> Not Selected</MenuItem>
                    {loadFiles.map(row => { return (
                      <MenuItem key={`file-${row.id}`} value={row.id}>{row.filename}</MenuItem>
                      )}
                    )}
                  </Select>
              </FormControl>
              { existingFile === 0 ?
                <>
                  <Form.Control type="file" name="newfile" onChange={(event) => handleFileFFieldChange('newfile', event)} />  
                  {newFFile ? `Selected: ${newFFile.name}` : ''}
                </>
              :
                null
              }
              {attachmentHelpText.length ? (<p className='error-help'>{attachmentHelpText}</p>) : ''}
            </Form.Group>
          </Col>
        </Row>

      </>
    )
  }

  function Footer(){
    // Check if noteSelector is set to email 
    if ((noteSelector === 'Email' || noteSelector === 'SMS') && (props.type !== "ClientRegistration"))
    {
      return (
        <Form.Group>
          <Form.Label>Footer (Waiting Room URL)</Form.Label>
          <Form.Control type="text" name="footer" defaultValue={props.waitingRoomURL} onBlur={(event) => setFooter(event.target.value)} autoComplete='new-password' style={{ fontSize: 12, backgroundColor: 'white' }} />
        </Form.Group>
      )
    }
    else 
    {
      return (null);
    }
  }
   
  function Message(){
    // Check if noteSelector is set to fax 
    if (noteSelector === 'Fax' || noteSelector === 'FaxGP')
    {
      return (null);
    }
    //
    return (
      <Row>
        <Col md="12">                  
          <Form.Group >
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={8} type="text" name="psychologistNote" defaultValue={newNote} onBlur={(event) => setNewNote(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
            {newNoteHelpText.length ? (<p className='error-help'>{newNoteHelpText}</p>) : ''}
          </Form.Group>
        </Col>
      </Row>
    )
  }

  const classes = useStyles();

  const getSMSDisabled = val => {
    // 
    if (role !== 'Admin')
    {
      if (!smsStatus)
      {
        return {disabled: true};
      }
    }
    return {}; 
  }

  const getFaxDisabled = val => {
    // 
    if (role !== 'Admin')
    {
      if (!faxStatus)
      {
        return {disabled: true};
      }
    }
    return {}; 
  }

  const getFaxGPDisabled =  val => {
    // 
    // Check if provider has run out of faxes 
    //
    if (role !== 'Admin')
    {
      if (!faxStatus)
      {
        return {disabled: true};
      }
    }
    // Check if GP Fax has been sent 
    if (!props.faxGP)
    {
      return {disabled: true};
    }
    return {}; 
  }

  const getReferrerEmailDisabled =  val => {
    // 
    // Check if Referrer Email Provided 
    //
    if ((!props.referrerEmail) || (props.referrerEmail && props.referrerEmail.length < 0))
    {
      return {disabled: true};
    }
    return {}; 
  }

  const getGPEmailDisabled =  val => {
    // 
    // Check if GP Email Provided 
    //
    if ((!props.gpEmail) || (props.gpEmail && props.gpEmail.length < 0))
    {
      return {disabled: true};
    }
    return {}; 
  }


  const getFormDisabled =  val => {
    // 
    // Check if in invite - cannot send client invites forms 
    //
    if (props.type ==="ClientRegistration")
    {
        return {disabled: true};
    }
  }


  function getActionColour() {
    if(noteSelector === 'Note'){
      return '#ebfae8';
    } else if (noteSelector === 'Email') {
      return '#d2f9fa';
    } else if (noteSelector === 'ReferrerEmail') {
      return '#fffbc2';
    } else if (noteSelector === 'GPEmail') {
      return '#ffe6ff';
    } else if (noteSelector === 'SMS') {
      return '#faefcf';
    } else if (noteSelector === 'SMSCR') {
      return '#00B3BE';
    } else if (noteSelector === 'SMSGP') {
      return '#00B3BE';
    } else if (noteSelector === 'SMSChg') {
      return '#93A33A';
    } else if (noteSelector === 'SMSCRNC') {
      return '#93A33A';
    } else if (noteSelector === 'SMSCRMR') {
      return '#93A33A';
    } else if (noteSelector === 'Form') {
      return '#fffbc2';
    } else if (noteSelector === 'Fax') {
      return '#fff0ed';
    } else if (noteSelector === 'FaxGP') {
      return '#ffe6ff';
    }
  }
  return(
    <>
      <Grid container spacing={4}>
        <Grid item md={4}>  
          <Row>
            <Col md="12">
              <Form.Group >
                <FormControl variant="outlined" margin="dense">
                  <Form.Label>Select Action</Form.Label>
                  <Select 
                    id='noteSelector'
                    aria-label='Select Action Type'
                    name='noteSelector'
                    value={noteSelector} 
                    onChange={handleSelectChange}
                    style={{ backgroundColor: getActionColour() }}>
                    <MenuItem key='Note' value='Note'  >Record Note</MenuItem>
                    <MenuItem key='Email' value='Email' >Email To {props.type === 'ClientRegistration' ? 'Invitee' : props.type}</MenuItem>
                    { props.type === 'ClientRegistration' ? <MenuItem key='GPEmail' value='GPEmail' {...getGPEmailDisabled(true)} >Email To Referring GP</MenuItem>  : null }
                    { props.type === 'ClientRegistration' ? <MenuItem key='ReferrerEmail' value='ReferrerEmail' {...getReferrerEmailDisabled(true)} >Email To Referrer</MenuItem>  : null }
                    <MenuItem key='SMS' value='SMS' {...getSMSDisabled(true)} >SMS To {props.type === 'ClientRegistration' ? 'Invitee' : props.type}</MenuItem>
                    { props.type === 'ClientRegistration' ? <MenuItem key='SMSCR' value='SMSCR' {...getSMSDisabled(true)} >SMS To Invitee re Registration</MenuItem> : null }
                    { props.type === 'ClientRegistration' ? <MenuItem key='SMSCRNC' value='SMSCRNC' {...getSMSDisabled(true)} >SMS To Invitee re No Contact</MenuItem> : null }
                    { props.type === 'ClientRegistration' ? <MenuItem key='SMSCRCL' value='SMSCRCL' {...getSMSDisabled(true)} >SMS to Invitee re Close file</MenuItem> : null }
                    <MenuItem key='SMSCRMR' value='SMSCRMR' {...getSMSDisabled(true)} >SMS  re Medicare Paperwork</MenuItem>
                    { props.type === 'Client' ? <MenuItem key='SMSGP' value='SMSGP' {...getSMSDisabled(true)} >SMS To Client re Need to Select a Provider</MenuItem> : null }
                    { props.type === 'Client' ? <MenuItem key='SMSChg' value='SMSChg' {...getSMSDisabled(true)} >SMS To Client re New Provider Selection</MenuItem> : null }
                    <MenuItem key='Form' value='Form' {...getFormDisabled(true)}>Send Form to {props.type === 'ClientRegistration' ? 'Invitee' : props.type}</MenuItem>
                    <MenuItem key='Fax' value='Fax' {...getFaxDisabled(true)} >Send Fax Document</MenuItem>
                    <MenuItem key='FaxGP' value='FaxGP' {...getFaxGPDisabled(true)} >Send Fax to Client GP</MenuItem>
                  </Select>
                </FormControl> 
              </Form.Group>  

              <EmailSubject />
              <EmailCC />
              <FaxDestination />
              <FormSelection />
              <Attachment />
              <Message />
              <Footer />
              
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Grid container spacing={0} padding={6} justifyContent="space-between">
                <Button variant="light" size="sm" onClick={() => clearNote()} style={{ borderColor: '#D1D1D1'}}>
                  Clear
                </Button>
                <Button variant="success" size="sm" onClick={() => addNote()}>
                  {noteSelector === 'Note' ? 'Save' : 'Send'}
                </Button>
              </Grid>
            </Col>
          </Row>
        </Grid>
        <Grid item md={8}>
          <Form.Group>
            <Form.Label>Notes</Form.Label>
            <TableContainer style={{ backgroundColor: '#FFF', height: 540, width: "100%", overflowY: "scroll" }}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 100 }}>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes ? notes.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        {format(new Date(row.createdOn), "dd-MM-yyyy HH:mm")}
                      </TableCell>
                      <TableCell>
                        {row.action}
                      </TableCell>
                      <TableCell>
                        {row.note}
                      </TableCell>
                    </TableRow>
                  )): null}
                </TableBody>
              </Table>
            </TableContainer>
          </Form.Group >
        </Grid>
      </Grid>

      <Modal show={show} onHide={() => setShow(false)} dialogClassName="edit-md-modal">
        <Modal.Header className="edit-md-modal">
          <Modal.Title>View Form</Modal.Title>
          <CloseIcon onClick={() => setShow(false)} style={{ cursor: 'pointer' }} />
        </Modal.Header>
        <Modal.Body className="edit-md-modal">             
          <FormDataEntry setFormID={sendFormID} formID={sendFormID} formDataID={0} setShowFormData={setShow} />
        </Modal.Body>
      </Modal>

  </>

  )
}
  
