import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export const BookingServiceDropDownCell = props => {
    const localizedData = [
        {
          text: 'Bulk Billed',
          value: 'Bulk Billed'
        },
        {
          text: 'Reduced Gap',
          value: 'Reduced Gap'
        }, 
        {
          text: 'Full Fee',
          value: 'Full Fee'
        },
        {
          text: 'PrivateClient',
          value: 'PrivateClient'
        },
        {
          text: 'Contract',
          value: 'Contract'
        },
        {
          text: 'NDIS',
          value: 'NDIS'
        },
        {
          text: 'EAP',
          value: 'EAP'
        },
        {
          text: 'Provisional',
          value: 'Provisional'
        },
    ];

    const handleChange = e => {
      if (e.target.value.value && e.target.value.value !== 'NDIS' && e.target.value.value !== 'EAP' &&  e.target.value.value !== 'Contract' &&  e.target.value.value !== 'Provisional')
      {
        if (props.onChange) {
          props.onChange({
              dataIndex: 0,
              dataItem: props.dataItem,
              field: props.field,
              syntheticEvent: e.syntheticEvent,
              value: e.target.value.value,
          });
          // console.log(e.target.value.value);
        }
      }
    };

    const {
        dataItem
      } = props;
      const field = props.field || '';
      const dataValue = dataItem[field] === null ? '' : dataItem[field];
      return <td>
            {dataItem.inEdit ? <DropDownList style={{
          width: "150px"
        }} onChange={handleChange} value={localizedData.find(c => c.value === dataValue)} data={localizedData} textField="text" /> : dataValue.toString()}
          </td>;
    };


  