import React from "react";
import { SchedulerForm } from "@progress/kendo-react-scheduler";
import { CustomFormEditor } from "./custom-form-editor";
import { CustomDialog } from "./custom-dialog";

export const FormWithCustomEditor = (props) => {

  const requiredValidator = React.useCallback(
    (value) =>
        (value === undefined || value === null || value === "")
        ? "Field is required."
        : undefined,
    []
  );

  const formValidator = (_dataItem, formValueGetter) => {
    let result = {};
    if(_dataItem.eventType === 'booking')
    {
      result.clientID = [requiredValidator(formValueGetter("clientID"))]
        .filter(Boolean)
        .reduce((current, acc) => current || acc, "");
      result.status = [requiredValidator(formValueGetter("status"))]
        .filter(Boolean)
        .reduce((current, acc) => current || acc, "");
    }
    return result;
  };

  const initDataItem = {
    eventType: 'booking',
    status: 'Active',  
    bookingType: 'VIDEO',
    paymentType: 'Bulk Billed'  
  }

  return (
    <SchedulerForm
      {...props}
      editor={CustomFormEditor}
      dialog={CustomDialog}
      validator={formValidator}
      initialValues={{ ...initDataItem, ...props.dataItem }}
    />
  );
  
};