import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import * as admin from "../../store/ducks/admin.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
// import { LayoutSplashScreen } from "../../../_metronic";


class Logout extends Component {
  componentDidMount() {
    this.props.setAdminInitialState();
    this.props.logout();
  }

  render() {
    // for some reason this just hangs, so replace it with simple Redirect
    
    // const { hasAuthToken } = this.props;
    // return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
    return  <Redirect to="/auth" />
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.actions.logout()),
    setAdminInitialState: () => dispatch(admin.actions.setAdminInitialState()),
  };
};



export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps
)(Logout);
