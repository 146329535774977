import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import * as Forms from "../store/ducks/forms.duck";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import { Paper, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles, Tooltip, Checkbox, FormControl, Select, MenuItem } from '@material-ui/core';
import FormDataEntry from "../pages/home/admin/FormDataEntry";
import CloudDownload from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import { format } from "date-fns";



export default function Files(props) {
    
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchFiles({ type: props.type, pkID: props.pkid })) }, [dispatch, props.type, props.pkid] )
  useEffect( () => { dispatch(Forms.actions.fetchForms()) }, [dispatch] )
  const role = useSelector(state =>  state.auth.roleDesc)
  const inviteStatus = props.inviteStatus ? props.inviteStatus : 'none'
  
  var [newFile, setNewFile] = useState('')
  var [mhcp, setmhcp] = useState(0)
  const forms = useSelector(state => state.forms.Forms);
  // Will need to add more to this filter as they are developed
  const pdfForms = forms.filter(form => (form.formDataPlugin === 'Referring GP Letter' || form.formDataPlugin === 'Referring GP Ad-Hoc Letter' || form.formDataPlugin === 'Referring GP No Contact Letter'))
  const [sendFormID, setSendFormID] = useState(0);
  const [showFormData, setShowFormData] = useState(false);
  const [formData, setFormData] = useState()
  const [showCompleteForm, setShowCompleteForm] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [deleteFileId, setDeleteFileId] = useState()
  const [deleteFileName, setDeleteFileName] = useState()
  const [selectedForm, setSelectedForm] = useState()
  const files = useSelector(state => state.admin.Files[props.type])
  const subType = (props.subtype && props.subtype === 'NDIS' ? 'NDIS' : 'notset')

  function handleFileFieldChange(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    setNewFile(event.currentTarget.files[0]) 
  }

  function handleConfirmationClose() {
    setShowConfirmation(false);
  }  

  function addFile() {
    // Move from useState to FormData 
    const formData = new FormData();
    // Add fields 
    // console.log(newFile);
    formData.append('type',props.type);
    formData.append('pkid',props.pkid);
    formData.append('newfile',newFile);
    formData.append('mhcpFlag',mhcp);
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(...formData);
    dispatch(Admin.actions.postFile({formData:formData, type: props.type, pkID: props.pkid}));
    setNewFile('');
  }

  function completeForm() {
    setShowCompleteForm(true);
    setSelectedForm(forms.filter(form => form.id === sendFormID)[0].formName)
    // console.log(forms.filter(form => form.id === sendFormID));
  }


  const handleSelectForm = (event,row) => {
    setSendFormID(event.target.value);  
  };

  function DeleteFeature(props)
  {
    // Do not display delete icon for clients 
    if (role === 'Client')
    {
      return(null);
    }
    return(
      <Tooltip title="Delete" aria-label="Delete" placement="top" arrow>
        <Delete onClick={() => deleteConfirmation(props.id)} style={{ color: '#646c9a', cursor: 'pointer', marginLeft: 14 }} />
      </Tooltip>
    )
  }

  function handleCheckBoxChange(id,currentState) 
  {
    // console.log(id)
    // console.log(currentState)
    dispatch(Admin.actions.setFileClientViewableFlag({id:id, current: currentState, type: props.type, pkID: props.pkid}));
  };


  const loadDataEntry = (formData) => {
    setFormData(formData)
    setShowFormData(true);
  }

  function deleteConfirmation(delId)
  {
    setDeleteFileId(delId)
    setDeleteFileName(files.filter(file => file.id === delId)[0].filename)
    // console.log('Files')
    // console.log(delId)
    // console.log(files.filter(file => file.id === delId)[0].filename)
    setShowConfirmation(true)
  }
 
  function deleteFile() {
    // Soft delete file 
    // console.log(id);
    dispatch(Admin.actions.deleteFile({id: deleteFileId, type: props.type, pkID: props.pkid}));
  }

   const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: 200,
      // maxWidth: 200,
    },
    media: {
      height: 200,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));
    
  const classes = useStyles();

  return(
    <>
    <Grid container spacing={4}>
      <Grid item md={props.vertical ? 12 : 4}>  
        <Row>
          <Col md="12">                  
            <Form.Group >
              <Form.Label>Add File:</Form.Label>
              <Grid container className="file-upload-form">
                <Grid item md={8} >
                   <Form.Control type="file" name="newfile" onChange={(event) => handleFileFieldChange('newfile', event)}/>
                </Grid>
                {props.vertical ? 
                <Grid item md={4}>
                  <Button variant="success" size="sm" onClick={() => addFile()}>
                    Upload
                  </Button>
                </Grid>
                : null }
              </Grid>
            </Form.Group>
          </Col>
          {props.type === 'Client' ? 
            <Col md="12">
              <Form.Group>
                <Checkbox aria-label='Mental Health Care Plan' name="mhcp" value={mhcp} onChange={(event) => setmhcp(event.target.checked)} style={{ paddingTop: 6, paddingLeft: 0 }} />
                {subType && subType === 'NDIS' ?
                  <span style={{ paddingBottom: 4 }}>This is the current NDIS Plan</span>
                
                :
                  <span style={{ paddingBottom: 4 }}>This is the current Mental Health Care Plan</span>
                }
              </Form.Group>
            </Col>        
          : null} 
          {props.vertical ? null :
            <Col md="12"> 
              <Grid container spacing={0} padding={6} justifyContent="space-between">
                <Grid item>

                </Grid>
                <Grid item>
                  <Button variant="success" size="sm" onClick={() => addFile()}>
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </Col>
          }
        </Row>    
        {role !== 'Client' && role !== 'Referrer' && (inviteStatus === 'Sent' || inviteStatus === 'none') ?
          <>
            <hr />
            {pdfForms && pdfForms.length > 0 ?
              <Row>
                <Col md="12">                  
                  <Form.Group >
                    <FormControl variant="outlined" margin="dense">
                      <Form.Label>Generate PDF:</Form.Label>
                      <Select 
                        id='sendFormID'
                        aria-label='Select Action Type'
                        name='sendFormID'
                        value={sendFormID} 
                        onChange={handleSelectForm}
                        style={{ backgroundColor: 'white', minWidth: 150 }}>
                        <MenuItem key={0} value={0}></MenuItem>
                        {pdfForms.map(row => { return (
                          <MenuItem key={`form-${row.id}`} value={row.id}>{row.formName}</MenuItem>
                          )}
                        )}
                      </Select>
                    </FormControl> 
                  </Form.Group>  
                </Col>
                    {sendFormID !== 0 ? 
                      <Col md="12"> 
                        <Grid container spacing={0} padding={6} justifyContent="space-between">
                          <Grid item>

                          </Grid>
                          <Grid item>
                                  <Button variant="success" size="sm" onClick={() => completeForm()}>
                                    Display Form
                                  </Button>
                          </Grid>
                        </Grid>
                      </Col>
                    :
                      null
                    }
              </Row>
            :
              null
            }
          </>
        :
         null
        }
      </Grid>
      <Grid item md={props.vertical ? 12 : 8}>
        {/* <hr style={{ marginTop: 40 }}></hr> */}
        <Form.Group>
          <Form.Label>Files</Form.Label>
            <Paper>
              <TableContainer style={{ backgroundColor: '#FFF', height: props.vertical ? 300 : 540, width: "100%", overflowY: "scroll" }}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>File</TableCell>
                      {props.type === 'Client' ? 
                        <TableCell>Type</TableCell>
                        :
                        null
                      }
                      {props.type === 'Client' && role !== 'Client' ? 
                        <TableCell>Client View</TableCell>
                        :
                        null
                      }
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {files ? files.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {format(new Date(row.createdOn), "dd-MM-yyyy HH:mm")}
                        </TableCell>
                        <TableCell>
                          {row.filename}
                        </TableCell>
                        {props.type === 'Client' ? 
                          <TableCell>
                            {subType && subType === 'NDIS' && row.mhcpFlag === 'MHCP'?
                              'NDIS Plan'
                            :
                              <>
                                {row.mhcpFlag}
                              </>
                            }
                          </TableCell>
                          :
                          null
                        }
                        {props.type === 'Client' && role !== 'Client' ? 
                          <TableCell>
                            <Checkbox checked={row.clientViewable === '1' ? true : false} onClick={() => handleCheckBoxChange(row.id,row.clientViewable)} />
                          </TableCell>
                          :
                          null
                        }
                        <TableCell>
                          {row.formData 
                           ? <DescriptionIcon style={{ color: '#646c9a', cursor: 'pointer' }} onClick={() => loadDataEntry(row.formData)} />
                           : 
                          <a href={row.downloadlink}>
                            <Tooltip title="Download" aria-label="Download" placement="top" arrow>
                              <CloudDownload style={{ color: '#646c9a' }} />
                            </Tooltip>
                          </a>
                          } 
                          {!row.formData && <DeleteFeature id={row.id} />}
                        </TableCell>
                      </TableRow>
                    )): null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Form.Group >
        </Grid>
      </Grid>

      <Modal show={showFormData} onHide={() => setShowFormData(false)} dialogClassName="edit-md-sm-dialog-modal">
        <Modal.Header className="edit-md-sm-modal">
          <Modal.Title>{formData && formData.formName}</Modal.Title>
          <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setShowFormData(false)} >
            <CloseIcon />
          </Button> 
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          {formData && <FormDataEntry formID={formData.formID} formDataID={formData.formDataID} setShowFormData={setShowFormData} />}
        </Modal.Body>
      </Modal>

      <Modal show={showCompleteForm} onHide={() => setShowCompleteForm(false)} dialogClassName="edit-md-sm-dialog-modal">
        <Modal.Header className="edit-md-sm-modal">
          <Modal.Title>Generating {selectedForm && selectedForm} PDF</Modal.Title>
          <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setShowCompleteForm(false)} >
            <CloseIcon />
          </Button> 
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          <FormDataEntry formID={sendFormID} formDataID={0} setShowFormData={setShowCompleteForm} contextName={props.type} contextID={props.pkid} pdfFlag={true} />
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmation} onHide={handleConfirmationClose} dialogClassName="edit-md-sm-dialog-modal">
        <Modal.Header className="edit-md-sm-modal">
          <Modal.Title>Delete File Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          <Grid item md={12}>
            <p>Delete File: <span style={{ fontWeight: 600 }}>{deleteFileName}</span>?</p>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="edit-md-modal">
          <Button variant="secondary" onClick={handleConfirmationClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => deleteFile()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )


}
  
