import * as React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  Toolbar,
  WeekView,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DateNavigator,
  EditRecurrenceMenu,
  ConfirmationDialog,
  TodayButton,
  AllDayPanel,

} from '@devexpress/dx-react-scheduler-material-ui';

// import { yellow, lime, blue, amber, cyan, pink, teal, purple, orange, green, grey } from '@material-ui/core/colors';
import * as Admin from "../store/ducks/admin.duck";


const currentishDate = new Date();

const LabelComponent = (props) => {
  if (props.text === "Details" || props.text === "More Information") {
    return null;
  }
  return <AppointmentForm.Label {...props} />;
};

const TextEditor = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  // console.log(props);
  if (props.type === 'multilineTextEditor' || props.type === 'titleTextEditor')  {
    return null;
  } return <AppointmentForm.TextEditor {...props} />;
};

const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
  // Force title to be Available
  var newappointmentData = {...appointmentData, title:'Available', billingType: "1"}
  // console.log('Here')
  // console.log(newappointmentData)
  return (
    <AppointmentForm.BasicLayout
      appointmentData={newappointmentData }
      onFieldChange={onFieldChange}
      {...restProps}
    >
    </AppointmentForm.BasicLayout>
  );
};

const ResourceEditor = props => {
  var newprops = {}
  if(props.appointmentResources.length === 0){
    newprops = { ...props, 
      appointmentResources: [ { id: "1", color: "green", fieldName: "billingType", text: "Medicare", title: "BillingTypes", isMain: true, allowMultiple: false } ] 
    }
  } else {
    newprops = props;
  }
  return <AppointmentForm.ResourceEditor {...newprops} />;
};

class Availability extends React.PureComponent {
  constructor(props) {
    super(props);
    let filteredData = [];
    props.currentAvailability.forEach(event => {
      if (event.availabilityType === 'Any')
      {
        // Note this is known as New and Existing Clients - i.e. Any 
        let fa = {
          "slot"            : event.id,
          "type"            : 'A',
          "id"              : event.id,
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Available-New Client Bookings',
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "billingType"     : event.billingType[0],
          "availabilityType": 'Any',
          "color"           : "blue",
        };
        filteredData.push(fa);
      }
      if (event.availabilityType === 'Existing')
      {
        // Note this is known as Existing Clients Only - i.e. Existing 
        let fa = {
          "slot"            : event.id,
          "type"            : 'A',
          "id"              : event.id,
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Available-Existing',
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "billingType"     : event.billingType[0],
          "availabilityType": 'Existing',
          "color"           : "orange",
        };
        filteredData.push(fa);
      }
      if (event.availabilityType === 'Other')
      {
        // 
        let fa = {
          "slot"            : event.id,
          "type"            : 'A',
          "id"              : event.id,
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Available-Other (Not Available)',
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "billingType"     : event.billingType[0],
          "availabilityType": 'Other',
          "color"           : "purple",
        };
        filteredData.push(fa);
      }
      if (event.availabilityType === 'NDISContractEAP')
      {
        // 
        let fa = {
          "slot"            : event.id,
          "type"            : 'A',
          "id"              : event.id,
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Available - NDIS/Contract/EAP',
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "billingType"     : event.billingType[0],
          "availabilityType": 'NDISContractEAP',
          "color"           : "#F7D6D0",
        };
        filteredData.push(fa);
      }
      if (event.availabilityType === 'Supervision')
      {
        // 
        let fa = {
          "slot"            : event.id,
          "type"            : 'A',
          "id"              : event.id,
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Available-Supervision',
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "billingType"     : event.billingType[0],
          "availabilityType": 'Supervision',
          "color"           : "gray",
        };
        filteredData.push(fa);
      }
    });

    this.props.currentBookings.forEach(event => {
      if (event.status !== 'Cancelled')
      {
        let fa = {
          "slot"            : event.id.toString(),
          "type"            : 'B',
          "id"              : event.id.toString(),
          "psychologistID"  : event.psychologistID,
          "startDate"       : new Date(event.startDate),
          "endDate"         : new Date(event.endDate),
          "title"           : 'Booked - ' + event.clientfullname,
          "allDay"          : event.allDay,
          "rRule"           : event.rRule, 
          "exDate"          : event.exDate,
          "createdBy"       : event.createdBy,
          "createdOn"       : event.createdOn,
          "color"           : "green",
        };
        filteredData.push(fa);
      }
    });



    this.state = {
      data: filteredData,
      currentDate: currentishDate,

      addedAppointment: { 
        title: "Available",
        billingType: "1",
        availabilityType: "Any",
        allDay: false
      },
      appointmentChanges: {},
      editingAppointment: undefined,
      

      
    };

    this.currentDateChange = this.currentDateChange.bind(this);  //(currentDate) => { this.setState({ currentDate }); };
    this.commitChanges = this.commitChanges.bind(this);
    this.changeAddedAppointment = this.changeAddedAppointment.bind(this);
    this.changeAppointmentChanges = this.changeAppointmentChanges.bind(this);
    this.changeEditingAppointment = this.changeEditingAppointment.bind(this);
    this.mainID = props.mainID;
    this.userTZ = props.userTZ;
    this.winbrowserTZ = props.winbrowserTZ;
    
  }

  

  componentDidMount() {
    this.currentDateChange(this.state.currentDate)
    this.props.FETCHBOOKINGS({ key: 'psychologist', id: this.props.mainID, userTZ: this.props.userTZ, winbrowserTZ: this.props.winbrowserTZ });
  }

  componentDidUpdate(prevProps) {
    if ((this.props.currentAvailability !== prevProps.currentAvailability) || (this.props.currentBookings !== prevProps.currentBookings))
    {
      let filteredData = [];
      this.props.currentAvailability.forEach(event => {
        if (event.availabilityType === 'Any')
        {
          // Note this is known as New and Existing Clients - i.e. Any 
          let fa = {
            "slot"            : event.id,
            "type"            : 'A',
            "id"              : event.id,
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Available-New Client Bookings',
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "billingType"     : event.billingType[0],
            "availabilityType": 'Any',
            "color"           : "blue",
          };
          filteredData.push(fa);
        }
        if (event.availabilityType === 'Existing')
        {
          // Note this is known as Existing Clients Only - i.e. Existing 
          let fa = {
            "slot"            : event.id,
            "type"            : 'A',
            "id"              : event.id,
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Available-Existing',
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "billingType"     : event.billingType[0],
            "availabilityType": 'Existing',
            "color"           : "orange",
          };
          filteredData.push(fa);
        }
        if (event.availabilityType === 'Other')
        {
          // 
          let fa = {
            "slot"            : event.id,
            "type"            : 'A',
            "id"              : event.id,
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Available-Other (Not Available)',
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "billingType"     : event.billingType[0],
            "availabilityType": 'Other',
            "color"           : "purple",
          };
          filteredData.push(fa);
        }
        if (event.availabilityType === 'NDISContractEAP')
        {
          // 
          let fa = {
            "slot"            : event.id,
            "type"            : 'A',
            "id"              : event.id,
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Available - NDIS/Contract/EAP',
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "billingType"     : event.billingType[0],
            "availabilityType": 'NDISContractEAP',
            "color"           : "#F7D6D0",
          };
          filteredData.push(fa);
        }
        if (event.availabilityType === 'Supervision')
        {
          // 
          let fa = {
            "slot"            : event.id,
            "type"            : 'A',
            "id"              : event.id,
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Available-Supervision',
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "billingType"     : event.billingType[0],
            "availabilityType": 'Supervision',
            "color"           : "gray",
          };
          filteredData.push(fa);
        }
      });
      this.props.currentBookings.forEach(event => {
        if (event.status !== 'Cancelled')
        {
          let fa = {
            "slot"            : event.id.toString(),
            "type"            : 'B',
            "id"              : event.id.toString(),
            "psychologistID"  : event.psychologistID,
            "startDate"       : new Date(event.startDate),
            "endDate"         : new Date(event.endDate),
            "title"           : 'Booked - ' + event.clientfullname,
            "allDay"          : event.allDay,
            "rRule"           : event.rRule, 
            "exDate"          : event.exDate,
            "createdBy"       : event.createdBy,
            "createdOn"       : event.createdOn,
            "color"           : "green",
          };
          filteredData.push(fa);
        }
      });

      this.setState({ 
          data: filteredData
      });
      // console.log('filteredData');
      // console.log(filteredData);
    }
  }

  currentDateChange(currentDate) {
     this.setState({ currentDate }); 
    };

  changeAddedAppointment(addedAppointment) {
    if(!addedAppointment.slot) {
      addedAppointment = { 
        ...addedAppointment,
        title: "Available",
        billingType: "1",
      };
    }
    this.setState({ addedAppointment });
  }

  changeAppointmentChanges(appointmentChanges) {
    this.setState({ appointmentChanges });
  }

  changeEditingAppointment(editingAppointment) {
    this.setState({ editingAppointment });
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, mainID: this.mainID, userTZ: this.userTZ,  ...added }];
        added = {...added, mainID : this.mainID, userTZ: this.userTZ }
        // Note this causes calendar reload from API to get slotid of new record 
        this.props.ADDAVAILABILITY(added);
      }
      if (changed) {
        // console.log(changed);
        var changeKey = Object.keys(changed);
        // console.log(changeKey[0]);
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
        var changedData = data.find(appointment => appointment.id === changeKey[0]);
        changedData = {...changedData, mainID : this.mainID, userTZ: this.userTZ}
        // Need to handle availability and booking
        if (changedData.type === 'A')
        {
          // Add psychologist ID into packet for API 
          // console.log('A');
          // console.log(changedData);
          // Note this changes the current calendar event without API reload
          this.props.CHGAVAILABILITY(changedData);
        }
        else
        {
          // console.log('B');
          // console.log(changedData);
          // Add psychologist ID into packet for API 
          this.props.CHGBOOKING( 
            { id: 0, 
              appointment : changedData,
              status: 'Updated', 
              refreshPayload: { key: 'psychologist', id: this.mainID }
            });
        }
      }
      if (deleted !== undefined) {
        var deleteData = data.find(appointment => appointment.id === deleted.toString());
        data = data.filter(appointment => appointment.id !== deleted);
        // Add psychologist ID into packet for API 
        deleteData = {...deleteData, mainID : this.mainID, userTZ: this.userTZ}
        // console.log(deleteData);
        // Need to handle availability and booking
        if (deleteData.type === 'A')
        {
          // Note this deletes the current calendar event without API reload 
          this.props.DELAVAILABILITY(deleteData);
        }
        else
        {
          if (deleteData.type === 'B')
          {
            this.props.CANCELBOOKING( 
              { id: deleteData.id, 
                status: 'Cancelled', 
                refreshPayload: { key: 'psychologist', id: this.mainID }
              }
            );
          }
        }
      }
      return { data };
    });
  }

  

  render() {
    const {
      currentDate, data, resources, addedAppointment, appointmentChanges, editingAppointment,
    } = this.state;

    const AppointmentContent = (props) => {
      // const { data, style } = props;
      return (
        <Appointments.AppointmentContent
          {...props}
        />
      );
    };


    const Appointment = ({ children, style, data, ...restProps }) => (
      <Appointments.Appointment
        {...restProps}
        data={data}
        readOnly={true}
        style={{
          ...style,
          backgroundColor: data.color
        }}
      >
        {children}
      </Appointments.Appointment>
    );
  
  
    // const { classes } = this.props;
    // console.log(data);
    return (
      <>
      <Paper>
        <div className="availability-info">
          <InfoOutlinedIcon  className="availability-info-icon" /> 
          Double click on a time slot to create an entry for availability. 
          <strong> All Times in {this.winbrowserTZ}</strong>
        </div>
        
      {/* <div>{data.length}</div> */}
        <Scheduler data={data} firstDayOfWeek={1}>
          <ViewState 
            currentDate={currentDate} 
            onCurrentDateChange={this.currentDateChange}
          />
          <EditingState
            onCommitChanges={this.commitChanges}

            addedAppointment={addedAppointment}
            onAddedAppointmentChange={this.changeAddedAppointment}

            appointmentChanges={appointmentChanges}
            onAppointmentChangesChange={this.changeAppointmentChanges}

            editingAppointment={editingAppointment}
            onEditingAppointmentChange={this.changeEditingAppointment}

          />
          <WeekView 
            startDayHour={5}
            endDayHour={23}
            cellDuration={60}
          />

          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <AllDayPanel /> 

          {/* <IntegratedEditing /> */}
          <EditRecurrenceMenu />
          <ConfirmationDialog />
          <Appointments 
              appointmentComponent={Appointment} 
              appointmentContentComponent={AppointmentContent}
          />
          <AppointmentTooltip
            showOpenButton
            showCloseButton
            showDeleteButton
          />
          <AppointmentForm 
            resourceEditorComponent={ResourceEditor} 
            labelComponent={LabelComponent}
            textEditorComponent={TextEditor}
            basicLayoutComponent={BasicLayout}
          />
          <Resources data={resources} mainResourceName="billingType" />
        </Scheduler>
      </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentAvailability: state.admin.Availability,
    currentBookings: state.admin.Bookings,
    userTZ: state.auth.userTZ,
    winbrowserTZ: state.auth.winbrowserTZ
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ADDAVAILABILITY: added => dispatch({ type: Admin.actionTypes.ADD_AVAILABILITY, payload: added }),
    CHGAVAILABILITY: changed => dispatch({ type: Admin.actionTypes.CHG_AVAILABILITY, payload: changed }),
    DELAVAILABILITY: deleted => dispatch({ type: Admin.actionTypes.DEL_AVAILABILITY, payload: deleted }),
    CHGBOOKING: (payload) => dispatch({ type: Admin.actionTypes.PUT_BOOKING, payload: payload}),
    CANCELBOOKING: (payload) => dispatch({ type: Admin.actionTypes.PUT_BOOKING, payload: payload}),
    FETCHBOOKINGS: (payload) => dispatch({ type: Admin.actionTypes.FETCH_BOOKINGS, payload: {...payload} }),
    // FETCHAVAILABILITY: psyID => dispatch({ type: Admin.actionTypes.FETCH_AVAILABILITY, payload: { psychologistid: psyID, userTZ: this.userTZ } }),
  };
 };
 
 export default connect(mapStateToProps,mapDispatchToProps)(Availability);