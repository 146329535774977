import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardReferrer from "./DashboardReferrer";

// Not sure about these two
import { LayoutSplashScreen } from "../../../_metronic";

// Admin Pages 
import { ClientInvitesReferrer } from "./admin/ClientInvites";
import ProfileReferrer from "./admin/ProfileReferrer";
import SearchClient from "./admin/SearchClient";

// Reference Pages 
// import UserRoles from "./reference/UserRoles";

export default function HomePageReferrer() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={DashboardReferrer} />

        {/* Admin Pages */}
        <Route path="/clientinvites" component={ClientInvitesReferrer} />
        <Route path="/profile" component={ProfileReferrer} />
        <Route path="/search" component={SearchClient} />
        {/* Reference Pages */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

