
export default function MenuConfig({roleDesc,roleScope}) {

    switch(roleDesc) {
      case 'Client': 
      const itemsBasic = { 
        header: {
          self: {},
          items: []
        },
        aside: {
          self: {},
          items: [
            {
              title: "Dashboard",
              root: true,
              icon: "flaticon2-architecture-and-city",
              page: "dashboard",
              bullet: "dot"
            },
            {
              title: "Search Providers",
              root: true,
              icon: "flaticon2-map",
              bullet: "dot",
              page: "search"
            },
            {
              title: "Bookings",
              root: true,
              icon: "flaticon2-time",
              bullet: "dot",
              page: "bookings"
            },
            {
              title: "Profile",
              root: true,
              icon: "flaticon2-gear",
              bullet: "dot",
              page: "profile"
            },
            {
              title: "Files",
              root: true,
              icon: "flaticon-file",
              bullet: "dot",
              page: "files"
            },
            {
              title: "Log out",
              root: true,
              icon: "flaticon2-circular-arrow",
              page: "logout"
            },
          ]
        }
      };
      return itemsBasic;

    case 'Admin': 
      // console.log(roleScope)
      if (roleScope !== 'Provisional')
      {
        const itemsAdmin = {
          header: {
            self: {},
            items: []
          },
          aside: {
            self: {},
            items: [
              {
                title: "Dashboard",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                bullet: "dot"
              },
              {
                title: "Search",
                root: true,
                icon: "flaticon2-search",
                page: "searchclientdata",
                bullet: "dot"
              },
              {
                title: "Search Bookings",
                root: true,
                icon: "flaticon2-search",
                page: "searchbookingdata",
                bullet: "dot"
              },
              {
                title: "Faxes",
                root: true,
                icon: "flaticon2-paper",
                page: "faxes"
              },
              {
                title: "Interested Providers",
                root: true,
                icon: "flaticon2-map",
                page: "interestedPsychologists"
              },
              {
                title: "Registered Providers",
                root: true,
                icon: "flaticon2-avatar",
                page: "registeredPsychologists"
              },
              {
                title: "Referrers",
                root: true,
                icon: "flaticon2-avatar",
                page: "referrers"
              },
              {
                title: "Invited Clients",
                root: true,
                icon: "flaticon-download-1",
                page: "clientinvites"
              },
              {
                title: "Clients",
                root: true,
                icon: "flaticon2-user",
                bullet : "dot",
                submenu: [
                  {
                    title: "Psychology",
                    page: "psychologyclients"
                  },
                  {
                    title: "Provisional",
                    page: "provisionalclients"
                  },
                  {
                    title: "Social Worker",
                    page: "socialclients"
                  },
                  { title: "Mental Health Nurse",
                    page: "nurseclients"
                  },
                  {
                    title: "Financial",
                    page: "financialclients"
                  },
                  {
                    title: "GP",
                    page: "gpclients"
                  },
                  {
                    title: "Supervision",
                    page: "supervisionclients"
                  },
                  {
                    title: "EAP/Contract",
                    page: "eapcontractclients"
                  },
                  {
                    title: "NDIS",
                    page: "ndisclients"
                  },
                  {
                    title: "Private",
                    page: "privateclients"
                  },
                  {
                    title: "Unassociated",
                    page: "unassociated"
                  },
                ]
              },
              {
                title: "Medical",
                root: true,
                icon: "flaticon2-hospital",
                bullet: "dot",
                submenu: [
                  {
                    title: "Medical Practitioners",
                    page: "medicalpractitioners"
                  },
                  {
                    title: "Practices",
                    page: "medicalpractices"
                  }
                ]
              },
              {
                title: "Provider Invoicing",
                root: true,
                icon: "flaticon2-shopping-cart",
                submenu: [
                  {
                    title: "Verify Provider",
                    page: "xeroverify"
                  },
                  {
                    title: "Verify NDIS Provider",
                    page: "xerondisverify"
                  },
                  {
                    title: "Gather All Bookings",
                    page: "gatherbillableall"
                  },
                  {
                    title: "Gather Provider Clients",
                    page: "gatherbillable"
                  },
                  {
                    title: "Gather Contract/EAP Clients",
                    page: "gathercontracts"
                  },
                  {
                    title: "Gather NDIS Clients",
                    page: "gatherndis"
                  },
                  {
                    title: "Gather Contract by Provider",
                    page: "gathercontractsprovider"
                  },
                  {
                    title: "Gather NDIS by Provider",
                    page: "gatherndisprovider"
                  },
                  {
                    title: "Generate Provider Invoices",
                    page: "invoices"
                  },
                  {
                    title: "Generate Contract/EAP Invoices",
                    page: "contractinv"
                  },
                  {
                    title: "Generate Contract Provider Payments",
                    page: "contractpay"
                  },
                  {
                    title: "Generate NDIS Invoices",
                    page: "ndisinv"
                  },
                  {
                    title: "Generate NDIS Provider Payments",
                    page: "ndispay"
                  },
                  {
                    title: "Invoice Log",
                    page: "invoicelog"
                  }
                ]
              },
              {
                title: "Notifications",
                root: true,
                icon: "flaticon2-bell",
                page: "notifications"
              },
              {
                title: "My Profile",
                root: true,
                icon: "flaticon2-user-1",
                page: "adminprofile"
              },
              {
                title: "Log out",
                root: true,
                icon: "flaticon-logout",
                page: "logout"
              },
              { section: "System Configuration" }, 
              {
                title: "Admin Users",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "adminusers"
              },
              {
                title: "Forms",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "forms"
              },
              {
                title: "Tags Setup",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "metadataconfig"
              },
              {
                title: "Contract/EAP Setup",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "contracts"
              },
              {
                title: "Opportunity Setup",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "opportunity"
              },
              {
                title: "Program Setup",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "programs"
              },
              {
                title: "Reporting Options",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "adminreporting"
              },

            ]
          }
        };
        return itemsAdmin;
      }
      else
      {
        const itemsAdmin = {
            header: {
              self: {},
              items: []
            },
            aside: {
              self: {},
              items: [
                {
                  title: "Dashboard",
                  root: true,
                  icon: "flaticon2-architecture-and-city",
                  page: "dashboard",
                  bullet: "dot"
                },
                {
                  title: "Registered Providers",
                  root: true,
                  icon: "flaticon2-avatar",
                  page: "registeredPsychologists"
                },
                {
                  title: "Invited Clients",
                  root: true,
                  icon: "flaticon-download-1",
                  page: "clientinvites"
                },
                {
                  title: "Clients",
                  root: true,
                  icon: "flaticon2-user",
                  page: "provisionalclients"
                },
                {
                  title: "My Profile",
                  root: true,
                  icon: "flaticon2-user-1",
                  page: "adminprofile"
                },
                {
                  title: "Log out",
                  root: true,
                  icon: "flaticon-logout",
                  page: "logout"
                }
              ]
            }
          };
          return itemsAdmin;
        }

      case 'Psychologist': 
      const itemsPsychologist = {
          header: {
            self: {},
            items: []
          },
          aside: {
            self: {},
            items: [
              {
                title: "Dashboard",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                translate: "MENU.DASHBOARD",
                bullet: "dot"
              },
              {
                title: "Invited Clients",
                root: true,
                icon: "flaticon-download-1",
                page: "clientinvites"
              },
              {
                title: "Clients",
                root: true,
                icon: "flaticon2-map",
                page: "Clients"
              },
              {
                title: "Bookings",
                root: true,
                icon: "flaticon2-time",
                bullet: "dot",
                page: "bookings"
              },
              {
                title: "Tyro",
                root: true,
                icon: "flaticon2-shopping-cart",
                submenu: [
                  {
                    title: "Maintain Service Codes",
                    page: "codes"
                  },
                  {
                    title: "Invoicing",
                    page: "invoices"
                  },
                ]
              },
              {
                title: "Files",
                root: true,
                icon: "flaticon-file",
                bullet: "dot",
                page: "files"
              },
              {
                title: "Platform",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "platform"
              },
              {
                title: "Forms",
                root: true,
                icon: "flaticon2-document",
                bullet: "dot",
                page: "forms"
              },
              {
                title: "Profile",
                root: true,
                icon: "flaticon2-gear",
                bullet: "dot",
                page: "profile"
              },
              {
                title: "Security",
                root: true,
                icon: "flaticon2-gear",
                bullet: "dot",
                page: "security"
              },
              {
                title: "Log out",
                root: true,
                icon: "flaticon2-circular-arrow",
                page: "logout"
              },
            ]
          }
        };
        return itemsPsychologist;
  
        case 'Referrer': 
        const itemsReferrer = { 
          header: {
            self: {},
            items: []
          },
          aside: {
            self: {},
            items: [
              {
                title: "Welcome",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                bullet: "dot"
              },
              {
                title: "Invited Clients",
                root: true,
                icon: "flaticon-download-1",
                page: "clientinvites"
              },
              {
                title: "Providers",
                root: true,
                icon: "flaticon2-map",
                bullet: "dot",
                page: "search"
              },
              {
                title: "Profile",
                root: true,
                icon: "flaticon2-gear",
                bullet: "dot",
                page: "profile"
              },
              {
                title: "Log out",
                root: true,
                icon: "flaticon2-circular-arrow",
                page: "logout"
              },
            ]
          }
        };
        return itemsReferrer;
  


      default:
    }
  }



