import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form, Row, Col, InputGroup, Tabs, Tab, Badge  } from "react-bootstrap";
import Interweave from 'interweave';
import { Paper, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, CardActions, MenuItem, Select, Card, CardMedia, CardContent, Chip, Typography, makeStyles, Checkbox, useMediaQuery } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { ToastContainer, Flip } from "react-toastify";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Files from "../../../components/Files";
import Tags from '../../../components/Tags';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReferralMaintenance from "../../../components/ReferralMaintenance";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { format } from "date-fns";
import Masonry from 'react-masonry-css';
import Notes from "../../../components/Notes";
// import clsx from 'clsx';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));

const useStylesDatePicker = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      height: 33,
    }
  }
});


export function ClientInvitesPsychologist() {
  return <ClientInvites type="Psychologist" />;
}

export function ClientInvitesReferrer() {
  return <ClientInvites type="Referrer" />;
}

export function ClientInvitesAdmin() {
  return <ClientInvites type="Admin" />;
}


export default function ClientInvites(props) {

  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 760px)');
  const userTZ = useSelector(state => state.auth.userTZ)
  const filters = useSelector(state => state.admin.Filters)
  const displayinvite = useSelector(state => state.admin.DisplayInvite)
  const allcontracts = useSelector(state => state.admin.Contracts)
  const contracts = allcontracts.filter(contract => contract.type === 'Contract')
  const invites = useSelector(state =>  state.admin.ClientInvites);
  const classesDatePicker = useStylesDatePicker();
  const [currentProviderType, setcurrentProviderType] = useState('Psychologist');
  const [referrerAlert, setReferrerAlert] = useState('Please upload Mental Health Care Plan (MHCP) here, or ask your client to ask their GP to fax it to 074573 1100')
  const roleScope = useSelector(state =>  state.auth.roleScope)
  const source = useSelector(state =>  state.admin.Source)
  
  useEffect( () => {
    dispatch(Admin.actions.fetchClientInvites({ startDate: filters.clientInviteStartDate, endDate: filters.clientInviteEndDate })) },
    [dispatch, filters.clientInviteStartDate, filters.clientInviteEndDate] 
  )

  useEffect( () => { dispatch(Admin.actions.fetchSource()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMatching( { providertype: currentProviderType, invite : true, availabilitytype : currentProviderType === 'Supervision' ? 'Supervision' : 'New' })) }, [dispatch, currentProviderType] )
  useEffect( () => { dispatch(Admin.actions.fetchReferrers()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchContracts()) }, [dispatch] )
  
  const initSource = useCallback(() => {
    return { id: 0, firstName: '', surname: '', phone: '', email: '', organisationname: '',description: '', status: 'Active' }
  },[])

  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 

  const [currentid,setcurrentid] = useState(0);
  const [currentFirstName,setcurrentFirstName] = useState();
  const [currentPreferredName,setcurrentPreferredName] = useState();
  const [currentSurname,setcurrentSurname] = useState();
  const [currentEmail,setcurrentEmail] = useState();
  const [currentMobile,setcurrentMobile] = useState();
  const [currentMessage, setcurrentMessage] = useState('');
  const [currentAreasOfInterest, setcurrentAreasOfInterest] = useState([]);
  const [currentKeyAreas,setcurrentKeyAreas] = useState([]);
  const [currentPsychologist, setcurrentPsychologist] = useState('');
  const [currentTimeZone, setcurrentTimeZone] = useState(userTZ ? userTZ : 'AUS Eastern Standard Time');
  const [currentSlot, setcurrentSlot] = useState();
  const [currentInviteToken, setcurrentInviteToken] = useState();
  const [currentPaymentType, setcurrentPaymentType] = useState();
  const [currentAppointmentType, setcurrentAppointmentType] = useState('0');
  const [viewedFilesUpload, setViewedFilesUpload] = useState(false)
  
  const [currentReferrerFirstName,setcurrentReferrerFirstName] = useState();
  const [currentReferrerSurname,setcurrentReferrerSurname] = useState();
  const [currentReferrerMobile,setcurrentReferrerMobile] = useState();
  const [currentReferrerEmail,setcurrentReferrerEmail] = useState();
  const [currentStatus,setcurrentStatus] = useState();
  const [currentContract, setcurrentContract] = useState(null);

  const [currentReferrerName,setcurrentReferrerName]= useState();
  const [currentReferrerOrganisationName,setcurrentReferrerOrganisationName] = useState();
  const [currentReferrerOrgEmail,setcurrentReferrerOrgEmail] = useState();
  const [currentSelectLater,setcurrentSelectLater] = useState('Y');
  const [currentHandOverNotes,setcurrentHandOverNotes] = useState();
  const [currentBulkBilledReason,setcurrentBulkBilledReason] = useState();
  const [showProviderBio,setShowProviderBio] = useState(false)
  const [providerBioFullName,setProviderBioFullName] = useState()
  const [providerBioBio,setProviderBioBio] = useState()
  const [currentReferralGPName,setcurrentReferralGPName] = useState();
  const [currentReferralGPPracticeFax,setcurrentReferralGPPracticeFax] = useState();
  const [currentReferralGPPracticeEmail,setcurrentReferralGPPracticeEmail] = useState();
  const [currentProgram, setcurrentProgram] = useState(0)
  const referrers = useSelector(state =>  state.admin.Referrers)
  const invitedClientswithReferrers = referrers.filter(referrer => referrer.referralcount > 0)

  const areasOfInterest = useSelector(state => state.reference.AreasOfInterest);
  // Setup variables for Psychology and Financial Areas of Interest (note GP's and Provisional Psychologist Invites Do not have areas of interest)
  const FinancialAreasOfInterest = areasOfInterest.filter(area => area.providertype === 'Financial')
  // const ProvisionalAreasOfInterest = areasOfInterest.filter(area => (area.issue === 'Anxiety' || area.issue === 'Depression'  || area.issue === 'Grief & Loss'))
  const aoi_focus = areasOfInterest.filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === 'Psychologist')
  const aoi_other = areasOfInterest.filter(aoi => aoi.focusAreaIndex === null && aoi.providertype === 'Psychologist')
  const aoi_program = areasOfInterest.filter(aoi => aoi.program !== null && aoi.providertype === 'Psychologist')
  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  const psychologists = useSelector(state => state.admin.Matching)
  const [currentSource, setcurrentSource] = useState(initSource())  
  var [currentFacetoFaceBooking,setcurrentFacetoFaceBooking] = useState()
  var [currentSpecialistType,setcurrentSpecialistType] = useState()

  const loadSource = useCallback((thisSource) => {
    setcurrentSource(thisSource ? thisSource : initSource())
  },[initSource])


  const getSourceOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initSource();
    }
  }

  // when changing psychologists, fetch their availability
  const handleMatchingAutocompleteChange = useCallback((newValue) => { 
    if (newValue) {
      setcurrentPsychologist(newValue);
      setcurrentSlot();
      dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: newValue.id, userTZ: currentTimeZone, availabilityType : currentProviderType === 'Supervision' ? 'Supervision' : 'New', invite : true }));
    }
  },[currentTimeZone, currentProviderType, dispatch])

  const loadInvite = useCallback((thisInvite) => {
    thisInvite.psychologistid && dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: thisInvite.psychologistid, userTZ: thisInvite.timezone, availabilityType : currentProviderType === 'Supervision' ? 'Supervision' : 'New', invite : true }));
    setcurrentid(thisInvite.id);
    setcurrentFirstName(thisInvite.firstname)
    setcurrentPreferredName(thisInvite.preferredname)
    setcurrentSurname(thisInvite.surname)
    setcurrentEmail(thisInvite.email)
    setcurrentMobile(thisInvite.phone)
    setcurrentMessage(thisInvite.message)
    var aoi = aoi_focus.filter(( {issue} ) => thisInvite.clientAreasOfInterest.includes(issue))
    setcurrentAreasOfInterest(aoi);
    var keyaoi = aoi_other.filter(( {issue} ) => thisInvite.clientAreasOfInterest.includes(issue))
    setcurrentKeyAreas(keyaoi);
    setcurrentInviteToken(thisInvite.invitetoken);
    setcurrentTimeZone(thisInvite.timezone);
    setcurrentSlot(thisInvite.bookingdate);
    setcurrentReferrerFirstName(thisInvite.referrerfirstname);
    setcurrentReferrerSurname(thisInvite.referrersurname);
    setcurrentReferrerMobile(thisInvite.referrermobile) 
    setcurrentReferrerEmail(thisInvite.referreremail) 
    setcurrentReferrerName(thisInvite.referrername)
    setcurrentReferrerOrganisationName(thisInvite.referrerorganisationname)
    setcurrentReferrerOrgEmail(thisInvite.referrerorgemail)
    setcurrentPaymentType(thisInvite.paymenttype)
    setcurrentBulkBilledReason(thisInvite.bulkbilledreason)
    setcurrentAppointmentType(thisInvite.appointmenttype);
    setcurrentStatus(thisInvite.status);
    setTagValues(thisInvite.tagValues);
    setcurrentHandOverNotes(thisInvite.handOverNotes);
    setcurrentReferralGPName(thisInvite.referringGPName)
    setcurrentReferralGPPracticeFax(thisInvite.referralGPPracticeFax)
    setcurrentReferralGPPracticeEmail(thisInvite.referralGPPracticeEmail)
    loadContract(contracts.find(v => v.id === thisInvite.contractid))
    loadSource(source.find(v => v.id === thisInvite.sourceid))
    setcurrentProgram(thisInvite.programid)
    setcurrentFacetoFaceBooking(thisInvite.facetoFaceBooking)
    setcurrentSpecialistType(thisInvite.specialisttype)
    if (psychologists && props.type === 'Psychologist' && psychologists.length > 0)
    {
      setcurrentProviderType(psychologists[0].providertype)
    }
    else
    {
      setcurrentProviderType(thisInvite.providertype)
    }
    setInviteError('');

    if(props.type === 'Psychologist' && psychologists)
    {
      handleMatchingAutocompleteChange(psychologists[0]);
    }
    else
    {
      if(thisInvite.psychologistid)
      {
        setcurrentPsychologist(psychologists.find(row => row.id === thisInvite.psychologistid))
        setcurrentSelectLater('N')
      }
      else 
      {
        setcurrentPsychologist(null)
        setcurrentSelectLater('Y')
      }
    }
    setShowInvitation(true);
  },[aoi_focus,aoi_other,contracts,dispatch,handleMatchingAutocompleteChange,props.type,psychologists,currentProviderType,source,loadSource])

  const moreProviderBio = (fullName, bio) => {
    // console.log('Read More Clicked')
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)


  }

  useEffect(() => {
    // 
    // Some Commentary about this
    // Essentially when someone creates a new invite, the application creates the new invite then opens the invite modal with that invite displayed
    // To do this a Redux variable - DisplayInvite is set with the id  
    // This DisplayInvite variable is used to display the modal then cleared in another dispatch call
    //
    if (displayinvite && invites.some(invite => invite.id === displayinvite))
    {
      loadInvite(invites.find(invite => invite.id === displayinvite));
      dispatch(Admin.actions.clearDisplayInvite());
      if (props.type === 'Referrer')
      {
        // setReferrerAlert('Please upload Mental Health Care Plan (MHCP) here, or ask your client to ask their GP to fax it to 074573 1100')
        setReferrerAlert('Please upload mental health care plan, or ask your client to do so when they sign up.')

      }
      //
      if(!viewedFilesUpload)
      {
        setKey('files');
        setViewedFilesUpload(true);
      }
      else
      {
        setShowInvitation(false)
      }

    }
  },[setReferrerAlert,invites,displayinvite,props.type,dispatch,loadInvite,viewedFilesUpload]);

  const loadContract = (thisContract) => {
    setcurrentContract(thisContract ? thisContract : null)
  }

  function getProviderType(provider) {
    if(currentProviderType === 'Financial' || currentProviderType === 'GP' || currentProviderType === 'Provisional' || currentProviderType === 'Supervision' || currentProviderType === 'NDIS' || currentProviderType === 'Nurse' || currentProviderType === 'Social' )
    {
      return provider.providertype === currentProviderType;
    }
    else
    {
      return provider.providertype === 'Psychologist' || provider.providertype === 'Clinical' || currentProviderType === 'Occupational'
    }
  }

  const providers = psychologists.filter(provider => getProviderType(provider));  

  const timeZone = useSelector(state => state.reference.TimeZone)
  const matchingAvailability = useSelector(state => state.admin.MatchingAvailability)

  const searchInvites = (invite) => {
    if( true
        && (invite.fullname.toLowerCase().search(filters.invitedClientsTerm.toLowerCase()) > -1 || invite.flagClean.toLowerCase().search(filters.invitedClientsTerm.toLowerCase()) > -1 || filters.invitedClientsTerm.length === 0)
        && (!filters.invitedClientsReferrer || (filters.invitedClientsReferrer && invite.createdby === filters.invitedClientsReferrer.id))
        && (filters.invitedClientProviderType === 'All' || (invite.providertype === filters.invitedClientProviderType))
        && ((filters.invitedClientsStatus === 'All' && invite.status !== 'Archived') || invite.status === filters.invitedClientsStatus)
        && (filters.invitedClientsType === 'All' || (invite.specialisttype === filters.invitedClientsType)) 
      )
    {
      return invite
    }  
  }
  const filteredInvites = invites.filter(invite => searchInvites(invite))


  function setFlag(event, id) {
    dispatch(Admin.actions.putClientInviteFlag({ id: id, flag: event.target.value }));
  }

  function validateEmail(email) 
  {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
  }

  useEffect( () => { 
      if (psychologists && props.type === 'Psychologist' && psychologists.length > 0)
      {
        setcurrentPsychologist(psychologists[0])
        // console.log(psychologists[0])
        setcurrentProviderType(psychologists[0].providertype)
        // console.log('setting psychologist - done')
      }
   }, [props.type, psychologists] )
  
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
  }

  var [showInvitation, setShowInvitation] = useState(false)
  var [showConfirmation, setShowConfirmation] = useState(false)
  var [showProvider, setShowProvider] = useState(false)
  var [inviteError, setInviteError] = useState('')

  function handleInvitationClose() {
    setShowInvitation(false);
  }  

  function handleConfirmationClose() {
    setShowConfirmation(false);
  }  


  function handleProviderClose() {
    setShowProvider(false);
  }  


  const [tagValues, setTagValues] = useState([]);

  const initInvite = () => {
    return { 
      id: 0,
      firstname: '',
      preferredname: '',
      surname: '',
      email: '',
      phone: '',
      message: '',
      clientAreasOfInterest: [],
      psychologistid: undefined,
      timezone: currentTimeZone,
      bookingdate: undefined,
      appointmenttype:'0',
      providertype:'Psychologist',
      invitetoken: uuidv4(),
      status: '',
      referrerfirstname:'',
      referrersurname:'',
      referrermobile:'',
      referreremail:'',
      handOverNotes: '',
      paymenttype:'Reduced Gap',
      programid : 0,
      specialisttype: 'Stamdard',

      tagValues: [] }
  }


  const loadNewInvite = () => {
    loadInvite(initInvite());
    setViewedFilesUpload(false);
    setKey('inviteDetails');
  }

  function saveInviteConfirmation(props)
  {
    // Display confirmation modal
    setShowConfirmation(true);
  }

  function saveInvite(type) {
    // Check if files were meant to be uploaded 
    // console.log('Saving');
    // console.log(currentid);
    if (
          (currentFirstName === '' )
          || (currentMobile === '' )
          || (currentEmail.length > 1 && !validateEmail(currentEmail)) 
          || (currentEmail.length > 1 && currentEmail.includes('mailto'))
          || ((currentReferrerFirstName.length < 5) && currentReferrerSurname.length < 5 && currentReferrerMobile.length !== 10 && currentProviderType === 'Referrer')
          || ((currentPaymentType === 'Contract') && !currentContract)
        )
    { 
      if (currentProviderType === 'Referrer')
      {
        setInviteError('You must provide a client first name, Area of Interest, a valid client mobile phone number, your name and your mobile phone number.')
      }
      else
      {
        setInviteError('You must provide a first name, Area of Interest and a valid mobile phone number (and a valid email address if one has been included).')
      }
      
      if ((currentPaymentType === 'Contract') && !currentContract)
      {
        setInviteError(inviteError + ' Contract has not been selected.')
      }

    } 
    else 
    {
      setInviteError('')
      // Merge AOI and Key AOI into one field 
      let mergedAOI = [];
      if (currentAreasOfInterest.length > 0)
      {
        // Ok currentareasofinterest is populated 
        // Check if currentkeyareas is populated 
        if (currentKeyAreas.length > 0)
        {
          // ok both are populated - the excitement grows
          mergedAOI = [ ...currentAreasOfInterest, ...currentKeyAreas ];
        }
        else
        {
          // keyareas is not populated
          mergedAOI = [ ...currentAreasOfInterest]; 
        }
      }
      else
      {
        // Check if currentkeyareas is populated 
        if (currentKeyAreas.length > 0)
        {
          // ok keyareas is populated though areasofinterest is not thus set variable 
          mergedAOI = [...currentKeyAreas ];
        }
        else
        {
          // nothing is populated let it go through to the keeper as null 
        }
      }
      //
      var payload = {
        id: currentid,
        firstname: currentFirstName,
        preferredname: currentPreferredName,
        surname: currentSurname,
        email: currentEmail,
        phone: currentMobile,
        message: currentMessage,
        clientAreasOfInterest: mergedAOI.map(aoi => aoi.issue).join(","),
        psychologistid: currentPsychologist ? currentPsychologist.id : undefined,
        timezone: currentTimeZone ? currentTimeZone : userTZ,
        bookingdate: currentSlot,
        appointmenttype: currentAppointmentType,
        invitetoken: currentInviteToken,
        providertype: currentProviderType,
        referrerfirstname: currentReferrerFirstName,
        referrersurname: currentReferrerSurname,
        referrermobile: currentReferrerMobile,
        referreremail: currentReferrerEmail,
        contractid : currentContract && currentContract.id,
        handOverNotes: currentHandOverNotes,
        paymenttype: currentSelectLater === 'Y'  ? '' : currentProviderType === 'GP' ? 'Bulk Billed' : currentProviderType === 'Provisional' ? 'Provisional' : currentProviderType === 'Supervision' ? 'Full Fee' : currentPaymentType,
        bulkbilledreason: currentBulkBilledReason,
        status: type === 'Update' ? currentStatus : type,
        tagValues: tagValues,
        source: currentSource,
        programid: currentProgram,
        facetoFaceBooking: currentFacetoFaceBooking,
        specialisttype: currentSpecialistType
      }

      // console.log(payload);
      if (currentid === 0) {
        dispatch(Admin.actions.postClientInvite(payload));
      } else {
        dispatch(Admin.actions.putClientInvite(payload));
      }
      if (type === 'Sent')
      {
        dispatch(Admin.actions.sendClientInvite(payload));
        setShowConfirmation(false);
        setShowInvitation(false)
      }


    }
  }

  const deleteInvite = (id) => {
    dispatch(Admin.actions.deleteClientInvite(id));
  }

  const sendCallUnsuccessfulReminder = (id) => {
    dispatch(Admin.actions.sendCallUnsuccessfulReminder(filteredInvites.find(row => row.id === id)));
  }

  const sendInvite = (id) => {
    dispatch(Admin.actions.sendClientInvite(filteredInvites.find(row => row.id === id)));
  }

  const sendInviteReminder = (id) => {
    dispatch(Admin.actions.sendClientInviteReminder(filteredInvites.find(row => row.id === id)));
  }

  function handleAppointmentTypeChange(value) {
    setcurrentAppointmentType(value);
  }

  function getIndex(value, arr, prop) {
    for(var i = 0; i < arr.length; i++) {
        if(arr[i][prop] === value) {
            return i;
        }
    }
    return -1; // where the value doesn't exist
  }

  const handleToggle = (value) => () => {
    const currentIndex = getIndex(value.id, currentKeyAreas, 'id') //checked.find(r => r.id === value.id);
    const newChecked = [...currentKeyAreas];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setcurrentKeyAreas(newChecked);
  };


  function handleTimeZoneChange(value) {
    currentPsychologist && dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: currentPsychologist.id, userTZ: value, availabilityType : currentProviderType === 'Supervision' ? 'Supervision' : 'New', invite : true }));
    setcurrentTimeZone(value);
    setcurrentSlot();
  }

  function handleProgramChange(value) {
    setcurrentProgram(value);
  }

  function changeSpecialistType(value) {
    dispatch(Admin.actions.fetchMatching( { providertype: value === 'nthqldphn' ? 'NthQLDPHN' : currentProviderType, invite : true, availabilitytype : value === 'Supervision' ? 'Supervision' : 'New' }))
    setcurrentSpecialistType(value)
    // setcurrentAreasOfInterest([]) // wipe the selected AOI as they belong to a different provider type
    // setcurrentSelectLater(value === 'Supervision' ? 'N' : value === 'GP' ? 'N' : value === 'NDIS' ? 'N' : 'Y')
  }


  function changeProviderType(value) {
    dispatch(Admin.actions.fetchMatching( { providertype: value, invite : true, availabilitytype : value === 'Supervision' ? 'Supervision' : 'New' }))
    setcurrentProviderType(value)
    setcurrentAreasOfInterest([]) // wipe the selected AOI as they belong to a different provider type
    setcurrentSelectLater(value === 'Supervision' ? 'N' : value === 'GP' ? 'N' : value === 'NDIS' ? 'N' : 'Y')
  }

  function selectProvider(row) {
    handleMatchingAutocompleteChange(row)
    setShowProvider(false);
  }

  useEffect( () => {
    if(filters.invitesListLoadInviteID)
    {
      if (invites && invites.length > 1)
      {
        // console.log(filters.invitesListLoadInviteID)
        // console.log(invites);
        // console.log(invites.find(invite => invite.id === filters.invitesListLoadInviteID))
        loadInvite(invites.find(invite => invite.id === filters.invitesListLoadInviteID));
        dispatch(Admin.actions.setFilters({ filterName: 'invitesListLoadInviteID', filterValue: undefined }))
      }
    }
  }, [loadInvite, dispatch, filters.invitesListLoadInviteID,invites])


  function renderProviderOption(option) {
    // Check to see if option can be listed based on Payment Type selected 
    // console.log(option);
    // console.log(currentPaymentType);
    return (
      <>
        { (currentPaymentType !== 'Bulk Billed') || (currentPaymentType === 'Bulk Billed' && option.bulkBillingAvailable !== "0") ?
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={6}>{option.fullname}</Grid>
            <Grid item md={6} className='provider-option-available'><span style={{ color: '#ccc' }}>Next available:</span> &nbsp; {option.nextAvailable}</Grid>
          </Grid>
        :
          null
        }
      </>
    )
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true},
    { field: 'fullname', headerName: 'Name', width: 180,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadInvite(invites.find(invite => invite.id === params.row.id))} className="cell-Link">
          {(params.row.contractid || params.row.specialisttype === 'nthqldphn') ?
            <>
              <span style={{ color: params.row.contractcolour }}>{params.value}</span>
            </>
          :
            <>
              {params.value}
            </>
          }              
        </div>
      </strong>
    ),  },
    { field: 'flag', headerName: 'Follow-up', width: 250,
      cellClassName: (params) => params.row.flag && params.row.flag.length > 0 ? 'flag-full-cell' : 'flag-empty-cell',
      renderCell: (params) => (
        <>
        <TextField 
          variant="outlined"
          name={`flag`} 
          defaultValue={params.row.flag} 
          onBlur={(event) => setFlag(event, params.row.id)}
          InputProps={{ classes: { notchedOutline:classes.noBorder, input: params.row.flag && params.row.flag.length > 0 ? 'flag-full' : 'flag-empty' }, }}
          multiline
          maxRows={4}
          style= {{ width: '100%'}}
          onFocus={event => {
            event.target.select();
          }}>
        </TextField>
        </>
      )
    },
    { field: 'email', headerName: 'Email', width: 200,
      renderCell: (params) => (
        <CopyToClipboard text={params.row.email}>
          <span  className="cell-Link" style={{ fontWeight: 500}}>{params.row.email}</span>
        </CopyToClipboard>
    ) },
    { field: 'phone', headerName: 'Mobile', width: 110   },
    { field: 'providertype', headerName: 'Type', width: 120 },
    { field: 'status', headerName: 'Status', width: 110,
      cellClassName: (params) => 
        params.row.status === 'Registered' ? 'registered-status' : (params.row.status === 'Sent' ? 'sent-status' : (params.row.status === 'Reminder' ? 'sent-status' : (params.row.status === 'Called' ? 'sent-status' : (params.row.status === 'Call' ? 'sent-status' : (params.row.status === 'Ready to Archive' ? 'sent-status' : (params.row.status === 'Saved' ? 'saved-status' : (params.row.status === 'Archived' ? 'archived-status' : (params.row.status === 'Hold' ? 'hold-status' : null))))))))
    },    
    { field: 'createdon', type: 'date', headerName: 'Created',  width: 130, valueFormatter: ({ value }) => format(new Date(value), "dd-MM-yyyy HH:mm") },
    { field: 'updatedon', type: 'date', headerName: 'Updated',  width: 130, valueFormatter: ({ value }) => value ? format(new Date(value), "dd-MM-yyyy HH:mm") : null },
    { field: 'referrer', headerName: 'Last Updated By', width: 230,
      cellClassName: (params) => params.row.referrertype && params.row.referrertype === 'R' ? 'referrer-client' : params.row.referrertype && params.row.referrertype === 'P' ? 'private-client' : null  },
    { field: 'none', headerName: 'Actions', width: 270,
      renderCell: (params) => (
        <>
          {(params.row.status === 'Sent' || params.row.status === 'Reminder' || params.row.status === 'Call' || params.row.status === 'Called' || params.row.status === 'Ready to Archive') && props.type !== 'Referrer' && 
            <>
              <a href={`/clientregistration/${params.row.invitetoken}`} target="RHC">
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }} >
                  Test Invite
                </Button>
              </a>
              <Button variant="secondary" size="sm" onClick={()=>sendInviteReminder(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Reminder
              </Button>
              <Button variant="secondary" size="sm" onClick={()=>deleteInvite(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} >
                Delete
              </Button>
            </>
          }
          {(params.row.status === 'Sent' || params.row.status === 'Reminder' || params.row.status === 'Called' || params.row.status === 'Call' || params.row.status === 'Ready to Archive')  && props.type === 'Referrer' && 
            <>
              <a href={`/clientregistration/${params.row.invitetoken}`} target="RHC">
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }} >
                  View Invite
                </Button>
              </a>
            </>
          }
          {((params.row.status === 'Saved' || params.row.status === 'Hold') && params.row.email.length > 5) && 
            <>
              <Button variant="secondary" size="sm" onClick={()=>sendInvite(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Send
              </Button>
            </>
          }
          {(params.row.status === 'Saved' || params.row.status === 'Hold') && props.type === 'Referrer' && 
            <>
              <Button variant="secondary" size="sm" onClick={()=>deleteInvite(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} >
                Delete
              </Button>
            </>
          }
          {(params.row.status === 'Call') && props.type === 'Referrer' && 
            <>
              <Button variant="secondary" size="sm" onClick={()=>sendInviteReminder(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Call Successful
              </Button>
              <Button variant="secondary" size="sm" onClick={()=>sendCallUnsuccessfulReminder(params.row.id)} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} >
                Call Unsuccessful
              </Button>
            </>
          }
        </>
      ) 
    }
  ];
  
  const [key, setKey] = React.useState('inviteDetails');
  const classes = useStyles();

  return (
    <>
      <h4 >Invited Clients</h4>
      {props.type === 'Referrer' ? 
        <Alert severity="info" variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
          <AlertTitle>Please tell your client this info:</AlertTitle>
          <ul>
            <li>
              <p>This link sends an invitation to sign up to the Rural Health Connect Portal as an SMS and Email.</p>
            </li>  
            <li>
              <p>They need to complete the registration form to confirm their appointment.</p>
            </li>
            <li>
              <p>They will then receive a reminder via SMS and email the day before their appointment with a portal link attached that they click on to enter the call.</p>
            </li>
            <li>
              <p>If they have any issues they should call Rural Health Connect via the number on our website.</p>
            </li>
          </ul>  
        </Alert>
      :
        null
      }
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
              <Grid container>
                <Grid item>             
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control                  
                        type="text"
                        value={filters.invitedClientsTerm}
                        onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientsTerm', filterValue: event.target.value }))}
                        autoComplete='OFF'
                        style={{ maxWidth: 170, height: 35, backgroundColor: filters.invitedClientsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                      />
                    <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientsTerm', filterValue: '' }))}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: filters.invitedClientsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item style={{ paddingRight: 10 }}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="dense"
                      id="date-picker-inline"
                      label="Start Date"
                      value={filters.clientInviteStartDate}
                      style={{ marginTop: 0, maxWidth: 140 }}
                      className={classesDatePicker.root}
                      onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'clientInviteStartDate', filterValue: newValue }))}
                      InputLabelProps={{ shrink: true }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item style={{ paddingRight: 10 }}>
                    <KeyboardDatePicker
                      disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="dense"
                      id="date-picker-inline"
                      label="End Date"
                      value={filters.clientInviteEndDate}
                      style={{ marginTop: 0, maxWidth: 140 }}
                      className={classesDatePicker.root}
                      onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'clientInviteEndDate', filterValue: newValue }))}
                      InputLabelProps={{ shrink: true }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.invitedClientProviderType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientProviderType', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="Psychologist">Psychologist</option>
                      <option value="Financial">Financial Counsellor</option>
                      <option value="Clinical">Clinical Psychologist</option>
                      <option value="GP">GP</option>
                      <option value="Provisional">Provisional Psychologist</option>
                      <option value="Social">Social Worker</option>
                      <option value="Nurse">Mental Health Nurse</option>
                      <option value="Occupational">Occupational Therapist</option>
                      <option value="NDIS">NDIS</option>
                      <option value="Supervision">Supervision</option>
                    </Form.Control>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.invitedClientsStatus} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientsStatus', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="Saved">Saved</option>
                      <option value="Hold">On Hold</option>
                      <option value="Sent">Sent</option>
                      <option value="Reminder">Reminder</option>
                      <option value="Registered">Registered</option>
                      <option value="Call">Call</option>
                      <option value="Called">Called</option>
                      <option value="Ready to Archive">Ready to Archive</option>
                      <option value="Archived">Archived</option>
                    </Form.Control>
                </Grid>
                {props.type !== 'Psychologist' && props.type !== 'Referrer' ?
                  <>
                    <Grid item style={{ marginLeft: 10 }}>  
                      <Autocomplete
                        id="searchReferrer"
                        variant="outlined"
                        options={invitedClientswithReferrers}
                        getOptionLabel={(option) => option ? option.fullname : ''}
                        value={filters.invitedClientsReferrer}
                        onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientsReferrer', filterValue: newValue }))}
                        style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            label="Referrer"
                            type="text"
                            variant="outlined"
                            margin="dense"
                            name="ReferrerSelector"
                            style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'}}
                        />}
                      />
                    </Grid>
                  </>
                :
                  null
                }
                <Grid item style={{ marginLeft: 10 }}>  
                    <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.invitedClientsType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invitedClientsType', filterValue: event.target.value }))}>
                      <option value="All">All</option>
                      <option value="nthqldphn">Nth QLD PHN</option>
                    </Form.Control>
                </Grid>
                {props.type === 'Referrer' ? 
                  <>
                    <Grid item style={{ marginLeft: 10 }}>
                      <a href={`https://files.ruralhealthconnect.com.au/fileStoreRHC/RHCReferralGuidelines.pdf`} target="_Referrers">
                        <Button variant="secondary" size="sm" >
                          Review Invite Guidelines
                        </Button>
                      </a>
                    </Grid>
                  </>
                :
                  null
                }
              </Grid>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadNewInvite()}>
              Add Invite
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid rows={filteredInvites} columns={columns} pageSize={15} rowHeight={38} rowsPerPageOptions={[15]} checkboxSelection={false} sortModel={[{ field: 'createdon', sort: 'desc' }]}  />
      </Paper>
    
      <Modal show={showInvitation} onHide={handleInvitationClose} dialogClassName="invite-modal">
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title style={{ width: 400 }}>
            <Grid container>
              <Grid item md={5} style={{ paddingTop: 6 }}>
                Invite New Client:
              </Grid>
              <Grid item md={7}>
                {props.type !== 'Psychologist' ? 
                  <Form.Control as="select" value={currentProviderType} onChange={(event) => changeProviderType(event.target.value)} style={{ width: 230, backgroundColor: '#fffed0' }}>
                    {roleScope !== 'Provisional' ? <option value="Psychologist">Psychologist</option> : null }
                    {roleScope !== 'Provisional' ? <option value="GP" >GP</option>  : null }
                    <option value="Provisional">Provisional Psychologist</option>
                    {roleScope !== 'Provisional' ? <option value="NDIS" >NDIS</option>  : null }
                    {roleScope !== 'Provisional' ? <option value="Social">Social Worker</option>  : null }
                    {roleScope !== 'Provisional' ? <option value="Nurse">Mental Health Nurse</option>  : null }
                    {roleScope !== 'Provisional' ? <option value="Occupational">Occupational Therapist</option>  : null }
                    {roleScope !== 'Provisional' ? <option value="Clinical">Clinical Psychologist</option>  : null }
                    {roleScope !== 'Provisional' ? <option value="Supervision">Supervision</option>  : null }

                  </Form.Control>
                :
                  null
                }
              </Grid>
            </Grid>  
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal">
          {inviteError !== '' ?
          <Alert severity="error" style={{ marginBottom: 14 }}>
            {inviteError}
          </Alert>
          :
          null }
          <Tabs
            id="ClientInvite"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="inviteDetails" title="Invite Details" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>     
              {(props.type === 'Referrer' && (currentStatus === 'Saved' || currentStatus === '' || currentStatus === 'Hold')) || (props.type !== 'Referrer') ?
                <Row>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" name="profileFirstName" value={currentFirstName} onChange={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Preferred Name</Form.Label>
                      <Form.Control type="text" name="profilePreferredName" value={currentPreferredName} onChange={(event) => setcurrentPreferredName(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group >
                      <Form.Label>Surname</Form.Label>
                      <Form.Control type="text"  name="profileSurname" value={currentSurname} onChange={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Mobile Phone</Form.Label>
                      <Form.Control type="text" name="profileMobile" value={currentMobile} onChange={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" name="profileEmail" value={currentEmail} onChange={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row>
              :
                null
              }  
              { (currentProviderType !== 'Provisional'  && currentProviderType !== 'Supervision') ?
                <Row>
                  { (currentProviderType !== 'GP' && currentProviderType !== 'Social' && currentProviderType !== 'Occupational' && currentProviderType !== 'Nurse' ) ?
                    <Col md="6">
                      <Form.Group >
                        <Form.Label>Is this invitation part of a program (please select)</Form.Label><br />
                        <Select
                          variant="outlined"
                          margin="dense"
                          value={currentProgram}
                          onChange={(event) => handleProgramChange(event.target.value)}
                          className="select-edit"
                          style={{ minWidth: 400 }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {aoi_program && aoi_program.map((row, index) => (
                            <MenuItem key={`program_${index}`} value={row.program}>{row.programName} (Area of Interest: {row.issue})</MenuItem>
                          ))}
                          <MenuItem value={0}></MenuItem>            
                        </Select>
                      </Form.Group>
                    </Col>
                  :  
                    null
                  }
                  <Col md="6">
                    <Form.Group >
                      <Form.Label>Specialist Type</Form.Label>
                      <Form.Control as="select" defaultValue={currentSpecialistType} onChange={(event) => changeSpecialistType(event.target.value)}>
                        <option value="Standard">Standard</option>
                        <option value="nthqldphn">Nth QLD PHN</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              :
                null
              }
              { currentSpecialistType === 'nthqldphn' ? 
                <Row>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Client requires Face to Face booking</Form.Label>
                      <Form.Control as="select" defaultValue={currentFacetoFaceBooking} onBlur={(event) => setcurrentFacetoFaceBooking(event.target.value)}>
                        <option value="N">No</option>
                        <option value="Y">Yes</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              :
                null
              }
              { (currentProviderType !== 'GP' && currentProviderType !== 'Provisional' && currentProviderType !== 'Social' && currentProviderType !== 'Nurse' && currentProviderType !== 'Occupational' && currentProviderType !== 'Supervision' && currentProgram === 0) ?
                <>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Please choose up to four areas of interest your client is wanting to address (or leave blank and they can select)</Form.Label>
                        <Autocomplete
                          id="aoi"
                          variant="outlined"
                          multiple
                          options={(currentProviderType === 'Financial') ? FinancialAreasOfInterest : aoi_focus }
                          getOptionLabel={(option) => option.issue }
                          value={currentAreasOfInterest}
                          onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="AOISelector"
                              style={{backgroundColor: "", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Rural Health Connect is working on projects targeting these key areas of need. Please tick all that apply</Form.Label>
                        <Row>
                          <Col md="6">
                            <List className={classes.root}>
                              {((currentProviderType === 'Financial') ? FinancialAreasOfInterest : aoi_other).map(row => {
                                if(row.index <= aoi_other.length/2)
                                {
                                  const labelId = `checkbox-list-label-${row.id}`;
                                  var isChecked = currentKeyAreas.find(r => r.id === row.id);
                                  return (
                                    <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked !== undefined}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })
                              }
                            </List>
                          </Col>
                          <Col md="6">
                            <List className={classes.root}>
                              {((currentProviderType === 'Financial') ? FinancialAreasOfInterest : aoi_other).map(row => {
                                if(row.index > aoi_other.length/2)
                                {
                                  const labelId = `checkbox-list-label-${row.id}`;
                                  var isChecked = currentKeyAreas.find(r => r.id === row.id);
                                  return (
                                    <ListItem key={row.id} role={undefined} dense button onClick={handleToggle(row)}>
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked !== undefined}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })
                              }
                            </List>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              :
                null 

              }
              <Row>
                <Col md="12">
                  <Form.Group>
                    <Form.Label>Referral Hand Over Notes</Form.Label>
                    <Form.Control as="textarea" rows={3} name="handOverNotes" value={currentHandOverNotes} onChange={(event) => setcurrentHandOverNotes(event.target.value)} autoComplete='new-password' />
                  </Form.Group>
                </Col>
              </Row> 
              { ( currentProviderType !== 'NDIS' && currentProviderType !== 'GP' && currentProviderType !== 'Supervision') ?              
                <Row>
                  <Col md="6">
                    <Form.Group>
                        <Form.Label style={{ fontWeight: 500 }}>Invite Selection</Form.Label>
                        <Form.Check id="SelectLater" name="selectlater" type="radio" style={{ marginBottom: 8 }} >
                          <Form.Check.Input name="SelectLater" type="radio" checked={currentSelectLater === 'Y' ? true: false} onChange={e => setcurrentSelectLater('Y')} />
                          <Form.Check.Label style={{marginLeft: 12 }}>Client will choose psychologist and payment option</Form.Check.Label>
                        </Form.Check>
                        <Form.Check id="NotSelectLater" name="notselectlater" type="radio" style={{ marginBottom: 8 }} >
                          <Form.Check.Input name="SelectLater" type="radio" checked={currentSelectLater === 'N' ? true: false} onChange={e => setcurrentSelectLater('N')} />
                          <Form.Check.Label style={{marginLeft: 12 }}>I want to select the psychologist and payment option below</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                  </Col>
                </Row>
              :
                null
              }
              {currentSelectLater !== 'Y' || currentProviderType === 'GP' || currentProviderType === 'Supervision' ?
                <>
                  <hr></hr>
                  { (currentProviderType !== 'Financial' && currentProviderType !== 'Provisional' && currentProviderType !== 'GP' && currentProviderType !== 'NDIS' && currentProviderType !== 'Supervision') ?              
                    <Row>
                      <Col md="12">
                        <fieldset disabled={currentSelectLater === 'Y' ? true : false}>
                        <Form.Group>
                            <Form.Label style={{ fontWeight: 500 }}>Payment Options</Form.Label>
                            <p>We want everyone be able to access a psychologist and therefore have a range of pricing options. Reduced fee places are limited so please only apply if it suits your circumstances:</p>
                            <Form.Check id="payment-fullfee" name="paymenttype" type="radio" style={{ marginBottom: 8 }}>
                              <Form.Check.Input name="paymenttype" type="radio" value="Full Fee"  checked={currentPaymentType === 'Full Fee' ? true : false} onChange={e => { setcurrentPaymentType('Full Fee')}} />
                              <Form.Check.Label style={{marginLeft: 12 }}>STANDARD FEE: I am employed and/or am not experiencing financial hardship </Form.Check.Label>
                            </Form.Check>
                            <Form.Check id="payment-reducedgap" name="paymenttype" type="radio" style={{ marginBottom: 8 }} >
                              <Form.Check.Input name="paymenttype" type="radio" value="Reduced Gap" checked={currentPaymentType === 'Reduced Gap' ? true : false} onChange={e => { setcurrentPaymentType('Reduced Gap')}} />
                              <Form.Check.Label style={{marginLeft: 12 }}>REDUCED FEE: I am experiencing financial hardship and would like to apply for the psychologist's the reduced gap fee option. </Form.Check.Label>
                            </Form.Check>
                            {currentPaymentType === 'Reduced Gap' ?
                              <>
                              <Row>
                                  <Col md="12">
                                    <Form.Group >
                                      <Form.Label>Please tell us about your circumstances in a few sentences</Form.Label>
                                      <Form.Control as="textarea" rows={4} type="text" name="bulkBilledReason" value={currentBulkBilledReason} onChange={(event) => setcurrentBulkBilledReason(event.target.value)} autoComplete='new-password' />
                                    </Form.Group>
                                  </Col>
                                </Row>
                            </>
                            :
                              null
                            }
                            {props.type === 'Admin' ?
                              <Form.Check id="payment-contract" name="paymenttype" type="radio" style={{ marginBottom: 8 }} >
                                <Form.Check.Input name="paymenttype" type="radio" value="Contract" checked={currentPaymentType === 'Contract' ? true : false} onChange={e => {  setcurrentPaymentType('Contract')}} />
                                <Form.Check.Label style={{marginLeft: 12 }}>Contract Sessions (Select Contract Selection Below)</Form.Check.Label>
                              </Form.Check>
                            :
                              null
                            }
                            <Form.Check id="payment-bulkbilled" name="paymenttype" type="radio" style={{ marginTop: 20, marginBottom: 8 }} >
                              <Form.Check.Input name="paymenttype" type="radio" value="Bulk Billed"  checked={currentPaymentType === 'Bulk Billed' ? true : false} onChange={e => { setcurrentPaymentType('Bulk Billed')}} />
                              <Form.Check.Label style={{marginLeft: 12 }}>​BULK BILL(limited sessions available) We have some bulk billed sessions available for those most in need. For Healthcare, Pension or Disability card holders only. </Form.Check.Label>
                            </Form.Check>                      
                        </Form.Group>
                        </fieldset>
                      </Col>
                    </Row>               
                  :
                    null
                  }  
                  
                  {props.type === 'Admin' && currentPaymentType === 'Contract' && currentProviderType !== 'Supervision' ?
                    <Row>
                      <Col md="9">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>Contract Selection </Form.Label>  
                            <Autocomplete
                              id="currentContractID"
                              variant="outlined"
                              options={contracts}
                              getOptionLabel={(option) => option.displayname}
                              // getOptionSelected={(option, value) => getContractOption(option, value)}
                              value= {currentContract}
                              onChange={(event, newValue) => loadContract(newValue)}
                              style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="currentContactID"
                                  style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 600  }}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }
                  <Row>
                    {!(props.type === 'Psychologist' && psychologists && currentPsychologist) && (currentProviderType !== 'NDIS') ?
                      <>
                        <Col md="9">
                          <Form.Group >
                            <Form.Label>Provider (type to search)</Form.Label>
                            <Autocomplete
                              id="psychologist"
                              variant="outlined"
                              options={providers}
                              getOptionLabel={(option) => `${option.fullname}  (Next available: ${option.nextAvailable})`  }
                              renderOption={(option) => renderProviderOption(option)}
                              disabled={currentSelectLater === 'Y' ? true : false}
                              value={currentPsychologist}
                              onChange={(event, newValue) => handleMatchingAutocompleteChange(newValue)}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="PsySelector"
                                  style={{backgroundColor: "", margin: 0, padding: 0}}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                          </Form.Group>
                        </Col>  
                        <Col md="2">
                          <Button variant="primary" size="sm" onClick={() => setShowProvider(true)} style={{ marginTop: 28 }} disabled={currentSelectLater === 'Y' ? true : false}>
                            Search
                          </Button>
                        </Col>
                      </>
                    : null}
                  </Row>
                  {(currentProviderType !== 'NDIS') ?
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Booking Date and Time</Form.Label> 
                          <Select
                            variant="outlined"
                            margin="dense"
                            disabled={currentSelectLater === 'Y' ? true : false}
                            value={currentSlot ? currentSlot : ''}
                            onChange={(event)=>setcurrentSlot(event.target.value)}
                            className="select-edit"
                            style={{ minWidth: 200 }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            {currentPsychologist && matchingAvailability.map((row, index) => (
                              <MenuItem key={`key-${index}`} value={row.fromDate}>{format(new Date(row.fromDate), "dd/MM/yyyy h:mm aaaa")}</MenuItem>
                            ))}
                            <MenuItem value={undefined}></MenuItem>            
                          </Select>
                        </Form.Group>
                      </Col>
                    </Row>                    
                  :
                    null
                  }
                </>
              :
                null
              }
              <hr></hr>
              <Row>
                <Col md="6">
                  <Form.Group >
                    <Form.Label>Timezone</Form.Label><br />
                    <Select
                      variant="outlined"
                      margin="dense"
                      value={currentTimeZone}
                      onChange={(event) => handleTimeZoneChange(event.target.value)}
                      className="select-edit"
                      style={{ minWidth: 200 }}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {timeZone && timeZone.map((row, index) => (
                        <MenuItem key={`tz_${index}`} value={row.systemName}>{row.name}</MenuItem>
                      ))}
                      <MenuItem value={undefined}></MenuItem>            
                    </Select>
                  </Form.Group>
                </Col>
                {(currentProviderType !== 'GP' && currentProviderType !== 'NDIS') ?
                  <Col md="3">
                    <Form.Group >

                      <Form.Label>Appointment Type</Form.Label>
                      <Select 
                        variant="outlined"
                        margin="dense"
                        id='appointmentType'
                        aria-label='Select Appointment Type'
                        name='appointmentType'
                        value={currentAppointmentType} 
                        onChange={(event) => handleAppointmentTypeChange(event.target.value)}
                        style={{ minWidth: 200, backgroundColor: "#ffffff" }} >
                        <MenuItem key='Video' value='0'>Video Call</MenuItem>
                        <MenuItem key='Phone' value='1'>Phone Call</MenuItem>
                      </Select>
                    </Form.Group>
                  </Col>
                :
                  null
                }
                {currentStatus !== '' && props.type !== 'Referrer' ?
                  <>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setcurrentStatus(event.target.value)}>
                          <option value="Saved">Saved</option>
                          <option value="Hold">On Hold</option>
                          <option value="Sent" disabled>Sent</option>
                          <option value="Reminder" disabled>Reminder</option>
                          <option value="Registered" disabled>Registered</option>
                          <option value="Call" disabled>Call</option>
                          <option value="Called" disabled>Called</option>
                          <option value="Ready to Archive" disabled>Ready to Archive</option>
                          <option value="Archived">Archived</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </>
                :
                  null
                }
              </Row>
              {props.type === 'Referrer' || props.type === 'Admin' ? 
                <>
                  <h5>Referrer Details</h5>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        {props.type === 'Admin' ?
                          <Form.Label>Referrer First Name</Form.Label>
                        :
                          <Form.Label>Your First Name</Form.Label>
                        }
                        <Form.Control type="text" name="profileReferrerFirstName" value={currentReferrerFirstName} onChange={(event) => setcurrentReferrerFirstName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        {props.type === 'Admin' ?
                          <Form.Label>Referrer Surname</Form.Label>
                        :
                          <Form.Label>Your Surname</Form.Label>
                        }
                        <Form.Control type="text"  name="profileReferrerSurname" value={currentReferrerSurname} onChange={(event) => setcurrentReferrerSurname(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        {props.type === 'Admin' ?
                          <Form.Label>Referrer Mobile Phone</Form.Label>
                        :
                          <Form.Label>Your Mobile Phone</Form.Label>
                        }
                        <Form.Control type="text" name="profileReferrerMobile" value={currentReferrerMobile} onChange={(event) => setcurrentReferrerMobile(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        {props.type === 'Admin' ?
                          <Form.Label>Referrer Email Address</Form.Label>
                        :
                          <Form.Label>Your Email Address</Form.Label>
                        }
                        <Form.Control type="text" name="profileReferrerEmail" value={currentReferrerEmail} onChange={(event) => setcurrentReferrerEmail(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row>
                  {props.type === 'Admin' && currentStatus!=='' ? 
                    <>
                      <h5>Referrer Account Details</h5>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Name </Form.Label>
                            <Form.Control type="text" name="referrername" value={currentReferrerName} readOnly />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Email </Form.Label>
                            <Form.Control type="text" name="referreremail" value={currentReferrerOrgEmail} readOnly />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Organisation</Form.Label>
                            <Form.Control type="text" name="referrerorganisationname" value={currentReferrerOrganisationName} readOnly />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {props.type === 'Admin' ?
                    <>
                      <h5>Source Referrer</h5>
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label style={{ color: 'green', fontWeight: 500 }}>Select Referrer (or enter a new one below)</Form.Label>  
                        <Autocomplete
                          id="sourceReferrerLookUp"
                          variant="outlined"
                          options={source}
                          getOptionLabel={(option) => option.description }
                          getOptionSelected={(option, value) => getSourceOption(option, value)}
                          value={currentSource}
                          onChange={(event, newValue) => loadSource(newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="SourceSelector"
                              style={{backgroundColor: "", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                      <hr></hr>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                          <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" name="currentSourceFirstname" value={currentSource ? currentSource.firstname : ''} onChange={(event) => setcurrentSource({...currentSource, firstname: event.target.value})} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                          <Form.Label>Surname</Form.Label>
                            <Form.Control type="text" name="currentSourceSurname" value={currentSource ? currentSource.surname : ''} onChange={(event) => setcurrentSource({...currentSource, surname: event.target.value})} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Organisation</Form.Label>
                            <Form.Control type="text" name="currentSourceOrganisationName" value={currentSource ? currentSource.organisationname : ''} onChange={(event) => setcurrentSource({...currentSource, organisationname: event.target.value})} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" name="currentSourcePhone" value={currentSource ? currentSource.phone : ''} onChange={(event) => setcurrentSource({...currentSource, phone: event.target.value})} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="currentSourceEmail" value={currentSource ? currentSource.email : ''} onChange={(event) => setcurrentSource({...currentSource, email: event.target.value})} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row> 
                    </>
                  :
                    null
                  }
                </>
              :
                null
              }
            </Tab>
            <Tab eventKey="files" title="Files" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
              {currentid ? 
                <>
                  {(props.type === 'Referrer' && (currentStatus === 'Saved' || currentStatus === 'Hold' || currentStatus === '' || currentStatus === 'Sent' || currentStatus === 'Reminder' || currentStatus === 'Called' || currentStatus === 'Call' || currentStatus === 'Ready to Archive')) || (props.type !== 'Referrer') ?
                    <>
                      {props.type === 'Referrer' ?
                          <Alert severity="info" variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
                            <AlertTitle>{referrerAlert}</AlertTitle>
                          </Alert>
                      :
                        null
                      }
                      <Files type="ClientRegistration" pkid={currentid} vertical={true} inviteStatus={currentStatus}/>
                    </>
                  :
                    null
                  }
                </> 
              : 
                <>
                  After you have saved this invite to your client please upload MHCP and other relevant documents here.
                </>
              }              
            </Tab>
            <Tab eventKey="notes" title="Notes" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
              {currentid ? 
                  <>
                    {(props.type === 'Referrer' && (currentStatus === 'Saved' || currentStatus === 'Hold' || currentStatus === '' || currentStatus === 'Sent' || currentStatus === 'Call' || currentStatus === 'Called' || currentStatus === 'Reminder' || currentStatus === 'Ready to Archive')) || (props.type !== 'Referrer') ?
                      <>
                        <Notes type="ClientRegistration" 
                          pkid={currentid} 
                          firstName={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName} 
                          preferredName={currentPreferredName}
                          surname={currentSurname}
                          referrerEmail={currentReferrerEmail} 
                          faxGP={currentReferralGPPracticeFax}
                          gpEmail={currentReferralGPPracticeEmail}
                          gpName={currentReferralGPName}
                          />
                      </>
                    :
                      null
                    }
                  </> 
                : 
                  <>
                    After you have saved this invite you can make notes etc. here.
                  </>
                }              
            </Tab>

            <Tab eventKey="tags" title="Tags" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
              <Row>
                <Col md="8">
                  <Tags args={{ 
                    context: 'Client_Registration', 
                    tagValues: tagValues, 
                    setTagValues: setTagValues, 
                    display: { layout: 'form', box: true },
                    readOnly: false }} />
                </Col>
              </Row>              
            </Tab>
            <Tab eventKey="referral" title="Referral" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
              {(currentid && props.type === 'Admin') ? 
                <ReferralMaintenance 
                    viewerFullName={`${currentFirstName} ${currentSurname}`}
                    clientRegistrationID={currentid}
                    clientiD={null}
                    type={'clientregistration'}
                  />            
              :
                null
              }
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleInvitationClose}>
            Close
          </Button>
          {(props.type === 'Referrer' && (currentStatus === 'Saved' || currentStatus === 'Hold' || currentStatus === '')) || (props.type !== 'Referrer') ?
            <>
              {!viewedFilesUpload ?
                <>
                  {currentStatus !== '' && currentStatus !== 'Saved' ?
                    <>
                      <Button variant="primary" onClick={() => saveInvite('Update')}>
                          Next &gt;
                      </Button>
                    </>
                  :
                    <>
                      <Button variant="primary" onClick={() => saveInvite('Saved')} style={{ color: "black", fontWeight: 500, background: "#FFFACD" }}>
                          Save &amp; Next &gt;
                      </Button>
                      <Button variant="primary" onClick={() => saveInvite('Sent')} >
                          Send &amp; Next &gt;
                      </Button>
                    </>
                  }
                </>
              : 
                null
              }
              {viewedFilesUpload ?
                <>
                  {currentStatus !== '' && currentStatus !== 'Saved' ?
                    <>
                      <Button variant="success" onClick={() => saveInvite('Update')}>
                        Update
                      </Button>
                    </>
                  :
                    <>
                      {props.type === 'Referrer' ?
                        <Button variant="success" onClick={() => saveInviteConfirmation('Sent')}>
                          Send
                        </Button>
                      :
                        <Button variant="success" onClick={() => saveInvite('Sent')}>
                          Send
                        </Button>
                      }
                    </>
                  }  
                </>  
              : 
                null
              }
            </>
          :
            null
          }
        </Modal.Footer>
      </Modal>

      <Modal show={showProvider} onHide={handleProviderClose} dialogClassName="invite-modal">
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>Available Providers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal">
          <Grid item md={12} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
            {currentProviderType !== 'Supervision'  ?
              <Masonry
                breakpointCols={isMobile ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {providers && providers.map((row, index) => {
                  return (
                    <Card className={classes.root} variant="outlined" key={index}>
                      <CardMedia
                        className={classes.media}
                        image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                        title="Psychologist"
                      />
                      <CardContent>
                        <Grid container justifyContent="space-between">
                          <Grid item sm={12}>
                            <Typography variant="h5" component="h3">
                              {row.fullname}  (Next available: {row.nextAvailable})
                            </Typography>
                          </Grid>
                        </Grid>
                        {(currentProviderType !== 'GP' && currentProviderType !== 'Provisional' && currentProviderType !== 'Social' && currentProviderType !== 'Occupational' && currentProviderType !== 'Nurse' ) ?
                          <>
                            {currentPaymentType === 'Full Fee' ?
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {row.hourlyRateOverrideGap !== '0' ? row.hourlyRateOverrideGap : row.standardFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                      You pay {row.hourlyrate} on the day
                                    </Typography>
                                    <Typography>
                                      Receive back {row.hourlyRateOverrideRebate !== '0'  ? row.hourlyRateOverrideRebate : row.medicareRebate} from Medicare)
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                              :
                              null
                            }
                            {currentPaymentType === 'Reduced Gap' ?
                              <>
                                {row.isReducedFee === 'Y' ? 
                                  <>
                                    <Grid container style={{ marginTop: 10 }}>
                                      <Grid item sm={6} >
                                        <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                          Out of Pocket Cost
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={6} style={{ textAlign: "right" }}>
                                        <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                          {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={12}>
                                        { row.reducedFee === '0' ?
                                          <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                            You pay $0 on the day
                                          </Typography>
                                        :
                                          <>
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay {row.reducedFee} on the day
                                            </Typography>
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                            </Typography>
                                          </>
                                        }
                                      </Grid>
                                      { row.reducedFee !== '0' ?
                                        <Grid item sm={12}>
                                          <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                            Note this provider offers some flexibility, depending on client circumstances
                                          </Typography>
                                        </Grid>
                                      :
                                        null
                                      }
                                    </Grid>
                                  </>
                                :
                                  <>
                                    <Grid container style={{ marginTop: 10 }}>
                                      <Grid item sm={6} >
                                        <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                          Out of Pocket Cost
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={6} style={{ textAlign: "right" }}>
                                        <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                          {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                        </Typography>
                                      </Grid>
                                      <Grid item sm={12} >
                                        { row.reducedFee === '0' ?
                                          <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                            You pay $0 on the day
                                          </Typography>
                                        :
                                          <>
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay {row.reducedFee} on the day
                                            </Typography>
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                            </Typography>
                                          </>
                                        }
                                      </Grid>
                                    </Grid>
                                  </>
                                }
                              </>
                            :
                              null
                           }
                            <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                              {row.areasOfInterest.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                            </Grid>
                          </>
                        :
                          null
                        }
                        <Typography className={classes.pos} color="textSecondary">
                          <br />
                        </Typography>
                        <Grid container>
                          <Typography variant="body2" component="p">
                            <Interweave content={row.bio.substring(0,700)} />
                            {row.bio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </Grid>
                        <br />
                        {(currentPaymentType === 'Bulk Billed' && row.bulkBillingAvailable === '0') ?
                          <Button variant="secondary">
                            Not Available to Bulk Bill
                          </Button>
                        :
                          <Button onClick={()=>selectProvider(row)}>Select</Button>
                        }
                      </CardContent>
                      {/*  This hides the AOI requiring a click to view them, Megan now wants AOI above the bio - hence it has been commented out                    
                      <CardActions disableSpacing>
                        <IconButton
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                          })}
                          onClick={() => handleExpandClick(row.id)}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </CardActions>
                      <Collapse in={(expanded === row.id)} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography variant="body2" component="div">        
                            {row.areasOfInterest.map((row, index) => {return (<Chip size="small" variant="outlined" key={index} label={row} style={{ margin: 2 }} />)})}
                          </Typography>
                        </CardContent>
                      </Collapse> */}
                    </Card>
                    )
                  })
                }
              </Masonry>
            :
              <Masonry
                breakpointCols={isMobile ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {providers && providers.map((row, index) => {
                  return (
                    <>
                      <Card className={classes.root} variant="outlined" key={index}>
                        <CardMedia
                          className={classes.media}
                          image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                          title="Psychologist"
                        />
                        <CardContent>
                          <Grid container justifyContent="space-between">
                            <Grid item sm={12}>
                              <Typography variant="h5" component="h3">
                                {row.fullname}  (Next available: {row.nextAvailable})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: 10 }}>
                            <Grid item sm={8} >
                              <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                Cost
                              </Typography>
                            </Grid>
                            <Grid item sm={4} style={{ textAlign: "right" }}>
                              <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                {row.supervisorHourlyRate}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography className={classes.pos} color="textSecondary">
                            <br />
                          </Typography>
                          <Grid container>
                            <Typography variant="body2" component="p">
                              <Interweave content={row.supervisorBio.substring(0,700)} />
                              {row.supervisorBio.length > 700 ?
                                <>
                                  ...
                                  <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                    more
                                  </Badge>
                                </>
                              :
                                null
                              }
                            </Typography>
                          </Grid>
                          <br />
                        </CardContent>
                        <CardActions disableSpacing>
                          <Button onClick={()=>selectProvider(row)}>Select</Button>
                        </CardActions>
                          <CardContent>
                            <Typography variant="h6" component="h3">
                              Available For
                            </Typography>
                            <Row>
                              <List className={classes.root}>
                                {supervisionAreas.map(sRow => {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = row.supervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                    );
                                })}
                              </List>
                            </Row>
                            <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                              Endorsed In 
                            </Typography>
                            <Row>
                              <Col md="6">
                                <List className={classes.root}>
                                  {endorsedSupervisionAreas.map(sRow => {
                                    var isChecked = row.supervisorAreas.includes(sRow.id);
                                    const labelId = `checkbox-list-label-${sRow.id}`;
                                    if (isChecked) {
                                      return(
                                        <ListItem key={sRow.id} role={undefined} dense button >
                                          <ListItemIcon style={{ minWidth: 40 }}>
                                            <Checkbox
                                              edge="start"
                                              checked={isChecked}
                                              tabIndex={-1}
                                              disableRipple
                                              inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                              disableTypography 
                                              primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                              className={classes.link} />
                                        </ListItem>
                                      )
                                    }
                                    else
                                    {
                                      return null
                                    }
                                  })}
                                </List>
                              </Col>
                            </Row>
                          </CardContent>
                      </Card>
                    </>
                  )
                })}
              </Masonry>
            }
            </Grid>

        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleProviderClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={handleConfirmationClose} dialogClassName="invite-modal-dialog-confirmation">
        <Modal.Header closeButton className="invite-modal-confirmation">
          <Modal.Title>MHCP Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal-confirmation">
          <Grid item md={12}>
            <p style={{ fontWeight: 600 }}>If you have the clients' MHCP have you uploaded it?</p>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal-confirmation">
          <Button variant="secondary" onClick={handleConfirmationClose}>
            &lt; Back
          </Button>
          <Button variant="success" onClick={() => saveInvite('Sent')}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{border: "solid rgb(215, 215, 215) 1px", borderRadius: "4px",  backgroundColor: "white"}}>
        
        <ToastContainer
          position="bottom-right"
          transition={Flip}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

