import React, { useState, useEffect } from "react";  
import { useSelector, useDispatch } from 'react-redux';
import { FormElement, FieldWrapper, Field } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { TextArea } from '@progress/kendo-react-inputs';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { SchedulerFormEditor } from "@progress/kendo-react-scheduler";
import { TitleEditor, TitleLabel, CustomEndEditor, StartTimezoneCheckedLabel, StartTimezoneCheckedEditor, DescriptionLabel, DescriptionEditor, ClientEditor, StatusEditor, BookingTypeEditor, EventTypeEditor, PaymentTypeEditor } from './editors';
import { TextField, MenuItem, Grid, Select } from '@material-ui/core';
import { Button, Modal, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Admin from "../../store/ducks/admin.duck";
import PlacesAutoComplete from "../AddressAutoComplete";
import { format } from "date-fns";
import Notes from "../Notes";
import Files from "../Files";
import BookingBillingList from "../BookingBillingList";

export const CustomFormEditor = props => {
  
  const dispatch = useDispatch();
  const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
  const medicalPractitioners = useSelector(state =>  state.admin.MedicalPractitioners);
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.GeneralPractitioner);
  const timeZone = useSelector(state =>  state.reference.TimeZone);
  const areasOfInterest = useSelector(state =>  state.reference.AreasOfInterest);
  const client = useSelector(state => state.admin.Client);
  const role = useSelector(state =>  state.auth.roleDesc)
  
  useEffect( () => { props.valueGetter('clientID') && dispatch(Admin.actions.fetchClient( { clientID: props.valueGetter('clientID'), type: props.valueGetter('clienttype') } )) }, [dispatch, props] )
  
  var [currentClientID, setcurrentClientID] = useState(); 
  var [currentMedicalPractitionerID, setcurrentMedicalPractitionerID] = useState();
  var [currentPaymentType, setcurrentPaymentType] = useState();
  var [currentTimeZone, setcurrentTimeZone] = useState();
  var [currentfulladdress, setcurrentfulladdress] = useState();
  var [currentLatitude, setcurrentLatitude] = useState();
  var [currentLongitude, setcurrentLongitude] = useState();
  var [currentID, setcurrentID] = useState();
  var [currentPostCode, setcurrentPostCode] = useState();
  var [currentStatus, setcurrentStatus] = useState(); 
  var [currentFirstName, setcurrentFirstName] = useState();
  var [currentSurname, setcurrentSurname] = useState();
  var [currentDOB, setcurrentDOB] = useState();
  var [currentEmail, setcurrentEmail] = useState();
  var [currentGender, setcurrentGender] = useState();
  var [currentOccupation, setcurrentOccupation] = useState();
  var [currentMobile, setcurrentMobile] = useState();
  var [currentNote, setcurrentNote] = useState();
  var [currenthopeToGetFromTreatment, setcurrenthopeToGetFromTreatment] = useState();
  var [currentEmergencyContactName1, setcurrentEmergencyContactName1] = useState();
  var [currentEmergencyContactPhone1, setcurrentEmergencyContactPhone1] = useState();
  var [currentEmergencyContactRelation1, setcurrentEmergencyContactRelation1] = useState();
  var [currentEmergencyContactName2, setcurrentEmergencyContactName2] = useState();
  var [currentEmergencyContactPhone2, setcurrentEmergencyContactPhone2] = useState();
  var [currentEmergencyContactRelation2, setcurrentEmergencyContactRelation2] = useState();
  var [currentAreasOfInterest, setcurrentAreasOfInterest] = useState();
  var [currentmedicareno, setcurrentmedicareno] = useState();
  var [currentmedicarereferenceno, setcurrentmedicarereferenceno] = useState();
  var [currentmedicareexpirydate, setcurrentmedicareexpirydate] = useState();
  var [nextBookingDate, setnextBookingDate] = useState();
  var [currentAppointmentType, setcurrentAppointmentType] = useState('0');
  const [currentReferringGPName,setcurrentReferringGPName] = useState();
  const [currentReferringGPProviderNo,setcurrentReferringGPProviderNo] = useState();
  const [currentReferredGPOn,setcurrentReferredGPOn] = useState();

  var [show, setShow] = useState(false);
  var [showNotes, setShowNotes] = useState(false);
  var [showFiles, setShowFiles] = useState(false);
  var [showBookingBilling, setShowBookingBilling] = useState(false);
  const [showCancelProviderBooking,setShowCancelProviderBooking] = useState(false);
  const [cancellationReason,setCancellationReason] = useState()
  const [showCancellationReason,setShowCancellationReason] = useState('')
  const [booking, setBooking] = useState(false)

  var [noteAction, setNoteAction] = useState('SMS')
  var [key, setKey] = React.useState('personalDetails');
    
  const initMedicalPractitioner = () => {
    return { id: 0, providernumber: '', salutation: 'Dr', firstName: '', surname: '', fullname: '', mobilephone: '', phone: '', type: 'Client GP',  }
  }

  const initMedicalPractice = () => {
    return { id: 0, abn: '', practicename: '', phone: '', address1: '', email: '', website: '', logoURL: '', practiceType: 'GeneralPractitioner' }
  }

  const loadMedicalPractitioner = (thisMedicalPractitioner) =>{
    setcurrentMedicalPractitioner(thisMedicalPractitioner ? thisMedicalPractitioner : initMedicalPractitioner())
    setcurrentMedicalPractitionerID(thisMedicalPractitioner ? thisMedicalPractitioner.id : 0);
    var tempMedicalPractice = thisMedicalPractitioner ? medicalPractices.find(v => v.id === thisMedicalPractitioner.medicalPracticeID) : null;
    loadMedicalPractitice(tempMedicalPractice)
  }

  const loadMedicalPractitice = (thisMedicalPractice) => {
    setcurrentMedicalPractice(thisMedicalPractice ? thisMedicalPractice : initMedicalPractice())
  }

  function handleAppointmentTypeChange(value) {
    setcurrentAppointmentType(value);
  }


  var [currentMedicalPractitioner, setcurrentMedicalPractitioner] = useState(initMedicalPractitioner());  
  var [currentMedicalPractice, setcurrentMedicalPractice] = useState(initMedicalPractice());

  const getMedicalPractitionerOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractitioner();
    }
  }

  const getMedicalPracticeOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractice();
    }
  }
  
  function handleTimeZoneAutocompleteChange(propertyName, event, newValue) { 
    if (newValue) {
      setcurrentTimeZone(newValue.systemName);
    }
  } 

  function cancelBooking(id) {
    // Need to handle no show which is triggered if it is save by a provider and reason is not selected - it is like the multiverse 
    if (!showCancellationReason || (showCancellationReason && showCancellationReason === 'N')) 
    {
          // Update database 
      dispatch(Admin.actions.putAppointment( 
        { id: id, 
          status: 'NoShow', 
          refreshPayload: { key: 'psychologist', id: registered.id } 
        }));
      // Update local display         
      setBooking({...booking, status: 'NoShow'});
    }
    else
    {
      // Update database 
      dispatch(Admin.actions.putAppointment( 
        { id: id, 
          status: 'Cancelled', 
          refreshPayload: { key: 'psychologist', id: registered.id } 
      }));
      // Update local display       
      setBooking({...booking, status: 'Cancelled'});
    }
    // Save note if a cancellation reason was set - it can be set in provider cancellations 
    if (showCancellationReason && showCancellationReason === 'Y')
    {
      // ok we have a note to save 
      const formData = new FormData();
      // Create note 
      var newishNote = `Please note your consultation with ${booking.psychologistfullname} at ${format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy hh:mm aa")}. was cancelled by the provider due to ${cancellationReason}`;
      // Add fields 
      formData.append('type','Client');
      formData.append('pkid', booking.clientID);
      formData.append('subject','');
      formData.append('selector','Note');
      formData.append('note',newishNote);
      formData.append('faxdestination','');
      formData.append('faxFile','');
      formData.append('sendFormID',0);
      // Ok Time to Dispatch 
      process.env.REACT_APP_DEBUG && console.log(...formData);
      dispatch(Admin.actions.postNote({ formData:formData, type: 'Client', pkID: booking.clientID}));
    }
    else
    {

    }
    // Close provider booking cancel modal - if open
    setShowCancelProviderBooking(false)
  }


  function putClient() {
    var payload = {
      profileClientID: currentClientID,
      profileFirstName: currentFirstName,
      profileSurname: currentSurname,
      profileDOB: currentDOB,
      profileEmail: currentEmail,
      profileGender: currentGender,
      profileOccupation: currentOccupation,
      profileMobile: currentMobile,
      profilefulladdress: currentfulladdress,
      profileStatus: currentStatus,
      profileNote: currentNote,
      profilehopeToGetFromTreatment: currenthopeToGetFromTreatment,
      profileMedicalPractitionerID: currentMedicalPractitionerID,
      profilePaymentType: currentPaymentType,
      profileTimeZone: currentTimeZone,
      profileLatitude: currentLatitude, 
      profileLongitude: currentLongitude,
      profilePostCode: currentPostCode,
      emergencycontactname1: currentEmergencyContactName1,
      emergencycontactphone1: currentEmergencyContactPhone1,
      emergencycontactrelation1: currentEmergencyContactRelation1,
      emergencycontactname2: currentEmergencyContactName2,
      emergencycontactphone2: currentEmergencyContactPhone2,
      emergencycontactrelation2: currentEmergencyContactRelation2,
      profilemedicareno: currentmedicareno,
      profilemedicarereferenceno: currentmedicarereferenceno,
      profilemedicareexpirydate: currentmedicareexpirydate,  
      profileAreasOfInterest: currentAreasOfInterest.map(aoi => aoi.issue).join(","),  
      medicalPractice: currentMedicalPractice,
      medicalPractitioner: currentMedicalPractitioner, 
      appointmenttype: currentAppointmentType,  
      status: currentStatus,             
    }
    //
    if (process.env.REACT_APP_DEBUG) {
      // Output form
      // console.log(payload);
    }
    //
    // console.log(payload);
    // Check if there is an update or insert 
    if (currentClientID > 0)
    {
      if (process.env.REACT_APP_DEBUG) 
      {
        console.log ('Putting (Update)');
      }        
      // Save update 
      dispatch(Admin.actions.putClientDetail(payload));
    }
    // Close form
    setShow(false);
  }

  const loadProfile = (thisClient) => {
    setcurrentClientID(thisClient.id);
    setcurrentID(thisClient.id);
    setcurrentMedicalPractitionerID(thisClient.medicalPractitionerID);
    setcurrentPaymentType(thisClient.paymentType);
    setcurrentfulladdress(thisClient.fulladdress);
    setcurrentStatus(thisClient.status);
    setcurrentLatitude(thisClient.latitude);
    setcurrentLongitude(thisClient.longitude);
    setcurrentFirstName(thisClient.firstname);
    setcurrentSurname(thisClient.surname);
    setcurrentDOB(thisClient.dob);
    setcurrentEmail(thisClient.email);
    setcurrentGender(thisClient.gender);
    setcurrentOccupation(thisClient.occupation);
    setcurrentMobile(thisClient.mobilephone);
    setcurrentNote(thisClient.note);
    setcurrentTimeZone(thisClient.timeZone);
    setcurrentPostCode(thisClient.postCode);
    setcurrenthopeToGetFromTreatment(thisClient.hopeToGetFromTreatment);
    setcurrentEmergencyContactName1(thisClient.emergencycontactname1);
    setcurrentEmergencyContactPhone1(thisClient.emergencycontactphone1);
    setcurrentEmergencyContactRelation1(thisClient.emergencycontactrelation1);
    setcurrentEmergencyContactName2(thisClient.emergencycontactname2);
    setcurrentEmergencyContactPhone2(thisClient.emergencycontactphone2);
    setcurrentEmergencyContactRelation2(thisClient.emergencycontactrelation2);
    setcurrentmedicareno(thisClient.medicareno);
    setcurrentmedicarereferenceno(thisClient.medicarereferenceno);
    setcurrentmedicareexpirydate(thisClient.medicareexpirydate);
    setcurrentAppointmentType(thisClient.appointmenttype);
    setcurrentStatus(thisClient.status);
    setcurrentReferringGPName(thisClient.referringGPName)
    setcurrentReferringGPProviderNo(thisClient.referringGPProviderNo)
    setcurrentReferredGPOn(thisClient.referredGPOn)


    var aoi = areasOfInterest.filter(( {issue} ) => thisClient.clientAreasOfInterest.includes(issue))
    setcurrentAreasOfInterest(aoi);

    loadMedicalPractitioner(medicalPractitioners.find(v => v.id === thisClient.medicalPractitionerID));
    // Load Form
    setShow(true);
  }

  function loadBooking(props)
  {
    var bookingData = {
      'id' : props.valueGetter('id'),
      'clientfullname' : client.fullname,
      'psychologistfullname' : registered.fullname,
      'psychologistDate' : props.valueGetter('start'),
      'clientID' : client.id,
      'paymentType' : props.valueGetter('paymentType'),
      'bookingType' : props.valueGetter('bookingType')

    }
    setBooking(bookingData)
    // console.log(bookingData)
    // Open Provider Cancel Modal
    setShowCancelProviderBooking(true);
  }


  function loadFiles(thisClient) {
    setcurrentClientID(thisClient.id);
    setcurrentFirstName(thisClient.firstname);
    setcurrentSurname(thisClient.surname);
    setShowFiles(true);
   }

  function loadNotes(thisClient, noteAction) {
    setNoteAction(noteAction);
    setcurrentClientID(thisClient.id);
    setcurrentID(thisClient.id);
    setcurrentFirstName(thisClient.firstname);
    setcurrentSurname(thisClient.surname);
    setnextBookingDate(thisClient.nextBooking)
    loadMedicalPractitioner(medicalPractitioners.find(v => v.id === thisClient.medicalPractitionerID));
    setShowNotes(true);
   }

   function loadBookingBilling(thisClient, noteAction) {
    setcurrentClientID(thisClient.id);
    setcurrentID(thisClient.id);
    setcurrentFirstName(thisClient.firstname);
    setcurrentSurname(thisClient.surname);
    setcurrentDOB(thisClient.dob);
    setcurrentEmail(thisClient.email);
    setcurrentMobile(thisClient.mobilephone);
    setcurrentmedicareno(thisClient.medicareno);
    setcurrentmedicarereferenceno(thisClient.medicarereferenceno);
    setcurrentmedicareexpirydate(thisClient.medicareexpirydate);
    setcurrentReferringGPName(thisClient.referringGPName)
    setcurrentReferringGPProviderNo(thisClient.referringGPProviderNo)
    setcurrentReferredGPOn(thisClient.referredGPOn)
    setShowBookingBilling(true);
   }

  // Availability uses the default form editor because it contains the RR controls
  if(props.valueGetter('eventType') === 'availability')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }
  if(props.valueGetter('eventType') === 'availabilityAny')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityExisting')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityOther')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilitySupervision')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityNDISContractEAP')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityNDISContractEAPNew')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityNDISContractEAPExisting')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

  if(props.valueGetter('eventType') === 'availabilityPerinatalGriefLoss')
  {
    return (
      <SchedulerFormEditor 
        {...props}
        titleLabel={TitleLabel}
        titleEditor={TitleEditor}
        endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
        startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
        startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
        descriptionLabel={DescriptionLabel}
        descriptionEditor={DescriptionEditor}
        horizontal={true}
      >
      </SchedulerFormEditor>
    );
  }

    if(props.valueGetter('eventType') === 'availabilityNthQLDPHNNew')
    {
      return (
        <SchedulerFormEditor 
          {...props}
          titleLabel={TitleLabel}
          titleEditor={TitleEditor}
          endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
          startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
          startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
          descriptionLabel={DescriptionLabel}
          descriptionEditor={DescriptionEditor}
          horizontal={true}
        >
        </SchedulerFormEditor>
      );
    }

    if(props.valueGetter('eventType') === 'availabilityNthQLDPHNExisting')
    {
      return (
        <SchedulerFormEditor 
          {...props}
          titleLabel={TitleLabel}
          titleEditor={TitleEditor}
          endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
          startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
          startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
          descriptionLabel={DescriptionLabel}
          descriptionEditor={DescriptionEditor}
          horizontal={true}
        >
        </SchedulerFormEditor>
      );
    }

    if(props.valueGetter('eventType') === 'availabilityNthQLDPHNFacetoFace')
      {
        return (
          <SchedulerFormEditor 
            {...props}
            titleLabel={TitleLabel}
            titleEditor={TitleEditor}
            endEditor={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker}
            startTimezoneCheckedLabel={StartTimezoneCheckedLabel}
            startTimezoneCheckedEditor={StartTimezoneCheckedEditor}
            descriptionLabel={DescriptionLabel}
            descriptionEditor={DescriptionEditor}
            horizontal={true}
          >
          </SchedulerFormEditor>
        );
      }

  // Bookings use completely custom form for all client related features
  else if(props.valueGetter('eventType') === undefined || props.valueGetter('eventType') === 'booking')
  {
    return (
      <FormElement horizontal={true} style={{ height: 460 }}>
        {!props.valueGetter('id') &&
          <FieldWrapper style={{ display: 'flex' }}>  
            <Label>
              Type
            </Label>
            <div className={"k-form-field-wrap"} style={{ width: 120 }}>
              <Field 
                label="Type" 
                name={'eventType'} 
                component={EventTypeEditor} 
                eapcontractndis={(registered && registered.eapContractNDISAvailability === 'Y') || (role === 'Admin') ? true : false} 
                nthqldphn={(registered && registered.specialisttype === 'nthqldphn') || (role === 'Admin') ? true : false}
                admin={ role === 'Admin' ? true : false}
              />
              {props.visited && props.errors.eventType && <Error>{props.errors.eventType}</Error>}
            </div>
          </FieldWrapper>
        }
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            Start
          </Label>
          <div className="k-form-field-wrap">
            <Field name={'start'} component={props.startEditor || DateTimePicker} as={DateTimePicker} rows={1} width={'200px'} format="dd/MM/yyyy hh:mm a" />
          </div>
        </FieldWrapper>
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            End
          </Label>
          <div className="k-form-field-wrap">
            <Field name={'end'} component={!props.valueGetter('id') ? CustomEndEditor : DateTimePicker} as={DateTimePicker} eventType={props.valueGetter('eventType')} rows={1} width={'200px'} format="dd/MM/yyyy hh:mm a" />
          </div>
        </FieldWrapper>
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            Client
          </Label>
          <div className="k-form-field-wrap">
            <Field name={'clientID'} component={ClientEditor} disabled={props.valueGetter('id')} />
            {props.visited && props.errors.clientID && <Error>{props.errors.clientID}</Error>}
          </div>
        </FieldWrapper>
        {props.valueGetter('id') &&                
          <FieldWrapper style={{ display: 'flex' }}>
            <Label>
              Booking Type
            </Label>
            <div className="k-form-field-wrap">
              <Field name={'bookingType'} component={BookingTypeEditor} client={client} />
              {props.visited && props.errors.bookingType && <Error>{props.errors.bookingType}</Error>}
            </div>
          </FieldWrapper>
        }
        {props.valueGetter('id') &&                
          <FieldWrapper style={{ display: 'flex' }}>
            <Label>
              Payment
            </Label>
            <div className="k-form-field-wrap">
              <Field name={'paymentType'} component={PaymentTypeEditor} client={client} />
              {props.visited && props.errors.paymentType && <Error>{props.errors.paymentType}</Error>}
            </div>
          </FieldWrapper>
        }
        {client && (client.paymentType === 'NDIS') ?
          <>
            <FieldWrapper style={{ display: 'flex' }}>
              <Label>

              </Label>
            <div className="k-form-field-wrap">
              <strong>
                <p>If this was a NDIS standard session for this client please <br /> invoice <i>accounts@ruralhealthconnect.com.au</i> the <br /> amount {client.ndisproviderfee} for this session.  If not, note any changes below.</p>
              </strong>
            </div>
            </FieldWrapper>
          </>
        :
          null
        }
        {console.log(client && client)}
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            Note
          </Label>
          <div className="k-form-field-wrap">
            <Field name={'note'} component={TextArea} rows={2} />
          </div>
        </FieldWrapper>
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            Type
          </Label>
          <div className="k-form-field-wrap" style={{ maxWidth: 120 }}>
            <Field name={'bookingType'} component={BookingTypeEditor}/>
          </div>
        </FieldWrapper>
        <FieldWrapper style={{ display: 'flex' }}>
          <Label>
            Status
          </Label>
          <div className="k-form-field-wrap" style={{ maxWidth: 120 }}>
            <Field name={'status'} component={StatusEditor}/>
          </div>
          { props.valueGetter('status') === 'Active'  && props.valueGetter('clientID') > 0 && client.id > 0 && props.valueGetter('id') > 0?
            <div className="k-form-field-wrap" style={{ maxWidth: 220 }}>
              <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#faa0a0", marginLeft: 30, marginRight: 10 }} onClick={() => loadBooking(props)}>
                Cancel Booking
              </Button>
            </div>
          :
            null
          }
        </FieldWrapper>
        {props.valueGetter('clientID') > 0 && client.id > 0 && role !== 'Admin' ?
          <FieldWrapper style={{ display: 'flex' }}>
            <Label>
              Actions
            </Label>            
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#b2b6eb", marginRight: 10 }} onClick={() => loadProfile(client)}>
              Profile 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#faefcf", marginRight: 10 }} onClick={() => loadNotes(client, 'SMS')}>
              SMS 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#d2f9fa", marginRight: 10 }} onClick={() => loadNotes(client, 'Email')}>
              Email 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ebfae8", marginRight: 10 }} onClick={() => loadFiles(client)}>
              Files 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#fff0ed", marginRight: 10 }} onClick={() => loadNotes(client, 'Fax')}>
              Fax 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#fffbc2", marginRight: 10 }} onClick={() => loadNotes(client, 'Form')}>
              Forms 
            </Button>
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#FF9636", marginRight: 10 }} onClick={() => loadBookingBilling(client)}>
              Billing 
            </Button>
          </FieldWrapper>
        : 
          <FieldWrapper style={{ display: 'flex' }}>
          </FieldWrapper>
        }
        
        <Modal show={showNotes} onHide={()=>setShowNotes(false)} dialogClassName="notes-modal-dialog" style={{zIndex:1150}}>
          <Modal.Header closeButton className="notes-modal">
            <Modal.Title>Notes - {currentFirstName} {currentSurname}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="notes-modal">
            {registered ? 
              <Notes type="Client" noteAction={noteAction} pkid={currentClientID} medicalPractice={currentMedicalPractice} waitingRoomURL={registered && registered.waitingRoomURL} nextBookingDate={nextBookingDate} psychologistFullName={registered.firstname + ' ' + registered.surname} clientFirstName={currentFirstName} />
            : null}   
          </Modal.Body>
          <Modal.Footer className="notes-modal">
            <Button variant="secondary" onClick={() => setShowNotes(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showFiles} onHide={()=>setShowFiles(false)} dialogClassName="notes-modal-dialog" style={{zIndex:1150}}>
          <Modal.Header closeButton className="notes-modal">
            <Modal.Title>Files - {currentFirstName} {currentSurname}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="notes-modal">
            {registered ? 
              <Files type="Client" pkid={currentClientID} />
            : null}   
          </Modal.Body>
          <Modal.Footer className="notes-modal">
            <Button variant="secondary" onClick={() => setShowFiles(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showBookingBilling} onHide={()=>setShowBookingBilling(false)} dialogClassName="notes-modal-dialog" style={{zIndex:1150}}>
          <Modal.Header closeButton className="notes-modal">
            <Modal.Title>Billing  - {currentFirstName} {currentSurname}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="notes-modal">
            {registered ? 
              <BookingBillingList
                id={currentClientID}
                clientFirstName={currentFirstName}
                clientSurname={currentSurname}
                clientDOB={currentDOB}
                clientEmail={currentEmail}
                clientMobile={currentMobile}
                clientMedicareNo={currentmedicareno}
                clientMedicareReferenceNo={currentmedicarereferenceno}
                clientMedicareExpiryDate={currentmedicareexpirydate}
                clientreferringGPName={currentReferringGPName}
                clientreferringGPProviderNo={currentReferringGPProviderNo}
                clientreferredOn={currentReferredGPOn}
              />
            : null}   
          </Modal.Body>
          <Modal.Footer className="notes-modal">
            <Button variant="secondary" onClick={() => setShowBookingBilling(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={show} onHide={()=>setShow(false)} dialogClassName="editprofile-modal" style={{zIndex:1150}}>
          <Modal.Header closeButton className="editprofile-modal">
            <Modal.Title>Edit Profile - {currentFirstName} {currentSurname}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="editprofile-modal">
            <Form onSubmit={putClient}>
              <Tabs
                id="EditModal"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="personalDetails" title="Personal" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" name="profileFirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Surname</Form.Label>
                          <Form.Control type="text"  name="profileSurname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Gender</Form.Label>
                          <Form.Control as="select" defaultValue={currentGender} onBlur={(event) => setcurrentGender(event.target.value)}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                            <option value="Prefer not to say">Prefer not to say</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control type="date" name="profileDOB" defaultValue={currentDOB} onBlur={(event) => setcurrentDOB(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" name="profileEmail" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Contact Phone Number</Form.Label>
                          <Form.Control type="text" name="profileMobile" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>TimeZone</Form.Label>
                          <Autocomplete
                            id="profileTimeZone"
                            variant="outlined"
                            options={timeZone}
                            getOptionLabel={(option) => option.name}
                            value={timeZone.find(v => v.systemName === currentTimeZone) || ''}
                            onChange={(event, newValue) => handleTimeZoneAutocompleteChange('profileTimeZone', event, newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="TimeZoneSelector"
                                style={{backgroundColor: "white", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>Home Address</Form.Label>
                          <PlacesAutoComplete 
                            currentID={currentID}
                            currentfulladdress={currentfulladdress}
                            setcurrentfulladdress = {setcurrentfulladdress}
                            setcurrentLatitude = {setcurrentLatitude} 
                            setcurrentLongitude = {setcurrentLongitude}
                            setcurrentPostCode = {setcurrentPostCode}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control type="text" name="profileOccupation" defaultValue={currentOccupation} onBlur={(event) => setcurrentOccupation(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group style={{width: "100%"}}>
                          <Form.Label>Funding Type</Form.Label>                        
                          <Form.Control as="select" defaultValue={currentPaymentType} onBlur={(event) => setcurrentPaymentType(event.target.value)}>
                            <option value="Bulk Billed">Bulk Billed</option>
                            <option value="Reduced Gap">Reduced Gap</option>
                            <option value="Full Fee">Full Fee</option>
                            <option value="NDIS">NDIS</option>
                            <option value="Contract" disabled>Contract</option>
                            <option value="EAP" disabled>EAP</option>
                            <option value="Provisional" disabled>Provisional</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* Note this field doesn't current exist */}
                    {/* <Row>
                      <Col md="10">
                        <Form.Group controlId="addForm.profileNote">
                          <Form.Label>Client Note</Form.Label>
                          <Form.Control as="textarea" rows={10} type="text" name="profileNote" value={addForm.profileNote} onChange={(event) => handleTextFieldChange('profileNote', event)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Areas of Interest  (Select all that apply) </Form.Label>
                          <Autocomplete
                            id="searchAOI"
                            variant="outlined"
                            multiple
                            options={areasOfInterest}
                            getOptionLabel={(option) => option.issue }
                            value={currentAreasOfInterest}
                            onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="AOISelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>What are you hoping to get out of your sessions?</Form.Label>
                          <Form.Control as="textarea" rows={4} type="text" name="profilehopeToGetFromTreatment" defaultValue={currenthopeToGetFromTreatment} onBlur={(event) => setcurrenthopeToGetFromTreatment(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    </Row>
                    {/* <Row>
                      <Col md="10">
                        <Form.Group controlId="currentMedicalPractitioner.address">
                          <Form.Label>Address - may need to add this </Form.Label>
                            <Form.Control type="text" name="address" value={currentMedicalPractitioner ? currentMedicalPractitioner.address : ''} readOnly />
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Primary Emergency Contact</Form.Label>
                          <Form.Control type="text" name="emergencycontactname1" defaultValue={currentEmergencyContactName1 || ''} onBlur={(event) => setcurrentEmergencyContactName1(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control type="text" name="emergencycontactrelation1" defaultValue={currentEmergencyContactRelation1 || ''} onBlur={(event) => setcurrentEmergencyContactRelation1(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text"  name="emergencycontactphone1" defaultValue={currentEmergencyContactPhone1 || ''} onBlur={(event) => setcurrentEmergencyContactPhone1(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Secondary Emergency Contact</Form.Label>
                          <Form.Control type="text" name="emergencycontactname2" defaultValue={currentEmergencyContactName2 || ''} onBlur={(event) => setcurrentEmergencyContactName2(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control type="text" name="emergencyrelation2" defaultValue={currentEmergencyContactRelation2 || ''} onBlur={(event) => setcurrentEmergencyContactRelation2(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group >
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text"  name="emergencycontactphone2" defaultValue={currentEmergencyContactPhone2 || ''} onBlur={(event) => setcurrentEmergencyContactPhone2(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Medicare Card Number</Form.Label>
                          <Form.Control type="text" name="medicareno" defaultValue={currentmedicareno || ''} onBlur={(event) => setcurrentmedicareno(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Order On Card</Form.Label>
                          <Form.Control type="text"  name="medicarereferenceno" defaultValue={currentmedicarereferenceno || ''} onBlur={(event) => setcurrentmedicarereferenceno(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Medicare Expiry Date</Form.Label>
                          <Form.Control type="text" name="medicareexpirydate" defaultValue={currentmedicareexpirydate || ''} onBlur={(event) => setcurrentmedicareexpirydate(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Appointment Type</Form.Label>
                          <Select 
                            variant="outlined"
                            margin="dense"
                            id='appointmentType'
                            aria-label='Select Appointment Type'
                            name='appointmentType'
                            value={currentAppointmentType} 
                            onChange={(event) => handleAppointmentTypeChange(event.target.value)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff'  }}>
                            <MenuItem key='Video' value='0'>Video Call</MenuItem>
                            <MenuItem key='Phone' value='1'>Phone Call</MenuItem>
                          </Select>
                        </Form.Group>
                      </Col>                       
                    </Row>
                </Tab>
                <Tab eventKey="clientGP" title="GP" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  
                  {/* Medical Practitioner */}
                  <Row>
                      <Col md="4">
                        <h4>Client's GP</h4>
                      </Col>
                      <Col md="2" style={{ textAlign: "right"}}>
                        <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                      </Col>
                      <Col md="6">
                        <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                          <Autocomplete
                            id="profileMedicalPractitionerID"
                            variant="outlined"
                            options={medicalPractitioners}
                            getOptionLabel={(option) => option.fullname }
                            getOptionSelected={(option, value) => getMedicalPractitionerOption(option, value)}
                            value={currentMedicalPractitioner}
                            onChange={(event, newValue) => loadMedicalPractitioner(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="MedicalPractitionerSelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col md="3">
                        <Form.Group controlId="currentMedicalPractitionerFirstName">
                          <Form.Label>Firstname</Form.Label>
                          <Form.Control type="text" name="firstName" value={currentMedicalPractitioner.firstName} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, firstName: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId="currentMedicalPractitionerSurname">
                          <Form.Label>Surname</Form.Label>
                          <Form.Control type="text" name="surname" value={currentMedicalPractitioner.surname} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, surname: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId="currentMedicalPractitionerMobilePhone">
                          <Form.Label>Mobile Phone</Form.Label>
                          <Form.Control type="text" name="mobilephone" value={currentMedicalPractitioner.mobilephone} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, mobilephone: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group controlId="currentMedicalPractitionerProviderNo">
                          <Form.Label>Provider Number</Form.Label>
                          <Form.Control type="text" name="providernumber" value={currentMedicalPractitioner.providernumber} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, providernumber: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row> 
                    {/* Medical Practice */}  
                    <Row>
                      <Col md="4">
                        <h4>Practice</h4>
                      </Col>
                      <Col md="2" style={{ textAlign: "right"}}>
                        <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                      </Col>
                      <Col md="6">
                        <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                          <Autocomplete
                            id="profileMedicalPractice"
                            variant="outlined"
                            options={medicalPractices}
                            getOptionLabel={(option) => option.practicename }
                            getOptionSelected={(option, value) => getMedicalPracticeOption(option, value)}
                            value={currentMedicalPractice}
                            onChange={(event, newValue) => loadMedicalPractitice(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="MedicalPracticeSelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                    <Col md="6">
                      <Form.Group >
                      <Form.Label>Clinic Name</Form.Label>
                        <Form.Control type="text" name="practicename" value={currentMedicalPractice ? currentMedicalPractice.practicename : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, practicename: event.target.value})} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                      <Form.Label>ABN</Form.Label>
                        <Form.Control type="text" name="currentAbn" value={currentMedicalPractice ? currentMedicalPractice.abn : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, abn: event.target.value})} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Contact Phone</Form.Label>
                        <Form.Control type="text" name="currentPhone" value={currentMedicalPractice ? currentMedicalPractice.phone : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, phone: event.target.value})} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="9">
                      <Form.Group >
                        <Form.Label>Clinic Address</Form.Label>
                        <Form.Control type="text" name="currentAddress1" value={currentMedicalPractice ? currentMedicalPractice.address1 : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, address1: event.target.value})} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Clinic Email</Form.Label>
                        <Form.Control type="text" name="currentEmail" value={currentMedicalPractice ? currentMedicalPractice.email : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, email: event.target.value})} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row> 

                </Tab>
                <Tab eventKey="notes" title="Notes" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                  <Notes type="Client" pkid={currentClientID} medicalPractice={currentMedicalPractice} waitingRoomURL={registered && registered.waitingRoomURL}/>
                </Tab>
                <Tab eventKey="files" title="Files" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                  <Files type="Client" pkid={currentClientID} />
                </Tab>
              </Tabs>
            </Form>                      
          </Modal.Body>
          <Modal.Footer className="editprofile-modal">
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={putClient}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCancelProviderBooking} onHide={()=>setShowCancelProviderBooking(false)} dialogClassName="new-bookingDialog-modal" style={{zIndex:1150}} centered>
          <Modal.Header closeButton className="new-booking-modal">
            <Modal.Title>Booking with {booking.clientfullname}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
            <Grid container spacing={3} >
              <Grid item md={3} xs={6}>
                Client:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {booking.clientfullname}
              </Grid>
              <Grid item md={3} xs={6}>
                Date:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy")}
              </Grid>
              <Grid item md={3} xs={6}>
                Time:
              </Grid>
              <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "h:mm aa")}
              </Grid>
              <Grid item md={3} xs={6}>
                Session URL:
              </Grid>
              <hr />
              <Grid item md={12} xs={6}>
                <Form.Group>
                    <Form.Label>Payment Type: {booking.paymentType}</Form.Label>
                    <Form.Label >Reason for Cancelling</Form.Label>
                    <Form.Check id="cancellationType" name="selectlater" type="radio" style={{ marginBottom: 8 }} >
                      <Form.Check.Input name="SelectLater" type="radio" checked={showCancellationReason === 'N' ? true: false} onChange={e => setShowCancellationReason('N')} />
                      <Form.Check.Label style={{marginLeft: 12 }}>Did not attend </Form.Check.Label>
                    </Form.Check>
                    <Form.Check id="NotSelectLater" name="notselectlater" type="radio" style={{ marginBottom: 8 }} >
                      <Form.Check.Input name="SelectLater" type="radio" checked={showCancellationReason === 'Y' ? true: false} onChange={e => setShowCancellationReason('Y')} />
                      <Form.Check.Label style={{marginLeft: 12 }}>Reason - please enter below</Form.Check.Label>
                    </Form.Check>
                    { showCancellationReason === 'Y' ?
                      <Form.Control as="textarea" rows={3} name="cancellationReason" value={cancellationReason} onChange={(event) => setCancellationReason(event.target.value)} autoComplete='new-password' />
                    :
                      null
                    }
                </Form.Group>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer className="new-booking-modal">
            <Grid container direction="row" justifyContent="space-between"  alignItems="center">
              <Grid item>
                <Button variant="secondary" size="sm" onClick={() => setShowCancelProviderBooking(false)} className="lightButtonOutlinedLarge">
                  Close
                </Button>
              </Grid>
              {(showCancellationReason === 'N') || (showCancellationReason === 'Y' && cancellationReason && cancellationReason.length > 5) ? 
                <>
                  <Grid item>
                    <Button variant="warning" size="sm" onClick={() => cancelBooking(booking.id)}>
                      Cancel Booking &gt;
                    </Button>
                  </Grid>
                </>
              :
                null
              }
            </Grid>
          </Modal.Footer>
        </Modal>





      </FormElement>
    );
  }

};