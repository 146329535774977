import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import * as auth from '../../store/ducks/auth.duck';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux'
import * as builder from '../../../_metronic/ducks/builder';
import { totp } from '../../crud/auth.crud';

function Totp(props) {
  const { intl } = props
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  // const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  //   paddingRight: '2.5rem'
  // });

  // const enableLoading = () => {
  //   // setLoading(true);
  //   setLoadingButtonStyle({ paddingRight: '3.5rem' });
  // };

  // const disableLoading = () => {
  //   // setLoading(false);
  //   setLoadingButtonStyle({ paddingRight: '2.5rem' });
  // };

  return (
    <>
      <div className='kt-login__head'>
        {/* <span className='kt-login__signup-label'>
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to='/auth/registration' className='kt-link kt-login__signup-link'>
          Sign Up!
        </Link> */}
      </div>

      <div className='kt-login__body'>
        <div className='kt-login__form'>
          <div className='kt-login__title' style={{ marginBottom: 0 }}>
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              Multi Factor Authentication
            </h3>
            {/* <div className='login-message'>
              <p>Please enter your MFA Code  </p>
            </div> */}
          </div>

          <Formik
            initialValues={{
              totpEntry: ''
            }}
            validate={values => {
              const errors = {};

              // Disable as userid of members not an email address 
              // if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
              //  errors.email = intl.formatMessage({
              //     id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              //   });
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = intl.formatMessage({
              //     id: 'AUTH.VALIDATION.INVALID_FIELD'
              //   });
              // }

              // if (!values.password) {
              //   errors.password = intl.formatMessage({
              //     id: 'AUTH.VALIDATION.REQUIRED_FIELD'
              //   });
              // }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              // enableLoading();
              setTimeout(() => {
                const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
                totp(values.totpEntry, props.totpToken, browserTZ)
                  .then(({ data: { token, refreshToken, firstName, surname, preferredName, roleID, roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype } }) => {
                    // disableLoading();
                    // console.log(firstName);
                    props.login(token,refreshToken,firstName,surname, preferredName, roleID,roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype);
                    dispatch(builder.actions.setMenuConfig({roleDesc,roleScope}))
                  })
                  .catch((err) => {
                    // disableLoading();
                    setSubmitting(false);
                    if (err)
                    {
                      if (err.response)
                      {
                        if (err.response.data.message && err.response.data.message === 'FailedTOTP')
                        {
                          // console.log('Redirecting')
                          // console.log(props)
                          dispatch(auth.actions.clearMFAToken());
                          props.history.push("/auth/login")
                        }
                        if (err.response.data.message && err.response.data.message === 'No Access')
                        {
                          setStatus(
                            intl.formatMessage({
                              id: "AUTH.VALIDATION.INVALID_TOTP"
                            })
                          );
                        }
                      }
                    };
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete='off'
                className='kt-form'
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                    <i className="far far-warning"></i> Confidential Information - Authorized access only.
                    </div>
                  </div>
                )}

                <div className='form-group'>
                  <TextField
                    variant='outlined'
                    type='text'
                    label='Please enter your MFA Code'
                    margin='normal'
                    className='kt-width-full'
                    inputProps={{ style: { backgroundColor: 'white' }}}
                    name='totpEntry'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.totpEntry}
                    helperText={touched.totpEntry && errors.totpEntry}
                    error={Boolean(touched.totpEntry && errors.totpEntry)}
                    autoComplete='totpEntry'
                  />
                </div>

                <div className='kt-login__actions'>
                  <Link
                    to='/auth/backupcode'
                    className='kt-link kt-login__link-forgot'
                  >
                    <FormattedMessage id='AUTH.GENERAL.NOPHONE_BUTTON' />
                  </Link>

                  <Button id='kt_login_signin_submit' type='submit' disabled={isSubmitting} variant='primary' >
                    Send
                  </Button>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    totpToken: state.auth.totp
  }
};

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Totp)
);



