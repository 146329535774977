import React, { useState, useEffect } from 'react';
import { Redirect, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Form, Row, Col, Modal, Badge } from "react-bootstrap";
import { Paper, List, ListItem, ListItemIcon, ListItemText, Checkbox, TextField, Grid, Card, CardMedia, CardContent, CardActions, Chip, Typography, makeStyles, useMediaQuery, IconButton, Collapse } from '@material-ui/core';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToastContainer, Flip } from "react-toastify";
import Interweave from 'interweave';
import Masonry from 'react-masonry-css';
import clsx from 'clsx';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 200,
    // maxWidth: 200,
  },
  media: {
    height: 200,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

// Blob setup for psychologist photos 
const containerName = `psychologists`;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 

export default function SearchClient() {
    
  const dispatch = useDispatch()

  const isMobile = useMediaQuery('(max-width: 760px)');
  const role = useSelector(state =>  state.auth.roleDesc)
  const [showBookingExistsError, setShowBookingExistsError] = useState(false);
  const [searchList, setSearchList] = useState();
  const [expanded, setExpanded] = useState(0);
  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  // const [searchListLoaded, setSearchListLoaded] = useState(false);
  const matching = useSelector(state => state.admin.Matching)
  const [previousMatching,setPreviousMatching] = useState()
  const futureBookingStatus = useSelector(state => state.admin.ClientFutureBookingStatus)
  const [currentPsychologistName,setCurrentPsychologistName] = useState("Not Selected")
  const [currentGPName, setCurrentGPName] = useState("Not Selected")
  const client = useSelector(state => state.admin.Clients)
  const [searchType, setSearchType] = useState('Psychologist');
  const [showProviderBio,setShowProviderBio] = useState(false)
  const [providerBioFullName,setProviderBioFullName] = useState()
  const [providerBioBio,setProviderBioBio] = useState()

  
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMatching( { providertype : searchType, invite : true, availabilitytype : searchType === 'Supervision' ? 'Supervision' : 'Existing' })) }, [dispatch,searchType] )
  useEffect( () => { dispatch(Admin.actions.fetchClientFutureBookingStatus( { providertype : searchType })) }, [dispatch,searchType] )
  // useEffect( () => { dispatch(Admin.actions.fetchClientRegistered( {providertype : searchType })) }, [dispatch] )

  useEffect( () => { 
    if (matching !== previousMatching)
    {
      setSearchList(matching)
      setPreviousMatching(matching)
    }
  },[matching,previousMatching])

  useEffect( () => {
    if (client && client.length > 0 && client[0].psychologistName && (client[0].psychologistName !== currentPsychologistName))
    {
      setCurrentPsychologistName(client[0].psychologistName)
      setSearchType(client && client.length > 0 && client[0].paymentType === 'Supervision' ? 'Supervision' : 'Psychologist')
    }
  },[currentPsychologistName,setCurrentPsychologistName,client])

  useEffect( () => {
    if (client && client.length > 0 && client[0].gpName && (client[0].gpName !== currentGPName))
    {
      setCurrentGPName(client[0].gpName)
    }
  },[currentGPName,setCurrentGPName,client])


  const handleExpandClick = (id) => {
    // console.log(id)
    if(id === expanded) {
      setExpanded(0)
    } else {
      setExpanded(id);
    }
  };

  const moreProviderBio = (fullName, bio) => {
    // console.log('Read More Clicked')
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

  const areasOfInterest = useSelector(state =>  state.reference.AreasOfInterest)
  const [searchName, setSearchName] = useState('');
  const [searchAOI, setSearchAOI] = useState();
    
  // const [currentPsychologist, setcurrentPsychologist] = useState();
    
  const searchPsychologist = (p) => {
    if (  true
          // if there is AOI selections, then return if number of matches in psychologist equals the number of options in the selection
          && ( !searchAOI || (searchAOI && searchAOI.length === 0) || (searchAOI && searchAOI.filter(( {issue} ) => p.areasOfInterest.includes(issue)).length === searchAOI.length) )
          // search fullname for search string
          && (p.fullname.toUpperCase().indexOf(searchName.toUpperCase()) > -1 || searchName === '')
          && (p.bulkBillingAvailable === "1" || (p.bulkBillingAvailable === "0" && client && client.length > 0 && client[0].paymentType && client[0].paymentType !== "Bulk Billed"))
    )
    {
      return p
    }
  }
  
  var filteredPsychologists = searchList ? searchList.filter(p => searchPsychologist(p)) : []

  const savePsychologist = (thisPsychologist) => {
    //
    // Check to see if there are any future bookings
    if (futureBookingStatus)
    {
      setShowBookingExistsError(true)
      return(null)
    }
    else
    {
      //
      // setcurrentPsychologist(thisPsychologist)
      // Update clients' current provider
      if (searchType === 'GP')
      {
        dispatch(Admin.actions.putClientPsychologist({ gpid: thisPsychologist.id, clientid: client[0].id, providerName: thisPsychologist.fullname }))
        // Reload the page so the current provider settings are updated 
        setCurrentGPName()
      }
      else
      {
        dispatch(Admin.actions.putClientPsychologist({ psychologistid: thisPsychologist.id, clientid: client[0].id, providerName: thisPsychologist.fullname }))
        // Reload the page so the current provider settings are updated 
        setPreviousMatching()
      }
      // 
      return(
        <Redirect from="/auth" to='/dashboard' />
      )
    }

  }

  const handleSearchTypeChange = (event) => {
    // Set State 
    setSearchType(event.target.value)
    // Call for new data 
    dispatch(Admin.actions.fetchMatching( { providertype : searchType, invite : true, availabilitytype : searchType === 'Supervision' ? 'Supervision' : 'Existing' }))
    // dispatch(Admin.actions.fetchClientRegistered( {providertype : event.target.value })) 
    dispatch(Admin.actions.fetchClientFutureBookingStatus( { providertype : event.target.value }))
    setPreviousMatching()
  }

  const classes = useStyles();

  return (
    <>
     {/* <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} onClick={() => loadAppointment()}>
        Book 
     </Button> */}
      <h4>
        Search Providers
      </h4>
      <Paper style={{ padding: 15, backgroundColor: "rgb(245,245,245)" }}> 
        <Grid container spacing={3}>
          <Grid item md={12}>
            <div className="client-portal-heading-container">
              <span className="client-portal-heading-text">Your current Psychologist is <span className="client-portal-heading-highlight">{currentPsychologistName}</span>. </span>
              {currentGPName && currentGPName !== '' ? <span className="client-portal-heading-text">Your current GP is <span className="client-portal-heading-highlight">{currentGPName}.</span></span> : ''}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={3} style={{ height: isMobile ? 270 : 750, backgroundColor: "rgb(245,245,245)", paddingLeft: 10, paddingTop: 10 }}>
            {/* Psychologist search form */}
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Search</Form.Label>
                  <Form.Control type="text" name="searchName" value={searchName} onChange={(event) => setSearchName(event.target.value)} autoComplete='new-password' />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Provider Type</Form.Label>
                  <Form.Control id="searchType" as="select" value={searchType} onChange={handleSearchTypeChange}  >
                      <option value='Psychologist' disabled={searchType === 'Supervision'}>Psychologist</option>
                      <option value="GP" disabled={searchType === 'Supervision'}>GP</option>
                      <option value="Provisional" disabled={searchType === 'Supervision'}>Provisional Psychologist</option>
                      <option value='Supervision' disabled={searchType !== 'Supervision'}>Supervision</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              { searchType !== 'Supervision' ?
                <Col md="12">
                  <Form.Group >
                    <Form.Label>Area of Interest</Form.Label>
                    <Autocomplete
                      id="searchAOI"
                      variant="outlined"
                      multiple
                      options={areasOfInterest}
                      getOptionLabel={(option) => option.issue }
                      value={searchAOI}
                      onChange={(event, newValue) => setSearchAOI(newValue)}
                      style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="AOISelector"
                          style={{backgroundColor: "", margin: 0, padding: 0}}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                  </Form.Group>
                </Col>
              :
                null
              }
            </Row>
          </Grid>
          <Grid item md={9} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
            {searchType !== 'Supervision'  ?
              <Masonry
                breakpointCols={isMobile ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {filteredPsychologists.map((row, index) => {
                  return (
                    <Card className={classes.root} variant="outlined" key={index}>
                      <CardMedia
                        className={classes.media}
                        image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                        title="Psychologist"
                      />
                      <CardContent>
                        <Grid container justifyContent="space-between">
                          <Grid item sm={12}>
                            <Typography variant="h5" component="h3">
                            {row.fullname} {`(Next available: ${row.nextAvailable})`}
                            </Typography>
                          </Grid>
                          {(searchType !== 'GP' && searchType !== 'Provisional' && searchType !== 'Social' && searchType !== 'Occupational' && searchType !== 'Nurse') ?
                            <>
                              {client && client.length > 0 && client[0].paymentType === 'Full Fee' ?
                                <>
                                  <Grid container style={{ marginTop: 10 }}>
                                    <Grid item sm={6} >
                                      <Typography variant="subtitle1" style={{ fontWeight:600 , marginTop: 11 }}>
                                        Out of Pocket Cost
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={6} style={{ textAlign: "right" }}>
                                      <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                        {row.hourlyRateOverrideGap && row.hourlyRateOverrideGap !== '0' ? row.hourlyRateOverrideGap : row.standardFeeOutOfPocket }
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay {row.hourlyrate} on the day and receive back {row.hourlyRateOverrideRebate && row.hourlyRateOverrideRebate !== '0' ? row.hourlyRateOverrideRebate : row.medicareRebate} from Medicare
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                                :
                                null
                              }
                              {client && client.length > 0 && client[0].paymentType === 'Reduced Gap' ?
                                <>
                                  {row.isReducedFee === 'Y' ? 
                                    <>
                                      <Grid container style={{ marginTop: 10 }}>
                                        <Grid item sm={5} >
                                          <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                            Out of Pocket Cost
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>
                                          <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                            {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                          { row.reducedFee === '0' ?
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay $0 on the day
                                            </Typography>
                                          :
                                            <>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay {row.reducedFee} on the day
                                              </Typography>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                        { row.reducedFee !== '0' ?
                                          <Grid item sm={12}>
                                            <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                              Note this provider offers some flexibility, depending on client circumstances
                                            </Typography>
                                          </Grid>
                                        :
                                          null
                                        }
                                      </Grid>
                                    </>
                                  :
                                    <>
                                      <Grid container style={{ marginTop: 10 }}>
                                        <Grid item sm={5} >
                                          <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                            Out of Pocket Cost
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={7} style={{ textAlign: "right" }}>
                                          <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                            {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                          { row.reducedFee === '0' ?
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay $0 on the day
                                            </Typography>
                                          :
                                            <>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay {row.reducedFee} on the day
                                              </Typography>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    </>
                                  }
                                </>
                              :
                                null
                              }
                              <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                                {row.areasOfInterest.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                              </Grid>
                            </>
                          :
                            null
                          }
                        </Grid>
                        <Typography className={classes.pos} color="textSecondary">
                          <br />
                        </Typography>
                        <Grid container>
                          <Typography variant="body2" component="p">
                            <Interweave content={row.bio.substring(0,700)} />
                            {row.bio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </Grid>
                      </CardContent>
                      {role && role === 'Client' ?
                        <>
                          <CardActions disableSpacing>
                            <Button size="sm" onClick={()=>savePsychologist(row)}>Select as your {row.providerdescription} </Button>
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                              })}
                              onClick={() => handleExpandClick(row.id)}
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </CardActions> 
                          <Collapse in={(expanded !== row.id)} timeout="auto" unmountOnExit>
                            <CardContent>                      
                              {row.therapyTypes && row.therapyTypes.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                            </CardContent>
                          </Collapse>               
                        </>
                      :
                        null
                      }

                    </Card>
                    )
                  })
                }
              </Masonry>
            :
              <Masonry
                  breakpointCols={isMobile ? 1 : 3}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column">
                  {filteredPsychologists.map((row, index) => {
                    return (
                      <>
                        <Card className={classes.root} variant="outlined" key={index}>
                          <CardMedia
                            className={classes.media}
                            image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                            title="Psychologist"
                          />
                          <CardContent>
                            <Grid container justifyContent="space-between">
                              <Grid item sm={12}>
                                <Typography variant="h5" component="h3">
                                  {row.fullname}  (Next available: {row.nextAvailable})
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={8} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={4} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {row.supervisorHourlyRate}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography className={classes.pos} color="textSecondary">
                              <br />
                            </Typography>
                            <Grid container>
                              <Typography variant="body2" component="p">
                                <Interweave content={row.supervisorBio.substring(0,700)} />
                                {row.supervisorBio.length > 700 ?
                                  <>
                                    ...
                                    <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                      more
                                    </Badge>
                                  </>
                                :
                                  null
                                }
                              </Typography>
                            </Grid>
                            <br />
                          </CardContent>
                          <CardActions disableSpacing>
                            <Button size="sm" onClick={()=>savePsychologist(row)}>Select as your {row.providerdescription} </Button>
                          </CardActions> 
                          <CardContent>
                            <Typography variant="h6" component="h3">
                              Available For
                            </Typography>
                            <Row>
                              <List className={classes.root}>
                                {supervisionAreas.map(sRow => {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = row.supervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                    );
                                })}
                              </List>
                            </Row>
                            <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                              Endorsed In 
                            </Typography>
                            <Row>
                              <List className={classes.root}>
                                {endorsedSupervisionAreas.map(sRow => {
                                  var isChecked = row.supervisorAreas.includes(sRow.id);
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  if (isChecked) {
                                    return(
                                      <ListItem key={sRow.id} role={undefined} dense button >
                                        <ListItemIcon style={{ minWidth: 40 }}>
                                          <Checkbox
                                            edge="start"
                                            checked={isChecked}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                            disableTypography 
                                            primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                            className={classes.link} />
                                      </ListItem>
                                    )
                                  }
                                  else
                                  {
                                    return null
                                  }
                                })}
                              </List>
                            </Row>
                          </CardContent>
                        </Card>
                      </>
                    )
                  })}
                </Masonry>
              }
          </Grid>
        </Grid>
      </Paper>

      <Modal show={showBookingExistsError} onHide={()=>setShowBookingExistsError(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Warning - Future Booking Exists</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container spacing={2}>
            <Grid item md={9} xs={6}  style={{ fontWeight: 600 }}>
              Unable to change provider as future booking exists. Please cancel future booking and then change Provider
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Button variant="secondary" onClick={() => setShowBookingExistsError(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
   
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

