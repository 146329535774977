import React, { useEffect, useRef, useState } from 'react'
import Tooltip 	from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core';

const RenderExpandableCell = (props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const { value } = props;
  const textElementRef = useRef(null);

  const checkOverflow = () => {
    // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
    if (textElementRef.current)
    {
        const clientWidth = textElementRef.current.getBoundingClientRect().width;
        textElementRef.current.style.overflow = 'visible';
        const contentWidth = textElementRef.current.getBoundingClientRect().width;
        textElementRef.current.style.overflow = 'hidden';
        setIsOverflow(contentWidth > clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <Tooltip title={<Typography fontSize={30}>{value}</Typography>} disableHoverListener={!isOverflowed}>
      <span
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
};

export default RenderExpandableCell;
