
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { Formik } from 'formik';
import * as Yup from "yup";
import { DataGridPro  } from '@mui/x-data-grid-pro';
import { Paper, Grid } from '@material-ui/core';
import { MenuItem, FormControl, Select, InputLabel, makeStyles } from '@material-ui/core';
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup } from "react-bootstrap";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';
import { format } from "date-fns";

export default function AdminUsers() {

  const classes = useStyles();
  const dispatch = useDispatch();  
  const filters = useSelector(state => state.admin.Filters)
  const users = useSelector(state => state.reference.Users)
  useEffect( () => { dispatch(Reference.actions.fetchUsers()) }, [dispatch] )

  const [show, setShow] = useState(false);
  const [tabKey, setTabKey] = useState('details')
  const [userListTerm, setUserListTerm] = useState(filters.userListTerm);
  const [showPassword, setShowPassword] = useState(false);

  function changeFilter(value) {
    setUserListTerm(value);
    dispatch(Admin.actions.setFilters({ filterName: 'userListTerm', filterValue: value }))
  }

  const filteredusers = users.filter(user => (
       (user.email.toLowerCase().search(userListTerm.toLowerCase()) > -1 || userListTerm.length === 0))
    && (filters.userListStatus === 'All' || user.status === filters.userListStatus)
  )

  const initUser = () => {
    return {
	    id: 0,
	    userID: 0,
	    extUserID: null,
	    firstName: '',
	    lastName: '',
	    initials: '',
	    email: '',
	    roleDesc: 'Verifier',
      roleScope : '',
      profilePassword: '',
      profilePasswordConfirmation: '',
	    status: '',
      defaultDirectoryID: 1,
	    timeZone: '',
	    updatedOn: new Date(),
	    createdOn: new Date(),
	  }
  }
    
  const [user, setUser] = useState(initUser())

  const loadUser = (thisUser) => {
    setUser({ ...thisUser, profilePassword: '', profilePasswordConfirmation: '' });
	  setShow(true);
  }
    
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required"),
    firstname: Yup.string()
      .required("Firstname is required"),
    surname: Yup.string()
      .required("Surname is required"),
    profilePasswordConfirmation:  Yup.string()
      .min(8,"Too Short")
      .oneOf([Yup.ref('profilePassword'), null], 'Passwords must match'),
  });

  const submitForm = (values) => {
	  var payload = {
		  user: values,
	  }
    setShow(false);
    dispatch(Reference.actions.putUser(payload))
  }
 
  const columns = [
    { field: "id", headerName: "User", hide: false, width: 300,
      renderCell: (params) => (
        <strong>
          <div onClick={() => loadUser(users.find(row => row.id === params.row.id))} className="cell-link">
            {params.row.email}
          </div>
        </strong>
      ) },
    { field: 'fullname', type: 'string', headerName: 'Name', width: 200 },  
    { field: 'status', type: 'string', headerName: 'Status', width: 100 },
    { field: 'rolescope', type: 'string', headerName: 'Role Scope', width: 120 },
    { field: 'updatedOn', type: 'date', headerName: 'Status', width: 100, valueFormatter: ({ value }) => format(new Date(value), "MM-dd-yyyy"), headerAlign: 'center', align: 'center', cellClassName: 'grid-mono-font' },
  ]

  return (
    <div className='container-fluid'>
      <h4>
          Users
      </h4>     
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>		
          <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
            <Grid container>
              <Grid item>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control                  
                      type="text"
                      value={userListTerm}
                      onChange={(event) => changeFilter(event.target.value)}
                      autoComplete='OFF'
                      style={{ maxWidth: 170, height: 36, backgroundColor: userListTerm !== '' ? 'rgb(250 239 195)' : 'white' }}
                    />
                  <InputGroup.Append onClick={() => changeFilter('')}>
                    <InputGroup.Text>
                      <ClearIcon fontSize="small" style={{ color: userListTerm !== '' ? '#222222' : 'silver', marginLeft: -3 }} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>  
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="userListStatus-label" className={classes.label}>User Status</InputLabel>
                  <Select
                    labelId="userListStatus-label"
                    id="userListStatus"
                    margin="dense"
                    value={filters.userListStatus}
                    onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'userListStatus', filterValue: event.target.value }))}
                    >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Archived">Archived</MenuItem>
                    <MenuItem value="Deleted">Deleted</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
           </Grid>

          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => {
							setTabKey('details')
							loadUser(initUser())
						}}>
              New User
            </Button>
          </Grid>

        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGridPro rows={filteredusers} columns={columns} checkboxSelection={false} rowHeight={38} disableColumnMenu={true} />
      </Paper>

		<Modal show={show} onHide={() => setShow(false)} dialogClassName="edit-sm-dialog-modal">
			<Modal.Header closeButton className="edit-md-modal">
			<Modal.Title>Edit User</Modal.Title>
			</Modal.Header>
                 
			<Formik
			  enableReinitialize={false}
			  initialValues={user}
			  validationSchema = {validationSchema}
			  onSubmit={(values, actions) => {
				submitForm(values);
				// actions.setSubmitting(false);
			  }}
			>
			{props => {
			const { values, errors, touched, handleChange, handleSubmit, isSubmitting } = props; 
			const change = (name,e) => { e.persist();  handleChange(e); };
			return (
			<form noValidate={true} autoComplete="off" onSubmit={!isSubmitting ? handleSubmit : undefined}>

			<Modal.Body className="edit-md-modal">
			  <Tabs
				id="EditModal"
				activeKey={tabKey}
				onSelect={(k) => setTabKey(k)}
				mountOnEnter={true}
			  > 
			    <Tab eventKey="details" title="Details" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
			    <Row>
            <Col md="12">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" id="email" name="email" value={values.email} onChange={change.bind(null, "email")} autoComplete='new-password' isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email}/>
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
			    </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group >
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text" name="surname" value={values.surname} onChange={change.bind(null, "surname")} autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
			    <Row>
            <Col md="6">
              <Form.Group>
                <Form.Label>Role Scope</Form.Label>
                <FormControl className="evform-control">
                  <Select
                    name="rolescope" variant="outlined" margin="dense" className="evform-select"
                    value={values.rolescope} onChange={change.bind(null, "rolescope")}
                  >
                    <MenuItem value='' label='Not Set'>Not Set</MenuItem>
                    <MenuItem value='Provisional' label='Provisional'>Provisional Only</MenuItem>
                  </Select>
                </FormControl>
                <Form.Control.Feedback type="invalid">{errors.rolescope}</Form.Control.Feedback>
              </Form.Group>
            </Col>
			    </Row>
			    <Row>
            <Col md="6">
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <FormControl className="evform-control">
                  <Select
                    name="status" variant="outlined" margin="dense" className="evform-select"
                    value={values.status} onChange={change.bind(null, "status")}
                  >
                    <MenuItem value='Active' label='Active'>Active</MenuItem>
                    <MenuItem value="Archived" lable='Archived'>Archived</MenuItem>
                  </Select>
                </FormControl>
                <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
              </Form.Group>
            </Col>
			    </Row>
			    <Row>
            <Col md="6">
              <Form.Group>
                <Form.Label>Change Password</Form.Label>
                <InputGroup>
                    <Form.Control type={showPassword ? "text" : "password"} name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                    <InputGroup.Append onClick={()=>setShowPassword(!showPassword)}>
                      <InputGroup.Text>
                        {showPassword ?
                          <VisibilityOffIcon fontSize="small" style={{ marginLeft: -4 }} />
                        : 
                          <VisibilityIcon fontSize="small" style={{ marginLeft: -4 }} /> 
                        }
                      </InputGroup.Text>
                    </InputGroup.Append>  
                    <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                    <Form.Control type={showPassword ? "text" : "password"} name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} autoComplete='new-password' onChange={change.bind(null, "profilePasswordConfirmation")}  isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} />
                    <InputGroup.Append onClick={()=>setShowPassword(!showPassword)}>
                      <InputGroup.Text>
                        {showPassword ?
                          <VisibilityOffIcon fontSize="small" style={{ marginLeft: -4 }} />
                        : 
                          <VisibilityIcon fontSize="small" style={{ marginLeft: -4 }} /> 
                        }
                      </InputGroup.Text>
                    </InputGroup.Append>  
                    <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
				</Tab>

			  </Tabs>
			</Modal.Body>
			<Modal.Footer className="edit-md-modal">
			<Grid container justifyContent="space-between">
				<Grid item>
				</Grid>
				<Grid item>
				<Button variant="secondary" onClick={()=>setShow(false)}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" style={{ marginLeft: 8 }}>
					Save
				</Button>
				</Grid>
			</Grid>
			</Modal.Footer>
			</form>
			)
			}}
			</Formik>
		</Modal>

    <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )

}

const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: 0,
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0,
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));
  