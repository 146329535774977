import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDispatch } from 'react-redux'
import * as builder from "../../../_metronic/ducks/builder";
import { login } from "../../crud/auth.crud";

function Login(props) {
  const { intl } = props;
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [showPassword,setShowPassword] = useState(false);
  

  const handleClickShowPassword = () => {
    // Check existing state value
    if (showPassword)
    {
      setShowPassword(false)
    }
    else
    {
      setShowPassword(true)
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  //   paddingRight: "2.5rem"
  // });

  // const enableLoading = () => {
  //   // setLoading(true);
  //   setLoadingButtonStyle({ paddingRight: "3.5rem" });
  // };

  // const disableLoading = () => {
  //   // setLoading(false);
  //   setLoadingButtonStyle({ paddingRight: "2.5rem" });
  // };

  return (
    <>
      <div className="kt-login__head">
        {/* <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link> */}
      </div>

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title" style={{ marginBottom: 0 }}>
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              Portal Login
            </h3>
            <div className="login-message">
              <p>Welcome to the new look Rural Health Connect. </p>
              <p>If you are here for a session you can access the session directly using the URL provided with your booking, or please login here and click Start Session button on the dashboard</p> 
              <p>If you did not receive a URL or you experience any issues connecting please call us on 0427 692 377.  We are here to help.</p>
              <p></p>If you don't have an account, you can register here:
                <ul style={{ marginTop: 10, fontWeight: 500 }}>
                  <li>
                    <Link
                      to="/clientregistration/new"
                      className="kt-link kt-login__link-forgot"
                    >
                      Clients
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/psychologistInvitation"
                      className="kt-link kt-login__link-forgot"
                    >
                      Psychologists
                    </Link>
                  </li>
                </ul>
            </div>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              // Disable as userid of members not an email address 
              // if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
              //  errors.email = intl.formatMessage({
              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
              //   });
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = intl.formatMessage({
              //     id: "AUTH.VALIDATION.INVALID_FIELD"
              //   });
              // }

              // if (!values.password) {
              //   errors.password = intl.formatMessage({
              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
              //   });
              // }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              // enableLoading();
              setTimeout(() => {
                const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
                login(values.email, values.password, browserTZ)
                  .then(({ data: { token, refreshToken, firstName, surname, preferredName, roleID, roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype, recoveryStatus } }) => {
                    // disableLoading();
                    // console.log('In Login')
                    // console.log(recoveryStatus);
                    props.login(token,refreshToken,firstName,surname,preferredName,roleID,roleDesc, roleScope, userTZ, winbrowserTZ, totp, providertype, recoveryStatus);
                    dispatch(builder.actions.setMenuConfig({roleDesc,roleScope}))
                  })
                  .catch((err) => {
                    // disableLoading();
                    setSubmitting(false);
                    if (err)
                    {
                      if (err.response)
                      {
                        if (err.response.data.message && err.response.data.message === 'Expired Recovery')
                        {
                          setStatus(
                            intl.formatMessage({
                              id: "AUTH.VALIDATION.EXPIRED_RECOVERY"
                            })
                          )
                        }
                        else
                        {  
                          if (err.response.data.message && err.response.data.message === 'Invalid Recovery')
                          {
                            setStatus(
                              intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_RECOVERY"
                              })
                            );
                          }
                          else
                          {
                            setStatus(
                              intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN"
                              })
                            );
                          }
                        }
                      }
                      else
                      {
                        setStatus(
                          intl.formatMessage({
                            id: "AUTH.VALIDATION.SERVER_OUTAGE"
                          })
                        );
                      }
                    }
                    else
                    {
                      setStatus(
                        intl.formatMessage({
                          id: "AUTH.VALIDATION.SERVER_OUTAGE"
                        })
                      );
                    }
                  });
              }, 1000);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                    <i className="far far-warning"></i> Confidential Information - Authorized access only.
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    inputProps={{ style: { backgroundColor: "white" }}}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    autoComplete='new-password'
                  />
                </div>

                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type= {showPassword ? "text" : "password"}
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    inputProps={{ style: { backgroundColor: "white" }}}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{backgroundColor: "white" }}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                  />
                </div>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>

                  <Button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"                    
                  >
                    Sign In
                  </Button>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
