import { put, takeLatest, takeEvery, all, select } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";
import isCached from "../../components/Cache";

export const BASE_URL = process.env.REACT_APP_APIIP + "/admin";

const cache = (state) => state.admin.cache;

export const actionTypes = {
  // Init actiontypes to build reference data 
  SET_INITIAL: "SET_INITIAL",
  SET_CACHE: "SET_CACHE",
  SET_INTERESTED : "SET_INTERESTED",
  FETCH_HELPITEMS: "FETCH_HELPITEMS",
  FETCH_RESOURCEITEMS: "FETCH_RESOURCEITEMS",

  SET_MEDICALPRACTICES : "SET_MEDICALPRACTICES",
  FETCH_MEDICALPRACTICES : "FETCH_MEDICALPRACTICES",
  PUT_MEDICALPRACTICE : "PUT_MEDICALPRACTICE",

  SET_MEDICALPRACTITIONERS : "SET_MEDICALPRACTITIONERS",
  FETCH_MEDICALPRACTITIONERS : "FETCH_MEDICALPRACTITIONERS",
  PUT_MEDICALPRACTITIONER : "PUT_MEDICALPRACTITIONER",
  FETCH_SOURCE : "FETCH_SOURCE",

  FETCH_CLIENTFUTUREBOOKINGSTATUS : "FETCH_CLIENTFUTUREBOOKINGSTATUS",
  SET_CLIENTFUTUREBOOKINGSTATUS : "SET_CLIENTFUTUREBOOKINGSTATUS",

  FETCH_INTERESTED : "FETCH_INTERESTED",
  PUT_INTERESTED : "PUT_INTERESTED",
  FETCH_REGISTERED : "FETCH_REGISTERED",
  SET_REGISTERED : "SET_REGISTERED",
  FETCH_CLIENTREGISTERED : "FETCH_CLIENTREGISTERED",
  SET_CLIENTREGISTERED : "SET_CLIENTREGISTERED",
  
  FETCH_OPPORTUNITIES: "FETCH_OPPORTUNITIES",
  SET_OPPORTUNITIES : "SET_OPPORTUNITIES",
  PUT_OPPORTUNITY: "PUT_OPPORTUNITY",

  FETCH_REFERRALSVIEW : "FETCH_REFERRALSVIEW",
  FETCH_REFERRALS : "FETCH_REFERRALS",
  SET_REFERRALS : "SET_REFERRALS",
  PUT_REFERRAL : "PUT_REFERRAL",
  PUT_REFERRALSTATUS : "PUT_REFERRALSTATUS",
  FETCH_FAXES : "FETCH_FAXES",
  SET_FAXES : "SET_FAXES",
  FETCH_FAXPDF : "FETCH_FAXPDF",
  SET_FAXPDF : "SET_FAXPDF",
  FETCH_INTENT : "FETCH_INTENT",
  SET_INTENT  : "SET_INTENT",
  PUT_CARD : "PUT_CARD",
  PUT_CLIENT_FLAG: "PUT_CLIENT_FLAG",
  PUT_FAX_FLAG : "PUT_FAX_FLAG",
  PUT_INTERESTEDPROVIDER_FLAG : "PUT_INTERESTEDPROVIDER_FLAG",
  PUT_CLIENTINVITE_FLAG : "PUT_CLIENTINVITE_FLAG",
  PUT_FAX_CLIENTNAME : "PUT_FAX_CLIENTNAME",
  PUT_CLIENT_TASKFLAG: "PUT_CLIENT_TASKFLAG",

  PUT_ARCHIVECLIENT : "PUT_ARCHIVECLIENT",

  PUT_INVOICESTATUS : "PUT_INVOICESTATUS",
  SEND_PSYCHOLOGISTREFERRAL : "SEND_PSYCHOLOGISTREFERRAL",

  SEND_PSYCHOLOGISTINVITE: "SEND_PSYCHOLOGISTINVITE",
  POST_PSYCHOLOGISTINVITE: "POST_PSYCHOLOGISTINVITE",
  DEL_PSYCHOLOGISTINVITE: "DEL_PSYCHOLOGISTINVITE",
  PUT_PSYCHOLOGISTDATA : "PUT_PSYCHOLOGISTDATA",
  POST_PSYCHOLOGISTDATA : "POST_PSYCHOLOGISTDATA",
  PUT_APIKEY : "PUT_APIKEY",

  POST_CLIENTINVITE: "POST_CLIENTINVITE",
  PUT_CLIENTINVITE: "PUT_CLIENTINVITE",
  SEND_CLIENTINVITE: "SEND_CLIENTINVITE",
  SEND_CLIENTINVITEREMINDER: "SEND_CLIENTINVITEREMINDER",
  SEND_SENDCALLUNSUCCESSFULREMINDER: "SEND_SENDCALLUNSUCCESSFULREMINDER",
  DEL_CLIENTINVITE: "DEL_CLIENTINVITE",
  FETCH_CLIENTINVITES : "FETCH_CLIENTINVITES",
  FETCH_CLIENTS : "FETCH_CLIENTS",
  FETCH_CLIENT : "FETCH_CLIENT",
  SET_CLIENTINVITES : "SET_CLIENTINVITES",
  SET_CLIENTS : "SET_CLIENTS",
  PUT_CLIENT : "PUT_CLIENT",
  CLEAR_CLIENT : "CLEAR_CLIENT",
  PUT_CLIENTDATA : "PUT_CLIENTDATA",
  POST_CLIENTDATA : "POST_CLIENTDATA",
  PUT_CLIENTPSYCHOLOGIST: "PUT_CLIENTPSYCHOLOGIST",

  FETCH_PAYMENTAPI: "FETCH_PAYMENTAPI",
  SET_PAYMENTAPI: "SET_PAYMENTAPI",

  FETCH_NOTES : "FETCH_NOTES",
  SET_NOTES : "SET_NOTES",
  POST_NOTE : "POST_NOTE",
  POST_CLIENTNOTE : "POST_CLIENTNOTE",

  FETCH_TODOS: "FETCH_TODOS",
  PUT_TODO: "PUT_TODO",

  FETCH_FILES : "FETCH_FILES",
  SET_FILES : "SET_FILES",
  POST_FILE : "POST_FILE",
  DELETE_FILE : "DELETE_FILE",
  SET_CLIENTVIEWABLEFLAG : "SET_CLIENTVIEWABLEFLAG",

  FETCH_REFERRERS : "FETCH_REFERRERS",
  SET_REFERRERS : "SET_REFERRERS",
  PUT_REFERRER : "PUT_REFERRER",

  CHG_BULKBILLINGAVAILABILITY: "CHG_BULKBILLINGAVAILABILITY",
  ADD_AVAILABILITY : "ADD_AVAILABILITY",
  CHG_AVAILABILITY : "CHG_AVAILABILITY",
  DEL_AVAILABILITY : "DEL_AVAILABILITY",
  SET_AVAILABILITY : "SET_AVAILABILITY",
  FETCH_AVAILABILITY : "FETCH_AVAILABILITY",
  FETCH_BOOKINGS : "FETCH_BOOKINGS",
  SET_BOOKINGS : "SET_BOOKINGS",
  FETCH_MATCHING : "FETCH_MATCHING",
  SET_MATCHING : "SET_MATCHING",
  FETCH_MATCHINGAVAILABILITY : "FETCH_MATCHINGAVAILABILITY",
  SET_MATCHINGAVAILABILITY : "SET_MATCHINGAVAILABILITY",
  CHG_PROVIDERAOI : "CHG_PROVIDERAOI",

  FETCH_BOOKING: "FETCH_BOOKING",
  SET_BOOKING: "SET_BOOKING",
  ADD_BOOKING : "ADD_BOOKING",
  PUT_BOOKING : "PUT_BOOKING",
  // BOOKING_REMINDER : "BOOKING_REMINDER", 

  FETCH_COUNTER : "FETCH_COUNTER",
  SET_COUNTER : "SET_COUNTER",

  PUT_XEROCONTACTLINK : "PUT_XEROCONTACTLINK",
  SET_XEROCONTACTLINK : "SET_XEROCONTACTLINK",
  PUT_XERONDISCONTACTLINK : "PUT_XERONDISCONTACTLINK",
  SET_XERONDISCONTACTLINK : "SET_XERONDISCONTACTLINK",
  PUT_BOOKINGBILLING : "PUT_BOOKINGBILLING",

  FETCH_BILLABLE : "FETCH_BILLABLE",
  FETCH_BILLABLEALL : "FETCH_BILLABLEALL",
  FETCH_BILLABLECONTRACT : "FETCH_BILLABLECONTRACT",
  FETCH_BILLABLENDIS : "FETCH_BILLABLENDIS",
  FETCH_BILLABLECONTRACTPROVIDER : "FETCH_BILLABLECONTRACTPROVIDER",
  FETCH_BILLABLENDISPROVIDER : "FETCH_BILLABLENDISPROVIDER",
  FETCH_SINGLEBILLABLE : "FETCH_SINGLEBILLABLE",
  SET_BILLABLE : "SET_BILLABLE",
  SET_BILLABLECONTRACT : "SET_BILLABLECONTRACT",
  SET_BILLABLENDIS : "SET_BILLABLENDIS",
  SET_BILLABLECONTRACTPROVIDER : "SET_BILLABLECONTRACTPROVIDER",
  SET_BILLABLENDISPROVIDER : "SET_BILLABLENDISPROVIDER",
  SET_SINGLEBILLABLE : "SET_SINGLEBILLABLE",
  PUT_BILLABLEBOOKING : "PUT_BILLABLEBOOKING",
  CREATE_BILLABLEEMAILS : "CREATE_BILLABLEEMAILS",
  CREATE_INVOICES : "CREATE_INVOICES",
  CREATE_INVOICESCONTRACT : "CREATE_INVOICESCONTRACT",
  CREATE_INVOICESNDIS : "CREATE_INVOICESNDIS",
  CREATE_INVOICESNDISPROVIDER : "CREATE_INVOICESNDISPROVIDER",
  CREATE_INVOICESCONTRACTPROVIDER : "CREATE_INVOICESCONTRACTPROVIDER",
  CREATE_PAYMENTS : "CREATE_PAYMENTS",
  FETCH_PAYMENTS : "FETCH_PAYMENTS",
  SET_PAYMENTS : "SET_PAYMENTS",

  FETCH_MEDIPASSINVOICES : "FETCH_MEDIPASSINVOICES",
  SET_MEDIPASSINVOICES : "SET_MEDIPASSINVOICES",
  FETCH_MEDIPASSCODES : "FETCH_MEDIPASSCODES",
  SET_MEDIPASSCODES : "SET_MEDIPASSCODES",
  FETCH_MEDIPASSBOOKINGDATES : "FETCH_MEDIPASSBOOKINGDATES",
  SET_MEDIPASSBOOKINGDATES : "SET_MEDIPASSBOOKINGDATES",
  PUT_MEDIPASSCODES : "PUT_MEDIPASSCODES",
  PUT_MEDIPASSINVOICES : "PUT_MEDIPASSINVOICES",

  FETCH_MEDIPASSCLIENTS : "FETCH_MEDIPASSCLIENTS",
  SET_MEDIPASSCLIENTS : "SET_MEDIPASSCLIENTS",

  FETCH_REPORT: "FETCH_REPORT",
  SET_REPORT: "SET_REPORT",
  SET_FILTERS: "SET_FILTERS",
  
  FETCH_TAGVALUES: "FETCH_TAGVALUES",
  PUT_TAGVALUES: 'PUT_TAGVALUES',
  FETCH_DASHBOARDNEWS: "FETCH_DASHBOARDNEWS",

  FETCH_BOOKINGTYPESTATISTICS : "FETCH_BOOKINGTYPESTATISTICS",
  SET_BOOKINGTYPESTATISTICS : "FETCH_BOOKINGTYPESTATISTICS",

  SET_DISPLAYINVITE: "SET_DISPLAYINVITE",
  CLEAR_DISPLAYINVITE: "CLEAR_DISPLAYINVITE",

  FETCH_INVOICELOG: "FETCH_INVOICELOG",
  SET_INVOICELOG: "SET_INVOICELOG",
  FETCH_CONTRACTS: "FETCH_CONTRACTS",
  SET_CONTRACTS: "SET_CONTRACTS",
  PUT_CONTRACT: "PUT_CONTRACT",
  FETCH_PROGRAMS: "FETCH_PROGRAMS",
  SET_PROGRAMS: "SET_PROGRAMS",
  PUT_PROGRAM: "PUT_PROGRAM",

  PUT_FAXSTATUS: "PUT_FAXSTATUS",

  FETCH_BOOKINGBILLABLECSV : "FETCH_BOOKINGBILLABLECSV",
  SET_BOOKINGBILLABLECSV : "SET_BOOKINGBILLABLECSV",

  FETCH_POWERBIXLSX : "FETCH_POWERBIXLSX",
  SET_POWERBIXLSX : "SET_POWERBIXLSX",

  FETCH_ACTIVITYFEED : "FETCH_ACTIVITYFEED",
  SET_ACTIVITYFEED : "SET_ACTIVITYFEED",
  PUT_PROVIDERPASSWORD : "PUT_PROVIDERPASSWORD",
  FETCH_OPPORTUNITYFEED : "FETCH_OPPORTUNITYFEED",
  SET_OPPORTUNITYFEED : "SET_OPPORTUNITYFEED",
  PUT_OPPORTUNITYINTEREST : "PUT_OPPORTUNITYINTEREST",
  PUT_RESETINTERESTEDPROVIDERS : "PUT_RESETINTERESTEDPROVIDERS",

  SET_SEARCHRESULTS : "SET_SEARCHRESULTS",
  FETCH_SEARCHCLIENTDATA : "FETCH_SEARCHCLIENTDATA",
  SET_SEARCHBOOKINGRESULTS : "SET_SEARCHBOOKINGRESULTS",
  FETCH_SEARCHBOOKINGDATA : "FETCH_SEARCHBOOKINGDATA",
  
  INITALISE_INVITES : "INITALISE_INVITES",

  SET_BOOKINGSTATUS : "SET_BOOKINGSTATUS",
  SET_PROGRESS_STATUS : "SET_PROGRESS_STATUS",

  FETCH_STATISTICS : "FETCH_STATISTICS",
  SET_STATISTICS: "SET_STATISTICS",

  FETCH_BOOKINGBILLING : "FETCH_BOOKINGBILLING",
  SET_BOOKINGBILLING : "SET_BOOKINGBILLING",

  FETCH_ADMINUSER : "FETCH_ADMINUSER",
  SET_ADMINUSER: "SET_ADMINUSER",
  PUT_ADMINUSER: "PUT_ADMINUSER",
  FETCH_CLIENTSBYDAY : "FETCH_CLIENTSBYDAY",
  SET_CLIENTSBYDAY : "SET_CLIENTSBYDAY",
  FETCH_CLIENTSBYWEEK : "FETCH_CLIENTSBYWEEK",
  SET_CLIENTSBYWEEK : "SET_CLIENTSBYWEEK",
  FETCH_CLIENTREGISTRATIONSBYDAY : "FETCH_CLIENTREGISTRATIONSBYDAY",
  SET_CLIENTREGISTRATIONSBYDAY : "SET_CLIENTREGISTRATIONSBYDAY",

  FETCH_PROVIDERPROGRAMBIO: "FETCH_PROVIDERPROGRAMBIO",
  SET_PROVIDERPROGRAMBIO: "SET_PROVIDERPROGRAMBIO",
  PUT_PROVIDERPROGRAMBIO: "PUT_PROVIDERPROGRAMBIO",

  FETCH_PROVIDERBOOKINGAPPROVAL : "FETCH_PROVIDERBOOKINGAPPROVAL",
  SET_PROVIDERBOOKINGAPPROVAL : "SET_PROVIDERBOOKINGAPPROVAL",
  PUT_PROVIDERBOOKINGAPPROVAL : "PUT_PROVIDERBOOKINGAPPROVAL",
  PUT_APPROVEBOOKING: "PUT_APPROVEBOOKING",
  PUT_RHCINVOICEDBOOKING: "PUT_RHCINVOICEDBOOKING",
  PUT_BOOKINGNOTE: "PUT_BOOKINGNOTE"
};

const initialState = {
  cache: {},
  progressStatus: { },
  MedicalPractices: { Psychologist: [], GeneralPractitioner: [], All: [] },
  AdminUser: { id: 0 },
  BookingTypeStatistics: '',
  Booking: {
    psychologistfullname: '',
    clientfullname: '',
    psychologistDate: '', 
    clientDate: '',
    waitingroomurl: '',
    message: '',
    clientID: 0,
    status: ''
  },
  BookingBilling: [],
  Source: [],
  MedicalPractitioners: [],
  InterestedPsychologists: [],
  RegisteredPsychologists: [],
  ClientRegisteredPsychologists: [],
  Referrals: [],
  ReferralsView: [],
  Faxes: [],
  ClientInvites: [],
  ActivityFeed: [],
  OpportunityFeed: [],
  Clients: [],
  Client: { id: 0 },
  ClientFutureBookingStatus : false,
  ClientsByDay: [],
  ClientsByWeek: [],
  ClientRegistrationsByDay: [],
  Contracts: [],
  Programs: [],
  ProgramProviderBio: '',
  Notes: { Client: [], Referral: [], Psychologist: [], Medicalpractitioner: [], Medicalpractice: [] },
  Files: { Client: [], ClientRegistration: [], Referral: [], Psychologist: [] },
  ToDos: [],
  Availability: [],
  SearchResults: [],
  SearchBookingResults: [],
  Bookings: [],
  Matching: [],
  MedipassInvoices: [],
  MedipassCodes: [],
  MedipassProviderClients: [],
  MedipassBookingDates: [],
  MatchingAvailability: [],
  BillableIntents: [],
  BillableIntentsAll: [],
  BillableIntentContracts: [],
  BillableIntentNDIS: [],
  BillableIntentContractsProvider: [],
  BillableIntentNDISProvider: [],
  BillableSingleIntents: [],
  ProviderBookingApproval: [],
  Payments: [],
  XeroLinkFailures: [],
  XeroNDISLinkFailures: [],
  Opportunities: [],
  FaxPDF: '',
  Intent: '',
  PaymentAPI: '',
  Cancellations: [],
  DisplayInvite: null,
  Referrers : [],
  FAXCounter : false,
  SMSCounter : false,
  Reports: { DashboardMetrics: {} },
  HelpItems: [],
  ResourceItems: [],
  DashboardNews: [],
  InvoiceLog: [],
  Statistics: [],
  TagValues: { 
    Fax: [],
    Psychologist: [],
    GP: [],
    Financial_Counsellor: [],
    Client: [],
    Client_Registration: [],
    Booking: [],
    Invoice: [],
  },
  Filters: { 
    bookingPhase: 'Future',
    bookingPaymentType: 'All',
    bookingValidationStatus: 'All',
    clientsListLoadClientID: undefined,
    clientsList: [],
    clientsTerm: '', 
    clientsPsychologist: 'All',
    clientsContract: 'All',
    clientsFlags: 'All', 
    clientsAOI: 'All', 
    clientsStatus: 'Active',
    clientsType: 'All',
    clientsReferrer: '',
    clientsSpecialisationType: 'All',
    clientRegistrationSort: 'NextAvailable',
    faxTerm:'',
    registeredTerm: '', 
    registeredProviderType: 'All',
    registeredStatus: 'Active',
    registeredAOI: 'All',
    registeredInterest: 'All',
    registeredTherapy: 'All',
    invitesListLoadInviteID: undefined,
    invitedClientsTerm: '', 
    invitedClientsStatus: 'All', 
    invitedClientProviderType: 'All',
    invitedClientsReferrer: undefined,
    invitedClientsType : 'All',
    faxesStatus: 'All', 
    interestedPsychologistsTerm: '', 
    interestedPsychologistsStatus: 'All',
    interestedProviderType: 'All',
    tagsTerm: '',
    referrersTerm: '',
    invoicelogTerm: '',  
    invoicesPsychologist: undefined,
    todoStatus: 'Active',
    userListStatus: 'All',
    userListTerm: '',
    BookingBillableCSV: '',
    PowerBIXlsx: '',
    invoiceStatus: 'All',
    approvalStatus: 'All',
    searchClientData: '',
    searchBookingData: '',
    searchClientDataType: 'All',
    providerApprovalStatus: 'Incomplete',
    interestedPsychologistsType: 'All',
    registeredPsychologistsType: 'All',
    invoiceStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
    invoiceEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
    geninvoiceStartDate: new Date(new Date().getFullYear(), new Date().getMonth()-1, 1).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
    geninvoiceEndDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
    clientInviteStartDate: new Date(new Date().getFullYear(), new Date().getMonth() - 4, 1).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
    clientInviteEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).toLocaleString("sv-SE", {dateStyle: 'short',timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
  }
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_CACHE': {
      return {
        ...state,
        cache: { ...state.cache, [action.payload.caller.type]: { payload: action.payload.caller.payload, resultCount: action.payload.count, timestamp: Date.now() } },
      }
    }
    case 'SET_PROGRESS_STATUS': {
      // console.log(action)
      return {
        ...state,
        progressStatus: { ...state.progressStatus, [action.payload.statusClientID]: { statusName: action.payload.statusName, statusValue: action.payload.statusValue } },
      }
    }
    case 'SET_REFERENCE': {
      return {
        ...state,
        [action.payload.referenceName]: action.payload.grid, 
      };
    }
    case 'INITALISE_INVITES': {
      return {
        ...state,
        ClientInvites: [],
      }
    }
    case 'SET_REPORT': {
      return {
        ...state, Reports: {...state.Reports,
          [action.payload.reportname]: action.payload.data, 
        }
      };
    }
    case 'SET_FILTERS': {
      // console.log(' *********** SET FILTERS ***********')
      // console.log(action.payload)
      return {
        ...state, Filters: {...state.Filters,
          [action.payload.filterName]: action.payload.filterValue, 
        }
      };
    }
    case 'SET_BOOKINGBILLABLECSV': {
      return{
        ...state, BookingBillableCSV: action.payload
      }
    }
    case 'SET_POWERBIXLSX': {
      return{
        ...state, PowerBIXlsx: action.payload
      }
    }
    case 'SET_MEDICALPRACTICES': {
      return {
        ...state, MedicalPractices: {
          ...state.MedicalPractices, [action.payload.type]: action.payload.data
        }
      };
    }
    case 'SET_SOURCE': {
      return {
        ...state,
        Source: action.payload
      }
    }
    case 'SET_CLIENTFUTUREBOOKINGSTATUS': {
      return {
        ...state,
        ClientFutureBookingStatus: action.payload,
      }
    }
    case 'SET_MEDICALPRACTIONERS': {
      return {
        ...state,
        MedicalPractitioners: action.payload, 
      };
    }
    case 'SET_INTERESTED': {
      return {
        ...state,
        InterestedPsychologists: action.payload, 
      };
    }
    case 'SET_BOOKINGTYPESTATISTICS' : {
      return {
        ...state,
        BookingTypeStatistics : action.payload,
      }
    }
    case 'SET_STATISTICS' : {
      return {
        ...state,
        Statistics : action.payload,
      }
    }
    case 'SET_CLIENTSBYDAY' : {
      return {
        ...state,
        ClientsByDay : action.payload,
      }
    }
    case 'SET_CLIENTREGISTRATIONSBYDAY' : {
      return {
        ...state,
        ClientRegistrationsByDay : action.payload,
      }
    }
    case 'SET_CLIENTSBYWEEK' : {
      return {
        ...state,
        ClientsByWeek : action.payload,
      }
    }
    case 'SET_REFERRERS' : {
      return {
        ...state,
        Referrers : action.payload,
      }
    }
    case 'SET_SEARCHRESULTS': {
      return {
        ...state,
        SearchResults: action.payload, 
      };
    }
    case 'SET_SEARCHBOOKINGRESULTS': {
      return {
        ...state,
        SearchBookingResults: action.payload, 
      };
    }
    case 'SET_REGISTERED': {
      return {
        ...state,
        RegisteredPsychologists: action.payload, 
      };
    }
    case 'SET_CLIENTREGISTERED': {
      return {
        ...state,
        ClientRegisteredPsychologists: action.payload, 
      };
    }
    case 'SET_REFERRALSVIEW': {
      return {
        ...state,
        ReferralsView: action.payload, 
      };
    }
    case 'SET_REFERRALS': {
      return {
        ...state,
        Referrals: action.payload, 
      };
    }
    case 'SET_FAXES': {
      return {
        ...state,
        Faxes: action.payload, 
      };
    }
    case 'SET_FAXPDF': {
      return {
        ...state,
        FaxPDF: action.payload.faxBlob, 
      };
    }
    case 'SET_CLIENTINVITES': {
      return {
        ...state,
        ClientInvites: action.payload, 
      };
    }
    case 'SET_CLIENTS': {
      return {
        ...state,
        Clients: action.payload, 
      };
    }
    case 'SET_CLIENT': {
      return {
        ...state,
        Client: action.payload, 
      };
    }
    case 'SET_ADMINUSER': {
      return {
        ...state,
        AdminUser: action.payload, 
      };
    }
    case 'SET_NOTES': {
      return {
        ...state,
        Notes: {...state.Notes, [action.payload.type]: action.payload.retData }, 
      };
    }
    case 'SET_FILES': {
      return {
        ...state,
        Files: {...state.Files, [action.payload.type]: action.payload.retData }, 
      };
    }
    case 'SET_TODOS': {
      return {
        ...state,
        ToDos: action.payload
      };
    }    
    case 'SET_ACTIVITYFEED': {
      return {
        ...state,
        ActivityFeed: action.payload
      };
    }    
    case 'SET_OPPORTUNITYFEED': {
      return {
        ...state,
        OpportunityFeed: action.payload
      };
    }
    case 'SET_AVAILABILITY': {
      return {
        ...state,
        Availability: action.payload, 
      };
    }
    case 'SET_BOOKINGS': {
      return {
        ...state,
        Bookings: action.payload, 
      };
    }
    case 'SET_BOOKINGBILLING': {
      return {
        ...state,
        BookingBilling: action.payload, 
      };
    }
    case 'SET_MEDIPASSINVOICES': {
      return {
        ...state,
        MedipassInvoices: action.payload,
      };
    }
    case 'SET_MEDIPASSCODES': {
      return {
        ...state,
        MedipassCodes: action.payload,
      };
    }
    case 'SET_CONTRACTS': {
      return {
        ...state,
        Contracts: action.payload,
      };
    }
    case 'SET_PROGRAMS': {
      return {
        ...state,
        Programs: action.payload,
      }
    }
    case 'SET_OPPORTUNITIES':{
      return {
        ...state,
        Opportunities: action.payload,
      }
    }
    case 'SET_BOOKING': {
      return {
        ...state,
        Booking: action.payload,
      };
    }
    case 'SET_BOOKINGSTATUS': {
      return {
        ...state,
        Booking: {
          ...state,
          status : action.payload.status
        }
      };
    }
    case 'SET_MEDIPASSCLIENTS': {
      return {
        ...state,
        MedipassProviderClients: action.payload,
      };
    }
    case 'SET_MEDIPASSBOOKINGDATES': {
      return {
        ...state,
        MedipassBookingDates: action.payload,
      };
    }
    case 'SET_MATCHING': {
      return {
        ...state,
        Matching: action.payload,
      };
    }
    case 'SET_MATCHINGAVAILABILITY': {
      return {
        ...state,
        MatchingAvailability : action.payload, 
      };
    }
    case 'SET_BILLABLE': {
      return {
        ...state,
        BillableIntents : action.payload, 
      };
    }
    case 'SET_BILLABLEALL': {
      return {
        ...state,
        BillableIntentsAll : action.payload, 
      };
    }
    case 'SET_BILLABLECONTRACT': {
      return {
        ...state,
        BillableIntentContracts : action.payload, 
      };
    }
    case 'SET_BILLABLENDIS': {
      return {
        ...state,
        BillableIntentNDIS : action.payload, 
      };
    }
    case 'SET_PROVIDERBOOKINGAPPROVAL': {
      return {
        ...state,
        ProviderBookingApproval : action.payload, 
      };
    }
    case 'SET_BILLABLECONTRACTPROVIDER': {
      return {
        ...state,
        BillableIntentContractsProvider : action.payload, 
      };
    }
    case 'SET_BILLABLENDISPROVIDER': {
      return {
        ...state,
        BillableIntentNDISProvider : action.payload, 
      };
    }
    case 'SET_SINGLEBILLABLE': {
      return {
        ...state,
        BillableSingleIntents : action.payload, 
      };
    }
    case 'SET_PAYMENTS': {
      return {
        ...state,
        Payments : action.payload, 
      };
    }
    case 'SET_INTENT': {
      return {
        ...state,
        Intent : action.payload, 
      };
    }
    case 'SET_XEROCONTACTLINK': {
      return {
        ...state,
        XeroLinkFailures : action.payload, 
      };
    }
    case 'SET_XERONDISCONTACTLINK': {
      return {
        ...state,
        XeroNDISLinkFailures : action.payload, 
      };
    }
    case 'SET_PAYMENTAPI': {
      return {
        ...state,
        PaymentAPI : action.payload, 
      };
    }
    case 'SET_INVOICELOG': {
      return {
        ...state,
        InvoiceLog : action.payload, 
      };
    }
    case 'SET_INITIAL': {
      return initialState;
    }
    case 'SET_COUNTER': {
      // console.log(action);
      return {
        ...state,
        FAXCounter: action.payload.faxStatus, 
        SMSCounter: action.payload.smsStatus, 
      };
    }
    case 'SET_TAGVALUES': {
      return {
        ...state,
        TagValues : {
          ...state.TagValues,
          [action.payload.context] : action.payload.tagValues
        }
      };
    }
    case 'SET_DISPLAYINVITE': {
      return {
        ...state, 
        DisplayInvite : action.payload.id, 
      };
    }
    case 'CLEAR_DISPLAYINVITE': {
      return {
        ...state, 
        DisplayInvite : null, 
      };
    }
    case 'CLEAR_CLIENT': {
      return {
        ...state, 
        Client: { id: 0 }, 
      };
    }
    case 'SET_PROVIDERPROGRAMBIO': {
      return {
        ...state,
        ProgramProviderBio : action.payload,
      }
    }
    default:
      return state;
  }
}

// Fetches Below 
export function* fetchCounter(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/counter";
    const response = yield axios.get(newURL);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_COUNTER', payload: response.data[0]});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchStatistics(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/statistics";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_STATISTICS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBookingTypeStatistics(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/bookingtypestatistics";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BOOKINGTYPESTATISTICS', payload: response.data[0]});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchInvoiceLog(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/invoicelog";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_INVOICELOG', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchContracts(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/contracts";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CONTRACTS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchPrograms(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/programs";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_PROGRAMS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchProviderProgramBio(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/providerprogrambio/" + action.payload.programID;
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_PROVIDERPROGRAMBIO', payload: response.data[0]});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchPayments(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/payments";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_PAYMENTS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMedipassInvoices(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/medipassinvoices";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MEDIPASSINVOICES', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMedipassCodes(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/medipasscodes";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MEDIPASSCODES', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMedipassClients(action) {
  // let currentCache = yield select(cache);
  // if(!isCached(currentCache, action)) {
  try 
  {
    const newURL = BASE_URL + "/medipassclients";
    const response = yield axios.get(newURL);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_MEDIPASSCLIENTS', payload: response.data});
      // yield put({ type: 'SET_CACHE', payload: { caller: action }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
  //}
}

export function* fetchMedipassBookingDates(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/medipassbookingdates/" + action.payload;
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MEDIPASSBOOKINGDATES', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntents(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintents";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLE', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntentsAll(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintentsall";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLEALL', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchPowerBIXlsx(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      yield put({ type: 'SET_POWERBIXLSX', payload: '' });
      const newURL = BASE_URL + "/powerbixlsx"
      const response = yield axios.get(newURL)
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
        process.env.REACT_APP_DEBUG && console.log(response.data);
        yield put({ type: 'SET_POWERBIXLSX', payload: response.data[0].exportCSV });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        toast.error(`Failed: ${action.type}`);
        yield put({ type: 'SET_POWERBIXLSX', payload: '' });
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
      } 
    } 
    catch (error) // API call itself has errored out
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'SET_POWERBIXLSX', payload: '' });
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBookingBillableCSV(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      yield put({ type: 'SET_BOOKINGBILLABLECSV', payload: '' });
      const newURL = BASE_URL + "/bookingbillableCSV";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
        process.env.REACT_APP_DEBUG && console.log(response.data);
        yield put({ type: 'SET_BOOKINGBILLABLECSV', payload: response.data[0].exportCSV });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        toast.error(`Failed: ${action.type}`);
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
      } 
    } 
    catch (error) // API call itself has errored out
    {
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntentsContract(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintentscontract";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLECONTRACT', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntentsNDIS(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintentsndis";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLENDIS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchProviderBookingApproval(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/providerbookingapproval";
      const response = yield axios.get(newURL)
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_PROVIDERBOOKINGAPPROVAL', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntentsContractProvider(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintentscontractprovider";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLECONTRACTPROVIDER', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBillableIntentsNDISProvider(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/billableintentsndisprovider";
      const response = yield axios.put(newURL, action.payload);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BILLABLENDISPROVIDER', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchSingleBillableIntents(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/singlebillableintents";
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_SINGLEBILLABLE', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMedicalPractices(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/medicalpractices/" + action.payload.type;
      const response = yield axios.get(newURL);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MEDICALPRACTICES', payload: { type: action.payload.type, data: response.data }});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchSource(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + '/source'
      const response = yield axios.get(newURL)
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_SOURCE', payload: response.data})
        yield put({ type: 'SET_CACHE', payload: { caller: action }})
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } })
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } })
    }
  }
}

export function* fetchMedicalPractitioners(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/medicalpractitioners";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if (response)
      {
        if(response.status === 200 && response.data.returnStatus !== "Error") 
        {
          yield put({ type: 'SET_MEDICALPRACTIONERS', payload: response.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        } 
        else // API completed with 200, however there is an error message
        {
          yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
        }
      }
      else
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
        // console.log(response); 
      }
    } 
    catch (error) // API call itself has errored out
    {
      // console.log(error);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchInterested(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/interested";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_INTERESTED', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchReferrers(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/referrers";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERRERS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchFaxes(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/inboundfaxes";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_FAXES', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
} 

export function* fetchFaxPDF(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/faxpdf/"+action.payload;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_FAXPDF', payload: response.data[0]});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchRegistered(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/registered" ;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REGISTERED', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientRegistered(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/clientregistered/" + action.payload.providertype;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTREGISTERED', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientFutureBookingStatus(action) {
  try 
  {
    const newURL = BASE_URL + "/clientfuturebookingstatus/" + action.payload.providertype;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_CLIENTFUTUREBOOKINGSTATUS', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchReferrals(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/referrals";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERRALS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientsByDay(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/clientsbyday";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTSBYDAY', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientRegistrationsByDay(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/clientregistrationsbyday";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTREGISTRATIONSBYDAY', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientsByWeek(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/clientsbyweek";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTSBYWEEK', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchReferralsView(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/referralsview/" + action.payload.type + "/" + action.payload.id;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERRALSVIEW', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchClientInvites(action) {
  // Disable caching as it causing issue with the global search (i.e. the record is in cache though not in REDUX - may need to review this later)
  // let currentCache = yield select(cache);
  // if (!isCached(currentCache, action) || (action.payload.clearcache && action.payload.clearcache === 'Y')) {
    try 
    {
      console.log('Getting invite data')
      const newURL = BASE_URL + "/clientinvites";
      const response = yield axios.put(newURL, action.payload);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTINVITES', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  // }
}

export function* fetchClients(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/clients";
      const response = yield axios.put(newURL, action.payload ? action.payload : { status: 'Active' });
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_CLIENTS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
        if(action.payload.progressStatusPayload)
        {
          yield put({ type: 'SET_PROGRESS_STATUS', payload: action.payload.progressStatusPayload});
        }        
      } 
      // else if(response.status !== 400) // API completed with 200, however there is an error message, ignoring 400 errors when not authorised
      else
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      // if(error.message.indexOf("Request failed with status code 400") === -1)
      // {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
      // }
    }
  }
}

export function* fetchClient(action) {
  try 
  {
    const newURL = BASE_URL + "/client/" + action.payload.clientID + "/" + action.payload.type;
    const response = yield axios.get(newURL, action.payload ? action.payload : { tagValues: [] });
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_CLIENT', payload: response.data[0]});
    } 
    // else if(response.status !== 400) // API completed with 200, however there is an error message, ignoring 400 errors when not authorised
    else
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    // if(error.message.indexOf("Request failed with status code 400") === -1)
    // {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    // }
  }
}

export function* fetchAdminUser(action) {
  try 
  {
    const newURL = BASE_URL + "/adminuser/";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_ADMINUSER', payload: response.data[0]});
    } 
    // else if(response.status !== 400) // API completed with 200, however there is an error message, ignoring 400 errors when not authorised
    else
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    // if(error.message.indexOf("Request failed with status code 400") === -1)
    // {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    // }
  }
}

export function* fetchAvailability(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/availability/" + action.payload.psychologistid + "/" + action.payload.userTZ;
      // *********************************************************************************************************
      // This is debug code to catch an undefined psychologistid being sent - 2021-06-15
      // *********************************************************************************************************
      if (action.payload.psychologistid)
      {
        const response = yield axios.get(newURL);
        process.env.REACT_APP_DEBUG && console.log(response);
        if(response.status === 200 && response.data.returnStatus !== "Error") 
        {
          yield put({ type: 'SET_AVAILABILITY', payload: response.data});
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        } 
        else // API completed with 200, however there is an error message
        {
          yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
        }
      }
      else
      {
        // ok custom error time 
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: Custom Error - Packet Not Sent, ReturnStatus: `, Message : 'URL sent: ' + newURL } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchBookingBilling(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      // console.log('Booking Billing')
      // console.log(action);
      const newURL = BASE_URL + "/bookingbilling/" + action.payload.clientID;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BOOKINGBILLING', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'XExtra Debug' , Message: 'Raw: ' + JSON.stringify(action) } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'XExtra Debug' , Message: 'Raw: ' + JSON.stringify(action) } });
    }
  }
}

export function* fetchBookings(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/bookings/" + action.payload.key + "/" + action.payload.id;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BOOKINGS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'XExtra Debug' , Message: 'Raw: ' + JSON.stringify(action) } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'XExtra Debug' , Message: 'Raw: ' + JSON.stringify(action) } });
    }
  }
}

export function* fetchBooking(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/booking/" + action.payload.bookingid;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_BOOKING', payload: response.data[0]});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMatching(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      //console.log(action.payload)
      const newURL = BASE_URL + "/matching/" + action.payload.providertype + "/" + action.payload.invite + "/" + action.payload.availabilitytype;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MATCHING', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchMatchingAvailability(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/matchingavailability/" + action.payload.psychologistID + "/" + action.payload.userTZ + "/" + action.payload.availabilityType + "/" + action.payload.invite;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MATCHINGAVAILABILITY', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored with the psychologist of ' + action.payload.psychologistID + ' and timezone of ' + action.payload.userTZ, Message : error.message } });
    }
  }
}

export function* fetchIntent(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    // This is not ideal - to fetch the intent I need to send a few variables to the API - these need to be sent in the URL - all very message though GET cannot have body

    const intentVars = action.payload.referenceID + "/" + action.payload.name + "/" + action.payload.email + "/" + action.payload.mobile;
    const encodedVars = encodeURI(intentVars);
    const newURL = BASE_URL + "/intent/" + encodedVars;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_INTENT', payload: response.data.intentReference});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchPaymentAPI(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/paymentapi";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_PAYMENTAPI', payload: response.data});
    } 
    else // API completed with 200, however there is an error message
    {
      if (response.data.returnText === 'Tyro Authorisation Failure')
      {
        toast.error(`Action Failed: ${response.data.returnText}`);
      }
      else
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchNotes(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/notes/" + action.payload.type + "/" + action.payload.pkID;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_NOTES', payload: { type: action.payload.type, retData: response.data } });
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchFiles(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/files/" + action.payload.type + "/" + action.payload.pkID;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_FILES', payload: { type: action.payload.type, retData: response.data } });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchToDos(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/todos/" + action.payload.clientID;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_TODOS', payload: response.data });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchActivityFeed(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/activityfeed";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(`${action.type}: ${newURL}`)
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_ACTIVITYFEED', payload: response.data });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchOpportunityFeed(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/opportunityfeed";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(`${action.type}: ${newURL}`)
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_OPPORTUNITYFEED', payload: response.data });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchOpportunities(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/opportunities";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(`${action.type}: ${newURL}`)
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_OPPORTUNITIES', payload: response.data });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchResourceItems(action)
{
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/resourceitems";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERENCE', payload: { referenceName: 'ResourceItems', grid: response.data } });
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchHelpItems(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/helpitems";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERENCE', payload: { referenceName: 'HelpItems', grid: response.data } });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchDashboardNews(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/dashboardnews";
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_REFERENCE', payload: { referenceName: 'DashboardNews', grid: response.data } });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchReport(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/report/" + action.payload.reportname;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        if (response.data[0].result !== null)
        {
          if(action.payload.output === 'collection')
          {
            const switchDataType = (item) => {
                if (item.DataType === 'Numeric') {
                  item.Value = parseFloat(item.Value)
                }
                return item.Value
            }
            yield put({ type: 'SET_REPORT', payload: { reportname: action.payload.reportname, data: JSON.parse(response.data[0].result).reduce((obj, item) => Object.assign(obj, { [item.Name]: switchDataType(item) }), {} ) }});
          }
          else
          {      
            yield put({ type: 'SET_REPORT', payload: { reportname: action.payload.reportname, data: JSON.parse(response.data[0].result).map((item) => ({...item})) }});
          }
          yield put({ type: 'SET_CACHE', payload: { caller: action }});
        }
        else
        {
          console.log('Returned Null for ' + action.payload.reportname);
        }
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReportName: ${action.payload.reportname}, ReturnStatus: ${response.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, API call has errored, ReportName: ${action.payload.reportname}`, Message : error.message } });
    }
  }
}

export function* fetchTagValues(action) {
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) {
    try 
    {
      const newURL = BASE_URL + "/tagvalues/" + action.payload.context + "/" + action.payload.fkID;
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
      const response = yield axios.get(newURL);    
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
        yield put({ type: 'SET_TAGVALUES', payload: { context: action.payload.context.replaceAll(" ", ""), fkID: action.payload.fkID, tagValues: response.data } });
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        toast.error(`Fetch Failed: ${response.data.returnText}`);
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }  
    } 
    catch (error) // API call itself has errored out
    {
      toast.error("Fetch Failed: " + action.type);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchSearchClientData(action) {
  // a put disguised as a fetch 
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) 
  {
    try 
    {
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
      const newURL = BASE_URL + "/searchclientdata"
      var response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        // toast.success(response.data.returnText,{ autoClose:5000 });
        yield put({ type: 'SET_SEARCHRESULTS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        toast.error(`Search Failed: ${response.data.returnText}`);
        yield put({ type: 'SET_SEARCHRESULTS', payload: []});
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      toast.error("Search Failed: " + action.type);
      yield put({ type: 'SET_SEARCHRESULTS', payload: []});
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

export function* fetchSearchBookingData(action) {
  // a put disguised as a fetch 
  let currentCache = yield select(cache);
  if(!isCached(currentCache, action)) 
  {
    try 
    {
      process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
      const newURL = BASE_URL + "/searchbookingdata"
      var response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        // toast.success(response.data.returnText,{ autoClose:5000 });
        yield put({ type: 'SET_SEARCHBOOKINGRESULTS', payload: response.data});
        yield put({ type: 'SET_CACHE', payload: { caller: action }});
      } 
      else // API completed with 200, however there is an error message
      {
        toast.error(`Search Failed: ${response.data.returnText}`);
        yield put({ type: 'SET_SEARCHBOOKINGRESULTS', payload: []});
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    } 
    catch (error) // API call itself has errored out
    {
      toast.error("Search Failed: " + action.type);
      yield put({ type: 'SET_SEARCHBOOKINGRESULTS', payload: []});
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    }
  }
}

// Put's below 

export function* putReferral(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/referral"
    var response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      toast.success(response.data.returnText,{ autoClose:5000 });
      yield put({ type: 'FETCH_REFERRALSVIEW', payload: { type: action.payload.refresh.payload.type, id: action.payload.refresh.payload.id, popCache: true } });
      // yield put({ type: 'FETCH_REFERRALS'});
      yield put({ type: 'FETCH_MEDICALPRACTICES', payload: { type: 'GeneralPractitioner', popCache: true }});
      yield put({ type: 'FETCH_MEDICALPRACTITIONERS', payload: { popCache: true }});
      // yield put({ type: 'FETCH_CLIENTS'});
      // yield put({ type: 'FETCH_FAXES'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putReferralStatus(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/referralstatus"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Status Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_REFERRALSVIEW', payload: { type: action.payload.refresh.payload.type, id: action.payload.refresh.payload.id, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putOpportunityInterest(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientopportunityinterest"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Status Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_OPPORTUNITYFEED', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putFaxStatus(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/faxstatus"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      // toast.success("Status Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClient(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/client"
    var response = {}
    if (action.payload.id > 0) {
      response = yield axios.put(newURL,action.payload);
    } else {
      response = yield axios.post(newURL,action.payload);
    }
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Client",{ autoClose:5000 });
      yield put({ type: 'FETCH_MEDICALPRACTICES', payload: { type: 'GeneralPractitioner', popCache: true }});
      yield put({ type: 'FETCH_MEDICALPRACTITIONERS', payload: { popCache: true }});
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});
      yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putMedipassCodes(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/medipasscodes"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Code",{ autoClose:5000 });
      yield put({ type: 'FETCH_MEDIPASSCODES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putContract(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/contract"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Contract",{ autoClose:5000 });
      yield put({ type: 'FETCH_CONTRACTS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putProgram(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/program"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Program",{ autoClose:5000 });
      yield put({ type: 'FETCH_PROGRAMS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putOpportunity(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/opportunity"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Opportunity",{ autoClose:5000 });
      yield put({ type: 'FETCH_OPPORTUNITIES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putProviderProgramBio(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/providerprogrambio"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Bio",{ autoClose:5000 });
      yield put({ type: 'FETCH_PROVIDERPROGRAMBIO', payload: { programID: action.payload.id, popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putBookingBilling(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/bookingbilling"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Billing Details",{ autoClose:5000 });
      // 
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.invoiceStartDate;
      const endDate = filterstate.invoiceEndDate;
      const billableStatus = filterstate.invoiceStatus;
      if (action.payload.refresh && action.payload.refresh === 'ALL') {
        yield put({ type: 'FETCH_BILLABLEALL', payload: { startDate: startDate, endDate: endDate, billableStatus: billableStatus, popCache: true }});
      } else {
        yield put({ type: 'FETCH_BILLABLE', payload: { startDate: startDate, endDate: endDate, billableStatus: billableStatus, popCache: true }});
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putMedipassInvoices(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/medipassinvoices"
    var response = {}
    response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved Code",{ autoClose:5000 });
      yield put({ type: 'FETCH_MEDIPASSINVOICES', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putInvoiceStatus(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/invoicestatus"
    var response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_PAYMENTS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putBillableEmails(action) {
  // **********************************
  // Not curently in use
  // **********************************
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/billableemails"
    var response = yield axios.put(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Sent",{ autoClose:5000 });
      yield put({ type: 'FETCH_BILLABLE', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Send Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Send Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postNote(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/note"
    var response = yield axios.post(newURL,action.payload.formData);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_NOTES', payload: { type: action.payload.formData.get('type'), pkID: action.payload.formData.get('pkid'), popCache: true} });
      yield put({ type: 'FETCH_FILES', payload: { type: action.payload.formData.get('type'), pkID: action.payload.formData.get('pkid'), popCache: true } });
      yield put({ type: 'FETCH_COUNTER'});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      if (response.data.returnText && !(response.data.returnText.includes('is not a mobile number')))
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'Extra Debug' , Message: 'Type: ' + action.payload.formData.get('type') + ' Pkid: ' + action.payload.formData.get('pkid') } });
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'XExtra Debug' , Message: 'Raw: ' + JSON.stringify(Object.fromEntries(action.payload.formData)) } });
  }
}

export function* postClientNote(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientnote"
    var response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_NOTES', payload: { type: 'Client', pkID: action.payload.pkid, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postFile(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/files"
    var response = yield axios.post(newURL,action.payload.formData);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_FILES', payload: { type: action.payload.type, pkID: action.payload.pkID, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, API call has errored here is the payload ${action}`, Message : error.message } });
  }
}

export function* deleteFile(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/deletefile"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_FILES', payload: { type: action.payload.type, pkID: action.payload.pkID, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* setClientViewableFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/setClientViewableFlag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_FILES', payload: { type: action.payload.type, pkID: action.payload.pkID, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreateInvoices(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createinvoices"
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invoices Sent to Xero",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.geninvoiceStartDate;
      const endDate = filterstate.geninvoiceEndDate;
      yield put({ type: 'FETCH_BILLABLE', payload: { startDate: startDate, endDate: endDate, status: 'Unbilled', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invoice Generation Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invoice Generation Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreateInvoicesContract(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createinvoicescontract"
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invoices Sent to Xero",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.geninvoiceStartDate;
      const endDate = filterstate.geninvoiceEndDate;
      yield put({ type: 'FETCH_BILLABLECONTRACT', payload: { startDate: startDate, endDate: endDate, status: 'Unbilled', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invoice Generation Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invoice Generation Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreateInvoicesNDIS(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createinvoicesndis"
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invoices Sent to Xero",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.geninvoiceStartDate;
      const endDate = filterstate.geninvoiceEndDate;
      yield put({ type: 'FETCH_BILLABLENDIS', payload: { startDate: startDate, endDate: endDate, status: 'Unbilled', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invoice Generation Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invoice Generation Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreateInvoicesNDISProvider(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createpaymentsndisprovider"
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Payments Sent to Xero",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.geninvoiceStartDate;
      const endDate = filterstate.geninvoiceEndDate;
      yield put({ type: 'FETCH_BILLABLENDISPROVIDER', payload: { startDate: startDate, endDate: endDate, status: 'Not Paid', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Payment Invoice Generation Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Payment Invoice Generation Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreateInvoicesContractProvider(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createpaymentscontractprovider"
    const response = yield axios.put(newURL, action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Payments Sent to Xero",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.geninvoiceStartDate;
      const endDate = filterstate.geninvoiceEndDate;
      yield put({ type: 'FETCH_BILLABLECONTRACTPROVIDER', payload: { startDate: startDate, endDate: endDate, status: 'Not Paid', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Payment Invoice Generation Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Payment Invoice Generation Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putXeroContactLink(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/xerocontactlink"
    var response = yield axios.put(newURL,'');
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_XEROCONTACTLINK', payload: response.data });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putXeroNDISContactLink(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/xerondiscontactlink"
    var response = yield axios.put(newURL,'');
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_XERONDISCONTACTLINK', payload: response.data });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCreatePayments(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/createpayments"
    var response = yield axios.put(newURL,'');
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Payments Created",{ autoClose:5000 });
      yield put({ type: 'FETCH_PAYMENTS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putMedicalPractitioner(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/medicalpractitioner"
    // if id is real then put, otherwise post
    var response = {}
    if(action.payload.id > 0) 
    {
      process.env.REACT_APP_DEBUG && console.log('Put medicalpractitioner');
      response = yield axios.put(newURL,action.payload);
    } 
    else 
    {
      process.env.REACT_APP_DEBUG && console.log('Post medicalpractitioner');
      response = yield axios.post(newURL,action.payload);
    }
    // Process response
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_MEDICALPRACTITIONERS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }   
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putMedicalPractice(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/medicalpractice"
    // if id is real then put, otherwise post
    var response = {}
    if(action.payload.formData.get('id') > 0) {
      response = yield axios.put(newURL,action.payload.formData);
    } else {
      response = yield axios.post(newURL,action.payload.formData);
    }
    // Process response
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_MEDICALPRACTICES', payload: { type: 'All', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }   
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putInterestedStatus(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/interestedstatus";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_INTERESTED', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putApproveBooking(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/approvebooking";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Updated",{ autoClose:5000 });
      yield put({ type: 'FETCH_PROVIDERBOOKINGAPPROVAL', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Update Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Update Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putRHCInvoicedBooking(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/rhcinvoicedbooking";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Updated",{ autoClose:5000 });
      yield put({ type: 'FETCH_PROVIDERBOOKINGAPPROVAL', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Update Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Update Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putBookingNote(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/bookingnote";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Updated",{ autoClose:5000 });
      yield put({ type: 'FETCH_PROVIDERBOOKINGAPPROVAL', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Update Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Update Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putPsychologistDetail(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    process.env.REACT_APP_DEBUG && console.log(...action.payload.formData);
    const newURL = BASE_URL + "/psychologistdata"
    const response = yield axios.put(newURL,action.payload.formData);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true } });
      yield put({ type: 'FETCH_MEDICALPRACTICES', payload: { type: 'Psychologist', popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* chgProviderAOI(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/chgprovideraoi"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postPsychologistInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/psychologistinvite"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invite Sent",{ autoClose:5000 });
      yield put({ type: 'FETCH_INTERESTED', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invite Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invite Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putReferrer(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/referrer"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Referrer Updated",{ autoClose:5000 });
      yield put({ type: 'FETCH_REFERRERS', payload: {popCache: true} });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invite Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invite Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deletePsychologistInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/psychologistinvite/" + action.payload;
    const response = yield axios.delete(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok deleted');
      toast.success("Deleted",{ autoClose:5000 });
      // Currently not refetching on delete as calendar state should be able to handle delete and slot id not needed 
      yield put({ type: 'FETCH_INTERESTED', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postPsychologistDetail(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/psychologistdata"
    const response = yield axios.post(newURL,action.payload.formData);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendPsychologistReferral(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sendpsychologistreferral"
    const response = yield axios.post(newURL,action.payload);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Referral Sent",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Referral Not Sent: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Referral Not Sent: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendPsychologistInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sendpsychologistinvite"
    const response = yield axios.post(newURL,action.payload);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invite Sent",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invite Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invite Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postClientInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientinvite"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
      yield put({ type: 'SET_DISPLAYINVITE', payload: { id : response.data.newID }});
      yield put({ type: 'FETCH_SOURCE', payload: { popCache: true}})
      if(action.payload.faxid > 0){
        yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientInvite(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientinvite";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
      yield put({ type: 'FETCH_SOURCE', payload: { popCache: true}})
      if(action.payload.faxid > 0){
        yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendClientInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sendclientinvite"
    const response = yield axios.post(newURL,action.payload);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Invite Sent",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Invite Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Invite Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendCallUnsuccessfulReminder(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sendcallunsuccessfulreminder"
    const response = yield axios.post(newURL,action.payload);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Final Reminder Sent",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Final Reminder Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Final Reminder Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendClientInviteReminder(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/sendclientinvitereminder"
    const response = yield axios.post(newURL,action.payload);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Reminder Sent",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Reminder Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Reminder Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteClientInvite(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientinvite/" + action.payload;
    const response = yield axios.delete(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok deleted');
      toast.success("Deleted",{ autoClose:5000 });
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientDetail(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientdata"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'SET_CLIENT', payload: initialState.Client });
      yield put({ type: 'FETCH_MEDICALPRACTICES', payload: { type: 'GeneralPractitioner', popCache: true }});
      yield put({ type: 'FETCH_MEDICALPRACTITIONERS', payload: { popCache: true }});
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});
      yield put({ type: 'FETCH_SOURCE', payload: { popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putAdminUser(action) {
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/adminuser"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_ADMINUSER', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postClientDetail(action) {
  // Note this no longer used - clients cannot be added except via the registration process
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientdata"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putCard(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/card"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Card Saved",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putProviderPassword(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/providerpassword"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Password Updated",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(response.data.returnText);
      // Removed you got your original password wrong errors from being reported.
      // yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Password Update Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putApikey(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/apikey"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("APIKey Saved",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientTaskFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clienttaskflag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true, type : action.payload.type, progressStatusPayload: { statusClientID: action.payload.id, statusName: action.payload.flagChange, statusValue: 'Complete' } }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientflag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true, type : action.payload.type }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putInterestedProviderFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/interestedproviderflag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_INTERESTED', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putResetInterestedProviders(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/resetinterestedproviders"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      if (action.payload.type !== 'Admin') {
        yield put({ type: 'FETCH_CLIENT', payload: { popCache: true, clientID : action.payload.clientID, type : action.payload.type }});
      } else {
        yield put({ type: 'FETCH_OPPORTUNITIES', payload: { popCache: true }});
      }

    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putArchiveClient(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/archiveclient"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true, status : action.payload.action }})
      yield put({ type: 'SET_FILTERS', payload: { filterName: 'clientsStatus', filterValue: action.payload.changetype }})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Update Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Update Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientInviteFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientinviteflag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      const filters = (state) => state.admin.Filters;
      const filterstate = yield select(filters)
      const startDate = filterstate.clientInviteStartDate;
      const endDate = filterstate.clientInviteEndDate;
      yield put({ type: 'FETCH_CLIENTINVITES', payload: { startDate: startDate, endDate: endDate, popCache: true}})
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putFaxFlag(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/faxflag"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putFaxClientName(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/faxclientname"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      yield put({ type: 'FETCH_FAXES', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putClientPsychologist(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientpsychologist"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Psychologist Selection Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postBooking(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/booking"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Booking Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_BOOKINGS', payload: { key: action.payload.refreshPayload.key, id: action.payload.refreshPayload.id, popCache: true } });
      // Determine which client list to retrieve may have come from the admin calendar
      if (action && action.payload && action.payload.refreshPayload.id && action.payload.refreshPayload.key === 'psychologist')
      {
        yield put({ type: 'FETCH_CLIENTS', payload: { status: 'Active', providerId: action.payload.refreshPayload.id, popCache: true }})
      }
      else
      {
        yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});  
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* sendBookingReminder(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/bookingreminder"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Reminder Sent",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Reminder Not Sent: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Reminder Not Sent: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putBooking(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/booking"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Booking Saved",{ autoClose:5000 });
      // Note this call is also used by GatherBillable.js 
      // Check if it was called by GatherBillable.js
      if (action.payload.refreshPayload.key === 'Billable')
      {
        toast.success("Booking Updated",{ autoClose:5000 });
        yield put({ type: 'FETCH_BILLABLE', payload: { popCache: true } });
      }
      else
      {
        if (action.payload.refreshPayload.key === 'BillableSingle')
        {
          toast.success("Booking Updated",{ autoClose:5000 });
          yield put({ type: 'FETCH_SINGLEBILLABLE', payload: { popCache: true } });
        }
        else
        {
          // It was called by one of the booking pages 
          toast.success("Booking Saved",{ autoClose:5000 });
          yield put({ type: 'FETCH_BOOKINGS', payload: { key: action.payload.refreshPayload.key, id: action.payload.refreshPayload.id, popCache: true } });
          if (action && action.payload && action.payload.refreshPayload.id && action.payload.refreshPayload.key === 'psychologist')
          {
            yield put({ type: 'FETCH_CLIENTS', payload: { status: 'Active', providerId: action.payload.refreshPayload.id, popCache: true }})
          }
          else
          {
            yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true }});  
          }
          // Check to see if the booking needs to be loaded again 
          if (action.payload.refreshPayload.bookingid)
          {
            yield put({ type: 'FETCH_BOOKING', payload: { bookingid: action.payload.refreshPayload.bookingid, popCache: true } });
          }
        }
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putBillableBooking(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/booking"
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Booking Updated",{ autoClose:5000 });
      yield put({ type: 'FETCH_SINGLEBILLABLE', payload : { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postAvailability(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/availability"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'FETCH_AVAILABILITY', payload: {psychologistid: action.payload.mainID, userTZ: action.payload.userTZ, popCache: true }});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'POST_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putAvailability(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/availability";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      // Kendo scheduler can be linked to state, so we refresh now... Old(Currently not refetching on change as calendar state should be able to handle change and slot id not needed )
      yield put({ type: 'FETCH_AVAILABILITY', payload: { psychologistid: action.payload.mainID, userTZ: action.payload.userTZ, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteAvailability(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/availability/" + action.payload.slot;
    const response = yield axios.delete(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Deleted",{ autoClose:5000 });
      // Currently not refetching on delete as calendar state should be able to handle delete and slot id not needed 
      yield put({ type: 'FETCH_AVAILABILITY', payload: { psychologistid: action.payload.mainID, userTZ: action.payload.userTZ, popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* chgBulkBillingAvailability(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/bulkbillingavailability/" + action.payload.id + "/" + action.payload.bulkBillingAvailability;
    process.env.REACT_APP_DEBUG && console.log(newURL);
    const response = yield axios.put(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Bulk billing availability updated.",{ autoClose:5000 });
      // Currently not refetching on delete as calendar state should be able to handle delete and slot id not needed 
      yield put({ type: 'FETCH_REGISTERED', payload: { popCache: true } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putToDo(action) {
  try 
  {
    const newURL = BASE_URL + "/todo/"
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload);  
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      process.env.REACT_APP_DEBUG && console.log(response.data);
      yield put({ type: 'FETCH_TODOS', payload: { clientID: action.payload.todo.clientID, popCache: true } });
      yield put({ type: 'FETCH_CLIENTS', payload: { tagValues: action.payload.tagValues, type: action.payload.clientType, popCache: true } });
    }
    else // API completed with 200, however there is an error message
    {
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.error(`Failed: ${action.type}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putTagValues(action) {
  try 
  {
    const newURL = BASE_URL + "/tagvalues";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload);   
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);
      yield put({ type: 'SET_TAGVALUES', payload: { context: action.payload.context, fkID: action.payload.fkID, tagValues: action.payload.tagValues, popCache: true } })
      switch(action.payload.context)
      {
        case 'Client': 
          yield put({ type: 'FETCH_CLIENTS', payload: { popCache: true } })
          break
        default: 
      }
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }  
  } 
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putError(action) {
  process.env.REACT_APP_DEBUG && console.log('Saving Error content');
  // ok lets update the server
  try 
  {
    const newURL = BASE_URL + "/error";
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    // Check response
    if (response.status === 200) 
    {
      const retData = response;
      if (retData.data.returnStatus === 'Error') 
      {
        // Report error
        // Note in this case as the error logging failed, the errors will be written to console 
        console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')');
      }
      else 
      {
        process.env.REACT_APP_DEBUG && console.log('ok saved');
      }
    } 
    else 
    {
      // Report error
      const retData = response;
      // Note in this case as the error logging failed, the errors will be written to console 
      console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')');
    }
  } 
  catch (error)
  {
    // Note in this case as the error logging failed, the errors will be written to console 
    console.log('error: Warning Cannot Save Task Grid (Code - SVR)');
    console.log(error)
  }
}

export const actions = {
  fetchMedicalPractices:                 (payload) => ({ type: actionTypes.FETCH_MEDICALPRACTICES, payload: payload }),
  fetchMedicalPractitioners:             (payload) => ({ type: actionTypes.FETCH_MEDICALPRACTITIONERS, payload: payload  }),
  fetchSource:                           (payload) => ({ type: actionTypes.FETCH_SOURCE, payload: payload  }),
  fetchInterested:                       (payload) => ({ type: actionTypes.FETCH_INTERESTED, payload: payload  }),
  fetchRegistered:                       (payload) => ({ type: actionTypes.FETCH_REGISTERED, payload: payload  }),
  fetchClientRegistered:                 (payload) => ({ type: actionTypes.FETCH_CLIENTREGISTERED, payload: payload  }),
  fetchReferralsView:                    (payload) => ({ type: actionTypes.FETCH_REFERRALSVIEW, payload : payload }),
  fetchReferrals:                        (payload) => ({ type: actionTypes.FETCH_REFERRALS, payload: payload  }),
  fetchFaxes:                            (payload) => ({ type: actionTypes.FETCH_FAXES, payload: payload  }),
  fetchClientInvites:                    (payload) => ({ type: actionTypes.FETCH_CLIENTINVITES, payload: payload  }),
  fetchClients:                          (payload) => ({ type: actionTypes.FETCH_CLIENTS, payload: payload }),
  fetchClient:                           (payload) => ({ type: actionTypes.FETCH_CLIENT, payload: payload }),
  fetchAvailability:                     (payload) => ({ type: actionTypes.FETCH_AVAILABILITY, payload : payload }),
  fetchBookings:                         (payload) => ({ type: actionTypes.FETCH_BOOKINGS, payload : payload }),
  fetchBookingBilling:                   (payload) => ({ type: actionTypes.FETCH_BOOKINGBILLING, payload : payload }),
  fetchBooking:                          (payload) => ({ type: actionTypes.FETCH_BOOKING, payload : payload }),
  fetchMatching:                         (payload) => ({ type: actionTypes.FETCH_MATCHING, payload : payload }),
  fetchMatchingAvailability:             (payload) => ({ type: actionTypes.FETCH_MATCHINGAVAILABILITY, payload : payload }),
  fetchFaxPDF:                           (payload) => ({ type: actionTypes.FETCH_FAXPDF, payload : payload }),
  fetchStripeIntent:                     (payload) => ({ type: actionTypes.FETCH_INTENT, payload : payload }),
  fetchPayments:                         (payload) => ({ type: actionTypes.FETCH_PAYMENTS, payload : payload }),
  fetchBillableIntents:                  (payload) => ({ type: actionTypes.FETCH_BILLABLE, payload : payload }),
  fetchBillableIntentsAll:               (payload) => ({ type: actionTypes.FETCH_BILLABLEALL, payload : payload }),
  fetchBillableIntentsContract:          (payload) => ({ type: actionTypes.FETCH_BILLABLECONTRACT, payload : payload }),
  fetchBillableIntentsNDIS:              (payload) => ({ type: actionTypes.FETCH_BILLABLENDIS, payload : payload }),
  fetchBillableIntentsContractProvider:  (payload) => ({ type: actionTypes.FETCH_BILLABLECONTRACTPROVIDER, payload : payload }),
  fetchBillableIntentsNDISProvider:      (payload) => ({ type: actionTypes.FETCH_BILLABLENDISPROVIDER, payload : payload }),
  fetchSingleBillable:                   (payload) => ({ type: actionTypes.FETCH_SINGLEBILLABLE, payload: payload  }),
  fetchNotes:                            (payload) => ({ type: actionTypes.FETCH_NOTES, payload : payload }),
  fetchFiles:                            (payload) => ({ type: actionTypes.FETCH_FILES, payload : payload }),
  fetchPaymentAPI:                       (payload) => ({ type: actionTypes.FETCH_PAYMENTAPI, payload : payload }),
  fetchMedipassInvoices:                 (payload) => ({ type: actionTypes.FETCH_MEDIPASSINVOICES, payload: payload  }),
  fetchMedipassCodes:                    (payload) => ({ type: actionTypes.FETCH_MEDIPASSCODES, payload: payload  }),
  fetchMedipassClients:                  (payload) => ({ type: actionTypes.FETCH_MEDIPASSCLIENTS, payload: payload  }),
  fetchMedipassBookingDates:             (payload) => ({ type: actionTypes.FETCH_MEDIPASSBOOKINGDATES, payload : payload }),
  fetchToDos:                            (payload) => ({ type: actionTypes.FETCH_TODOS, payload : payload }),
  fetchResourceItems:                    (payload) => ({ type: actionTypes.FETCH_RESOURCEITEMS, payload : payload }),
  fetchHelpItems:                        (payload) => ({ type: actionTypes.FETCH_HELPITEMS, payload: payload  }),
  fetchDashboardNews:                    (payload) => ({ type: actionTypes.FETCH_DASHBOARDNEWS, payload: payload  }),
  fetchReferrers:                        (payload) => ({ type: actionTypes.FETCH_REFERRERS, payload: payload  }),
  fetchBookingTypeStatistics:            (payload) => ({ type: actionTypes.FETCH_BOOKINGTYPESTATISTICS, payload: payload  }),
  fetchClientFutureBookingStatus:        (payload) => ({ type: actionTypes.FETCH_CLIENTFUTUREBOOKINGSTATUS, payload: payload  }),
  searchClientData:                      (payload) => ({ type: actionTypes.FETCH_SEARCHCLIENTDATA, payload: payload  }),
  searchBookingData:                     (payload) => ({ type: actionTypes.FETCH_SEARCHBOOKINGDATA, payload: payload  }),
  initialiseInvites:                     (payload) => ({ type: actionTypes.INITALISE_INVITES, payload: payload }), 
  fetchOpportunities:                    (payload) => ({ type: actionTypes.FETCH_OPPORTUNITIES, payload: payload  }),
  putOpportunity:                        (payload) => ({ type: actionTypes.PUT_OPPORTUNITY, payload: payload  }),

  postClientInvite:                      (payload) => ({ type: actionTypes.POST_CLIENTINVITE, payload : payload }),
  putClientInvite:                       (payload) => ({ type: actionTypes.PUT_CLIENTINVITE, payload : payload }),
  sendClientInvite:                      (payload) => ({ type: actionTypes.SEND_CLIENTINVITE, payload : payload }),
  sendClientInviteReminder:              (payload) => ({ type: actionTypes.SEND_CLIENTINVITEREMINDER, payload : payload }),
  sendCallUnsuccessfulReminder:          (payload) => ({ type: actionTypes.SEND_SENDCALLUNSUCCESSFULREMINDER, payload : payload }),
  deleteClientInvite:                    (payload) => ({ type: actionTypes.DEL_CLIENTINVITE, payload : payload }),
  putReferral:                           (payload) => ({ type: actionTypes.PUT_REFERRAL, payload : payload }),
  putReferralStatus:                     (payload) => ({ type: actionTypes.PUT_REFERRALSTATUS, payload : payload }),
  putClient:                             (payload) => ({ type: actionTypes.PUT_CLIENT, payload : payload }),
  putMedicalPractitioner:                (payload) => ({ type: actionTypes.PUT_MEDICALPRACTITIONER, payload : payload }),
  putMedicalPractice:                    (payload) => ({ type: actionTypes.PUT_MEDICALPRACTICE, payload : payload }),
  postNote:                              (payload) => ({ type: actionTypes.POST_NOTE, payload : payload }),
  postClientNote:                        (payload) => ({ type: actionTypes.POST_CLIENTNOTE, payload : payload }),
  postFile:                              (payload) => ({ type: actionTypes.POST_FILE, payload : payload }),
  deleteFile:                            (payload) => ({ type: actionTypes.DELETE_FILE, payload : payload }),
  setFileClientViewableFlag:             (payload) => ({ type: actionTypes.SET_CLIENTVIEWABLEFLAG, payload : payload }),
  putClientTaskFlag:                     (payload) => ({ type: actionTypes.PUT_CLIENT_TASKFLAG, payload : payload }),
  putClientFlag:                         (payload) => ({ type: actionTypes.PUT_CLIENT_FLAG, payload : payload }),
  putClientInviteFlag:                   (payload) => ({ type: actionTypes.PUT_CLIENTINVITE_FLAG, payload : payload }),
  putFaxFlag:                            (payload) => ({ type: actionTypes.PUT_FAX_FLAG, payload : payload }),
  putInterestedProviderFlag:             (payload) => ({ type: actionTypes.PUT_INTERESTEDPROVIDER_FLAG, payload : payload }),
  putFaxClientName:                      (payload) => ({ type: actionTypes.PUT_FAX_CLIENTNAME, payload : payload }),
  putClientArchive:                      (payload) => ({ type: actionTypes.PUT_ARCHIVECLIENT, payload : payload }),
  putReferrer:                           (payload) => ({ type: actionTypes.PUT_REFERRER, payload : payload }),
  putToDo:                               (payload) => ({ type: actionTypes.PUT_TODO, payload: payload }),
  fetchBookingBillableCSV:               (payload) => ({ type: actionTypes.FETCH_BOOKINGBILLABLECSV, payload: payload }),
  fetchPowerBIXlsx:                      (payload) => ({ type: actionTypes.FETCH_POWERBIXLSX, payload: payload }),

  putCard:                               (payload) => ({ type: actionTypes.PUT_CARD, payload : payload }),
  sendPsychologistReferral:              (payload) => ({ type: actionTypes.SEND_PSYCHOLOGISTREFERRAL, payload : payload }),
  putInvoiceStatus:                      (payload) => ({ type: actionTypes.PUT_INVOICESTATUS, payload : payload }),
  changeInterestedStatus:                (payload) => ({ type: actionTypes.PUT_INTERESTED, payload: payload }),

  sendPsychologistInvite:                (payload) => ({ type: actionTypes.SEND_PSYCHOLOGISTINVITE, payload : payload }),
  postPsychologistInvite:                (payload) => ({ type: actionTypes.POST_PSYCHOLOGISTINVITE, payload : payload }),
  deletePsychologistInvite:              (payload) => ({ type: actionTypes.DEL_PSYCHOLOGISTINVITE, payload : payload }),
  putPsychologistDetail:                 (payload) => ({ type: actionTypes.PUT_PSYCHOLOGISTDATA, payload : payload }),
  postPsychologistDetail:                (payload) => ({ type: actionTypes.POST_PSYCHOLOGISTDATA, payload : payload }),
  putClientDetail:                       (payload) => ({ type: actionTypes.PUT_CLIENTDATA, payload : payload }),
  postClientDetail:                      (payload) => ({ type: actionTypes.POST_CLIENTDATA, payload : payload }),
  putClientPsychologist:                 (payload) => ({ type: actionTypes.PUT_CLIENTPSYCHOLOGIST, payload : payload }),
  putAPIKey:                             (payload) => ({ type: actionTypes.PUT_APIKEY, payload : payload }),
  putMedipassCode:                       (payload) => ({ type: actionTypes.PUT_MEDIPASSCODES, payload : payload }),
  putMedipassInvoice:                    (payload) => ({ type: actionTypes.PUT_MEDIPASSINVOICES, payload : payload }),
  putFaxStatus:                          (payload) => ({ type: actionTypes.PUT_FAXSTATUS, payload : payload }),
    
  addAvailability:                       (payload) => ({ type: actionTypes.ADD_AVAILABILITY, payload : payload }),
  chgAvailability:                       (payload) => ({ type: actionTypes.CHG_AVAILABILITY, payload : payload }),
  delAvailability:                       (payload) => ({ type: actionTypes.DEL_AVAILABILITY, payload : payload }),
  chgBulkBillingAvailability:            (payload) => ({ type: actionTypes.CHG_BULKBILLINGAVAILABILITY, payload: payload }),
  chgProviderAOI:                        (payload) => ({ type: actionTypes.CHG_PROVIDERAOI, payload: payload }),

  bookAppointment:                       (payload) => ({ type: actionTypes.ADD_BOOKING, payload : payload }),
  putAppointment:                        (payload) => ({ type: actionTypes.PUT_BOOKING, payload : payload }),
  putBillableAppointment:                (payload) => ({ type: actionTypes.PUT_BILLABLEBOOKING, payload : payload }),
    // appointmentReminder:            (payload) => ({ type: actionTypes.BOOKING_REMINDER, payload : payload }),

  putXeroContactLink:                    () => ({ type: actionTypes.PUT_XEROCONTACTLINK }),
  putXeroNDISContactLink:                () => ({ type: actionTypes.PUT_XERONDISCONTACTLINK }),

  putCreateProviderBillableEmails:      (payload) => ({ type: actionTypes.CREATE_BILLABLEEMAILS, payload : payload }),
  putCreateInvoices:                    (payload) => ({ type: actionTypes.CREATE_INVOICES, payload : payload }),
  putCreateInvoicesContract:            (payload) => ({ type: actionTypes.CREATE_INVOICESCONTRACT, payload : payload }),
  putCreateInvoicesContractProvider:    (payload) => ({ type: actionTypes.CREATE_INVOICESCONTRACTPROVIDER, payload : payload }),
  putCreateInvoicesNDIS:                (payload) => ({ type: actionTypes.CREATE_INVOICESNDIS, payload : payload }),
  putCreateInvoicesNDISProvider:        (payload) => ({ type: actionTypes.CREATE_INVOICESNDISPROVIDER, payload : payload }),
  // putProcessPayments:                   (payload) => ({ type: actionTypes.CREATE_PAYMENTS, payload : payload }),

  fetchCounter:                         (payload) => ({ type: actionTypes.FETCH_COUNTER, payload: payload }),
  fetchStatistics:                      (payload) => ({ type: actionTypes.FETCH_STATISTICS, payload: payload }),

  fetchReport:                          (payload) => ({ type: actionTypes.FETCH_REPORT, payload: payload }),
  setAdminInitialState:                        () => ({ type: actionTypes.SET_INITIAL }),
  setFilters:                           (payload) => ({ type: actionTypes.SET_FILTERS, payload: payload }),

  fetchTagValues:                       (payload) => ({ type: actionTypes.FETCH_TAGVALUES, payload: payload }),
  putTagValues:                         (payload) => ({ type: actionTypes.PUT_TAGVALUES, payload: payload }),
  clearDisplayInvite:                          () => ({ type: actionTypes.CLEAR_DISPLAYINVITE }),
  clearClient:                                 () => ({ type: actionTypes.CLEAR_CLIENT }),
  fetchOpportunityFeed:                 (payload) => ({ type: actionTypes.FETCH_OPPORTUNITYFEED, payload: payload  }),
  putClientOpportunityInterest:         (payload) => ({ type: actionTypes.PUT_OPPORTUNITYINTEREST, payload: payload  }),
  putResetInterestedProviders:          (payload) => ({ type: actionTypes.PUT_RESETINTERESTEDPROVIDERS, payload: payload  }),
  fetchActivityFeed:                    (payload) => ({ type: actionTypes.FETCH_ACTIVITYFEED, payload: payload  }),
  fetchInvoiceLog:                      (payload) => ({ type: actionTypes.FETCH_INVOICELOG, payload: payload  }),
  fetchContracts:                       (payload) => ({ type: actionTypes.FETCH_CONTRACTS, payload: payload  }),
  putContract:                          (payload) => ({ type: actionTypes.PUT_CONTRACT, payload : payload }),
  fetchPrograms:                        (payload) => ({ type: actionTypes.FETCH_PROGRAMS, payload: payload  }),
  putProgram:                           (payload) => ({ type: actionTypes.PUT_PROGRAM, payload : payload }),


  updateBookingBilling:                 (payload) => ({ type: actionTypes.PUT_BOOKINGBILLING, payload : payload }),
  putProviderPassword:                  (payload) => ({ type: actionTypes.PUT_PROVIDERPASSWORD, payload : payload }),

  setBookingStatus:                     (payload) => ({ type: actionTypes.SET_BOOKINGSTATUS, payload : payload }),
  setProgressStatus:                    (payload) => ({ type: actionTypes.SET_PROGRESS_STATUS, payload : payload }),  

  fetchAdminUser:                       (payload) => ({ type: actionTypes.FETCH_ADMINUSER, payload : payload }),  
  putAdminUser:                         (payload) => ({ type: actionTypes.PUT_ADMINUSER, payload : payload }),  
  fetchClientsByDay:                    (payload) => ({ type: actionTypes.FETCH_CLIENTSBYDAY, payload : payload }),  
  fetchClientRegistrationsByDay:        (payload) => ({ type: actionTypes.FETCH_CLIENTREGISTRATIONSBYDAY, payload : payload }),  
  fetchClientsByWeek:                   (payload) => ({ type: actionTypes.FETCH_CLIENTSBYWEEK, payload : payload }),  
  fetchProviderProgramBio:              (payload) => ({ type: actionTypes.FETCH_PROVIDERPROGRAMBIO, payload : payload }),  
  putProviderProgramBio:                (payload) => ({ type: actionTypes.PUT_PROVIDERPROGRAMBIO, payload : payload }),  

  fetchProviderBookingApproval:         (payload) => ({ type: actionTypes.FETCH_PROVIDERBOOKINGAPPROVAL, payload : payload }),  
  putProviderBookingApproval:           (payload) => ({ type: actionTypes.PUT_PROVIDERBOOKINGAPPROVAL, payload : payload }),   
  putApproveBooking:                    (payload) => ({ type: actionTypes.PUT_APPROVEBOOKING, payload : payload }),  
  putRHCInvoicedBooking:                (payload) => ({ type: actionTypes.PUT_RHCINVOICEDBOOKING, payload : payload }),   
  putBookingNote:                       (payload) => ({ type: actionTypes.PUT_BOOKINGNOTE, payload : payload }),   
};

function* actionWatcher() {
  yield takeLatest('FETCH_HELPITEMS',fetchHelpItems)
  yield takeLatest('FETCH_RESOURCEITEMS',fetchResourceItems)
  yield takeLatest('FETCH_DASHBOARDNEWS',fetchDashboardNews)
  yield takeLatest('FETCH_MEDICALPRACTICES',fetchMedicalPractices)
  yield takeLatest('FETCH_MEDICALPRACTITIONERS',fetchMedicalPractitioners)
  yield takeLatest('FETCH_SOURCE',fetchSource)
  yield takeLatest('FETCH_INTERESTED',fetchInterested)
  yield takeLatest('FETCH_REGISTERED',fetchRegistered)
  yield takeLatest('FETCH_CLIENTREGISTERED',fetchClientRegistered)
  yield takeLatest('FETCH_REFERRALSVIEW',fetchReferralsView)
  yield takeLatest('FETCH_REFERRALS',fetchReferrals)
  yield takeLatest('FETCH_FAXES',fetchFaxes)
  yield takeLatest('FETCH_CLIENTINVITES',fetchClientInvites)
  yield takeLatest('FETCH_CLIENTS',fetchClients)
  yield takeLatest('FETCH_CLIENT',fetchClient)
  yield takeLatest('FETCH_AVAILABILITY',fetchAvailability)
  yield takeLatest('FETCH_BOOKINGS',fetchBookings)
  yield takeLatest('FETCH_BOOKINGBILLING',fetchBookingBilling)
  yield takeLatest('FETCH_BOOKING',fetchBooking)
  yield takeLatest('FETCH_MATCHING',fetchMatching)
  yield takeLatest('FETCH_CLIENTFUTUREBOOKINGSTATUS',fetchClientFutureBookingStatus)
  yield takeLatest('FETCH_MATCHINGAVAILABILITY',fetchMatchingAvailability)
  yield takeLatest('FETCH_FAXPDF',fetchFaxPDF)
  yield takeLatest('FETCH_INTENT',fetchIntent)
  yield takeLatest('FETCH_BILLABLE',fetchBillableIntents)
  yield takeLatest('FETCH_BILLABLEALL',fetchBillableIntentsAll)
  yield takeLatest('FETCH_BILLABLECONTRACT',fetchBillableIntentsContract)
  yield takeLatest('FETCH_BILLABLENDIS',fetchBillableIntentsNDIS)
  yield takeLatest('FETCH_BILLABLECONTRACTPROVIDER',fetchBillableIntentsContractProvider)
  yield takeLatest('FETCH_BILLABLENDISPROVIDER',fetchBillableIntentsNDISProvider)
  yield takeLatest('FETCH_SINGLEBILLABLE',fetchSingleBillableIntents)
  yield takeLatest('FETCH_PAYMENTS',fetchPayments)
  yield takeLatest('FETCH_NOTES',fetchNotes)
  yield takeLatest('FETCH_FILES',fetchFiles)
  yield takeLatest('FETCH_PAYMENTAPI',fetchPaymentAPI)
  yield takeLatest('FETCH_MEDIPASSINVOICES',fetchMedipassInvoices)
  yield takeLatest('FETCH_MEDIPASSCODES',fetchMedipassCodes)
  yield takeLatest('FETCH_MEDIPASSCLIENTS',fetchMedipassClients)
  yield takeLatest('FETCH_MEDIPASSBOOKINGDATES',fetchMedipassBookingDates)
  yield takeLatest('FETCH_TODOS',fetchToDos)
  yield takeLatest('FETCH_TAGVALUES', fetchTagValues)
  yield takeLatest('FETCH_REFERRERS', fetchReferrers)
  yield takeLatest('FETCH_BOOKINGTYPESTATISTICS', fetchBookingTypeStatistics)
  yield takeLatest('FETCH_SEARCHCLIENTDATA',fetchSearchClientData)
  yield takeLatest('FETCH_SEARCHBOOKINGDATA',fetchSearchBookingData)
  yield takeLatest('FETCH_CLIENTSBYDAY',fetchClientsByDay)
  yield takeLatest('FETCH_CLIENTREGISTRATIONSBYDAY',fetchClientRegistrationsByDay)
  yield takeLatest('FETCH_CLIENTSBYWEEK',fetchClientsByWeek)
  yield takeLatest('FETCH_OPPORTUNITIES',fetchOpportunities)
  yield takeLatest('PUT_OPPORTUNITY',putOpportunity)

  yield takeLatest('FETCH_ADMINUSER',fetchAdminUser)
  yield takeLatest('PUT_ADMINUSER',putAdminUser)

  yield takeLatest('PUT_REFERRAL',putReferral)
  yield takeLatest('PUT_REFERRALSTATUS',putReferralStatus)
  yield takeLatest('PUT_CLIENT',putClient)
  yield takeLatest('POST_CLIENTINVITE',postClientInvite)
  yield takeLatest('PUT_CLIENTINVITE',putClientInvite)
  yield takeLatest('SEND_CLIENTINVITE', sendClientInvite)
  yield takeLatest('SEND_CLIENTINVITEREMINDER', sendClientInviteReminder)
  yield takeLatest('SEND_SENDCALLUNSUCCESSFULREMINDER', sendCallUnsuccessfulReminder)
  yield takeLatest('DEL_CLIENTINVITE', deleteClientInvite)
  yield takeLatest('PUT_MEDICALPRACTITIONER',putMedicalPractitioner)
  yield takeLatest('PUT_MEDICALPRACTICE',putMedicalPractice)
  yield takeLatest('POST_NOTE',postNote)
  yield takeLatest('POST_CLIENTNOTE',postClientNote)
  yield takeLatest('POST_FILE',postFile)
  yield takeLatest('DELETE_FILE',deleteFile)
  yield takeLatest('SET_CLIENTVIEWABLEFLAG',setClientViewableFlag)
  // yield takeLatest('BOOKING_REMINDER',sendBookingReminder);
  yield takeLatest('PUT_CLIENTPSYCHOLOGIST',putClientPsychologist)
  yield takeLatest('PUT_CLIENT_FLAG',putClientFlag)
  yield takeEvery('PUT_CLIENT_TASKFLAG',putClientTaskFlag)
  yield takeLatest('PUT_FAX_FLAG',putFaxFlag)
  yield takeLatest('PUT_INTERESTEDPROVIDER_FLAG',putInterestedProviderFlag)
  yield takeLatest('PUT_FAX_CLIENTNAME',putFaxClientName)
  yield takeLatest('PUT_CLIENTINVITE_FLAG',putClientInviteFlag)
  yield takeLatest('PUT_APIKEY',putApikey)
  yield takeLatest('PUT_MEDIPASSCODES',putMedipassCodes)
  yield takeLatest('PUT_MEDIPASSINVOICES',putMedipassInvoices)
  yield takeLatest('PUT_TAGVALUES', putTagValues)
  yield takeLatest('PUT_REFERRER', putReferrer)
  yield takeLatest('PUT_CONTRACT', putContract)
  yield takeLatest('PUT_PROGRAM', putProgram)
  yield takeLatest('PUT_TODO', putToDo)
  yield takeLatest('PUT_FAXSTATUS', putFaxStatus)
  
  yield takeLatest('PUT_ARCHIVECLIENT',putArchiveClient)
  yield takeLatest('PUT_CARD',putCard)
  yield takeLatest('PUT_INTERESTED',putInterestedStatus)
  yield takeLatest('SEND_PSYCHOLOGISTINVITE',sendPsychologistInvite)
  yield takeLatest('SEND_PSYCHOLOGISTREFERRAL',sendPsychologistReferral)
  
  yield takeLatest('POST_PSYCHOLOGISTINVITE',postPsychologistInvite)
  yield takeLatest('DEL_PSYCHOLOGISTINVITE',deletePsychologistInvite)
  yield takeLatest('PUT_PSYCHOLOGISTDATA',putPsychologistDetail)
  yield takeLatest('POST_PSYCHOLOGISTDATA',postPsychologistDetail)
  yield takeLatest('PUT_CLIENTDATA',putClientDetail)
  yield takeLatest('POST_CLIENTDATA',postClientDetail)
  yield takeLatest('ADD_AVAILABILITY',postAvailability)
  yield takeLatest('CHG_AVAILABILITY',putAvailability)
  yield takeLatest('DEL_AVAILABILITY',deleteAvailability)
  yield takeLatest('CHG_BULKBILLINGAVAILABILITY', chgBulkBillingAvailability)
  yield takeLatest('CHG_PROVIDERAOI', chgProviderAOI)

  yield takeLatest('PUT_XEROCONTACTLINK',putXeroContactLink)
  yield takeLatest('PUT_XERONDISCONTACTLINK',putXeroNDISContactLink)
  yield takeLatest('PUT_BOOKINGBILLING',putBookingBilling)
  yield takeLatest('PUT_PROVIDERPASSWORD',putProviderPassword)
  yield takeLatest('PUT_INVOICESTATUS',putInvoiceStatus)
  yield takeLatest('CREATE_BILLABLEEMAILS',putBillableEmails)
  yield takeLatest('CREATE_INVOICES',putCreateInvoices)
  yield takeLatest('CREATE_INVOICESCONTRACT',putCreateInvoicesContract)
  yield takeLatest('CREATE_INVOICESNDIS',putCreateInvoicesNDIS)
  yield takeLatest('CREATE_INVOICESNDISPROVIDER',putCreateInvoicesNDISProvider)
  yield takeLatest('CREATE_INVOICESCONTRACTPROVIDER',putCreateInvoicesContractProvider)
    // yield takeLatest('CREATE_PAYMENTS',putCreatePayments)
  yield takeLatest('ADD_BOOKING',postBooking)
  yield takeLatest('PUT_BOOKING',putBooking)
  yield takeLatest('PUT_BILLABLEBOOKING',putBillableBooking)
  yield takeLatest('FETCH_COUNTER',fetchCounter)
  yield takeLatest('FETCH_STATISTICS',fetchStatistics)

  yield takeLatest('FETCH_INVOICELOG',fetchInvoiceLog)
  yield takeLatest('FETCH_CONTRACTS',fetchContracts)
  yield takeLatest('FETCH_PROGRAMS',fetchPrograms)
  yield takeEvery('FETCH_REPORT',fetchReport)

  yield takeLatest('FETCH_BOOKINGBILLABLECSV',fetchBookingBillableCSV)
  yield takeLatest('FETCH_POWERBIXLSX',fetchPowerBIXlsx)
  yield takeLatest('FETCH_ACTIVITYFEED',fetchActivityFeed)
  yield takeLatest('FETCH_OPPORTUNITYFEED',fetchOpportunityFeed)
  yield takeLatest('PUT_OPPORTUNITYINTEREST',putOpportunityInterest)
  yield takeLatest('PUT_RESETINTERESTEDPROVIDERS',putResetInterestedProviders)
  yield takeLatest('PUT_ERROR',putError)

  yield takeLatest('FETCH_PROVIDERPROGRAMBIO',fetchProviderProgramBio)
  yield takeLatest('PUT_PROVIDERPROGRAMBIO',putProviderProgramBio)

  yield takeLatest('FETCH_PROVIDERBOOKINGAPPROVAL',fetchProviderBookingApproval)
  yield takeLatest('PUT_APPROVEBOOKING',putApproveBooking)
  yield takeLatest('PUT_RHCINVOICEDBOOKING',putRHCInvoicedBooking)
  yield takeLatest('PUT_BOOKINGNOTE',putBookingNote)

}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 
}


