/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import * as Admin from "../../app/store/ducks/admin.duck";

// Blob setup for psychologist photos 
const containerName = `psychologists`;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 

export default function TopPsychologists() {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchReport({ reportname: 'topPsychologistsReferrals', output: 'query'})) }, [dispatch] )
  const topPsychologistsReferrals = useSelector(state => state.admin.Reports['topPsychologistsReferrals'])
  

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Top Psychologist Referrals</h3>
          </div>
           
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">

            {topPsychologistsReferrals ? topPsychologistsReferrals.map((row, index) => {
              return (
                <div className="kt-widget4__item" key={`psych_${index}`}>

                  <div className="kt-widget4__pic kt-widget4__pic--pic">
                    <img
                      alt={row.fullname}
                      src={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                    />
                  </div>
                  <div className="kt-widget4__info">
                    <Link to="/registeredPsychologists" className="kt-widget4__title">
                      {row.fullname}
                    </Link>
                    <p className="kt-widget4__text">{row.referralLast}</p>
                  </div>
                  <span className="kt-widget4__number kt-font-brand">{row.referrals}</span>
                </div>
              )
            })
          
          : null}

          </div>
        </div>
      </div>
    </>
  );
}
