import React from 'react'
import { withStyles, makeStyles, Switch, Button } from '@material-ui/core'
// import OndemandVideoIcon 		from '@material-ui/icons/OndemandVideo';
// import SendIcon 				from '@material-ui/icons/Send';
// import MarkunreadMailboxIcon 	from '@material-ui/icons/MarkunreadMailbox';
// import MessageIcon 				from '@material-ui/icons/Message';
import Tooltip 					from '@material-ui/core/Tooltip';
import HelpIcon 				from '@material-ui/icons/Help';
import  './ToggleButton.scss'

const useStyles = makeStyles((theme) => ({
	btnDash: {
		color: '#fff',
		padding: '1em 2em 1em 1.5em',
		fontSize: '15px',
		textTransform: "capitalize",
		fontWeight: '100',
	},
	btnDashSmall: {
		padding: '0.6em 1.2em 0.6em 1em',
		fontSize: '1.1em',
	},
	btnHeroDarker: {		backgroundColor: '#93A33A',	'&:hover': {	backgroundColor: '#7c9300',	}	},
	btnFocusContrast: {		backgroundColor: '#00B3BE',	'&:hover': {	backgroundColor: '#D2B12B',	}	},
	btnContrastDarker: {	backgroundColor: '#D2B12B',	'&:hover': {	backgroundColor: '#b79300',	}	},
	btnSupportDarker: {		backgroundColor: '#A33383',	'&:hover': {	backgroundColor: '#890062',	}	},
	btnAdditionalDarker: {	backgroundColor: '#007cbe',	'&:hover': {	backgroundColor: '#005481',	}	},
	btnToggleButton: {		backgroundColor: '#00B3BE',	'&:hover': {	backgroundColor: '#009ba5',	}	},
	tooltipIcon: {
		color: '#333',
		marginLeft: '0.6em'
	}
}));

const RHCTooltip = withStyles({
	tooltip: {
	  color: "#FFFFFF",
	  backgroundColor: "#666",
	  fontSize: '1.2em',
	  fontWeight: '100',
	  padding: '1em',
	}
})(Tooltip);

// WHOLE-BUTTON-TIP
// const ToolTipWrap = ({ title, children }) => title ? <RHCTooltip title={title}>{children}</RHCTooltip> : children;

export default function ToggleButton(props) {
	
	const {
		isActive,
		handleToggle,
		label,
		id,
		labelSuffix,
		size,
		tooltip,
	} = props;

	const classes = useStyles();
	// const countClass = isActive	? 'active' : 'inactive';
	return (
		

		// <ToolTipWrap title={tooltip} className>								// See: WHOLE-BUTTON-TIP ... use this if the tip needs to be on the whole button
			<Button
				className={[
					'switch-box',
					'bulk-switcher',
					isActive ? 'active' : 'inactive',
					classes.btnToggleButton,
					classes.btnDash,
					size==="small" ? classes.btnDashSmall : ''
					].join(' ')}
				tabIndex="0"
				type="button"
				onClick={(e) => handleToggle(e,id)}
				variant="contained"
				disableElevation
				>
					<Switch
						size="small"
						checked={isActive}
						onClick={(e) => { e.stopPropagation(); handleToggle(e,id) } }
						className={`bulk-switch ${isActive ? 'active' : 'inactive'}`}
						/>
				
					{label}{labelSuffix && labelSuffix}

					{tooltip && <RHCTooltip title={tooltip} >
						<HelpIcon className={classes.tooltipIcon} />
					</RHCTooltip>}

			</Button>
		// </ToolTipWrap>														// WHOLE-BUTTON-TIP
	)
}