import React, { useEffect, useState } from "react";  
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../../store/ducks/admin.duck";
import * as Auth from "../../store/ducks/auth.duck";
import { Card, Button, Modal } from "react-bootstrap";
import '../../../_metronic/_assets/css/app2.css';
import UpdatePassword from '../../components/UpdatePassword'
import { ToastContainer, Flip } from "react-toastify";

export default function DashboardReferrer() {
  
  const dispatch = useDispatch()
  const isRecovery = useSelector(state => state.auth.recoveryStatus)
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)
  useEffect( () => { dispatch(Admin.actions.fetchReferrers()) }, [dispatch] )
  
  useEffect( () => {
    if (isRecovery === 'Recovery') {
      dispatch(Auth.actions.clearRecovery())
      setShowUpdatePassword(true)
    }
  }, [dispatch,isRecovery])


  // now init the fields with values so that Formik can manage the touched state
  const referrerInit = {
    firstname: '', 
    surname: '', 
    email: '', 
    mobilephone: '',
    timezone: 'AUS Eastern Standard Time',
    newpassword: '',
    newpasswordconfirmation: '',
    id : 0,
    organisationname : ''
  }


  const YoutubeEmbed = ({ embedId }) => (
    <div className="ytvideo-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );


  // get the Referrer records and merge with the initialised fields
  var referrerState = useSelector(state =>  state.admin.Referrers[0]);
  var referrer = {...referrerInit, ...referrerState};



  return (
    <>
      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-xl-6">
        <Card style={{ marginRight: 20 }}>
          <Card.Body className="welcome-bg">
            <Card.Title><img src="https://files.ruralhealthconnect.com.au/images/logo.png" className="welcome-bg-logo" alt="Rural Health Connect Logo" /></Card.Title>
            <p>Dear {referrer.firstname},</p>
            <p>Welcome to Rural Health Connect. We are pleased to be working with you.</p>
            <p><strong>NOTE: We now require mental health care plans to be completed before a referral is made via this service. You can attach the mental health care plan as part of this referral. Or your client can request it from their GP and upload it when they sign up. Photos of the pages are fine.</strong></p>
            <p>If your client is unable to access a GP then you can refer them to our GP on the platform first.</p>
            <p>Before referring your client please ensure that they are suitable for our telehealth service. You can read our referral guidelines <a href={`https://files.ruralhealthconnect.com.au/fileStoreRHC/RHCReferralGuidelines.pdf`} target="_Referrers" style={{ textDecoration: 'underline' }}>here</a></p>
            <p>To assist your client in understanding our process, and what to expect, please pass on the information in the blue dot points on the referral page.</p>
            <p>As always we are on hand to help if needed. Please email info@ruralhealthconnect.com.au ​or call 0427 692 377.</p>
            <p>Thank you!</p>
            <p>Rural Health Connect </p>
          <hr />
            <a href={`https://files.ruralhealthconnect.com.au/fileStoreRHC/RHCReferralGuidelines.pdf`} target="_Referrers">
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#eee" }} >
                Referral Guidelines
            </Button>
            </a>
          </Card.Body>
        </Card>
      </div>
      <div className="col-xl-6">
          < YoutubeEmbed
            embedId = {'IYmoANPP3Ko'}
          />
      </div>
    </div>

    <ToastContainer
      position="bottom-right"
      transition={Flip}
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />    

    <Modal show={showUpdatePassword} onHide={()=>setShowUpdatePassword(false)} dialogClassName="todos-modal-dialog">
      <Modal.Header closeButton className="todos-modal">
        <Modal.Title>You have recently recovered your password, please update your password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="todos-modal">
        <UpdatePassword setShowUpdatePassword={setShowUpdatePassword} />
      </Modal.Body>
    </Modal>
  </>
  )

}
