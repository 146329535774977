import React, { useState, useEffect } from "react"; 
import { useSelector, useDispatch } from 'react-redux'
import ToggleButton from './ToggleButton';
import { makeStyles, Button as MUIButton } from '@material-ui/core'
import { Button, Modal, Form } from "react-bootstrap";
import * as Reference from "../../store/ducks/reference.duck";
import * as Admin from "../../store/ducks/admin.duck";
import  './InterestsPanels.scss'
import '../../../_metronic/_assets/css/app2.css';
// import { Form } from "react-bootstrap/lib/navbar";

const useStyles = makeStyles((theme) => ({
	btnAOI: {
		color: '#fff',
		padding: '0.7em 0.8em',
		fontSize: '1.1em',
		textTransform: "capitalize",
		fontWeight: '100',
    marginTop: 10,
	},
	btnTT: {
		color: '#fff',
		padding: '0.7em 0.8em',
		fontSize: '1.1em',
		textTransform: "capitalize",
		fontWeight: '100',
    marginTop: 10,
	},
	btnAdditionalDarker: {	backgroundColor: '#007cbe',	'&:hover': {	backgroundColor: '#005481',	}	},
  btnHeroDarker: {		backgroundColor: '#93A33A',	'&:hover': {	backgroundColor: '#7c9300',	}	},
}));

export default function InterestsPanels() {

  const dispatch = useDispatch()
	const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
	const areasOfInterestRAW = useSelector(state =>  state.reference.AreasOfInterest)
  const areasOfProviderInterest = areasOfInterestRAW.filter(aoi => aoi.providertype === 'ProviderInterest')
  const areasOfTherapyTypes = areasOfInterestRAW.filter(aoi => aoi.providertype === 'TherapyType')
	const areasOfInterest = areasOfInterestRAW.filter(aoi => (aoi.providertype !== 'ProviderInterest' && aoi.providertype !== 'TherapyType'))
	const aoi_focus = areasOfInterest.filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === 'Psychologist')
	const aoi_other = areasOfInterest.filter(aoi => aoi.focusAreaIndex === null && aoi.providertype === 'Psychologist')
  const [activeInterestAreas, setActiveInterestAreas] = useState([]);	
  const [showAOI, setShowAOI] = useState(false)
  const [showTT, setShowTT] = useState(false)
	useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )

  useEffect( () => {
    // Populate AOI Key Areas from Registered 
    if (registered && registered.areasOfInterest.length > 0)
    {
      setActiveInterestAreas(registered.areasOfInterest)
    }
  },[registered])

  const handleAOIKeyAreasChange = (e = undefined, id) => {
    if(registered)
    {
      // Set temporary variable
      let providerAOI = [...activeInterestAreas]
      // Check if we are removing or adding this AOI
      if (activeInterestAreas.includes(id))
      {
        // Ok it exists so we must be removing it 
        let issueIndex = providerAOI.indexOf(id)
        if (issueIndex !== -1)
        {
          providerAOI.splice(issueIndex,1)
        }
        // Removed from database
        dispatch(Admin.actions.chgProviderAOI( { id: registered.id, aoi : id, action : 'DEL' } ))  
      }
      else
      {
        // Ok it doesn't exist so we must be adding it
        providerAOI = [...providerAOI, id]
        // Add to database
        dispatch(Admin.actions.chgProviderAOI( { id: registered.id, aoi : id, action : 'ADD' } ))  
      }
      // Set State
      setActiveInterestAreas(providerAOI)
    }
  }

  function handleAOIClose() {
    setShowAOI(false);
  }  

  function handleTTClose() {
    setShowTT(false);
  }  

	const classes = useStyles();	

	return (
    <>
      <div className="row interests-panels">
        <div className={`provider_help`}> 
          Please review and update your Profile Information
        </div>
        <div className="grid-box col-xl-12">
          {registered && (registered.providertype === 'Psychologist' || registered.providertype === 'Clinical' || registered.providertype === 'Occupational') ?
            <div className='grit interest-areas-panel'>
              
              <div className='grit-content'>
                <div className='panel-info interest-areas-info'>
                  <h2 className='h2'>Support for Key Areas of Need</h2>
                  <p>I can work with RHC on projects targeting the following key areas of need and will bulk bill when possible:</p>
                </div>
                <div className='panel-actions interest-areas-actions'>
                  {aoi_other && aoi_other.map( (itm, idx) => {
                    const isActive = activeInterestAreas.includes(itm.issue)
                    return (									
                      <ToggleButton
                        key={idx}
                        id={itm.issue}									// change for actual ID
                        isActive={isActive}
                        handleToggle={handleAOIKeyAreasChange}
                        label={itm.issue}
                        size="small"
                        tooltip={itm.tip}
                        />
                    )
                  })}
                </div>
                <div>
                  <hr></hr>
                  <MUIButton
                    variant="contained"
                    className={[classes.btnAdditionalDarker, classes.btnAOI].join(' ')}
                    disableElevation
                    size="small" 
                    onClick={() => setShowAOI(true)}
                    >
                    My Areas of Interest 
                  </MUIButton>
                  <MUIButton
                    style={{ marginLeft: 20, marginRight: 10 }}
                    variant="contained"
                    className={[classes.btnHeroDarker, classes.btnAOI].join(' ')}
                    disableElevation
                    size="small" 
                    onClick={() => setShowTT(true)}
                    >
                    My Therapy Types 
                  </MUIButton>
                </div>
              </div>
            </div>
          :
            null
          }
          <div className='grit work-options-panel'>
            <div className='grit-content'>
              <div className='panel-info work-options-info'>
                <h2 className='h2'>Other Options</h2>
                <p>We ask that if you click these options, you also aim to take on some bulk billed clients, to help meet the significant need. Thank you.</p>
              </div>
              <div className='panel-actions work-options-actions'>
              {areasOfProviderInterest && areasOfProviderInterest.map( (itm, idx) => {
                    const isActive = activeInterestAreas.includes(itm.issue)
                  return (
                    <ToggleButton
                      key={idx}
                      id={itm.issue}									// change for actual ID
                      isActive={isActive}
                      handleToggle={handleAOIKeyAreasChange}
                      label={itm.issue}
                      size="small"
                      tooltip={itm.tip}
                      />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAOI} onHide={handleAOIClose} >
        <Modal.Header closeButton >
          <Modal.Title>Areas of Interest Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row custom-rhc" style={{ padding: 10 }}>
            <div className="interests-panels">
              {/* <div className="grid-box col-xl-12"> */}
                <div className='grit work-options-panel'>
                  <div className='grit-content'>
                    <Form.Label>
                      Please select the areas you specialise in:
                    </Form.Label>
                    <div className='panel-actions work-options-actions'>
                      {aoi_focus && aoi_focus.map( (itm, idx) => {
                        const isActive = activeInterestAreas.includes(itm.issue)
                        return (									
                          <ToggleButton
                            key={idx}
                            id={itm.issue}									// change for actual ID
                            isActive={isActive}
                            handleToggle={handleAOIKeyAreasChange}
                            label={itm.issue}
                            size="small"
                            tooltip={itm.tip}
                            />
                        )
                      })}
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAOIClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTT} onHide={handleTTClose} >
        <Modal.Header closeButton >
          <Modal.Title>Therapy Types Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row custom-rhc" style={{ padding: 10 }}>
            <div className="interests-panels">
              {/* <div className="grid-box col-xl-12"> */}
                <div className='grit work-options-panel'>
                  <div className='grit-content'>
                    <Form.Label>
                      Please select the therapy types you specialise in:
                    </Form.Label>
                    <div className='panel-actions work-options-actions'>
                      {areasOfTherapyTypes && areasOfTherapyTypes.map( (itm, idx) => {
                        const isActive = activeInterestAreas.includes(itm.issue)
                        return (									
                          <ToggleButton
                            key={idx}
                            id={itm.issue}									// change for actual ID
                            isActive={isActive}
                            handleToggle={handleAOIKeyAreasChange}
                            label={itm.issue}
                            size="small"
                            tooltip={itm.tip}
                            />
                        )
                      })}
                    </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTTClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
	)
}