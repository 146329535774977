import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import * as Admin from "../../../app/store/ducks/admin.duck";
import { Modal } from "react-bootstrap";
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import YouTubeIcon from '@material-ui/icons/YouTube';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QuickPanelToggler from "./QuickPanelToggle";

import  '../../../app/components/DashStyle/topbar.scss'										// RHC-DASH-STYLE: Obviously this is garbage. ¯\_(ツ)_/¯
  
export default function Topbar({ isDashStyle=false }) {										// RHC-DASH-STYLE-UPDATE: Add the props

  // const pathname = useLocation().pathname													// RHC-DASH-STYLE
	// (below) Get from redux. Random here to show different 								   RHC-DASH-STYLE
	//  styles for 0, 1,8 and 9+. max set to show 9+ more often.							   RHC-DASH-STYLE
	const noteCount = Math.floor(Math.random() * 15);										// RHC-DASH-STYLE:

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchHelpItems()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchResourceItems()) }, [dispatch] )

  const helpItems = useSelector(state =>  state.admin.HelpItems)
  const resourceItems = useSelector(state =>  state.admin.ResourceItems)
  const [show, setShow] = useState(false);
  const [showResources, setShowResources] = useState(false);
  // const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;	// RHC-DASH-STYLE-UPDATE: Overkill but you may eventually have more than just the help item.
    
  function renderLink(item) {
    if(item.pdf_url !== '')
    {
      return (
        <ListItem button key={`item-${item.title}`}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <a href={`https://files.ruralhealthconnect.com.au/fileStoreRHC/${item.pdf_url}`} target="_help">
              <ListItemText primary={item.title} /> 
            </a>
        </ListItem>
      )
    }
    else if(item.youtube_url !== '')
    {
      return (
        <ListItem button key={`item-${item.title}`}>
            <ListItemIcon>
              <YouTubeIcon />
            </ListItemIcon>
            <a href={item.youtube_url} target="_help">
              <ListItemText primary={item.title} /> 
            </a>
      </ListItem>
      )
    }
  }
  
  return (
    <>
      <div className="kt-header__topbar" style={{ paddingTop: 20, cursor: 'pointer' }}>
        <h4 style={{ paddingRight: 10 }} onClick={()=>setShow(true)}>Help</h4>
        <h4 onClick={()=>setShowResources(true)}>Resources</h4>
        <QuickPanelToggler noteCount={noteCount} />        
      </div>


      <Modal show={show} onHide={() => setShow(false)} dialogClassName="edit-md-modal">
        <Modal.Header className="edit-md-modal">
          <Modal.Title>Online Help</Modal.Title>
          <CloseIcon onClick={() => setShow(false)} style={{ cursor: 'pointer' }} />
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          <List component="nav" aria-label="Help topics">
            {helpItems.map(item=>renderLink(item))}
          </List>
        </Modal.Body>
      </Modal>

      <Modal show={showResources} onHide={() => setShowResources(false)} dialogClassName="edit-md-modal">
        <Modal.Header className="edit-md-modal">
          <Modal.Title>Resources</Modal.Title>
          <CloseIcon onClick={() => setShowResources(false)} style={{ cursor: 'pointer' }} />
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          <List component="nav" aria-label="Resources">
            {resourceItems.map(item=>renderLink(item))}
          </List>
        </Modal.Body>
      </Modal>

    </>
  );
}
