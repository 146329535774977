import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';

import { process } from '@progress/kendo-data-query';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { Grid as MGrid, makeStyles } from '@material-ui/core';
import * as Admin from "../../../store/ducks/admin.duck";
import DateFnsUtils from '@date-io/date-fns';
// import * as Reference from "../../../store/ducks/reference.duck";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStylesDatePicker = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      height: 33,
    }
  }
});

// Note this process previously created invoices in Xero on behalf of NDIS providers 
// This is temporarily running until Megan moved to approved NDIS/EAP/Contract Bookings Process 

export default function PaymentNDIS() 
{
  const dispatch = useDispatch()
  const classesDatePicker = useStylesDatePicker();
  const filters = useSelector(state => state.admin.Filters)
  const billableIntents = useSelector(state => state.admin.BillableIntentNDISProvider)

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [{
      field: 'psychologistSurname',
      dir: 'asc'
    }],
  });

  useEffect( () => {
    dispatch(Admin.actions.fetchBillableIntentsNDISProvider({ startDate: filters.geninvoiceStartDate, endDate: filters.geninvoiceEndDate, status: 'Not Paid'})) }, 
    [dispatch, filters.geninvoiceStartDate, filters.geninvoiceEndDate] 
  )
  
  const [dataResult, setDataResult] = useState(process(billableIntents, dataState)); 
  const [prevBillableIntents, setPrevBillableIntents] = useState();

  useEffect( () => {
    if ((JSON.stringify(billableIntents) !== JSON.stringify(prevBillableIntents)) && billableIntents.length > 0)
    {
      // console.log('Refreshing Data')
      setPrevBillableIntents(billableIntents)
      setDataResult(process(billableIntents, dataState))
      // console.log('dataResult')
      // console.log(dataResult)
    }
  }, [prevBillableIntents, billableIntents, dataResult, dataState])

  // This is the expanded grid section 
  const DetailComponent = props => {
    const dataItem = props.dataItem;
    return <div>
              <Grid style={{width: "1600px"}} 
                data={dataItem.clients} 
              >
                <GridColumn field="clientName" title="Client Name" width="220px" />
                <GridColumn field="providerName" title="Provider Name" width="220px" />
                <GridColumn field="bookingDate" title="Booking Date" width="200px" />
                <GridColumn field="cost" filter={"numeric"} title="NDIS Cost (exGST)" width="130px" />
                <GridColumn field="providerfee" filter={"numeric"} title="Fee to Provider (exGST)" width="170px" />
                <GridColumn field="status" filter={"numeric"} title="Status" width="80px" />
                <GridColumn field="note" title="Note" width="200px" />
              </Grid> 
            </div>;
  };

  function sendProviderEmail() 
  {
    //console.log ('Generating Invoices in Xero for NDIS');
    dispatch(Admin.actions.putCreateInvoicesNDISProvider({ startDate: filters.geninvoiceStartDate, endDate: filters.geninvoiceEndDate}));
  }



  const dataStateChange = event => {
    setDataResult(process(billableIntents, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = event => {
    const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({ ...dataResult});
  };


  return(
    <>
      <div>
        <h4>Select Period for the NDIS Providers to be Paid  (Do Not Use For bookings after 4/8/24)</h4>
        <MGrid container style={{ padding: 10 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MGrid item style={{ paddingLeft: 2, paddingRight: 10 }}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="Start Date"
                value={filters.geninvoiceStartDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'geninvoiceStartDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MGrid>
            <MGrid item style={{ paddingRight: 10 }}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="End Date"
                value={filters.geninvoiceEndDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'geninvoiceEndDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
            </MGrid>
          </MuiPickersUtilsProvider>
        </MGrid>
        <Grid
          style={{height: "700px",}}
          sortable={true}
          reorderable={true}
          pageable={{buttonCount: 4,pageSizes: true,}}
          data={dataResult} {...dataState} 
          onDataStateChange={dataStateChange}
          detail={DetailComponent}
          expandField="expanded"
          onExpandChange={expandChange}
        >
          <GridToolbar>
            <button title="General NDIS Payments for Providers in Xero" className="k-button k-primary" onClick={sendProviderEmail}>
              Generate NDIS Provider Payments
            </button>&nbsp;
          </GridToolbar>
          <GridColumn field="psychologistName" title="Name" width="300px" />
          <GridColumn field="psychologistEmail" title="Email" width="350px" />
          <GridColumn field="xero" title="Xero Link" width="100px" />
          <GridColumn field="total" filter={"numeric"} title="Total Cost (exGST)" width="600px" />
        </Grid>
      </div>
      <ToastContainer
          position="bottom-right"
          transition={Flip}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />      
    </>
  )
}

