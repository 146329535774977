import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup, Badge } from "react-bootstrap";
import { Paper, TextField, Grid, MenuItem, Select, FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SearchIcon from '@material-ui/icons/Search';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import PlacesAutoComplete from "../../../components/AddressAutoComplete";
import BookingsList from "../../../components/BookingsList";
import BookingBillingList from "../../../components/BookingBillingList";
import { BookingWeek } from "../../../components/Appointments";
import ReferralMaintenance from "../../../components/ReferralMaintenance";
import Notes from "../../../components/Notes";
import Files from "../../../components/Files";
import ToDos from '../../../components/ToDos';
import { ToastContainer, Flip } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

export default function ClientsPsychologist() {
    
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('')
  const [haveBookings, sethaveBookings] = useState(false);

  const dispatch = useDispatch()
  const clients = useSelector(state => state.admin.Clients)
  const client = useSelector(state => state.admin.Client)
  const filters = useSelector(state => state.admin.Filters)

  const searchClients = (client) => {
    if( true
        && (client.fullname.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )
        // && (client.clientAreasOfInterest.some(issue => issue === searchAOI) || searchAOI === '')
        && (filters.clientsStatus === 'All' 
            || (filters.clientsStatus === 'Active' && client.status === 'Active') 
            || (filters.clientsStatus === 'Archived' && client.status === 'Archived') )  
      )
    {
      return client
    }  
  }
  
  const filteredClients = clients.filter(client => searchClients(client))

  // May need to consider this - do providers need to see inactive clients? 
  useEffect( () => { dispatch(Admin.actions.fetchClients( { status: filters.clientsStatus } )) }, [dispatch, filters.clientsStatus] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractitioners()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'GeneralPractitioner' })) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  const providerType  = useSelector(state => (state.admin.RegisteredPsychologists[0] && state.admin.RegisteredPsychologists[0].providertype))
  const userTZ = useSelector(state => state.auth.userTZ)
  const medicalPractitioners = useSelector(state =>  state.admin.MedicalPractitioners)
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.GeneralPractitioner)
  const timeZone = useSelector(state =>  state.reference.TimeZone)
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)
  const areasOfInterest = areasOfInterestReference.filter(area => area.providertype === providerType)
  
  const bookings = useSelector(state =>  state.admin.Bookings)
  
  const getBookings = () => {
    if (!haveBookings && registered) {
      dispatch(Admin.actions.fetchBookings({ key: 'psychologist', id: registered.id, userTZ: userTZ }))
      sethaveBookings(true)
    }     
  }

  useEffect( () => {
    if(filters.clientsListLoadClientID)
    {
      loadClient(filters.clientsListLoadClientID)
      dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: undefined }))
    }
  })

  useEffect( () => { getBookings() }, )

  var [show, setShow] = useState(false);
  var [showAppointment, setShowAppointment] = useState(false);
  var [showNotes, setShowNotes] = useState(false);
  var [showFiles, setShowFiles] = useState(false);
  const [showToDo, setShowToDo] = useState(false)
  const [toDoClientID, setToDoClientID] = useState()
  const [toDoTitle, setToDoTitle] = useState('')
    
  const initMedicalPractitioner = () => {
    return { id: 0, providernumber: '', salutation: 'Dr', firstName: '', surname: '', fullname: '', mobilephone: '', phone: '', type: 'Client GP',  }
  }

  const initMedicalPractice = useCallback(() => {
    return { id: 0, abn: '', practicename: '', phone: '', address1: '', email: '', website: '', logoURL: '', practiceType: 'GeneralPractitioner' }
  },[])

  const loadMedicalPractitice = useCallback((thisMedicalPractice) => {
    setcurrentMedicalPractice(thisMedicalPractice ? thisMedicalPractice : initMedicalPractice())
  },[initMedicalPractice])


  const loadMedicalPractitioner = useCallback((thisMedicalPractitioner) =>{
    setcurrentMedicalPractitioner(thisMedicalPractitioner ? thisMedicalPractitioner : initMedicalPractitioner())
    setcurrentMedicalPractitionerID(thisMedicalPractitioner ? thisMedicalPractitioner.id : 0);
    var tempMedicalPractice = thisMedicalPractitioner ? medicalPractices.find(v => v.id === thisMedicalPractitioner.medicalPracticeID) : null;
    loadMedicalPractitice(tempMedicalPractice)
  },[loadMedicalPractitice,medicalPractices])

  
  function handleAppointmentTypeChange(value) {
    setcurrentAppointmentType(value);
  }


  var [currentMedicalPractitioner, setcurrentMedicalPractitioner] = useState(initMedicalPractitioner());  
  var [currentMedicalPractice, setcurrentMedicalPractice] = useState(initMedicalPractice());

  const getMedicalPractitionerOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractitioner();
    }
  }

  const getMedicalPracticeOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractice();
    }
  }
  
  function handleTimeZoneAutocompleteChange(propertyName, event, newValue) { 
    if (newValue) {
      setcurrentTimeZone(newValue.systemName);
    }
  } 

  function putArchive()
  {
    // Archive client - i.e. alter status to Archived from Active 
    // console.log(currentClientID);
    dispatch(Admin.actions.putClientArchive({id : currentClientID, changetype: currentAction}));
    setShowConfirmation(false)
  }


  function putClient() {
    var payload = {
      profileClientID: currentClientID,
      profileFirstName: currentFirstName,
      profilePreferredName: currentPreferredName,
      profileSurname: currentSurname,
      profileDOB: currentDOB,
      profileEmail: currentEmail,
      profileGender: currentGender,
      profileOccupation: currentOccupation,
      profileMobile: currentMobile,
      profilefulladdress: currentfulladdress,
      profileStatus: currentStatus,
      profileNote: currentNote,
      profilehopeToGetFromTreatment: currenthopeToGetFromTreatment,
      profileMedicalPractitionerID: currentMedicalPractitionerID,
      profilePaymentType: currentPaymentType,
      profileTimeZone: currentTimeZone,
      profileLatitude: currentLatitude, 
      profileLongitude: currentLongitude,
      profilePostCode: currentPostCode,
      emergencycontactname1: currentEmergencyContactName1,
      emergencycontactphone1: currentEmergencyContactPhone1,
      emergencycontactrelation1: currentEmergencyContactRelation1,      
      emergencycontactname2: currentEmergencyContactName2,
      emergencycontactphone2: currentEmergencyContactPhone2,
      emergencycontactrelation2: currentEmergencyContactRelation2,
      profilemedicareno: currentmedicareno,
      profilemedicarereferenceno: currentmedicarereferenceno,
      profilemedicareexpirydate: currentmedicareexpirydate,  
      profileAreasOfInterest: currentAreasOfInterest.map(aoi => aoi.issue).join(","),  
      medicalPractice: currentMedicalPractice,
      medicalPractitioner: currentMedicalPractitioner, 
      status: currentStatus,
      appointmenttype: currentAppointmentType,   
      profilegpname: currentGPName,
      profilegpclinicname :  currentGPClinicName, 
      profilendisnumber: currentNDISNumber,
      profilendisdatefrom : currentNDISDateFrom,
      profilendisdateto : currentNDISDateTo,
      profilendisplanmanagementprovider : currentNDISPlanManagementProvider,
      profilendisdiagnosishistory : currentNDISDiagnosisHistory,
      profilepassword: null,
      profilepasswordconfirmation : null,
      profileConsentToShareWithGP : currentConsentToShareWithGP,
      profilePronouns : currentPronouns,
      profileIndigenous: currentIndigenous,
      profileNDISSupportCoordinator : currentNDISSupportCoordinator,
      profileNDISSupportCoordinatorPhone : currentNDISSupportCoordinatorPhone,
      profileprogrampopulation: currentProgramPopulation.map(pp => pp.name).join(","),
    }
    //
    if (process.env.REACT_APP_DEBUG) 
    {
        console.log ('Putting (Update)');
    }        
    // Save update 
    dispatch(Admin.actions.putClientDetail(payload));
    // Close form
    setShow(false);
  }

  function loadFiles(thisClient) {
    setcurrentClientID(thisClient.id)
    setcurrentPaymentType(thisClient.paymentType)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setShowFiles(true)
   }

  function loadNotes(thisClient, noteAction) {
    setNoteAction(noteAction)
    setcurrentClientID(thisClient.id)
    setcurrentID(thisClient.id)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setcurrentReferralGPPracticeFax(thisClient.referralGPPracticeFax)
    setShowNotes(true)
   }

  const loadArchive = (thisClient,action) => {
    // console.log(providerType);
    setcurrentClientID(thisClient.id)
    setcurrentID(thisClient.id)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setcurrentAction(action)
    
    setShowConfirmation(true)
  }

  const loadClient = (id) => {
    dispatch(Admin.actions.fetchClient( { clientID: id, type: providerType } ))
    setcurrentProgram(false)
  }

  // Define Program Question responses 
  const programPopulationOptions = [
    {
      "id" : "1",
      "name" : "Aboriginal and/or Torres Strait Islander",
    },
    {
      "id" : "2",
      "name": "Culturally and linguistically diverse",
    },
    {
      "id": "3",
      "name": "Migrant and Refugee",
    },
    {
      "id" : "4",
      "name": "Under 20",
    },
    {
      "id" : "5",
      "name": "Rural and Remote",
    }
  ]

  const loadProfile = useCallback((thisClient) => {
    // console.log(providerType);
    setcurrentProviderType(providerType);
    setcurrentPaymentType(thisClient.paymentType);
    // Check to see if it supervision client - if so update providertype 
    if (thisClient.paymentType === "Supervision")
    {
      setcurrentProviderType('Supervision');
    }
    //
    setcurrentClientID(thisClient.id);
    setcurrentID(thisClient.id);
    setcurrentMedicalPractitionerID(thisClient.medicalPractitionerID);
    setcurrentPaymentType(thisClient.paymentType);
    setcurrentClientBookingType(thisClient.appointmenttype === '1' ? 'PHONE' : 'VIDEO')
    setcurrentfulladdress(thisClient.fulladdress);
    setcurrentStatus(thisClient.status);
    setcurrentLatitude(thisClient.latitude);
    setcurrentLongitude(thisClient.longitude);
    setcurrentFirstName(thisClient.firstname);
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname);
    setcurrentDOB(thisClient.dob);
    setcurrentEmail(thisClient.email);
    setcurrentGender(thisClient.gender);
    setcurrentOccupation(thisClient.occupation);
    setcurrentMobile(thisClient.mobilephone);
    setcurrentNote(thisClient.note);
    setcurrentTimeZone(thisClient.timeZone);
    setcurrentPostCode(thisClient.postCode);
    setcurrenthopeToGetFromTreatment(thisClient.hopeToGetFromTreatment);
    setcurrentEmergencyContactName1(thisClient.emergencycontactname1);
    setcurrentEmergencyContactPhone1(thisClient.emergencycontactphone1);
    setcurrentEmergencyContactRelation1(thisClient.emergencycontactrelation1);
    setcurrentEmergencyContactName2(thisClient.emergencycontactname2);
    setcurrentEmergencyContactPhone2(thisClient.emergencycontactphone2);
    setcurrentEmergencyContactRelation2(thisClient.emergencycontactrelation2);
    setcurrentmedicareno(thisClient.medicareno);
    setcurrentmedicarereferenceno(thisClient.medicarereferenceno);
    setcurrentmedicareexpirydate(thisClient.medicareexpirydate);
    setcurrentAppointmentType(thisClient.appointmenttype);
    setcurrentStatus(thisClient.status);
    setcurrentBusinessName(thisClient.businessName);;
    setcurrentABN(thisClient.abn);
    setcurrentBusinessAddress(thisClient.businessAddress);
    setcurrentBackgroundInformation(thisClient.backgroundInformation);
    setcurrentHealthCareCard(thisClient.healthcarecard);
    setcurrentUnemployed(thisClient.unemployed);
    setcurrentNaturalDisaster(thisClient.naturaldisaster);
    setcurrentBulkBilledReason(thisClient.bulkbilledreason);
    setcurrentGPName(thisClient.gpName);
    setcurrentGPClinicName(thisClient.gpClinicName)
    setcurrentNDISNumber(thisClient.ndisnumber)
    setcurrentNDISDateFrom(thisClient.ndisdatefrom)
    setcurrentNDISDateTo(thisClient.ndisdateto)
    setcurrentNDISPlanManagementProvider(thisClient.ndisplanmanagementprovider)
    setcurrentNDISDiagnosisHistory(thisClient.ndisdiagnosishistory)
    setcurrentNDISActualProviderFee(thisClient.ndisactualproviderfee)
    setcurrentReferralGPPracticeFax(thisClient.referralGPPracticeFax)
    setcurrentConsentToShareWithGP(thisClient.consentToShareWithGP)
    setcurrentPronouns(thisClient.pronouns)
    setcurrentIndigenous(thisClient.indigenous)
    setcurrentHandOverNotes(thisClient.handOverNotes)
    setcurrentNDISSupportCoordinator(thisClient.ndissupportcoordinator)
    setcurrentNDISSupportCoordinatorPhone(thisClient.ndissupportcoordinatorphone)
    setcurrentReferringGPName(thisClient.referringGPName)
    setcurrentReferringGPProviderNo(thisClient.referringGPProviderNo)
    setcurrentReferredGPOn(thisClient.referredGPOn)
    setcurrentContractProviderFee(thisClient.contractproviderfee)
    // Determine RHC Managed Flag 
    if (thisClient.rhcndismanagment === '1' || thisClient.rhccontractmanagement === '1')
    {
      setcurrentRHCManagedLabel(true)
    }
    else
    {
      setcurrentRHCManagedLabel(false)
    }

    var aoi_clienttype = areasOfInterestReference.filter(area => area.providertype === providerType)
    var aoi = aoi_clienttype.filter(( {issue} ) => thisClient.clientAreasOfInterest.includes(issue))
    setcurrentAreasOfInterest(aoi);

    var aoi_program = aoi.filter(aoip => (aoip.program !== null && aoip.program !== 0)) 
    // console.log(aoi_program)
    if (aoi_program && aoi_program.length > 0)
    {
      //console.log('Not here')
      setcurrentProgram(true)
    }
    var programPopulation = programPopulationOptions.filter(( {name} ) => thisClient.programpopulation.includes(name))
    setcurrentProgramPopulation(programPopulation)

    loadMedicalPractitioner(medicalPractitioners.find(v => v.id === thisClient.medicalPractitionerID));
    // Load Form
    setShow(true);
  },[areasOfInterestReference,loadMedicalPractitioner,medicalPractitioners,providerType])

  useEffect( () => { client && client.id && client.id > 0 && loadProfile(client) }, [client,loadProfile] )

  var [currentClientID, setcurrentClientID] = useState(); 
  var [currentMedicalPractitionerID, setcurrentMedicalPractitionerID] = useState();
  var [currentPaymentType, setcurrentPaymentType] = useState();
  var [currentClientBookingType, setcurrentClientBookingType] = useState()
  var [currentTimeZone, setcurrentTimeZone] = useState();
  var [currentfulladdress, setcurrentfulladdress] = useState();
  var [currentLatitude, setcurrentLatitude] = useState();
  var [currentLongitude, setcurrentLongitude] = useState();
  var [currentID, setcurrentID] = useState();
  var [currentPostCode, setcurrentPostCode] = useState()
  var [currentStatus, setcurrentStatus] = useState() 
  var [currentFirstName, setcurrentFirstName] = useState()
  var [currentPreferredName, setcurrentPreferredName] = useState()
  var [currentSurname, setcurrentSurname] = useState()
  var [currentDOB, setcurrentDOB] = useState();
  var [currentEmail, setcurrentEmail] = useState();
  var [currentGender, setcurrentGender] = useState();
  var [currentOccupation, setcurrentOccupation] = useState();
  var [currentMobile, setcurrentMobile] = useState();
  var [currentNote, setcurrentNote] = useState();
  var [currenthopeToGetFromTreatment, setcurrenthopeToGetFromTreatment] = useState();
  var [currentEmergencyContactName1, setcurrentEmergencyContactName1] = useState();
  var [currentEmergencyContactPhone1, setcurrentEmergencyContactPhone1] = useState();
  var [currentEmergencyContactRelation1, setcurrentEmergencyContactRelation1] = useState();
  var [currentEmergencyContactName2, setcurrentEmergencyContactName2] = useState();
  var [currentEmergencyContactPhone2, setcurrentEmergencyContactPhone2] = useState();
  var [currentEmergencyContactRelation2, setcurrentEmergencyContactRelation2] = useState();
  var [currentAreasOfInterest, setcurrentAreasOfInterest] = useState();
  var [currentmedicareno, setcurrentmedicareno] = useState();
  var [currentmedicarereferenceno, setcurrentmedicarereferenceno] = useState();
  var [currentmedicareexpirydate, setcurrentmedicareexpirydate] = useState();
  var [currentAppointmentType, setcurrentAppointmentType] = useState('0');
  var [currentProviderType, setcurrentProviderType] = useState(); 
  var [currentBusinessName,setcurrentBusinessName] = useState();
  var [currentABN,setcurrentABN] = useState();
  var [currentBusinessAddress,setcurrentBusinessAddress] = useState();
  var [currentBackgroundInformation,setcurrentBackgroundInformation] = useState();
  var [currentHealthCareCard,setcurrentHealthCareCard] = useState();
  var [currentUnemployed,setcurrentUnemployed] = useState();
  var [currentNaturalDisaster,setcurrentNaturalDisaster] = useState();
  var [currentBulkBilledReason,setcurrentBulkBilledReason] = useState();
  const [currentGPName,setcurrentGPName] = useState();
  const [currentGPClinicName,setcurrentGPClinicName] = useState();
  const [currentNDISNumber,setcurrentNDISNumber] = useState();
  const [currentNDISDateFrom,setcurrentNDISDateFrom] = useState();
  const [currentNDISDateTo,setcurrentNDISDateTo] = useState();
  const [currentNDISPlanManagementProvider,setcurrentNDISPlanManagementProvider] = useState();
  const [currentNDISDiagnosisHistory,setcurrentNDISDiagnosisHistory] = useState();
  const [currentNDISSupportCoordinator,setcurrentNDISSupportCoordinator] = useState();
  const [currentNDISSupportCoordinatorPhone,setcurrentNDISSupportCoordinatorPhone] = useState();
  const [currentNDISActualProviderFee,setcurrentNDISActualProviderFee] = useState()
  const [currentReferralGPPracticeFax,setcurrentReferralGPPracticeFax] = useState();
  const [currentConsentToShareWithGP,setcurrentConsentToShareWithGP] = useState();
  const [currentPronouns,setcurrentPronouns] = useState();
  const [currentHandOverNotes,setcurrentHandOverNotes] = useState(); 
  const [currentReferringGPName,setcurrentReferringGPName] = useState();
  const [currentReferringGPProviderNo,setcurrentReferringGPProviderNo] = useState();
  const [currentReferredGPOn,setcurrentReferredGPOn] = useState()
  const [currentAction,setcurrentAction] = useState('None')
  const [currentIndigenous,setcurrentIndigenous] = useState()
  const [currentProgramPopulation,setcurrentProgramPopulation] = useState([])
  const [currentProgram, setcurrentProgram] = useState(false)
  const [currentContractProviderFee, setcurrentContractProviderFee] = useState()
  const [currentRHCManagedLabel, setcurrentRHCManagedLabel] = useState(false)
  
  // console.log('Form Reload');

  const [key, setKey] = React.useState('personalDetails');

  var [showBooking, setShowBooking] = useState(false)
  var [showConfirmation,setShowConfirmation] = useState(false);
  var [booking, setBooking] = useState(false)
  var [toggleMessage, setToggleMessage] = useState(false)
  var [newNote, setNewNote] = useState('')
  var [sentMsg, setSentMsg] = useState()
  var [noteAction, setNoteAction] = useState('SMS')

  // const initBooking = {
  //   id: 0,
  //   status: '',
  //   psychologistDate: new Date(),
  //   endDate: new Date(),
  //   title: '',
  //   clientfullname: '',
  //   clientMobilePhone: '',
  //   psychologistfullname: '',
  // }

  const loadBooking = (thisBooking) => {
    setBooking(thisBooking);
    setNewNote(`Dear ${thisBooking.clientFirstName},

Please note you have been booked for a consultation with ${thisBooking.psychologistfullname} at ${format(new Date(thisBooking.clientDate ? thisBooking.clientDate: null), "dd-MM-yyyy hh:mm aa")}. 

Please connect here: ${thisBooking.waitingroomurl} at that time.`);
    setToggleMessage(false);
    setShowBooking(true);
  }


  

  function getActionButtons(id) {
    if (booking.status === 'Cancelled') {
      return(
        <>
          <Button variant="danger" size="sm" onClick={() => deleteBooking(id)}>
            Delete Booking
          </Button>
        </>
      )
    }
    else if (booking.status === 'Active') {
      return(
        <>
          <Button variant="warning" size="sm" onClick={() => cancelBooking(id)}>
            Cancel Booking
          </Button>
        </>
      )
    }
  }

  function cancelBooking(id) {
    dispatch(Admin.actions.putAppointment( 
      { id: id, 
        status: 'Cancelled', 
        refreshPayload: { key: 'psychologist', id: registered.id }
      }));
    setBooking({...booking, status: 'Cancelled'});
  }

  function deleteBooking(id) {
    dispatch(Admin.actions.putAppointment( 
      { id: id, 
        status: 'Deleted', 
        refreshPayload: { key: 'psychologist', id: registered.id }
      }));
    setBooking({...booking, status: 'Deleted'});
    setShowBooking(false)
  }

  function sendMessage() {
    const formData = new FormData();
    // Add fields 
    formData.append('type','Client');
    formData.append('pkid', booking.clientID);
    formData.append('subject','');
    formData.append('selector','SMS');
    formData.append('note',newNote);
    formData.append('faxdestination','');
    formData.append('faxFile','');
    formData.append('sendFormID',0);
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(...formData);
    dispatch(Admin.actions.postNote({ formData:formData, type: 'Client', pkID: booking.clientID}));
    setSentMsg('Message sent!')
    showMessageTimer();
  }

  const showMessageTimer =() => {
    const timer = setTimeout(() => {
      setSentMsg(undefined);
      setShowBooking(false)
      }, 3000);
      return () => clearTimeout(timer);
  }

  const loadToDos = (clientID, fullName) => {
    setToDoClientID(clientID)
    setToDoTitle(fullName)
    setShowToDo(true)
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', width: 250,
      renderCell: (params) => (
          <Grid container justifyContent="space-between">
            <Grid item onClick={() => loadClient(params.row.id)} className="cell-Link">
              <strong>
                {params.value} 
              </strong>
            </Grid>
            <Grid item>
              <Badge variant={params.row.toDoCount === 0 ? "secondary" : params.row.toDoWarning ? "warning" : "success"} onClick={()=>loadToDos(params.row.id, params.value)} style={{ marginRight: 2, cursor: 'pointer', minWidth: 21 }}>
                {params.row.toDoCount}
                {/* {params.row.toDoCount === 0 ?
                  <NoteAddIcon style={{ marginLeft: 8, color: 'green' }}  />
                : params.row.toDoCount } */}
                </Badge>              
            </Grid>
          </Grid>
        // <strong>
        //   <div onClick={() => loadClient(params.row.id)} className="cell-Link">
        //     {params.value}
        //   </div>
        // </strong>
      ),  },
    { field: 'email', headerName: 'Email', width: 230 },
    { field: 'mobilephone', headerName: 'Phone', width: 120 },
    // { field: 'medicareisvalid', headerName: 'Medicare', width: 130 },
    { field: 'createdon', type: 'date', headerName: 'Created', width: 120, hide: true },
    { field: 'nextBookingID', headerName: 'Next Booking ID', width: 120, hide: true },
    { field: 'status', headerName: 'Status', width: 100, hide: true },
    { field: 'topBookingPsy', type: 'date', headerName: 'Booking', width: 140, 
      // valueFormatter: ({ value }) => value ? format(new Date(value), "dd-MM-yyyy HH:mm") : null, 
      cellClassName: (params) => params.row.nextBookingID !== null ? 'future-booking' : null,
      renderCell: (params) => (
        <>
          {bookings && params.row.nextBookingID ?
            <div onClick={() => loadBooking(bookings.find(r => r.id === params.row.nextBookingID))} className="cell-Link"> 
              {format(new Date(params.row.topBookingPsy), "dd-MM-yyyy HH:mm")}
            </div>
          :
            params.row.topBookingPsy != null ? format(new Date(params.row.topBookingPsy), "dd-MM-yyyy HH:mm") : null
          }
        </>
      )
    },
    { field: 'fileCount', headerName: 'File Count', width: 120, hide: true },
    { field: 'totalbookings', headerName: 'Bookings', width: 125, hide: false },
    { field: 'files', headerName: 'Files', width: 120, hide: true },
    { field: 'none', headerName: 'Actions', width: 360,
      renderCell: (params) => (
        <>
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#faefcf", marginRight: 10 }} onClick={() => loadNotes(clients.find(client => client.id === params.row.id), 'SMS')}>
            SMS 
          </Button>
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#d2f9fa", marginRight: 10 }} onClick={() => loadNotes(clients.find(client => client.id === params.row.id), 'Email')}>
            Email 
          </Button>
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ebfae8", marginRight: 10 }} onClick={() => loadFiles(clients.find(client => client.id === params.row.id))}>
            Files 
          </Button>
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#fff0ed", marginRight: 10 }} onClick={() => loadNotes(clients.find(client => client.id === params.row.id), 'Fax')}>
            Fax 
          </Button>
          {bookings && params.row.nextBookingID ?
            <Button variant="secondary" disabled size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ffff00", marginRight: 10 }} onClick={() => loadArchive(clients.find(client => client.id === params.row.id), 'Archive')}>
              Archive 
            </Button>
          :
            <>
              {params.row.status === 'Active' ?
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ffff00", marginRight: 10 }} onClick={() => loadArchive(clients.find(client => client.id === params.row.id), 'Archived')}>
                  Archive 
                </Button>
              :
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ffff00", marginRight: 10 }} onClick={() => loadArchive(clients.find(client => client.id === params.row.id), 'Active')}>
                  UnArchive 
                </Button>
              }
            </>
          }
          {/* {params.row.fileCount > 0 ?  
          <>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#eee", marginRight: 10  }} {...bindTrigger(popupState)}>
                    Files
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    {params.row.files.map(file => (
                      <a href={file.downloadlink} key={file.filename}>
                        <MenuItem onClick={popupState.close}>{file.filename}</MenuItem>
                      </a>
                    )
                    )}
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </>
          : null } */}
        </>
      )
    }
  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }

  return (
    <>
     
      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item  style={{ paddingTop: 8, paddingRight: 50, paddingLeft: 4, color: "#646c9a" }}>
            <h4>Clients</h4>
          </Grid>
          <Grid item  style={{ marginTop: 2, paddingRight: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                  style={{ height: 37, maxWidth: 200 }}
                />
              </InputGroup>
          </Grid>
          <Grid item  style={{ marginTop: 2, paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsStatus-label" className={classes.label}>Status</InputLabel>
              <Select
                labelId="clientsStatus-label"
                id="clientsStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                value={filters.clientsStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Active'>Active</MenuItem>
                <MenuItem value='Archived'>Archived</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item style={{ marginTop: 2, paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsAOI-label" className={classes.label}>Area of Interest</InputLabel>
              <Select
                labelId="clientsAOI-label"
                id="clientsAOI"
                margin="dense"
                  value={searchAOI}
                onChange={(event) => setSearchAOI(event.target.value)}
                style={{ minWidth: 140, backgroundColor: "#ffffff" }}
              >
                <MenuItem value=''>All</MenuItem>
                {areasOfInterest.sort((a,b) => (a.issue > b.issue) ? 1 : ((b.issue > a.issue) ? -1 : 0)).map(row => {
                  return <MenuItem key={`key-${row.id}`} value={row.issue}>{row.issue}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGridPro
          rows={filteredClients} 
          columns={columns} 
          pagination={false}
          checkboxSelection={false} 
          sortModel={[{ field: 'topBookingPsy', sort: 'desc' }]} 
          getRowClassName={(params) => `datagrid-row-clients-${params.row.isNewClient.toString()}`} />
      </Paper>

      <Modal show={show} onHide={()=>setShow(false)} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Edit Profile - {currentFirstName} {currentSurname} {currentRHCManagedLabel ? <span style={{ marginLeft:10, color: 'blue' }}>(Managed by RHC)</span>  : null}  </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <Form onSubmit={putClient}>
            <Tabs
              id="EditModal"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="personalDetails" title="Personal" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="profileFirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Preferred Name</Form.Label>
                        <Form.Control type="text" name="profilePreferredName" defaultValue={currentPreferredName} onBlur={(event) => setcurrentPreferredName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Surname</Form.Label>
                        <Form.Control type="text"  name="profileSurname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    {(currentProviderType !== 'Supervision') ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>Gender</Form.Label>
                          <Form.Control as="select" defaultValue={currentGender} onBlur={(event) => setcurrentGender(event.target.value)}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                            <option value="Prefer not to say">Prefer not to say</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Pronouns</Form.Label>
                        <Form.Control type="text"  name="profilePronouns" defaultValue={currentPronouns} onBlur={(event) => setcurrentPronouns(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    {(currentProviderType !== 'Supervision') ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control type="date" name="profileDOB" defaultValue={currentDOB} onBlur={(event) => setcurrentDOB(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="profileEmail" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Contact Phone Number</Form.Label>
                        <Form.Control type="text" name="profileMobile" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>TimeZone</Form.Label>
                        <Autocomplete
                          id="profileTimeZone"
                          variant="outlined"
                          options={timeZone}
                          getOptionLabel={(option) => option.name}
                          value={timeZone.find(v => v.systemName === currentTimeZone) || ''}
                          onChange={(event, newValue) => handleTimeZoneAutocompleteChange('profileTimeZone', event, newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="TimeZoneSelector"
                              style={{backgroundColor: "white", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>Home Address</Form.Label>
                        <PlacesAutoComplete 
                          currentID={currentID}
                          currentfulladdress={currentfulladdress}
                          setcurrentfulladdress = {setcurrentfulladdress}
                          setcurrentLatitude = {setcurrentLatitude} 
                          setcurrentLongitude = {setcurrentLongitude}
                          setcurrentPostCode = {setcurrentPostCode}
                        />
                      </Form.Group>
                    </Col>
                    {(currentProviderType !== 'Supervision') ?
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control type="text" name="profileOccupation" defaultValue={currentOccupation} onBlur={(event) => setcurrentOccupation(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                    {(currentProviderType !== 'Financial' && currentProviderType !== 'Supervision') ?
                      <>
                        <Col md="3">
                          <Form.Group style={{width: "100%"}}>
                            <Form.Label>Funding Type</Form.Label>                        
                            <Form.Control as="select" defaultValue={currentPaymentType} onChange={(event) => setcurrentPaymentType(event.target.value)}>
                              <option value="Bulk Billed">Bulk Billed</option>
                              <option value="Reduced Gap">Reduced Gap</option>
                              <option value="Full Fee">Full Fee</option>
                              <option value="NDIS">NDIS</option>
                              <option value="Contract" disabled>Contract</option>
                              <option value="EAP" disabled>EAP</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Do you identify as Aboriginal and/or Torres Strait islander</Form.Label>
                        <Form.Control as="select" defaultValue={currentIndigenous} onBlur={(event) => setcurrentIndigenous(event.target.value)}>
                          <option value="N">No</option>
                          <option value="Y">Yes</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    {currentPaymentType === 'Contract' || currentPaymentType === 'EAP' ?
                      <Col md="2">
                       <Form.Group >
                          <Form.Label>Contract Provider Fee</Form.Label>
                          <Form.Control type="text"  name="contractproviderfee" defaultValue={currentContractProviderFee} readOnly />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                  </Row>
                  {programPopulationOptions && currentProgram ? 
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Do you identify as any of the following populations that we are particularly supporting via this program? (select all that apply)</Form.Label>
                          <Autocomplete
                            id="searchProgramPopulation"
                            variant="outlined"
                            multiple
                            freeSolo
                            options={programPopulationOptions}
                            getOptionLabel={(option) => option.name}
                            value={currentProgramPopulation}
                            onChange={(event, newValue) => setcurrentProgramPopulation(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="ProgramPopulationSelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }

                  {(currentProviderType !== 'GP' && currentProviderType !== 'Supervision') ?
                    <>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>Areas of Interest  (Select all that apply) </Form.Label>
                            <Autocomplete
                              id="searchAOI"
                              variant="outlined"
                              multiple
                              options={areasOfInterest}
                              getOptionLabel={(option) => option.issue }
                              value={currentAreasOfInterest}
                              onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="AOISelector"
                                  style={{backgroundColor: "", margin: 0, padding: 0}}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        {currentProviderType !== 'Supervision' ?  
                          <Form.Label>What are you hoping to get out of your sessions?</Form.Label>
                        :
                          <Form.Label>Details on the type of supervision you are after, where  you are currently practicing and other relevant information.</Form.Label>
                        }
                        <Form.Control as="textarea" rows={4} type="text" name="profilehopeToGetFromTreatment" defaultValue={currenthopeToGetFromTreatment} onBlur={(event) => setcurrenthopeToGetFromTreatment(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row>
                  {currentHandOverNotes && currentHandOverNotes.length > 2 ? 
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Referrer Handover Notes </Form.Label>
                          <Form.Control as="textarea" rows={4} type="text" name="profilehandOverNotes" readOnly defaultValue={currentHandOverNotes}  />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }
                  <Row>
                  </Row>
                  {/* <Row>
                    <Col md="10">
                      <Form.Group controlId="currentMedicalPractitioner.address">
                        <Form.Label>Address - may need to add this </Form.Label>
                          <Form.Control type="text" name="address" value={currentMedicalPractitioner ? currentMedicalPractitioner.address : ''} readOnly />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  {currentProviderType === 'Financial' ?
                    <>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control type="text" name="businessName" readOnly defaultValue={currentBusinessName || ''}  />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>ABN</Form.Label>
                            <Form.Control type="text"  name="abn" readOnly defaultValue={currentABN || ''} />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Business Address</Form.Label>
                            <Form.Control type="text"  name="businessAddress" readOnly defaultValue={currentBusinessAddress || ''} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>Background Information</Form.Label>
                            <Form.Control as="textarea" rows={4} type="text"  readOnly name="backgroundinformation" defaultValue={currentBackgroundInformation}  autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }

                  {(currentProviderType !== 'Financial' && currentProviderType !== 'Supervision') ?
                    <>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Emergency Contact 1</Form.Label>
                            <Form.Control type="text" name="emergencycontactname1" defaultValue={currentEmergencyContactName1 || ''} onBlur={(event) => setcurrentEmergencyContactName1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control type="text" name="emergencycontactrelation1" defaultValue={currentEmergencyContactRelation1 || ''} onBlur={(event) => setcurrentEmergencyContactRelation1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text"  name="emergencycontactphone1" defaultValue={currentEmergencyContactPhone1 || ''} onBlur={(event) => setcurrentEmergencyContactPhone1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                         <Col md="4">
                          <Form.Group >
                            <Form.Label>Emergency Contact 2</Form.Label>
                            <Form.Control type="text" name="emergencycontactname2" defaultValue={currentEmergencyContactName2 || ''} onBlur={(event) => setcurrentEmergencyContactName2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control type="text" name="emergencyrelation2" defaultValue={currentEmergencyContactRelation2 || ''} onBlur={(event) => setcurrentEmergencyContactRelation2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text"  name="emergencycontactphone2" defaultValue={currentEmergencyContactPhone2 || ''} onBlur={(event) => setcurrentEmergencyContactPhone2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {currentPaymentType === 'NDIS' ?
                    <>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>NDIS Number</Form.Label>
                            <Form.Control type="text"  name="ndisnumber" defaultValue={currentNDISNumber || ''} onBlur={(event) => setcurrentNDISNumber(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>NDIS Date From</Form.Label>
                            <Form.Control type="date" name="profileNDISDateFrom" defaultValue={currentNDISDateFrom} onBlur={(event) => currentNDISDateFrom(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>To</Form.Label>
                            <Form.Control type="date" name="profileNDISDateTo" defaultValue={currentNDISDateTo} onBlur={(event) => currentNDISDateTo(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Provider to Invoice</Form.Label>
                            <Form.Control type="text"  name="ndisactualproviderfee" defaultValue={currentNDISActualProviderFee || ''} readOnly />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>NDIS Plan Management Provider</Form.Label>
                            <Form.Control type="text" name="profileNDISPlanManagementProvider" defaultValue={currentNDISPlanManagementProvider} onBlur={(event) => setcurrentNDISPlanManagementProvider(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>NDIS Support Coordinator</Form.Label>
                            <Form.Control type="text" name="profileNDISSupportCoordinator" defaultValue={currentNDISSupportCoordinator} onBlur={(event) => setcurrentNDISSupportCoordinator(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>NDIS Support Coordinator Phone</Form.Label>
                            <Form.Control type="text" name="profileNDISSupportCoordinatorPhone" defaultValue={currentNDISSupportCoordinatorPhone} onBlur={(event) => setcurrentNDISSupportCoordinatorPhone(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>Medical Diagnoses/History</Form.Label>
                            <Form.Control as="textarea" rows={4} type="text"  readOnly name="currentNDISDiagnosisHistory" defaultValue={currentNDISDiagnosisHistory}  autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }

                  {(currentProviderType !== 'Financial')  ?
                    <>
                      <Row>
                        {(currentPaymentType !== 'Contract' && currentPaymentType !== 'NDIS' && currentPaymentType !== 'EAP' && currentProviderType !== 'Supervision') ?
                          <>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>Medicare Card Number</Form.Label>
                                <Form.Control type="text" name="medicareno" defaultValue={currentmedicareno || ''} onBlur={(event) => setcurrentmedicareno(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>Order on Card</Form.Label>
                                <Form.Control type="text"  name="medicarereferenceno" defaultValue={currentmedicarereferenceno || ''} onBlur={(event) => setcurrentmedicarereferenceno(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>Medicare Expiry Date</Form.Label>
                                <Form.Control type="text" name="medicareexpirydate" defaultValue={currentmedicareexpirydate || ''} onBlur={(event) => setcurrentmedicareexpirydate(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Appointment Type</Form.Label>
                            <Select 
                              variant="outlined"
                              margin="dense"
                              id='appointmentType'
                              aria-label='Select Appointment Type'
                              name='appointmentType'
                              value={currentAppointmentType} 
                              onChange={(event) => handleAppointmentTypeChange(event.target.value)}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff'  }}>
                              <MenuItem key='Video' value='0'>Video Call</MenuItem>
                              <MenuItem key='Phone' value='1'>Phone Call</MenuItem>
                            </Select>
                          </Form.Group>
                        </Col>                       
                      </Row>
                      {currentPaymentType === 'Bulk Billed' ||  currentPaymentType === 'Reduced Gap'?
                        <>                      
                          {currentPaymentType === 'Bulk Billed' ?
                            <>
                              <Row>
                                <Col md="3">
                                  <Form.Group >
                                    <Form.Label>Do you have a Health Care Card?</Form.Label>
                                    <Form.Control type="text" name="healthcarecard" readOnly defaultValue={currentHealthCareCard || ''}  />
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group >
                                    <Form.Label>Are you Unemployed?</Form.Label>
                                    <Form.Control type="text"  name="unemployed" readOnly defaultValue={currentUnemployed || ''} />
                                  </Form.Group>
                                </Col>
                                <Col md="6">
                                  <Form.Group >
                                    <Form.Label>Have you been impacted by drought, bushfire, flood or other natural disaster?</Form.Label>
                                    <Form.Control type="text"  name="naturaldisaster" readOnly defaultValue={currentNaturalDisaster || ''} />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </>
                          :
                            null
                          }
                          <Row>
                            <Col md="12">
                              <Form.Group >
                              <Form.Label>Please tell us why you need to be billed a reduced fee (or bulk billed)?</Form.Label>
                                <Form.Control as="textarea" rows={4} type="text"  readOnly name="bulkbilledreason" defaultValue={currentBulkBilledReason}  autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      :
                        null
                      }
                    </>
                  :
                    null
                  }
              </Tab>
              {(currentProviderType !== 'Financial' && currentProviderType !== 'Supervision') ?
                <Tab eventKey="clientGP" title="GP" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  <>
                  {/* Medical Practitioner */}
                    {(currentProviderType !== 'Provisional' && currentProviderType !== 'GP' && currentProviderType !== 'Social' && currentProviderType !== 'Occupational' && currentProviderType !== 'Nurse' ) ?
                      <>
                        <Row>
                          <Col md="4">
                            <h4>Client's GP</h4>
                          </Col>
                          <Col md="2" style={{ textAlign: "right"}}>
                            <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                          </Col>
                          <Col md="6">
                            <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                              <Autocomplete
                                id="profileMedicalPractitionerID"
                                variant="outlined"
                                options={medicalPractitioners}
                                getOptionLabel={(option) => option.fullname }
                                getOptionSelected={(option, value) => getMedicalPractitionerOption(option, value)}
                                value={currentMedicalPractitioner}
                                onChange={(event, newValue) => loadMedicalPractitioner(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="MedicalPractitionerSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerFirstName">
                              <Form.Label>Firstname</Form.Label>
                              <Form.Control type="text" name="firstName" value={currentMedicalPractitioner.firstName} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, firstName: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerSurname">
                              <Form.Label>Surname</Form.Label>
                              <Form.Control type="text" name="surname" value={currentMedicalPractitioner.surname} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, surname: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerMobilePhone">
                              <Form.Label>Mobile Phone</Form.Label>
                              <Form.Control type="text" name="mobilephone" value={currentMedicalPractitioner.mobilephone} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, mobilephone: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerProviderNo">
                              <Form.Label>Provider Number</Form.Label>
                              <Form.Control type="text" name="providernumber" value={currentMedicalPractitioner.providernumber} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, providernumber: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row> 
                        {/* Medical Practice */}  
                        <Row>
                          <Col md="4">
                            <h4>Practice</h4>
                          </Col>
                          <Col md="2" style={{ textAlign: "right"}}>
                            <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                          </Col>
                          <Col md="6">
                            <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                              <Autocomplete
                                id="profileMedicalPractice"
                                variant="outlined"
                                options={medicalPractices}
                                getOptionLabel={(option) => option.practicename }
                                getOptionSelected={(option, value) => getMedicalPracticeOption(option, value)}
                                value={currentMedicalPractice}
                                onChange={(event, newValue) => loadMedicalPractitice(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="MedicalPracticeSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col md="5">
                            <Form.Group >
                            <Form.Label>Clinic Name</Form.Label>
                              <Form.Control type="text" name="practicename" value={currentMedicalPractice ? currentMedicalPractice.practicename : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, practicename: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                            <Form.Label>ABN</Form.Label>
                              <Form.Control type="text" name="currentAbn" value={currentMedicalPractice ? currentMedicalPractice.abn : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, abn: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Contact Phone</Form.Label>
                              <Form.Control type="text" name="currentPhone" value={currentMedicalPractice ? currentMedicalPractice.phone : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, phone: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group >
                              <Form.Label>Clinic Address</Form.Label>
                              <Form.Control type="text" name="currentAddress1" value={currentMedicalPractice ? currentMedicalPractice.address1 : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, address1: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Clinic Fax</Form.Label>
                              <Form.Control type="text" name="currentFax" value={currentMedicalPractice ? currentMedicalPractice.fax : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, fax: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Clinic Email</Form.Label>
                              <Form.Control type="text" name="currentEmail" value={currentMedicalPractice ? currentMedicalPractice.email : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, email: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row> 
                      </>
                    :
                      <>
                        <Row>
                          <Col md="4">
                            <h4>Client's GP</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>GP Name</Form.Label>
                              <Form.Control type="text" name="profilegpname" defaultValue={currentGPName} onBlur={(event) => setcurrentGPName(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>GP Clinic Name</Form.Label>
                              <Form.Control type="text"  name="profilegpclinicname" defaultValue={currentGPClinicName} onBlur={(event) => setcurrentGPClinicName(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          {currentProviderType === 'GP' ?
                            <Col md="6">
                              <Form.Group >
                                <Form.Label>Client consent to share their Mental Health Treatment Plan with their GP</Form.Label>
                                <Form.Control as="select" name="profileconsentToShareWithGP" defaultValue={currentConsentToShareWithGP} onBlur={(event) => setcurrentConsentToShareWithGP(event.target.value)} >
                                  <option value=''></option>
                                  <option value='N'>No</option>
                                  <option value='Y'>Yes</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          :
                            null
                          }
                        </Row>
                      </>
                    }
                  </>
                </Tab>
              :
                null
              }
              <Tab eventKey="notes" title="Notes" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <Notes type="Client" pkid={currentClientID} firstName={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName} medicalPractice={currentMedicalPractice} waitingRoomURL={registered && (registered.teleHealthProvider === 'COVIU' ? registered.coviuRoomURL : registered.waitingRoomURL )} faxGP={currentReferralGPPracticeFax} />
              </Tab>
              <Tab eventKey="files" title="Files" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Files type="Client" subtype={currentPaymentType} pkid={currentClientID} />
              </Tab>
              {(currentProviderType !== 'Financial' && (currentProviderType !== 'GP')) ?
                <Tab eventKey="referrals" title="Referrals" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                  <ReferralMaintenance 
                    viewerFullName={`${currentFirstName} ${currentSurname}`}
                    clientID={currentClientID}
                    psychologistID={registered && registered.id}
                    type={'client'}
                  />
                </Tab>
              :
                null
              }
              <Tab eventKey="bookings" title="Bookings" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <BookingsList
                  type='client'
                  id={currentClientID}
                  userTZ={currentTimeZone}
                  view='psychologist'
                  providerType={currentProviderType}
                />
              </Tab>
              <Tab eventKey="billing" title="Billing" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <BookingBillingList
                  id={currentClientID}
                  clientFirstName={currentFirstName}
                  clientPreferredName={currentPreferredName}
                  clientSurname={currentSurname}
                  clientDOB={currentDOB}
                  clientEmail={currentEmail}
                  clientMobile={currentMobile}
                  clientMedicareNo={currentmedicareno}
                  clientMedicareReferenceNo={currentmedicarereferenceno}
                  clientMedicareExpiryDate={currentmedicareexpirydate}
                  clientreferringGPName={currentReferringGPName}
                  clientreferringGPProviderNo={currentReferringGPProviderNo}
                  clientreferredOn={currentReferredGPOn}
                />
              </Tab>
            </Tabs>
          </Form>                      
        </Modal.Body>
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putClient}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAppointment} onHide={() => setShowAppointment(false)} dialogClassName="editappointment-modal">
        <Modal.Header closeButton className="editappointment-modal">
          <Modal.Title>Appointments - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editappointment-modal">
          {registered ? 
            <BookingWeek
              mode="psychologist"
              psychologistID={registered && registered.id}
              psychologistFullname = {registered.firstname + ' ' + registered.surname}
              clientID = {currentClientID && currentClientID}
              clientFullname={currentFirstName && (currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName + ' ' + currentSurname : currentFirstName + ' ' + currentSurname)}
              clientFirstName={currentFirstName}
              clientPreferredName={currentPreferredName}
              currentHourlyRate = {registered.hourlyrate}
              currentWaitingRoomURL = {registered && (registered.teleHealthProvider === 'COVIU' ? registered.coviuRoomURL : registered.waitingRoomURL )}
              clientEmail = {currentEmail}
              psychologistTZ = {registered.timeZone}
              clientTZ={currentTimeZone}
              providerType={currentPaymentType}
              bookingType={currentClientBookingType}
              setShowAppointment={setShowAppointment}
            />
          : null}   
        </Modal.Body>
        <Modal.Footer className="editappointment-modal">
          <Button variant="secondary" onClick={() => setShowAppointment(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBooking} onHide={()=>setShowBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Booking - {booking.status}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          {!toggleMessage ?
          <Grid container spacing={3} style={{ height: 247 }}>
            <Grid item md={3} xs={6}>
              Provider:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {booking.psychologistfullname}
            </Grid>
            <Grid item md={3} xs={6}>
              Client:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {booking.clientfullname}
            </Grid>
            <Grid item md={3} xs={6}>
              Date:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy")}
            </Grid>
            <Grid item md={3} xs={6}>
              Time:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "h:mm aa")}
            </Grid>
            { booking.clientDate !== booking.psychologistDate ?
              <>
                <Grid item md={3} xs={6}>
                  Client's Time:
                </Grid>
                <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                  {format(new Date(booking.clientDate ? booking.clientDate: null), "h:mm aa")}
                </Grid>
              </>
            : null
            }
            {/* <Grid item md={3} xs={6}>
              Session URL:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              <a href={booking.waitingroomurl} target="_blank" rel="noopener noreferrer">
                {booking.waitingroomurl}
              </a>
            </Grid> */}
          </Grid>
          :
          sentMsg ?             
          <>
            <Row>
              <Col md="12" style={{ height: 222 }}>                  
              {sentMsg}
              </Col>
            </Row>
          </>
            :            
          <>
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={8} type="text" name="psychologistNote" defaultValue={newNote} onBlur={(event) => setNewNote(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                  {/* {newNoteHelpText.length ? (<p className='error-help'>{newNoteHelpText}</p>) : ''} */}
                </Form.Group>
              </Col>
            </Row>
          </>
          }
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
          {!toggleMessage ?
            <>
              <Grid item>
                {getActionButtons(booking.id)}
              </Grid>
              <Grid item>
                <Button variant="primary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} style={{ marginRight: 15 }}>
                  Message
                </Button>
                <Button variant="secondary" size="sm" onClick={() => setShowBooking(false)} className="lightButtonOutlinedLarge">
                  Close
                </Button>
              </Grid>
            </>
          :
            <>
              <Grid item>
                <Button variant="secondary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} className="lightButtonOutlinedLarge">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="success" size="sm" onClick={() => sendMessage()}>
                  Send &gt;
                </Button>
              </Grid>
            </>
          }
          </Grid>
        </Modal.Footer>
      </Modal>

      <Modal show={showNotes} onHide={()=>setShowNotes(false)} dialogClassName="notes-modal-dialog">
        <Modal.Header closeButton className="notes-modal">
          <Modal.Title>Notes - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="notes-modal">
          {registered ? 
            <Notes type="Client" noteAction={noteAction} pkid={currentClientID} firstName ={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName} faxGP={currentReferralGPPracticeFax} medicalPractice={currentMedicalPractice} waitingRoomURL={registered && (registered.teleHealthProvider === 'COVIU' ? registered.coviuRoomURL : registered.waitingRoomURL)} psychologistFullName={registered.firstname + ' ' + registered.surname} clientFirstName={currentFirstName} />
          : null}   
        </Modal.Body>
        <Modal.Footer className="notes-modal">
          <Button variant="secondary" onClick={() => setShowNotes(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFiles} onHide={()=>setShowFiles(false)} dialogClassName="notes-modal-dialog">
        <Modal.Header closeButton className="notes-modal">
          <Modal.Title>Files - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="notes-modal">
          {registered ? 
            <Files type="Client" subtype={currentPaymentType} pkid={currentClientID} />
          : null}   
        </Modal.Body>
        <Modal.Footer className="notes-modal">
          <Button variant="secondary" onClick={() => setShowFiles(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={()=>setShowConfirmation(false)} dialogClassName="notes-modal-dialog">
        <Modal.Header closeButton className="notes-modal">
          <Modal.Title>{currentAction === 'Active' ? 'Re activate - ' : 'Archive - '} {currentFirstName} {currentSurname} ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="notes-modal">
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putArchive}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showToDo} onHide={()=>setShowToDo(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>To Do - {toDoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          {toDoClientID && <ToDos clientID={toDoClientID} setShowToDo={setShowToDo} />}
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

