import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";

export const BASE_URL = process.env.REACT_APP_APIIP + "/register";

export const actionTypes = {
  // Init actiontypes to build reference data 
  SET_INITIAL: "SET_INITIAL",

  SET_REGSTATUS: "SET_REGSTATUS",

  POST_PSYCHOLOGISTINVITE_REQUEST : "POST_PSYCHOLOGISTINVITE_REQUEST",
  POST_REFERRERINVITE_REQUEST : "POST_REFERRERINVITE_REQUEST",
  FETCH_PSYCHOLOGISTINVITE: "FETCH_PSYCHOLOGISTINVITE",
  SET_PSYCHOLOGISTINVITE: "SET_PSYCHOLOGISTINVITE",
  POST_PSYCHOLOGIST_REGISTRATION : "POST_PSYCHOLOGIST_REGISTRATION",

  POST_CONTACTFORM_REQUEST : "POST_CONTACTFORM_REQUEST",
  FETCH_CLIENTINVITE: "FETCH_CLIENTINVITE",
  SET_CLIENTINVITE: "SET_CLIENTINVITE",
  PUT_CLIENTINVITE: "POST_CLIENTINVITE",
  // FETCH_CLIENTEAP: "FETCH_CLIENTEAP",
  // SET_CLIENTEAP: "SET_CLIENTEAP",
  // PUT_CLIENTEAP: "PUT_CLIENTEAP",

  POST_CLIENT_REGISTRATION : "POST_CLIENT_REGISTRATION",

  FETCH_MATCHINGAVAILABILITY_REGISTRATION: "FETCH_MATCHINGAVAILABILITY_REGISTRATION",
  SET_MATCHINGAVAILABILITY_REGISTRATION: "SET_MATCHINGAVAILABILITY_REGISTRATION",

  FETCH_REGISTEREDPUBLIC : "FETCH_REGISTEREDPUBLIC",

  FETCH_EMAILDUPLICATE : "FETCH_EMAILDUPLICATE",
  SET_EMAILDUPLICATE : "SET_EMAILDUPLICATE",
  SET_EMAILDUPLICATECLEAR: "SET_EMAILDUPLICATECLEAR",
  SET_REGISTEREDFLAG : "SET_REGISTEREDFLAG"
};

const initialState = {
  Registered: [],
  ClientInvite: {},
  ClientEAP: {},
  PsychologistInvite: {},
  InviteToken: '',
  // EAPToken: '',
  MatchingAvailability: [],
  EmailDuplicate: null,
  RegisteredFetch: false,
  regstatus: { form: 'client', status: 'Not Started', newID: null }
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_REGISTEREDPUBLIC': {
      return {
        ...state,
        Registered : action.payload.data
      };
    }
    case 'SET_PSYCHOLOGISTINVITE': {
      return {
        ...state,
        PsychologistInvite : action.payload.data, 
        InviteToken : action.payload.invitetoken
      };
    }
    case 'SET_MATCHINGAVAILABILITY_REGISTRATION': {
      return {
        ...state,
        MatchingAvailability : action.payload
      };
    }
    case 'SET_EMAILDUPLICATECLEAR':{
      return {
        ...state,
        EmailDuplicate: null, 
      };
    }
    case 'SET_EMAILDUPLICATE':{
      return {
        ...state,
        EmailDuplicate: action.payload, 
      };
    }
    case 'SET_REGISTEREDFLAG':{
      return {
        ...state,
        RegisteredFetch: action.payload, 
      };
    }
    case 'SET_CLIENTINVITE': {
      return {
        ...state,
        ClientInvite : action.payload.data, 
        InviteToken : action.payload.invitetoken
      };
    }
    // case 'SET_CLIENTEAP': {
    //   return {
    //     ...state,
    //     ClientEAP : action.payload.data, 
    //     EAPToken : action.payload.eaptoken
    //   };
    // }
    case 'SET_REGSTATUS': {
      return {
        ...state,
        regstatus : action.payload
      };
    }
    case 'SET_INITIAL': {
      return initialState;
    }
    default:
      return state;
  }
}

export function* fetchClientInvite(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/clientinvite/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_CLIENTINVITE', payload: { data: response.data[0], invitetoken: action.payload }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchEmailDuplicate(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/emailduplicate/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_EMAILDUPLICATE', payload: response.data[0]});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

// export function* fetchClientEAP(action)
// {
//   process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
//   try 
//   {
//     const newURL = BASE_URL + "/clienteap/" + action.payload;
//     const response = yield axios.get(newURL);
//     process.env.REACT_APP_DEBUG && console.log(response);
//     if(response.status === 200 && response.data.returnStatus !== "Error") 
//     {
//       yield put({ type: 'SET_CLIENTEAP', payload: { data: response.data[0], invitetoken: action.payload }});
//     } 
//     else // API completed with 200, however there is an error message
//     {
//       yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
//     }
//   } 
//   catch (error) // API call itself has errored out
//   {
//     yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
//   }
// }

export function* fetchPsychologistInvite(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/psychologistinvite/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_PSYCHOLOGISTINVITE', payload: { data: response.data[0], invitetoken: action.payload }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchRegisteredPublic(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    // Clear get registered flag
    yield put({ type: 'SET_REGISTEREDFLAG', payload: false });
    // 
    const newURL = BASE_URL + "/registered/" + action.payload.providerType + "/" + action.payload.inviteToken;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_REGISTEREDFLAG', payload: true });
      yield put({ type: 'SET_REGISTEREDPUBLIC', payload: { data: response.data }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchMatchingAvailability(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    // registration events will fire this sometimes before a psychologist and timezone have been set, so ignore those requests
    if(action.payload.psychologistID && action.payload.userTZ){      
      const newURL = BASE_URL + "/matchingavailability/" + action.payload.psychologistID + "/" + action.payload.userTZ + "/" + action.payload.invitetoken + "/" + action.payload.providerType;
      const response = yield axios.get(newURL);
      process.env.REACT_APP_DEBUG && console.log(response);
      if(response.status === 200 && response.data.returnStatus !== "Error") 
      {
        yield put({ type: 'SET_MATCHINGAVAILABILITY_REGISTRATION', payload: response.data});
      } 
      else // API completed with 200, however there is an error message
      {
        yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
      }
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}


// Put's below 

export function* postPsychologistInvite(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/psychologistinvite"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postReferrerInvite(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/referrerinvite"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postContactFormEntry(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/contactformentry"
    const response = yield axios.post(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postPsychologistData(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/psychologistdata"
    const response = yield axios.post(newURL, action.payload.formData);

    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      yield put({ type: 'SET_REGSTATUS', payload : { form: 'psychologist', status: 'Success', newID: response.data.newID } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* postClientData(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/clientdata"
    const response = yield axios.post(newURL, action.payload.formData);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      //  SET the reg form state with the new ClientID so that the UI knows it was completed successfully
      // yield put({ type: 'FETCH_CLIENTINVITE', payload : action.payload.invitetoken });
      yield put({ type: 'SET_REGSTATUS', payload : { form: 'client', status: 'Success', newID: response.data.newID } });
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putAvailability(action) {
  // ok lets update the server
  try 
  {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
    const newURL = BASE_URL + "/availability";
    const response = yield axios.put(newURL,action.payload);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log('ok saved');
      toast.success("Saved",{ autoClose:5000 });
      // Currently not refetching on change as calendar state should be able to handle change and slot id not needed 
      // yield put({ type: 'FETCH_AVAILABILITY', payload: action.payload.mainID});
    } 
    else // API completed with 200, however there is an error message
    {
      toast.error(`Save Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    toast.error("Save Failed: " + action.type);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export const actions = {
  postPsychologistInvite:         (payload) => ({ type: actionTypes.POST_PSYCHOLOGISTINVITE_REQUEST, payload : payload }),
  postReferrerInvite:             (payload) => ({ type: actionTypes.POST_REFERRERINVITE_REQUEST, payload : payload }),
  postContactFormEntry:           (payload) => ({ type: actionTypes.POST_CONTACTFORM_REQUEST, payload : payload }),
  fetchPsychologistInvite:        (payload) => ({ type: actionTypes.FETCH_PSYCHOLOGISTINVITE, payload : payload }),
  postPsychologistData:           (payload) => ({ type: actionTypes.POST_PSYCHOLOGIST_REGISTRATION, payload : payload }),

  fetchClientInvite:              (payload) => ({ type: actionTypes.FETCH_CLIENTINVITE, payload : payload }),
  // fetchClientEAP:              (payload) => ({ type: actionTypes.FETCH_CLIENTEAP, payload : payload }),
  postClientData:                 (payload) => ({ type: actionTypes.POST_CLIENT_REGISTRATION, payload : payload }),

  fetchMatchingAvailability:      (payload) => ({ type: actionTypes.FETCH_MATCHINGAVAILABILITY_REGISTRATION, payload : payload }),
  fetchRegisteredPublic:          (payload) => ({ type: actionTypes.FETCH_REGISTEREDPUBLIC, payload : payload }),
  emailDuplicate:                 (payload) => ({ type: actionTypes.FETCH_EMAILDUPLICATE, payload : payload }),
  emailDuplicateClear:            () => ({ type: actionTypes.SET_EMAILDUPLICATECLEAR }),
};

function* actionWatcher() {  
  yield takeLatest('POST_PSYCHOLOGISTINVITE_REQUEST',postPsychologistInvite);
  yield takeLatest('POST_REFERRERINVITE_REQUEST',postReferrerInvite);
  yield takeLatest('POST_CONTACTFORM_REQUEST',postContactFormEntry);
  yield takeLatest('FETCH_PSYCHOLOGISTINVITE',fetchPsychologistInvite);
  yield takeLatest('POST_PSYCHOLOGIST_REGISTRATION',postPsychologistData);

  yield takeLatest('FETCH_CLIENTINVITE',fetchClientInvite);
  // yield takeLatest('FETCH_CLIENTEAP',fetchClientEAP);
  yield takeLatest('POST_CLIENT_REGISTRATION',postClientData);
 
  yield takeLatest('FETCH_MATCHINGAVAILABILITY_REGISTRATION', fetchMatchingAvailability);
  yield takeLatest('FETCH_REGISTEREDPUBLIC',fetchRegisteredPublic);
  yield takeLatest('FETCH_EMAILDUPLICATE',fetchEmailDuplicate);
}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 
}


