import React, {useState} from "react"  
import {  useDispatch } from 'react-redux';
import * as Yup from "yup";
import { Formik } from 'formik';
import { TextField } from "@material-ui/core";
import { Button } from 'react-bootstrap'
import * as Auth from '../store/ducks/auth.duck';
import { Paper } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";


export default function UpdatePassword(props) {

  const dispatch = useDispatch()
  const [showPassword,setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    // Check existing state value
    if (showPassword)
    {
      setShowPassword(false)
    }
    else
    {
      setShowPassword(true)
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  function submitForm(values)
  {
    dispatch(Auth.actions.updatePassword(values))
    props.setShowUpdatePassword(false)
  }

  const validationSchema = Yup.object().shape({
    newPassword:  Yup.string()
      .min(8,"Too Short")
      .oneOf([Yup.ref('passwordConfirmation'), null], 'Passwords must match'),
    passwordConfirmation:  Yup.string()
      .min(8,"Too Short")
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  
  return (
    <>
      <Formik
        initialValues={{
          newPassword: '',
          passwordConfirmation: ''
        }}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
          return (
            <form noValidate={true} autoComplete="off" onSubmit={!isSubmitting ? handleSubmit : undefined} >
              <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type= {showPassword ? "text" : "password"}
                    margin="normal"
                    label="New Password"
                    className="kt-width-full"
                    inputProps={{ style: { backgroundColor: "white" }}}
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{backgroundColor: "white" }}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    type= {showPassword ? "text" : "password"}
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    inputProps={{ style: { backgroundColor: "white" }}}
                    name="passwordConfirmation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.passwordConfirmation}
                    helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                    error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                sx={{backgroundColor: "white" }}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                  />
                </div>
                <div className='kt-login__actions'>
                    <Button id='kt_login_signin_submit' type='submit' disabled={isSubmitting} variant='primary' >
                    Update
                    </Button>
                </div>
              </Paper>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
