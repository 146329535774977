import React, { useEffect } from "react";  
import { useSelector } from "react-redux";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import TopPsychologists from "../../widgets/TopPsychologists";
import QuickStat from "../../widgets/QuickStat";
import SalesBarChart from "../../widgets/SalesBarChart";
import CapacityBarChart from "../../widgets/CapacityBarChart";
import LatestNotes from "../../widgets/LatestNotes";
import BookingStatistics from "../../widgets/BookingStatistics";
import ClientStatistics from "../../widgets/ClientStatistics";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import * as Admin from "../../store/ducks/admin.duck";
import * as Reference from "../../store/ducks/reference.duck";
import '../../../_metronic/_assets/css/app2.css';

// background-image: url("/media/icons/background_fax.png")

export default function Dashboard() {
  
  const dashboardMetrics = useSelector(state => state.admin.Reports['dashboardMetrics'])
  const roleScope = useSelector(state => state.auth.roleScope)

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchReport({ reportname: 'dashboardMetrics', output: 'collection'})) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchContexts()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTags()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClientsByDay()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClientsByWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClientRegistrationsByDay()) }, [dispatch] )

  return (
    <>
      {roleScope !== 'Provisional' ?
        <>
          <div className="row">
            <div className="col-xl-12">
              <div className="row row-full-height">

                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true} className="quick-stat-fax">
                      <Link to="/psychologyclients">
                        <QuickStat
                          value={dashboardMetrics? dashboardMetrics['Clients Last 30 Days'] : 0}
                          desc="New Clients (Last 30 Days)"
                        />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div> 
                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true} className="quick-stat-client" >
                      <Link to="/psychologyclients">
                      <QuickStat
                        value={dashboardMetrics? dashboardMetrics['Clients All'] : 0}
                        desc="Clients"
                      />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div> 

                <div className="col-sm-12 col-md-12 col-lg-2"> 
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true}  className="quick-stat-registered">
                      <Link to="/registeredPsychologists">
                      <QuickStat
                        value={dashboardMetrics? dashboardMetrics['Psychologists All'] : 0}
                        desc="Registered Psychologists"
                      />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div> 
                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true}  className="quick-stat-interested">
                      <Link to="/interestedPsychologists">
                      <QuickStat
                        value={dashboardMetrics? dashboardMetrics['Psychologist Registrations Pending'] : 0}
                        desc="Interested Psychologists"
                      />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div>            
                <div className="col-sm-12 col-md-12 col-lg-2"> 
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true} className="quick-stat-referral">
                      <Link to="/clients">
                        <QuickStat
                          value={dashboardMetrics? dashboardMetrics['Past Bookings per Day'] : 0}
                          desc="Average Bookings in Last 30"
                        />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div> 
                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                    <PortletBody fluid={true} className="quick-stat-clientinvite">
                      <Link to="/clientInvites">
                        <QuickStat
                          value={dashboardMetrics? dashboardMetrics['Client Registrations Pending'] : 0}
                          desc="Client Invitations Pending"
                        />
                      </Link>
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </div>
          </div> 
          <Portlet>
            <PortletBody fit={true}>
              <div className="row row-no-padding row-col-separator-xl">
                {/* <div className="col-xl-4">
                  <OrdersWidget />
                </div> */}
                <div className="col-xl-12">
                  <SalesBarChart
                    title="Client Registrations"
                    desc="New Clients Per Day"
                    type='Clients'
                  />
                </div>
              </div>
            </PortletBody>
          </Portlet> 
          <Portlet>
            <PortletBody fit={true}>
              <div className="row row-no-padding row-col-separator-xl">
                {/* <div className="col-xl-4">
                  <OrdersWidget />
                </div> */}
                <div className="col-xl-12">
                  <SalesBarChart
                    title="Client Invites"
                    desc="New Client Invites Per Day"
                    type='Invites'
                  />
                </div>
              </div>
            </PortletBody>
          </Portlet> 
          <Portlet>
            <PortletBody fit={true}>
              <div className="row row-no-padding row-col-separator-xl">
                <div className="col-xl-12">
                  <CapacityBarChart
                    title="Client Registrations"
                    desc="New Clients Per Week"
                  />
                </div>
              </div>
            </PortletBody>
          </Portlet> 

          <div className="row">
            <div className="col-xl-6">
              <BookingStatistics />
            </div>
            <div className="col-xl-6">
              <ClientStatistics />
            </div>
          </div>
        </>
      :
        null
      }

      <div className="row">
        <div className="col-xl-8">
          <LatestNotes />
        </div>
        <div className="col-xl-4">
          <TopPsychologists />
        </div>
      </div>

    </>
  );
}
