import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Form, InputGroup, Row, Col,  } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Grid, FormControl, Select, InputLabel, MenuItem, makeStyles, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Admin from "../../../store/ducks/admin.duck";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hide } from '@popperjs/core';


const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

  const useStylesDatePicker = makeStyles({
    root: {
      "& .MuiInputBase-root": {
        height: 33,
      }
    }
  });

export default function GatherBillableAll() 
{
  const dispatch = useDispatch()
  const classes = useStyles();
  const classesDatePicker = useStylesDatePicker();

  const billableIntentsAll = useSelector(state => state.admin.BillableIntentsAll)
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists)
  const contracts = useSelector(state => state.admin.Contracts)
  const filters = useSelector(state => state.admin.Filters)
  const [searchTerm, setSearchTerm] = useState('')

  const initContract = () => {
    return { id:0, name: ''}
  }
  const [currentContract, setcurrentContract] = useState(initContract());
  const loadContract = (thisContract) => {
    setcurrentContract(thisContract ? thisContract : initContract())
  }


  useEffect( () => {
    dispatch(Admin.actions.fetchBillableIntentsAll({ startDate: filters.invoiceStartDate, endDate: filters.invoiceEndDate})) }, 
    [dispatch, filters.invoiceStartDate, filters.invoiceEndDate] 
  )
  useEffect( () => { dispatch(Admin.actions.fetchContracts()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )


  const searchBillableIntentsAll = (billableIntent) => {
    if ( true
        // && (billableIntent => billableIntent.psychologistName.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0)
        && (billableIntent.clientName.toLowerCase().search(searchTerm.toLowerCase()) > -1 
          || billableIntent.psychologistName.toLowerCase().search(searchTerm.toLowerCase()) > -1 
          || billableIntent.bookingid.toString().search(searchTerm.toLowerCase()) > -1 
          || billableIntent.contractName.toLowerCase().search(searchTerm.toLowerCase()) > -1 
          || searchTerm.length === 0)
        && (!filters.clientsPsychologist || filters.clientsPsychologist === 'All' || (filters.clientsPsychologist && billableIntent.psychologistid === filters.clientsPsychologist.id))          
        && (!filters.clientsContract || filters.clientsContract === 'All' || (filters.clientsContract && billableIntent.contractid && billableIntent.contractid === filters.clientsContract.id))   
        && (!filters.bookingPaymentType || filters.bookingPaymentType === 'All' || (billableIntent.bookingPaymentType === filters.bookingPaymentType))
        && (!filters.invoiceStatus || filters.invoiceStatus === 'All' || (billableIntent.billingstatus === filters.invoiceStatus))
        && (!filters.bookingValidationStatus || filters.bookingValidationStatus === 'All' || (filters.bookingValidationStatus === 'Issue' && billableIntent.validationStatus && billableIntent.validationStatus.length > 0))
      )
      {
        return billableIntent
      }  
  }
  
  const filteredbillableIntentsAll = billableIntentsAll.filter(billableIntent => searchBillableIntentsAll(billableIntent))
  const [showBooking, setShowBooking] = useState(false)
  const [clientName, setClientName] = useState()
  const [bookingID,setBookingID] = useState()
  const [bookingStatus,setBookingStatus] = useState()
  const [paymentType,setPaymentType] = useState()
  const [ndisPlanManagementProvider,setNDISPlanManagementProvider] = useState()
  const [ndisEmailForInvoices,setNDISEmailForInvoices] = useState()
  const [bookingNote,setBookingNote] = useState()
  const [validationStatus, setValidationStatus] = useState()


  const loadBooking = (thisBooking) => {
    setClientName(thisBooking.clientName)
    setBookingID(thisBooking.bookingid)
    setBookingStatus(thisBooking.bookingStatus)
    setcurrentContract(contracts.find(contract => contract.id === thisBooking.contractid))
    setPaymentType(thisBooking.bookingPaymentType)
    setNDISPlanManagementProvider(thisBooking.ndisPlanManagementProvider)
    setNDISEmailForInvoices(thisBooking.ndisEmailForInvoices)
    setBookingNote(thisBooking.bookingNote)
    setValidationStatus(thisBooking.validationStatus)
    setShowBooking(true);
  }

  function UpdateBooking() {
    // Create payload to send to API 
    var payload = {
        id : bookingID,
        paymenttype: paymentType,
        contractid : currentContract && currentContract.id,
        status : bookingStatus,
        refresh : 'ALL'
    }
    
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.updateBookingBilling(payload));
    setShowBooking(false)
  }

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }

  function handleBookingClose() {
    setShowBooking(false);
  }  

  const getContractOption = (option, value) => {
    // console.log(option);
    // console.log(value);
    if (option.id === value.id) {
      return option
    } else {
      return initContract();
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100,
      renderCell: (params) => (
        <>
          <strong>
            <div onClick={() => loadBooking(billableIntentsAll.find(booking => booking.id === params.row.id))} className="cell-Link" >
              {params.value}
            </div>
          </strong>
        </>
    )},
    { field: 'psychologistid', headerName: 'Psychologist ID', width: 100, hide: true },
    { field: 'psychologistSurname', headerName: 'psychologistSurname', width: 100,  hide: true },
    { field: 'psychologistName', headerName: 'Provider Name', width: 250,  
      renderCell: (params) => {
        return(
          <strong>
            { params.row.validationStatus && params.row.validationStatus.length > 0 ? 
              <>
                <span style={{ color: 'red' }}>{params.value}</span>
              </>
            :
              <>
                {params.value}
              </>
            }
          </strong>
        )
      }
    },
    { field: 'clientName', headerName: 'Client Name', width: 250   },
    { field: 'bookingDate', headerName: 'Booking Date', width: 150 },
    { field: 'bookingStatus', headerName: 'Booking Status', width: 170 },
    { field: 'bookingPaymentType', headerName: 'Payment Type', width: 180 },
    { field: 'contractid', headerName: 'Contract ID', width: 100, hide: true },
    { field: 'contractName', headerName: 'Contract', width: 150 },
    { field: 'ndisEmailForInvoices', headerName: 'NDIS Email', width: 150 },
    { field: 'billingstatus', headerName: 'Billing Status', width: 150 },
    { field: 'validationStatus', headerName: 'Validation', width: 150, hide: true }
  ];

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Review All Bookings</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
          {/* <Grid item style={{ marginTop: 2, marginLeft: 10 }}> */}
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                  style={{ height: 33 }}
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <Autocomplete
              id="searchPsychologist"
              variant="outlined"
              options={registered}
              getOptionLabel={(option) => ((filters.clientsPsychologist === 'All' && !option.fullname) || !option.fullname) ? 'All' : option.fullname}
              value={filters.clientsPsychologist === 'All' ? null : filters.clientsPsychologist}
              onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'clientsPsychologist', filterValue: newValue ? newValue : 'All' }))}
              style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Provider"
                  type="text"
                  variant="outlined"
                  margin="dense"
                  name="PsychologistSelector"
                  style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password'}}
                />}
            />
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <Autocomplete
              id="searchContract"
              variant="outlined"
              options={contracts}
              getOptionLabel={(option) => ((filters.clientsContract === 'All' && !option.name) || !option.name) ? 'All' : option.name}
              value={filters.clientsContract === 'All' ? null : filters.clientsContract}
              onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'clientsContract', filterValue: newValue ? newValue : 'All' }))}
              style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Contract/EAP"
                  type="text"
                  variant="outlined"
                  margin="dense"
                  name="ContractSelector"
                  style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password'}}
                />}
            />
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="PaymentType-label" className={classes.label}> Payment Type</InputLabel>
              <Select
                labelId="PaymentType-label"
                id="PaymentTypeFilter"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.bookingPaymentType}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'bookingPaymentType', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Full Fee'>Full Fee</MenuItem>
                <MenuItem value='Reduced Gap'>Reduced Gap</MenuItem>
                <MenuItem value='Reduced GapX'>'Reduced GapX</MenuItem>  
                <MenuItem value='Bulk Billed'>Bulk Billed</MenuItem>
                <MenuItem value='NDIS'>NDIS</MenuItem>
                <MenuItem value='Contract'>Contract</MenuItem>
                <MenuItem value='EAP'>EAP</MenuItem>
                <MenuItem value='Provisional'>Provisional</MenuItem>
                <MenuItem value='Supervision'>Supervision</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="invoiceStatus-label" className={classes.label}> Invoice Status</InputLabel>
              <Select
                labelId="InvoiceStatus-label"
                id="InvoiceStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.invoiceStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Billed'>Billed</MenuItem>
                <MenuItem value='Unbilled'>UnBilled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="ValidationStatus-label" className={classes.label}> Validation</InputLabel>
              <Select
                labelId="ValidationStatus-label"
                id="ValidationStatusFilter"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.bookingValidationStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'bookingValidationStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Issue'>Issue</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item style={{ paddingRight: 10 }}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="Start Date"
                value={filters.invoiceStartDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceStartDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{ paddingRight: 10 }}>
                <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="End Date"
                value={filters.invoiceEndDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceEndDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Paper>


      { billableIntentsAll ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredbillableIntentsAll} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} sortModel={[{ field: 'psychologistSurname', sort: 'asc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showBooking} onHide={handleBookingClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Update Booking Status and Funding - {clientName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <Row>
            <Col md="3">
              <Form.Group style={{width: "100%"}}>
                <Form.Label style={{ color: 'green', fontWeight: 500 }}>Booking Status</Form.Label>                        
                <Form.Control as="select" defaultValue={bookingStatus} onChange={(event) => setBookingStatus(event.target.value)}>
                  <option value="Active">Billable</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="NoShow">No Show</option>
                  <option value="Deleted" disabled>Deleted</option>                  
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group style={{width: "100%"}}>
                <Form.Label style={{ color: 'green', fontWeight: 500 }}>Funding Type</Form.Label>                        
                <Form.Control as="select" defaultValue={paymentType} onChange={(event) => setPaymentType(event.target.value)}>
                  <option value="Bulk Billed">Bulk Billed</option>
                  <option value="Reduced Gap">Reduced Gap (+$20)</option>
                  <option value="Full Fee">Full Fee</option>
                  <option value="NDIS">NDIS</option>
                  <option value="Contract">Contract</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {paymentType === 'Contract' ? 
              <Col md="6">
                <Form.Group style={{width: "100%"}}>
                  <Form.Label style={{ color: 'green', fontWeight: 500 }}>Contract</Form.Label>  
                    <Autocomplete
                      id="contractID"
                      variant="outlined"
                      options={contracts}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => getContractOption(option, value)}
                      value= {currentContract}
                      onChange={(event, newValue) => loadContract(newValue)}
                      style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="currentContactID"
                          style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 400  }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                </Form.Group>
              </Col>
            :
              null
            }
          </Row>
          {paymentType === 'NDIS' ? 
            <Row>
              <Col md="6">
                <Form.Group>
                  <Form.Label>NDIS Plan Management Provider</Form.Label>
                  <Form.Control type="text" name="ndisPlanManagementProvider" defaultValue={ndisPlanManagementProvider} readOnly />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Form.Label>NDIS Email for Invoices</Form.Label>
                  <Form.Control type="text" name="ndisEmailForInvoices" defaultValue={ndisEmailForInvoices} readOnly />
                </Form.Group>
              </Col>
            </Row>
          :
            null
          }
          {(paymentType === 'NDIS' || paymentType === 'Contract' || paymentType === 'EAP') ?           
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Booking Note</Form.Label>
                  <Form.Control as="textarea" rows={8} type="text" name="bookingNote" defaultValue={bookingNote}  />
                </Form.Group>
              </Col>
            </Row>
          :
            null
          }
          {validationStatus && validationStatus.length > 0 ?
            <Row>
              <Col md="12">
                <Form.Group>
                  <Form.Label>Warning</Form.Label>
                  <Form.Control type="text" name="validationStatus" defaultValue={validationStatus} readOnly />
                </Form.Group>
              </Col>
            </Row>
          :  
            null
          } 
        </Modal.Body>
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleBookingClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={UpdateBooking}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
