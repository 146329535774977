import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, InputGroup } from "react-bootstrap";
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import KTUtil from "../../_metronic/_assets/js/util";

/* Display instances of tags and values
   Let user add a tag by selecting a yet unused tag and entering a value

   Props are sent in an args object:
    args.context   - used to offer only relevant tags
    args.tagValues - existing tag values passed in
    args.setTagValues - function to set the values of the tags back to the caller
    args.display   - Layout and funcitonality is different for data entry on forms(entities) vs. a list where they are used as filters
    args.readOnly  - disable input for either permissions reasons or archived records
    args.tagValuesFilter - passed through to use when fetching after put in redux
    args.autoSave  - Mode where tags fetch and put their own values directly rather than from the caller
*/

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}));

export default function Tags(props) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const initDisplay = { layout: 'form', header: true }

  const context = props.args.context;
  const tagValues = props.args.tagValues;
  const setTagValues = props.args.setTagValues;
  const display = { ...initDisplay, ...props.args.display };
  const readOnly = props.args.readOnly ? true : false;
  const autoSave = props.args.autoSave ? true : false;
  const fkID = props.args.fkID;
  const tagValuesFilter = props.args.tagValuesFilter

  const contexts  = useSelector(state => state.reference.Contexts);
  const contextID = contexts.length > 0 && contexts.find(row => row.Context === context).ContextID;
  const alltags   = useSelector(state => state.reference.Tags);
  const autoitems = useSelector(state => state.admin.TagValues[context.replaceAll(" ", "")]);

  const [newTag, setnewTag] = useState();
  const [items, setItems] = useState(Array.isArray(tagValues) ? tagValues : []);

  // If auto save, then fetch tagValues directly
  useEffect( () => { autoSave && context && fkID && dispatch(Admin.actions.fetchTagValues({ context: context, fkID: fkID })) }, [dispatch, context, fkID, autoSave] )

  // when state differs from items, update to state values
  useEffect(() => {
    if(autoSave)
    {
      if(items && items !== autoitems){
        setItems(autoitems);
      }
    }
    else
    {
      if (items && items !== tagValues) {
        setItems(tagValues);
      }
    }
  }, [items, tagValues, autoSave, autoitems, setItems]);

  // filters tag array for data entry by removing those with values already (unless multiple values are allowed)
  const reftags = alltags.filter(tag => tag.contexts.find(row => row.context === context) && (!items.find(item => item.tagID === tag.id) || tag.allowMultipleValues ));
  const newTagValueRef = useRef(null);

  // If this is a list search, and no Tag is selected, then load the search tag (EntityName) system tag
  useEffect(() => {
    if (!newTag && display.layout === 'list') {      
      var newValue = reftags.find(row => row.systemName === 'EntityName')
      if(newValue){
        setnewTag(newValue)
      }      
    }
  }, [newTag, display, reftags]);

  // set focus to new tag value on tag selection
  useEffect(() => {
    if (newTag && newTag.dataType === 'General') {
      newTagValueRef.current.focus();
    }
  }, [newTag]);

  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  function selectNewTag(id) {
    var newValue = reftags.find(row => row.id === parseInt(id))
    setnewTag(newValue)
  }

  function clearNewTag()  {
    setnewTag();
  }

  function addTagValue(value) {
    if(value.length > 0)
    {
      var newItem = { id: makeid(6), tagID: newTag.id, tagName: newTag.tagName, contextID: contextID, tagValue: value }
      var tempItems = [ ...items ];
      tempItems.push(newItem);
      setItems(tempItems);
      clearNewTag();
      setTagValues && setTagValues(tempItems);
      autoSave && dispatch(Admin.actions.putTagValues({ tagValues: tempItems, context: context, contextID: contextID, fkID: fkID, tagValuesFilter: tagValuesFilter }))
    }
  }

  // merge in new item into items array
  function updateTagValue(id, value) {
    var tempItems = [ ...items ]
    tempItems.forEach(function(obj) {
      if (obj.id.toString() === id.toString()) {
          obj.tagValue = value;
      }
    });
    setItems(tempItems);
    setTagValues && setTagValues(tempItems);
    autoSave && dispatch(Admin.actions.putTagValues({ tagValues: tempItems, context: context, contextID: contextID, fkID: fkID, tagValuesFilter: tagValuesFilter }))
  }

  function deleteTagValue(deleteid) {
    var tempItems = items.filter((row) => row.id !== deleteid);
    setItems(tempItems);
    setTagValues && setTagValues(tempItems);
    autoSave && dispatch(Admin.actions.putTagValues({ tagValues: tempItems, context: context, contextID: contextID, fkID: fkID, tagValuesFilter: tagValuesFilter }))
  }

  function deleteAllTagValues() {
    setItems([]);
    setTagValues && setTagValues([]);
    autoSave && dispatch(Admin.actions.putTagValues({ tagValues: [], context: context, contextID: contextID, fkID: fkID, tagValuesFilter: tagValuesFilter }))
  } 

  // For Tag Data Entry - Text field
  function TagValueText(props) {
    var [newValue, setnewValue] = useState(props.thisTagValue.tagValue)     
    function onDataEntry(event) {
      if ((event.charCode || event.keyCode) === 13) { 
        changeText(event.target.value)
      }
    }

    function changeText(value) {
      if (props.thisTagValue.id) {
        updateTagValue(props.thisTagValue.id, value)
      }
      else {
        addTagValue(value)
      }
    }

    return (      
      <Form.Control 
        ref={newTagValueRef}
        type="text"
        defaultValue={newValue}
        readOnly={readOnly}
        onKeyPress={(event) => onDataEntry(event)}
        onInput={(event) => setnewValue(event.target.value)}
        onBlur={(event) => changeText(event.target.value)}
        autoComplete='OFF'
        className="tag-input"
      />
    )
  }

  // For Tag Data Entry - Select
  function TagValueSelect(props) {
    const change = (value) => {
      if (props.thisTagValue.id) {
        updateTagValue(props.thisTagValue.id, value)
      }
      else {
        addTagValue(value)
      }
    }
    return (
      <Form.Control               
        as="select"
        value={props.thisTagValue.tagValue}
        onChange={(event) => change(event.target.value)}
        autoComplete='OFF'
        className="tag-input"
        disabled={readOnly}
      >
        <option> - Select Value - </option>
        {props.thisTag.options.map(option => {
          return  <option key={option.id} value={option.tagValue} style={{ height: 35 }}>{option.tagValue}</option>
        })}
      </Form.Control>
    )
  }

  function SelectNewTag(props) {
    return (

      <InputGroup size="sm">
        <Form.Control
          as="select"
          onChange={(event) => selectNewTag(event.target.value)}
          autoComplete='OFF'
          className="tag-select-new"
          style={{ maxWidth: 130 }}
          value={newTag && newTag.id}
        >
          <option>Select Tag: </option>
          {reftags && reftags.map(option => {
            return !(display.layout === 'form' && option.systemName === 'EntityName') && <option key={option.id} value={option.id} style={{ height: 35 }}>{option.tagName}</option>       
          })}
        </Form.Control>
        <Form.Control 
          type="text"
          value=""
          readOnly
          className="tag-select-new-blank-input"
      />        
        {props.allowClear ?
        <InputGroup.Append onClick={()=> !readOnly ? deleteAllTagValues() : null } className="tag-input-append">
          <InputGroup.Text>
            <ClearIcon fontSize="small" style={{ marginRight: 5 }} /> <span style={{ fontWeight: 500 }}>Clear</span>
          </InputGroup.Text>
        </InputGroup.Append>
        : 
        <InputGroup.Append className="tag-input-append">
          <InputGroup.Text>
            <ClearIcon fontSize="small" style={{ color: "#f7f8fa" }} />
          </InputGroup.Text>
        </InputGroup.Append>
        }
      </InputGroup>
    )
  }

  function EnterNewTagValue() {
    return (
      <InputGroup size="sm">        
        <Form.Control
          as="select"
          onChange={(event) => selectNewTag(event.target.value)}
          autoComplete='OFF'
          className="tag-select-new"
          style={{ maxWidth: 130 }}
          value={newTag && newTag.id}
        >
          <option>Select Tag: </option>
          {reftags && reftags.map(option => {
            return !(display.layout === 'form' && option.systemName === 'EntityName') && <option key={option.id} value={option.id} style={{ height: 35 }}>{option.tagName}</option>       
          })}
        </Form.Control>
        {newTag.dataType === 'List' ?
            <TagValueSelect thisTag={newTag} thisTagValue={{ tagValue: '' }} />
          :
            <TagValueText thisTag={newTag} thisTagValue={{ tagValue: '' }} />
        }             
        <InputGroup.Append onClick={clearNewTag} className="tag-input-append">
          <InputGroup.Text>
            <ClearIcon fontSize="small" />
          </InputGroup.Text>
        </InputGroup.Append>  
      </InputGroup>
    )
  }

  function ListTagsForm () {
    return (
      <>
        <Grid container p={2} style={{ maxWidth: 450 }}>
        {display.header ?
          <Grid item md={12} style={{ marginTop: 10, fontWeight: 400, marginBottom: '0.5rem' }}>
            Tags
            <hr></hr>
          </Grid>
        :
          null
        }
          <Grid item md={12}>
            {items.map(({ id, tagName, tagValue, tagID }) => {
              var thisTag = alltags.find(ref => { return ref.id === tagID })
              return thisTag.systemName !== 'EntityName' && (
                <InputGroup size="sm" key={id}>
                  <InputGroup.Prepend className="tag-input-prepend">
                    <InputGroup.Text className="tag-input-prepend-text">
                      {thisTag && thisTag.tagName} :
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {
                    thisTag.dataType === 'List' ?
                    <TagValueSelect key={`tvs${id}`} thisTag={thisTag} thisTagValue={{ id, tagName, tagValue, tagID }} />
                  :
                    <TagValueText key={`tvt${id}`} thisTag={thisTag} thisTagValue={{ id, tagName, tagValue, tagID }} />            
                  }
                  <InputGroup.Append onClick={()=> !readOnly ? deleteTagValue(id) : null } className="tag-input-append">
                    <InputGroup.Text>
                      {!readOnly ? <ClearIcon fontSize="small" /> : null }
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              )
              }
            )}
            {items.length < 1 && <p>No tags yet.</p>}    
          </Grid>
          {!readOnly ?
            <>
            <Grid item md={12} style={{ marginTop: 10, fontWeight: 400, marginBottom: '0.5rem' }}>
              Add Tag
            </Grid>
            <Grid item md={12}>
              {!newTag ?
                <SelectNewTag allowClear={false} />
              :
                <EnterNewTagValue />
              }
            </Grid>
            </> 
          : null }
        </Grid>
      </>
    )
  }

  if (KTUtil.isEmpty(alltags)) {
    return <p>...loading Tags</p>
  }

  if (display.layout === 'form') 
  {
    return (
      <>
        {display.box ? 
          <div className="tag-box"> 
            <ListTagsForm />
          </div>
        :
            <ListTagsForm />
        }
      </>
    );
  }
  else if (display.layout === 'title') 
  {
    return (
      <>
        {/* <div style={{ marginLeft: 10 }}> */}
          {items.map(({ id, tagName, tagValue, tagID }) => {              
              var thisTag = alltags.find(ref => { return ref.id === tagID })
              return (
                <div key={`add_div${id}`} className="tag-title-item">
                  <Tooltip title={tagName} classes={{ tooltip: classes.tooltip }}> 
                      <span style={{ backgroundColor: thisTag.colour }}>
                        <LabelOutlinedIcon style={{ marginRight: 5, marginBottom: 2 }} />
                        <span className="tag-title-value-span">{tagValue}</span>
                      </span>
                  </Tooltip>
                </div>
              )}
            )}
        {/* </div> */}
      </>
    );
  }
  else // if not form, this is a list. Other uses may emerge later
  {
    return (
      <>
        <Grid container className="tag-search-container">
          <Grid item md={12}>
            <div className="tag-search-input-container">
              {!newTag ?
                <SelectNewTag allowClear={true} />
              :
                <EnterNewTagValue />
              }
            </div>
          </Grid>
          <Grid item md={12}>
            {items.map(({ id, tagName, tagValue, tagID }) => {              
              var thisTag = alltags.find(ref => { return ref.id === tagID })
              return (
                <div key={`add_div${id}`} className="tag-search-item">
                    {thisTag && thisTag.tagName}: <span style={{ fontWeight: 600 }}>{tagValue}</span> 
                    <HighlightOffIcon key={`add${id}`} onClick={()=>deleteTagValue(id)} className="tag-search-item-delete" />
                </div>
              )}
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  
}

