/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Interweave from 'interweave';
import * as Admin from "../../app/store/ducks/admin.duck";
import * as Reference from "../../app/store/ducks/reference.duck";
import { Card, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, Checkbox,CardMedia, CardContent, Chip, CardActions, makeStyles } from '@material-ui/core';
import { Row, Col, Badge, Modal, Button } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 200,
    // maxWidth: 200,
  },
  media: {
    height: 200,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function ClientActions(props) {
  
  const classes = useStyles();
  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchClients()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClientRegistered( {providertype : props.providerType === 'Supervision' ? 'Psychologist' : props.providerType })) }, [dispatch, props.providerType] )
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  
  const client = useSelector(state => state.admin.Clients[0]);
  const psychologists = useSelector(state =>  state.admin.ClientRegisteredPsychologists)
  const mypsychologist = client ? psychologists.find(v => v.id === client.currentpsychologistid) : null; 
  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  const [showProviderBio,setShowProviderBio] = useState(false)
  const [providerBioFullName,setProviderBioFullName] = useState()
  const [providerBioBio,setProviderBioBio] = useState()

  const moreProviderBio = (fullName, bio) => {
    // console.log('Read More Clicked')
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }
 
  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 

  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            {props.providerType === 'Supervision' ?
              <h3 className="kt-portlet__head-title">My Supervisor</h3>
            :
              <h3 className="kt-portlet__head-title">My Psychologist</h3>
            }
          </div>
           
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget4">
          {mypsychologist ? 
          <Grid item md={12} style={{ height: '50vh', width: "100%", paddingLeft: 20 }}>           
            <Card className={classes.root} variant="outlined">
              <CardMedia
                className={classes.media}
                image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${mypsychologist.imageURL}`}
                title="Psychologist"
              />
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item sm={9}>
                    <Typography variant="h5" component="h3">
                      {mypsychologist.fullname}
                    </Typography>
                  </Grid>
                  {props.providerType === 'Supervision' ?
                    <Grid item sm={3} style={{ textAlign: "right" }}>
                      <Typography variant="subtitle1">
                        {mypsychologist.supervisorHourlyRate}
                      </Typography>
                    </Grid>
                  :
                    <Grid item sm={3} style={{ textAlign: "right" }}>
                      <Typography variant="subtitle1">
                        {mypsychologist.hourly}
                      </Typography>
                    </Grid>
                  }
                </Grid>
                {props.providerType === 'Supervision' ?
                  <Grid container>
                    <Typography variant="body2" component="p">
                      <Interweave content={mypsychologist.supervisorBio.substring(0,700)} />
                      {mypsychologist.supervisorBio.length > 700 ?
                        <>
                          ...
                          <Badge variant="secondary" onClick={()=>moreProviderBio(mypsychologist.fullname, mypsychologist.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                            more
                          </Badge>
                        </>
                      :
                        null
                      }
                    </Typography>
                  </Grid>
                :
                  <Grid container>
                    <Typography variant="body2" component="p">
                      <Interweave content={mypsychologist.bio.substring(0,700)} />
                      {mypsychologist.bio.length > 700 ?
                        <>
                          ...
                          <Badge variant="secondary" onClick={()=>moreProviderBio(mypsychologist.fullname, mypsychologist.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                            more
                          </Badge>
                        </>
                      :
                        null
                      }
                    </Typography>
                  </Grid>
                }
                {props.providerType === 'Supervision' ?
                  <>  

                    <Typography variant="h6" component="h3">
                      Available For
                    </Typography>
                    <Row>
                      <Col md="6">
                        <List className={classes.root}>
                          {supervisionAreas.map(sRow => {
                            if(sRow.index <= supervisionAreas.length/2)
                            {
                              const labelId = `checkbox-list-label-${sRow.id}`;
                              var isChecked = mypsychologist.supervisorAreas.includes(sRow.id);
                              return (
                                <ListItem key={sRow.id} role={undefined} dense button >
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              );
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Col>
                      <Col md="6">
                        <List className={classes.root}>
                          {supervisionAreas.map(sRow => {
                            if(sRow.index > supervisionAreas.length/2)
                            {
                              const labelId = `checkbox-list-label-${sRow.id}`;
                              var isChecked = mypsychologist.supervisorAreas.includes(sRow.id);
                              return (
                                <ListItem key={sRow.id} role={undefined} dense button >
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              );
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Col>
                    </Row>
                    <Typography variant="h6" component="h3" >
                      Endorsed In 
                    </Typography>
                    <Row>
                      <Col md="6">
                        <List className={classes.root}>
                          {endorsedSupervisionAreas.map(sRow => {
                            var isChecked = mypsychologist.supervisorAreas.includes(sRow.id);
                            const labelId = `checkbox-list-label-${sRow.id}`;
                            if (isChecked) {
                              return(
                                <ListItem key={sRow.id} role={undefined} dense button >
                                  <ListItemIcon style={{ minWidth: 40 }}>
                                    <Checkbox
                                      edge="start"
                                      checked={isChecked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                      disableTypography 
                                      primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                      className={classes.link} />
                                </ListItem>
                              )
                            }
                            else
                            {
                              return null
                            }
                          })}
                        </List>
                      </Col>
                    </Row>
                  </>
                :
                  <Grid container>
                    {mypsychologist && mypsychologist.therapyTypes && mypsychologist.therapyTypes.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                  </Grid>
                }
              </CardContent>
              <CardActions>
                <Grid container justifyContent="space-between">
                <Grid item sm={12} >
                  <Link to="/search">
                    {props.providerType === 'Supervision' ?
                      <Button size="small" >Search Supervisors</Button>
                    :
                      <Button size="small" >Search Psychologists</Button>
                    }
                  </Link>
                </Grid>
                </Grid>
              </CardActions>
              
            </Card>
          </Grid>
          : (<div>Select Psychologist</div>)}

          </div>
        </div>
      </div>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
          <Modal.Header closeButton className="invite-modal">
            <Modal.Title>{providerBioFullName}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
            <Grid container >
              <Typography variant="body2" component="p">
                <Interweave content={providerBioBio} />
              </Typography>
            </Grid>
          </Modal.Body>
          <Modal.Footer className="invite-modal">
            <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}
