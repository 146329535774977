import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal } from "react-bootstrap";
import { Paper } from '@material-ui/core';
import StatisticsProviderDay from "./StatisticsProviderDay"


export default function StatisticsProvider({week, providerStatistics, closeProviders}) 
{
  const [showProvidersDay, setShowProvidersDay] = useState(false);
  const [providerStatisticsDay, setProviderStatisticsDay] = useState();
  const [providerName, setProviderName] = useState();
 

  const loadWeek = (thisProvider) => {
    setProviderStatisticsDay(thisProvider.statisticsProviderDay);
    setProviderName(thisProvider.providerName)
    setShowProvidersDay(true);
  }

  const columns = [
    { field: 'id', headerName: 'id', width: 100,  hide: true },
    { field: 'providerID', headerName: 'BookingID', width: 100,  hide: true },
    { field: 'providerName', headerName: 'Provider Name', width: 200,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => loadWeek(providerStatistics.find(provider => provider.providerID === params.row.providerID))} className="cell-Link" >
              {params.value}
            </div>
        </strong>
      ),  
    },
    { field: 'weekProviderBookingsTotal', headerName: 'Bookings', width: 130},
    { field: 'weekProviderSlotsTotal', headerName: 'Slots', width: 120},
    { field: 'weekProviderFreeSlotsTotal', headerName: 'Free Slots Total', width: 200},
    { field: 'weekProviderAnyFreeSlotsTotal', headerName: 'Free Slots (Any', width: 200},
    { field: 'weekProviderExistingFreeSlotsTotal', headerName: 'Existing', width: 120},
    { field: 'weekProviderSupervisionFreeSlotsTotal', headerName: 'Supervision)', width: 150}
  ];


  // public string weekProviderStart { get; set; }




  function handleProvidersDayClose() {
    setShowProvidersDay(false);
  }  

  return (
    <>
      { providerStatistics ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={providerStatistics} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} sortModel={[{ field: 'providerName', sort: 'asc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showProvidersDay} onHide={handleProvidersDayClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Daily Statistics for {providerName} for week Starting - {week}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <StatisticsProviderDay
            providerStatisticsDay={providerStatisticsDay}
          />
        </Modal.Body>
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleProvidersDayClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
