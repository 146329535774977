import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField, Paper, Grid, MenuItem, Select, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles, Card, CardContent, CardMedia, Badge, Chip} from '@material-ui/core';
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup, Image } from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';
import { DataGrid } from '@material-ui/data-grid';
import Interweave from 'interweave';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import * as Auth from "../../../store/ducks/auth.duck";
import AvailabilityNew from "../../../components/AvailabilityNew";
import PlacesAutoComplete from "../../../components/AddressAutoComplete";
import Masonry from 'react-masonry-css';
import ReferralsView from "../../../components/ReferralsView";
import BookingsList from "../../../components/BookingsList";
import Notes from "../../../components/Notes";
import Files from "../../../components/Files";

import { format } from "date-fns";
// import CardSetupForm from "../../../components/CardSetupForm";
import APIKeyForm from "../../../components/APIKeyForm";
// import InvoiceDetail from "../../../components/InvoiceDetail";
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  media: {
    height: 200,
  },
  root: {
    "& .MuiInputBase-root": {
      height: 33,
    }
  }  
}));


export default function RegisteredPsychologists() {
    
  const classes = useStyles();

  const areasOfInterestRAW = useSelector(state =>  state.reference.AreasOfInterest)
  const areasOfProviderInterest = areasOfInterestRAW.filter(aoi => aoi.providertype === 'ProviderInterest')
  const areasOfTherapyTypes = areasOfInterestRAW.filter(aoi => aoi.providertype === 'TherapyType')
  const areasOfInterest = areasOfInterestRAW.filter(aoi => aoi.providertype !== 'ProviderInterest')
  const aoi_focus = areasOfInterest.filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === 'Psychologist')
  const aoi_other = areasOfInterest.filter(aoi => aoi.focusAreaIndex === null && aoi.providertype === 'Psychologist')
  const FinancialAreasOfInterest = areasOfInterest.filter(area => area.providertype === 'Financial')
  const mbsServices = useSelector(state =>  state.reference.MBSServices)
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.Psychologist)
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists)
  const timeZone = useSelector(state =>  state.reference.TimeZone)
  const fundingTypes = useSelector(state =>  state.reference.FundingTypes)
  const userTZ = useSelector(state => state.auth.userTZ)
  const filters = useSelector(state => state.admin.Filters)
  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  
  
  var [showRegistrationView, setShowRegistrationView] = useState(false)   
  var [showProviderBio,setShowProviderBio] = useState(false)
  var [providerBioFullName,setProviderBioFullName] = useState()
  var [providerBioBio,setProviderBioBio] = useState()
  var [currentPaymentType,setCurrentPaymentType] = useState('Reduced Gap')
  var [currentSpecialistType,setcurrentSpecialistType] = useState()


  // const filteredRegistered = registered.filter(psychologist => (psychologist.fullname.toLowerCase().search(filters.registeredTerm.toLowerCase()) > -1 || filters.registeredTerm.length === 0) && (psychologist.providertype === filters.registeredProviderType || filters.registeredProviderType === 'All')) 

  

  const searchProviders = (provider) => {
    if( true
        && (provider.fullname.toLowerCase().search(filters.registeredTerm.toLowerCase()) > -1 || filters.registeredTerm.length === 0)
        && (filters.registeredStatus === 'All' || (filters.registeredStatus === 'Active' && provider.status === 'Active') || (filters.registeredStatus === 'Archived' && provider.status === 'Archived'))  
        && ((provider.providertype === filters.registeredProviderType) || (filters.registeredProviderType === 'All') || (filters.registeredProviderType === 'PsychologistClinical' && (provider.providertype ==='Psychologist' || provider.providertype === 'Clinical')) || (filters.registeredProviderType === 'Supervisor' && provider.isSupervisor === 'Y'))
        && (
               (!filters.registeredAOI) 
            || (filters.registeredAOI === 'All') 
            || (filters.registeredAOI === '') 
            || (filters.registeredAOI && provider.areasOfInterest.some(issue => issue === filters.registeredAOI.issue)))
        && (
               (!filters.registeredInterest) 
            || (filters.registeredInterest === 'All') 
            || (filters.registeredInterest === '')
            || (filters.registeredInterest && provider.areasOfInterest.some(issue => issue === filters.registeredInterest.issue)))
        && (
               (!filters.registeredTherapy) 
            || (filters.registeredTherapy === 'All') 
            || (filters.registeredTherapy === '') 
            || (filters.registeredTherapy && provider.areasOfInterest.some(issue => issue === filters.registeredTherapy.issue)))
          && (filters.registeredPsychologistsType === 'All' || (provider.specialisttype === filters.registeredPsychologistsType)) 
      )
    {
      return provider
    }  
  }

  const filteredRegistered = registered.filter(provider => searchProviders(provider))

  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 
  
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'Psychologist' })) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchMBSServices()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )

  var [show, setShow] = useState(false);
  // var [showCard, setShowCard] = useState(false);
  var [showAPIKey, setShowAPIKey] = useState(false);
  // var [showInvoiceDetail, setShowInvoiceDetail] = useState(false);

  function handleRegistrationViewClose() {
    setShowRegistrationView(false);
  }  

  function DeactivateMFA(psychologistID) {
    // Ok Time to Dispatch 
    setcurrentMfaConfigured('Not Configured')
    dispatch(Auth.actions.postDeactivateMFA({type : 'Provider', providerID : psychologistID}));
  }

  function handleClose() {
    setShow(false);
  }  

  // function handleCardClose() {
  //   setShowCard(false);
  // }  

  // function handleInvoiceDetailClose() {
  //   setShowInvoiceDetail(false);
  // }  

  function handleAPIKeyClose() {
    setShowAPIKey(false);
  }  

  const registrationView = (feeType) => {
    // Populate Modal which contains preview of Provider Registration Information
    setCurrentPaymentType(feeType)
    setShowRegistrationView(true)
  }

  
  const moreProviderBio = (fullName, bio) => {
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

    function handleTimeZoneAutocompleteChange(propertyName, event, newValue) {   
    // console.log(newValue);     
    if (newValue) {
      setcurrentTimeZone(newValue.systemName);
    }
  } 

  function handleFileFieldChangeNewInsuranceFile(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    setcurrentNewInsuranceFile(event.currentTarget.files[0]) 
  }

  function handleFileFieldChangeNewProfilePicture(propertyName, event) {  
    // console.log(event.currentTarget.files[0]);
    setcurrentNewProfilePicture(event.currentTarget.files[0]) 
  }

  function updatePassword() {
    // Put together packet
    var payload = {
      registeredid : currentPsychologistID,
      registeredexistingpassword : '',
      registeredpassword: currentNewPassword,
      registeredpasswordconfirmation: currentNewPasswordConfirmation
    }
    // Save new password for provider
    dispatch(Admin.actions.putProviderPassword(payload));
  }


  function putPsychologist() {
    // Move from useState to FormData 
    const formData = new FormData();
    // Merge AOI and Key AOI into one field 
    let mergedAOI = [];
    if (currentAreasOfInterest.length > 0)
    {
      mergedAOI = [...mergedAOI,...currentAreasOfInterest]; 
    }
    if (currentKeyAreas.length > 0)
    {
      mergedAOI = [...mergedAOI,...currentKeyAreas]; 
    }
    if (currentProviderInterest.length > 0 )
    {
      mergedAOI = [...mergedAOI,...currentProviderInterest ];
    }
    if (currentTherapyTypes.length > 0 )
    {
      mergedAOI = [...mergedAOI,...currentTherapyTypes ];
    }

    // 
    // Add fields 
    formData.append('psychologistID',currentPsychologistID)
    formData.append('areasOfInterest',mergedAOI.map(aoi => aoi.issue))
    formData.append('fundingTypes',currentFundingTypes.map(ft => ft.id))
    formData.append('mbsServices',currentMbsServices.map(mbs => mbs.id))
    formData.append('bio',currentBio)
    // Field currently readOnly - formData.append('documentLink',currentDocumentLink);
    formData.append('education',currentEducation)
    formData.append('firstName',currentFirstName)
    formData.append('gender',currentGender)
    formData.append('hourlyrate',currentHourly)
    formData.append('isReducedFee',currentIsReducedFee)
    formData.append('reducedFee',currentReducedFee)
    formData.append('hourlyRateOverrideGap',currentHourlyRateOverrideGap)
    formData.append('hourlyRateOverrideRebate',currentHourlyRateOverrideRebate)
    formData.append('reducedFeeOverrideGap',currentReducedFeeOverrideGap)
    formData.append('reducedFeeOverrideRebate',currentReducedFeeOverrideRebate)
    formData.append('startedpracticingyear',currentStartedPracticingYear)
    formData.append('email',currentEmail)
    formData.append('waitingRoomURL',currentWaitingRoomURL)
    formData.append('mobilephone',currentMobile)
    formData.append('insurancenumber',currentInsuranceNumber)
    formData.append('providernumber',currentProviderNumber)
    formData.append('medicalPracticeID',currentMedicalPracticeID)
    formData.append('imageURL',currentProfilePicture)
    formData.append('newInsuranceFile',currentNewInsuranceFile)
    formData.append('newProfilePicture',currentNewProfilePicture)
    formData.append('pdfPassword',currentPDFPassword)
    formData.append('status',currentStatus)
    formData.append('surname',currentSurname)
    formData.append('timeZone',currentTimeZone)
    formData.append('providertype',currentProviderType)
    formData.append('ahpraRegistrationNo',currentAHPRARegistrationNo)
    formData.append('coviuRoomURL',currentCOVIURoomURL)
    formData.append('teleHealthProvider',currentTeleHealthProvider)
    formData.append('testprovider',currentTestProvider)
    formData.append('isRegistrationBlocked',currentIsRegistrationBlocked)
    formData.append('eapContractNDISAvailability', currentEapContractNDISAvailability)
    formData.append('autoBulkBillingConsentForm',currentAutoBulkBillingConsentForm)
    // Handle Supervisor Fields 
    formData.append('isSupervisor',currentIsSupervisor)
    formData.append('supervisorBio',currentSupervisorBio)
    formData.append('supervisorEducation',currentSupervisorEducation)
    formData.append('supervisorHourlyRate',currentSupervisorHourlyRate)
    formData.append('supervisorAreas',currentSupervisorAreas)
    formData.append('specialisttype', currentSpecialistType)
    formData.append('facetoFaceBooking',currentFaceToFaceBooking)
    // Patch for bug where the address autocomplete sets the currentmedicalpractice, yet it reverts
    var medicalPractice = newpracticeaddress !== '' ? {...currentMedicalPractice, address1: newpracticeaddress} : currentMedicalPractice
    formData.append('medicalPractice', JSON.stringify(medicalPractice))
    // Check if there is an update or insert 
    process.env.REACT_APP_DEBUG && console.log(...formData)
    process.env.REACT_APP_DEBUG && console.log ('Putting (Update)')
    // Save update 
    dispatch(Admin.actions.putPsychologistDetail({formData}))
    // Close form
    setShow(false);
  }

  function InsuranceDownload(props) {
    // Check to see if link is populated
    if (!props.documentLink)
    {
      return(null)
    }
    //
    return (
      <Button variant="info" size="sm" href={props.documentLink} style={{ marginTop: 5, paddingTop: 6 }}>
        <DescriptionIcon fontSize="small" style={{ marginRight: 6, marginTop: -1 }} /> Download
      </Button>
    )
  }
  
  const loadAPIKey = (thisPsychologist) => {
    setcurrentFirstName(thisPsychologist.firstname);
    setcurrentSurname(thisPsychologist.surname);
    setcurrentPsychologistID(thisPsychologist.id);
    // Load Form
    setShowAPIKey(true);
  }

  function getIndex(value, arr, prop) {
    for(var i = 0; i < arr.length; i++) {
        if(arr[i][prop] === value) {
            return i;
        }
    }
    return -1; // where the value doesn't exist
  }

  const handleSupervisorAreaToggle = (value) => () => {
    const currentIndex = getIndex(value.id, currentSupervisorAreas, 'id') //checked.find(r => r.id === value.id);
    const newChecked = [...currentSupervisorAreas];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCurrentSupervisorAreas(newChecked);
  };



  // const loadInvoiceDetail = (thisInvoice) => {
  //   setcurrentInvoiceID(thisInvoice.id);
  //   setcurrentInvoiceItems(thisInvoice.invoiceitems);
  //   setShowInvoiceDetail(true);
  // }


  const loadProfile = (thisRegistered) => {
    // dispatch(Admin.actions.fetchAvailability({ psychologistid: thisRegistered.id, userTZ: userTZ }));
    var tempMedicalPractice = thisRegistered ? medicalPractices.find(v => v.id === thisRegistered.medicalPracticeID) : null;
    loadMedicalPractitice(tempMedicalPractice)
    setcurrentMedicalPracticeID(thisRegistered.medicalPracticeID)
    setcurrentPsychologistID(thisRegistered.id)
    setcurrentFirstName(thisRegistered.firstname)
    setcurrentSurname(thisRegistered.surname)
    setcurrentGender(thisRegistered.gender)
    setcurrentEmail(thisRegistered.email)
    setcurrentMobile(thisRegistered.mobilephone)
    setcurrentBio(thisRegistered.bio)
    setcurrentProviderNumber(thisRegistered.providernumber)
    setcurrentSpecialistType(thisRegistered.specialisttype)
    setcurrentEducation(thisRegistered.education)
    setcurrentPDFPassword(thisRegistered.pdfPassword)
    // Remove Prefix $ character from Fields 
    setcurrentIsReducedFee(thisRegistered.isReducedFee)
    setcurrentHourly(thisRegistered.hourlyrate.substring(0,1) === '$' ? thisRegistered.hourlyrate.substring(1) : thisRegistered.hourlyrate)
    setcurrentReducedFee(thisRegistered.reducedFee.substring(0,1) === '$' ? thisRegistered.reducedFee.substring(1) : thisRegistered.reducedFee)
    setcurrentHourlyRateOverrideGap(thisRegistered.hourlyRateOverrideGap.substring(0,1) === '$' ? thisRegistered.hourlyRateOverrideGap.substring(1) : thisRegistered.hourlyRateOverrideGap)
    setcurrentHourlyRateOverrideRebate(thisRegistered.hourlyRateOverrideRebate.substring(0,1) === '$' ? thisRegistered.hourlyRateOverrideRebate.substring(1) : thisRegistered.hourlyRateOverrideRebate)
    setcurrentReducedFeeOverrideGap(thisRegistered.reducedFeeOverrideGap.substring(0,1) === '$' ? thisRegistered.reducedFeeOverrideGap.substring(1) : thisRegistered.reducedFeeOverrideGap)
    setcurrentReducedFeeOverrideRebate(thisRegistered.reducedFeeOverrideRebate.substring(0,1) === '$' ? thisRegistered.reducedFeeOverrideRebate.substring(1) : thisRegistered.reducedFeeOverrideRebate)
    setcurrentMedicareRebate(thisRegistered.medicareRebate)
    setcurrentStandardFeeOutOfPocket(thisRegistered.stanardFeeOutofPocket)
    setcurrentReducedFeeOutOfPocket(thisRegistered.reducedFeeOutofPocket)
    //
    setcurrentStartedPracticingYear(thisRegistered.startedpracticingyear);
    setcurrentInsuranceNumber(thisRegistered.insurancenumber);
    setcurrentDocumentLink(thisRegistered.documentLink);
    setcurrentStatus(thisRegistered.status);
    setcurrentProfilePicture(thisRegistered.imageURL);
    setcurrentMfaConfigured(thisRegistered.mfaconfigured);
    setcurrentFaceToFaceBooking(thisRegistered.facetoFaceBooking)
    var ftypes = fundingTypes.filter(( {id} ) => thisRegistered.fundingTypes.includes(id))
    setcurrentFundingTypes(ftypes);
    var aoi = aoi_focus.filter(( {issue} ) => thisRegistered.areasOfInterest.includes(issue))
    setcurrentAreasOfInterest(aoi);
    var keyaoi = aoi_other.filter(( {issue} ) => thisRegistered.areasOfInterest.includes(issue))
    setcurrentKeyAreas(keyaoi);
    var mbs = mbsServices.filter(( {id} ) => thisRegistered.mbsServices.includes(id))
    setcurrentMbsServices(mbs);
    var providerInterest = areasOfProviderInterest.filter(( {issue} ) => thisRegistered.areasOfInterest.includes(issue))
    setcurrentProviderInterest(providerInterest);
    var therapyTypes = areasOfTherapyTypes.filter(( {issue} ) => thisRegistered.areasOfInterest.includes(issue))
    setcurrentTherapyTypes(therapyTypes);
    // setcurrentCcExpiry(thisRegistered.ccExpiry);
    // setcurrentCcLast4(thisRegistered.ccLast4);
    setcurrentTimeZone(thisRegistered.timeZone);
    setcurrentWaitingRoomURL(thisRegistered.waitingRoomURL);
    // setcurrentInvoices(thisRegistered.invoices);
    setcurrentAPIKey(thisRegistered.medipassapikey);
    setcurrentProviderType(thisRegistered.providertype);
    setcurrentProviderDescription(thisRegistered.providerdescription);
    setcurrentAHPRARegistrationNo(thisRegistered.ahpraRegistrationNo);
    setcurrentCOVIURoomURL(thisRegistered.coviuRoomURL);
    setcurrentTeleHealthProvider(thisRegistered.teleHealthProvider)
    setcurrentTestProvider(thisRegistered.testprovider)
    setcurrentIsRegistrationBlocked(thisRegistered.isRegistrationBlocked)
    setcurrentEapContractNDISAvailability(thisRegistered.eapContractNDISAvailability)
    setcurrentAutoBulkBillingConsentForm(thisRegistered.autoBulkBillingConsentForm)
    // Supervisor Fields
    setCurrentIsSupervisor(thisRegistered.isSupervisor)
    setCurrentSupervisorBio(thisRegistered.supervisorBio)
    setCurrentSupervisorEducation(thisRegistered.supervisorEducation)
    setCurrentSupervisorHourlyRate(thisRegistered.supervisorHourlyRate)
    setCurrentSupervisorAreas(thisRegistered.supervisorAreas)
    // setcurrentInvoiceColumns([
    //   { field: 'id', headerName: 'ID', width: 100,  
    //     renderCell: (params) => (
    //       <strong>
    //         <div onClick={() => loadInvoiceDetail(thisRegistered.invoices.find(invoice => invoice.id === params.row.id))} className="cell-Link">
    //           {params.value}
    //         </div>
    //       </strong>
    //     ),  
    //   },
    //   { field: 'invoiceDate', type: 'date', headerName: 'Date', width: 210 },
    //   { field: 'total', headerName: 'Total', width: 250 },
    //   { field: 'status', headerName: 'Status', width: 200 },
    // ]);
    setcurrentNewPassword(null);
    setcurrentNewPasswordConfirmation(null);

    // Load Form
    setShow(true);
  }
       
  var [newpracticeaddress, setnewpracticeaddress] = useState(''); // for weird bug in Autocompleteaddress where setting state gets undone
  var [currentMedicalPractice, setcurrentMedicalPractice] = useState({ address1: '' });
  var [currentMedicalPracticeID, setcurrentMedicalPracticeID] = useState();
  var [currentTimeZone, setcurrentTimeZone] = useState();
  var [currentPsychologistID,setcurrentPsychologistID] = useState();
  var [currentFirstName,setcurrentFirstName] = useState();
  var [currentSurname,setcurrentSurname] = useState();
  var [currentGender, setcurrentGender] = useState();
  var [currentEmail,setcurrentEmail] = useState();
  var [currentMobile,setcurrentMobile] = useState();
  var [currentBio,setcurrentBio] = useState();
  var [currentEducation,setcurrentEducation] = useState();
  var [currentPDFPassword,setcurrentPDFPassword] = useState();
  //
  var [currentHourly,setcurrentHourly] = useState();
  var [currentIsReducedFee,setcurrentIsReducedFee] = useState();
  var [currentHourlyRateOverrideGap,setcurrentHourlyRateOverrideGap] = useState();
  var [currentHourlyRateOverrideRebate,setcurrentHourlyRateOverrideRebate] = useState();
  var [currentReducedFeeOverrideGap,setcurrentReducedFeeOverrideGap] = useState();
  var [currentReducedFeeOverrideRebate,setcurrentReducedFeeOverrideRebate] = useState();
  var [currentReducedFee,setcurrentReducedFee] = useState();
  var [currentMedicareRebate,setcurrentMedicareRebate] = useState()
  var [currentStandardFeeOutOfPocket,setcurrentStandardFeeOutOfPocket] = useState()
  var [currentReducedFeeOutOfPocket,setcurrentReducedFeeOutOfPocket] = useState()
  //
  var [currentInsuranceNumber,setcurrentInsuranceNumber] = useState();
  var [currentProviderNumber,setcurrentProviderNumber] = useState(); 
  var [currentDocumentLink,setcurrentDocumentLink] = useState();
  var [currentNewInsuranceFile,setcurrentNewInsuranceFile] = useState();
  var [currentStatus,setcurrentStatus] = useState();
  var [currentProviderType,setcurrentProviderType] = useState();
  var [currentAreasOfInterest,setcurrentAreasOfInterest] = useState();
  var [currentKeyAreas,setcurrentKeyAreas] = useState();
  var [currentProviderInterest,setcurrentProviderInterest] = useState();
  var [currentTherapyTypes,setcurrentTherapyTypes] = useState();
  var [currentMbsServices,setcurrentMbsServices] = useState();
  var [currentFaceToFaceBooking,setcurrentFaceToFaceBooking] = useState()
  // var [currentCcExpiry,setcurrentCcExpiry] = useState();
  // var [currentCcLast4,setcurrentCcLast4] = useState();
  var [currentFundingTypes,setcurrentFundingTypes] = useState([]); 
  // var [currentInvoices, setcurrentInvoices] = useState([]); 
  // var [currentInvoiceColumns,setcurrentInvoiceColumns] = useState([]);
  var [currentWaitingRoomURL,setcurrentWaitingRoomURL] = useState();
  var [currentProfilePicture,setcurrentProfilePicture] = useState();
  var [currentNewProfilePicture,setcurrentNewProfilePicture] = useState();
  var [currentStartedPracticingYear,setcurrentStartedPracticingYear] = useState(2000);
  // var [currentInvoiceID,setcurrentInvoiceID] = useState();
  // var [currentInvoiceItems,setcurrentInvoiceItems] = useState();
  var [currentProviderDescription,setcurrentProviderDescription] = useState();
  var [currentAPIKey,setcurrentAPIKey] = useState();
  var [currentNewPassword,setcurrentNewPassword] = useState();
  var [currentNewPasswordConfirmation,setcurrentNewPasswordConfirmation] = useState();
  const [currentAHPRARegistrationNo,setcurrentAHPRARegistrationNo] = useState();
  const [currentCOVIURoomURL,setcurrentCOVIURoomURL] = useState();
  const [currentMfaConfigured,setcurrentMfaConfigured] = useState();
  const [currentTeleHealthProvider,setcurrentTeleHealthProvider] = useState();
  const [currentTestProvider,setcurrentTestProvider] = useState();
  const [currentIsRegistrationBlocked,setcurrentIsRegistrationBlocked] = useState();
  const [currentEapContractNDISAvailability,setcurrentEapContractNDISAvailability] = useState()
  const [currentAutoBulkBillingConsentForm,setcurrentAutoBulkBillingConsentForm] = useState()
  // Supervisor Fields
  const[currentIsSupervisor,setCurrentIsSupervisor] = useState()
  const[currentSupervisorBio,setCurrentSupervisorBio] = useState()
  const[currentSupervisorEducation,setCurrentSupervisorEducation] = useState()
  const[currentSupervisorHourlyRate,setCurrentSupervisorHourlyRate] = useState()
  const[currentSupervisorAreas,setCurrentSupervisorAreas] = useState()
  const [key, setKey] = React.useState('personalDetails');

  function handleTestProviderChange(value) {
      setcurrentTestProvider(value);
  }
  
  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', sortable: true, width: 190,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadProfile(registered.find(psychologist => psychologist.id === params.row.id))} className="cell-Link">
          {params.value}
        </div>
      </strong>
    ),  },
    { field: 'email', headerName: 'Email', width: 250,
      renderCell: (params) => (
        <CopyToClipboard text={params.row.email}>
          <span  className="cell-Link" style={{ fontWeight: 500}}>{params.row.email}</span>
        </CopyToClipboard>
      )  
    },
    { field: 'hourlyrate', headerName: 'Hourly', width: 110 },
    { field: 'practicename', headerName: 'Clinic', width: 250 },
    { field: 'referralCount', headerName: 'Clients', width: 110 },
    { field: 'providertype', headerName: 'Type', width: 100 },
    { field: 'created', headerName: 'Joined', sortable: true, width: 135,  renderCell: (params) => (format(new Date(params.row.created), "dd-MM-yyyy")), cellClassName: 'grid-mono-font' },
  ]; 

  const initMedicalPractice = () => {
    return { id: 0, abn: '', practicename: '', phone: '', address1: '', email: '', website: '', logoURL: '', practiceType: 'Psychologist' }
  }
  
  const getMedicalPracticeOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractice();
    }
  }

  const loadMedicalPractitice = (thisMedicalPractice) => {
    setcurrentMedicalPractice(thisMedicalPractice ? thisMedicalPractice : initMedicalPractice())
  }


  const handleToggle = () => {
    if (currentIsReducedFee === 'Y')
    {
      setcurrentIsReducedFee('N')
    }
    else
    {
      setcurrentIsReducedFee('Y')
    }
  };

  return (
    <>
      <h4>
        Registered Providers
      </h4>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 2 }}>      
            <Grid container> 
              <Grid item>           
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <SearchIcon fontSize="small" style={{ marginLeft: -3 }} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control                  
                      type="text"
                      value={filters.registeredTerm}
                      onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'registeredTerm', filterValue: event.target.value }))}
                      autoComplete='OFF'
                      style={{  height: 35, backgroundColor: filters.registeredTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                    />
                    <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'registeredTerm', filterValue: '' }))}>
                      <InputGroup.Text>
                        <ClearIcon fontSize="small" style={{ color: filters.registeredTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
              </Grid>
              <Grid item style={{ marginLeft: 10, paddingRight: 10 }}>  
                <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.registeredProviderType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'registeredProviderType', filterValue: event.target.value }))}>
                  <option value="All">All</option>
                  <option value="PsychologistClinical">Psychologist/Clinical</option>
                  <option value="Psychologist">Psychologist</option>
                  <option value="Financial">Financial Counsellor</option>
                  <option value="Clinical">Clinical Psychologist</option>
                  <option value="GP">GP</option>
                  <option value="Provisional">Provisional</option>
                  <option value="Social">Social Worker</option>
                  <option value="Nurse">Mental Health Nurse</option>
                  <option value="Occupational">Occupational Therapist</option>
                  <option value="Supervisor">Supervisor</option>
                </Form.Control>
              </Grid>
              <Grid item style={{ paddingRight: 10 }}>
                <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.registeredStatus} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'registeredStatus', filterValue: event.target.value }))}>
                  <option value="All">All</option>
                  <option value='Active'>Active</option>
                  <option value='OnHold'>On Hold</option>
                  <option value='Archived'>Archived</option>
                </Form.Control>
              </Grid>
              <Grid item style={{ paddingRight: 10 }}>
                <Autocomplete
                  id="searchAOI"
                  variant="outlined"
                  options={areasOfInterest}
                  getOptionLabel={(option) => ((filters.registeredAOI === 'All' && !option.issue) || !option.issue) ? 'All' : option.issue}
                  value={filters.registeredAOI === 'All' ? null : filters.registeredAOI}
                  onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'registeredAOI', filterValue: newValue ? newValue : 'All' }))}
                  style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Area of Interest"
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="AOISelector"
                      style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                />
              </Grid>
              <Grid item style={{ paddingRight: 10 }}>
                <Autocomplete
                  id="searchProviderInterest"
                  variant="outlined"
                  options={areasOfProviderInterest}
                  getOptionLabel={(option) => ((filters.registeredInterest === 'All' && !option.issue) || !option.issue) ? 'All' : option.issue}
                  value={filters.registeredInterest === 'All' ? null : filters.registeredInterest}
                  onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'registeredInterest', filterValue: newValue ? newValue : 'All' }))}
                  style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Provider Interest"
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="ProviderInterestSelector"
                      style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                />
              </Grid>
              <Grid item style={{ paddingRight: 10 }}>
                <Autocomplete
                  id="searchTherapyTypes"
                  variant="outlined"
                  options={areasOfTherapyTypes}
                  getOptionLabel={(option) => ((filters.registeredTherapy === 'All' && !option.issue) || !option.issue) ? 'All' : option.issue}
                  value={filters.registeredTherapy === 'All' ? null : filters.registeredTherapy}
                  onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'registeredTherapy', filterValue: newValue ? newValue : 'All' }))}
                  style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Therapy Types"
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="TherapyTypesSelector"
                      style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                />
              </Grid>
              <Grid item style={{ paddingRight: 10 }}>
                <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.registeredPsychologistsType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'registeredPsychologistsType', filterValue: event.target.value }))}>
                  <option value="All">All</option>
                  <option value="nthqldphn">Nth QLD PHN</option>
                </Form.Control>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGrid 
          rows={filteredRegistered} 
          columns={columns} 
          pageSize={10} 
          rowsPerPageOptions={[10]} 
          checkboxSelection={false} 
          sortModel={[{ field: 'created', sort: 'desc' }]}
        />
      </Paper>

      {/* <Demo /> */}

      <Modal show={show} onHide={handleClose} dialogClassName="edit-psychologist-dialog-modal" style={{dataBsFocus:false}}>
        <Modal.Header closeButton className="edit-psychologist-modal">
          <Modal.Title>Edit Profile - {currentFirstName} {currentSurname} ({currentProviderDescription})</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-psychologist-modal">
          <Form onSubmit={putPsychologist}>
            <Tabs
              id="EditModal"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              mountOnEnter={true}
            >
              <Tab eventKey="personalDetails" title="Profile" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                    
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="FirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Surname</Form.Label>
                        <Form.Control type="text"  name="Surname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select" defaultValue={currentGender} onBlur={(event) => setcurrentGender(event.target.value)}>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                          <option value="Prefer not to say">Prefer not to say</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>TimeZone</Form.Label>
                        <Autocomplete
                          id="profileTimeZone"
                          variant="outlined"
                          options={timeZone}
                          getOptionLabel={(option) => option.name}
                          value={timeZone.find(v => v.systemName === currentTimeZone) || ''}
                          onChange={(event, newValue) => handleTimeZoneAutocompleteChange('TimeZone', event, newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="TimeZoneSelector"
                              style={{backgroundColor: "white", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="email" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Mobile Phone</Form.Label>
                        <Form.Control type="text" name="mobilephone" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Occupational') ?
                      <>
                        <Col md="5">
                          <Form.Group >
                          <Form.Label>Education</Form.Label>
                            <Form.Control type="text" name="Education" defaultValue={currentEducation} onBlur={(event) => setcurrentEducation(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </>
                    :
                      null
                    }
                    <Col md="2">
                      <Form.Group >
                        <Form.Label style={{ fontWeight: 500 }}>Is this a test provider</Form.Label>
                        <Select 
                          variant="outlined"
                          margin="dense"
                          id='testProvider'
                          aria-label='Select Test Provider'
                          name='testProvider'
                          value={currentTestProvider} 
                          onChange={(event) => handleTestProviderChange(event.target.value)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                          <MenuItem key='No' value='0'>No</MenuItem>
                          <MenuItem key='Yes' value='1'>Yes</MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>                    
                  </Row>

                  {/* Medical Practice */}  
                  <Row>
                    <Col md="4">
                    {(currentProviderType === 'Financial') ?
                      <h4>Organisation</h4>
                    :
                      <h4>Practice</h4>
                    }
                    </Col>
                    <Col md="2" style={{ textAlign: "right"}}>
                      <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                    </Col>
                    <Col md="6">
                      <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                        <Autocomplete
                          id="profileMedicalPractice"
                          variant="outlined"
                          options={medicalPractices}
                          getOptionLabel={(option) => option.practicename }
                          getOptionSelected={(option, value) => getMedicalPracticeOption(option, value)}
                          value={currentMedicalPractice}
                          onChange={(event, newValue) => loadMedicalPractitice(newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="MedicalPracticeSelector"
                              style={{backgroundColor: "", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                  <Col md="6">
                    <Form.Group >
                      {(currentProviderType === 'Financial') ?
                        <Form.Label>Organisation Name</Form.Label>
                      :
                        <Form.Label>Practice Name</Form.Label>
                      }
                      <Form.Control type="text" name="practicename" value={currentMedicalPractice ? currentMedicalPractice.practicename : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, practicename: event.target.value})} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                    <Form.Label>ABN</Form.Label>
                      <Form.Control type="text" name="currentAbn" value={currentMedicalPractice ? currentMedicalPractice.abn : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, abn: event.target.value})} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Contact Phone</Form.Label>
                      <Form.Control type="text" name="currentPhone" value={currentMedicalPractice ? currentMedicalPractice.phone : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, phone: event.target.value})} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="9">
                    <Form.Group >
                      <Form.Label>Practice Address</Form.Label>
                      <PlacesAutoComplete 
                          currentID={currentMedicalPractice.id}
                          currentfulladdress={currentMedicalPractice.address1}
                          setcurrentfulladdress = {(value) => setnewpracticeaddress(value)} 
                          setcurrentLatitude = {(value) => setcurrentMedicalPractice({...currentMedicalPractice, latitude: value})}
                          setcurrentLongitude = {(value) => setcurrentMedicalPractice({...currentMedicalPractice, longitude: value})}
                          setcurrentPostCode = {(value) => setcurrentMedicalPractice({...currentMedicalPractice, postcode: value})}
                        />
                      {/* <Form.Control type="text" name="currentAddress1" value={currentMedicalPractice ? currentMedicalPractice.address1 : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, address1: event.target.value})} autoComplete='new-password' /> */}
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Practice Email</Form.Label>
                      <Form.Control type="text" name="currentEmail" value={currentMedicalPractice ? currentMedicalPractice.email : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, email: event.target.value})} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row> 
                <Row>
                  <Col md="12">
                    <Form.Group >
                    <Form.Label>Bio</Form.Label>
                      <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                            value={currentBio}
                            onEditorChange={(newValue, editor)=>setcurrentBio(newValue)}
                            init={{ branding: false, statusbar: false, height: 500 }}
                        />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                      <Form.Group >
                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setcurrentStatus(event.target.value)}>
                          <option value="Active">Active</option>
                          <option value="Archived">Archived</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Provider Type</Form.Label>
                        <Form.Control as="select" defaultValue={currentProviderType} onBlur={(event) => setcurrentProviderType(event.target.value)}>
                          <option value="Psychologist">Psychologist</option>
                          <option value="Financial">Financial Counsellor</option>
                          <option value="Clinical">Clinical Psychologist</option>
                          <option value="GP">GP</option>
                          <option value="Provisional">Provisional Psychologist</option>
                          <option value="Social">Social Worker</option>
                          <option value="Nurse">Mental Health Nurse</option>
                          <option value="Occupational">Occupational Therapist</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Specialist Type</Form.Label>
                        <Form.Control as="select" defaultValue={currentSpecialistType} onBlur={(event) => setcurrentSpecialistType(event.target.value)}>
                          <option value="Standard">Standard</option>
                          <option value="nthqldphn">Nth QLD PHN</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Create Consent Forms on Bulk Billing Booking</Form.Label>
                        <Form.Control as="select" defaultValue={currentAutoBulkBillingConsentForm} onBlur={(event) => setcurrentAutoBulkBillingConsentForm(event.target.value)}>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Allow Provider to Receive New Registrations</Form.Label>
                        <Form.Control as="select" defaultValue={currentIsRegistrationBlocked} onBlur={(event) => setcurrentIsRegistrationBlocked(event.target.value)}>
                          <option value="N">Yes</option>
                          <option value="Y">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Allow Provider to Create EAP/Contract/NDIS Availability</Form.Label>
                        <Form.Control as="select" defaultValue={currentEapContractNDISAvailability} onBlur={(event) => setcurrentEapContractNDISAvailability(event.target.value)}>
                          <option value="Y">Yes</option>
                          <option value="N">No</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                </Row>
              </Tab>
              <Tab eventKey="professionalDetails" title="Professional" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                                     
                <>
                  {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'GP' || currentProviderType === 'Provisional' || currentProviderType === 'Occupational') ?
                    <>
                      <Row>
                        {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Provisional' || currentProviderType === 'Occupational') ?                        
                          <>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>AHPRA Registration Number</Form.Label>
                                <Form.Control type="text" name="AHPRARegistrationNo" defaultValue={currentAHPRARegistrationNo} onBlur={(event) => setcurrentAHPRARegistrationNo(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Occupational') ?
                          <>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Provider Number</Form.Label>
                                <Form.Control type="text" name="ProviderNumber" defaultValue={currentProviderNumber} onBlur={(event) => setcurrentProviderNumber(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {currentProviderType !== 'Provisional' ?                        
                          <>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Insurance No.</Form.Label>
                                <Form.Control type="text" name="InsuranceNumber" defaultValue={currentInsuranceNumber} onBlur={(event) => setcurrentInsuranceNumber(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical'|| currentProviderType === 'Occupational') ?
                          <>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Year First Practiced</Form.Label>
                                <Form.Control type="text" name="StartedPracticingYear" defaultValue={currentStartedPracticingYear} onBlur={(event) => setcurrentStartedPracticingYear(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>PDF Password</Form.Label>
                            <Form.Control type="text" name="PdfPassword" defaultValue={currentPDFPassword} onBlur={(event) => setcurrentPDFPassword(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Occupational') ?
                        <>
                          <Form.Label>Client Billing Settings</Form.Label><Form.Label onClick={() => registrationView('Full Fee')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Full Fee)</Form.Label><Form.Label onClick={() => registrationView('Reduced Gap')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Reduced Fee)</Form.Label>
                          <Row>        
                            <Col md="2">
                              <Form.Group >
                                <Form.Label >Full Fee Rate (Total) *</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" name="Hourly" defaultValue={currentHourly} onBlur={(event) => setcurrentHourly(event.target.value)} autoComplete='new-password' />
                                  </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Full Fee Gap (Override)</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control type="text" name="HourlyRateOverrideGap" defaultValue={currentHourlyRateOverrideGap} onBlur={(event) => setcurrentHourlyRateOverrideGap(event.target.value)} autoComplete='new-password' />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Full Fee Rebate (Override)</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control type="text" name="HourlyRateOverrideRebate" defaultValue={currentHourlyRateOverrideRebate} onBlur={(event) => setcurrentHourlyRateOverrideRebate(event.target.value)} autoComplete='new-password' />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>  
                          <Row>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>Reduced Fee Rate (Total) *</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control type="text" name="ReducedFee" defaultValue={currentReducedFee} onBlur={(event) => setcurrentReducedFee(event.target.value)} autoComplete='new-password' />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Reduced Fee Gap (Override)</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control type="text" name="ReducedFeeOverrideGap" defaultValue={currentReducedFeeOverrideGap} onBlur={(event) => setcurrentReducedFeeOverrideGap(event.target.value)} autoComplete='new-password' />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Reduced Fee Rebate (Override)</Form.Label>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <Form.Control type="text" name="ReducedFeeOverrideRebate" defaultValue={currentReducedFeeOverrideRebate} onBlur={(event) => setcurrentReducedFeeOverrideRebate(event.target.value)} autoComplete='new-password' />
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <Form.Group>
                                <Checkbox aria-label='Offer Client Pricing Flexibility' name='isReducedFee' checked={currentIsReducedFee === 'Y' ? true : false} onClick={() => handleToggle()} style={{ paddingTop: 6, paddingLeft: 0 }} />
                                <span style={{ fontWeight:600 }} >Offer flexible fees based on client circumstances </span>
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      :
                        null
                      }
                    </>
                  :  
                    null
                  }
                </>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>TeleConference Provider Selection</Form.Label>
                      <Form.Control as="select" defaultValue={currentTeleHealthProvider} onBlur={(event) => setcurrentTeleHealthProvider(event.target.value)}>
                        <option value="COVIU">COVIU</option>
                        <option value="Alternate Meeting">Other Meeting Room</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="5">
                    <Form.Group >
                      <Form.Label>COVIU URL</Form.Label>
                      <Form.Control type="text"  name="coviuRoomURL" defaultValue={currentCOVIURoomURL} onBlur={(event) => setcurrentCOVIURoomURL(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Group >
                      <Form.Label>Alternate link (Zoom etc)</Form.Label>
                      <Form.Control type="text" name="WaitingRoomURL" defaultValue={currentWaitingRoomURL} onBlur={(event) => setcurrentWaitingRoomURL(event.target.value)} autoComplete='new-password' />                        
                    </Form.Group>
                  </Col>
                </Row>
                {currentSpecialistType === 'nthqldphn' ?
                  <Row>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>Offer Face to Face Booking (when face to face availability is set)</Form.Label>
                        <Form.Control as="select" defaultValue={currentFaceToFaceBooking} onBlur={(event) => setcurrentFaceToFaceBooking(event.target.value)}>
                          <option value="N">No</option>
                          <option value="Y">Yes</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                :
                  null
                }

                <Row>  
                  {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'GP' || currentProviderType === 'Occupational') ?
                    <>
                      <Col md="3">
                        <Form.Group>
                          <Form.Label>Insurance Document:</Form.Label>
                          <Form.Control type="file" name="NewInsuranceFile" onChange={(event) => handleFileFieldChangeNewInsuranceFile('NewInsuranceFile', event)} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <div style={{ width: "100%", paddingTop: 25 }}>
                            <InsuranceDownload documentLink={currentDocumentLink} />
                          </div>
                        </Form.Group>
                      </Col>
                    </>
                  :
                    null
                  }                    
                </Row>
                {(currentProviderType !== 'GP') ?
                  <>
                    <Row>
                      <Col md="10">
                        <Form.Group >
                          <Form.Label>Areas of Interest  (Select all that apply) </Form.Label>
                          <Autocomplete
                            id="searchAOI"
                            variant="outlined"
                            multiple
                            options={(currentProviderType === 'Financial') ? FinancialAreasOfInterest : aoi_focus }
                            getOptionLabel={(option) => option.issue }
                            value={currentAreasOfInterest}
                            onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="AOISelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10">
                        <Form.Group >
                          <Form.Label>Key Areas (Select all that apply) </Form.Label>
                          <Autocomplete
                            id="searchKeyAOI"
                            variant="outlined"
                            multiple
                            options={(currentProviderType === 'Financial') ? FinancialAreasOfInterest : aoi_other }
                            getOptionLabel={(option) => option.issue }
                            value={currentKeyAreas}
                            onChange={(event, newValue) => setcurrentKeyAreas(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="AOIKeySelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Occupational') ?
                      <>
                        <Row>
                          <Col md="10">
                            <Form.Group >
                              <Form.Label>Client Interest Areas (Select all that apply) </Form.Label>
                              <Autocomplete
                                id="searchInterestAOI"
                                variant="outlined"
                                multiple
                                options={areasOfProviderInterest}
                                getOptionLabel={(option) => option.issue }
                                value={currentProviderInterest}
                                onChange={(event, newValue) => setcurrentProviderInterest(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="AOIInterestSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10">
                            <Form.Group >
                              <Form.Label>Therapy Types (Select all that apply) </Form.Label>
                              <Autocomplete
                                id="searchTherapyAOI"
                                variant="outlined"
                                multiple
                                options={areasOfTherapyTypes}
                                getOptionLabel={(option) => option.issue }
                                value={currentTherapyTypes}
                                onChange={(event, newValue) => setcurrentTherapyTypes(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="AOITherapyTypeSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="10">
                            <Form.Group >
                              <Form.Label>Accepted Funding Types  (Select all that apply) </Form.Label>
                              <Autocomplete
                                id="fundingTypes"
                                variant="outlined"
                                multiple
                                options={fundingTypes}
                                getOptionLabel={(option) => option.type }
                                value={currentFundingTypes}
                                onChange={(event, newValue) => setcurrentFundingTypes(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="AOISelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                          </Row>
                        </>
                      :
                        null
                      }
                    {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical' || currentProviderType === 'Occupational') ?
                        <>
                        <Row>
                          <Col md="10">
                            <Form.Group >
                              <Form.Label>MBS Services (Select all that apply) </Form.Label>
                              <Autocomplete
                                id="mbsServices"
                                variant="outlined"
                                multiple
                                options={mbsServices}
                                getOptionLabel={(option) => option.serviceName}
                                value={currentMbsServices}
                                onChange={(event, newValue) => setcurrentMbsServices(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="AOISelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>  
                          </Col>
                        </Row>
                      </>
                    :
                      null
                    }  
                  </>
                :
                  null
                }
              </Tab>
              <Tab eventKey="notes" title="Comms/Notes" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Notes type="Psychologist" pkid={currentPsychologistID} medicalPractice={currentMedicalPractice} />
              </Tab>
              <Tab eventKey="availability" title="Availability" style={{backgroundColor: "rgb(252, 252, 252)", padding: 0}}>
                <AvailabilityNew
                  mainID={currentPsychologistID}
                  currentFundingTypes={currentFundingTypes}
                  userTZ={userTZ}
                  /> 
              </Tab>
              <Tab eventKey="bookings" title="Bookings" style={{backgroundColor: "rgb(252, 252, 252)", padding: 0}}>
                <>
                  <Grid container direction="row" justifyContent="space-between" style={{ padding: 16 }}>
                    <Grid item><h4 style={{ marginBottom: 0 }}>Bookings</h4></Grid>
                  </Grid>
                  <BookingsList
                    type='psychologist'
                    id={currentPsychologistID && currentPsychologistID}
                    userTZ={userTZ}
                    providerType={currentProviderType}
                  />
                </>
              </Tab>
              {(currentProviderType !== 'Financial') ?
                <Tab eventKey="referrals" title="Referrals" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  <ReferralsView 
                    viewerFullName={`${currentFirstName} ${currentSurname}`}
                    type="psychologist"
                    id={currentPsychologistID}
                  />
                </Tab>
              :
                null
              }
              {(currentProviderType !== 'Financial') ?
                <Tab eventKey="Tyro" title="Tyro" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>API Key</Form.Label>
                        <Form.Control type="text" name="APIKey" value={currentAPIKey} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <Button 
                        variant="primary" 
                        size="sm" 
                        style={{ marginLeft: 5, }} 
                        onClick={() => loadAPIKey(registered.find(psychologist => psychologist.id === currentPsychologistID))}
                      >
                        Update APIKey 
                      </Button>
                    </Col>
                  </Row>
                </Tab>
              :
                null
              }
              {/* <Tab eventKey="invoices" title="Invoices" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                { currentInvoices ? 
                  <Paper style={{ height: 700 }}>
                    <DataGrid rows={currentInvoices} columns={currentInvoiceColumns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} />
                  </Paper>
                : ('None')
                }
              </Tab> */}
              {/* <Tab eventKey="chargeDetails" title="Charge Information" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                                     
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Card Number</Form.Label>
                      <Form.Control type="text" name="CcLast4" value={'XXXX-XXXX-XXXX-' + currentCcLast4} readOnly />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Expiry</Form.Label>
                      <Form.Control type="text" name="CcExpiry" value={currentCcExpiry} readOnly />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <Button 
                      variant="primary" 
                      size="sm" 
                      style={{ marginLeft: 5, }} 
                      onClick={() => loadCard(registered.find(psychologist => psychologist.id === currentPsychologistID))}
                    >
                      Update Card Information 
                    </Button>
                  </Col>
                </Row>
              </Tab> */}
              {(currentProviderType === 'Psychologist' || currentProviderType === 'Clinical') ?
                <Tab eventKey="supervision" title="Supervision" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  <>
                    <Row>
                      <Col md="10">
                        <Form.Group>
                          <Form.Label style={{ fontWeight: 500 }}>Do you offer Supervision sessions to Other Providers? </Form.Label>
                          <Form.Check id="supervsior-nosupervision" name="isSupervisor" type="radio" style={{ marginBottom: 8 }} >
                          <Form.Check.Input name="isSupervisor" type="radio"  checked={currentIsSupervisor === 'N' ? true : false} onChange={e => setCurrentIsSupervisor('N')}  />
                            <Form.Check.Label style={{marginLeft: 12 }}>No</Form.Check.Label>
                          </Form.Check>
                          <Form.Check id="supervisor-supervision" name="isSupervisor" type="radio" style={{ marginBottom: 8 }} >
                            <Form.Check.Input name="isSupervisor" type="radio"  checked={currentIsSupervisor === 'Y' ? true : false} onChange={e => setCurrentIsSupervisor('Y')} />
                            <Form.Check.Label style={{marginLeft: 12 }}>​Yes</Form.Check.Label>
                          </Form.Check>
                          {currentIsSupervisor === 'Y' ?
                            <>
                              <Row>
                                <Col md="12">
                                <Form.Label onClick={() => registrationView('Supervision')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Supervision)</Form.Label>
                                  <Form.Group >
                                    <Form.Label>Supervisor Bio (if blank standard Bio will be displayed)</Form.Label>
                                    <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                                      value={currentSupervisorBio}
                                      onEditorChange={(newValue, editor)=>setCurrentSupervisorBio(newValue)}
                                      init={{ branding: false, statusbar: false, height: 500 }}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="8">
                                  <Form.Group >
                                    <Form.Label>Supervisor Education (if blank standard Education will be displayed)</Form.Label>
                                    <Form.Control type="text" name="supervisorEducation" defaultValue={currentSupervisorEducation} onBlur={(event) => setCurrentSupervisorEducation(event.target.value)} autoComplete='new-password' />
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group>
                                  <Form.Label >Supervisor Rate (if blank Full Fee Rate will be used)</Form.Label>
                                  <InputGroup>
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <Form.Control type="text" name="supervisorHourlyRate" defaultValue={currentSupervisorHourlyRate} onBlur={(event) => setCurrentSupervisorHourlyRate(event.target.value)} autoComplete='new-password'/>
                                  </InputGroup>
                                  </Form.Group>
                                </Col>
                              </Row>  
                              <Row>
                                <Col md="12">
                                  <Form.Group >
                                    <Form.Label>Available For</Form.Label>
                                    <Row>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {supervisionAreas.map(row => {
                                            if(row.index <= supervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${row.id}`;
                                              var isChecked = currentSupervisorAreas.find(r => r === row.id)
                                              return (
                                                <ListItem key={row.id} role={undefined} dense button onClick={handleSupervisorAreaToggle(row)}>
                                                <ListItemIcon style={{ minWidth: 40 }}>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={isChecked !== undefined}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                    disableTypography 
                                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                    className={classes.link} />
                                              </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })
                                          }
                                        </List>
                                      </Col>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {supervisionAreas.map(row => {
                                            if(row.id > supervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${row.id}`;
                                              var isChecked = currentSupervisorAreas.find(r => r === row.id)
                                              return (
                                                <ListItem key={row.id} role={undefined} dense button onClick={handleSupervisorAreaToggle(row)}>
                                                <ListItemIcon style={{ minWidth: 40 }}>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={isChecked !== undefined}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                    disableTypography 
                                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                    className={classes.link} />
                                              </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })
                                          }
                                        </List>
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <Form.Group >
                                    <Form.Label>Endorsed In </Form.Label>
                                    <Row>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {endorsedSupervisionAreas.map(row => {
                                            console.log(endorsedSupervisionAreas.length/2)
                                            if(row.index <= endorsedSupervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${row.index}`;
                                              var isChecked = currentSupervisorAreas.find(r => r === row.id)
                                              return (
                                                <ListItem key={row.id} role={undefined} dense button onClick={handleSupervisorAreaToggle(row)}>
                                                <ListItemIcon style={{ minWidth: 40 }}>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={isChecked !== undefined}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                    disableTypography 
                                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                    className={classes.link} />
                                              </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })
                                          }
                                        </List>
                                      </Col>
                                      <Col md="6">
                                        <List className={classes.root}>
                                          {endorsedSupervisionAreas.map(row => {
                                            if(row.index > endorsedSupervisionAreas.length/2)
                                            {
                                              const labelId = `checkbox-list-label-${row.index}`;
                                              var isChecked = currentSupervisorAreas.find(r => r === row.id)
                                              return (
                                                <ListItem key={row.id} role={undefined} dense button onClick={handleSupervisorAreaToggle(row)}>
                                                <ListItemIcon style={{ minWidth: 40 }}>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={isChecked !== undefined}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                    disableTypography 
                                                    primary={<Typography className={isChecked !== undefined ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                    className={classes.link} />
                                              </ListItem>
                                              );
                                            }
                                            else
                                            {
                                              return null
                                            }
                                          })
                                          }
                                        </List>
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Col>
                              </Row>

                            </>
                          :
                            null
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                </Tab>
              :
                null
              }
              <Tab eventKey="photoDetails" title="Photo" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                                     
                <Row>
                  <Col md="10">
                    <Image src={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${currentProfilePicture}`} style={{ maxHeight: 400, marginBottom: 30 }} />
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <Form.Group>
                      <Form.Label>Upload New Profile Photo</Form.Label>
                      <Form.Control type="file" name="NewProfilePhoto" onChange={(event) => handleFileFieldChangeNewProfilePicture('NewProfilePhoto', event)}/>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="files" title="Files" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Files type="Psychologist" pkid={currentPsychologistID} />
              </Tab>
              <Tab eventKey="security" title="Security" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password" name="newpassword" defaultValue={currentNewPassword} onBlur={(event) => setcurrentNewPassword(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Confirm Password</Form.Label>
                      <Form.Control type="password" name="newpasswordconfirmation" defaultValue={currentNewPasswordConfirmation} onBlur={(event) => setcurrentNewPasswordConfirmation(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Button variant="success" size="sm" onClick={() => updatePassword()} style={{marginTop : 30}}>
                      Update
                    </Button>
                  </Col>
                </Row>
                <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20, marginTop:20 }} >
                <h4>Multi Factor Authentication</h4>
                <hr></hr>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label> Status</Form.Label>
                      <Form.Control type="text" name="MFAConfigured" value={currentMfaConfigured} readOnly />
                    </Form.Group>
                  </Col>
                  {currentMfaConfigured === 'Configured' ?
                    <Col md="3" style={{ paddingTop: 29}}>  
                      <Button 
                        variant="danger" 
                        size="sm" 
                        style={{ marginLeft: 5, }} 
                        onClick={() => DeactivateMFA(currentPsychologistID)}
                      >
                        Disable 
                      </Button>
                    </Col>
                  :
                    null
                  }
                </Row>
              </Paper>


              </Tab>
            </Tabs>
         </Form>                      
        </Modal.Body>
        <Modal.Footer className="edit-psychologist-dialog-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putPsychologist}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showCard} onHide={handleCardClose} centered>
        <Modal.Header closeButton className="chargeCard-modal">
          <Modal.Title>Update Card Details - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="chargeCard-modal">
          <Elements stripe={stripePromise}>
            <CardSetupForm 
              referenceID={"PS" + currentPsychologistID}
              name={currentFirstName + " " + currentSurname}
              mobile={currentMobile ? currentMobile : 'not supplied'}
              email={currentEmail ? currentEmail : 'not supplied'}
              onCloseCard={handleCardClose}
            />
          </Elements>
        </Modal.Body>
      </Modal> */}

      <Modal show={showAPIKey} onHide={handleAPIKeyClose} centered>
        <Modal.Header closeButton className="apiKey-modal">
          <Modal.Title>Update Tyro APIKey Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="apiKey-modal">
          <APIKeyForm 
            referenceID={currentPsychologistID}
            onCloseAPIKey={handleAPIKeyClose}
          />
        </Modal.Body>
      </Modal>


      {/* <Modal show={showInvoiceDetail} onHide={handleInvoiceDetailClose} centered>
        <Modal.Header closeButton className="invoiceDetail-modal">
          <Modal.Title>Invoice #{currentInvoiceID} {}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invoiceDetail-modal">
          <InvoiceDetail 
            invoiceID={currentInvoiceID}
            invoiceitems={currentInvoiceItems}
            onCloseInvoiceDetail={handleInvoiceDetailClose}
            />
        </Modal.Body>
      </Modal> */}

      <Modal show={showRegistrationView} onHide={handleRegistrationViewClose} dialogClassName="providerRegistrationView-modal">
        <Modal.Header closeButton className="providerRegistrationView-modal">
          <Modal.Title>Client Registration View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="providerRegistrationView-modal">
          <Grid item md={12} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
              <Masonry
                breakpointCols={1}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                <Card className={classes.root} variant="outlined" key={currentPsychologistID}>
                  <CardMedia
                      className={classes.media}
                      image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${currentProfilePicture}`}
                      title="Psychologist"
                  />
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item sm={12}>
                        <Typography variant="h5" component="h3">
                          {currentFirstName} {currentSurname}  (Next available: Next Available)
                        </Typography>
                      </Grid>
                    </Grid>
                    {(currentProviderType !== 'GP' && currentProviderType !== 'Provisional') ?
                      <>
                          {currentPaymentType === 'Supervision' ?
                          <>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={6} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Out of Pocket Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {currentSupervisorHourlyRate}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                          :
                          null
                        }
                        {currentPaymentType === 'Full Fee' ?
                          <>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={6} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Out of Pocket Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {currentHourlyRateOverrideGap && currentHourlyRateOverrideGap !== '0' ? '$' + currentHourlyRateOverrideGap : currentStandardFeeOutOfPocket }
                                </Typography>
                              </Grid>
                              <Grid item sm={12}>
                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                  You pay ${currentHourly} on the day 
                                </Typography>
                                <Typography>
                                  Receive back {currentHourlyRateOverrideRebate && currentHourlyRateOverrideRebate !== '0'  ? '$' + currentHourlyRateOverrideRebate :  currentMedicareRebate} from Medicare
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                          :
                          null
                        }
                        {currentPaymentType === 'Reduced Gap' ?
                          <>
                            {currentIsReducedFee === 'Y' ? 
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {currentReducedFeeOverrideGap && currentReducedFeeOverrideGap !== '0' ? '$' + currentReducedFeeOverrideGap : currentReducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { currentReducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${currentReducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {currentReducedFeeOverrideRebate && currentReducedFeeOverrideRebate !== '0' ? '$' + currentReducedFeeOverrideRebate : currentMedicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                  { currentReducedFee !== '0' ?
                                    <Grid item sm={12}>
                                      <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                        Note this provider offers some flexibility, depending on client circumstances
                                      </Typography>
                                    </Grid>
                                  :
                                    null
                                  }
                                </Grid>
                              </>
                            :
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {currentReducedFeeOverrideGap && currentReducedFeeOverrideGap !== '0' ? '$' + currentReducedFeeOverrideGap : '$' + currentReducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { currentReducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${currentReducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {currentReducedFeeOverrideRebate && currentReducedFeeOverrideRebate !== '0' ? '$' + currentReducedFeeOverrideRebate :  currentMedicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </>
                            }
                          </>
                        :
                          null
                        } 
                        {currentPaymentType !== 'Supervision' ?
                          <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                            {currentAreasOfInterest && currentAreasOfInterest.map((row) => {return (<Chip size="small" key={`AOI${row.id}`} variant="outlined" label={row.issue} style={{ margin: 2 }} />)})}
                          </Grid>
                        :
                          null
                        }
                      </>
                    :
                      null
                    }
                    <Typography className={classes.pos} color="textSecondary">
                      <br />
                    </Typography>
                    <Grid container>
                      {currentPaymentType === 'Supervision' ?
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={currentSupervisorBio && currentSupervisorBio.substring(0,700)} />
                            {currentSupervisorBio && currentSupervisorBio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(currentFirstName + ' ' + currentSurname, currentSupervisorBio && currentSupervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      :
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={currentBio && currentBio.substring(0,700)} />
                            {currentBio && currentBio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(currentFirstName + ' ' + currentSurname, currentBio && currentBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      }
                    </Grid>
                    {currentPaymentType === 'Supervision' ?
                      <>
                        <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                          Available For
                        </Typography>
                        <Row>
                          <Col md="6">
                            <List className={classes.root}>
                              {supervisionAreas.map(sRow => {
                                if(sRow.index <= supervisionAreas.length/2)
                                {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = currentSupervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                          <Col md="6">
                            <List className={classes.root}>
                              {supervisionAreas.map(sRow => {
                                if(sRow.index > supervisionAreas.length/2)
                                {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = currentSupervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                        </Row>
                        <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                          Endorsed In 
                        </Typography>
                        <Row>
                          <Col md="6">
                            <List className={classes.root}>
                              {endorsedSupervisionAreas.map(sRow => {
                                var isChecked = currentSupervisorAreas.includes(sRow.id);
                                const labelId = `checkbox-list-label-${sRow.id}`;
                                if (isChecked) {
                                  return(
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  )
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                        </Row>
                      </>
                    :
                      null
                    }  
                  </CardContent>
                </Card>
              </Masonry>
            </Grid>

        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleRegistrationViewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>




      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )

}

