import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../../../store/ducks/admin.duck";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import { ToastContainer, Flip } from "react-toastify";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../../_metronic/_assets/css/app2.css';

export default function ProfileReferrer() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchReferrers()) }, [dispatch] )
  
 
  // now init the fields with values so that Formik can manage the touched state
  const referrerInit = {
    firstname: '', 
    surname: '', 
    email: '', 
    mobilephone: '',
    timezone: 'AUS Eastern Standard Time',
    newpassword: '',
    newpasswordconfirmation: '',
    id : 0,
    organisationname : ''
  }

  // get the Referrer records and merge with the initialised fields
  var referrerState = useSelector(state =>  state.admin.Referrers[0]);
  var referrer = {...referrerInit, ...referrerState};

  function submitForm(data){
    // Submission Time
    dispatch(Admin.actions.putReferrer(data))
  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("Surname is required"),
    organisationname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("Organisation is required"),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    mobilephone:  Yup.string()
    .min(2,"Too Short")
    .max(12,"Too Long")
    .required("Phone Number is required"),
    newpassword:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('newpasswordconfirmation'), null], 'Passwords must match'),
    newpasswordconfirmation:  Yup.string()
    .min(8,"Too Short")
    .oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={referrer}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit} = props;
          const change = (name,e) => { e.persist();  handleChange(e); };
          return (
            <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >

      <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
        <Row>
          <Col md="12">
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
              <Grid item md={6} xs={12}>
                <h3>Referrer Profile</h3>
              </Grid>
              <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                * indicates required fields
              </Grid>
            </Grid>
          </Col>
        </Row>
        <Row>
          <Col md="12">                  
            {values.message ? (
              <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                {values.message}
              </Alert>) 
              : null
            }
          </Col>
        </Row>
        <hr style={{ marginTop: 4 }}></hr>
        <Row>
          <Col md="3">
            <Form.Group >
              <Form.Label>First Name *</Form.Label>
              <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
              <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group >
              <Form.Label>Surname *</Form.Label>
              <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
              <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
            <Row>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Email *</Form.Label>
                  <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Phone Number *</Form.Label>
                  <Form.Control type="text" name="mobilephone" value={values.mobilephone} onChange={change.bind(null, "mobilephone")} isInvalid={touched.mobilephone && !!errors.mobilephone} isValid={touched.mobilephone && !errors.mobilephone} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.mobilephone}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group >
                  <Form.Label>Organisation</Form.Label>
                  <Form.Control type="text" name="organisationname" value={values.organisationname} onChange={change.bind(null, "organisationname")} autoComplete='new-password' />
                </Form.Group>
              </Col>
            </Row>
            <h4>Login Details (Leave blank unless updating password)</h4>
            <hr></hr>
            <Row>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name="newpassword" value={values.newpassword} autoComplete='new-password' onChange={change.bind(null, "newpassword")}  isInvalid={touched.newPassword && !!errors.newpassword} isValid={touched.newpassword && !errors.newpassword} />
                  <div style={{ fontSize: 10, fontWeight: 400, paddingTop: 4, paddingLeft: 4 }}>(Leave blank unless changing)</div>
                  <Form.Control.Feedback type="invalid">{errors.newpassword}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group >
                  <Form.Label>Confirm Password </Form.Label>
                  <Form.Control type="password" name="newpasswordconfirmation" value={values.newpasswordconfirmation} onChange={change.bind(null, "newpasswordconfirmation")} isInvalid={touched.newpasswordconfirmation && !!errors.newpasswordconfirmation} isValid={touched.newpPasswordconfirmation && !errors.newPasswordconfirmation} autoComplete='new-password' />
                  <Form.Control.Feedback type="invalid">{errors.newpasswordconfirmation}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item md={3} xs={6}>
                  </Grid>
                  <Grid item md={1} xs={6} style={{ textAlign: "right"}}>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </Paper>
      </form>
      )
      }}
    </Formik>
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

