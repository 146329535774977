import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid'
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';
import GatherBillableDetail from "../../../components/GatherBillableDetail"
import * as Admin from "../../../store/ducks/admin.duck";
import { ToastContainer, Flip } from "react-toastify";
// import { saveAs } from 'file-saver';
import "react-toastify/dist/ReactToastify.css";

// Need to rewrite this - as it is not completely correct 
// In the world of confusion - please note the following invoicing process 
//
// Today we are billing per booking using the Services Table to define the prices - each booking has a billable type that is set (or defaulted) when a booking is made
//
// Process 
// 
// GatherBillable.js  - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to email providers a list of those to be billed at the end of the month if still eliglible. 
//                    - Can toogle bookings to cancelled/noshow as needed 
// Invoices.js        - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to create Invoices in Xero for these providers. 
//                    - This essentially a copy of GatherBillable though expect this will change at client acceptance occurs. 
// InvoiceLog.js      - This is a searchable log of invoices/clients/providers 
// 
// PlatformPsychologist.js - Lists unbilled boookings 
//                         - Only list bookings that have a booking charged field of null and a booking of status Active
//                         - Can toogle bookings to cancelled/noshow as needed //

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

  const useStylesDatePicker = makeStyles({
    root: {
      "& .MuiInputBase-root": {
        height: 33,
      }
    }
  });

export default function GatherBillableNDIS() 
{
  const dispatch = useDispatch()
  const classes = useStyles();
  const classesDatePicker = useStylesDatePicker();

  const billableIntents = useSelector(state => state.admin.BillableIntentNDIS)
  const filters = useSelector(state => state.admin.Filters)
  // const bookingBillableCSV = useSelector(state => state.admin.BookingBillableCSV);
  // const [exportExcelStatus, setExportExcelStatus] = useState('Not Started')
  const [searchTerm, setSearchTerm] = useState('')


  useEffect( () => {
    dispatch(Admin.actions.fetchBillableIntentsNDIS({ startDate: filters.invoiceStartDate, endDate: filters.invoiceEndDate})) }, 
    [dispatch, filters.invoiceStartDate, filters.invoiceEndDate] 
  )

  const searchBillableIntents = (billableIntent) => {
    if ( true
        && (billableIntent => billableIntent.ndisplanmanagementprovider.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0)
        && (!filters.invoiceStatus || filters.invoiceStatus === 'All' || (billableIntent.billingstatus === filters.invoiceStatus || billableIntent.billingstatus === 'Multiple'))
        && (!filters.approvedStatus || filters.approvalStatus === 'All' || (billableIntent.approvedstatus === filters.approvalStatus || billableIntent.approvedstatus === 'Multiple'))
      )
      {
        return billableIntent
      }  
  }
  

  const filteredbillableIntents = billableIntents.filter(billableIntent => searchBillableIntents(billableIntent))
  const [showClients, setShowClients] = useState(false);
  const [ndisProviderClients, setNDISProviderClients] = useState();
  const [ndisProviderName, setNDISProviderName] = useState();
  // const [ndisProviderEmail, setNDISProviderEmail] = useState();


  // initial call from the download button - fires fetch action if needed, otherwise triggers download 
  // const exportFile = () => {
  //   if(exportExcelStatus === 'Not Started')
  //   {
  //     dispatch(Admin.actions.fetchBookingBillableCSV({ startDate: filters.invoiceStartDate, endDate: filters.invoiceEndDate, billableStatus: filters.invoiceStatus}))
  //     setExportExcelStatus('Fetching')
  //   }
  // }    

  // grab the blob from state and tell the browser to download
  // const downloadExport = () => {
  //   // Sort out filename
  //   let headingStartDate = '';    
  //   let headingEndDate = '';
  //   let headingStatus = '';
  //   //
  //   if (filters.invoiceStartDate === '')
  //   {
  //     headingStartDate = '2020-01-01'
  //   }
  //   else
  //   {
  //     headingStartDate = filters.invoiceStartDate;
  //   }
  //   if (filters.invoiceEndDate === '')
  //   {
  //     let yourDate = new Date();
  //     headingEndDate = yourDate.toISOString().split('T')[0];
  //     console.log(headingEndDate);
  //   }
  //   else
  //   {
  //     headingEndDate = filters.invoiceEndDate;
  //   }
  //   if (filters.invoiceStatus === 'All')
  //   {
  //     headingStatus = '';
  //   }
  //   else
  //   {
  //     if (filters.invoiceStatus === 'Unbilled')
  //     {
  //       headingStatus = '-Unbilled Bookings';
  //     }
  //     else
  //     {
  //       headingStatus = '-Billed Bookings';
  //     }
  //   }
  //   //
  //   var exportDocName = 'RHC-Bookings- FROM-' + headingStartDate + '-TO-' + headingEndDate  + headingStatus;
  //   if(bookingBillableCSV && bookingBillableCSV !== '')
  //   {
  //     const file = new Blob(
  //     [Buffer.from(bookingBillableCSV)], 
  //     {type: 'text/csv'});
  //     saveAs(file, `${exportDocName}.csv`);
  //   }
  // }

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  // useEffect( () => {
  //   if(exportExcelStatus === 'Fetching' && bookingBillableCSV && bookingBillableCSV !== '')
  //   {
  //     downloadExport()
  //     setExportExcelStatus('Not Started')
  //   }
  // }, [exportExcelStatus, bookingBillableCSV,downloadExport])



  // function sendProviderEmail() 
  // {
  //   if (process.env.REACT_APP_DEBUG) 
  //   {
  //     console.log ('Generating Billable Client Emails to Psychologists');
  //   }
  //   dispatch(Admin.actions.putCreateProviderBillableEmails());
  // }


  const loadClients = (thisNDISProvider) => {
    setNDISProviderName(thisNDISProvider.ndisPlanManagementProvider);
    // setNDISProviderEmail(thisNDISProvider.ndisemailforinvoices)
    setNDISProviderClients(thisNDISProvider.clients);
    // setPsychologistID(thisPsychologist.id);
    setShowClients(true);
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'ndisPlanManagementProvider', headerName: 'Name', width: 300,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => loadClients(billableIntents.find(intent => intent.ndisPlanManagementProvider === params.row.ndisPlanManagementProvider))} className="cell-Link" >
              {params.value}
            </div>
        </strong>
      ),  
    },
    { field: 'ndisEmailForInvoices', headerName: 'Email', width: 300 },
    { field: 'xero', headerName: 'Xero Link', width: 200 },
    { field: 'totalClients', headerName: 'Bookings', width: 200},
    { field: 'total', headerName: 'Total', width: 120 },
    { field: 'billingstatus', headerName: 'Billing Status', width: 150 },
    { field: 'approvedstatus', headerName: 'Approval Status', width: 170 }
  ];


  function handleClientsClose() {
    setShowClients(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Review NDIS Bookings</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
          {/* <Grid item style={{ marginTop: 2, marginLeft: 10 }}> */}
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                  style={{ height: 33 }}
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="invoiceStatus-label" className={classes.label}> Invoice Status</InputLabel>
              <Select
                labelId="InvoiceStatus-label"
                id="InvoiceStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.invoiceStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Billed'>Billed</MenuItem>
                <MenuItem value='Unbilled'>UnBilled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="approvalStatus-label" className={classes.label}>Approval Status</InputLabel>
              <Select
                labelId="approvalStatus-label"
                id="approvalStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.approvalStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'approvalStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Approved'>Approved</MenuItem>
                <MenuItem value='Unapproved'>Unapproved</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item style={{ paddingRight: 10 }}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="Start Date"
                value={filters.invoiceStartDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceStartDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{ paddingRight: 10 }}>
                <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="End Date"
                value={filters.invoiceEndDate}
                style={{ marginTop: 0, maxWidth: 140 }}
                className={classesDatePicker.root}
                onChange={(newValue) => dispatch(Admin.actions.setFilters({ filterName: 'invoiceEndDate', filterValue: newValue }))}
                InputLabelProps={{ shrink: true }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
        {/* <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => exportFile()}>
              Download
            </Button>
        </Grid>  */}

          {/* <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => sendProviderEmail()}>
              Generate Billable Emails to Providers
            </Button>
          </Grid> */}
        </Grid>
      </Paper>


      { billableIntents ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredbillableIntents} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} sortModel={[{ field: 'ndisPlanManagementProvider', sort: 'asc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showClients} onHide={handleClientsClose} dialogClassName="editBilling-modal">
        <Modal.Header closeButton className="editBilling-modal">
          <Modal.Title>NDIS Bookings - {ndisProviderName} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editBilling-modal">
          <GatherBillableDetail
            billablestatus={filters.invoiceStatus}
            approvalstatus={filters.approvalStatus}
            clients={ndisProviderClients}
            closeClients={setShowClients}
            providerFee={true}
            displayNote={true}
          />
        </Modal.Body>
        <Modal.Footer className="editBilling-modal">
          <Button variant="secondary" onClick={handleClientsClose}>
            Cancel
          </Button>
        </Modal.Footer>

      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
