import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardPsychologist from "./DashboardPsychologist2";

// Not sure about these two
// import Basic from "./Basic"; 
import { LayoutSplashScreen } from "../../../_metronic";

// Admin Pages 
import { ClientInvitesPsychologist } from "./admin/ClientInvites";
import ClientsPsychologist from "./admin/ClientsPsychologist";
import ReferralsPsychologist from "./admin/ReferralsPsychologist";
import AvailabilityPsychologist from "./admin/AvailabilityPsychologist";
import BookingsPsychologist from "./admin/BookingsPsychologist";
import InvoicesPsychologist from "./admin/InvoicesPsychologist";
import MedipassCodes from "./admin/MedipassCodes";
import Platform from "./admin/PlatformInvoicesPsychologist";
import ProfilePsychologist from "./admin/ProfilePsychologist";
import ProviderSecurity from "./admin/ProviderSecurity";
import FilesPsychologist from "./admin/FilesPsychologist";
import FormConfig from "./admin/FormConfig";
// Reference Pages 
// import UserRoles from "./reference/UserRoles";

export default function HomePagePsychologist() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>

      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={DashboardPsychologist} />

        {/* Admin Pages */}
        <Route path="/clients" component={ClientsPsychologist} />
        <Route path="/clientinvites" component={ClientInvitesPsychologist} />
        <Route path="/referrals" component={ReferralsPsychologist} />
        <Route path="/availability" component={AvailabilityPsychologist} />  
        <Route path="/bookings" component={BookingsPsychologist} />   
        <Route path="/codes" component={MedipassCodes} />   
        <Route path="/invoices" component={InvoicesPsychologist} />

        <Route path="/profile" component={ProfilePsychologist} />
        <Route path="/security" component={ProviderSecurity} />
        <Route path="/platform" component={Platform} />        
        <Route path="/files" component={FilesPsychologist} />
        <Route path="/forms" component={FormConfig} />

        {/* Reference Pages */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

