import { put, takeLatest, all } from "redux-saga/effects";
import axios from "axios";
import { toast } from "react-toastify";
// import isCached from "../../components/Cache";

// const cache = (state) => state.admin.cache;
export const BASE_URL = process.env.REACT_APP_APIIP + "/forms";

export const actionTypes = {
  SET_INITIAL: "SET_INITIAL",  
  FETCH_FORMS: "FETCH_FORMS",
  FETCH_FORM: "FETCH_FORM",
  FETCH_ALLFORMDATA: "FETCH_ALLFORMDATA",
  FETCH_FORMDATA: "FETCH_FORMDATA",
  FETCH_DATANODES: "FETCH_DATANODES",

  PUT_FORM: "PUT_FORM",
  PUT_FORMQUESTION: "PUT_FORMQUESTION",
  PUT_FORMQUESTIONS_ORDER: "PUT_FORMQUESTIONS_ORDER",
  DELETE_FORMQUESTION: "DELETE_FORMQUESTION",
  DELETE_FORM: "DELETE_FORM",
  COPY_FORM_TO_PROVIDERS: "COPY_FORM_TO_PROVIDERS",

  PUT_FORMDATA: "PUT_FORMDATA",
  FETCH_PREVIEWPDF : "FETCH_PREVIEWPDF",
  SET_PREVIEWPDF: "SET_PREVIEWPDF",
  CLEAR_PREVIEWPDF: "CLEAR_PREVIEWPDF",

};

const initialState = {
  Forms: [],
  AllFormData: [],
  Form: {
    id: 0,
    formID: 0,
    subscriberID: 0,
    ownerID: null,
    formType: 'General',
    formName: '',
    formDescription: '',
    formInstructions: '',
    confirmationText: 'Thank you - your form has been submitted.',
    inputOrientation: '',
    questionToAnswerWidth: '',
    formStyles: '',
    formStatus: 'Active',
    formQuestions: []
  },
  DataNodes: [],  
  PreviewPDF: '',
  FormData: {
    id: 0,
    formDataID: 0,
    formID: 0,
    formName: '',
    formDataStatus: 'Not Started',
    values: []
  }
};

export function reducer(state = initialState, {type, ...action }) 
{
  switch (type) 
  {
    case 'SET_FORMS_COLLECTION': {
      return {
        ...state,
        [action.payload.collectionName]: action.payload.grid, 
      };
    }
    case 'SET_PREVIEWPDF': {
      return {
        ...state,
        PreviewPDF: action.payload.faxBlob, 
      };
    }
    case 'CLEAR_PREVIEWPDF': {
      return {
        ...state,
        PreviewPDF: '', 
      };
    }
    case 'SET_INITIAL': {
      return initialState;
    }
    default:
      return state;
  }
}

// Fetch's Below 

export function* fetchForms(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/forms";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'Forms', grid: response.data} });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchPreviewPDF(action) {
  try 
  {
    const newURL = BASE_URL + "/previewpdf";
    const response = yield axios.put(newURL, action.payload); 
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_PREVIEWPDF', payload: response.data[0]});
      yield put({ type: 'SET_CACHE', payload: { caller: action }});
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchDataNodes(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/datanodes";
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'DataNodes', grid: response.data} });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchForm(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/form/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'Form', grid: response.data[0] } });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putForm(action) {
  try
  {
    const newURL = BASE_URL + "/form";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      yield put({ type: 'FETCH_FORMS' });
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putFormQuestion(action) {
  try
  {
    const newURL = BASE_URL + "/formquestion";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload.formData); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      yield put({ type: 'FETCH_FORM', payload: action.payload.formData.get('formID') });
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putQuestionsOrder(action) {
  try
  {
    const newURL = BASE_URL + "/formquestionsorder";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      yield put({ type: 'FETCH_FORM', payload: action.payload.formID });
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteFormQuestion(action) {
  try
  {
    const newURL = BASE_URL + "/deleteformquestion";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      yield put({ type: 'FETCH_FORM', payload: action.payload.formID });
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* deleteForm(action) {
  try
  {
    const newURL = BASE_URL + "/deleteform/" + action.payload;
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      yield put({ type: 'FETCH_FORMS' });
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* copyToProviders(action) {
  try
  {
    const newURL = BASE_URL + "/copyformtoproviders/" + action.payload;
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.success(`Success: Form copied to providers.`);
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchAllFormData(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    const newURL = BASE_URL + "/allformdata/" + action.payload;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'AllFormData', grid: response.data } });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* fetchFormData(action)
{
  process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action));
  try 
  {
    // Need to clear existing formdata incase the screen has not upated 
    yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'FormData', grid: initialState.FormData } });
    // Handle contextName and contextID not being set
    const contextName = action.payload.contextName ? action.payload.contextName : 'Not Set' 
    const contextID = action.payload.contextID ? action.payload.contextID : 0
    // Now on with the show
    const newURL = BASE_URL + "/formdata/" + action.payload.formID + "/" + action.payload.formDataID + "/" + contextName + "/" + contextID;
    const response = yield axios.get(newURL);
    process.env.REACT_APP_DEBUG && console.log(response);
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      const switchDataType = (item) => {
        var thisValue = item.dataValue;
        if (item.type === 'MULTIOPTION'){
          thisValue = item.dataValues
        }
        return thisValue
      }
      let gridData = {};
      if(response.data.length === 0) // if no form data returned, then overwrite with initial state
      {
        gridData = initialState.FormData;
      }
      else
      {
        var values = response.data[0].values.reduce((obj, item) => Object.assign(obj, { [`Q${item.questionID}`]: switchDataType(item) }), {} );
        gridData = { ...response.data[0], values: values }
      }
      yield put({ type: 'SET_FORMS_COLLECTION', payload: { collectionName: 'FormData', grid: gridData } });
    } 
    else // API completed with 200, however there is an error message
    {
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  } 
  catch (error) // API call itself has errored out
  {
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}

export function* putFormData(action) {
  try
  {
    const newURL = BASE_URL + "/formdata";
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action) + `  - ${newURL}`);
    const response = yield axios.put(newURL, action.payload); 
    if(response.status === 200 && response.data.returnStatus !== "Error") 
    {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`);  
      process.env.REACT_APP_DEBUG && console.log(response);
      toast.success(`Success: Form Saved.`);
      if (!action.payload.contextID || action.payload.contextID === 0)
      {
        yield put({ type: 'FETCH_FORMDATA', payload: { formDataID : action.payload.formDataID, formID : action.payload.formID, contextName : 'Not Set', contextID : 0} });
        yield put({ type: 'FETCH_ALLFORMDATA', payload: 0 });
      }
      else
      {
        yield put({ type: 'FETCH_FILES', payload: { type: action.payload.contextName, pkID: action.payload.contextID, popCache: true } });
      }
    }
    else // API completed with 200, however there is an error message
    {
      toast.error(`Failed: ${response.data.returnText}`);
      yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.data.returnStatus}`, Message : response.data.returnText } });
    }
  }
  catch (error) // API call itself has errored out
  {
    toast.error(`Failed: ${action.type}`);
    yield put({ type: 'PUT_ERROR', payload: {Request: action.type, ErrorType : 'REDUX Function, API call has errored', Message : error.message } });
  }
}



export const actions = {
  fetchForms:                    () => ({ type: actionTypes.FETCH_FORMS }),
  fetchDataNodes:                () => ({ type: actionTypes.FETCH_DATANODES }),
  fetchForm:              (payload) => ({ type: actionTypes.FETCH_FORM, payload: payload }),
  fetchAllFormData:       (payload) => ({ type: actionTypes.FETCH_ALLFORMDATA, payload: payload }),
  fetchFormData:          (payload) => ({ type: actionTypes.FETCH_FORMDATA, payload: payload }),
  fetchPreviewPDF:        (payload) => ({ type: actionTypes.FETCH_PREVIEWPDF, payload : payload }),
  clearPreviewPDF:              ()  => ({ type: actionTypes.CLEAR_PREVIEWPDF }),

  putForm:                (payload) => ({ type: actionTypes.PUT_FORM, payload: payload }),
  putFormQuestion:        (payload) => ({ type: actionTypes.PUT_FORMQUESTION, payload: payload }),
  putQuestionsOrder:      (payload) => ({ type: actionTypes.PUT_FORMQUESTIONS_ORDER, payload: payload }),
  deleteFormQuestion:     (payload) => ({ type: actionTypes.DELETE_FORMQUESTION, payload: payload }),
  deleteForm:             (payload) => ({ type: actionTypes.DELETE_FORM, payload: payload }),
  copyToProviders:        (payload) => ({ type: actionTypes.COPY_FORM_TO_PROVIDERS, payload: payload }),
  
  putFormData:           (payload) => ({ type: actionTypes.PUT_FORMDATA, payload: payload }),
};

function* actionWatcher() {
  yield takeLatest('FETCH_FORMS', fetchForms);
  yield takeLatest('FETCH_DATANODES', fetchDataNodes);
  yield takeLatest('FETCH_FORM', fetchForm);
  yield takeLatest('FETCH_ALLFORMDATA', fetchAllFormData);
  yield takeLatest('FETCH_FORMDATA', fetchFormData);
  yield takeLatest('FETCH_PREVIEWPDF',fetchPreviewPDF);

  yield takeLatest('PUT_FORM', putForm);
  yield takeLatest('PUT_FORMQUESTION', putFormQuestion);
  yield takeLatest('PUT_FORMQUESTIONS_ORDER', putQuestionsOrder);
  yield takeLatest('DELETE_FORMQUESTION', deleteFormQuestion);
  yield takeLatest('DELETE_FORM' ,deleteForm);
  yield takeLatest('COPY_FORM_TO_PROVIDERS', copyToProviders);

  yield takeLatest('PUT_FORMDATA',putFormData);
}

export function* saga() {
  yield all([
    actionWatcher(),
  ]); 
}


