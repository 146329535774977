import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { makeStyles, TextField, Grid, Button as MUIButton } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import OndemandVideoIcon 		from '@material-ui/icons/OndemandVideo';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Carousel from 'react-bootstrap/Carousel';
import * as Admin from "../../store/ducks/admin.duck";
import Notes from "../../components/Notes";
import SendLetter from "../../components/SendLetter";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from './ToggleButton';
import  './TileGrid.scss'
import { isArray } from 'lodash';
import { useHistory } from 'react-router-dom'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};


const useStyles = makeStyles((theme) => ({
	btnDash: {
		color: '#fff',
		padding: '1em 2em',
		fontSize: '1.1em',
		textTransform: "capitalize",
		fontWeight: '100',
    width: '90%'
	},
	btnDashHuge: {																// CONNECTED TO min-width of panel $min-cp-width
		padding: '0.7em 2.2em',
		fontSize: '1.4em',
		// Adjust icon adding  style={{ fontSize: 40 }}  to the componentattributes.
	},
  btnBulkBilling: {
    width: '100%',
  },
	btnHeroDarker: {		backgroundColor: '#93A33A',	'&:hover': {	backgroundColor: '#7c9300',	}	},
	btnFocusContrast: {		backgroundColor: '#00B3BE',	'&:hover': {	backgroundColor: '#D2B12B',	}	},
	btnContrastDarker: {	backgroundColor: '#D2B12B',	'&:hover': {	backgroundColor: '#b79300',	}	},
	btnSupportDarker: {		backgroundColor: '#A33383',	'&:hover': {	backgroundColor: '#890062',	}	},
	btnAdditionalDarker: {	backgroundColor: '#007cbe',	'&:hover': {	backgroundColor: '#005481',	}	},
	btnBulkSwitcher: {		backgroundColor: '#00B3BE',	'&:hover': {	backgroundColor: '#009ba5',	}	},

}));


export default function TileGrid(props) {

  const dispatch = useDispatch()
  const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
  const news = useSelector(state =>  state.admin.DashboardNews)
  const activityfeed = useSelector(state => state.admin.ActivityFeed)
  const opportunityFeed = useSelector(state => state.admin.OpportunityFeed)
  const [isBulkActive, setIsBulkActive] = useState(false)
  const [displayBulkBilling, setDisplayBulkBilling] = useState(false)
  const [showOpportunityRelevantInfo,setShowOpportunityRelevantInfo] = useState(false)

  const history = useHistory();
  const clients = useSelector(state => state.admin.Clients)
  const client = useSelector(state => state.admin.Client)
  // const medicalPractitioners = useSelector(state =>  state.admin.MedicalPractitioners);
  // const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.GeneralPractitioner);
  
  const [currentClient,setcurrentClient] = useState()
  const [showNotes, setShowNotes] = useState(false)
  const [showLetter,setShowLetter] = useState(false)
  // const [showFiles, setShowFiles] = useState(false);
  const [noteAction, setNoteAction] = useState('SMS')
  const [currentDOB,setcurrentDOB] = useState()
  const [currentClientID, setcurrentClientID] = useState() 
  const [currentFirstName, setcurrentFirstName] = useState()
  const [currentPreferredName, setcurrentPreferredName] = useState()
  const [currentSurname, setcurrentSurname] = useState();
  const [clientOpportunityInterest,setClientOpportunityInterest] = useState()
  const [clientOpportunityID,setClientOpportunityID] = useState()
  const [clientOpportunityStatus,setClientOpportunityStatus] = useState()
  const [clientOpportunityRelevantInfo,setClientOpportunityRelevantInfo] = useState()
  // const [currentMedicalPractitionerID, setcurrentMedicalPractitionerID] = useState();
    const [nextBookingDate, setnextBookingDate] = useState();

  useEffect( () => { dispatch(Admin.actions.fetchDashboardNews()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchOpportunityFeed()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchActivityFeed()) }, [dispatch] )
  useEffect( () => {
    // Determine if bulk billing button can be displayed 
    if (registered && (registered.providertype === 'Psychologist' || registered.providertype === 'Clinical' || registered.providertype === 'Occupational'))
    {
      setDisplayBulkBilling(true)
    }
    else
    {
      setDisplayBulkBilling(false)
    }
    // Determine if bulk billing button is set
    if (registered && registered.bulkBillingAvailable === "1")
    {
      setIsBulkActive(true)
    }
    else
    {
      setIsBulkActive(false)
    }
  },[registered])

  
	const classes = useStyles();	

  const handleBulkbillingAvailableChange = () => { 
    if(registered && registered.bulkBillingAvailable === "0")
    {
      // setBulkBillingAvailable("1")
      dispatch(Admin.actions.chgBulkBillingAvailability( { id: registered.id, bulkBillingAvailability: "1" } ))
    }
    else
    {
      // setBulkBillingAvailable("0")
      dispatch(Admin.actions.chgBulkBillingAvailability( { id: registered.id, bulkBillingAvailability: "0" } ))
    }    
  }
		
  function handleClientAutocompleteChange(propertyName, event, newValue) { 
    if (newValue) {
      // console.log(newValue);
      setcurrentClient(newValue);
      setcurrentClientID(newValue.id)
      setcurrentFirstName(newValue.firstname)
      setcurrentPreferredName(newValue.preferredname)
      setcurrentSurname(newValue.surname)
      // console.log(registered);
      dispatch(Admin.actions.fetchClient( { clientID: newValue.id, type: registered.providertype } ))
    }
  } 

  function sendLetter(thisClient) {
    setNoteAction(noteAction)
    setcurrentClientID(thisClient.id);
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setcurrentDOB(thisClient.dob)
    setShowLetter(true)
  }
   
  function expressInterest() {
    // Close modal
    setShowOpportunityRelevantInfo(false)
    // Make API call to express interest
    dispatch(Admin.actions.putClientOpportunityInterest( { clientOpportunityID: clientOpportunityID, providerStatus : clientOpportunityStatus, relevantInfo : clientOpportunityRelevantInfo } ))
  }

  function withdrawInterest(clientOpportunityID,status) {
    // Make API call to withdraw interest
    dispatch(Admin.actions.putClientOpportunityInterest( { clientOpportunityID: clientOpportunityID, providerStatus : status, relevantInfo : '' } ))
  }

  function expressInterestModal(clientOpportunityID,status,interest) { 
    // Pop Modal for Relevant Info
    setClientOpportunityInterest(interest)
    setClientOpportunityID(clientOpportunityID)
    setClientOpportunityStatus(status)
    setClientOpportunityRelevantInfo()
    setShowOpportunityRelevantInfo(true)
  } 

  function ReviewPlatFormCosts() {
    // console.log(history)
    history.push('/platform')
  }


  function loadNotes(thisClient, noteAction) {
    setNoteAction(noteAction);
    setcurrentClientID(thisClient.id);
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setnextBookingDate(thisClient.nextBooking)
    setShowNotes(true);
   }

  const initMedicalPractice = () => {
    return { id: 0, abn: '', practicename: '', phone: '', address1: '', email: '', website: '', logoURL: '', practiceType: 'GeneralPractitioner' }
  }

	return (
    <>
      <Grid container spacing={4} style={{marginBottom:4}}>
        <Grid item md={8}  >
          <Grid container spacing={4} style={{backgroundColor: '#F2F2F2', paddingLeft: 6, marginTop: 10, paddingBottom: 20}}>
            {/*  marginTop: 10, paddingBottom: 10, paddingTop: 5 */}
            <Grid item md={12} >
              <h2 className='h2' style={{textTransform: 'uppercase', fontSize: 15, marginBottom: 10, marginLeft: 5, fontWeight: 500}}>Control Panel</h2>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <Grid item md={12} style={{ paddingLeft: 20 }}>
                  <a href={ registered && registered.teleHealthProvider && registered.teleHealthProvider !== 'COVIU' ? registered.waitingRoomURL : "https://vcc.healthdirect.org.au/login" } target="_blank" rel="noopener noreferrer">
                    <MUIButton
                      variant="contained"
                      className={[classes.btnHeroDarker, classes.btnDash, classes.btnDash].join(' ')}
                      startIcon={<OndemandVideoIcon style={{ fontSize: 40 }} />}
                      disableElevation
                      size="small"
                    >
                      Enter TeleHealth Room  
                    </MUIButton>
                  </a>
                </Grid>
              </Grid>
              <Grid item md={8}>   
            <Grid container spacing={2}>
                <Grid item md={6} >
                  <div className='switch-set' style={{width: '100%'}}>
                    {displayBulkBilling ?
                      <ToggleButton
                        isActive={isBulkActive}
                        handleToggle={handleBulkbillingAvailableChange}
                        label='Bulk Billing'
                        className={classes.btnBulkBilling}
                        labelSuffix=<span className='bulk-suffix-fixed-width'>{isBulkActive ? 'Active' : 'Paused'}</span>
                        />
                      :
                        null
                      }
                  </div>
                </Grid>
                <Grid item md={6}>
                  <MUIButton
                    variant="contained"
                    className={[classes.btnAdditionalDarker, classes.btnDash].join(' ')}
                    startIcon={<FolderOpenIcon style={{ fontSize: 24 }} />}
                    disableElevation
                    size="small" 
                    onClick={() => loadNotes(client, 'SMS')}
                    >
                    Quick Action 
                  </MUIButton>
                </Grid>
                <Grid item md={6}>
                  <MUIButton
                    variant="contained"
                    className={[classes.btnSupportDarker, classes.btnDash].join(' ')}
                    startIcon={<FolderOpenIcon style={{ fontSize: 24 }} />}
                    disableElevation
                    size="small" 
                    onClick={() => sendLetter(client)}
                    >
                    Send Letter
                  </MUIButton>
                </Grid>
                <Grid item md={6}>
                  <MUIButton
                    variant="contained"
                    className={[classes.btnContrastDarker, classes.btnDash].join(' ')}
                    startIcon={<FolderOpenIcon style={{ fontSize: 24 }} />}
                    disableElevation
                    size="small" 
                    onClick={() => ReviewPlatFormCosts()}
                    >
                    Review Invoice
                  </MUIButton>
                </Grid>
                </Grid>         
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} >
            <Grid item md={6}>
              <Grid container spacing={2}  style={{ backgroundColor: '#F2F2F2', marginTop: 20, paddingBottom: 10, paddingTop: 10}} >
                <Grid item md={12} >
                  <h2 className='h2' style={{ textTransform: 'uppercase', fontSize: 15, marginBottom: 10, marginLeft: 5,fontWeight: 500}}>Notifications</h2>
                </Grid>
                <Grid item md={12} >
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    style={{
                      maxHeight: 250,
                      position: "relative"
                    }} >
                    <div className="kt-notification">
                      {activityfeed && activityfeed.map((item, index) => 
                        <Link key={index} to={item.link ? item.link : '/dashboard'} onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: item.clientID }))} >
                          <div className="kt-notification__item" key={item.id} style={{ paddingLeft: 2, paddingRight: 1, paddingTop: 3, paddingBottom: 3 }}>
                            {/* a href={item.link} */}
                            <div className="kt-notification__item-icon">
                              <i className={item.icon} />
                            </div>
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title" style={{ color:item.colour}}>
                                {item.label}
                              </div>
                              {item.additional ? 
                                <div className="kt-notification__item-title" >
                                  {item.additional}
                                </div>
                              :
                                null
                              }
                              <div className="kt-notification__item-time">
                                {item.created}
                              </div>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} >
              <Grid container spacing={2}  style={{ backgroundColor: '#F2F2F2', marginTop: 20, paddingBottom: 10, paddingTop: 10}} >
                <Grid item md={12} >
                  <Carousel variant='dark'
                    style={{
                      maxHeight: 294,
                      minHeight: 294,
                      position: "relative"
                    }} >
                    {news && isArray(news) && news.map( (itm,idx) => {
                      return (
                        <Carousel.Item key={idx}>
                          <div key={idx} className={`grit`}>
                            <div className='grit-content'  style={{ height: 300 }}>
                              <h2 className='h2'>{itm.title}</h2>
                              {/* <h3 className='h3'>{itm.subheading}</h3> */}
                              <p style={{paddingTop: 5}}>{itm.copy}</p>
                            </div>					
                            {/* {itm.download} */}
                          </div>
                        </Carousel.Item>
                      )
                    })}
                  </Carousel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} >
          <Grid container spacing={2} style={{ backgroundColor: '#F2F2F2',  marginTop: 10, paddingBottom: 10, paddingTop: 5}}>
            <Grid item md={12} >
              <h2 className='h2' style={{ textTransform: 'uppercase', fontSize: 15, marginBottom: 4, marginLeft: 5,fontWeight: 500}}>Available Opportunities</h2>
            </Grid>
            <Grid item md={12} >
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{
                  maxHeight: 486,
                  minHeight: 486,
                  position: "relative"
                }} >
                <div className="kt-notification">
                  {opportunityFeed && opportunityFeed.map((item) => 
                      <div className="kt-notification__item" key={item.id} style={{ paddingLeft: 2, paddingRight: 1, paddingTop: 10, paddingBottom: 10 }}>
                        <div className="kt-notification__item-details">
                          <div className="kt-notification__item-title" style={{ color:item.colour}}>
                            {item.description}
                          </div>
                          <div className="kt-notification__item-time">
                            Listed {item.created}
                            {item.interestcreatedon ? 
                              <Button variant="success" size="sm" onClick={() => withdrawInterest(item.id,'Withdraw')}  style={{ float: 'right', backgroundColor : item.colour }} >
                                  Withdraw Interest
                              </Button> 
                          :
                              <Button size="sm" onClick={() => expressInterestModal(item.id,'Express',item.description)} style={{ float: 'right', backgroundColor : item.colour }}>
                                  Express Interest
                              </Button> 
                          }

                          </div>
                        </div>
                      </div>

                  )}
                </div>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </Grid>
    </Grid>

    <Modal show={showNotes} onHide={()=>setShowNotes(false)} dialogClassName="notes-modal-dialog">
      <Modal.Header closeButton className="notes-modal">
        <Modal.Title>Notes - {currentClient && `${currentFirstName} ${currentSurname}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="notes-modal">
        <div className="row">          
          <div className="col-xl-12">
            <Form.Group style={{ marginBottom: 5 }}>
              <Form.Label>Client</Form.Label>
              <Autocomplete
                id="searchClient"
                variant="outlined"
                options={clients}
                getOptionLabel={(option) => option.fullname}
                value={currentClient ? currentClient : null}
                onChange={(event, newValue) => handleClientAutocompleteChange('profileTimeZone', event, newValue)}
                style={{ minWidth: 300, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    type="text"
                    variant="outlined"
                    margin="dense"
                    name="ClientSelector"
                    style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 300  }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password'}}
                  />}
              />
            </Form.Group>
          </div>
        </div>
        {registered && currentClient ? 
          <Notes type="Client" noteAction={noteAction} pkid={currentClientID} medicalPractice={initMedicalPractice()} waitingRoomURL={registered && (registered.teleHealthProvider === 'COVIU' ? registered.coviuRoomURL : registered.waitingRoomURL)} nextBookingDate={nextBookingDate} psychologistFullName={registered.firstname + ' ' + registered.surname} firstName={currentFirstName} faxGP={client && client.referralGPPracticeFax} />
        : 
          null
        }   
      </Modal.Body>
      <Modal.Footer className="notes-modal">
        <Button variant="secondary" onClick={() => setShowNotes(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showLetter} onHide={()=>setShowLetter(false)} dialogClassName="notes-modal-dialog">
      <Modal.Header closeButton className="notes-modal">
        <Modal.Title>Send Letter - {currentClient && `${currentFirstName} ${currentSurname}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="notes-modal">
        <div className="row">          
          <div className="col-xl-12">
            <Form.Group style={{ marginBottom: 5 }}>
              <Form.Label>Client</Form.Label>
              <Autocomplete
                id="searchClient"
                variant="outlined"
                options={clients}
                getOptionLabel={(option) => option.fullname}
                value={currentClient ? currentClient : null}
                onChange={(event, newValue) => handleClientAutocompleteChange('profileTimeZone', event, newValue)}
                style={{ minWidth: 300, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    type="text"
                    variant="outlined"
                    margin="dense"
                    name="ClientSelector"
                    style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 300  }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password'}}
                  />}
              />
            </Form.Group>
          </div>
        </div>
        {registered && currentClient ? 
          <SendLetter type="Client" pkid={currentClientID} psychologistFullName={registered.firstname + ' ' + registered.surname} dob={currentDOB} firstName={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName} surname={currentSurname} faxGP={client && client.referralGPPracticeFax} />
        : null}   
      </Modal.Body>
      <Modal.Footer className="notes-modal">
        <Button variant="secondary" onClick={() => setShowLetter(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showOpportunityRelevantInfo} onHide={()=>setShowOpportunityRelevantInfo(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>Please enter any relevant information to your expression of interest</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
        <Grid item md={12}>  
      <Row>
        <Col md="12">                  
          <Form.Group >
              <Form.Label>Opportunity</Form.Label>
              <p style={{ fontStyle:'italic' }}>{clientOpportunityInterest}</p>
              <Form.Label>Relevant Info (maximum 500 characters)</Form.Label>
              <Form.Control as="textarea" rows={3} type="text" name="relevantInfo" defaultValue={clientOpportunityRelevantInfo} onBlur={(event) => setClientOpportunityRelevantInfo(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Grid container spacing={0} padding={6} justifyContent="space-between">
            <Button variant="light" onClick={()=>setShowOpportunityRelevantInfo(false)}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={() => expressInterest()}>
              Express Interest
            </Button>
          </Grid>
        </Col>
      </Row>
    </Grid>
        </Modal.Body>
      </Modal>


    </>
	)
}