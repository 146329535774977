
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux'
import * as Forms from "../../../store/ducks/forms.duck";
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Editor } from '@tinymce/tinymce-react';
import Interweave from 'interweave';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Grid, Card, CardHeader, CardContent, makeStyles, Divider, MenuItem, Select, RadioGroup, TextField, FormControl, FormGroup, FormHelperText, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup } from "react-bootstrap";
import { ToastContainer, Flip } from "react-toastify";
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { DropzoneArea } from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from "yup";
import EditIcon from '@material-ui/icons/Edit';
import KTUtil  from "../../../../_metronic/_assets/js/util";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.pointerEvents = 'none';
      div.style.top = '0';
      div.style.width = '100%';
      div.style.height = '100%';
      self.elt = div;
      document.body.appendChild(div);
      return () => {
          document.body.removeChild(div);
      };
  }, [self]);

  return (render) => (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
          return createPortal(element, self.elt);
      }
      return element;
  };
};
  
export default function FormQuestions({ setFormID, formID, readOnly }) {

  const classes = useStyles();
  const form = useSelector(state => state.forms.Form);
  const dataNodes = useSelector(state => state.forms.DataNodes);
  const dispatch = useDispatch();
    
  useEffect( () => { dispatch(Forms.actions.fetchForm(formID)) }, [dispatch, formID] )
  useEffect( () => { dispatch(Forms.actions.fetchDataNodes()) }, [dispatch])

  const [questions, setQuestions] = useState();

  useEffect( () => { setQuestions(form.formQuestions) }, [setQuestions, form] )

  const [show, setShow] = useState(false);
  const [tabKey, setTabKey] = useState('details');

  const types = [
    { type: 'VARCHAR', label: 'General (text)' },
    { type: 'TEXT', label: 'Paragraph' },
    { type: 'DATE', label: 'Date' },
    { type: 'OPTION', label: 'Option List' },
    { type: 'MULTIOPTION', label: 'Multiple Options' },
    { type: 'FILE', label: 'File' },
    { type: 'STATEMENT', label: 'Statement' },
    { type: 'STATEMENTPDF', label: 'PDF Statement' },
    { type: 'SIGNATURE', label: 'Signature'}
  ]

  // const formStatuses = ['Active','Inactive']
  const initFormQuestion = () => {
    return {
      id: 0,
      formID: formID,
      questionID: 0,
      questionIndex: 0,
      type: 'VARCHAR',
      reference: '',
      questionText: '',
      questionHelpText: '',
      questionStatement: '',
      questionStatementPDFFile: '',
      typeInput: 'radio',
      questionLayout: '',
      answerLayout: 'column',
      answerInputSize: '',
      questionStyles: '', 
      questionStatus: '',
      mandatory: true,
      vhdataNodeName: '',
      formOptionListID: null,
      readOnly: 0,
      protected: false,
      questionPlaceHolderText: '',
      adminUseOnly: false,
      nodeID:0,
      formOptions: []
    }
  }
    
  const [formQuestion, setFormQuestion] = useState(initFormQuestion())

  useEffect( () => { 
    if(formQuestion.type !== 'OPTION' && formQuestion.type !== 'MULTIOPTION' && tabKey === 'options'){
      setTabKey('details')
    }   
  }, [setTabKey, tabKey, formQuestion] )  

  const loadFormQuestion = (thisFormQuestion) => {
    setFormQuestion({ ...thisFormQuestion });
    setItems(thisFormQuestion.formOptions)
    setShow(true);
  }
    
  const validationSchema = Yup.object().shape({
    reference: Yup.string()
      .min(2,"Reference Too Short")
      .max(50,"Reference Too Long")
      .required("Reference is required"),
    questionText: Yup.string()
      .when(['type'],
      {
        is: (type) => type === 'VARCHAR' || type === 'TEXT',
        then: Yup.string().required("Question Text is required")
                          .min(2,"Question Text Too Short")
                          .max(3950,"Question Text Too Long")
      }),
    questionStatement: Yup.string()
      .when(['type'],
      {
        is: (type) => type === 'STATEMENT',
        then: Yup.string().required("Statement Text is required")
                          .min(2,"Statement Text Too Short")
                          .max(3950,"Statement Text Too Long")
      }),
    });

    


  const submitForm = (values) => {
    // var payload = {
    //   formQuestion: values,
    //   formOptions: items
    // }
    const formData = new FormData();
    formData.append('id', values['id'])
    formData.append('formID', values['formID'])
    formData.append('questionID', values['questionID'])
    formData.append('questionIndex', values['questionIndex'])
    formData.append('type', values['type'])
    formData.append('reference', values['reference'])
    formData.append('questionText', values['questionText'])
    formData.append('questionHelpText', values['questionHelpText'])
    formData.append('questionStatement', values['questionStatement'])
    formData.append('questionStatementPDFFile', values['questionStatementPDFFile'])
    formData.append('typeInput', values['typeInput'])
    formData.append('questionLayout', values['questionLayout'])
    formData.append('answerLayout', values['answerLayout'])
    formData.append('answerInputSize', values['answerInputSize'])
    formData.append('questionStyles', values['questionStyles'])
    formData.append('questionStatus', values['questionStatus'])
    if (values['nodeID'] === null)
    {
      formData.append('nodeID', 0)
    }
    else
    {
      formData.append('nodeID', values['nodeID'])
    }
    formData.append('mandatory', values['mandatory'])
    formData.append('vhdataNodeName', values['vhdataNodeName'])
    formData.append('formOptionListID', values['formOptionListID'])
    formData.append('readOnly', values['readOnly'])
    formData.append('protected', values['protected'])
    formData.append('questionPlaceHolderText', values['questionPlaceHolderText'])
    formData.append('adminUseOnly', values['adminUseOnly'])
    formData.append('formOptionsJSON', JSON.stringify(items))

    setShow(false);
    dispatch(Forms.actions.putFormQuestion({formData}))
  }
   
  const deleteQuestion = () => {
    if (!readOnly)
    {
      var payload = {
        formID: formID,
        questionID: formQuestion.questionID
      }
      setShow(false);
      dispatch(Forms.actions.deleteFormQuestion(payload))
    }
  }

  function FormInput({question}) {
    switch(question.type) {
      case 'VARCHAR':
        return  <FormVarchar question={question}/>
      case 'TEXT':
        return <FormText question={question}/>
      case 'STATEMENT':
        return <FormStatement question={question}/>
      case 'STATEMENTPDF':
        return <FormStatementPDF question={question}/>
      case 'OPTION':
        if(question.typeInput === 'radio')
        {
          return <FormOptionRadio question={question}/>
        }
        else // question.typeInput === 'radio'
        {
          return <FormOptionSelect question={question}/>
        }
      case 'MULTIOPTION':
        return <FormOptionCheckbox question={question}/>
      case 'DATE':
        return <FormDate question={question}/>
      case 'FILE':
        return <FormFile question={question}/>
      case 'SIGNATURE':
        return <FormSignature question={question}/>
      default :
        return null
    }
  }

  function FormVarchar({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return <Form.Control type="text" id={qname} name={qname} autoComplete='new-password' placeholder={question.questionPlaceHolderText}/>     
  }

  function FormText({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return <Form.Control as="textarea" rows={4} type="text" id={qname} name={qname} autoComplete='new-password' placeholder={question.questionPlaceHolderText}/>     
  }

  function FormStatement({question}) {
    return <Interweave content={question.questionStatement} />
  }

  function FormStatementPDF({question}) {
    const [questionPDF, showQuestionPDF] = useState(false);
    const [numPages, setNumPages] = useState(null);  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
      <>
        <Button onClick={()=>showQuestionPDF(true)}>{question.questionStatementPDFFileName}</Button>
        
        <Modal show={questionPDF} onHide={()=>showQuestionPDF(false)} dialogClassName="viewQuestionPDF-dialog-modal">
          <Modal.Header closeButton className="viewQuestionPDF-modal" style={{ padding: 10 }}>
            <Modal.Title style={{ marginTop: 6, width: "75%", }}>
              {question.questionStatementPDFFileName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="viewQuestionPDF-modal" style={{ height: 700  }}>
            <Grid container spacing={2}>
              <Grid item md={12} style={{ height: 680, overflowY: "scroll", backgroundColor: "white" }}>
                {question.questionStatementPDF.length > 5 ? 
                  <Document
                    file={'data:application/pdf;base64,'+ question.questionStatementPDF}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    {Array.from(
                      new Array(numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          scale={1.5}
                        />
                      ),
                    )}
                  </Document> : <div style={{ marginLeft: 15, marginTop: 15 }}>No PDF Attached.</div>}
              </Grid>
            </Grid>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  function FormOptionSelect({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return (      
      <Select 
        variant="outlined"
        margin="dense"
        id={qname}
        aria-label='Type'
        name={qname}
        value=''
        style={{ backgroundColor: "#ffffff" }} >
          <MenuItem value=''>&nbsp;</MenuItem>
          {question.formOptions ? question.formOptions.map(options => {
            return (
              <MenuItem 
                key={options.optionID}
                value={options.optionID}>
                {options.optionLabel}
              </MenuItem>
            )
            })
          : null}
      </Select>
    )
  }

  function FormOptionRadio({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    //<Form.Control type="text" id={qname} name={qname} autoComplete='new-password' placeholder={question.questionPlaceHolderText}/>  
    return (
      <Grid item xs={12} md={12}>
        <RadioGroup 
          aria-label={question.questionText} 
          style={{ paddingLeft: 8 }}
          //name={values[qname] || ''} 
          //value={values[qname] || ''} 
          row={question.answerLayout === 'row' ? true : false}
          >
          {question.formOptions ? 
            question.formOptions.map(options => {
              return (
                <FormControlLabel
                  key={options.optionID}
                  value={options.optionID}
                  control={<Radio name={qname} className="form-radio-input" />}
                  label={options.optionLabel}
                  style={{ marginBottom: 0 }}
                  // onChange={handleChange(qname)}
                />
              )
            })
            : ('None')
          }
        </RadioGroup>
      </Grid>
    )    
  }

  function FormOptionCheckbox({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup row={question.answerLayout === 'row' ? true : false}>
          {question.formOptions ? 
              question.formOptions.map(options => {
                return (
                  <FormControlLabel
                    key={options.optionID}
                    control={<Checkbox name={qname} className="form-radio-input" />}
                    label={options.optionLabel}
                    style={{ marginBottom: 0 }}
                  />
                )
              })
              : ('None')
            }
        </FormGroup>
        <FormHelperText>{question.questionHelpText}</FormHelperText>
      </FormControl>
    ) 
  }

  function FormDate({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return (      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            name={qname}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="dense"
            id={qname}
            autoOk={true}
            size="small"
            onChange={() => null}
        />
      </MuiPickersUtilsProvider>
    )   
  }

  function FormFile({question}) {
    // Add q to name - having issues with variables as numbers (it is a primtive language)
    const qname = 'Q' + question.questionID;
    return (
      <Grid item xs={12} md={12} style={{ marginTop: 12 }}> 
        <DropzoneArea
          name={qname}
          acceptedFiles={['image/*,.pdf']}
          showPreviews={true}
          maxFileSize={4000000}
          showPreviewsInDropzone={false}
          dropzoneText={"Drag and drop files here, or use your phone camera."}
          dropzoneClass="drop-zone-area"
          onChange={() => null}
        />
      </Grid> 
    )  
  }

  function FormSignature({question}) {
    return (
      <>
      <FormHelperText>Please draw your signature with mouse or touch:</FormHelperText>
        <div className="sigOuterContainer">
          <div className="sigContainer" style={{ height: 200 }}>
          </div>
        </div>
      </>
    )   
  }


  // Manage question options
  const [items, setItems] = useState([]);

  function handleOnDragEnd(result) {
    // create a new re-ordered array from the draggable context and set it back to state
    if(result.destination) {
      const itemsCopy = Array.from(items);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);
      setItems(itemsCopy);
    }
  }

  function ErrorsNotification ({ errors, touched }) {
    // console.log(errors)
    // console.log(touched)
    if(!KTUtil.isEmpty(errors) && !KTUtil.isEmpty(touched))
    {
      return(
        <>
          <div className="errors-list">
            <h4 className="errors-list-heading">Please fix the following fields before you submit:</h4>
            <ul>
            {Object.keys(errors).map(msg => (
              <React.Fragment key={msg}>
                {touched[msg] ?
                  <li>{errors[msg]}</li>
                :
                  null
                }
              </React.Fragment>
            ))}
            </ul>
          </div>
          <hr />
        </>
      )
    } 
    else
    {
      return null
    }
  }


  function handleOnDragEndQuestions(result) {
    // create a new re-ordered array from the draggable context and set it back to state
    if (!readOnly)
    {
      if(result.destination) {
        const itemsCopy = Array.from(questions);
        const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
        itemsCopy.splice(result.destination.index, 0, reorderedItem);
        setQuestions(itemsCopy);
        var payload = {
          formID: formID,
          formQuestions: itemsCopy.map((question, index) => {
            return {
              questionID: question.questionID,
              questionIndex: index + 1
            }
          })
        }
        dispatch(Forms.actions.putQuestionsOrder(payload))
      }
    }
  }

  function makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
   }
   return result.join('');
  }

  function saveLabel(event) {
    if(event.key === 'Enter' || event.key === undefined)
    {
      updateLabel(event.target.name, event.target.value)
    }
  }

  function addLabel(value) {
    if(value.length > 0)
    {
      var newItem = { optionID: makeid(6), optionLabel: value }
      var tempItems = [ ...items ];
      tempItems.push(newItem);
      setItems(tempItems);
    }
  }

  function updateLabel(optionID, value) {
    // merge in new label into item in items array
    var tempItems = [ ...items ]
    tempItems.forEach(function(obj) {
      if (obj.optionID === optionID) {
          obj.optionLabel = value;
      }
    setItems(tempItems); 
  });
  }

  function deleteLabel(rowindextoDelete) {
    var tempItems = items.filter((row, index) => index !== rowindextoDelete);
    setItems(tempItems);
  }

  function AddItem () {
    const [newLabel, setNewLabel] = useState('');
    function addNewLabel(value) {
      addLabel(value)
      setNewLabel('')
    }
    return(
      <Form.Control style={{ height: 40 }} type="text" id="itemLabel" name="newLabel" value={newLabel} onChange={(event) => setNewLabel(event.target.value)} onBlur={(event) => addNewLabel(event.target.value)} autoComplete='new-password'/>
    )
  }

  // general function to capture Enter key press (to align it with tab/onBlur) - this will prevent the form being submitted and run relevant update/save methods instead
  function onKeyDown(event) {
    if ((event.charCode || event.keyCode) === 13) {
      
      // new checklist item
      if(event.target.name === 'newLabel')
      {
        addLabel(event.target.value)
      }
      event.preventDefault();
    }
  }

  const renderDraggable = useDraggableInPortal();

  useEffect(() => {
    const handler = (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    };
    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);
  
return (
  <div className='container-fluid'>
    
    <Card className={classes.root}>
    {!readOnly ?
      <CardHeader
        title={form.formName}
        subheader={null}
        className={classes.cardHeader}
          action={
            <>
              <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5' }} onClick={() => loadFormQuestion(initFormQuestion())} >
                Add Question
              </Button>
              <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setFormID()} >
                <CloseIcon />
              </Button>              
            </>
            }
      />
    :
      <CardHeader
        title={form.formName}
        subheader={null}
        className={classes.cardHeader}
      />
    }
      <CardContent className="formquestion-card">

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <p>{form.formInstructions}</p>
          </Grid>
          <DragDropContext onDragEnd={handleOnDragEndQuestions}>
            <Droppable droppableId="questionsList">
              {(provided) => (
                <div className="formquestions-draggable" {...provided.droppableProps} ref={provided.innerRef}>

                <Grid container spacing={1}>
                  {questions && questions.map((question, index) => {
                    return (
                      
                      <Draggable key={`id-${question.questionID}`} draggableId={`idd-${question.questionID}`} index={index}>
                        {renderDraggable((provided) => (

                            <Grid item xs={12} md={12} key={question.questionID} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={question.adminUseOnly ? 'formquestion-container-admin-only' : 'formquestion-container'}>
                              
                                <Grid container direction="row" justifyContent="space-between">
                                  <Grid item xs={10} md={10}>
                                  {question.type !== 'STATEMENT' ?
                                    <Form.Label style={{ width: '100%', fontWeight: 600 }}>
                                      {question.questionText} {question.mandatory ? ' * ' : null} {question.adminUseOnly ? ' (admin only) ' : null}
                                    </Form.Label>
                                    : null}
                                  </Grid>
                                  {!readOnly ? 
                                    <Grid item xs={2} md={2} style={{ textAlign: 'right' }}>
                                      <EditIcon className="formquestion-edit-icon" onClick={() => loadFormQuestion(question)} />
                                    </Grid>
                                  :
                                    null
                                  }
                                </Grid>
                                <FormInput question={question} />
                                <FormHelperText>{question.questionHelpText}</FormHelperText>
                              
                            </Grid>
                        ))}
                      </Draggable>

                    );
                  })}
                  {provided.placeholder}
                  </Grid>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

      </CardContent>
      <Divider />

    </Card>

    <ToastContainer
      transition={Flip}
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

    <Modal show={show} onHide={() => setShow(false)} dialogClassName="edit-md-sm-dialog-modal">
			<Modal.Header closeButton className="edit-md-sm-modal">
			<Modal.Title>Edit Form Question</Modal.Title>
			</Modal.Header>
                 
			<Formik
			  enableReinitialize={false}
			  initialValues={formQuestion}
			  validationSchema = {validationSchema}
			  onSubmit={(values, actions) => {
				submitForm(values);
				// actions.setSubmitting(false);
			  }}
			>
			{props => {
			const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = props; 
			const change = (name,e) => { e.persist();  handleChange(e); };
			return (
			<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>

			<Modal.Body className="edit-md-modal">
			  <Tabs
				id="EditModal"
				activeKey={tabKey}
				onSelect={(k) => setTabKey(k)}
				mountOnEnter={true}
			  > 
			    <Tab eventKey="details" title="Details" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
			    <Row>

					<Col md="6">
						<Form.Group>
							<Form.Label>Reference</Form.Label>
							<Form.Control type="text" id="reference" name="reference" value={values.reference} onChange={change.bind(null, "reference")} autoComplete='new-password' isInvalid={touched.reference && !!errors.reference} isValid={touched.reference && !errors.reference}/>
							<Form.Control.Feedback type="invalid">{errors.reference}</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md="6">
						<Form.Group>
							<Form.Label>Type</Form.Label>
                <Select 
                  variant="outlined"
                  margin="dense"
                  id='type'
                  aria-label='Type'
                  name='type'
                  value={values.type}
                  onChange={change.bind(null, "type")}
                  style={{ width: '100%', backgroundColor: "#ffffff" }} >
                    {types.map(row => {
                      return <MenuItem key={row.type} value={row.type}>{row.label}</MenuItem>
                  })}
                </Select>
						</Form.Group>
					</Col>

          {values.type !== 'STATEMENT' ? 
					<Col md="12">
						<Form.Group>
							<Form.Label>Question Text</Form.Label>
							<Form.Control as="textarea" rows={4} type="text" id="questionText" name="questionText" value={values.questionText} onChange={change.bind(null, "questionText")} autoComplete='new-password' isInvalid={touched.questionText && !!errors.questionText} isValid={touched.questionText && !errors.questionText}/>
							<Form.Control.Feedback type="invalid">{errors.questionText}</Form.Control.Feedback>
						</Form.Group>
					</Col>
          : null}

          {values.type === 'STATEMENTPDF' ?
            <Col md="12">
              <Form.Group>
                <Form.Label>PDF Statement</Form.Label>
                  <Form.Control type="file" name="questionStatementPDFFile" onChange={(event) => setFieldValue('questionStatementPDFFile', event.currentTarget.files[0])} isInvalid={touched.questionStatementPDFFile && !!errors.questionStatementPDFFile} isValid={touched.questionStatementPDFFile && !errors.questionStatementPDFFile} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                  <Form.Control.Feedback type="invalid">{errors.questionStatementPDFFile}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          : null}

          {values.type === 'STATEMENT' ? 
            <Col md="12">
              <Form.Group>
                <Form.Label>Statement</Form.Label>
                <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                  value={values.questionStatement}
                  onEditorChange={(newValue, editor)=>setFieldValue("questionStatement", newValue)}
                  init={{ branding: false, statusbar: false, height: 500 }}
                />
              </Form.Group>
            </Col>
          : 
            <>
              <Col md="12">
                <Form.Group>
                  <Form.Label>Help Text</Form.Label>
                  <Form.Control type="text" id="questionHelpText" name="questionHelpText" value={values.questionHelpText} onChange={change.bind(null, "questionHelpText")} autoComplete='new-password' isInvalid={touched.questionHelpText && !!errors.questionHelpText} isValid={touched.questionHelpText && !errors.questionHelpText}/>
                  <Form.Control.Feedback type="invalid">{errors.questionHelpText}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group style={{width: "100%"}}>
                    <Form.Label>DataNode Link</Form.Label>  
                    <Autocomplete
                      id="dataNodeID"
                      variant="outlined"
                      options={dataNodes}
                      getOptionLabel={(option) => option.description || ''}
                      value={dataNodes.find(v => v.id === values.nodeID) || null}
                      onChange={(e, newValue) => {setFieldValue("nodeID", newValue ? newValue.id : null);}}
                      style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="currentContactID"
                          style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 400  }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Form.Label>Placeholder Text</Form.Label>
                  <Form.Control type="text" id="questionPlaceHolderText" name="questionPlaceHolderText" value={values.questionPlaceHolderText} onChange={change.bind(null, "questionPlaceHolderText")} autoComplete='new-password' isInvalid={touched.questionPlaceHolderText && !!errors.questionPlaceHolderText} isValid={touched.questionPlaceHolderText && !errors.questionPlaceHolderText}/>
                  <Form.Control.Feedback type="invalid">{errors.questionPlaceHolderText}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <Form.Label>Other Settings</Form.Label>
                  <br/>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox name="mandatory" className="form-radio-input"  checked={values.mandatory} onChange={change.bind(null, "mandatory")}  />}
                        label="Mandatory - question must be answered"
                        style={{ marginBottom: 0 }}
                      />
                      <FormControlLabel
                        control={<Checkbox name="adminUseOnly" className="form-radio-input"  checked={values.adminUseOnly} onChange={change.bind(null, "adminUseOnly")}  />}
                        label="Admin use only (not displayed for data entry)"
                        style={{ marginBottom: 0 }}
                      />
                    </FormGroup>
                  </FormControl>
                </Form.Group>
              </Col>
            </>
          }
				</Row>
				</Tab>
        
        {values.type === 'OPTION' || values.type === 'MULTIOPTION' ?
        <Tab eventKey="questionOptions" title="Answer Options" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}} onKeyDown={onKeyDown} >
          {values.type === 'OPTION' ?
          <Row>
            <Col md="4">
            <Form.Group>
                <Form.Label>Input Type</Form.Label>
                  <Select 
                    variant="outlined"
                    margin="dense"
                    id='typeInput'
                    aria-label='Input Type'
                    name='typeInput'
                    value={values.typeInput}
                    onChange={change.bind(null, "typeInput")}
                    style={{ backgroundColor: "#ffffff" }} >
                    <MenuItem value='radio'>Radio Buttons</MenuItem>
                    <MenuItem value='select'>Dropdown</MenuItem>
                  </Select>
              </Form.Group>
            </Col>
            <Col md="4">
            <Form.Group>
                <Form.Label>Options Layout</Form.Label>
                  <Select 
                    variant="outlined"
                    margin="dense"
                    id='answerLayout'
                    aria-label='Options Layout'
                    name='answerLayout'
                    value={values.answerLayout}
                    onChange={change.bind(null, "typeInput")}
                    style={{ backgroundColor: "#ffffff" }} >
                    <MenuItem value='row'>Horizontal</MenuItem>
                    <MenuItem value='column'>Vertical</MenuItem>
                  </Select>
              </Form.Group>
            </Col>
          </Row>
          : null}
          {values.type === 'MULTIOPTION' ?
          <Row>
            <Col md="4">
            <Form.Group>
                <Form.Label>Options Layout</Form.Label>
                  <Select 
                    variant="outlined"
                    margin="dense"
                    id='answerLayout'
                    aria-label='Options Layout'
                    name='answerLayout'
                    value={values.answerLayout}
                    onChange={change.bind(null, "typeInput")}
                    style={{ backgroundColor: "#ffffff" }} >
                    <MenuItem value='row'>Horizontal</MenuItem>
                    <MenuItem value='column'>Vertical</MenuItem>
                  </Select>
              </Form.Group>
            </Col>
          </Row>
          : null}
          <Row>
            <Col md="12">
              <Form.Group>
                <Form.Label>Options</Form.Label> <span style={{ fontSize: 12 }}>( Drag items by # to reorder )</span>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="mylist">
                    {(provided) => (
                      <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                        {items && items.map(({ optionID, optionLabel, optionIndex }, index) => {
                          return (
                            <Draggable key={`id-${optionID}`} draggableId={`id-${optionID}`} index={index}>
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="checklist-item">                                          
                                  <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {index + 1}.
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control style={{ height: 45 }} type="text" id={optionID} name={optionID} defaultValue={optionLabel} onKeyPress={(event) => saveLabel(event)} onBlur={(event) => updateLabel(event.target.name, event.target.value)}  autoComplete='new-password'/> 
                                    <InputGroup.Append>
                                      <InputGroup.Text onClick={() => deleteLabel(index)}>
                                        <DeleteOutlineIcon style={{ cursor: 'pointer' }} />
                                      </InputGroup.Text>
                                    </InputGroup.Append>
                                  </InputGroup>                                
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Form.Group>           
              <Form.Group>
                <Form.Label>Add Item:</Form.Label>              
                <div className="checklist-item">                                          
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        &nbsp; &nbsp;
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <AddItem />
                  </InputGroup>
                </div>
              </Form.Group>
            </Col>
				  </Row>
        </Tab>
        : null}
			  </Tabs>
			</Modal.Body>
      <ErrorsNotification errors={errors} touched={touched} />
			<Modal.Footer className="edit-md-modal">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button variant="warning" onClick={() => deleteQuestion()}>
              Delete
            </Button>
          </Grid>
          <Grid item>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" style={{ marginLeft: 8 }}>
            Save
          </Button>
          </Grid>
        </Grid>
			</Modal.Footer>
			</form>
			)
			}}
			</Formik>
		</Modal>

    </div>
  )

}

const useStyles = makeStyles(theme => ({  
  root: {
    maxWidth: 650,
  },
  cardHeader: {
    borderBottom: "solid 1px silver",
    backgroundColor: "#daeaf5",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    marginLeft: 8
  },
  dropzoneQuestion: {
    height: 100
  }
}));

