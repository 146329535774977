import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutoComplete from "../../../components/AddressAutoComplete";
import * as Reference from "../../../store/ducks/reference.duck";
import * as Admin from "../../../store/ducks/admin.duck";
import { Button, Modal, Form, Row, Col, InputGroup, Image, Badge } from "react-bootstrap";
import { Paper, Grid, TextField, Typography, makeStyles, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox, Card, CardMedia, CardContent, Chip } from '@material-ui/core';
import Interweave from 'interweave'
import DescriptionIcon from '@material-ui/icons/Description';
import { Formik } from 'formik';
import * as Yup from "yup";
import Alert from '@material-ui/lab/Alert';
import APIKeyForm from "../../../components/APIKeyForm";
import ProviderProgramBio from "../../../components/ProviderProgramBio";
import { Editor } from '@tinymce/tinymce-react';
import StarIcon from '@material-ui/icons/Star';
import { ToastContainer, Flip } from "react-toastify";
import Masonry from 'react-masonry-css';
import  KTUtil  from "../../../../_metronic/_assets/js/util";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../../_metronic/_assets/css/app2.css';
import 'bootstrap/dist/css/bootstrap.min.css'


const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  media: {
    height: 200,
  },
  root: {
    "& .MuiInputBase-root": {
      height: 33,
    }
  }  
}));


export default function ProfilePsychologist() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchMBSServices()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )

  
  const fundingTypesReference = useSelector(state =>  state.reference.FundingTypes)
  const mbsServicesReference = useSelector(state =>  state.reference.MBSServices)
  const timeZone = useSelector(state =>  state.reference.TimeZone)
  const classes = useStyles();

  
  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 
  var [currentprofilepicture, setcurrentprofilepicture] = useState();

  // now init the fields with values so that Formik can manage the touched state
  const psychologistInit = {
    psychologistid : 0,
    firstname: '', 
    surname: '', 
    gender: '',
    email: '', 
    mobilephone: '',
    education: '',
    startedpracticingyear: 2000,
    bio: '',

    practicename: '',
    abn: '',
    fulladdress: '',
    latitude: '',
    longitude: '',
    postcode: '',

    providernumber : '',
    insurancenumber: '',
    hourlyrate: '',
    newInsuranceFile: '',
    reducedFee:'',
    
    areasOfInterest: [],
    keyAreas: [],
    fundingTypes: [],
    mbsServices: [],
    waitingRoomURL: '',
    imageURL: currentprofilepicture,

    pdfpassword: '',
    timeZone: 'AUS Eastern Standard Time',
    medipassapikey: '',
    providertype: 'Psychologist',
    providerdescription: 'Psychologist',
    ahpraRegisrationNo: '',
    coviuRoomURL: '',
    isReducedFee: 'N',
    teleHealthProvider: 'COVIU',
    mfaConfigured: false,
    supervisorAreas: [],
    supervisorBio: '',
    supervisorEducation: '',
    supervisorHourlyRate : '',
    autoBulkBillingConsentForm: 'N'
  }


  // for previewing uploaded profile pictures
  var [previewFile, setPreviewFile] = useState('')
  var [showAPIKey, setShowAPIKey] = useState(false)
  var [showCustomBio,setShowCustomBio] = useState(false)
  var [insuranceFileClear, setInsuranceFileClear] = useState(0)
  var [showRegistrationView, setShowRegistrationView] = useState(false)   
  var [showProviderBio,setShowProviderBio] = useState(false)
  var [providerBioFullName,setProviderBioFullName] = useState()
  var [providerBioBio,setProviderBioBio] = useState()
  var [providerRegistrationView,setProviderRegistrationView] = useState(psychologistInit)
  var [currentPaymentType,setCurrentPaymentType] = useState('Reduced Gap')

  function handleRegistrationViewClose() {
    setShowRegistrationView(false);
  }  

  function handleAPIKeyClose() {
    setShowAPIKey(false);
  }  

  function handleCustomBioClose() {
    setShowCustomBio(false)
  }  

  const registrationView = (values,feeType) => {
    // Populate Modal which contains preview of Provider Registration Information
    setCurrentPaymentType(feeType)
    setProviderRegistrationView(values)
    setShowRegistrationView(true)
  }


  const moreProviderBio = (fullName, bio) => {
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

  function ErrorsNotification ({ errors, touched }) {
    if(!KTUtil.isEmpty(errors) && !KTUtil.isEmpty(touched))
    {
      return(
        <>
          <div className="errors-list">
            <h4 className="errors-list-heading">Please fix the following fields before you submit:</h4>
            <ul>
            {Object.keys(errors).map(msg => (
              <React.Fragment key={msg}>
                {touched[msg] ?
                  <li>{errors[msg]}</li>
                :
                  null
                }
              </React.Fragment>
            ))}
            </ul>
          </div>
          <hr />
        </>
      )
    } 
    else
    {
      return null
    }
  }



  // get the psychologist from state and merge with the initialised fields
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  var psychologist = {...psychologistInit, ...registered};

  // setup the aoi according to provider type and aoi type
  // const areasOfInterestReference = useSelector(state => state.reference.AreasOfInterest).filter(area => area.providertype === (registered && registered.providertype === 'Provisional' ? 'Psychologist' : registered && registered.providertype))

  const aoi_focus = useSelector(state => state.reference.AreasOfInterest).filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === (registered && registered.providertype === 'Provisional' ? 'Psychologist' : registered && registered.providertype === 'Clinical' ? 'Psychologist': registered && registered.providertype === 'Occupational' ? 'Psychologist': registered && registered.providertype === 'Social' ? 'Psychologist': registered && registered.providertype === 'Nurse' ? 'Psychologist': registered && registered.providertype))
  const aoi_other = useSelector(state => state.reference.AreasOfInterest).filter(aoi => aoi.focusAreaIndex === null && aoi.providertype === (registered && registered.providertype ===  'Provisional' ? 'Psychologist' : registered && registered.providertype === 'Clinical' ? 'Psychologist': registered && registered.providertype === 'Occupational' ? 'Psychologist': registered && registered.providertype === 'Social' ? 'Psychologist': registered && registered.providertype === 'Nurse' ? 'Psychologist': registered && registered.providertype))
  const aoi_program = useSelector(state  => state.reference.AreasOfInterest).filter(aoi => aoi.program !== null && aoi.providertype === (registered && registered.providertype ===  'Provisional' ? 'Psychologist' : registered && registered.providertype === 'Clinical' ? 'Psychologist': registered && registered.providertype === 'Occupational' ? 'Psychologist': registered && registered.providertype === 'Social' ? 'Psychologist': registered && registered.providertype === 'Nurse' ? 'Psychologist': registered && registered.providertype))

  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  const aoi_tt = useSelector(state => state.reference.AreasOfInterest).filter(aoi => aoi.providertype === 'TherapyType')
  const aoi_providerinterest = useSelector(state => state.reference.AreasOfInterest).filter(aoi => aoi.providertype === 'ProviderInterest')
  
  // Convert the various selections to their array members
  var ftypes = fundingTypesReference.filter(( {id} ) => psychologist.fundingTypes.includes(id))
  var aoi = aoi_focus.filter(( {issue} ) => psychologist.areasOfInterest.includes(issue))
  var keyAoi = aoi_other.filter(( {issue} ) => psychologist.areasOfInterest.includes(issue))
  var ttAoi = aoi_tt.filter(( {issue} ) => psychologist.areasOfInterest.includes(issue))
  var piAoi = aoi_providerinterest.filter(( {issue} ) => psychologist.areasOfInterest.includes(issue))    
  var mbs = mbsServicesReference.filter(( {id} ) => psychologist.mbsServices.includes(id))

  // And merge those back into the primary record
  psychologist = {...psychologist, 
    hourlyrate: psychologist.hourlyrate.substring(0,1) === '$' ? psychologist.hourlyrate.substring(1) : psychologist.hourlyrate,
    reducedFee: psychologist.reducedFee.substring(0,1) === '$' ? psychologist.reducedFee.substring(1) : psychologist.reducedFee,
    fundingTypes: ftypes,
    areasOfInterest: aoi,
    keyAreas: keyAoi,
    providerInterest: piAoi,
    therapytypes: ttAoi,
    mbsServices: mbs,
  }
  
  const loadAPIKey = (thisPsychologist) => {
    // Load Form
    setShowAPIKey(true);
  }

  const loadCustomBio = (programID) => {
    // Load CustomBIO
    dispatch(Admin.actions.fetchProviderProgramBio({ programID: programID}))
    // Display Modal with BIO 
    setShowCustomBio(true)
  }

  function InsuranceDownload(props) {
    // Check to see if link is populated
    if (!props.documentLink)
    {
      return(null)
    }
    //
    return (
      <Button variant="info" size="sm" href={props.documentLink} >
        <DescriptionIcon fontSize="small" style={{ marginRight: 6, marginTop: -1 }} /> Download
      </Button>
    )
  }

  function submitForm(data){
    //
    const formData = new FormData();
    // Merge AOI and Key AOI into one field 
    let mergedAOI = [];
    if (data['areasOfInterest'].length > 0)
    {
      mergedAOI = [...mergedAOI,...data['areasOfInterest']]; 
    }
    if (data['keyAreas'].length > 0)
    {
      mergedAOI = [...mergedAOI,...data['keyAreas']]; 
    }
    if (data['providerInterest'].length > 0 )
    {
      mergedAOI = [...mergedAOI,...data['providerInterest']];
    }
    if (data['therapytypes'].length > 0 )
    {
      mergedAOI = [...mergedAOI,...data['therapytypes']];
    }

    formData.append('psychologistID',psychologist.id);
    formData.append('firstname',data['firstname'])
    formData.append('surname',data['surname'])
    formData.append('gender',data['gender'])
    formData.append('email',data['email'])
    formData.append('education',data['education'])
    formData.append('mobilephone',data['mobilephone'])
    formData.append('bio',data['bio'])
    formData.append('practicename',data['practicename'])
    formData.append('abn',data['abn'])
    formData.append('fulladdress',data['fulladdress'])
    formData.append('providernumber',data['providernumber'])
    formData.append('insurancenumber',data['insurancenumber'])
    formData.append('newInsuranceFile',data['newInsuranceFile'])
    formData.append('areasOfInterest',mergedAOI.map(aoi => aoi.issue));
    formData.append('fundingTypes',data['fundingTypes'].map(ft => ft.id));
    formData.append('mbsServices',data['mbsServices'].map(mbs => mbs.id));
    formData.append('pdfpassword',data['pdfpassword'])
    formData.append('timeZone',data['timeZone'])
    formData.append('imageURL',data['imageURL'])
    formData.append('waitingRoomURL',data['waitingRoomURL'])
    formData.append('startedpracticingyear',data['startedpracticingyear']);
    formData.append('providertype',data['providertype'])
    formData.append('newProfilePicture', currentprofilepicture)
    formData.append('ahpraRegistrationNo',data['ahpraRegistrationNo']);
    formData.append('coviuRoomURL',data['coviuRoomURL']);
    formData.append('teleHealthProvider',data['teleHealthProvider']);
    formData.append('autoBulkBillingConsentForm',data['autoBulkBillingConsentForm'])
    // Handle Supervisor Fields 
    formData.append('isSupervisor',data['isSupervisor'])
    formData.append('supervisorBio',data['supervisorBio'])
    formData.append('supervisorEducation',data['supervisorEducation'])
    formData.append('supervisorHourlyRate',data['supervisorHourlyRate'])
    formData.append('supervisorAreas',data['supervisorAreas'])
    // Handle Payment 
    formData.append('hourlyrate',data['hourlyrate'])
    formData.append('reducedFee',data['reducedFee'])
    formData.append('facetoFaceBooking',data['facetoFaceBooking'])
    // formData.append('hourlyRateOverrideGap',data['hourlyRateOverrideGap'])
    // formData.append('hourlyRateOverrideRebate',data['hourlyRateOverrideRebate'])
    // formData.append('reducedFeeOverrideGap',data['reducedFeeOverrideGap'])
    // formData.append('reducedFeeOverrideRebate',data['reducedFeeOverrideRebate'])
    if (data['isReducedFee'])
    {
      formData.append('isReducedFee','Y');
    }
    else
    {
      formData.append('isReducedFee','N');
    }
    // Medical Practice
    formData.append('medicalPracticeID',psychologist.medicalPracticeID);
    const initMedicalPractice = { 
      id: psychologist.medicalPracticeID, 
      abn: data['abn'], 
      practicename: data['practicename'], 
      phone: psychologist.phone, 
      address1: data['fulladdress'], 
      latitude: data['latitude'],
      longitude: data['longitude'],
      postcode: data['postcode'],
      email: data['email'], 
      website: '', 
      logoURL: '', 
      practiceType: 'Psychologist'
    };
    formData.append('medicalPractice', JSON.stringify(initMedicalPractice))
    
    dispatch(Admin.actions.putPsychologistDetail({formData}));
    //
    setInsuranceFileClear(1)

  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"First Name too Short")
    .max(255,"First Name too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Surname too Short")
    .max(255,"Surname too Long")
    .required("Surname is required"),
    mobilephone:  Yup.string()
    .min(2,"Mobile Phone NumberToo Short")
    .max(12,"Mobile Phone NumberToo Long")
    .required("Mobile Phone Number is required"),
    gender:  Yup.string()
    .min(2,"Gender too Short")
    .max(20,"Gender too Long")
    .required("Gender is required"),
    email: Yup.string()
    .min(2,"Email Address too Short")
    .max(200,"Email Address too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    bio: Yup.string()
    .max(3900,"Bio too Long"),
    education: Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Education too Short")
                          .max(100,"Education too Long")
                          .required("Education is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Education too Short")
                          .max(100,"Education too Long")
                          .required("Education is required")
      }),
    practicename: Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.string().min(2,"Practice Name too Short")
                          .max(200,"Practice Name too Long")
                          .required("Practice Name is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Financial',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Social',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().min(2,"Organisation too Short")
                          .max(200,"Organisation too Long")
                          .required("Organisation is required")
      }),
    fulladdress:  Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Address too Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Address too Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.string().min(2,"Address too Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Financial',
        then: Yup.string().min(2,"AddressToo Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Nurse',
        then: Yup.string().min(2,"AddressToo Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Social',
        then: Yup.string().min(2,"AddressToo Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"AddressToo Short")
                          .max(100,"Address too Long")
                          .required("Address is required"),
      }),
    abn:   Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'GP',
      then: Yup.string().min(8,"ABN too Short")
                        .max(20,"ABN too Long")
                        .required("ABN is required"),
    })
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Financial',
      then: Yup.string().min(8,"ABN too Short")
                        .max(20,"ABN too Long")
                        .required("ABN is required"),
    }),
    providernumber: Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(2,"Provider Number too Short")
                        .max(255,"Provider Number too Long")
                        .required("Provider Number is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.string().min(2,"Provider Number too Short")
                        .max(255,"Provider Number too Long")
                        .required("Provider Number is required"),
    }),
    insurancenumber: Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(8,"Insurance Number too Short")
                        .max(20,"Insurance Number too Long")
                        .required("Insurance Number is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.string().min(8,"Insurance Number too Short")
                        .max(20,"Insurance Number too Long")
                        .required("Insurance Number is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'GP',
      then: Yup.string().min(8,"Insurance Number too Short")
                        .max(20,"Insurance Number too Long")
                        .required("Insurance Number is required"),
    }),
    startedpracticingyear: Yup.number()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.number().max(2027)
                        .min(1960)
                        .required('Year First Practiced is required'),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.number().max(2027)
                        .min(1960)
                        .required('Year First Practiced is required'),
    }),
    psychologistAreasOfInterest:  Yup.array()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Provisional',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Financial',
      then: Yup.array().min(1, "Please select at least one area of interest"),
    }),    
    hourlyrate: Yup.number()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.number().max(1000,"Full Fee Hourly rate is too High")
                          .required("Hourly rate is required"),
        })
        .when(['providertype'], 
        {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.number().max(1000,"Full Fee Hourly rate is too High")
                          .required("Full Fee Hourly rate is required"),
      }),
    reducedFee: Yup.number()
    .when(['providertype'], 
    {
      is: (providertype) => (providertype === 'Psychologist'),
      then: Yup.number().max(1000,"Reduced Fee Hourly rate is too High")
                        .min(89,"Reduced Fee Hourly rate is too Low")
                        .required("Reduced Fee Hourly rate is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => (providertype === 'Clinical'),
      then: Yup.number().max(1000,"Reduced Fee Hourly rate is too High")
                        .min(89,"Reduced Fee Hourly rate is too Low")
                        .required("Reduced Fee Hourly rate is required"),
    }),
    supervisorEducation: Yup.string().nullable(true)
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Psychologist' && isSupervisor === 'Y'),
        then: Yup.string().max(100,"Supervisor Education is too Long")
                          .nullable(true)
      })
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Clinical' && isSupervisor === 'Y'),
        then: Yup.string().max(100,"Supervisor Education is too Long")
                          .nullable(true)
      }),
      supervisorHourlyRate:  Yup.number().nullable(true)
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Psychologist' && isSupervisor === 'Y'),
        then: Yup.number().max(1000,"Supervior Fee Hourly rate is too High")
                          .min(89,"Supervior Fee Hourly rate is too Low")
                          .nullable(true)
      })
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Clinical' && isSupervisor === 'Y'),
        then: Yup.number().max(1000,"Supervior Fee Hourly rate is too High")
                          .min(89,"Supervior Fee Hourly rate is too Low")
                          .nullable(true)
      }),
    supervisorBio: Yup.string().nullable(true)
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Psychologist' && isSupervisor === 'Y'),
        then: Yup.string().max(3900,"Supervisor Bio is too Long")
                          .nullable(true)
      })
      .when(['providertype','isSupervisor'], 
      {
        is: (providertype,isSupervisor) => (providertype === 'Clinical' && isSupervisor === 'Y'),
        then: Yup.string().max(3900,"Supervisor Bio is too Long")
      }),
    coviuRoomURL: Yup.string().nullable(true)
      .when(['teleHealthProvider'],
      {
        is: (teleHealthProvider) => (teleHealthProvider === 'COVIU'),
        then: Yup.string().max(400,'COVIU URL is too long')  
      }),
    waitingRoomURL: Yup.string().nullable(true)
    .when(['teleHealthProvider'],
    {
      is: (teleHealthProvider) => (teleHealthProvider === 'Alternate Meeting'),
      then: Yup.string().max(400,'Alternate Meeting URL is too long')  
    }),
  });

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
    })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={psychologist}
        validationSchema = {validationSchema}
        onSubmit={(values, actions) => {
          submitForm(values);
          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting } = props;
          const change = (name,e) => { e.persist();  handleChange(e); }; 
          // function to create image preview by reading file into a URL object                
          const makePreview = (file) => {
            if(!file) {
              setPreviewFile('');
              return;
            }
            setcurrentprofilepicture(file);                                           
            fileToDataUri(file)
              .then(newFile => {
                setPreviewFile(newFile)
            })                      
          }                  
          return (
            <form noValidate={true} autoComplete="off" onSubmit={!isSubmitting ? handleSubmit : undefined} >

            <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
              <Row>
                <Col md="12">
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid item md={6} xs={12}>
                      <h2>{psychologist.providerdescription} Profile</h2>
                    </Grid>
                    <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                      * indicates required fields
                    </Grid>
                  </Grid>
                </Col>
              </Row>
              <Row>
                <Col md="12">                  
                  {values.message ? (
                    <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                      {values.message}
                    </Alert>) 
                    : null
                  }
                </Col>
              </Row>
              <hr style={{ marginTop: 4 }}></hr>
              <Row>
                <Col md="2">
                  <Form.Group >
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group >
                    <Form.Label>Surname *</Form.Label>
                    <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                    <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group >
                    <Form.Label>Phone Number *</Form.Label>
                    <Form.Control type="text" name="mobilephone" value={values.mobilephone} onChange={change.bind(null, "mobilephone")} isInvalid={touched.mobilephone && !!errors.mobilephone} isValid={touched.mobilephone && !errors.mobilephone} autoComplete='new-password' />
                    <Form.Control.Feedback type="invalid">{errors.mobilephone}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group style={{width: "100%"}}>
                    <Form.Label>TimeZone</Form.Label>
                    <Autocomplete
                      id="profileTimeZone"
                      variant="outlined"
                      options={timeZone}
                      getOptionLabel={(option) => option.name  || ''}
                      value={timeZone.find(v => v.systemName === values.timeZone) || ''}
                      onChange={(event, newValue) => setFieldValue("timeZone", newValue.systemName)}
                      style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          type="text"
                          variant="outlined"
                          margin="dense"
                          name="TimeZoneSelector"
                          style={{backgroundColor: "white", margin: 0, padding: 0}}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'}}
                        />}
                    />
                  </Form.Group>
                </Col>
                <Col md="2">
                  <Form.Group >
                    <Form.Label>Gender</Form.Label>
                    <Form.Control as="select" name="gender" value={values.gender} onChange={change.bind(null, "gender")} >
                      <option value="Prefer not to say">Prefer not to say</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group >
                        <Form.Label>Email *</Form.Label>
                        <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical') ?
                      <>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Education</Form.Label>
                            <Form.Control type="text" name="education" value={values.education} onChange={change.bind(null, "education")} isInvalid={touched.education && !!errors.education} isValid={touched.education && !errors.education} autoComplete='new-password' />
                            <Form.Control.Feedback type="invalid">{errors.education}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>  
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>Year First Practiced</Form.Label>
                            <Form.Control type="text" name="startedpracticingyear" value={values.startedpracticingyear} onChange={change.bind(null, "startedpracticingyear")} isInvalid={touched.startedpracticingyear && !!errors.startedpracticingyear} isValid={touched.startedpracticingyear && !errors.startedpracticingyear} autoComplete='new-password' />
                            <Form.Control.Feedback type="invalid">{errors.startedpracticingyear}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>                  
                      </>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Bio </Form.Label>
                        <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                            value={values.bio}
                            onEditorChange={(newValue, editor)=>setFieldValue("bio", newValue)}
                            init={{ branding: false, statusbar: false, height: 500 }}
                        />
                        {/* <Form.Control as="textarea" rows={4} type="text" name="bio" value={values.bio}  onChange={change.bind(null, "bio")} isInvalid={touched.bio && !!errors.bio} isValid={touched.bio && !errors.bio} autoComplete='new-password' /> */}
                        <Form.Control.Feedback type="invalid">{errors.bio}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical') ?                        
                    <>
                      <h4>Supervision Details</h4><Form.Label onClick={() => registrationView(values,'Supervision')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Supervision)</Form.Label>
                      <hr></hr>
                        <Row>
                        <Col md="10">
                          <Form.Group>
                            <Form.Label style={{ fontWeight: 500 }}>Do you offer Supervision sessions to Other Providers? </Form.Label>
                            <Form.Check id="supervsior-nosupervision" name="isSupervisor" type="radio" style={{ marginBottom: 8 }} >
                            <Form.Check.Input name="isSupervisor" type="radio" value='N' checked={values.isSupervisor === 'N' ? true : false} onChange={change.bind(null, "isSupervisor")} />
                              <Form.Check.Label style={{marginLeft: 12 }}>No</Form.Check.Label>
                            </Form.Check>
                            <Form.Check id="supervisor-supervision" name="isSupervisor" type="radio" style={{ marginBottom: 8 }} >
                              <Form.Check.Input name="isSupervisor" type="radio" value='Y' checked={values.isSupervisor === 'Y' ? true : false} onChange={change.bind(null, "isSupervisor")} />
                              <Form.Check.Label style={{marginLeft: 12 }}>​Yes</Form.Check.Label>
                            </Form.Check>
                            {values.isSupervisor === 'Y' ?
                              <>
                                <Row>
                                  <Col md="12">
                                    <Form.Group >
                                      <Form.Label>Supervisor Bio (if blank standard Bio will be displayed)</Form.Label>
                                      <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                                        value={values.supervisorBio}
                                        onEditorChange={(newValue, editor)=>setFieldValue("supervisorBio", newValue)}
                                        init={{ branding: false, statusbar: false, height: 500 }}
                                      />
                                      {/* <Form.Control as="textarea" rows={4} type="text" name="supervisorBio" value={values.supervisorBio}  onChange={change.bind(null, "supervisorBio")} isInvalid={touched.supervisorBio && !!errors.supervisorBio} isValid={touched.supervisorBio && !errors.supervisorBio} autoComplete='new-password' /> */}
                                      <Form.Control.Feedback type="invalid">{errors.supervisorBio}</Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="8">
                                    <Form.Group >
                                      <Form.Label>Supervisor Education (if blank standard Education will be displayed)</Form.Label>
                                      <Form.Control type="text" name="supervisorEducation" value={values.supervisorEducation} onChange={change.bind(null, "supervisorEducation")}  isInvalid={touched.supervisorEducation && !!errors.supervisorEducation} isValid={touched.supervisorEducation && !errors.supervisorEducation} autoComplete='new-password' />
                                      <Form.Control.Feedback type="invalid">{errors.supervisorEducation}</Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col md="4">
                                    <Form.Group>
                                    <Form.Label >Supervisor Rate (if blank Full Fee Rate will be used)</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>$</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="text" name="supervisorHourlyRate" value={values.supervisorHourlyRate} onChange={change.bind(null, "supervisorHourlyRate")}  autoComplete='new-password' isInvalid={touched.supervisorHourlyRate && !!errors.supervisorHourlyRate} isValid={touched.supervisorHourlyRate && !errors.supervisorHourlyRate}/>
                                        <Form.Control.Feedback type="invalid">{errors.supervisorHourlyRate}</Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </Row>  
                                <Row>
                                  <Col md="12">
                                    <Form.Group >
                                      <Form.Label>Available For</Form.Label>
                                      <Row>
                                        <Col md="6">
                                          <List className={classes.root}>
                                            {supervisionAreas.map(row => {
                                              if(row.index <= supervisionAreas.length/2)
                                              {
                                                const labelId = `checkbox-list-label-${row.id}`;
                                                var isChecked = values.supervisorAreas.includes(row.id);
                                                return (
                                                  <ListItem key={`S${row.id}`} role={undefined} dense button 
                                                    onClick={
                                                      e => {
                                                        const idx = values.supervisorAreas.indexOf(row.id);
                                                        if (idx === -1) 
                                                        {
                                                          var currentValue1 = values.supervisorAreas;
                                                          currentValue1.push(row.id);
                                                          setFieldValue("supervisorAreas", currentValue1);
                                                        } 
                                                        else 
                                                        {
                                                          var currentValue2 = values.supervisorAreas;
                                                          currentValue2.splice(idx, 1);
                                                          setFieldValue("supervisorAreas", currentValue2);
                                                        }
                                                      }}
                                                     >
                                                    <ListItemIcon style={{ minWidth: 40 }}>
                                                      <Checkbox
                                                        edge="start"
                                                        checked={isChecked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                        disableTypography 
                                                        primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                        className={classes.link} />
                                                  </ListItem>
                                                );
                                              }
                                              else
                                              {
                                                return null
                                              }
                                            })
                                            }
                                          </List>
                                        </Col>
                                        <Col md="6">
                                          <List className={classes.root}>
                                            {supervisionAreas.map(row => {
                                              if(row.index > supervisionAreas.length/2)
                                              {
                                                const labelId = `checkbox-list-label-${row.id}`;
                                                var isChecked = values.supervisorAreas.includes(row.id);
                                                return (
                                                  <ListItem key={`S${row.id}`} role={undefined} dense button onClick={
                                                    e => {
                                                      const idx = values.supervisorAreas.indexOf(row.id);
                                                      if (idx === -1) 
                                                      {
                                                        var currentValue3 = values.supervisorAreas;
                                                        currentValue3.push(row.id);
                                                        setFieldValue("supervisorAreas", currentValue3);
                                                      } 
                                                      else 
                                                      {
                                                        var currentValue4 = values.supervisorAreas;
                                                        currentValue4.splice(idx, 1);
                                                        setFieldValue("supervisorAreas", currentValue4);
                                                      }
                                                    }}
                                                  >
                                                    <ListItemIcon style={{ minWidth: 40 }}>
                                                      <Checkbox
                                                        edge="start"
                                                        checked={isChecked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                        disableTypography 
                                                        primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                        className={classes.link} />
                                                  </ListItem>
                                                );
                                              }
                                              else
                                              {
                                                return null
                                              }
                                            })
                                            }
                                          </List>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="12">
                                    <Form.Group >
                                      <Form.Label>Endorsed In</Form.Label>
                                      <Row>
                                        <Col md="6">
                                          <List className={classes.root}>
                                            {endorsedSupervisionAreas.map(row => {
                                              if(row.index <= endorsedSupervisionAreas.length/2)
                                              {
                                                const labelId = `checkbox-list-label-${row.id}`;
                                                var isChecked = values.supervisorAreas.includes(row.id);
                                                return (
                                                  <ListItem key={`S${row.id}`} role={undefined} dense button 
                                                    onClick={
                                                      e => {
                                                        const idx = values.supervisorAreas.indexOf(row.id);
                                                        if (idx === -1) 
                                                        {
                                                          var currentValue1 = values.supervisorAreas;
                                                          currentValue1.push(row.id);
                                                          setFieldValue("supervisorAreas", currentValue1);
                                                        } 
                                                        else 
                                                        {
                                                          var currentValue2 = values.supervisorAreas;
                                                          currentValue2.splice(idx, 1);
                                                          setFieldValue("supervisorAreas", currentValue2);
                                                        }
                                                      }}
                                                     >
                                                    <ListItemIcon style={{ minWidth: 40 }}>
                                                      <Checkbox
                                                        edge="start"
                                                        checked={isChecked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                        disableTypography 
                                                        primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                        className={classes.link} />
                                                  </ListItem>
                                                );
                                              }
                                              else
                                              {
                                                return null
                                              }
                                            })
                                            }
                                          </List>
                                        </Col>
                                        <Col md="6">
                                          <List className={classes.root}>
                                            {endorsedSupervisionAreas.map(row => {
                                              if(row.index > endorsedSupervisionAreas.length/2)
                                              {
                                                const labelId = `checkbox-list-label-${row.id}`;
                                                var isChecked = values.supervisorAreas.includes(row.id);
                                                return (
                                                  <ListItem key={`S${row.id}`} role={undefined} dense button onClick={
                                                    e => {
                                                      const idx = values.supervisorAreas.indexOf(row.id);
                                                      if (idx === -1) 
                                                      {
                                                        var currentValue3 = values.supervisorAreas;
                                                        currentValue3.push(row.id);
                                                        setFieldValue("supervisorAreas", currentValue3);
                                                      } 
                                                      else 
                                                      {
                                                        var currentValue4 = values.supervisorAreas;
                                                        currentValue4.splice(idx, 1);
                                                        setFieldValue("supervisorAreas", currentValue4);
                                                      }
                                                    }}
                                                  >
                                                    <ListItemIcon style={{ minWidth: 40 }}>
                                                      <Checkbox
                                                        edge="start"
                                                        checked={isChecked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                      />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                                        disableTypography 
                                                        primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{row.issue}</Typography>} 
                                                        className={classes.link} />
                                                  </ListItem>
                                                );
                                              }
                                              else
                                              {
                                                return null
                                              }
                                            })
                                            }
                                          </List>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                </Row>

                              </>
                            :
                              null
                            }
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {psychologist && (psychologist.providertype !== 'Provisional') ?                                                
                    <>                  
                      {psychologist && (psychologist.providertype === 'Financial' ) ?                                                
                        <h4>Organisation Details</h4>
                      :
                        <h4>Practice Details</h4>
                      }
                      <hr></hr>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            {psychologist && (psychologist.providertype === 'Financial' ) ?                                                
                              <Form.Label>Organisation Name</Form.Label>
                            :
                              <Form.Label>Practice Name</Form.Label>
                            }
                          <Form.Control type="text" name="practicename" value={values.practicename} onChange={change.bind(null, "practivename")}  autoComplete='new-password' isInvalid={touched.practicename && !!errors.practicename} isValid={touched.practicename && !errors.practicename}/>
                            <Form.Control.Feedback type="invalid">{errors.practicename}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>ABN</Form.Label>
                            <Form.Control type="text" name="abn" value={values.abn} onChange={change.bind(null, "abn")}  autoComplete='new-password' isInvalid={touched.abn && !!errors.abn} isValid={touched.abn && !errors.abn}/>
                            <Form.Control.Feedback type="invalid">{errors.abn}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group style={{width: "100%"}}>
                            <Form.Label>Practice Address or Town (start typing to search) *</Form.Label>
                            <PlacesAutoComplete 
                              currentID={psychologist.id}
                              currentfulladdress={psychologist.fulladdress}
                              setcurrentfulladdress = {(value) => setFieldValue("fulladdress", value)}
                              setcurrentLatitude = {(value) => setFieldValue("latitude", value)} 
                              setcurrentLongitude = {(value) => setFieldValue("longitude", value)}
                              setcurrentPostCode = {(value) => setFieldValue("postcode", value)}
                              isInvalid={touched.fulladdress && !!errors.fulladdress} 
                              isValid={touched.fulladdress && !errors.fulladdress} 
                            />
                            {touched.fulladdress && !!errors.fulladdress ? (<p className='error-help'>{errors.fulladdress}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  <h4>Professional Details</h4>
                  <hr></hr>
                  {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical' || psychologist.providertype === 'GP' || psychologist.providertype === 'Provisional') ?
                    <>
                      <Row>
                        {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical' || psychologist.providertype === 'Provisional') ?                        
                          <>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>AHPRA Registration Number</Form.Label>
                                <Form.Control type="text" name="ahpraRegistrationNo" value={values.ahpraRegistrationNo} onChange={change.bind(null, "ahpraRegistrationNo")}  autoComplete='new-password' isInvalid={touched.ahpraRegistrationNo && !!errors.ahpraRegistrationNo} isValid={touched.ahpraRegistrationNo && !errors.ahpraRegistrationNo}/>
                                <Form.Control.Feedback type="invalid">{errors.ahpraRegistrationNo}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical') ?                        
                          <>
                          <Col md="2">
                            <Form.Group >
                              <Form.Label>Provider Number *</Form.Label>
                              <Form.Control type="text" name="providernumber" value={values.providernumber} onChange={change.bind(null, "providernumber")}  autoComplete='new-password' isInvalid={touched.providernumber && !!errors.providernumber} isValid={touched.providernumber && !errors.providernumber}/>
                              <Form.Control.Feedback type="invalid">{errors.providernumber}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </>
                        :
                          null
                        }
                        {psychologist &&  psychologist.providertype !== 'Provisional' ?                        
                          <>
                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Insurance Number *</Form.Label>
                                <Form.Control type="text" name="insurancenumber" value={values.insurancenumber} onChange={change.bind(null, "insurancenumber")}  autoComplete='new-password' isInvalid={touched.insurancenumber && !!errors.insurancenumber} isValid={touched.insurancenumber && !errors.insurancenumber}/>
                                <Form.Control.Feedback type="invalid">{errors.insurancenumber}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologist &&  psychologist.providertype !== 'Provisional' ?                        
                          <>
                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Create Consent Forms on Bulk Billing Booking*</Form.Label>
                                <Form.Control as="select" name="autoBulkBillingConsentForm" value={values.autoBulkBillingConsentForm} onChange={change.bind(null, "autoBulkBillingConsentForm")} >
                                  <option value="N">No</option>
                                  <option value="Y">Yes</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologist &&  psychologist.providertype !== 'Provisional' ?                        
                          <>
                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Insurance Document:</Form.Label>
                                <div style={{ width: "100%" }}>
                                  <InsuranceDownload documentLink={values.documentLink} />
                                </div>
                              </Form.Group>
                            </Col>

                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Upload New:</Form.Label>
                                <Form.Control type="file" key={insuranceFileClear} name="newInsuranceFile" onChange={(event) => setFieldValue('newInsuranceFile', event.currentTarget.files[0])} isInvalid={touched.newInsuranceFile && !!errors.newInsuranceFile} isValid={touched.newInsuranceFile && !errors.newInsuranceFile} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                                <Form.Control.Feedback type="invalid">{errors.newInsuranceFile}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                      </Row>
                      {values.specialisttype === 'nthqldphn' ?
                        <Row>
                          <Col md="4">
                            <Form.Group>
                              <Form.Label>Offer Face to Face Booking (when face to face availability is set)</Form.Label>
                              <Form.Control as="select" name="facetoFaceBooking" value={values.facetoFaceBooking} onChange={change.bind(null, "facetoFaceBooking")} >
                                <option value="N">No</option>
                                <option value="Y">Yes</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      :
                        null
                      }
                    </>
                  :
                    null
                  }
                  {psychologist && (psychologist.providertype !== 'GP'  ) ?                        
                    <>
                      <Row>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Areas of Interest  (Select all that apply) *</Form.Label>
                            <Autocomplete
                              id="areasOfInterest"
                              variant="outlined"
                              multiple
                              options={aoi_focus}
                              getOptionLabel={(option) => option.issue  || ''}
                              value={values.areasOfInterest}
                              // onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                              onChange={(e, newValue) => {
                                setFieldValue("areasOfInterest", newValue);
                              }}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="AOISelector"
                                  style={{backgroundColor: "white", margin: 0, padding: 0}}
                                  error={touched.areasOfInterest && !!errors.areasOfInterest} 
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                            {touched.areasOfInterest && !!errors.areasOfInterest ? (<p className='error-help'>{errors.areasOfInterest}</p>) : ''}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Key Areas (Select all that apply) *</Form.Label>
                            <Autocomplete
                              id="keyAreas"
                              variant="outlined"
                              multiple
                              options={aoi_other}
                              getOptionLabel={(option) => option.issue || ''}
                              value={values.keyAreas}
                              // onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                              onChange={(e, newValue) => {
                                setFieldValue("keyAreas", newValue);
                              }}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="KeyAOISelector"
                                  style={{backgroundColor: "white", margin: 0, padding: 0}}
                                  error={touched.keyAreas && !!errors.keyAreas} 
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                            {touched.keyAreas && !!errors.keyAreas ? (<p className='error-help'>{errors.keyAreas}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Therapy Types (Select all that apply) *</Form.Label>
                            <Autocomplete
                              id="therapytypes"
                              variant="outlined"
                              multiple
                              options={aoi_tt}
                              getOptionLabel={(option) => option.issue  || ''}
                              value={values.therapytypes}
                              // onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                              onChange={(e, newValue) => {
                                setFieldValue("therapytypes", newValue);
                              }}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="TherapyTypeAOISelector"
                                  style={{backgroundColor: "white", margin: 0, padding: 0}}
                                  error={touched.therapytypes && !!errors.therapytypes} 
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                            {touched.therapytypes && !!errors.therapytypes ? (<p className='error-help'>{errors.therapytypes}</p>) : ''}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Provider Interests (Select all that apply) *</Form.Label>
                            <Autocomplete
                              id="keyAreas"
                              variant="outlined"
                              multiple
                              options={aoi_providerinterest}
                              getOptionLabel={(option) => option.issue  || ''}
                              value={values.providerInterest}
                              // onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                              onChange={(e, newValue) => {
                                setFieldValue("providerInterest", newValue);
                              }}
                              style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "", }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  type="text"
                                  variant="outlined"
                                  margin="dense"
                                  name="ProviderInterestAOISelector"
                                  style={{backgroundColor: "white", margin: 0, padding: 0}}
                                  error={touched.keyAreas && !!errors.keyAreas} 
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'}}
                                />}
                            />
                            {touched.keyAreas && !!errors.keyAreas ? (<p className='error-help'>{errors.keyAreas}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>



                    </>
                  :
                    null
                  }
                  {aoi_program && aoi_program.length > 0 ?
                    <>
                      <h4>Open Programs</h4>
                      <hr></hr>
                      <Row style={{ paddingBottom: 40}}>
                        <List className={classes.root} >
                          {aoi_program.map(program => {
                            return (
                              <ListItem key={program.id}  dense button style={{display:'flex', minWidth:'100%'}}> 
                                <ListItemIcon style={{ minWidth: 60 }}>
                                  <StarIcon/>
                                </ListItemIcon>
                                <ListItemText id={program.id} style={{ fontSize: 12, paddingRight: 160 }} 
                                  disableTypography 
                                  primary={<Typography>{program.programName} (Area of Interest: {program.issue})</Typography>} 
                                  className={classes.link} />
                                <ListItemSecondaryAction >
                                  <Button 
                                    variant="primary" 
                                    size="sm" 
                                    style={{ marginLeft: 5, }} 
                                    onClick={() => loadCustomBio(program.program)}
                                  >
                                    Add/Update Custom Bio
                                  </Button> 
                                </ListItemSecondaryAction>
                              </ListItem>
                              );
                          })}
                        </List>
                      </Row>
                    </>
                  : 
                    null
                  }
                  {psychologist && (psychologist.providertype === 'Psychologist' 
                  || psychologist.providertype === 'Clinical'
                  || psychologist.providertype === 'Social'
                  || psychologist.providertype === 'Nurse'
                  || psychologist.providertype ==='Occupational') ?                        
                    <>
                      <h4>Client Billing Settings </h4>
                      <Form.Label onClick={() => registrationView(values,'Full Fee')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Full Fee)</Form.Label><Form.Label onClick={() => registrationView(values,'Reduced Gap')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Reduced Fee)</Form.Label>
                      <hr></hr>
                      <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                          <p>Please use the review button above to check client view.</p>
                      </Alert>
                      <Row>
                        <Col md="2">
                          <Form.Group>
                            <Form.Label >Full Fee Rate (Total)*</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" name="hourlyrate" value={values.hourlyrate} onChange={change.bind(null, "hourlyrate")}  autoComplete='new-password' isInvalid={touched.hourlyrate && !!errors.hourlyrate} isValid={touched.hourlyrate && !errors.hourlyrate}/>
                                <Form.Control.Feedback type="invalid">{errors.hourlyrate}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        {/* <Col md="2">
                          <Form.Group >
                            <Form.Label>Full Fee Gap (Override)</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="hourlyRateOverrideGap" value={values.hourlyRateOverrideGap} onChange={change.bind(null, "hourlyRateOverrideGap")}  autoComplete='new-password' isInvalid={touched.hourlyRateOverrideGap && !!errors.hourlyRateOverrideGap} isValid={touched.reducedFeeOverrideGap && !errors.reducedFeeOverrideGap}/>
                              <Form.Control.Feedback type="invalid">{errors.hourlyRateOverrideGap}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>Full Fee Rebate (Override)</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="hourlyRateOverrideRebate" value={values.hourlyRateOverrideRebate} onChange={change.bind(null, "hourlyRateOverrideRebate")}  autoComplete='new-password' isInvalid={touched.hourlyRateOverrideRebate && !!errors.hourlyRateOverrideRebate} isValid={touched.hourlyRateOverrideRebate && !errors.hourlyRateOverrideRebate}/>
                              <Form.Control.Feedback type="invalid">{errors.hourlyRateOverrideRebate}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>Reduced Fee Rate (Total)*</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="reducedFee" value={values.reducedFee} onChange={change.bind(null, "reducedFee")}  autoComplete='new-password' isInvalid={touched.reducedFee && !!errors.reducedFee} isValid={touched.reducedFee && !errors.reducedFee}/>
                              <Form.Control.Feedback type="invalid">{errors.reducedFee}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        {/* <Col md="2">
                          <Form.Group >
                            <Form.Label>Reduced Fee Gap (Override)</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="reducedFeeOverrideGap" value={values.reducedFeeOverrideGap} onChange={change.bind(null, "reducedFeeOverrideGap")}  autoComplete='new-password' isInvalid={touched.reducedFeeOverrideGap && !!errors.reducedFeeOverrideGap} isValid={touched.reducedFeeOverrideGap && !errors.reducedFeeOverrideGap}/>
                              <Form.Control.Feedback type="invalid">{errors.reducedFeeOverrideGap}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>Reduced Fee Rebate (Override)</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="reducedFeeOverrideRebate" value={values.reducedFeeOverrideRebate} onChange={change.bind(null, "reducedFeeOverrideRebate")}  autoComplete='new-password' isInvalid={touched.reducedFeeOverrideRebate && !!errors.reducedFeeOverrideRebate} isValid={touched.reducedFeeOverrideRebate && !errors.reducedFeeOverrideRebate}/>
                              <Form.Control.Feedback type="invalid">{errors.reducedFeeOverrideRebate}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col> */}
                      </Row>
                      <Row>
                        <Form.Label style={{ fontWeight:300, marginLeft:10 }}>*Set your Reduced Fee to 0 for client bulk billing as reduced fee option</Form.Label>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Label style={{ marginTop:20 }}>Check the box below if you want this text to appear alongside your fees</Form.Label>
                          <Form.Group>
                            <Checkbox aria-label='Offer Client Pricing Flexibility' name='isReducedFee' value={values.isReducedFee === 'Y' ? true : false} onChange={change.bind(null, 'isReducedFee')} style={{ paddingTop: 6, paddingLeft: 0 }} />
                              <span style={{ fontWeight:600 }} >Some flexibility, depending on client circumstances</span>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  <h4>Platform Provider Type</h4>
                  <hr></hr>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Control as="select" name='providertype' value={values.providertype} onChange={change.bind(null, "providertype")}>
                          <option value="Psychologist">Psychologist</option>
                          <option value="Financial">Financial Counsellor</option>
                          <option value="Clinical">Clinical Psychologist</option>
                          <option value="GP">GP</option>
                          <option value="Provisional">Provisional Psychologist</option>
                          <option value="Social">Social Worker</option>
                          <option value="Nurse">Mental Health Nurse</option>
                          <option value="Occupational">Occupational Therapist</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {psychologist && (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Clinical' || psychologist.providertype === 'GP' || psychologist.providertype === 'Occupational') ?
                    <>
                      <h4>Tyro</h4>
                      <hr></hr>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label> API Key</Form.Label>
                            <Form.Control type="text" name="APIKey" value={values.medipassapikey} readOnly />
                          </Form.Group>
                        </Col>
                        <Col md="3" style={{ paddingTop: 29}}>
                            
                          <Button 
                            variant="primary" 
                            size="sm" 
                            style={{ marginLeft: 5, }} 
                            onClick={() => loadAPIKey()}
                          >
                            Update APIKey 
                          </Button>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>TeleConference Provider Selection</Form.Label>
                        <Form.Control as="select" name="teleHealthProvider" value={values.teleHealthProvider} onChange={change.bind(null, "teleHealthProvider")} >
                          <option value="COVIU">COVIU</option>
                          <option value="Alternate Meeting">Other Meeting Room</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="5">
                      <Form.Group >
                        <Form.Label>COVIU URL</Form.Label>
                        <Form.Control type="text"  name="coviuRoomURL" value={values.coviuRoomURL} onChange={change.bind(null, "coviuRoomURL")}  autoComplete='new-password' isInvalid={touched.coviuRoomURL && !!errors.coviuRoomURL} isValid={touched.coviuRoomURL && !errors.coviuRoomURL}/>
                        <Form.Control.Feedback type="invalid">{errors.coviuRoomURL}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Alternate link (Zoom etc)</Form.Label>
                        <Form.Control type="text" name="waitingRoomURL" value={values.waitingRoomURL} onChange={change.bind(null, "waitingRoomURL")} autoComplete='new-password' isInvalid={touched.waitingRoomURL && !!errors.waitingRoomURL} isValid={touched.waitingRoomURL && !errors.waitingRoomURL}/>
                        <Form.Control.Feedback type="invalid">{errors.waitingRoomURL}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4>Login Details (Leave blank unless updating password)</h4>
                  <hr></hr>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                        <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>New Confirm Password</Form.Label>
                        <Form.Control type="password" name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4>Profile Photo</h4>
                  <hr></hr>
                  <Row>
                    <Col md="10">
                      <Image src={previewFile ? previewFile : (values.imageURL ? `https://${storageAccountName}.blob.core.windows.net/${containerName}/${values.imageURL}` : null)} style={{ maxHeight: 400, marginBottom: 30 }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="10">
                      <Form.Group>
                        <Form.Label>Upload New Profile Photo</Form.Label>
                        <Form.Control type="file" name="newProfilePicture" onChange={(event) => makePreview(event.target.files[0])} isInvalid={touched.newProfilePicture && !!errors.newProfilePicture} isValid={touched.newProfilePicture && !errors.newProfilePicture} />
                        <Form.Control.Feedback type="invalid">{errors.newProfilePicture}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <ErrorsNotification errors={errors} touched={touched} />
                  <Row>
                    <Col md="12">
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item md={3} xs={6}>
                          
                        </Grid>
                        <Grid item md={3} xs={6} style={{ textAlign: 'right' }}>
                          {!KTUtil.isEmpty(errors) && <div style={{ color: 'red', padding: 8 }}>There are incomplete fields above!</div>}
                          <Button variant="primary" type="submit" >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Col>
                  </Row>
                </Paper>
            </form>
          )
        }}
      </Formik>


      <Modal show={showAPIKey} onHide={handleAPIKeyClose} centered>
        <Modal.Header closeButton className="apiKey-modal">
          <Modal.Title>Update Tyro APIKey Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="apiKey-modal">
          <APIKeyForm 
            referenceID={psychologist.id}
            onCloseAPIKey={handleAPIKeyClose}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showRegistrationView} onHide={handleRegistrationViewClose} dialogClassName="providerRegistrationView-modal">
        <Modal.Header closeButton className="providerRegistrationView-modal">
          <Modal.Title>Client Registration View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="providerRegistrationView-modal">
          <Grid item md={12} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
              <Masonry
                breakpointCols={1}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                <Card className={classes.root} variant="outlined" key={psychologist.id}>
                  <CardMedia
                      className={classes.media}
                      image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${providerRegistrationView.imageURL}`}
                      title="Psychologist"
                  />
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item sm={12}>
                        <Typography variant="h5" component="h3">
                          {providerRegistrationView.firstname} {providerRegistrationView.surname}  (Next available: Next Available)
                        </Typography>
                      </Grid>
                    </Grid>
                    {(providerRegistrationView.providertype !== 'GP' && providerRegistrationView.providertype !== 'Provisional') ?
                      <>
                          {currentPaymentType === 'Supervision' ?
                          <>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={6} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Out of Pocket Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {providerRegistrationView.supervisorHourlyRate}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                          :
                          null
                        }
                        {currentPaymentType === 'Full Fee' ?
                          <>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={6} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Out of Pocket Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {providerRegistrationView.hourlyRateOverrideGap && providerRegistrationView.hourlyRateOverrideGap !== '0' ? providerRegistrationView.hourlyRateOverrideGap :  providerRegistrationView.standardFeeOutOfPocket }
                                </Typography>
                              </Grid>
                              <Grid item sm={12}>
                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                  You pay ${providerRegistrationView.hourlyrate} on the day 
                                </Typography>
                                <Typography>
                                  Receive back {providerRegistrationView.hourlyRateOverrideRebate && providerRegistrationView.hourlyRateOverrideRebate !== '0'  ? providerRegistrationView.hourlyRateOverrideRebate : providerRegistrationView.medicareRebate} from Medicare
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                          :
                          null
                        }
                        {currentPaymentType === 'Reduced Gap' ?
                          <>
                            {providerRegistrationView.isReducedFee === 'Y' ? 
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {providerRegistrationView.reducedFeeOverrideGap && providerRegistrationView.reducedFeeOverrideGap !== '0' ? providerRegistrationView.reducedFeeOverrideGap : providerRegistrationView.reducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { providerRegistrationView.reducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${providerRegistrationView.reducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {providerRegistrationView.reducedFeeOverrideRebate && providerRegistrationView.reducedFeeOverrideRebate !== '0' ? providerRegistrationView.reducedFeeOverrideRebate : providerRegistrationView.medicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                  { providerRegistrationView.reducedFee !== '0' ?
                                    <Grid item sm={12}>
                                      <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                        Note this provider offers some flexibility, depending on client circumstances
                                      </Typography>
                                    </Grid>
                                  :
                                    null
                                  }
                                </Grid>
                              </>
                            :
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {providerRegistrationView.reducedFeeOverrideGap && providerRegistrationView.reducedFeeOverrideGap !== '0' ? providerRegistrationView.reducedFeeOverrideGap : providerRegistrationView.reducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { providerRegistrationView.reducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${providerRegistrationView.reducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {providerRegistrationView.reducedFeeOverrideRebate && providerRegistrationView.reducedFeeOverrideRebate !== '0' ? providerRegistrationView.reducedFeeOverrideRebate : providerRegistrationView.medicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </>
                            }
                          </>
                        :
                          null
                        } 
                        {currentPaymentType !== 'Supervision' ?
                          <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                            {providerRegistrationView.areasOfInterest.map((row) => {return (<Chip size="small" key={`AOI${row.id}`} variant="outlined" label={row.issue} style={{ margin: 2 }} />)})}
                          </Grid>
                        :
                          null
                        }
                      </>
                    :
                      null
                    }
                    <Typography className={classes.pos} color="textSecondary">
                      <br />
                    </Typography>
                    <Grid container>
                      {currentPaymentType === 'Supervision' ?
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={providerRegistrationView.supervisorBio.substring(0,700)} />
                            {providerRegistrationView.supervisorBio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(providerRegistrationView.fullname, providerRegistrationView.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      :
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={providerRegistrationView.bio.substring(0,700)} />
                            {providerRegistrationView.bio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(providerRegistrationView.fullname, providerRegistrationView.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      }
                    </Grid>
                    {currentPaymentType === 'Supervision' ?
                      <>
                        <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                          Available For
                        </Typography>
                        <Row>
                          <Col md="6">
                            <List className={classes.root}>
                              {supervisionAreas.map(sRow => {
                                if(sRow.index <= supervisionAreas.length/2)
                                {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = providerRegistrationView.supervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                          <Col md="6">
                            <List className={classes.root}>
                              {supervisionAreas.map(sRow => {
                                if(sRow.index > supervisionAreas.length/2)
                                {
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  var isChecked = providerRegistrationView.supervisorAreas.includes(sRow.id);
                                  return (
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  );
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                        </Row>
                        <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                          Endorsed In 
                        </Typography>
                        <Row>
                          <Col md="6">
                            <List className={classes.root}>
                              {endorsedSupervisionAreas.map(sRow => {
                                var isChecked = providerRegistrationView.supervisorAreas.includes(sRow.id);
                                const labelId = `checkbox-list-label-${sRow.id}`;
                                if (isChecked) {
                                  return(
                                    <ListItem key={sRow.id} role={undefined} dense button >
                                      <ListItemIcon style={{ minWidth: 40 }}>
                                        <Checkbox
                                          edge="start"
                                          checked={isChecked}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                          disableTypography 
                                          primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                          className={classes.link} />
                                    </ListItem>
                                  )
                                }
                                else
                                {
                                  return null
                                }
                              })}
                            </List>
                          </Col>
                        </Row>
                      </>
                    :
                      null
                    }  
                  </CardContent>
                </Card>
              </Masonry>
            </Grid>

        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleRegistrationViewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCustomBio} onHide={handleCustomBioClose} centered>
        <Modal.Header closeButton className="apiKey-modal">
          <Modal.Title>Enter Custom Bio </Modal.Title>
        </Modal.Header>
        <Modal.Body className="apiKey-modal">
          <ProviderProgramBio
            providerID={psychologist.id}
            onCloseCustomBio={handleCustomBioClose}
          />
        </Modal.Body>
      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  );
}

