import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
// import { useSchedulerEditItemPropsContext } from "@progress/kendo-react-scheduler";

export const CustomDialog = (props) => {
  
  var title = props.children[0].props.valueGetter('title');
  var eventType = props.children[0].props.valueGetter('eventType');
  var paymentType = props.children[0].props.valueGetter('paymentType');
  var bookingtType = props.children[0].props.valueGetter('bookinhType');
  var appointmentType = props.children[0].props.valueGetter('appointmenttype');
  var contactMobilePhone = props.children[0].props.valueGetter('contactMobilePhone');

  var className = '';

  switch (eventType)
  {
    case 'availability':
      title = 'Availability';
      className = 'availability';
    break;
    case 'availabilityExisting':
      title = 'Availability - Existing';
      className = 'availability';
    break;
    case 'availabilityAny' :
      title = 'Availability - New Client Bookings';
      className = 'availability';
    break;
    case 'availabilityOther' :
      title = 'Availability - Other (Not Available)';
      className = 'availability';
    break;
    case 'availabilitySupervision' :
      title = 'Availability - Supervision';
      className = 'availability';
    break;
    case 'availabilityNDISContractEAP' :
      title = 'Availability - NDIS/Contract/EAP';
      className = 'availability';
    break;
    case 'availabilityNDISContractEAPNew' :  
      title =  'Availability - New Client or NDIS/Contract/EAP';
      className = 'availability';
    break;
    case 'availabilityNDISContractEAPExisting' :   
      title = 'Availability - Existing Client or NDIS/Contract/EAP'
      className = 'availability';
    break;
    case 'availabilityPerinatalGriefLoss' :   
      title = 'Availability - Perinatal Grief & Loss'
      className = 'availability';
    break;
    case 'availabilityNthQLDPHNNew' :   
      title = 'Availability - Nth QLD PHN New'
      className = 'availability';
    break;
    case 'availabilityNthQLDPHNExisting' :   
      title = 'Availability - Nth QLD PHN Existing'
      className = 'availability';
    break;
    case 'availabilityNthQLDPHNFacetoFace' :   
      title = 'Available - Nth QLD PHN Face to Face Clients'
      className = 'availability';
    break;
    default:
    {
      title = `${title ? `Booking: ${title}` : 'New Booking'}`;
      if(appointmentType && appointmentType === "1" && contactMobilePhone)
      {
        var phoneNumber = contactMobilePhone.slice(0, 4) + " " + contactMobilePhone.slice(4,7) + " " + contactMobilePhone.slice(7,10);
        title = `${title} - ${phoneNumber}`
      }
      className = `${eventType}-${paymentType}`;
    }
  }
  

  return <Dialog {...props} title={`${title}`} id={`rhc-event-${className}`} className="scheduler-dialog" style={{zIndex:1100}}/>;
};


