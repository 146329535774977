/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import { connect } from 'react-redux';
import * as Admin from "../../store/ducks/admin.duck";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

class QuickPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activityfeed: props.activityfeed,
      selectedTab: "Notifications",
      clientID: undefined
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.activityfeed !== prevProps.activityfeed)
    {
      this.setState({ 
        activityfeed: this.props.activityfeed
      });
      // console.log(filteredData);
    }
  }

  componentDidMount() {
    this.props.FETCHACTIVITYFEED();
  }

  setTab = _tabName => {
    this.setState({ selectedTab: _tabName });
  };

  setClientForDisplay = clientID => {
    this.setState({ clientID: clientID });
    this.props.SET_CLIENT_FOR_DISPLAY({ filterName: 'clientsListLoadClientID', filterValue: clientID });
  }

  render() {
    const {
      activityfeed,
    } = this.state;
    

    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
        <a
          href="#"
          className="kt-quick-panel__close"
          id="kt_quick_panel_close_btn"
        >
          <i className="flaticon2-delete" />
        </a>
        <Tab.Container defaultActiveKey={this.state.selectedTab}>
          <div className="kt-quick-panel__nav">
            <Nav
              onSelect={this.setTab}
              className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
            >
              <Nav.Item className="nav-item">
                <Nav.Link eventKey="Notifications" className="nav-link show">
                  Notifications
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="kt-quick-panel__content">
            <Tab.Content>
              <Tab.Pane eventKey="Notifications">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative"
                  }}
                >
                  <div className="kt-notification">
                    {activityfeed && activityfeed.map((item, index) => 
                      <Link key={index} to={item.link ? item.link : '/dashboard'} onClick={()=>this.setClientForDisplay(item.clientID)}>
                        <div className="kt-notification__item" key={item.id}>
                          {/* a href={item.link} */}
                          <div className="kt-notification__item-icon">
                            <i className={item.icon} />
                          </div>
                          <div className="kt-notification__item-details">
                            <div className="kt-notification__item-title">
                              {item.label}
                            </div>
                            <div className="kt-notification__item-time">
                              {item.created}
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </PerfectScrollbar>
              </Tab.Pane>
             
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activityfeed : state.admin.ActivityFeed
});
  
const mapDispatchToProps = dispatch => {
  return {
    SET_CLIENT_FOR_DISPLAY:  (payload) => dispatch({ type: Admin.actionTypes.SET_FILTERS, payload: payload }),
    FETCHACTIVITYFEED:    () => dispatch({ type: Admin.actionTypes.FETCH_ACTIVITYFEED }),
  };
};
   
export default connect(mapStateToProps,mapDispatchToProps)(QuickPanel);

