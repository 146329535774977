import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import Bookings from "../../../components/Bookings";
import BookingsList from "../../../components/BookingsList";
import * as Admin from "../../../store/ducks/admin.duck";
import { Grid } from '@material-ui/core';

export default function BookingsPsychologist() {

  const dispatch = useDispatch()
  const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
  const userTZ = useSelector(state => state.auth.userTZ)

  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  // const [mode, setMode] = useState('list');

  if(registered) {
    return (
          <>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item><h4 style={{ marginBottom: 12 }}>Bookings</h4></Grid>
              {/* <Grid item> <a onClick={() => setMode('list')} style={{ fontWeight: (mode === 'list') ? 600 : 300 }}>List</a> | <a onClick={() =>setMode('calendar')}style={{ fontWeight: (mode === 'calendar') ? 600 : 300 }}>Calendar</a> </Grid> */}
            </Grid>
            <BookingsList
              type='psychologist'
              view='psychologist'
              id={registered && registered.id}
              medipassapikey={registered && registered.medipassapikey}
              userTZ={userTZ}
              providerType={registered.providertype}
            />
          </>
      )
  } 
  else 
  {
    return <div>...loading</div>
  }

}
