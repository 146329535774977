import axios from "axios"

export const apiURL = process.env.REACT_APP_APIIP

// console.log(apiURL());

export function reauth(refreshToken){
  return axios.post(apiURL + "/users/reauth", { refreshToken })
}

export function login(username, password, browserTZ) {
  return axios.post(apiURL + "/users/authenticate", { username, password, browserTZ })
}

export function totp(totp, totpToken, browserTZ) {
  return axios.post(apiURL + "/users/authenticatetotp", { totp, totpToken, browserTZ })
}

export function requestPassword(username) {
  return axios.post(apiURL + "/users/lostpassword", { username })
}

