import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom"
import { useMediaQuery } from '@material-ui/core'
import { toAbsoluteUrl } from "../../../_metronic"
import "../../../_metronic/_assets/sass/pages/login/login-1.scss"
import Login from "./Login"
import ForgotPassword from "./ForgotPassword"
import BackupCode from "./BackupCode"
import Totp from "./Totp";

export default function AuthPage() {
  const isMobile = useMediaQuery('(max-width: 760px)');
  
  return (
    <>
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
            }}
          >
            <div className="kt-grid__item">
              <Link to="/auth/login" className="kt-login__logo">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-3.png")}
                  style={{ marginLeft: -10 }}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                  Welcome to the Rural Health Connect Portal.
                </h4>
              </div>
            </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2024 Rural Health Connect
                  </div>
                  <div className="kt-login__menu">
                    {/* <Link to="/terms" className="kt-link">
                      Privacy
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Legal
                    </Link>
                    <Link to="/terms" className="kt-link">
                      Contact
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"  style={{ backgroundColor: "#FBFBFB" }}>
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/forgot-password" component={ForgotPassword} />
                <Route path="/auth/backupcode" component={BackupCode} />
                <Route path="/auth/totp" component={Totp} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
