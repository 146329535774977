import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import * as Admin from "../store/ducks/admin.duck";


import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { format } from "date-fns";
import { Paper, TextField, Grid, Select, MenuItem } from '@material-ui/core';
// import { makeStyles  } from '@material-ui/core';
import { Button, Modal, Form, Row, Col } from "react-bootstrap";


export default function Referrals(props) {

  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     // minWidth: 200,
  //     // maxWidth: 200,
  //   },
  //   media: {
  //     height: 200,
  //   },
  //   title: {
  //     fontSize: 14,
  //   },
  //   pos: {
  //     marginBottom: 12,
  //   },
  //   expand: {
  //     transform: 'rotate(0deg)',
  //     marginLeft: 'auto',
  //     transition: theme.transitions.create('transform', {
  //       duration: theme.transitions.duration.shortest,
  //     }),
  //   },
  //   expandOpen: {
  //     transform: 'rotate(180deg)',
  //   },
  // }));
  
  const initReferral = {
    id : 0,
    psychologistid : 0,
    source : 'FAX',
    status : 'CURRENT',
    referredOn: new Date().toISOString().slice(0,10),
    medicalPractitionerID : 0,
    referralPeriodCode : 'S',
    referralPeriodDuration : '0'
  }



  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchReferralsView({type: props.type, id: !props.clientID ? props.clientRegistrationID : props.clientID})) }, [dispatch, props.type, props.clientID,  props.clientRegistrationID] )

  //console.log(props.clientRegistrationID);
  // console.log(props.clientiD);

  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractitioners()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'GeneralPractitioner' })) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const medicalPractitioners = useSelector(state =>  state.admin.MedicalPractitioners)
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.GeneralPractitioner)
  const referrals = useSelector(state =>  state.admin.ReferralsView)
  const [showForm, setShowForm] = useState(false)

  const initMedicalPractitioner = () => {
    return { id: 0, providernumber: '', salutation: 'Dr', firstName: '', surname: '', mobilephone: '', phone: '', type: 'Referring GP', description: '' }
  }

  const initMedicalPractice = () => {
    return { id: 0, abn: '', practicename: '', phone: '', fax: '',address1: '', email: '', website: '', logoURL: '', practiceType: 'GeneralPractitioner' }
  }

  function handlePeriodCodeChange(value) {
    setcurrentPeriodCode(value);
  }

  function handleSourceChange(value) {
    setcurrentSource(value);
  }

  var [currentReferralID,setcurrentReferralID] = useState();
  var [currentReferredOn,setcurrentReferredOn] = useState(); 
  var [currentMedicalPractitioner, setcurrentMedicalPractitioner] = useState(initMedicalPractitioner());  
  var [currentMedicalPractice, setcurrentMedicalPractice] = useState(initMedicalPractice());
  var [currentPeriodCode,setcurrentPeriodCode] = useState();
  var [currentPeriodDuration,setcurrentPeriodDuration] = useState();
  var [currentStatus,setcurrentStatus] = useState();
  var [currentSource,setcurrentSource] = useState(); 

  var [currentMedicalPractitionerFirstNameError,setcurrentMedicalPractitionerFirstNameError] = useState('');
  var [currentMedicalPractitionerSurnameError,setcurrentMedicalPractitionerSurnameError] = useState('');
  var [currentMedicalPractitionerMobilePhoneError,setcurrentMedicalPractitionerMobilePhoneError] = useState('');
  var [currentMedicalPractitionerProviderNumberError,setcurrentMedicalPractitionerProviderNumberError] = useState('');
  var [currentMedicalPracticeClinicNameError,setcurrentMedicalPracticeClinicNameError] = useState('');
  var [currentMedicalPracticeClinicAddressError,setcurrentMedicalPracticeClinicAddressError] = useState('');
  var [currentMedicalPracticeClinicPhoneError,setcurrentMedicalPracticeClinicPhoneError] = useState('');
  var [currentMedicalPracticeClinicFaxError,setcurrentMedicalPracticeClinicFaxError] = useState('');
  var [currentReferredOnError,setcurrentReferredOnError] = useState('');
  var [currentPeriodDurationError,setcurrentPeriodDurationError] = useState('');
  
  const loadReferral = (thisReferral) => {
    setcurrentReferralID(thisReferral.id);
    setcurrentSource(thisReferral.source);
    setcurrentPeriodDuration(thisReferral.referralPeriodDuration);
    setcurrentStatus(thisReferral.status);
    // Need to handle date comes through as yyyy-MM-ddThh:mm
    var tempReferredOn = thisReferral.referredOn;
    tempReferredOn = tempReferredOn.split('T')[0];
    setcurrentReferredOn(tempReferredOn);
    setcurrentPeriodCode(thisReferral.referralPeriodCode);
    var tempMedicalPractitioner = thisReferral ? medicalPractitioners.find(v => v.id === thisReferral.medicalPractitionerID) : null;    
    loadMedicalPractitioner(tempMedicalPractitioner ? tempMedicalPractitioner : initMedicalPractitioner())
    // Display Modal
    setShowForm(true)
  }

  const loadNewReferral = () => {
    loadReferral(initReferral);
  }
 
  const loadMedicalPractitioner = (thisMedicalPractitioner) =>{
    setcurrentMedicalPractitioner(thisMedicalPractitioner ? thisMedicalPractitioner : initMedicalPractitioner())
    var tempMedicalPractice = thisMedicalPractitioner ? medicalPractices.find(v => v.id === thisMedicalPractitioner.medicalPracticeID) : null;
    loadMedicalPractitice(tempMedicalPractice)
  }

  const loadMedicalPractitice = (thisMedicalPractice) => {
    setcurrentMedicalPractice(thisMedicalPractice ? thisMedicalPractice : initMedicalPractice())
  }


  const cols = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'referredOn', type: 'date', headerName: 'Date', width: 210, renderCell: (params) => (format(new Date(params.value ? params.value : null), "dd-MM-yyyy"))},
    { field: 'clientFullName', headerName: 'Client', width: 250, hide: props.clientRegistrationID ? true: false,
      renderCell: (params) => (
        <strong>
          <div onClick={() => loadReferral(referrals.find(r => r.id === params.row.id))} className="cell-Link">
            {params.value}
          </div>
        </strong>
      ),  
    },
    { field: 'mpFullName', headerName: 'Referring GP', width: 200,
      renderCell: (params) => (
        <>
          Dr. {params.value}
        </>
      ),
    },  
    { field: 'referralPeriodCode', headerName: 'Type', width: 150,
      renderCell: (params) => (
        <>
        {params.value === 'S' ?
          'Standard'
        :
          <>
            {params.value === 'NS' ?
              <>
                'Non-Standard for ' + {params.row.referralPeriodDuration}
              </>
            :
              'Indefinite'}
          </>
        }
        </>
      ),
    },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'none', headerName: 'Actions', width: 150,
      renderCell: (params) => (
        <>
        {params.row.status !== 'CANCELLED' ?
          <Button variant="danger" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 10 }} onClick={() => changeReferralStatus(referrals.find(r => r.id === params.row.id),'CANCELLED')} >
            Cancel 
          </Button>
          :
          <Button variant="danger" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 10 }} onClick={() => changeReferralStatus(referrals.find(r => r.id === params.row.id),'CURRENT')} >
            Enable 
          </Button>
        },
      </>
      ),
    },

  ];

  function handleFormClose() {
    setShowForm(false);
  }  

  const getMedicalPractitionerOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractitioner();
    }
  }

  function save() {
    // Need to review and see if everything is populated 
    var allOk = true;
    // Key fields are the name and provider name for the referring gp 
    if (currentMedicalPractitioner.firstName.length < 3)
    {
      setcurrentMedicalPractitionerFirstNameError('Please specify the First Name of the referring GP');
      allOk = false;
    }
    if (currentMedicalPractitioner.surname.length < 2)
    {
      setcurrentMedicalPractitionerSurnameError('Please specify the Surname of the referring GP');
      allOk = false;
    }
    if (currentMedicalPractitioner.mobilephone.length !== 10)
    {
      setcurrentMedicalPractitionerMobilePhoneError('Please specify the Mobile Phone number of the referring GP');
      allOk = false;
    }
    if (currentMedicalPractitioner.providernumber.length < 7)
    {
      setcurrentMedicalPractitionerProviderNumberError('Please specify the Medicare Provider Number of the referring GP');
      allOk = false;
    }
    if (currentMedicalPractice.practicename.length < 3)
    {
      setcurrentMedicalPracticeClinicNameError('Please specify the Clinic Name of the referring GP');
      allOk = false;
    }
    if (currentMedicalPractice.phone.length !== 10)
    {
      setcurrentMedicalPracticeClinicPhoneError('Please specify the Phone Number of the referring GP Practice');
      allOk = false;
    }
    if (currentMedicalPractice.fax.length !== 10)
    {
      setcurrentMedicalPracticeClinicFaxError('Please specify the Fax Number of the referring GP Practice');
      allOk = false;
    }
    if (currentMedicalPractice.address1.length < 5)
    {
      setcurrentMedicalPracticeClinicAddressError('Please specify the address of the referring GP Practice');
      allOk = false;
    }
    if ((!currentReferredOn) || (currentReferredOn.length < 6))
    {
      setcurrentReferredOnError('Please specify the Referred On Date');
      allOk = false;
    }
    if ((currentPeriodCode === 'NS') && ((currentPeriodDuration.length < 1) || (currentPeriodDuration === '0')))
    {
      setcurrentPeriodDurationError('Please specify the Referral Duration in Months');
      allOk = false;
    }
    // Check error status 
    if (allOk)
    {
      var payload = 
      { 
        referral: 
        {
          id : currentReferralID,
          source : currentSource,
          clientID : props.clientID, 
          clientRegistrationID : props.clientRegistrationID,
          psychologistID : props.psychologistID,
          referralPeriodCode : currentPeriodCode,
          referralPeriodDuration : currentPeriodDuration,
          adminNote : '',
          psychologistNote : '',
          referredOn : currentReferredOn,
          status : currentStatus
        }, 
        medicalPractitioner: currentMedicalPractitioner, 
        medicalPractice: currentMedicalPractice,
        newClient : false,
        refresh: { 
          action: 'FETCH_REFERRALSVIEW', 
          payload: { type: 'client', id: props.clientID }
        } 
      }

      // console.log(payload);
      dispatch(Admin.actions.putReferral(payload));
      setShowForm(false);
    }
  }


  const getMedicalPracticeOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractice();
    }
  }

  function changeReferralStatus(thisReferral,action) {
    // put status & send refresh action to fetch the referrals collection again for this psychologist
    dispatch(Admin.actions.putReferralStatus( 
      { referenceID: thisReferral.id, 
        statusCHG: action, 
        refresh: { 
          action: 'FETCH_REFERRALSVIEW', 
          payload: { type: 'client', id: props.clientID }
        } 
      }));
  }

  // const classes = useStyles();

  return(
    <>
      <h4>
        Referrals
      </h4>
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadNewReferral()}>
              Add Referral
            </Button>
          </Grid>
        </Grid>
      </Paper>

      { referrals ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={referrals} columns={cols} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'referredOn', sort: 'desc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showForm} onHide={handleFormClose} dialogClassName="editReferralDialog-modal">
        <Modal.Header closeButton className="editReferral-modal" style={{ padding: 10 }}>
          <Modal.Title style={{ width: "50%", marginTop: 6 }}>
            <h4>Referral</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editReferral-modal" style={{ height: 800  }}> 
          {/* Medical Practitioner */}
          <Row>
            <Col md="4">
              <h5>Referring GP</h5>
            </Col>
            <Col md="2" style={{ textAlign: "right"}}>
              <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
            </Col>
            <Col md="6">
              <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                <Autocomplete
                  id="profileMedicalPractitionerID"
                  variant="outlined"
                  options={medicalPractitioners}
                  getOptionLabel={(option) => option.description }
                  getOptionSelected={(option, value) => getMedicalPractitionerOption(option, value)}
                  value={currentMedicalPractitioner}
                  onChange={(event, newValue) => loadMedicalPractitioner(newValue)}
                  style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="MedicalPractitionerSelector"
                      style={{backgroundColor: "", margin: 0, padding: 0}}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md="3">
              <Form.Group controlId="currentMedicalPractitionerFirstName">
                <Form.Label>Firstname</Form.Label>
                <Form.Control type="text" name="firstName" value={currentMedicalPractitioner.firstName} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, firstName: event.target.value})} isInvalid={!!currentMedicalPractitionerFirstNameError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPractitionerFirstNameError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="currentMedicalPractitionerSurname">
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text" name="surname" value={currentMedicalPractitioner.surname} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, surname: event.target.value})}  isInvalid={!!currentMedicalPractitionerSurnameError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPractitionerSurnameError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="currentMedicalPractitionerMobilePhone">
                <Form.Label>Mobile Phone</Form.Label>
                <Form.Control type="text" name="mobilephone" value={currentMedicalPractitioner.mobilephone} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, mobilephone: event.target.value})}  isInvalid={!!currentMedicalPractitionerMobilePhoneError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPractitionerMobilePhoneError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="currentMedicalPractitionerProviderNo">
                <Form.Label>Provider Number</Form.Label>
                <Form.Control type="text" name="providernumber" value={currentMedicalPractitioner.providernumber} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, providernumber: event.target.value})}  isInvalid={!!currentMedicalPractitionerProviderNumberError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPractitionerProviderNumberError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> 
          {/* Medical Practice */}  
          <Row>
            <Col md="4">
              <h5>Practice</h5>
            </Col>
            <Col md="2" style={{ textAlign: "right"}}>
              <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
            </Col>
            <Col md="6">
              <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                <Autocomplete
                  id="profileMedicalPractice"
                  variant="outlined"
                  options={medicalPractices}
                  getOptionLabel={(option) => option.practicename }
                  getOptionSelected={(option, value) => getMedicalPracticeOption(option, value)}
                  value={currentMedicalPractice}
                  onChange={(event, newValue) => loadMedicalPractitice(newValue)}
                  style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      type="text"
                      variant="outlined"
                      margin="dense"
                      name="MedicalPracticeSelector"
                      style={{backgroundColor: "", margin: 0, padding: 0}}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password'}}
                    />}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md="3">
              <Form.Group >
              <Form.Label>Clinic Name</Form.Label>
                <Form.Control type="text" name="practicename" value={currentMedicalPractice ? currentMedicalPractice.practicename : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, practicename: event.target.value})} isInvalid={!!currentMedicalPracticeClinicNameError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPracticeClinicNameError}</Form.Control.Feedback>          
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
              <Form.Label>ABN</Form.Label>
                <Form.Control type="text" name="currentAbn" value={currentMedicalPractice ? currentMedicalPractice.abn : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, abn: event.target.value})} autoComplete='new-password' />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Contact Phone</Form.Label>
                <Form.Control type="text" name="currentPhone" value={currentMedicalPractice ? currentMedicalPractice.phone : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, phone: event.target.value})}  isInvalid={!!currentMedicalPracticeClinicPhoneError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPracticeClinicPhoneError}</Form.Control.Feedback>          
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Contact Fax</Form.Label>
                <Form.Control type="text" name="currentFax" value={currentMedicalPractice ? currentMedicalPractice.fax : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, fax: event.target.value})}  isInvalid={!!currentMedicalPracticeClinicFaxError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPracticeClinicFaxError}</Form.Control.Feedback>          
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="9">
              <Form.Group >
                <Form.Label>Clinic Address</Form.Label>
                <Form.Control type="text" name="currentAddress1" value={currentMedicalPractice ? currentMedicalPractice.address1 : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, address1: event.target.value})}  isInvalid={!!currentMedicalPracticeClinicAddressError} autoComplete='new-password' />
                <Form.Control.Feedback type="invalid">{currentMedicalPracticeClinicAddressError}</Form.Control.Feedback>          
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group >
                <Form.Label>Clinic Email</Form.Label>
                <Form.Control type="text" name="currentEmail" value={currentMedicalPractice ? currentMedicalPractice.email : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, email: event.target.value})} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>                           
          <Grid container spacing={3}>
            <Grid item md={12}>
              <h3>Referral</h3>
              <hr></hr>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Row>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Referral Date</Form.Label>
                        <Form.Control type="date" name="ReferredOn" value={currentReferredOn} onChange={(event) => setcurrentReferredOn(event.target.value)} isInvalid={!!currentReferredOnError} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{currentReferredOnError}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Status</Form.Label>
                        <Form.Control type="text" name="referredOn" value={currentStatus} readOnly />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Referral Period</Form.Label>
                        <Select 
                          variant="outlined"
                          margin="dense"
                          id='periodCode'
                          aria-label='Select Referral Period'
                          name='periodCode'
                          value={currentPeriodCode} 
                          onChange={(event) => handlePeriodCodeChange(event.target.value)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                          <MenuItem key='Stanard' value='S'>Standard</MenuItem>
                          <MenuItem key='Non-Standard' value='NS'>Non Standard</MenuItem>
                          <MenuItem key='Indefinite' value='I'>Indefinite</MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>          
                    {currentPeriodCode && currentPeriodCode === 'NS' ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>Referral Duration (Mths)</Form.Label>
                          <Form.Control type="text" name="PeriodDuration" value={currentPeriodDuration} onChange={(event) => setcurrentPeriodDuration(event.target.value)} isInvalid={!!currentPeriodDurationError} autoComplete='new-password' />
                          <Form.Control.Feedback type="invalid">{currentPeriodDurationError}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    :
                    null
                    }          
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Referral Source</Form.Label>
                        <Select 
                          variant="outlined"
                          margin="dense"
                          id='source'
                          aria-label='Select Referral Source'
                          name='source'
                          value={currentSource} 
                          onChange={(event) => handleSourceChange(event.target.value)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                          <MenuItem key='Fax' value='FAX'>Fax</MenuItem>
                          <MenuItem key='Email' value='EMAIL'>Email</MenuItem>
                          <MenuItem key='Other' value='OTHER'>Other</MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>                    
                  </Row>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal.Body> 
        <Modal.Footer className="addresource-modal">
          <Button variant="secondary" onClick={handleFormClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => save()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )


}