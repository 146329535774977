import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {getGeocode, getLatLng, getZipCode} from "use-places-autocomplete";
import { Form } from "react-bootstrap";

const PlacesAutoComplete = (props) => {

  const {
    value,
    suggestions: { status, data },
    clearSuggestions,
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions : {country: 'au'},
    },
    // defaultValue : props.currentfulladdress,
    debounce: 300,
  });

  // need to reset the value when changing faxes - not when we change it internally via getGeocode
  const [currentID, setCurrentID] = useState(0)
  useEffect(() => {
    if (currentID !== props.currentID) {
      setValue(props.currentfulladdress, false);
      setCurrentID(props.currentID)
    }
  }, [currentID, props.currentID, props.currentfulladdress, setValue]);

  // console.log(props.currentfulladdress);

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    props.setcurrentfulladdress(description);
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        props.setcurrentLatitude(lat);
        props.setcurrentLongitude(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });

    getGeocode({ address: description })
    .then((results) => getZipCode(results[0],false))
    .then(( zipCode ) => {
      props.setcurrentPostCode(zipCode);
      // console.log('Getting ZipCode');
      // console.log(zipCode);
    })
    .catch((error) => {
      console.log("😱 Error: ", error);
    });


  };

  const handleInput = (e) => {
    // Place a "string" to update the value of the input element
    setValue(e.target.value);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div>
      <Form.Control type="text" name="profilefulladdress" value={value} onChange={handleInput} autoComplete='new-password' isInvalid={props.isInvalid} isValid={props.isValid} />
      {/* <input value={value} onChange={handleInput} /> */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutoComplete