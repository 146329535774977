import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useSelector } from 'react-redux'


// Not sure about these two
// import Basic from "./Basic"; 
import { LayoutSplashScreen } from "../../../_metronic";

// Admin Pages 
import InterestedPsychologists from "./admin/InterestedPsychologists";
import RegisteredPsychologists from "./admin/RegisteredPsychologists";
import Referrers from "./admin/Referrers";
import Clients from "./admin/Clients";
import { ClientInvitesAdmin } from "./admin/ClientInvites";
import MedicalPractitioners from "./admin/MedicalPractitioners";
import MedicalPractices from "./admin/MedicalPractices";
import SearchClientData from "./admin/SearchClientData";
import SearchBookingData from "./admin/SearchBookingData";
import Notifications from "./admin/Notifications";
// import Referrals from "./admin/Referrals";
import Faxes from "./admin/Faxes";
import VerifyXero from "./admin/VerifyXero";
import VerifyNDISXero from "./admin/VerifyNDISXero";
import Invoices from "./admin/Invoices";
import InvoiceContracts from "./admin/InvoiceContracts";
import InvoiceNDIS from "./admin/InvoiceNDIS";
import InvoiceLog from "./admin/InvoiceLog";
import GatherBillable from "./admin/GatherBillable"
import GatherBillableAll from "./admin/GatherBillableAll"
import GatherBillableContracts from "./admin/GatherBillableContracts";
import GatherBillableNDIS from "./admin/GatherBillableNDIS";
import GatherBillableContractsProvider from "./admin/GatherBillableContractsProvider";
import GatherBillableNDISProvider from "./admin/GatherBillableNDISProvider";
import ProfileAdmin from "./admin/ProfileAdmin"
import PaymentNDIS from "./admin/PaymentNDIS";
import PaymentContracts from "./admin/PaymentContracts";
// import Cancellations from "./admin/Cancellations";
import AdminReporting from "./configuration/AdminReporting";
import AdminUsers from "./configuration/AdminUsers";
import MetaDataConfig from "./configuration/MetaDataConfig";
import FormConfig from "./admin/FormConfigAdmin";
import Contracts from "./admin/Contracts";
import Programs from './configuration/Programs'
import Opportunity from './configuration/Opportunity'

// Reference Pages 
// import UserRoles from "./reference/UserRoles";

export default function HomePageAdmin() {

  const roleScope = useSelector(state =>  state.auth.roleScope)
  if (roleScope === 'Provisional')
  {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/interestedpsychologists" component={InterestedPsychologists} />
          <Route path="/registeredpsychologists" component={RegisteredPsychologists} />
          <Route path="/provisionalclients" render={(props) => <Clients {...props} clienttype={`Provisional`} />} />
          <Route path="/gpclients" render={(props) => <Clients {...props} clienttype={`GP`} />} />
          <Route path="/clientinvites" component={ClientInvitesAdmin} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
  else
  {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <Route path="/dashboard" component={Dashboard} />
  
          {/* Admin Pages */}
          <Route path="/interestedpsychologists" component={InterestedPsychologists} />
          <Route path="/registeredpsychologists" component={RegisteredPsychologists} />
  
          <Route path="/referrers" component={Referrers} />
  
          <Route path="/psychologyclients" render={(props) => <Clients {...props} clienttype={`Psychologist`} />} />
          <Route path="/financialclients" render={(props) => <Clients {...props} clienttype={`Financial`} />} />
          <Route path="/gpclients" render={(props) => <Clients {...props} clienttype={`GP`} />} />
          <Route path="/provisionalclients" render={(props) => <Clients {...props} clienttype={`Provisional`} />} />
          <Route path="/socialclients" render={(props) => <Clients {...props} clienttype={`Social`} />} />
          <Route path="/nurseclients" render={(props) => <Clients {...props} clienttype={`Nurse`} />} />
          <Route path="/occupationalclients" render={(props) => <Clients {...props} clienttype={`Occupational`} />} />
          <Route path="/supervisionclients" render={(props) => <Clients {...props} clienttype={`Supervision`} />} />
          <Route path="/ndisclients" render={(props) => <Clients {...props} clienttype={`NDIS`} />} />
          <Route path="/unassociated" render={(props) => <Clients {...props} clienttype={`Unassociated`} />} />

          <Route path="/eapcontractclients" render={(props) => <Clients {...props} clienttype={`EAPContract`} />} />
          <Route path="/privateclients" render={(props) => <Clients {...props} clienttype={`Private`} />} />


          <Route path="/searchclientdata" component={SearchClientData} />
          <Route path="/searchbookingdata" component={SearchBookingData} />
          <Route path="/clientinvites" component={ClientInvitesAdmin} />
          <Route path="/medicalpractitioners" component={MedicalPractitioners} />
          <Route path="/medicalpractices" component={MedicalPractices} />
          <Route path="/faxes" component={Faxes} />
          <Route path="/notifications" component={Notifications} />
          {/* <Route path="/referrals" component={Referrals} /> */}
          <Route path="/xeroverify" component={VerifyXero} />
          <Route path="/xerondisverify" component={VerifyNDISXero} />
          <Route path="/gatherbillableall" component={GatherBillableAll} />
          <Route path="/gatherbillable" component={GatherBillable} />
          <Route path="/gathercontracts" component={GatherBillableContracts} />
          <Route path="/gatherndis" component={GatherBillableNDIS} />
          <Route path="/gathercontractsprovider" component={GatherBillableContractsProvider} />
          <Route path="/gatherndisprovider" component={GatherBillableNDISProvider} />
          <Route path="/invoices" component={Invoices} />
          <Route path="/contractinv" component={InvoiceContracts} />
          <Route path="/contractpay" component={PaymentContracts} />
          <Route path="/ndisinv" component={InvoiceNDIS} />
          <Route path="/ndispay" component={PaymentNDIS} />          
          <Route path="/invoicelog" component={InvoiceLog} />
          <Route path="/adminprofile" component={ProfileAdmin} />
  
          {/* Reference Pages */}
          <Route path="/adminreporting" component={AdminReporting} />
          <Route path="/adminusers" component={AdminUsers} />
          <Route path="/metadataconfig" component={MetaDataConfig} />
          <Route path="/forms" component={FormConfig} />
          <Route path="/contracts" component={Contracts} />
          <Route path="/programs" component={Programs} />
          <Route path="/opportunity" component={Opportunity} />
  
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    );
  }

}


