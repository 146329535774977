
export const eventTypes = [
  {
    value: 'booking',
    text: 'Booking'
  }, 
  {
    value: 'availabilityAny',
    text: 'Availability - New Client Bookings'
  }, 
  {
    value: 'availabilityExisting',
    text: 'Availability - Existing'
  }, 
  {
    value: 'availabilityOther',
    text: 'Other (Not Available)'
  }, 
  {
    value: 'availabilitySupervision',
    text: 'Availability - Supervision'
  }, 
  {
    value: 'availabilityPerinatalGriefLoss',   
    text: 'Availability - Perinatal Grief & Loss'
  }
];


export const eventTypesEAP = [
  {
    value: 'booking',
    text: 'Booking'
  }, 
  {
    value: 'availabilityAny',
    text: 'Availability - New Client Bookings'
  }, 
  {
    value: 'availabilityExisting',
    text: 'Availability - Existing'
  }, 
  {
    value: 'availabilityOther',
    text: 'Other (Not Available)'
  }, 
  {
    value: 'availabilitySupervision',
    text: 'Availability - Supervision'
  }, 
  {
    value: 'availabilityNDISContractEAP',
    text: 'Availability - NDIS/Contract/EAP'
  }, 
  {
    value: 'availabilityNDISContractEAPNew',  
    text: 'Availability - New Client or NDIS/Contract/EAP'
  },
  {
    value: 'availabilityNDISContractEAPExisting',   
    text: 'Availability - Existing Client or NDIS/Contract/EAP'
  },
  {
    value: 'availabilityPerinatalGriefLoss',   
    text: 'Availability - Perinatal Grief & Loss'
  }
];

export const eventTypesNthQLDPHN = [
  {
    value: 'booking',
    text: 'Booking'
  }, 
  {
    value: 'availabilityNthQLDPHNNew',
    text: 'Availability - Nth QLD PHN New'
  }, 
  {
    value: 'availabilityNthQLDPHNExisting',
    text: 'Availability - Nth QLD PHN Existing'
  }, 
  {
    value: 'availabilityNthQLDPHNFacetoFace',   
    text: 'Availability- Nth QLD PHN Face to Face Clients'
  }
];

export const eventTypesAdmin = [
  {
    value: 'booking',
    text: 'Booking'
  }, 
  {
    value: 'availabilityAny',
    text: 'Availability - New Client Bookings'
  }, 
  {
    value: 'availabilityExisting',
    text: 'Availability - Existing'
  }, 
  {
    value: 'availabilityOther',
    text: 'Other (Not Available)'
  }, 
  {
    value: 'availabilitySupervision',
    text: 'Availability - Supervision'
  }, 
  {
    value: 'availabilityNDISContractEAP',
    text: 'Availability - NDIS/Contract/EAP'
  }, 
  {
    value: 'availabilityNDISContractEAPNew',  
    text: 'Availability - New Client or NDIS/Contract/EAP'
  },
  {
    value: 'availabilityNDISContractEAPExisting',   
    text: 'Availability - Existing Client or NDIS/Contract/EAP'
  },
  {
    value: 'availabilityPerinatalGriefLoss',   
    text: 'Availability - Perinatal Grief & Loss'
  },
  {
    value: 'availabilityNthQLDPHNNew',
    text: 'Availability - Nth QLD PHN New'
  }, 
  {
    value: 'availabilityNthQLDPHNExisting',
    text: 'Availability - Nth QLD PHN Existing'
  }, 
  {
    value: 'availabilityNthQLDPHNFacetoFace',   
    text: 'Availability- Nth QLD PHN Face to Face Clients'
  } 
];

export const bookingTypes = [
  {
    value: 'VIDEO',
    text: 'Video'
  }, {
    value: 'PHONE',
    text: 'Phone'
  },  {
    value: 'FACE',
    text: 'Face to Face'
  },   
];

export const paymentTypes = [
  {
    value: 'NDIS',
    text: 'NDIS'
  }, {
    value: 'Bulk Billed',
    text: 'Bulk Billed'
  },  {
    value: 'Reduced Gap',
    text: 'Reduced Gap'
  },  {
    value: 'Full Fee',
    text: 'Full Fee'
  }, 
  {
    value: 'EAP',
    text: 'EAP'
  },   
  {
    value: 'Contract',
    text: 'Contract'
  },   
  {
    value: 'Provisional',
    text: 'Provisional'
  },   
];

export const eventStatuses = [{
    value: 'Active',
    text: 'Active'
  }, {
    value: 'Cancelled',
    text: 'Cancelled'
  },  {
    value: 'Deleted',
    text: 'Deleted'
  },  {
    value: 'NoShow',
    text: 'No Show'
  }, 
];

export const treatments = [{
  value: 138,
  text: 'Vital sign'
}, {
  value: 189,
  text: 'Blood test'
}, {
  value: 392,
  text: 'Electrooculography(EOG)'
}, {
  value: 491,
  text: 'Holter monitor(ECG)'
}];

export const customModelFields = {
  id: 'AppointmentID',
  description: 'Note',
  start: 'Start',
  end: 'End',
  title: 'Title',
  recurrenceRule: 'RecurrenceRule',
  recurrenceId: 'RecurrenceID',
  recurrenceExceptions: 'RecurrenceException'
};
