import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Availability from "../../../components/Availability";
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";

export default function AvailabilityPsychologist() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
    
  const referenceFundingTypes = useSelector(state =>  state.reference.FundingTypes)
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  const ftypes = referenceFundingTypes.filter(( {id} ) => registered && registered.fundingTypes.includes(id))
  const userTZ = useSelector(state => state.auth.userTZ)
  
  useEffect( () => {
    if (registered) 
    {
     dispatch(Admin.actions.fetchAvailability({ psychologistid: registered.id, userTZ: userTZ }))
    }
  }, [dispatch, registered, userTZ]);
    

  // const [fetched, setFetched] = useState(false);

  // function getAvailability() {
  //   if(!fetched && registered) {
  //     dispatch(Admin.actions.fetchAvailability({ psychologistid: registered.id, userTZ: userTZ }));
  //     setFetched(true)
  //     }
  //   }

  if(registered && ftypes && referenceFundingTypes) {
    return (
      <>
        <h4>Availability</h4>
        <Availability
          mainID={registered.id}
          currentFundingTypes={ftypes}
          userTZ={userTZ}
        /> 
      </>
    )
  }
  else
  {
    return <div>Loading...</div>
  }

}
