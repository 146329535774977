import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';

import * as Admin from "../../../store/ducks/admin.duck";
import { Grid, Paper } from '@material-ui/core';
import RenderExpandableCell from "../../../components/RenderExpandableCell";

export default function Notifications(props) {

  const [searchTerm, setSearchTerm] = useState('')
  const notifications = useSelector(state => state.admin.ActivityFeed)
  const filteredNotifications = notifications.filter(notification => notification.label.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchActivityFeed()) }, [dispatch] )

  const linkPage = (link,id) => {
    //
    dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: id }))  
    props.history.push(link ? link : '/dashboard')                
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'clientID', headerName: 'ClientID', width: 100,  hide: true },
    { field: 'label', headerName: 'Notification Description (Hover to View Note)', width: 500 ,  renderCell: (params) => (
      <RenderExpandableCell {...params} />)},  
    { field: 'created', headerName: 'Created', width: 150 },  
    { field: 'link', headerName: 'Link', width: 120,
    renderCell: (params) => (
      <strong>
        <div onClick={() => linkPage(params.row.link,params.row.clientID)} className="cell-Link" >
          View Client
        </div>
      </strong>
    ),  
  }

  ];

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Admin Notifications</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
        </Grid>
      </Paper>
      { notifications ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredNotifications} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} />
        </Paper>
      : ('None')
      }

    </>
  )
}
