import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { format } from "date-fns";

export default function ReferralsView(props) {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchReferralsView({type: props.type, id: props.id})) }, [dispatch, props.type, props.id] )

  const referrals = useSelector(state =>  state.admin.ReferralsView)

  const [showReferralView, setShowReferralView] = useState(false)

  const initReferral = {
    status: '',
    referredOn: new Date(),
    psychologistFullName: '',
    psychologistNote: '',
    clientFullName: '',
    clientMobilePhone: '',
    clientEmail: '',
    mpFullName: '',
    practicename: '',
    practiceAddress: '',
    practicePhone: '',
    practiceFax : ''
  }

  const [referral, setReferral] = useState(initReferral)

  const loadReferral = (thisReferral) => {
    setReferral(thisReferral)
    setShowReferralView(true)
  }

  const cols = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'referredOn', type: 'date', headerName: 'Date', width: 210, renderCell: (params) => (format(new Date(params.value ? params.value : null), "dd-MM-yyyy"))},
    { field: 'clientFullName', headerName: 'Client', width: 250, 
      renderCell: (params) => (
        <strong>
          <div onClick={() => loadReferral(referrals.find(r => r.id === params.row.id))} className="cell-Link">
            {params.value}
          </div>
        </strong>
      ),  },
    { field: 'status', headerName: 'Status', width: 100 },
  ];

  function getActionButtons() {
    if (referral.status === 'Sent') {
      return(
        <>
          <Button variant="warning" onClick={() => changeReferralStatus('Declined')}>
            Decline Referral
          </Button>
          <Button variant="primary" onClick={() => changeReferralStatus('Accepted')}>
            Accept Referral
          </Button>
        </>
      )
    } else if (referral.status === 'Accepted') {
      return(
        <Button variant="danger" onClick={() => changeReferralStatus('Cancelled')}>
          Cancel Referral
        </Button>
      ) 
    } else {
      return(null)
    }
  }

  function changeReferralStatus(action) {
    // console.log(action)
    // put status & send refresh action to fetch the referrals collection again for this psychologist
    dispatch(Admin.actions.putReferralStatus( 
      { referenceID: referral.id, 
        statusCHG: action, 
        refresh: { 
          action: 'FETCH_REFERRALSVIEW', 
          payload: { type: props.type, id: props.id }
        } 
      }));
    setReferral({...referral, status: action});
    dispatch(Admin.actions.postNote({ type: 'Referral', pkID: referral.id, note: `Referral ${action} by ${props.viewerFullName}.` }));
  }

  return(
    <>
    { referrals ? 
      <Paper style={{ height: 700 }}>
        <DataGrid rows={referrals} columns={cols} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'referredOn', sort: 'desc' }]} />
      </Paper>
    : ('None')
    }


    <Modal show={showReferralView} onHide={() => setShowReferralView(false)} dialogClassName="referralsViewDialog-modal" centered>
      <Modal.Header closeButton className="referralsView-modal">
        <Modal.Title>Referral</Modal.Title>
      </Modal.Header>
      <Modal.Body className="referralsView-modal" style={{ padding: 30 }}>
        <Paper>
          <Grid container spacing={4} style={{ paddingLeft: 25 }}>
            <Grid item md="3">
              Referred On:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {format(new Date(referral.referredOn ? referral.referredOn: null), "dd-MM-yyyy")}
            </Grid>
            <Grid item md="3">
              Status:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.status}
            </Grid>
            <hr style={{ borderTop: '2px dotted #8c8c8c' }}></hr>
            <Grid item md="3">
              Pyschologist:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.psychologistFullName}
            </Grid>
            <Grid item md="3">
              Additional Details:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.psychologistNote}
            </Grid>
            <hr></hr>
            <Grid item md="3">
              Client:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.clientFullName}
            </Grid>
            <Grid item md="3">
              Email:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.clientEmail}
            </Grid>
            <Grid item md="3">
              Phone:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.clientMobilePhone}
            </Grid>
            <hr></hr>
            <Grid item md="3">
              GP:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.gpFullName}
            </Grid>
            <Grid item md="3">
              Practice:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.practicename} {referral.practicename !== referral.practiceAddress ? referral.practiceAddress : null}
            </Grid>
            <Grid item md="3">
              Phone:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.practicePhone}
            </Grid>
            <Grid item md="3">
              Fax:
            </Grid>
            <Grid item md="9" style={{ fontWeight: 600 }}>
              {referral.practiceFax}
            </Grid>
          </Grid>
        </Paper>
      </Modal.Body>
      <Modal.Footer className="referralsView-modal">
        <Button variant="secondary" onClick={() => setShowReferralView(false)}>
          Close
        </Button>
        {getActionButtons()}
      </Modal.Footer>
    </Modal>


    </>
  )


}