import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Auth from "../store/ducks/auth.duck";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Grid, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';

// import { makeStyles } from '@material-ui/core';

export default function MFAForm(props) {
    
  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Auth.actions.getMFA()) }, [dispatch] )
  const mfaQR = useSelector(state => state.auth.MFAQR)
  const codeAccepted = useSelector(state => state.auth.CodeAccepted)
  const backupCodes = useSelector(state => state.auth.BackupCodes)
  const [currentLoadingQRCode,setcurrentLoadingQRCode] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState()
  const [confirmationCodeHelpText, setConfirmationCodeHelpText] = useState('') 
   
  
  // For a language that is designed to work with API it is primitive that I need to set a flag to stop the React_PDF from erroring when a PDF is not loaded 
  if (!currentLoadingQRCode && mfaQR && mfaQR.length > 5)
  {
    setcurrentLoadingQRCode(true);
  }
  
  function confirmMFA() {
    // Determine if the criteria has been made 
    if ((confirmationCode.length !== 6))
    {
      setConfirmationCodeHelpText("Please enter the six digit code");
    }
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(confirmationCode);
    dispatch(Auth.actions.postConfirmCode({ confirmCode: confirmationCode}));
    // props.onCloseAPIKey();
  }

  function activateMFA() {
    // Ok Time to Dispatch 
    dispatch(Auth.actions.postActivateMFA({type : props.type}));
    props.onCloseMFA();
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //  const useStyles = makeStyles((theme) => ({
  //   root: {
  //     // minWidth: 200,
  //     // maxWidth: 200,
  //   },
  //   media: {
  //     height: 200,
  //   },
  //   title: {
  //     fontSize: 14,
  //   },
  //   pos: {
  //     marginBottom: 12,
  //   },
  // }));
    
  // const classes = useStyles();

  return(
    <>
      {codeAccepted ?
        <>
          <Row>  
            <p style={{ marginLeft:10, fontWeight:600 }}>Backup Codes</p>
          </Row>
          <Row style={{ marginTop: 15}}>
            <Col md="12">                  
              <Form.Group >
                <Form.Label>Backup codes are unsed to access your account in the event that you cannot receive two-factor authentication. For security reasons, each code can only be used once.</Form.Label>
                <Form.Label style={{ fontWeight:600}}>Print or copy your Backup Codes before continuing two-factor authentication setup</Form.Label>
                <Row style={{ marginTop:10, marginBottom:20, padding:5, border:"solid 1px gray"}} ref={componentRef}>
                  <Col md="6">
                    <List >
                      {backupCodes.map((row,index) => {
                        if(index+1 <= backupCodes.length/2)
                        {
                          return (
                            <ListItem key={index} dense button >
                              <ListItemText id={row} style={{ fontSize: 12 }} 
                                disableTypography 
                                primary={<Typography>{row}</Typography>}  />
                            </ListItem>
                          );
                        }
                        else
                        {
                          return null
                        }
                      })}
                    </List>
                  </Col>
                  <Col md="6">
                    <List >
                      {backupCodes.map((row,index) => {
                        if(index+1 > backupCodes.length/2)
                        {
                          return (
                            <ListItem key={index} dense button >
                              <ListItemText id={row} style={{ fontSize: 12 }} 
                                disableTypography 
                                primary={<Typography>{row}</Typography>} />
                            </ListItem>
                          );
                        }
                        else
                        {
                          return null
                        }
                      })}
                    </List>
                  </Col>                  
                </Row>
                <Row>
                  <Col md="12">
                    <Grid container spacing={0} padding={6} >
                      <Button variant="warning" onClick={()=> handlePrint()}>
                        Print
                      </Button>
                      <Button variant="secondary" size="sm" onClick={() => navigator.clipboard.writeText(backupCodes)} style={{ marginLeft:20}}>
                        Copy
                      </Button>
                    </Grid>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="12">
              <Grid container spacing={0} padding={6} justifyContent="space-between">
                <Button variant="light" onClick={()=>props.onCloseMFA()}>
                  Cancel
                </Button>
                <Button variant="success" size="sm" onClick={() => activateMFA()}>
                  Finish
                </Button>
              </Grid>
            </Col>
          </Row>
        </>
      :
        <>
          <Row>  
            <p style={{ marginLeft:10 }}>Scan the QR code below with the two-factor authenticaiton app on your phone.</p>
          </Row>
          {currentLoadingQRCode ? 
            <div style={{ marginTop:10, padding:5, border:"solid 1px gray", maxWidth:160}}>
              <img src={`data:image/png;base64,${mfaQR}`} style={{ maxHeight: 150 }} alt='MFA QR Code' />
            </div>
          : 
            <div style={{ marginLeft: 15, marginTop: 15 }}>No QR code available</div>
          }
          <Row style={{ marginTop: 15}}>
            <Col md="12">                  
              <Form.Group >
                <Form.Label>Enter the six-digit code from the application</Form.Label>
                <Form.Label>After scanning the QR code, the app will display a six-digit code that you can enter below.</Form.Label>
                <Row>
                  <Col md="4">
                    <Form.Control type="text" name="confirmationCode" defaultValue={confirmationCode} onBlur={(event) => setConfirmationCode(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                    {confirmationCodeHelpText.length ? (<p className='error-help'>{confirmationCodeHelpText}</p>) : ''}
                  </Col>
                  { confirmationCodeHelpText && confirmationCodeHelpText.length > 1 ?
                    <Col md="4">
                      <Form.Label style={{ color: 'red', marginTop: 10}}>Code Too Short - Try Again</Form.Label>
                    </Col>
                  :
                    null
                  }                
                  { codeAccepted === false ?
                    <Col md="4">
                      <Form.Label style={{ color: 'red', marginTop: 10}}>Code Not Valid - Try Again</Form.Label>
                    </Col>
                  :
                    null
                  }
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Grid container spacing={0} padding={6} justifyContent="space-between">
                <Button variant="light" onClick={()=>props.onCloseMFA()}>
                  Cancel
                </Button>
                <Button variant="success" size="sm" onClick={() => confirmMFA()}>
                  Next
                </Button>
              </Grid>
            </Col>
          </Row>
        </>
      }
    </>
  )


}
  
