import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardClient from "./DashboardClient";

// Not sure about these two
// import Basic from "./Basic"; 
import { LayoutSplashScreen } from "../../../_metronic";

// Admin Pages 
import ProfileClient from "./admin/ProfileClient";
import BookingsClient from "./admin/BookingsClient";
import FilesClient from "./admin/FilesClient";
import SearchClient from "./admin/SearchClient";

// Reference Pages 
// import UserRoles from "./reference/UserRoles";

export default function HomePageClient() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={DashboardClient} />

        {/* Client Pages */}
        <Route path="/profile" component={ProfileClient} />
        <Route path="/bookings" component={BookingsClient} />
        <Route path="/files" component={FilesClient} />
        <Route path="/search" component={SearchClient} />

        {/* Reference Pages */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
