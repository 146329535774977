import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import * as Reference from "../store/ducks/reference.duck";
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Row, Col, Form, Badge} from "react-bootstrap";
import { Paper, Grid, List, ListItem, ListItemIcon, ListItemText,FormControl, MenuItem, Select, Card,  Checkbox, CardMedia, CardActions, CardContent, Chip, Typography, makeStyles, useMediaQuery,  IconButton, Collapse } from '@material-ui/core';
import { BookingWeek } from "./Appointments";
import Interweave from 'interweave';
import { format } from "date-fns";
import EmailIcon from '@material-ui/icons/Email';
import ToDos from './ToDos';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Masonry from 'react-masonry-css';
import '../../_metronic/_assets/css/app.css';
import '../../_metronic/_assets/css/app2.css';

// import medipassSDK from '@medipass/partner-sdk';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  media: {
    height: 200,
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));




export default function BookingsList(props) {

  // Blob setup for psychologist photos 
  const containerName = `psychologists`;
  const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME || ""; 
  const [showProvider, setShowProvider] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width: 760px)');
  const bookings = useSelector(state => state.admin.Bookings)
  const client = useSelector(state => state.admin.Client)
  const filters = useSelector(state => state.admin.Filters)
  // console.log(props);

  const supervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist')
  const endorsedSupervisionAreas = useSelector(state => state.reference.SupervisionAreas).filter(sa => sa.providertype === 'Psychologist-Endorsed')
  const [currentPsychologistID,setCurrentPsychologistID] = useState(props.type==='client' ? client.currentpsychologistid : 0)
  const currentClientPaymentType = props.type==='client' ? client.paymentType : 0;
  const currentClientBookingType = props.type==='client' ? client.appointmenttype === '1' ? 'PHONE' : 'VIDEO' : 'VIDEO';
  const [currentPsychologistFullname,setCurrentPsychologistFullname] = useState(props.type==='client' ? client.psychologistName : 0)
  const currentGPID = props.type==='client' ? client.currentgpid : 0;
  const currentGPFullname = props.type==='client' ? client.gpFullName : 0;
  const currentClientID = props.type==='client' ? client.id : 0;
  const currentClientFullname = props.type==='client' ? client.fullname : '';
  const [currentHourlyRate,setCurrentHourlyRate] = useState('TBA')
  const [currentWaitingRoomURL,setCurrentWaitingRoomURL] = useState(props.type==='client' ? client.waitingRoomURL : '')
  const currentGPWaitingRoomURL = props.type==='client' ? client.gpwaitingRoomURL : ''
  const currentClientFirstName = props.type==='client' ? client.firstname : ''
  const currentClientEmail = props.type==='client' ? client.email : ''
  const currentPreferredName = props.type==='client' ? client.preferredName : '' 
  const [currentPsychologistTZ,setCurrentPsychologistTZ] = useState('TBA')
  const currentClientTZ= props.type==='client' ? client.timeZone : ''
  const [expanded, setExpanded] = useState(1)
  const currentSpecialistType = props.type==='client' ? client.specialisttype : '';
  const classes = useStyles();
  const futureBookingStatus = useSelector(state => state.admin.ClientFutureBookingStatus)
  const filteredBookingsFuture = bookings.filter(booking => (booking.days === 'Future'));
  const filteredBookingsFutureProvider = bookings.filter(booking => ((booking.days === 'Future') && booking.status === 'Active'));
  const filteredBookingsPast = bookings.filter(booking => (booking.days === 'Past'));
  const filteredBookingsPastProvider = bookings.filter(booking => ((booking.days === 'Past') && booking.status === 'Active'));
  const psychologists = useSelector(state => state.admin.Matching)
  const psychologistsNew = psychologists.filter(psy => (psy.nextAvailableNew !== null && psy.nextAvailableNew !== ''))
  const psychologistsSupervision = psychologists.filter(psy => (psy.nextAvailableSupervision !== null && psy.nextAvailableSupervision !== ''))

  const [cancellationReason,setCancellationReason] = useState()
  const [showCancellationReason,setShowCancellationReason] = useState('')
  const [showProviderBio,setShowProviderBio] = useState(false)
  const [providerBioFullName,setProviderBioFullName] = useState()
  const [providerBioBio,setProviderBioBio] = useState()
  
  // props.type === 'psychologist'
  function handleProviderClose() {
    setShowProvider(false);
  }  
 
  var [providerType, setProviderType] = useState(props.providerType)

  useEffect( () => { dispatch(Reference.actions.fetchSupervisionAreas()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchBookings({ key: props.type, id: props.id })) }, [dispatch, props.type, props.id] )
  useEffect( () => { dispatch(Admin.actions.fetchMatching( { providertype : providerType, invite : true, availabilitytype : 'Both' })) }, [dispatch, providerType] )
  // useEffect( () => { dispatch(Admin.actions.fetchMatching( { providertype : 'Psychologist', invite : true, availabilitytype : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDIS/Contract/EAP' : 'Both' })) }, [dispatch] )
  useEffect( () => { 
    // console.log('Running UseEffect')
    if (props.type === 'client')
    {
      // console.log('Calling Dispatch')
      dispatch(Admin.actions.fetchClientFutureBookingStatus( { providertype : providerType }))
    }
  }, [dispatch, props.type,client, providerType] )

  var [showBooking, setShowBooking] = useState(false)
  var [showCancelBooking, setShowCancelBooking] = useState(false);
  const [toggleCancelMessage,setToogleCancelMessage] = useState(false);
  const [showCancelProviderBooking,setShowCancelProviderBooking] = useState(false)
  var [booking, setBooking] = useState(false)
  var [toggleMessage, setToggleMessage] = useState(false)
  var [newNote, setNewNote] = useState('')
  var [sentMsg, setSentMsg] = useState()
  var [makeBooking, setMakeBooking] = useState(false)
  
  const [showToDo, setShowToDo] = useState(false)
  const [toDoClientID, setToDoClientID] = useState()
  const [toDoTitle, setToDoTitle] = useState('')


  const loadToDos = (clientID, fullName) => {
    setToDoClientID(clientID)
    setToDoTitle(fullName)
    setShowToDo(true)
  }

  const handleExpandClick = (id) => {
    if(id === expanded) {
      setExpanded(0)
    } else {
      setExpanded(id);
    }
  };

  const moreProviderBio = (fullName, bio) => {
    // console.log('Read More Clicked')
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

  const clientCancel = (thisBooking) => {
    // Load data
    setBooking(thisBooking)
    // Check if in Admin portal - no rules apply 
    if (props.view !== 'admin')
    {
      // Clients cannot cancel bookings with 48 hours 
      var notice48 = new Date();
      var noticePast = new Date();
      var clientDate = new Date(thisBooking.clientDate);
      notice48.setDate(notice48.getDate() + 2);
      // Check if the booking is in the next 48 hours
      if ((clientDate < notice48) && (clientDate > noticePast))
      {
        // console.log('Within48');
        setToogleCancelMessage(true);
      }
    }
    // 
    // Open Modal
    setShowCancelBooking(true);
  }


  const providerCancel = (thisBooking) => {
    // Load data
    setBooking(thisBooking);
    // Close current modal 
    setShowBooking(false);
    // Open Provider Cancel Modal
    setShowCancelProviderBooking(true);
  }


  const loadBooking = (thisBooking) => {
    setBooking(thisBooking);
    var newishNote = `Dear ${thisBooking && thisBooking.clientPreferredName && thisBooking.clientPreferredName.length > 0 ? thisBooking.clientPreferredName : thisBooking.clientFirstName},

Please note you have been booked for a consultation with ${thisBooking.psychologistfullname} at ${format(new Date(thisBooking.clientDate ? thisBooking.clientDate: null), "dd-MM-yyyy hh:mm aa")}. `;

    if (!thisBooking.bookingType)
    {
      if (thisBooking.appointmenttype === '1')
        {
          setNewNote(`${newishNote}   
    ${thisBooking.psychologistfullname} will call you.`)
        }
        else
        {
          setNewNote(`${newishNote}
    Please connect here: ${thisBooking.waitingroomurl} at that time.`)
        }
    } else {
      if (thisBooking.bookingType === 'PHONE')
      {
        setNewNote(`${newishNote}   
          ${thisBooking.psychologistfullname} will call you.`)
      } else {
        if (thisBooking.bookingType === 'VIDEO')
        {
          setNewNote(`${newishNote}
            Please connect here: ${thisBooking.waitingroomurl} at that time.`)
        }
        else
        {
          setNewNote(`${newishNote}
            Please attend here: ${thisBooking.fullAddress} at that time.`)
        }

      }
        
    }



    setToggleMessage(false);
    setShowBooking(true);
  }

  var cols = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'psychologistDate', type: 'date', headerName: 'Date', flex: 1, renderCell: (params) => (format(new Date(params.value ? params.value : null), "dd-MM-yyyy"))}
  ]

  if(props.type === 'psychologist')
  {  
    cols.push(
    { field: 'clientDate', type: 'date', headerName: 'My Time / Client Time',  flex: 1, renderCell: (params) => (
        format(new Date(params.row.psychologistDate), "h:mm aa") +  ((params.row.clientDate !== params.row.psychologistDate) ? " / " + format(new Date(params.row.clientDate), "h:mm aa") : '')
        )},
    )
  }

  if(props.type === 'client')
  {    
    cols.push(
      { field: 'clientDate', type: 'date', headerName: 'Time',  flex: 1, renderCell: (params) => (format(new Date(params.row.clientDate), "h:mm aa"))},
    )
  }

  if(props.type !== 'psychologist')
  {    
    cols.push(
      { field: 'title', headerName: 'Provider', flex: 1 }
    )
  }
  else
  {
    cols.push(
      { field: 'title', headerName: 'Provider', flex: 1,
        renderCell: (params) => {
          return(
            <Grid container justifyContent="space-between">
              <Grid item>
                {params.value}
              </Grid>
              {params.row.toDoCount !== 0 ?
                <>
                  <Grid item>
                    <EmailIcon style={{ marginLeft: 8, color: 'green' }} onClick={()=>loadToDos(params.row.clientID, params.value)} />
                  </Grid>
                </>
              :
                null
              }
            </Grid>
          )
        }
      },
    )
  }

  if (props.view === 'admin')
  {
    cols.push(
      { field: 'status', headerName: 'Status', flex: 0.7 }
    )
  }

  if (props.type === 'psychologist' && props.medipassapikey === 'Configured')
  {
    cols.push(
      { field: 'invoicedate', type: 'date', headerName: 'Invoice Date', flex: 1},
      { field: 'invoicestatus', headerName: 'Invoice Status', flex: 0.7 }
    )
  }

  cols.push(
    { field: 'action', headerName: 'Actions', flex: 1.5, //width: 100
      renderCell: (params) => (
        <>
          <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 10 }} onClick={() => loadBooking(bookings.find(r => r.id === params.row.id))} >
              View 
          </Button>
          {props.type === 'client' && params.row.status !== 'Cancelled' ?
            <Button variant="warning"  size="sm" style={{ color: "white", fontWeight: 500, border: 0, background: "#A33383", marginRight: 10 }} onClick={() => clientCancel(bookings.find(r => r.id === params.row.id))} >
                Cancel Booking
            </Button>
          :
            null
          }
        </>
      ),
    },
  )

  function getActionButtons(id) {
    if (props.type === 'psychologist') {
      if (booking.status === 'Cancelled') {
        return(
          <>
            <Button variant="danger" size="sm" onClick={() => deleteBooking(id)}>
              Delete Booking
            </Button>
          </>
        )
      }
      else 
      {
        return(
          <>
            <Button variant="warning"  size="sm" onClick={() => providerCancel(bookings.find(r => r.id === id))} >
                Cancel Booking
            </Button>
          </>
        )
      }
    } 
    else 
    {
      return(null)
    }
  }

  function cancelBooking(id) {
    // Need to handle no show which is triggered if it is save by a provider and reason is not selected - it is like the multiverse 
    if (props.type === 'psychologist' && (!showCancellationReason || (showCancellationReason && showCancellationReason === 'N'))) 
    {
          // Update database 
      dispatch(Admin.actions.putAppointment( 
        { id: id, 
          status: 'NoShow', 
          refreshPayload: { key: props.type, id: props.id },
          note: cancellationReason
        }));
      // Update local display         
      setBooking({...booking, status: 'NoShow'});
    }
    else
    {
      // Update database 
      dispatch(Admin.actions.putAppointment( 
        { id: id, 
          status: 'Cancelled', 
          refreshPayload: { key: props.type, id: props.id },
          note: cancellationReason
      }));
      // Update local display       
      setBooking({...booking, status: 'Cancelled'});
    }
    // Save note if a cancellation reason was set - it can be set in provider cancellations 
    if (showCancellationReason && showCancellationReason === 'Y')
    {
      // ok we have a note to save 
      const formData = new FormData();
      // Create note 
      var newishNote = `Please note your consultation with ${booking.psychologistfullname} at ${format(new Date(booking.clientDate ? booking.clientDate: null), "dd-MM-yyyy hh:mm aa")}. was cancelled by the provider due to ${cancellationReason}`;
      // Add fields 
      formData.append('type','Client');
      formData.append('pkid', booking.clientID);
      formData.append('subject','');
      formData.append('selector','Note');
      formData.append('note',newishNote);
      formData.append('faxdestination','');
      formData.append('faxFile','');
      formData.append('sendFormID',0);
      // Ok Time to Dispatch 
      process.env.REACT_APP_DEBUG && console.log(...formData);
      dispatch(Admin.actions.postNote({ formData:formData, type: props.type, pkID: props.pkid}));
    }
    else
    {

    }
    // Close client booking cancel modal - if open
    setShowCancelBooking(false);
    // Close provider booking cancel modal - if open
    setShowCancelProviderBooking(false)
  }

  function deleteBooking(id) {
    dispatch(Admin.actions.putAppointment( 
      { id: id, 
        status: 'Deleted', 
        refreshPayload: { key: props.type, id: props.id }
      }));
    setBooking({...booking, status: 'Deleted'});
    setShowBooking(false)
  }

  // function noshowBooking(id) {
  //   dispatch(Admin.actions.putAppointment( 
  //     { id: id, 
  //       status: 'NoShow', 
  //       refreshPayload: { key: props.type, id: props.id }
  //     }));
  //   setBooking({...booking, status: 'NoShow'});
  //   setShowBooking(false)
  // }


  function sendMessage() {
    const formData = new FormData();
    // Add fields 
    formData.append('type','Client');
    formData.append('pkid', booking.clientID);
    formData.append('subject','');
    formData.append('selector','SMS');
    formData.append('note',newNote);
    formData.append('faxdestination','');
    formData.append('faxFile','');
    formData.append('sendFormID',0);
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(...formData);
    dispatch(Admin.actions.postNote({ formData:formData, type: props.type, pkID: props.pkid}));
    setSentMsg('Message sent!')
    showMessageTimer();
  }

  const showMessageTimer =() => {
    const timer = setTimeout(() => {
      setSentMsg(undefined);
      setShowBooking(false)
      }, 3000);
      return () => clearTimeout(timer);
  }

  function createBooking(type)
  {
    setProviderType(type);
    setMakeBooking(true)  
  }


  function selectProvider(row) {
    // Update State with newly chosen psychologist
    setCurrentPsychologistID(row.id)
    setCurrentPsychologistFullname(row.fullname)
    setCurrentHourlyRate(row.hourlyrate)
    setCurrentWaitingRoomURL(row.waitingroomurl)
    setCurrentPsychologistTZ(row.timezone)
    // Update Client 
    dispatch(Admin.actions.putClientPsychologist({ psychologistid: row.id, clientid: currentClientID, providerName: row.fullname }))
    // Now ask client to make new booking 
    setProviderType('Psychologst');
    if (props.paymentType === 'Supervision' || props.providerType === 'Supervision')
    {
      setProviderType('Supervision');
    }
    setShowProvider(false);
    setMakeBooking(true)  
  }


  function NewBookingButton()
  {
    // Changed this to show the book new appointment button on client dashboard - may need to change it back - 16/11/22
    // if (props.type !== 'client' || props.view !== 'client')
    if (props.type !== 'client')
    {
      return(null);
    }
    // console.log(props.currentpsychologistid);
    // console.log(futureBookingStatus)
    // console.log(props.currentgpid);
    return(
      <>
        {props.currentpsychologistid ?
          <>
            {providerType === 'Financial' ?
              <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
                <Button variant="primary" size="sm" onClick={() => createBooking('Financial')}>
                  Book Financial Counsellor Appointment
                </Button>
              </Grid>
            :
              <>
                {providerType === 'Supervision' ?
                  <>
                    <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
                      <Button variant="primary" size="sm" onClick={() => createBooking('Supervision')}>
                        Book Supervision Appointment
                      </Button>
                    </Grid>
                    { !futureBookingStatus ?
                      <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
                        <Button variant="primary" size="sm" onClick={() => setShowProvider(true)}  >
                          Select Different Supervisor
                        </Button>
                      </Grid>
                    :
                      null
                    }
                  </>
                :              
                  <>
                    <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
                      <Button variant="primary" size="sm" onClick={() => createBooking('Psychologist')}>
                        Book Psychologist Appointment
                      </Button>
                    </Grid>
                    { !futureBookingStatus ?
                      <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
                        <Button variant="primary" size="sm" onClick={() => setShowProvider(true)}  >
                          Select Different Psychologist
                        </Button>
                      </Grid>
                    :
                      null
                    }
                  </>
                }
              </>
            }
          </>
        :
          null
        }
        {props.currentgpid ?
          <Grid item style={{ marginLeft: 10, paddingLeft: 2 }}>
             <Button variant="primary" size="sm" onClick={() => createBooking('GP')}>
                Book GP Appointment
            </Button>            
          </Grid>
        :
          null
        }



      </>
    )
  }

  function BookingData()
  {
    let selectedList; 
    if (filters.bookingPhase === 'Future')
    {
      // Don't display non active bookings to psychologists 
      if (props.type === 'psychologist')
      {
        selectedList = filteredBookingsFutureProvider;
      }
      else
      {
        selectedList = filteredBookingsFuture;
      }
    }
    else
    {
      // Don't display non active bookings to psychologists 
      if (props.type === 'psychologist')
      {
        selectedList = filteredBookingsPastProvider;
      }
      else
      {
        selectedList = filteredBookingsPast; 
      }
    }
    // console.log(props.view)
    // console.log(props.type)
    return(
      <>
        { bookings ? 
            props.nextBooking ?       
              <Button variant="primary" size="sm" onClick={() => loadBooking(bookings.find(r => r.id === props.nextBooking.id))}>
                View
              </Button>            
            :
              <>
                <Paper> 
                  <Grid container justifyContent="space-between" style={{ padding: 10 }}>
                      <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
                        <Grid container>
                          <Grid item style={{ marginLeft: 10 }}>
                            <FormControl variant="outlined" className={classes.formControl}>  
                              <Select
                                labelId="bookingPhase-label"
                                id="bookingPhase"
                                margin="dense"
                                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                                value={filters.bookingPhase}
                                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'bookingPhase', filterValue: event.target.value }))}
                              >
                                <MenuItem key={1} value='Future'>Future</MenuItem>
                                <MenuItem key={2} value='Past'>Past</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <NewBookingButton  />
                        </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                {(props.view !=='dashboard') ?
                  <Paper style={{ height: 660 }}>
                      <DataGrid rows={selectedList} columns={cols} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection={false} sortModel={[{ field: 'psychologistDate', sort: 'asc' }]} />
                  </Paper>
                :
                  <Paper style={{ height: 200 }}>
                      <DataGrid rows={selectedList} columns={cols} pageSize={5} rowsPerPageOptions={[5]} checkboxSelection={false} sortModel={[{ field: 'psychologistDate', sort: 'asc' }]} />
                  </Paper>
                }
              </>
          : ('None')
        }
      </>
    )
  }

  // note that next booking prop renders the next booking button to provide all booking actions when used on a dashboard. Otherwise we return the list.
    return(
    <>
      { makeBooking ?
        <BookingWeek
          mode="client"
          inviteType = {props.type}
          gpID = {currentGPID}
          gpFullname = {currentGPFullname}
          psychologistID = {currentPsychologistID}
          psychologistFullname = {currentPsychologistFullname}
          clientID = {currentClientID}
          clientFullname = {currentClientFullname}
          currentHourlyRate = {currentHourlyRate}
          currentWaitingRoomURL = {currentWaitingRoomURL}
          gpcurrentWaitingRoomURL = {currentGPWaitingRoomURL}
          clientFirstName = {currentClientFirstName}
          clientPreferredName = {currentPreferredName}
          clientEmail = {currentClientEmail}
          psychologistTZ = {currentPsychologistTZ}
          clientTZ={currentClientTZ}
          paymentType={currentClientPaymentType}
          bookingType={currentClientBookingType}
          setMakeBooking={setMakeBooking}
          providerType={providerType}
          specialistType={currentSpecialistType}
        />
      :
        <>
          <BookingData />
          <Modal show={showBooking} onHide={()=>setShowBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
            <Modal.Header closeButton className="new-booking-modal">
              <Modal.Title>Booking - {booking.status}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
              {!toggleMessage ?
                <Grid container spacing={3} style={{ height: 247 }}>
                  <Grid item md={3} xs={6}>
                    {props.providerType === 'GP' ? 
                      <>
                        GP:
                      </>
                    :
                      <>
                        {props.providerType === 'Financial' ?
                          <>
                            Financial Counsellor:
                          </>
                        :
                          <>
                            Psychologist:
                          </>
                        }
                      </>
                    }
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {booking.psychologistfullname}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    Client:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {booking.clientfullname}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    Date:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy")}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    Time:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "h:mm aa")}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    Booking Type:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {booking.bookingType && booking.bookingType === 'FACE' ? 'Face to Face' : 'Video'}
                  </Grid>
                  { booking.clientDate !== booking.psychologistDate ?
                    <>
                      <Grid item md={3} xs={6}>
                        Client's Time:
                      </Grid>
                      <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                        {format(new Date(booking.clientDate ? booking.clientDate: null), "h:mm aa")}
                      </Grid>
                    </>
                  : 
                    null
                  }
                  {/* <Grid item md={3} xs={6}>
                    Session URL:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    <a href={booking.waitingroomurl} target="_blank" rel="noopener noreferrer">
                      {booking.waitingroomurl}
                    </a>
                  </Grid> */}
                </Grid>
              :
                sentMsg ?             
                <>
                  <Row>
                    <Col md="12" style={{ height: 222 }}>                  
                    {sentMsg}
                    </Col>
                  </Row>
                </>
                  :            
                <>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={8} type="text" name="psychologistNote" defaultValue={newNote} onBlur={(event) => setNewNote(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                        {/* {newNoteHelpText.length ? (<p className='error-help'>{newNoteHelpText}</p>) : ''} */}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              }
            </Modal.Body>
            <Modal.Footer className="new-booking-modal">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
              {!toggleMessage ?
                <>
                  <Grid item>
                    {getActionButtons(booking.id)}
                  </Grid>
                  <Grid item>
                    { props.type === 'psychologist' ? 
                    <Button variant="primary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} style={{ marginRight: 15 }}>
                      Message
                    </Button>
                    : null }
                    <Button variant="secondary" size="sm" onClick={() => setShowBooking(false)} className="lightButtonOutlinedLarge">
                      Close
                    </Button>
                  </Grid>
                </>
              :
                <>
                  <Grid item>
                    <Button variant="secondary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} className="lightButtonOutlinedLarge">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="success" size="sm" onClick={() => sendMessage()}>
                      Send &gt;
                    </Button>
                  </Grid>
                </>
              }
              </Grid>
            </Modal.Footer>
          </Modal>
        </>
      }

      <Modal show={showCancelBooking} onHide={()=>setShowCancelBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Cancel your booking on {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy")} at {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "h:mm aa")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          {!toggleCancelMessage ?
            <Grid container spacing={3} style={{ height: 150 }}>
              <Grid item md={6} xs={6}>
                {props.providerType === 'GP' ? 
                  <>
                    With GP:
                  </>
                :
                  <>
                    {props.providerType === 'Financial' ?
                      <>
                        With Financial Counsellor:
                      </>
                    :
                      <>
                        With Psychologist:
                      </>
                    }
                  </>
                }
              </Grid>
              <Grid item md={6} xs={6} style={{ fontWeight: 600 }}>
                {booking.psychologistfullname}
              </Grid>
              <Grid item md={6} xs={6}>
                Date:
              </Grid>
              <Grid item md={6} xs={6} style={{ fontWeight: 600 }}>
                {format(new Date(booking.clientDate ? booking.psychologistDate: null), "dd-MM-yyyy")}
              </Grid>
              <Grid item md={6} xs={6}>
                Time:
              </Grid>
              <Grid item md={6} xs={6} style={{ fontWeight: 600 }}>
                {format(new Date(booking.clientDate ? booking.psychologistDate: null), "h:mm aa")}
              </Grid>
            </Grid>
          :
            <>
              <Row>
                <Col md="12" style={{ height: 222, fontWeight: 600 }}> 
                  <p>Cancelling appointments within 48 hrs may incur costs.</p>
                  <p>Please call 0427 692 377 for assistance.</p>                 
                </Col>
              </Row>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Grid container direction="row" justifyContent="space-between"  alignItems="center">
            <Grid item>
              <Button variant="secondary" size="sm" onClick={() => setShowCancelBooking(false)} className="lightButtonOutlinedLarge">
                Close
              </Button>
            </Grid>
            {!toggleCancelMessage ?
              <>
                <Grid item>
                  <Button variant="warning" size="sm" onClick={() => cancelBooking(booking.id)}>
                    Cancel Booking &gt;
                  </Button>
                </Grid>
              </>
            :
              null
            }
          </Grid>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelProviderBooking} onHide={()=>setShowCancelProviderBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Booking with {booking.clientfullname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container spacing={3} >
            <Grid item md={3} xs={6}>
              Client:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {booking.clientfullname}
            </Grid>
            <Grid item md={3} xs={6}>
              Date:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "dd-MM-yyyy")}
            </Grid>
            <Grid item md={3} xs={6}>
              Time:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(booking.psychologistDate ? booking.psychologistDate: null), "h:mm aa")}
            </Grid>
            { booking.clientDate !== booking.psychologistDate ?
              <>
                <Grid item md={3} xs={6}>
                  Client's Time:
                </Grid>
                <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                  {format(new Date(booking.clientDate ? booking.clientDate: null), "h:mm aa")}
                </Grid>
              </>
            : 
              null
            }
            {/* <Grid item md={3} xs={6}>
              Session URL:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              <a href={booking.waitingroomurl} target="_blank" rel="noopener noreferrer">
                {booking.waitingroomurl}
              </a>
            </Grid> */}
            <hr />
            <Grid item md={12} xs={6}>
              <Form.Group>
                  <Form.Label >Reason for Cancelling</Form.Label>
                  <Form.Check id="cancellationType" name="selectlater" type="radio" style={{ marginBottom: 8 }} >
                    {booking.paymentType === 'NDIS' ?
                      <>
                        <Form.Check.Input name="SelectLater" type="radio" checked={showCancellationReason === 'N' ? true: false} onChange={e => setShowCancellationReason('N')} />
                        <Form.Check.Label style={{marginLeft: 12 }}>Did not attend (Enter Reason for NDIS Billing)</Form.Check.Label>
                      </>                    
                    :
                      <>
                      <Form.Check.Input name="SelectLater" type="radio" checked={showCancellationReason === 'N' ? true: false} onChange={e => setShowCancellationReason('N')} />
                      <Form.Check.Label style={{marginLeft: 12 }}>Did not attend</Form.Check.Label>
                      </>                    
                    }
                  </Form.Check>
                  <Form.Check id="NotSelectLater" name="notselectlater" type="radio" style={{ marginBottom: 8 }} >
                    <Form.Check.Input name="SelectLater" type="radio" checked={showCancellationReason === 'Y' ? true: false} onChange={e => setShowCancellationReason('Y')} />
                    <Form.Check.Label style={{marginLeft: 12 }}>Reason - please enter below</Form.Check.Label>
                  </Form.Check>
                  { showCancellationReason === 'Y' || booking.paymentType === 'NDIS' ?
                    <Form.Control as="textarea" rows={3} name="cancellationReason" value={cancellationReason} onChange={(event) => setCancellationReason(event.target.value)} autoComplete='new-password' />
                  :
                    null
                  }
              </Form.Group>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Grid container direction="row" justifyContent="space-between"  alignItems="center">
            <Grid item>
              <Button variant="secondary" size="sm" onClick={() => setShowCancelProviderBooking(false)} className="lightButtonOutlinedLarge">
                Close
              </Button>
            </Grid>
            {(showCancellationReason === 'N' && booking.paymentType !== 'NDIS') || (showCancellationReason === 'Y' && cancellationReason && cancellationReason.length > 5) || (booking.paymentType === 'NDIS' && cancellationReason && cancellationReason.length > 5)? 
              <>
                <Grid item>
                  <Button variant="warning" size="sm" onClick={() => cancelBooking(booking.id)}>
                    Cancel Booking &gt;
                  </Button>
                </Grid>
              </>
            :
              null
            }
          </Grid>
        </Modal.Footer>
      </Modal>

      <Modal show={showToDo} onHide={()=>setShowToDo(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>To Do - {toDoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          {toDoClientID && <ToDos clientID={toDoClientID} setShowToDo={setShowToDo}  clientType={props.clienttype} />}
        </Modal.Body>
      </Modal>


      <Modal show={showProvider} onHide={handleProviderClose} dialogClassName="invite-modal">
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>Available Providers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal">
          <Grid item md={12} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
            {providerType !== 'Supervision'  ?
              <Masonry
                breakpointCols={isMobile ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {psychologistsNew && psychologistsNew.map((row, index) => {
                  return (
                    <>
                      <Card className={classes.root} variant="outlined" key={index}>
                        <CardMedia
                          className={classes.media}
                          image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                          title="Psychologist"
                        />
                        <CardContent>
                          <Grid container justifyContent="space-between">
                            <Grid item sm={12}>
                              <Typography variant="h5" component="h3">
                                {row.fullname}  (Next available: {row.nextAvailableNew})
                              </Typography>
                            </Grid>
                          </Grid>
                          {(providerType !== 'GP' && providerType !== 'Provisional' && providerType !== 'Nurse' && providerType !== 'Social' && providerType !== 'Occupational') ?
                            <>
                              {currentClientPaymentType === 'Full Fee' ?
                                <>
                                  <Grid container style={{ marginTop: 10 }}>
                                    <Grid item sm={6} >
                                      <Typography variant="subtitle1" style={{ fontWeight:600 , marginTop: 11 }}>
                                        Out of Pocket Cost
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={6} style={{ textAlign: "right" }}>
                                      <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                        {row.hourlyRateOverrideGap && row.hourlyRateOverrideGap !== '0' ? row.hourlyRateOverrideGap : row.standardFeeOutOfPocket }
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay {row.hourlyrate} on the day 
                                      </Typography>
                                      <Typography>
                                        Receive back {row.hourlyRateOverrideRebate && row.hourlyRateOverrideRebate !== '0'  ? row.hourlyRateOverrideRebate : row.medicareRebate} from Medicare
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                                :
                                null
                              }
                              {currentClientPaymentType === 'Reduced Gap' ?
                                  <>
                                  {row.isReducedFee === 'Y' ? 
                                    <>
                                      <Grid container style={{ marginTop: 10 }}>
                                        <Grid item sm={6} >
                                          <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                            Out of Pocket Cost
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>
                                          <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                            {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                          { row.reducedFee === '0' ?
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay $0 on the day
                                            </Typography>
                                          :
                                            <>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay {row.reducedFee} on the day
                                              </Typography>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                        { row.reducedFee !== '0' ?
                                          <Grid item sm={12}>
                                            <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                              Note this provider offers some flexibility, depending on client circumstances
                                            </Typography>
                                          </Grid>
                                        :
                                          null
                                        }
                                      </Grid>
                                    </>
                                  :
                                    <>
                                      <Grid container style={{ marginTop: 10 }}>
                                        <Grid item sm={6} >
                                          <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                            Out of Pocket Cost
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: "right" }}>
                                          <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                            {row.reducedFeeOverrideGap && row.reducedFeeOverrideGap !== '0' ? row.reducedFeeOverrideGap : row.reducedFeeOutOfPocket }
                                          </Typography>
                                        </Grid>
                                        <Grid item sm={12} >
                                          { row.reducedFee === '0' ?
                                            <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                              You pay $0 on the day
                                            </Typography>
                                          :
                                            <>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                You pay {row.reducedFee} on the day
                                              </Typography>
                                              <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                                Receive back {row.reducedFeeOverrideRebate && row.reducedFeeOverrideRebate !== '0' ? row.reducedFeeOverrideRebate : row.medicareRebate} from Medicare
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    </>
                                  }
                                </>
                              :
                                null
                              }
                              <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                                {row.areasOfInterest.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}
                              </Grid>
                            </>
                          :
                            null
                          }
                          <Typography className={classes.pos} color="textSecondary">
                            <br />
                          </Typography>
                          <Grid container>
                            <Typography variant="body2" component="p">
                              <Interweave content={row.bio.substring(0,700)} />
                              {row.bio.length > 700 ?
                                <>
                                  ...
                                  <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                    more
                                  </Badge>
                                </>
                              :
                                null
                              }
                            </Typography>
                          </Grid>
                          <br />
                        </CardContent>
                        <CardActions disableSpacing>
                          {(currentClientPaymentType === 'Bulk Billed' && row.bulkBillingAvailable === '0') ?
                              <Button variant="secondary">
                                Not Available to Bulk Bill
                              </Button>
                            :
                              <Button onClick={()=>selectProvider(row)}>Select and Book</Button>
                            }
                          <IconButton
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: expanded,
                            })}
                            onClick={() => handleExpandClick(row.id)}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </CardActions>
                        <Collapse in={(expanded !== row.id)} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Typography variant="body2" component="div">  
                              {row.therapyTypes && row.therapyTypes.map((row) => {return (<Chip size="small" key={row} variant="outlined" label={row} style={{ margin: 2 }} />)})}      
                            </Typography>
                          </CardContent>
                        </Collapse> 
                      </Card>
                    </>
                  )
                })}
              </Masonry>
            :
              <Masonry
                breakpointCols={isMobile ? 1 : 3}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {psychologistsSupervision && psychologistsSupervision.map((row, index) => {
                  return (
                    <>
                      <Card className={classes.root} variant="outlined" key={index}>
                        <CardMedia
                          className={classes.media}
                          image={`https://${storageAccountName}.blob.core.windows.net/${containerName}/${row.imageURL}`}
                          title="Psychologist"
                        />
                        <CardContent>
                          <Grid container justifyContent="space-between">
                            <Grid item sm={12}>
                              <Typography variant="h5" component="h3">
                                {row.fullname}  (Next available: {row.nextAvailableSupervision})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: 10 }}>
                            <Grid item sm={8} >
                              <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                Cost
                              </Typography>
                            </Grid>
                            <Grid item sm={4} style={{ textAlign: "right" }}>
                              <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                {row.supervisorHourlyRate}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography className={classes.pos} color="textSecondary">
                            <br />
                          </Typography>
                          <Grid container>
                            <Typography variant="body2" component="p">
                              <Interweave content={row.supervisorBio.substring(0,700)} />
                              {row.supervisorBio.length > 700 ?
                                <>
                                  ...
                                  <Badge variant="secondary" onClick={()=>moreProviderBio(row.fullname, row.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                    more
                                  </Badge>
                                </>
                              :
                                null
                              }
                            </Typography>
                          </Grid>
                          <br />
                        </CardContent>
                        <CardActions disableSpacing>
                          <Button onClick={()=>selectProvider(row)}>Select and Book</Button>
                        </CardActions>
                        <CardContent>
                          <Typography variant="h6" component="h3">
                            Available For
                          </Typography>
                          <Row>
                            <List className={classes.root}>
                              {supervisionAreas.map(sRow => {
                                const labelId = `checkbox-list-label-${sRow.id}`;
                                var isChecked = row.supervisorAreas.includes(sRow.id);
                                return (
                                  <ListItem key={sRow.id} role={undefined} dense button >
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                      <Checkbox
                                        edge="start"
                                        checked={isChecked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                        disableTypography 
                                        primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                        className={classes.link} />
                                  </ListItem>
                                  );
                              })}
                            </List>
                          </Row>
                          <Typography variant="h6" component="h3" style={{paddingTop:20}}>
                            Endorsed In 
                          </Typography>
                          <Row>
                            <Col md="6">
                              <List className={classes.root}>
                                {endorsedSupervisionAreas.map(sRow => {
                                  var isChecked = row.supervisorAreas.includes(sRow.id);
                                  const labelId = `checkbox-list-label-${sRow.id}`;
                                  if (isChecked) {
                                    return(
                                      <ListItem key={sRow.id} role={undefined} dense button >
                                        <ListItemIcon style={{ minWidth: 40 }}>
                                          <Checkbox
                                            edge="start"
                                            checked={isChecked}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} style={{ fontSize: 12 }} 
                                            disableTypography 
                                            primary={<Typography className={isChecked ? classes.linkSelected : classes.link}>{sRow.issue}</Typography>} 
                                            className={classes.link} />
                                      </ListItem>
                                    )
                                  }
                                  else
                                  {
                                    return null
                                  }
                                })}
                              </List>
                            </Col>
                          </Row>
                        </CardContent>
                      </Card>
                    </>
                  )
                })}
              </Masonry>
            }
          </Grid>

        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleProviderClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
              Close
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  )
}