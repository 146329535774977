import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { DataGridPro } from '@mui/x-data-grid-pro';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

import { Paper, Grid, Typography } from '@material-ui/core';
import { format } from "date-fns";
import '../../_metronic/_assets/css/app.css';
import '../../_metronic/_assets/css/app2.css';

// import medipassSDK from '@medipass/partner-sdk';

export default function BookingBillingList(props) {

  const dispatch = useDispatch()
  // const isMobile = useMediaQuery('(max-width: 760px)');
  const bookingBilling = useSelector(state => state.admin.BookingBilling)

  useEffect( () => { dispatch(Admin.actions.fetchBookingBilling({ clientID : props.id })) }, [dispatch, props.id] )
  
  var cols = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'psychologistDate', type: 'date', headerName: 'Booking Date', flex: 1, 
      renderCell: (params) => (
        <div>
          <Typography><CopyIcon onClick={() => navigator.clipboard.writeText(format(new Date(params.value ? params.value : null), "dd-MM-yyyy"))} style={{marginRight : 10}}/> {format(new Date(params.value ? params.value : null), "dd-MM-yyyy")} </Typography>
        </div>
        )
    },
    { field: 'paymentType', headerName: 'Payment Type', flex: 0.7 },
    { field: 'status', headerName: 'Booking Status', flex: 0.7 }
]



  return (
    <>
      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item md={1} style={{ paddingTop: 8, paddingLeft: 4, color: "#646c9a" }}>
            <h4>Bookings</h4>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginLeft : 10}}>
          <Grid item md={3} xs={6} style={{ fontWeight: 600 }}>
            Client First Name:
          </Grid>
          <Grid item md={9} xs={6}>
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientFirstName)} style={{marginRight : 10}}/> {props.clientFirstName}
          </Grid>
          <Grid item md={3} xs={6} style={{ fontWeight: 600 }}>
            Client Preferred Name:
          </Grid>
          <Grid item md={9} xs={6}>
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientPreferredName)} style={{marginRight : 10}}/> {props.clientPreferredName}
          </Grid>
          <Grid item md={3} xs={6} style={{ fontWeight: 600 }}>
            Client Surname:
          </Grid>
          <Grid item md={9} xs={6}>
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientSurname)} style={{marginRight : 10}}/> {props.clientSurname} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            DOB:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientDOB)} style={{marginRight : 10}}/> {props.clientDOB} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Email:
          </Grid>
          <Grid item md={9} xs={6} > 
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientEmail)} style={{marginRight : 10}}/> {props.clientEmail} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Mobile:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientMobile)} style={{marginRight : 10}}/> {props.clientMobile} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Medicare No:
          </Grid>
          <Grid item md={9} xs={6} > 
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientMedicareNo)} style={{marginRight : 10}}/> {props.clientMedicareNo} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Order On Card:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientMedicareReferenceNo)} style={{marginRight : 10}}/> {props.clientMedicareReferenceNo} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Expiry:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientMedicareExpiryDate)} style={{marginRight : 10}}/> {props.clientMedicareExpiryDate} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Referring GP:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientreferringGPName)} style={{marginRight : 10}}/> {props.clientreferringGPName} 
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Provider No:
          </Grid>
          <Grid item md={9} xs={6} >
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientreferringGPProviderNo)} style={{marginRight : 10}}/> {props.clientreferringGPProviderNo}
          </Grid>
          <Grid item md={3} xs={6}  style={{ fontWeight: 600 }}>
            Referred On:
          </Grid>
          <Grid item md={9} xs={6} style={{marginBottom: 30}}>
          <CopyIcon onClick={() => navigator.clipboard.writeText(props.clientreferredOn)} style={{marginRight : 10}}/> {format(new Date(props.clientreferredOn ? props.clientreferredOn : null), "dd-MM-yyyy")} 
          </Grid>

        </Grid>
      </Paper>

      <Paper style={{ height: 500 }}>
        <DataGridPro
          rows={bookingBilling} 
          columns={cols} 
          pagination={false}
          checkboxSelection={false} 
          sortModel={[{ field: 'psychologistDate', sort: 'desc' }]} 
        />
      </Paper>
    </>
  )
}



// thisBookingBilling.id = (int)dr["BookingID"];
// thisBookingBilling.status = (dr["Status"]).ToString();
// thisBookingBilling.paymentType = dr["PaymentType"] == null ? "" : (dr["PaymentType"]).ToString();
// thisBookingBilling.psychologistDate = DateTime.Parse((dr["PsychologistBookingDate"]).ToString());
// thisBookingBilling.referringGPName = dr["GPName"].ToString();
// thisBookingBilling.referringGPProvderNo = dr["ProviderNumber"].ToString();
// thisBookingBilling.referredOn = dr["ReferredOn"].ToString();
