import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Grid, TextField, InputLabel, makeStyles } from '@material-ui/core';
import { Button, Modal } from "react-bootstrap";
import * as Admin from "../store/ducks/admin.duck";
import * as Reference from "../store/ducks/reference.duck";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../_metronic/_assets/css/app.css';
import '../../_metronic/_assets/css/app2.css';

export function BookingWeek(props) {

  // process.env.REACT_APP_DEBUG && console.log(props.inviteType)
  
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchMatching({providertype: props.providerType, invite : false, availabilitytype : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting' : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType==='Supervision' ? 'Supervision' : 'Existing'})) }, [dispatch, props.providerType,props.paymentType] )  
  useEffect( () => { setSinglePsychologist() } )  
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )

  const startingSunday = useSelector(state => state.reference.SundayThisWeek)
  const matching = useSelector(state => state.admin.Matching)
  const matchingAvailability = useSelector(state => state.admin.MatchingAvailability)
  const timeZone = useSelector(state => state.reference.TimeZone)
  var [currentPsychologistID, setcurrentPsychologistID] = useState();
  var [currentHourlyRate, setcurrentHourlyRate] = useState();
  var [currentPsychologistFullname, setcurrentPsychologistFullname] = useState();
  var [currentWaitingRoomURL, setcurrentWaitingRoomURL] = useState();
  var [currentPsychologistTimeZone, setcurrentPsychologistTimeZone] = useState();
  var [displayTZ, setDisplayTZ] = useState('psychologist');
  var [psychologistSet, setPsychologistSet] = useState(false)

  // console.log(props)

  // in psychologist mode, we do not select one, but set it up from props
  const setSinglePsychologist = () => {
    if(props.mode === 'psychologist' && props.psychologistID && !psychologistSet)
    {
      // setSinglePsychologist
      // Determine providertype 
      if (props.providerType === 'GP')
      {
        setcurrentPsychologistID(props.gpID);
        setcurrentPsychologistFullname(props.gpFullname);
        setcurrentWaitingRoomURL(props.gpcurrentWaitingRoomURL);
        // These two are not in use - both TBA
        setcurrentHourlyRate(props.currentHourlyRate);
        setcurrentPsychologistTimeZone(props.psychologistTZ);
        dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: props.gpID, userTZ: displayTZ === 'psychologist' ? props.psychologistTZ : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting'  : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : false}))
      }
      else
      {
        setcurrentPsychologistID(props.psychologistID);
        setcurrentPsychologistFullname(props.psychologistFullname);
        setcurrentWaitingRoomURL(props.currentWaitingRoomURL);
        // These two are not in use - both TBA
        setcurrentHourlyRate(props.currentHourlyRate);
        setcurrentPsychologistTimeZone(props.psychologistTZ);
        dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: props.psychologistID, userTZ: displayTZ === 'psychologist' ? props.psychologistTZ : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting'  : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : false}))
      }
      //
      setPsychologistSet(true)
    }

    if(props.mode === 'client' && props.psychologistID && !psychologistSet)
    {
      // Client Setting;
      if (props.providerType === 'GP')
      {
        setcurrentPsychologistID(props.gpID);
        setcurrentPsychologistFullname(props.gpFullname);
        setcurrentWaitingRoomURL(props.gpcurrentWaitingRoomURL);
        // These two are not in use - both TBA
        setcurrentHourlyRate(props.currentHourlyRate);
        setcurrentPsychologistTimeZone(props.psychologistTZ);
        dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: props.gpID, userTZ: displayTZ === 'client' ? props.psychologistTZ : props.clientTZ, availabilityType : 'Existing', invite : props.inviteType && props.inviteType === 'client' ? true : false }))        
      }
      else
      {
        setcurrentPsychologistID(props.psychologistID);
        setcurrentPsychologistFullname(props.psychologistFullname);
        setcurrentWaitingRoomURL(props.currentWaitingRoomURL);
        // These two are not in use - both TBA
        setcurrentHourlyRate(props.currentHourlyRate);
        setcurrentPsychologistTimeZone(props.psychologistTZ);
        dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: props.psychologistID, userTZ: displayTZ === 'client' ? props.psychologistTZ : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting' : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : props.inviteType && props.inviteType === 'client' ? true : false }))
      }      
      //
      setDisplayTZ('client');
      setPsychologistSet(true)
      // console.log('setSinglePsychologist')
    }
  }

  // in admin mode, we handle the selection of psychologists
  function handleMatchingAutocompleteChange(propertyName, event, newValue) {   
    // console.log(newValue);     
    if (newValue) {
      setcurrentPsychologistID(newValue.id)
      setcurrentPsychologistFullname(newValue.firstname + ' ' + newValue.surname)
      setcurrentHourlyRate(newValue.hourlyrate)
      setcurrentWaitingRoomURL(newValue.waitingroomurl)
      setcurrentPsychologistTimeZone(newValue.timezone)
      dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: newValue.id, userTZ: displayTZ === 'psychologist' ? newValue.timezone : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting' : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : false }))
    }
  }

  function changeDisplayTZ(value) {
    if(props.mode === 'client')
    {
      currentPsychologistID && dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: currentPsychologistID, userTZ: value === 'client' ? currentPsychologistTimeZone : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting' : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : false }));
    }
    else
    {
      currentPsychologistID && dispatch(Admin.actions.fetchMatchingAvailability({ psychologistID: currentPsychologistID, userTZ: value === 'psychologist' ? currentPsychologistTimeZone : props.clientTZ, availabilityType : props.specialistType === 'nthqldphn' ?  'NthQLDPHNExisting' : props.paymentType === 'NDIS' || props.paymentType === 'EAP' || props.paymentType === 'Contract' ? 'NDISContractEAP' : props.providerType === 'Supervision' ? 'Supervision' : 'Existing', invite : false }));
    }
    setDisplayTZ(value)
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: 4
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    dayCol : {
      padding: 2,
    }
  }));

  var [startDate, setStartDate] = useState(new Date(startingSunday))
  var [endDate, setEndDate] = useState(new Date(startingSunday))
  var [nextWeek, setNextWeek] = useState('');
  var [prevWeek, setPrevWeek] = useState('');
  var [weekdays, setWeekDays] = useState([]);

  function makeWeek (newDateStr) {
      var thisStartDate = new Date(newDateStr);
      setStartDate(new Date(newDateStr))

      var endDate = new Date(thisStartDate)
      endDate.setDate(endDate.getDate() + 6);
      setEndDate(new Date(endDate))

      var tempDays = []
      // set start date one day back so we can 
      thisStartDate.setDate(thisStartDate.getDate()  )

      var i = 1
      while(i <= 7) {
        tempDays.push(new Date(thisStartDate.setDate(thisStartDate.getDate() + 1 )));
        i++;
      }
      
      var backWeek = new Date(newDateStr)
      backWeek.setDate(backWeek.getDate() - 7); 
      setPrevWeek(formatDateString(backWeek))
      
      var nextWeek = new Date(newDateStr)
      nextWeek.setDate(nextWeek.getDate() + 7);  
      setNextWeek(formatDateString(nextWeek))   

      setWeekDays(tempDays);
  }

  function formatDateString (dateInput) {
    const o_date = new Intl.DateTimeFormat('au');
    const f_date = (m_ca, m_it) => Object({...m_ca, [m_it.type]: m_it.value});
    const m_date = o_date.formatToParts(dateInput).reduce(f_date, {});
    return m_date.year + '-' +  (m_date.month < 10 ? '0' + m_date.month : m_date.month) + '-' + m_date.day;
  }

  useEffect( () => { initWeek() }, )  

  function initWeek() {
    if(startingSunday.length && weekdays.length === 0) {
      makeWeek(startingSunday)
    }
  }

  const classes = useStyles();

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  function getDateRangeLabel() {
    if (startDate.getYear() === endDate.getYear()) {  
      return (
        <>
        {startDate ? startDate.getDate() : ''}-{startDate ? endDate.getDate() : ''} {startDate ? monthNames[endDate.getMonth()] : ''}
        </>
      )
    } else {
      return (
        <>
        {startDate ? startDate.getDate() : ''} {startDate ? monthNames[startDate.getMonth()] : ''} - {startDate ? endDate.getDate() : ''} {startDate ? monthNames[endDate.getMonth()] : ''}
        </>
      )
    }
  }

  const [showNewBooking, setShowNewBooking] = useState(false);
  const [newBookingProps, setNewBookingProps] = useState({})
  const [showBookingError, setShowBookingError] = useState(false);

  const startNewBooking = (booking) =>{
    setShowNewBooking(true)
    setNewBookingProps({ mode: booking.mode, day: booking.day, hour: booking.hour, psychologistID: booking.psychologistID, paymentType: booking.paymentType, bookingType: booking.bookingType, clientID: booking.clientID })
  }

  const saveAppointment = (newBookingProps) => {
    // Need to consider this further - need to support removing appointments as well 
    // console.log(newBookingProps)
    var newbookingdate = newBookingProps.day + newBookingProps.hour.substring(0,5)
    if (newBookingProps.mode === 'client')
    {
      // Check if date within 48 hours as clients cannot book Psychologists within next 48 hours 
      var twoDaysInFuture = new Date();
      twoDaysInFuture.setHours(twoDaysInFuture.getHours() + 48);
      var booking_date = new Date(newbookingdate);
      if (booking_date.getTime() < twoDaysInFuture.getTime())
      {
        // Bookings must be at least 48 hours in advance
        // console.log('Too Soon');
        setShowNewBooking(false);
        setShowBookingError(true);
      }
      else
      {
        dispatch(Admin.actions.bookAppointment({ 
          timezone: displayTZ === 'psychologist' ? currentPsychologistTimeZone : props.clientTZ, 
          newbookingdate: newbookingdate, 
          day: newBookingProps.day, 
          hour: newBookingProps.hour, 
          clientID: newBookingProps.clientID, 
          psychologistID: newBookingProps.psychologistID, 
          psychologistname : newBookingProps.psychologistname, 
          waitingRoomURL: newBookingProps.waitingRoomURL, 
          clientFullName : newBookingProps.clientFullName, 
          clientFirstName : newBookingProps.clientFirstName, 
          clientPreferredName: newBookingProps.clientPreferredName,
          clientEmail : newBookingProps.clientEmail,  
          paymentType: newBookingProps.paymentType,  
          bookingType: newBookingProps.bookingType,
          refreshPayload: { key: 'client', id: newBookingProps.clientID } }))
        setShowNewBooking(false)
        props.setShowAppointment && props.setShowAppointment(false)
        props.setMakeBooking(false)
      }
      // Update future booking status 
      dispatch(Admin.actions.fetchClientFutureBookingStatus( { providertype : props.providerType }))
    }
    else
    {
      dispatch(Admin.actions.bookAppointment({ 
        timezone: displayTZ === 'psychologist' ? currentPsychologistTimeZone : props.clientTZ, 
        newbookingdate: newbookingdate, 
        day: newBookingProps.day, 
        hour: newBookingProps.hour, 
        clientID: newBookingProps.clientID, 
        psychologistID: newBookingProps.psychologistID, 
        psychologistname : newBookingProps.psychologistname, 
        waitingRoomURL: newBookingProps.waitingRoomURL, 
        clientFullName : newBookingProps.clientFullName, 
        clientFirstName : newBookingProps.clientFirstName, 
        clientPreferredName: newBookingProps.clientPreferredName,
        clientEmail : newBookingProps.clientEmail, 
        paymentType: newBookingProps.paymentType,  
        bookingType: newBookingProps.bookingType, 
        refreshPayload: { key: 'psychologist', id: newBookingProps.psychologistID } }))
        setShowNewBooking(false)
        props.setShowAppointment && props.setShowAppointment(false)
    }
  }

  return (
    <>
    <div className={classes.root}>
      <Grid container spacing={2}>
        {(props.mode === 'admin') ?
            <Grid item xs={6} md={6}>
              <InputLabel>
                {props.providerType === 'GP' ? 
                  <>
                    GP Selection
                  </>  
                :
                  <>
                  {props.providerType === 'Financial' ?
                    <>
                      Financial Counsellor Selection
                    </>  
                  :
                    <>
                      Psychologist Selection
                    </>
                  }
                  </>
                }
              </InputLabel>
              <Autocomplete
                id="MatchingID"
                variant="outlined"
                options={matching}
                getOptionLabel={(option) => option.oneline || ''}
                value={matching.find(v => v.id === currentPsychologistID) || undefined}
                onChange={(event, newValue) => handleMatchingAutocompleteChange('MatchingID', event, newValue)}
                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                renderInput={(params) => 
                  <TextField 
                    {...params} 
                    type="text"
                    variant="outlined"
                    margin="dense"
                    name="MatchingSelector"
                    multiline={true}
                    style={{backgroundColor: "white", margin: 0, padding: 0}}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password'}}
                  />}
              />
            </Grid>
          : 
            null 
        }
      </Grid>
      <Grid container direction="row" justifyContent='space-between' spacing={2} style={{ marginTop: 8 }}>
        <Grid item md={5}>
          <Button variant="light" onClick={() => {makeWeek(startingSunday)}} style={{ marginRight: 10 }}>Today</Button>
          <Button variant="light" onClick={() => {makeWeek(prevWeek)}} style={{ marginRight: 10 }} alt="here"> &lt; </Button>
          <Button variant="light" onClick={() => {makeWeek(nextWeek)}} style={{ marginRight: 10 }}> &gt; </Button>
          {getDateRangeLabel()}
        </Grid>
        {props.mode !== 'client' ?
          <Grid item md={7}>
            {currentPsychologistTimeZone !== props.clientTZ ?
              <>
                <Button variant='light' className={displayTZ === 'psychologist' ? 'selected-tz' : null} onClick={() => {changeDisplayTZ('psychologist')}} style={{ marginRight: 10 }}>
                 {props.providerType === 'GP' ? 
                    <>
                      GP:
                    </>  
                  :
                    <>
                    {props.providerType === 'Financial' ?
                      <>
                        Fin:
                      </>  
                    :
                      <>
                        Psy:
                      </>
                    }
                    </>
                  }
                  {currentPsychologistTimeZone && timeZone.find(row => row.systemName === currentPsychologistTimeZone).name}
                </Button>
                <Button variant='light' className={displayTZ === 'client' ? 'selected-tz' : null} onClick={() => {changeDisplayTZ('client')}} style={{ marginRight: 10 }}>
                  Client: {props.clientTZ && timeZone.find(row => row.systemName === props.clientTZ).name}
                </Button>
              </>
            : 
              <Button variant='light' className="selected-tz" style={{ marginRight: 10 }}>
                Psy/Client: {currentPsychologistTimeZone && timeZone.find(row => row.systemName === currentPsychologistTimeZone).name}
              </Button>
            }
          </Grid>
        :     
          <>
            <Grid item md={5}>
              {props.providerType === 'GP' ? 
                  <h4>Availability for GP: {currentPsychologistFullname}</h4>
              :
                <>
                {props.providerType === 'Financial' ?
                    <h4>Availability for Financial Counsellor : {currentPsychologistFullname}</h4>
                :
                  <>
                    {props.providerType === 'Supervision' ?
                      <h4>Availability for Supervising Psychologist : {currentPsychologistFullname}</h4>
                    :
                      <h4>Availability for Psychologist : {currentPsychologistFullname}</h4>
                    }
                  </>
                }
                </>
              }
            </Grid>
            <Grid item md={2} style={{ textAlign: "right"}}>
              <Button variant='light' onClick={() => props.setMakeBooking(false)} style={{ textAlign: "right", marginRight: 10 }}>
                  Cancel
                </Button>
            </Grid>
            <Grid item md={12} style={{ textAlign: "center", fontWeight:600 }}>
                  <p>If your psychologist does not have any availability showing but you are an existing client, they will book you in. Please send them a message and if you need further help, please contact Rural Heath Connect admin via the phone numbers on our website</p>
                </Grid>

          </>    
          
          
        }
      </Grid>
{/*  */}
    <Grid container direction="row" spacing={2}>
      {weekdays.map(thisDay => (
        <Grid item xs className={classes.dayCol} key={thisDay}>
          <Paper className={classes.paper}>
            <BookingDay matchingAvailability={matchingAvailability} mode={props.mode} day={thisDay} clientID={props.clientID} clientFullName={props.clientFullname} bookingType={props.bookingType} paymentType={props.paymentType} currentPsychologistID={currentPsychologistID ? currentPsychologistID : 0} startNewBooking={startNewBooking} />
          </Paper>            
        </Grid>
       ))}
      
    </Grid>
    </div>
    
    <Modal show={showNewBooking} onHide={()=>setShowNewBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>New Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              Psychologist:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {currentPsychologistFullname} 
            </Grid>
            <Grid item md={3} xs={6}>
              Client:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {props.clientFullname} 
            </Grid>
            <Grid item md={3} xs={6}>
              Date:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {newBookingProps.day && newBookingProps.day.substring(0,10)}
            </Grid>
            <Grid item md={3} xs={6}>
              Time:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {newBookingProps.hour && newBookingProps.hour.substring(0,5)}
            </Grid>
            <Grid item md={3} xs={6}>
              Booking Type:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {newBookingProps.bookingType && newBookingProps.bookingType === 'FACE' ? 'Face to Face' : 'Video'}
            </Grid>
            {newBookingProps.mode !== 'client' ?
                <>
                  <Grid item md={3} xs={6}>
                    Cost:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {currentHourlyRate}
                  </Grid>
                  <Grid item md={3} xs={6}>
                    Payment Type:
                  </Grid>
                  <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                    {props.paymentType}
                  </Grid>
                </>
              :
                null
            }
            {/* <Grid item md={3} xs={6}>
              Session URL:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {currentWaitingRoomURL}
            </Grid> */}
          </Grid>
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Button variant="secondary" onClick={() => setShowNewBooking(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
          <Button variant="primary" onClick={() => saveAppointment({...newBookingProps, psychologistID: currentPsychologistID, psychologistname : currentPsychologistFullname , paymentType : props.paymentType, waitingRoomURL: currentWaitingRoomURL, clientFirstName : props.clientFirstName, clientPreferredName : props.clientPreferredName, clientEmail : props.clientEmail})}>
            Book Now
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBookingError} onHide={()=>setShowBookingError(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          <Modal.Title>Warning - Booking Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container spacing={2}>
            <Grid item md={9} xs={6}  style={{ fontWeight: 600 }}>
              Bookings must be at least 48 hours in Advance
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Button variant="secondary" onClick={() => setShowBookingError(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

function getActualStartTime(props,thisHour,currentDate)
{
  // Determine actual time for saving appointment
  const founditem = props.matchingAvailability.find(timeblock => timeblock.fromDate.toString().substring(0,13) === (currentDate + thisHour.substring(0,2)));
  return(founditem.fromDate.toString().substring(11,16))
}

function getActualStartTimeDisplay(props,thisHour,currentDate)
{
  // Determine descriptor to show in calendar 
  const founditem = props.matchingAvailability.find(timeblock => timeblock.fromDate.toString().substring(0,13) === (currentDate + thisHour.substring(0,2)));
  if (props.mode === 'client')
  {
    return('Book Session @' + founditem.fromDate.toString().substring(11,16))
  }
  else
  {
    return(founditem.fromDate.toString().substring(11,16))
  }
}

function getAvailabilityTypeForTimeBlock(props,thisHour,currentDate)
{
  // Determine availability type of slot - only used in the Face to Face to change the booking button colour
  const founditem = props.matchingAvailability.find(timeblock => timeblock.fromDate.toString().substring(0,13) === (currentDate + thisHour.substring(0,2)));
  return(founditem.availabilityType)
}

function getBookingTypeForTimeBlock(props,thisHour,currentDate)
{
  // Determine bookingType based on availability type of slot - only used in Face to Face bookings 
  const founditem = props.matchingAvailability.find(timeblock => timeblock.fromDate.toString().substring(0,13) === (currentDate + thisHour.substring(0,2)))
  //
  if (founditem.availabilityType ==='NthQLDPHNFacetoFace')
  {
    return('FACE')
  }
  else
  {
    return('VIDEO')
  }
}


function BookingDay(props) {

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: 4
    },
    hourItem : {
      borderBottom: "solid 1px silver",
      textAlign: "left"
    },
    slotItem : {
      backgroundColor: "rgb(254, 255, 204)",
      borderBottom: "solid 1px yellow",
      textAlign: "left"
    },
    dayHeading : {
      borderBottom: "solid 1px silver",
      fontWeight: 600,
      fontSize: 14,
      minHeight: 45
    }
  }));

  const classes = useStyles();
  var days = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var hours = ['06:00:00','07:00:00','08:00:00','09:00:00', '10:00:00', '11:00:00', '12:00:00', '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00', '19:00:00', '20:00:00', '21:00:00', '22:00:00']
  var currentDate = props.day.getFullYear() + '-' + ((props.day.getMonth()+ 1)<10?'0'+(props.day.getMonth()+1):props.day.getMonth()+1) + '-' + (props.day.getDate()<10?('0'+props.day.getDate()):props.day.getDate()) + 'T';
  
  var today = new Date();
  var isToday = (today.toDateString() === props.day.toDateString())
  var twoDaysInFuture = new Date();
  twoDaysInFuture.setHours(twoDaysInFuture.getHours() + 48);
  
  // 
  // console.log(props.matchingAvailability.find(timeblock => timeblock.fromDate.toString() === (currentDate + '07:00')));
  return(
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>

        <Grid item xs className={classes.dayHeading}>
          <div className="bookingsDayHeader">
            <p className={isToday ? "bookingsDayNameToday" : "bookingsDayName"}>{days[props.day.getDay()]}</p>
            <div className={isToday ? "bookingsDayNumberToday" : "bookingsDayNumber"}>{props.day.getDate()}</div>
          </div>           
        </Grid>

        {hours.map(thisHour => (
          props.matchingAvailability ? 
          (props.matchingAvailability.find(timeblock => timeblock.fromDate.toString().substring(0,13) === (currentDate + thisHour.substring(0,2)))) ? 
          (
            <Grid item xs className={classes.slotItem} key={getActualStartTime(props,thisHour,currentDate)} onClick={() => props.startNewBooking({ mode: props.mode, day: currentDate, hour: getActualStartTime(props,thisHour,currentDate), psychologistid: props.currentPsychologistID, paymentType: props.paymentType, bookingType: props.bookingType, bookingType: getBookingTypeForTimeBlock(props,thisHour,currentDate), clientID: props.clientID })}>
              <Button size="sm" style={{ width: "100%", backgroundColor : getAvailabilityTypeForTimeBlock(props,thisHour,currentDate) === 'NthQLDPHNFacetoFace' ? '#ffa384'  : '#384ad7' }}>
                {getActualStartTimeDisplay(props,thisHour,currentDate)}
              </Button>
            </Grid>
          )
          : (
            <Grid item xs className={classes.hourItem} key={thisHour.substring(0,5)}>
              <p>{thisHour.substring(0,5)}</p>           
            </Grid>
          )
          : (
            <Grid item xs className={classes.hourItem}>
              <p>{thisHour.substring(0,5)}</p>           
            </Grid>
          )
        ))}

      </Grid>
    </div>
  )



}


