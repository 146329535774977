import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core';

export default function APIKeyForm(props) {
    
  const dispatch = useDispatch()
   
  var [APIKey, setAPIKey] = useState()
  var [apiKeyHelpText, setapiKeyHelpText] = useState('')


  function addAPIKey() {
    // Determine if the criteria has been made 
    // Check if note has been entered (note is not required for Fax)
    if ((APIKey.length === 0))
    {
      setapiKeyHelpText("Please enter your Tyro APIKey");
    }
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(APIKey);
    dispatch(Admin.actions.putAPIKey({ apikey:APIKey, pkID: props.referenceID}));
    props.onCloseAPIKey();
  }

  //  const useStyles = makeStyles((theme) => ({
  //   root: {
  //     // minWidth: 200,
  //     // maxWidth: 200,
  //   },
  //   media: {
  //     height: 200,
  //   },
  //   title: {
  //     fontSize: 14,
  //   },
  //   pos: {
  //     marginBottom: 12,
  //   },
  // }));
    
  // const classes = useStyles();

  return(
    <Grid item md={12}>  
      <Row>
        <Col md="12">                  
          <Form.Group >
              <Form.Label>API Key</Form.Label>
              <Form.Control type="text" name="apikey" defaultValue={APIKey} onBlur={(event) => setAPIKey(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
              {apiKeyHelpText.length ? (<p className='error-help'>{apiKeyHelpText}</p>) : ''}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Grid container spacing={0} padding={6} justifyContent="space-between">
            <Button variant="light" onClick={()=>props.onCloseAPIKey()}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={() => addAPIKey()}>
              Save
            </Button>
          </Grid>
        </Col>
      </Row>
    </Grid>
  )


}
  
