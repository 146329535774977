
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Reference from "../../../store/ducks/reference.duck";
import { Paper, Grid } from '@material-ui/core';
import { Button } from "react-bootstrap";
import { saveAs } from 'file-saver';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';
import * as Admin from "../../../store/ducks/admin.duck";

export default function AdminReporting() {

  // const classes = useStyles();
  const dispatch = useDispatch();  
  const powerBIXlsx = useSelector(state => state.admin.PowerBIXlsx);
  const [exportExcelStatus, setExportExcelStatus] = useState('Not Started')


  function runETL() {
    dispatch(Reference.actions.runETL())
  }

  // initial call from the download button - fires fetch action if needed, otherwise triggers download 
  const exportFile = () => {
    if(exportExcelStatus === 'Not Started')
    {
      dispatch(Admin.actions.fetchPowerBIXlsx())
      setExportExcelStatus('Fetching')
    }
  }    

  // grab the blob from state and tell the browser to download
  const downloadExport = useCallback(() => {
    if (powerBIXlsx && powerBIXlsx !== '')
    {
      const file = new Blob([Buffer.from(powerBIXlsx, 'base64')], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'})
      saveAs(file, 'RHC-PowerBIData.xlsx');
    }
  },[powerBIXlsx])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect( () => {
    if(exportExcelStatus === 'Fetching' && powerBIXlsx && powerBIXlsx !== '')
    {
      downloadExport()
      setExportExcelStatus('Not Started')
    }
  }, [exportExcelStatus, powerBIXlsx,downloadExport])


  return (
    <div className='container-fluid'>
      <h4>
          Reporting
      </h4>     
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10, marginTop: 20 }}>		
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => {runETL()}}>
              Run ETL
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Grid container justifyContent="space-between" style={{ padding: 10, marginTop: 20 }}>		
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => exportFile()}>
            {exportExcelStatus === 'Fetching' ? 'Building....' : 'Download Power BI File'}
            </Button>
          </Grid> 
        </Grid>
      </Paper>

      <ToastContainer
          transition={Flip}
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )

}

// const useStyles = makeStyles((theme) => ({
// 	formControl: {
// 	  margin: 0,
// 	  minWidth: 120,
// 	},
// 	formControlWide: {
// 	  margin: 0,
// 	  minWidth: '100%',
// 	},
// 	selectEmpty: {
// 	  marginTop: theme.spacing(2),
// 	},
// 	label: {
// 	  backgroundColor: '#fff',
// 	  paddingLeft: 3,
// 	  paddingRight: 3,
// 	}
//   }));
  