import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "react-bootstrap";
import { Grid as MUIGrid } from '@material-ui/core';
import { process } from '@progress/kendo-data-query';
import * as Admin from "../../../store/ducks/admin.duck";
import { Modal } from "react-bootstrap";
import { ToastContainer, Flip, toast } from "react-toastify";
import InvoiceDetail from '../../../components/InvoiceDetail';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

import medipassSDK from '@medipass/partner-sdk';
import medipassTransactionSDK from '@medipass/partner-sdk';
import { formatDate } from '@telerik/kendo-intl';

export default function InvoicesPsychologist() {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchPaymentAPI()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedipassInvoices()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  const paymentAPI = useSelector(state =>  state.admin.PaymentAPI)
  const medipassInvoices = useSelector(state => state.admin.MedipassInvoices)

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [{
      field: 'invoicedate',
      dir: 'desc'
    }],
  });

  const [dataResult, setDataResult] = useState(process(medipassInvoices, dataState)); 
  const [prevMedipassInvoices, setPrevMedipassInvoices] = useState();
  const [currentMedipassInvoices, setCurrentMedipassInvoices] = useState();  
  

  var [show, setShow] = useState(false);
  var [currentInvoiceID, setCurrentInvoiceID] = useState();
  var [currentClientID, setCurrentClientID] = useState();
  var [currentClientName, setCurrentClientName] = useState(); 
  var [currentClientSuburb, setCurrentClientSuburb] = useState();
  var [currentClientSelect, setCurrentClientSelect] = useState(false);
  var [currentInvoiceItems, setCurrentInvoiceItems] = useState();
  var [currentInvoiceEditable, setCurrentInvoiceEditable] = useState(true);
  var [currentLineNo,setCurrentLineNo] = useState(0)

  useEffect( () => {
    if ((JSON.stringify(medipassInvoices) !== JSON.stringify(prevMedipassInvoices)) && medipassInvoices.length > 0)
    {
      setPrevMedipassInvoices(medipassInvoices)
      setCurrentMedipassInvoices(medipassInvoices)
      setDataResult(process(medipassInvoices, dataState))
    }
  }, [prevMedipassInvoices, currentMedipassInvoices, medipassInvoices, dataResult, dataState])

  
  function addNew() 
  {
    setCurrentInvoiceID(0);
    setCurrentClientID(0);
    setCurrentClientSelect(false);
    setCurrentInvoiceItems([]);
    setCurrentInvoiceEditable(true);
    setShow(true);
  }
    
  function handleClose() {
    setShow(false);
  }  

  const dataStateChange = event => {
    setDataResult(process(medipassInvoices, event.dataState));
    setDataState(event.dataState);
  };

  const DateFormatter = dataItem => {
    const value = dataItem.dataItem[dataItem.field];
    return(
      <td>
        {formatDate(new Date(value),'dd-MM-yyyy')}
      </td>
    )
  }

  const medipassSubmit = dataItem => {
    //
    // console.log('In Tyro Submit');
    // console.log(paymentAPI);
    // process.env.REACT_APP_DEBUG && 
    // console.log(dataItem);
    // Need to sort out the ReferredOn date 
    var tempReferredOn = "";
    if (dataItem.referral_issueDateString)
    {
      tempReferredOn = dataItem.referral_issueDateString;
      tempReferredOn = tempReferredOn.split(' ')[0];
      // console.log(tempReferredOn);
    }
    // Build JSON array of claimable items 
    let claimItems = [];
    for (var i = 0, l = dataItem.invoiceitems.length; i < l; i++) {
      // Add to array
      claimItems.push(
        {
          itemCode: dataItem.invoiceitems[i].code,
          reference: dataItem.invoiceitems[i].id.toString(),
          serviceDateString: dataItem.invoiceitems[i].servicedate,
          price: '$' + dataItem.invoiceitems[i].cost,
          isGross: true,
          quantity: 1,
          isTaxable: false,
        } 
      );
    }
    // console.log(claimItems);
    // Setup connection 
    // Note the env variable below should be set to 
    // env : 'stg',    for Tyro staging 
    // env : 'prod',   for Tyro prod 
    // Check if using test psychologist 
    if (registered && registered.id === 14)
    {

      // Ok using test psychologist - thus envionrment is staging 
      medipassSDK.setConfig({
        env: 'stg',
        apiKey: paymentAPI.secureapi,
        appId: 'ruralhealthconnect-web',
        appVersion: '2021Oct'
      });
    }
    else
    {
      medipassSDK.setConfig({
        env: 'prod',
        apiKey: paymentAPI.secureapi,
        appId: 'ruralhealthconnect-web',
        appVersion: '2021Oct'
      });
    }
    // Call Funder Modal 
    // Welcome to the world of not great solutions - need to stop sending referral information if blank
    if (dataItem.referral_providerNumber)
    {
      medipassSDK.renderCreateTransaction({}, {
        funder: "medicare",
        providerNumber: paymentAPI.providernumber,
        invoiceReference: 'RHC' + dataItem.id,

        patient: {
          refId: dataItem.clientid.toString(),
          firstName: dataItem.clientfirstname.trim(),
          lastName: dataItem.clientsurname.trim(),
          mobile: dataItem.clientmobilephone,
          dob: dataItem.clientdob,
          accountNumber: dataItem.clientmedicareno,
          reference: dataItem.clientmedicarereferenceno,
          sex: dataItem.clientgender
        },

        claimableItems: claimItems,

        funderData: {
          medicare: {
            referral: {
              providerNumber: dataItem.referral_providerNumber,
              providerName: dataItem.referral_providerName,
              referrerType: dataItem.referral_referrerType,
              issueDateString: tempReferredOn,
              period: dataItem.referral_period,
              periodDescription: dataItem.referral_periodDescription
            },
          }
        },
        
        webhooks: [
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/creation',
            event: 'invoiceCreated' ,
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/balpaid',
            event: 'invoiceBalancePaid',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/cancelled',
            event: 'invoiceCancelled',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/completed',
            event: 'invoiceCompleted',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/approved',
            event: 'healthFundApprovedInvoice',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/rejected',
            event: 'healthFundRejectedInvoice',
            method: 'POST',
          },
        ],

        disableModifyServiceItems: true,

        // Production webhooks 

        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/creation',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/cancelled',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/completed',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/approved',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/rejected',

        // Test Webhooks via Logic App to capture the packet 
        // url: 'https://prod-09.australiasoutheast.logic.azure.com:443/workflows/5bbaefa6a9844816b8c4b625bda66a9b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=A-6rwRycc1VL6tNtqIPGCrdv4YXsxmyaJtabBugo-xA',

        onSuccess: function (transaction) {
          // handle successful submission of transaction
          // Toast time
          toast.success("Claim Submitted",{ autoClose:5000 });
          process.env.REACT_APP_DEBUG && console.log(transaction);
        },
        onError: function (error) {
          // handle errored submission of transaction
          toast.error("`Claimed Not Submitted",{ autoClose:5000 });
          process.env.REACT_APP_DEBUG && console.log(error);
        },
        onCancel: function () {
          // handle when create transaction flow has been cancelled
          // cancelled - do nothing
        }
      });
    }
    else
    {
      medipassSDK.renderCreateTransaction({}, {
        funder: "medicare",
        providerNumber: paymentAPI.providernumber,
        invoiceReference: 'RHC' + dataItem.id,

        patient: {
          refId: dataItem.clientid.toString(),
          firstName: dataItem.clientfirstname.trim(),
          lastName: dataItem.clientsurname.trim(),
          mobile: dataItem.clientmobilephone,
          dob: dataItem.clientdob,
          accountNumber: dataItem.clientmedicareno,
          reference: dataItem.clientmedicarereferenceno,
          sex: dataItem.clientgender
        },

        claimableItems: claimItems,

        // funderData: {
        //   medicare: {
        //     referral: {
        //       providerNumber: dataItem.referral_providerNumber,
        //       providerName: dataItem.referral_providerName,
        //       referrerType: dataItem.referral_referrerType,
        //       issueDateString: tempReferredOn,
        //       period: dataItem.referral_period,
        //       periodDescription: dataItem.referral_periodDescription
        //     },
        //   }
        // },
        
        webhooks: [
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/creation',
            event: 'invoiceCreated' ,
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/balpaid',
            event: 'invoiceBalancePaid',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/cancelled',
            event: 'invoiceCancelled',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/completed',
            event: 'invoiceCompleted',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/approved',
            event: 'healthFundApprovedInvoice',
            method: 'POST',
          },
          {
            url: 'https://apicloud.ruralhealthconnect.com.au/medipass/rejected',
            event: 'healthFundRejectedInvoice',
            method: 'POST',
          },
        ],

        disableModifyServiceItems: true,

        // Production webhooks 

        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/creation',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/cancelled',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/completed',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/approved',
        // url: 'https://apicloud.ruralhealthconnect.com.au/medipass/rejected',

        // Test Webhooks via Logic App to capture the packet 
        // url: 'https://prod-09.australiasoutheast.logic.azure.com:443/workflows/5bbaefa6a9844816b8c4b625bda66a9b/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=A-6rwRycc1VL6tNtqIPGCrdv4YXsxmyaJtabBugo-xA',

        onSuccess: function (transaction) {
          // handle successful submission of transaction
          // Toast time
          toast.success("Claim Submitted",{ autoClose:5000 });
          process.env.REACT_APP_DEBUG && console.log(transaction);
        },
        onError: function (error) {
          // handle errored submission of transaction
          toast.error("`Claimed Not Submitted",{ autoClose:5000 });
          process.env.REACT_APP_DEBUG && console.log(error);
        },
        onCancel: function () {
          // handle when create transaction flow has been cancelled
          // cancelled - do nothing
        }
      });
    }
  }

  const openConnection = async(dataItem) => {
    // Note the env variable below should be set to 
    // env : 'stg',    for Tyro staging 
    // env : 'prod',   for Tyro prod
    // Check if using test psychologist 
    if (registered && registered.id === 14)
    {
      // Ok using test psychologist - thus envionrment is staging 
      await medipassTransactionSDK.setConfig({
        env: 'stg',
        apiKey: paymentAPI.secureapi,
        appId: 'ruralhealthconnect-web',
        appVersion: '2021Oct'
      });
      medipassTransactionSDK.transactions.downloadPDF({ transactionId: dataItem.medipasstransactionid });
    }
    else
    {
      await medipassTransactionSDK.setConfig({
        env: 'prod',
        apiKey: paymentAPI.secureapi,
        appId: 'ruralhealthconnect-web',
        appVersion: '2021Oct'
      });
      medipassTransactionSDK.transactions.downloadPDF({ transactionId: dataItem.medipasstransactionid });      
    }
  }

  const download = dataItem => {
    openConnection(dataItem);
  }


  const view = dataItem => {
    setCurrentInvoiceID(dataItem.id);
    setCurrentClientID(dataItem.clientid)
    setCurrentClientName(dataItem.clientName)
    // 
    setCurrentClientSuburb(dataItem.clientsuburb)
    setCurrentInvoiceItems(dataItem.invoiceitems)
    setCurrentClientSelect(true);
    // Determine current line number based on existing invoice 
    const maxlineno = Math.max(...dataItem.invoiceitems.map(o => o.lineno), 0);
    setCurrentLineNo(maxlineno);
    // Check status to determine if invoice is editable 
    if (dataItem.status === 'Saved')
    {
      setCurrentInvoiceEditable(true);
    }
    else
    {
      setCurrentInvoiceEditable(false);
    }
    setShow(true)
  }


  const CommandCell = props => {
    //
    const {dataItem} = props;
    // Hide Submit to Tyro if already submitted - even if failed
    let displayMedipass = false;
    if (dataItem.status === 'Saved')
    {
      displayMedipass = true
    }
    //
    return (
      <td className="k-command-cell">
        <button className="k-button k-grid-save-command" onClick={() => view(dataItem)} >
          View
        </button>
        {dataItem.medipasstransactionid ? <button className="k-button k-grid-save-command" onClick={() => download(dataItem)} >
          Statement
        </button>
        :
          null
        }
        {displayMedipass ? <button className="k-button k-grid-cancel-command" onClick={() => medipassSubmit(dataItem)}>
            Submit to Tyro        
          </button>
        :
          null
        }
      </td>
    )
  };

  if (paymentAPI && !paymentAPI.secureapi)
  {
    return('Tyro not configured')
  }


  return(
    <>
      <div>
        <h4>Tyro Invoices</h4>
        <Grid
          style={{height: "700px",}}
          sortable={true}
          reorderable={true}
          pageable={{buttonCount: 4,pageSizes: true,}}
          data={dataResult} {...dataState} 
          onDataStateChange={dataStateChange}
        >
          <GridToolbar style={{ align: 'right' }}>
            <MUIGrid container justifyContent="space-between">
              <MUIGrid item style={{ marginTop: 2, marginLeft: 2 }}>
               
              </MUIGrid>
              <MUIGrid item style={{ marginLeft: 20, marginRight: 10 }}>
                <Button variant="primary" size="sm" onClick={addNew}>
                  Add New
                </Button>
              </MUIGrid>
            </MUIGrid>
          </GridToolbar>
          <GridColumn field="id" title="#" width="55px" /> 
          <GridColumn field="invoicedate" title="Date" format="{0:d}" width="90px" cell={DateFormatter}/> 
          <GridColumn field="clientName" title="Client" width="200px" />
          <GridColumn field="clientdob" title="DOB" format='{0:d}' width="94px" cell={DateFormatter} /> 
          <GridColumn field="clientsuburb" title="Suburb" width="94px"  /> 
          <GridColumn field="total" filter={"numeric"} title="Total Cost" width="90px" className="kendo-grid-col-right" />
          <GridColumn field="payment" filter={"numeric"} title="Payment" width="90px" className="kendo-grid-col-right" />
          <GridColumn field="status" title="Invoice Status" width="120px" />
          <GridColumn field="claimstatus" title="Claim Status" width="150px" />
          <GridColumn title="Actions" cell={CommandCell} width="220px" />
        </Grid>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="edit-psychologist-dialog-modal">
        <Modal.Header closeButton className="edit-psychologist-modal">
          <Modal.Title>Invoice Maintenance </Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit-psychologist-modal">
          <InvoiceDetail
            invoiceID = {currentInvoiceID}
            closeModal = {handleClose}
            clientID = {currentClientID}
            clientName = {currentClientName}
            clientSuburb = {currentClientSuburb}
            setClientID = {setCurrentClientID}
            clientSelect = {currentClientSelect}
            invoiceitems = {currentInvoiceItems}
            invoiceeditable = {currentInvoiceEditable}
            lineno = {currentLineNo}
          />
        </Modal.Body>
      </Modal>

      <ToastContainer
          position="bottom-right"
          transition={Flip}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
      />      
    </>
  )
}
