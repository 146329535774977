
import * as auth from "../../app/store/ducks/auth.duck";
import * as reference from "../../app/store/ducks/reference.duck";
import * as admin from "../../app/store/ducks/admin.duck";

// import { reauth } from "../../app/crud/auth.crud";

export const apiURL = process.env.REACT_APP_APIIP;

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const hashPassword = (salt, password) => {

var crypto = require('crypto')

  if (salt && password) {
      var hashed = crypto.pbkdf2Sync(password, salt, 100000, 64, 'sha512').toString('hex');
      // console.log("hashed:",hashed);
      return hashed;
  } else {
      return password;
  }
};

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      if ((config.url.search('/psychologistdata') !== -1) || (config.url.search('/files') !== -1) || (config.url.search('/note') !== -1) || (config.url.search('/register/clientdata') !== -1))
      {
        // ok it is a form post 
        // console.log('Its a form PUT');
        const {
          auth: { authToken }
        } = store.getState();
        if (authToken) {
          config.headers = {  Authorization: `Bearer ${authToken}`, 'Content-Type' : 'multipart/form-data'}
        }
      }
      else
      {
        // console.log('Auth');
        // console.log(config.url);
        const 
        {
          auth: { authToken }
        } = store.getState();
        if (authToken) 
        {
          config.headers = {  Authorization: `Bearer ${authToken}`, 'Content-Type' : 'application/json'}
        }
      }
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use((response) => {
   return response
    }, 
  function (error) {
    const originalRequest = error.config;
    const { auth: { refreshToken }} = store.getState();
    if (error.response.status === 401 && !originalRequest._retry && refreshToken) {
      originalRequest._retry = true;
      // const { auth: { authToken,refreshToken }} = store.getState();
      // console.log ('Get New Token');
      // console.log (refreshToken);
      // console.log(originalRequest);
      return axios.post(apiURL+'/users/reauth',{ refreshToken })
        .then(res => {
          //console.log(res);
          if (res.status === 200) 
          {
              // 1) put token to LocalStorage
              // console.log ('Received New Token');
              // console.log (res.data.token);
              // console.log (res.data.refreshToken);
              // const { authToken } = res.data.token;
              // const { refreshToken } = res.data.refreshToken;
              store.dispatch({ type: auth.actionTypes.Reauth, payload: { authToken:res.data.token, refreshToken:res.data.refreshToken } })
              // console.log(originalRequest);
              return axios(originalRequest);
          }
        })
        .catch(() => {
          store.dispatch({ type: reference.actionTypes.SET_INITIAL }); 
          store.dispatch({ type: admin.actionTypes.SET_INITIAL }); 
          store.dispatch({ type: auth.actionTypes.Logout })
          // console.log('Logged Out');
        });
    }  
    else
    {
      return Promise.reject(error);
    }

  });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
