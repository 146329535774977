import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid'
import { Button, Modal, Form, InputGroup, Row, Col } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Grid, FormControl, Select, InputLabel, MenuItem, makeStyles } from '@material-ui/core';
import * as Admin from "../store/ducks/admin.duck"
import { Formik } from 'formik'
import * as Yup from "yup"
import RenderExpandableCell from "./RenderExpandableCell"
import Tooltip from '@material-ui/core/Tooltip'
import { ToastContainer, Flip } from "react-toastify"
// import { saveAs } from 'file-saver';
import "react-toastify/dist/ReactToastify.css"


const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

export default function BookingApproval() 
{
  const dispatch = useDispatch()
  const classes = useStyles();
  
  const providerBookingApproval = useSelector(state => state.admin.ProviderBookingApproval)
  const filters = useSelector(state => state.admin.Filters)
  const [searchTerm, setSearchTerm] = useState('')
  const [showBooking, setShowBooking] = useState(false)
  const [booking, setBooking] = useState()
  const [clientName,setClientName] = useState()
  const [viewOnlyMode,setViewOnlyMode] = useState(false)

  useEffect( () => {
    dispatch(Admin.actions.fetchProviderBookingApproval()) }, 
    [dispatch] 
  )

  const searchProviderBookingApproval = (billableIntent) => {
    if ( true
        && (billableIntent => billableIntent.clientName.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0)
        && (!filters.providerApprovalStatus || filters.providerApprovalStatus === 'All' || (billableIntent.approvedstatus === filters.providerApprovalStatus))
      )
      {
        return billableIntent
      }  
  }
  
  const filteredProviderBookingApproval = providerBookingApproval.filter(billableIntent => searchProviderBookingApproval(billableIntent))

  const updateBooking = (thisBooking) => {
    setViewOnlyMode(false)
    setBooking(thisBooking)
    setClientName(thisBooking.clientName)
    setShowBooking(true)
  }

  const viewNote = (thisBooking) => {
    setViewOnlyMode(true)
    setBooking(thisBooking)
    setClientName(thisBooking.clientName)
    setShowBooking(true)
  }

  const approveBooking = (thisBooking) => {
    //
    setViewOnlyMode(false)
    dispatch(Admin.actions.putApproveBooking({ id: thisBooking.id, atype: 'A'}))
  }

  const noShowBooking = (thisBooking) => {
    //
    setViewOnlyMode(false)
    dispatch(Admin.actions.putApproveBooking({ id: thisBooking.id, atype: 'N'}))
  }

  const unapproveBooking = (thisBooking) => {
    //
    setViewOnlyMode(false)
    dispatch(Admin.actions.putApproveBooking({ id: thisBooking.id, atype: 'U'}))
  }

  const rhcInvoicedBooking = (thisBooking) => {
    //
    dispatch(Admin.actions.putRHCInvoicedBooking({ id: thisBooking.id}))
  }

  const validationSchema = Yup.object().shape({
    note: Yup.string().min(2, 'Too Short').max(3800, 'Too Long')
  })

  function submitForm(data){
    // Save data 
    dispatch(Admin.actions.putBookingNote({id : data.id, note : data.note}))
    setShowBooking(false)
  }


  const columns = [
    { field: 'id', headerName: 'Booking ID', width: 130 },
    { field: 'charged', headerName: 'RHC', width: 50, hide: true},
    { field: 'clientName', headerName: 'Name', width: 150,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => updateBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} className="cell-Link" >
              {params.value}
            </div>
        </strong>
      ),  
    },
    { field: 'status', headerName: 'Status', width: 120 },    
    { field: 'bookingDate', headerName: 'Date', width: 150 },
    { field: 'service', headerName: 'Type', width: 100},
    { field: 'approvedstatus', headerName: 'Status', width: 110 },    
    { field: 'providerfee', headerName: 'Fee', width: 100 }, 
    { field: 'rhcinvoiced', headerName: 'Invoiced', width: 120 },
    { field: 'note', headerName: 'Note (Hover to View)', width: 190, renderCell: (params) => (
      <RenderExpandableCell {...params} />)},  
    { field: 'none', headerName: 'Actions (DNA - Did Not Attend)', width: 430,
    renderCell: (params) => (
      <>
        {params.row.charged !== 'Y' ?
          <>
            {params.row.approvedstatus === 'Incomplete' ?
              <>
                <Button variant="secondary" size="sm" onClick={()=>approveBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "green", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                  Completed
                </Button>
                <Tooltip title='Note required to Set as No Show' >
                  <Button variant="secondary" size="sm" disabled = {params.row.note.length < 10} onClick={()=>noShowBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "red", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                    DNA 
                  </Button>
                </Tooltip>
                <Button variant="secondary" size="sm" onClick={()=>updateBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "black", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                  Update Note
                </Button>
              </>
            :
              <Button variant="secondary" size="sm" onClick={()=>unapproveBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "green", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
                Uncomplete
              </Button>
            }
          </>
        :
          <Button variant="secondary" size="sm" onClick={()=>viewNote(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "green", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
            View Note
          </Button>
        }
        {params.row.rhcinvoiced === 'No' ?
          <Button variant="secondary" size="sm" onClick={()=>rhcInvoicedBooking(providerBookingApproval.find(intent => intent.id === params.row.id))} style={{ color: "blue", fontWeight: 500, border: 0, background: "#eee", marginRight: 14 }} >
            Mark Invoiced
          </Button>
        : 
          null
        }

      </>
    ) 
  }


  ];

  function handleBookingClose() {
    setShowBooking(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Review NDIS, Contract and EAP Bookings</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
          {/* <Grid item style={{ marginTop: 2, marginLeft: 10 }}> */}
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                  style={{ height: 33 }}
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} size="small">
              <InputLabel id="providerApprovalStatus-label" className={classes.label}> Approval Status</InputLabel>
              <Select
                labelId="providerApprovalStatus-label"
                id="providerApprovalStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff", height: 33  }}
                value={filters.providerApprovalStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'providerApprovalStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Completed'>Completed</MenuItem>
                <MenuItem value='Incomplete'>Incomplete</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingLeft: 10, paddingTop: 10, textAlign: "center", fontWeight:600 }}>
            <p>Please include the Booking ID reference on all invoicing. Send invoices to <i>invoices@ruralhealthconnect.com.au</i></p>
          </Grid>
        </Grid>
      </Paper>


      { providerBookingApproval ? 
        <Paper style={{ minHeight: 200, height: 300 }}>
          <DataGrid rows={filteredProviderBookingApproval} getRowHeight={() => 'auto'} columns={columns}  rowsPerPageOptions={[20]} pagination checkboxSelection={false} rowHeight={40} sortModel={[{ field: 'bookingDate', sort: 'desc' }]} />
        </Paper>
      : ('None')
      }

      <Modal show={showBooking} onHide={handleBookingClose} dialogClassName="edit-tags-modal">
        <Modal.Header closeButton className="edit-tags-modal">
        <Modal.Title>Update Booking - {clientName} </Modal.Title>
        </Modal.Header>         
        <Formik
          enableReinitialize={true}
          initialValues={booking}
          validationSchema = {validationSchema}
          onSubmit={(values, actions) => {
            submitForm(values);
          }}
        >
        {props => {
          const { values, errors, handleChange, handleSubmit } = props; 
          const change = (name,e) => { e.persist();  handleChange(e); };
          return (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} > 
            <Modal.Body className="edit-tags-modal">                
              <Row>
                <Col md="12">
                  <Form.Group >
                    <Form.Label>Update Booking Note for Invoicing Purposes </Form.Label>
                    <Form.Control as="textarea" rows={4} type="text" name="note" value={values.note} onChange={change.bind(null, "note")} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                    {errors ? (<p className='error-help'>{errors.note}</p>) : ''}
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="edit-tags-modal">
              <Grid container justifyContent="space-between">
                <Grid item>
                </Grid>
                <Grid item>
                  <Button variant="secondary" onClick={handleBookingClose}>
                    Cancel
                  </Button>
                  {!viewOnlyMode ?
                    <Button variant="primary" type="submit" style={{ marginLeft: 8 }}>
                      Save
                    </Button>
                  :
                    null
                  }
                </Grid>
              </Grid>
            </Modal.Footer>
          </form>)
        }}
      </Formik>
    </Modal>



      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
