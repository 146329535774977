import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Modal, Form, Row, Col, Tabs, Tab, InputGroup, Badge, Spinner } from "react-bootstrap";
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Grid, FormControl, Select, InputLabel, MenuItem, Tooltip, makeStyles } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TodayIcon from '@material-ui/icons/Today';
import ForumIcon from '@material-ui/icons/Forum';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import BookingsList from "../../../components/BookingsList";
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
// import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import ReferralMaintenance from "../../../components/ReferralMaintenance";
import PlacesAutoComplete from "../../../components/AddressAutoComplete";
import { BookingWeek } from "../../../components/Appointments";
import Notes from "../../../components/Notes";
import Files from "../../../components/Files";
import Tags from '../../../components/Tags';
import ToDos from '../../../components/ToDos';
import { ToastContainer, Flip } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

export default function Clients(props) {
    
  const dispatch = useDispatch()
  const contexts = useSelector(state => state.reference.Contexts)
  const tags = useSelector(state => state.reference.Tags)
  const actualClients = useSelector(state => state.admin.Clients)
  const client = useSelector(state => state.admin.Client)
  const progressStatus = useSelector(state => state.admin.progressStatus)
  const source = useSelector(state =>  state.admin.Source)
  
  const clients = actualClients.filter(client => (props.clienttype === 'Psychologist' ? (client.providertype === props.clienttype || client.providertype === 'Occupational' || client.providertype === 'Clinical' || client.providertype === 'EAPContract') : client.providertype === props.clienttype))
  const filters = useSelector(state => state.admin.Filters)
  const listfilters = useSelector(state => state.admin.Filters.clientsList)
  const bookings = useSelector(state =>  state.admin.Bookings)
  const clientBooking = useSelector(state => state.admin.Booking)

  var [currentClientID, setcurrentClientID] = useState(); 
  var [currentMedicalPractitionerID, setcurrentMedicalPractitionerID] = useState();
  var [currentPaymentType, setcurrentPaymentType] = useState();
  var [currentClientBookingType, setcurrentClientBookingType] = useState()
  var [currentTimeZone, setcurrentTimeZone] = useState();
  var [currentfulladdress, setcurrentfulladdress] = useState();
  var [currentLatitude, setcurrentLatitude] = useState();
  var [currentLongitude, setcurrentLongitude] = useState();
  var [currentID, setcurrentID] = useState();
  var [currentPostCode, setcurrentPostCode] = useState();
  var [currentStatus, setcurrentStatus] = useState(); 
  var [currentFirstName, setcurrentFirstName] = useState();
  var [currentPreferredName, setcurrentPreferredName] = useState();
  var [currentSurname, setcurrentSurname] = useState();
  var [currentDOB, setcurrentDOB] = useState();
  var [currentEmail, setcurrentEmail] = useState();
  var [currentGender, setcurrentGender] = useState();
  var [currentOccupation, setcurrentOccupation] = useState();
  var [currentMobile, setcurrentMobile] = useState();
  var [currentNote, setcurrentNote] = useState();
  var [currenthopeToGetFromTreatment, setcurrenthopeToGetFromTreatment] = useState();
  var [currentEmergencyContactName1, setcurrentEmergencyContactName1] = useState();
  var [currentEmergencyContactPhone1, setcurrentEmergencyContactPhone1] = useState();
  var [currentEmergencyContactRelation1, setcurrentEmergencyContactRelation1] = useState();
  var [currentEmergencyContactName2, setcurrentEmergencyContactName2] = useState();
  var [currentEmergencyContactPhone2, setcurrentEmergencyContactPhone2] = useState();
  var [currentEmergencyContactRelation2, setcurrentEmergencyContactRelation2] = useState();
  var [currentAreasOfInterest, setcurrentAreasOfInterest] = useState();
  var [currentmedicareno, setcurrentmedicareno] = useState();
  var [currentmedicarereferenceno, setcurrentmedicarereferenceno] = useState();
  var [currentmedicareexpirydate, setcurrentmedicareexpirydate] = useState();
  var [currentwaitingRoomURL, setcurrentwaitingRoomURL] = useState('');
  var [currentAppointmentType, setcurrentAppointmentType] = useState('0');
  var [currentRHCNDISManagement, setcurrentRHCNDISManagement] = useState('0')
  var [currentRHCContractManagement, setcurrentRHCContractManagement] = useState('0')
  var [currentTagValues, setcurrentTagValues] = useState([]);
  var [currentNewPassword,setcurrentNewPassword] = useState();
  var [currentNewPasswordConfirmation,setcurrentNewPasswordConfirmation] = useState();
  var [currentReferrerName,setcurrentReferrerName] = useState();
  var [currentReferrerEmail,setcurrentReferrerEmail] = useState();
  var [currentReferrerOrganisation,setcurrentReferrerOrganisation] = useState();
  var [currentReferrerMobile,setcurrentReferrerMobile] = useState();
  var [currentReferrerContactName,setcurrentReferrerContactName] = useState();
  var [currentReferrerContactMobile,setcurrentReferrerContactMobile] = useState();
  var [currentReferrerContactEmail,setcurrentReferrerContactEmail] = useState();
  var [currentBusinessName,setcurrentBusinessName] = useState();
  var [currentABN,setcurrentABN] = useState();
  var [currentBusinessAddress,setcurrentBusinessAddress] = useState();
  var [currentBackgroundInformation,setcurrentBackgroundInformation] = useState()
  var [currentHealthCareCard,setcurrentHealthCareCard] = useState();
  var [currentUnemployed,setcurrentUnemployed] = useState();
  var [currentNaturalDisaster,setcurrentNaturalDisaster] = useState();
  var [currentBulkBilledReason,setcurrentBulkBilledReason] = useState();
  var [currentPsychologistID,setcurrentPsychologistID] = useState();
  var [currentGPID,setcurrentGPID] = useState();
  
  const [currentGPName,setcurrentGPName] = useState();
  const [currentGPClinicName,setcurrentGPClinicName] = useState();
  const [currentEAPWorkPlace,setcurrentEAPWorkPlace] = useState();
  const [currentNDISNumber,setcurrentNDISNumber] = useState();
  const [currentNDISDateFrom,setcurrentNDISDateFrom] = useState();
  const [currentNDISDateTo,setcurrentNDISDateTo] = useState();
  const [currentNDISPlanManagementProvider,setcurrentNDISPlanManagementProvider] = useState();
  const [currentNDISEmailForInvoices,setcurrentNDISEmailForInvoices] = useState();
  const [existingNDISEmailForInvoices,setexistingNDISEmailForInvoices] = useState();
  const [currentNDISDiagnosisHistory,setcurrentNDISDiagnosisHistory] = useState();
  const [currentNDISBookingFee,setcurrentNDISBookingFee] = useState();
  const [currentNDISProviderFee,setcurrentNDISProviderFee] = useState();
  const [currentNDISActualProviderFee,setcurrentNDISActualProviderFee] = useState()
  const [currentNDISSupportCoordinator,setcurrentNDISSupportCoordinator] = useState();
  const [currentNDISSupportCoordinatorPhone,setcurrentNDISSupportCoordinatorPhone] = useState();
  const [currentRHCNDISItemCode, setcurrentRHCNDISItemCode] = useState()
  // const [currentReferralGPName,setcurrentReferralGPName] = useState();
  const [currentReferralGPPracticeFax,setcurrentReferralGPPracticeFax] = useState();
  // const [currentReferralGPPracticeEmail,setcurrentReferralGPPracticeEmail] = useState();
  const [currentConsentToShareWithGP,setcurrentConsentToShareWithGP] = useState();
  const [currentPronouns,setcurrentPronouns] = useState();
  const [currentHandOverNotes,setcurrentHandOverNotes] = useState();
  const [currentOpportunityDescription,setcurrentOpportunityDescription] = useState();
  const [currentOpportunityColour,setcurrentOpportunityColour] = useState();
  const [currentOpportunityStatus,setcurrentOpportunityStatus] = useState();
  const [currentOpportunityInterested,setcurrentOpportunityInterested] = useState();
  const [currentIndigenous,setcurrentIndigenous] = useState()
  const [currentProgramPopulation,setcurrentProgramPopulation] = useState([])
  const [currentProgram, setcurrentProgram] = useState(false)
  var [currentSpecialistType,setcurrentSpecialistType] = useState()
  var [currentClientType,setcurrentClientType] = useState()
  const [key, setKey] = React.useState('personalDetails');
  var [showBooking, setShowBooking] = useState(false)
  
  var [toggleMessage, setToggleMessage] = useState(false)
  var [newNote, setNewNote] = useState('')
  var [sentMsg, setSentMsg] = useState()


  // Define Program Question responses 
  const programPopulationOptions = [
    {
      "id" : "1",
      "name" : "Aboriginal and/or Torres Strait Islander",
    },
    {
      "id" : "2",
      "name": "Culturally and linguistically diverse",
    },
    {
      "id": "3",
      "name": "Migrant and Refugee",
    },
    {
      "id" : "4",
      "name": "Under 20",
    },
    {
      "id" : "5",
      "name": "Rural and Remote",
    }
  ]

 
  const searchClients = (client) => {
    if( true
        && (client.fullname.toLowerCase().search(filters.clientsTerm.toLowerCase()) > -1 || client.flagClean.toLowerCase().search(filters.clientsTerm.toLowerCase()) > -1 || client.psychologistName.toLowerCase().search(filters.clientsTerm.toLowerCase()) > -1 || filters.clientsTerm.length === 0)
        && (client.referrercontactname.toLowerCase().search(filters.clientsReferrer.toLowerCase()) > -1 || client.referrerorganisation.toLowerCase().search(filters.clientsReferrer.toLowerCase()) > -1 || filters.clientsReferrer.length === 0)
        && (!filters.clientsPsychologist || filters.clientsPsychologist === 'All' || (filters.clientsPsychologist && client.currentpsychologistid === filters.clientsPsychologist.id))
        // && (filters.clientsFlags === 'All' || (client.clientRegistrationID === null && filters.clientsFlags === 'Self-registered'))
        // Removed for performance reasons && (filters.clientsAOI === '' || client.clientAreasOfInterest.some(issue => issue === filters.clientsAOI))
        && (filters.clientsStatus === 'All' 
            || (filters.clientsStatus === 'Active' && client.status === 'Active') 
            || (filters.clientsStatus === 'Archived' && client.status === 'Archived')  
            || (filters.clientsStatus === 'ToDoWarning' && client.toDoWarning > 0 && client.status === 'Active') 
            || (filters.clientsStatus === 'ToDo' && client.toDoCount > 0 && client.status === 'Active')
            || (filters.clientsStatus === 'ToDoPPW' && (client.toDoPPWFollowUp > 0  || (client.taskflags && client.taskflags.length > 1 && client.taskflags[1] === '0')) && client.status === 'Active')
            || (filters.clientsStatus === 'ToDoGP' && client.toDoNeedsGPBooking > 0 && client.status === 'Active')
            || (filters.clientsStatus === 'ToDoCancel' && client.toDoRecentCancellation > 0 && client.status === 'Active')
            || (filters.clientsStatus === 'ToDoSentToProvider' && client.toDoSentToProvider > 0 && client.status === 'Active')
            || (filters.clientsStatus === 'Abandoned' && client.abandoned && client.status === 'Active')
            || (filters.clientsStatus === 'Opportunity' && client.opportunity && client.status === 'Active')
            || (filters.clientsStatus === 'GPUnallocated'  && client.providerUnallocated && client.status === 'Active')
            || (filters.clientsStatus === 'EAP/Contract'  && client.providerEAPContract && client.status === 'Active')
            || (filters.clientsStatus === 'Followup' && client.taskflags && client.taskflags[0] === '1' && client.taskflags.length > 1 && client.status === 'Active')
           )
        && (filters.clientsSpecialisationType === 'All' || (client.specialisttype === filters.clientsSpecialisationType))           
        && (filters.clientsType === 'All' || ( filters.clientsType === 'RHC' && client.clienttype === 'RHC' ) || ( filters.clientsType === 'Private' && client.clienttype === 'Private' ) || ( filters.clientsType === 'Self-registered' && client.clientRegistrationID === null ) )
      )
    {
      return client
    }  
  }
  
  const validateMedicareNumber = require('validate-medicare-number');

  const filteredClients = clients.filter(client => searchClients(client))
  // const filteredClients = clients;

  // useEffect( () => { dispatch(Admin.actions.fetchClients()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchSource()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractitioners()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'GeneralPractitioner' })) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchContracts()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest(props.clienttype))  }, [dispatch, props.clienttype] )
  useEffect( () => { (contexts && contexts.length < 1) && dispatch(Reference.actions.fetchContexts()) }, [dispatch, contexts] )
  useEffect( () => { (tags && tags.length < 1) && dispatch(Reference.actions.fetchTags()) }, [dispatch, tags] )

  const actualRegistered = useSelector(state =>  state.admin.RegisteredPsychologists)
  const registered = actualRegistered.filter(psychologist => (props.clienttype === 'Psychologist' ? (psychologist.providertype === 'Psychologist' || psychologist.providertype === 'Occupational' || psychologist.providertype === 'Provisional' || psychologist.providertype === 'Clinical') : psychologist.providertype === props.clienttype))
  const registeredPsychologists = actualRegistered.filter(psychologist => ((psychologist.providertype === 'Psychologist') || psychologist.providertype === 'Occupational' || psychologist.providertype === 'Provisional' || psychologist.providertype === 'Clinical'))
  const registeredFinancial = actualRegistered.filter(psychologist => (psychologist.providertype === 'Financial'))
  const registeredGP = actualRegistered.filter(psychologist => (psychologist.providertype === 'GP'))

  const registeredWithClients = registered.filter(psychologist => psychologist.referralCount > 0)
  const medicalPractitioners = useSelector(state =>  state.admin.MedicalPractitioners)
  const medicalPractices = useSelector(state =>  state.admin.MedicalPractices.GeneralPractitioner)
  // const fundingTypes = useSelector(state =>  state.reference.FundingTypes)
  const timeZone = useSelector(state =>  state.reference.TimeZone)
  const contracts = useSelector(state => state.admin.Contracts)
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)

  // based on the provider type, filter the AOI
  const areasOfInterest = areasOfInterestReference.filter(aoi => aoi.providertype === (props.clienttype === 'Unassociated' ?  'Psychologist' : props.clienttype))
  

  var [show, setShow] = useState(false)
  var [showAppointment, setShowAppointment] = useState(false)
  var [showNotes, setShowNotes] = useState(false);
  var [showFiles, setShowFiles] = useState(false);
  var [medicarenoError,setmedicarenoError] = useState('');
  const [showToDo, setShowToDo] = useState(false)
  const [toDoClientID, setToDoClientID] = useState()
  const [toDoTitle, setToDoTitle] = useState('')

  const initSource = useCallback(() => {
    return { id: 0, firstName: '', surname: '', phone: '', email: '', organisationname: '', description: '',status: 'Active' }
  },[])

  const initMedicalPractitioner = useCallback(() => {
    return { id: 0, providernumber: '', salutation: 'Dr', firstName: '', surname: '', fullname: '', mobilephone: '', phone: '', type: 'Client GP',  }
  },[])

  const initMedicalPractice = useCallback(() => {
    return { id: 0, abn: '', practicename: '', phone: '', fax: '', address1: '', email: '', website: '', logoURL: '', practiceType: 'GeneralPractitioner' }
  },[])

  const initContract = useCallback(() => {
    return { id:0, name: ''}
  },[])

  const loadSource = useCallback((thisSource) => {
    // console.log(thisSource)
    setcurrentSource(thisSource ? thisSource : initSource())
  },[initSource])


  const getSourceOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initSource();
    }
  }

  const [currentSource, setcurrentSource] = useState(initSource());  

  const loadMedicalPractitice = useCallback((thisMedicalPractice) => {
    setcurrentMedicalPractice(thisMedicalPractice ? thisMedicalPractice : initMedicalPractice())
  },[initMedicalPractice])


  const loadMedicalPractitioner = useCallback((thisMedicalPractitioner) =>{
    setcurrentMedicalPractitioner(thisMedicalPractitioner ? thisMedicalPractitioner : initMedicalPractitioner())
    setcurrentMedicalPractitionerID(thisMedicalPractitioner ? thisMedicalPractitioner.id : 0);
    var tempMedicalPractice = thisMedicalPractitioner ? medicalPractices.find(v => v.id === thisMedicalPractitioner.medicalPracticeID) : null;
    loadMedicalPractitice(tempMedicalPractice)
  },[setcurrentMedicalPractitionerID,loadMedicalPractitice,medicalPractices,initMedicalPractitioner])


  const loadContract = useCallback((thisContract) => {
    setcurrentContract(thisContract ? thisContract : initContract())
  }, [initContract])

  const loadPreviousContract = useCallback((thisContract) => {
    setPreviousContract(thisContract ? thisContract : initContract())
  }, [initContract])


  var [currentMedicalPractitioner, setcurrentMedicalPractitioner] = useState(initMedicalPractitioner());  
  var [currentMedicalPractice, setcurrentMedicalPractice] = useState(initMedicalPractice());
  var [currentContract, setcurrentContract] = useState(initContract())
  var [previousContract, setPreviousContract] = useState(initContract());

  const getMedicalPractitionerOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractitioner();
    }
  }

  const getMedicalPracticeOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initMedicalPractice();
    }
  }

  const getContractOption = (option, value) => {
    if (option.id === value.id) {
      return option
    } else {
      return initContract();
    }
  }

  function handleClose() {
    setShow(false);
    // Clear Client Selection in state
    dispatch(Admin.actions.clearClient());
  }  

  function handleAppointmentClose() {
    setShowAppointment(false);
  }  

  function handleRHCNDISManagementChange(value) {
    setcurrentRHCNDISManagement(value);
  }

  function handleRHCContractManagementChange(value) {
    setcurrentRHCContractManagement(value);
  }

  function handleAppointmentTypeChange(value) {
    setcurrentAppointmentType(value);
  }
  
  function handleTimeZoneAutocompleteChange(propertyName, event, newValue) { 
    if (newValue) {
      setcurrentTimeZone(newValue.systemName);
    }
  } 

  function handlePsychologistAutocompleteChange(propertyName,event,newValue)
  {
    if (newValue) 
    {
      setcurrentPsychologistID(newValue.id);
    }
  }

  function handleGPAutocompleteChange(propertyName,event,newValue)
  {
    if (newValue) 
    {
      setcurrentGPID(newValue.id);
    }
  }

  function putClient() {
    // Need to do some validation with Medicare Numbers firstly is their one entered 
    if (currentmedicareno.length > 0)
    {
      // Ok now check it is a valid number 
      var clientmedicare = validateMedicareNumber(currentmedicareno);
      if (!clientmedicare) 
      {
        // Its a bad number - don't save 
        setmedicarenoError('Medicare Number is invalid');
        return;
      }
    }

    var payload = {
      profileClientID: currentClientID,
      profileFirstName: currentFirstName,
      profilePreferredName: currentPreferredName,
      profileSurname: currentSurname,
      profileDOB: currentDOB,
      profileEmail: currentEmail,
      profileGender: currentGender,
      profileOccupation: currentOccupation,
      profileMobile: currentMobile,
      profilefulladdress: currentfulladdress,
      profileStatus: currentStatus,
      profileNote: currentNote,
      profilehopeToGetFromTreatment: currenthopeToGetFromTreatment,
      profileMedicalPractitionerID: currentMedicalPractitionerID,
      profilePaymentType: currentPaymentType,
      profileCurrentContractID : currentContract.id,
      profilePreviousContactID: previousContract.id,
      profileTimeZone: currentTimeZone,
      profileLatitude: currentLatitude, 
      profileLongitude: currentLongitude,
      profilePostCode: currentPostCode,
      emergencycontactname1: currentEmergencyContactName1,
      emergencycontactphone1: currentEmergencyContactPhone1,
      emergencycontactrelation1: currentEmergencyContactRelation1,
      emergencycontactname2: currentEmergencyContactName2,
      emergencycontactphone2: currentEmergencyContactPhone2,
      emergencycontactrelation2: currentEmergencyContactRelation2,
      profileAreasOfInterest: currentAreasOfInterest.map(aoi => aoi.issue).join(","),
      profilemedicareno: currentmedicareno,
      profilemedicarereferenceno: currentmedicarereferenceno,
      profilemedicareexpirydate: currentmedicareexpirydate,  
      medicalPractice: currentMedicalPractice,
      status: currentStatus,
      medicalPractitioner: currentMedicalPractitioner,  
      appointmenttype: currentAppointmentType,   
      rhcndismanagement : currentRHCNDISManagement,
      rhccontractmanagement : currentRHCContractManagement,
      profilepassword: currentNewPassword,
      profilepasswordconfirmation : currentNewPasswordConfirmation,
      profilecurrentPsychologistID : currentPsychologistID,
      profilecurrentGPID : currentGPID,
      profilegpname : currentGPName,
      profilegpclinicname : currentGPClinicName,
      profileeapworkplace : currentEAPWorkPlace,
      profilendisnumber: currentNDISNumber,
      profilendisdatefrom : currentNDISDateFrom,
      profilendisdateto : currentNDISDateTo,
      profilendisplanmanagementprovider : currentNDISPlanManagementProvider,
      profilendisemailforinvoices : currentNDISEmailForInvoices,
      profileexistingndisemailforinvoices : existingNDISEmailForInvoices,
      profilendisdiagnosishistory : currentNDISDiagnosisHistory,
      profilendisbookingfee : currentNDISBookingFee,
      profilendisproviderfee: currentNDISProviderFee,
      profileConsentToShareWithGP : currentConsentToShareWithGP,
      profilePronouns : currentPronouns,
      profileNDISSupportCoordinator : currentNDISSupportCoordinator,
      profileNDISSupportCoordinatorPhone : currentNDISSupportCoordinatorPhone,
      profileRHCNDISItemCode: currentRHCNDISItemCode,
      profileOpportunityDescription: currentOpportunityDescription,
      profileOpportunityColour: currentOpportunityColour,
      profileOpportunityStatus: currentOpportunityStatus,
      profileHandoverNotes: currentHandOverNotes,
      profileIndigenous: currentIndigenous,
      profileprogrampopulation: currentProgramPopulation.map(pp => pp.name).join(","),
      source: currentSource,
      specialisttype: currentSpecialistType,
      clienttype: currentClientType
      }    
    // Only Updates are allowed (inserts via registration) 
    if (process.env.REACT_APP_DEBUG) 
    {
      console.log ('Putting (Update)');
    }        
    // Save update 
    dispatch(Admin.actions.putClientDetail(payload));
    // Close form
    setShow(false);
  }

  function loadNotes(thisClient) {
    setcurrentClientID(thisClient.id)
    setcurrentID(thisClient.id)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setcurrentwaitingRoomURL(thisClient.waitingRoomURL)
    // setcurrentReferralGPName(thisClient.referringGPName)
    setcurrentReferralGPPracticeFax(thisClient.referralGPPracticeFax)
    // setcurrentReferralGPPracticeEmail(thisClient.referralGPPracticeEmail)
    setShowNotes(true)
   }
  
   function loadFiles(thisClient) {
    setcurrentClientID(thisClient.id)
    setcurrentPaymentType(thisClient.paymentType)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname)
    setShowFiles(true)
   }

  const loadClient = useCallback((id) => {
    dispatch(Admin.actions.fetchClient( { clientID: id, type: props.clienttype } ))
    setcurrentProgram(false)
  },[props.clienttype,dispatch, setcurrentProgram])

  const loadProfile = useCallback((thisClient) => {
    // console.log(thisClient);
    dispatch(Admin.actions.fetchBookings({ key: 'client', id: thisClient.id }))
    setcurrentClientID(thisClient.id);
    setcurrentID(thisClient.id);
    setcurrentMedicalPractitionerID(thisClient.medicalPractitionerID);
    setcurrentPaymentType(thisClient.paymentType)
    setcurrentClientBookingType(thisClient.appointmenttype === '1' ? 'PHONE' : 'VIDEO')
    setcurrentfulladdress(thisClient.fulladdress)
    setcurrentStatus(thisClient.status)
    setcurrentLatitude(thisClient.latitude)
    setcurrentLongitude(thisClient.longitude)
    setcurrentFirstName(thisClient.firstname)
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname);
    setcurrentDOB(thisClient.dob);
    setcurrentEmail(thisClient.email);
    setcurrentGender(thisClient.gender);
    setcurrentOccupation(thisClient.occupation);
    setcurrentMobile(thisClient.mobilephone);
    setcurrentNote(thisClient.note);
    setcurrentTimeZone(thisClient.timeZone);
    setcurrentPostCode(thisClient.postCode);
    setcurrenthopeToGetFromTreatment(thisClient.hopeToGetFromTreatment);
    setcurrentEmergencyContactName1(thisClient.emergencycontactname1);
    setcurrentEmergencyContactPhone1(thisClient.emergencycontactphone1);
    setcurrentEmergencyContactRelation1(thisClient.emergencycontactrelation1);
    setcurrentEmergencyContactName2(thisClient.emergencycontactname2);
    setcurrentEmergencyContactPhone2(thisClient.emergencycontactphone2);
    setcurrentEmergencyContactRelation2(thisClient.emergencycontactrelation2);
    setcurrentmedicareno(thisClient.medicareno);
    setcurrentmedicarereferenceno(thisClient.medicarereferenceno);
    setcurrentmedicareexpirydate(thisClient.medicareexpirydate);
    setcurrentAppointmentType(thisClient.appointmenttype);
    setcurrentStatus(thisClient.status);
    setcurrentTagValues(thisClient.tagValues);
    setcurrentwaitingRoomURL(thisClient.waitingRoomURL);
    setcurrentNewPassword(null);
    setcurrentNewPasswordConfirmation(null);
    setcurrentReferrerName(thisClient.referrername);
    setcurrentReferrerOrganisation(thisClient.referrerorganisation);
    setcurrentReferrerMobile(thisClient.referrermobile);
    setcurrentReferrerEmail(thisClient.referreremail);
    setcurrentReferrerContactName(thisClient.referrercontactname);
    setcurrentReferrerContactMobile(thisClient.referrercontactmobile);
    setcurrentReferrerContactEmail(thisClient.referrercontactemail);
    setcurrentBusinessName(thisClient.businessName);;
    setcurrentABN(thisClient.abn);
    setcurrentBusinessAddress(thisClient.businessAddress);
    setcurrentBackgroundInformation(thisClient.backgroundInformation);
    setcurrentHealthCareCard(thisClient.healthcarecard);
    setcurrentUnemployed(thisClient.unemployed);
    setcurrentNaturalDisaster(thisClient.naturaldisaster);
    setcurrentBulkBilledReason(thisClient.bulkbilledreason);
    setcurrentPsychologistID(thisClient.currentpsychologistid);
    setcurrentGPID(thisClient.currentgpid);
    var aoi_clienttype = areasOfInterestReference.filter(aoi => aoi.providertype === (props.clienttype === 'Unassociated' ?  'Psychologist' : props.clienttype))
    var aoi = aoi_clienttype.filter(( {issue} ) => thisClient.clientAreasOfInterest.includes(issue))
    var aoi_program = aoi.filter(aoip => (aoip.program !== null && aoip.program !== 0)) 
    if (aoi_program && aoi_program.length > 0)
    {
      setcurrentProgram(true)
    }
    setcurrentAreasOfInterest(aoi);
    loadMedicalPractitioner(medicalPractitioners.find(v => v.id === thisClient.medicalPractitionerID));
    loadPreviousContract(contracts.find(v => v.id === thisClient.currentcontractid))
    loadContract(contracts.find(v => v.id === thisClient.currentcontractid))
    setcurrentGPName(thisClient.gpName);
    setcurrentGPClinicName(thisClient.gpClinicName)
    setcurrentEAPWorkPlace(thisClient.eapworkplace)
    setcurrentNDISNumber(thisClient.ndisnumber)
    setcurrentNDISDateFrom(thisClient.ndisdatefrom)
    setcurrentNDISDateTo(thisClient.ndisdateto)
    setcurrentNDISPlanManagementProvider(thisClient.ndisplanmanagementprovider)
    setcurrentNDISEmailForInvoices(thisClient.ndisemailforinvoices)
    setexistingNDISEmailForInvoices(thisClient.ndisemailforinvoices)
    setcurrentNDISDiagnosisHistory(thisClient.ndisdiagnosishistory)
    // setcurrentReferralGPName(thisClient.referringGPName)
    setcurrentReferralGPPracticeFax(thisClient.referralGPPracticeFax)
    // setcurrentReferralGPPracticeEmail(thisClient.referralGPPracticeEmail)
    setcurrentNDISBookingFee(thisClient.ndisbookingfee)
    setcurrentNDISProviderFee(thisClient.ndisproviderfee)
    setcurrentNDISActualProviderFee(thisClient.ndisactualproviderfee)
    setcurrentRHCNDISManagement(thisClient.rhcndismanagement)
    setcurrentRHCContractManagement(thisClient.rhccontractmanagement)
    setcurrentConsentToShareWithGP(thisClient.consentToShareWithGP)
    setcurrentPronouns(thisClient.pronouns)
    setcurrentIndigenous(thisClient.indigenous)
    var programPopulation = programPopulationOptions.filter(( {name} ) => thisClient.programpopulation.includes(name))
    setcurrentProgramPopulation(programPopulation)
    setcurrentHandOverNotes(thisClient.handOverNotes)
    setcurrentNDISSupportCoordinator(thisClient.ndissupportcoordinator)
    setcurrentNDISSupportCoordinatorPhone(thisClient.ndissupportcoordinatorphone)
    setcurrentRHCNDISItemCode(thisClient.rhcndisitemcode)
    setcurrentOpportunityDescription(thisClient.opportunitydescription)
    setcurrentOpportunityColour(thisClient.opportunitycolour)
    setcurrentOpportunityStatus(thisClient.opportunitystatus)
    setcurrentOpportunityInterested(thisClient.opportunityinterested)
    loadSource(source.find(v => v.id === thisClient.sourceid));
    setcurrentSpecialistType(thisClient.specialisttype)
    setcurrentClientType(thisClient.clienttype)
    // Load Form
    setShow(true);
  }, [dispatch,areasOfInterestReference,contracts,medicalPractitioners,loadContract,loadPreviousContract,loadMedicalPractitioner,props.clienttype])


  useEffect( () => { client && client.id && client.id > 0 && loadProfile(client) }, [client,loadProfile] )

  const loadAppointment = (thisClient) => {
    setcurrentClientID(thisClient.id);
    setcurrentMedicalPractitionerID(thisClient.medicalPractitionerID);
    setcurrentPaymentType(thisClient.paymentType);
    setcurrentfulladdress(thisClient.fulladdress);
    setcurrentStatus(thisClient.status);
    setcurrentLatitude(thisClient.latitude);
    setcurrentLongitude(thisClient.longitude);
    setcurrentFirstName(thisClient.firstname);
    setcurrentPreferredName(thisClient.preferredname)
    setcurrentSurname(thisClient.surname);
    setcurrentDOB(thisClient.dob);
    setcurrentEmail(thisClient.email);
    setcurrentGender(thisClient.gender);
    setcurrentOccupation(thisClient.occupation);
    setcurrentMobile(thisClient.mobile);
    setcurrentNote(thisClient.note);
    setcurrentTimeZone(thisClient.timeZone);
    setcurrenthopeToGetFromTreatment(thisClient.hopeToGetFromTreatment);
    setcurrentEmergencyContactName1(thisClient.emergencycontactname1);
    setcurrentEmergencyContactRelation1(thisClient.emergencycontactrelation1);
    setcurrentEmergencyContactPhone1(thisClient.emergencycontactphone1);
    setcurrentEmergencyContactName2(thisClient.emergencycontactname2);
    setcurrentEmergencyContactPhone2(thisClient.emergencycontactphone2);
    setcurrentEmergencyContactRelation2(thisClient.emergencycontactrelation2);
    setcurrentAreasOfInterest(thisClient.clientAreasOfInterest);
    setcurrentSpecialistType(thisClient.specialisttype)
    
    loadMedicalPractitioner(medicalPractitioners.find(v => v.id === thisClient.medicalPractitionerID));
    // Load Form
    setShowAppointment(true);
  }

  
  useEffect( ()=> {
  setNewNote(`Dear ${clientBooking.clientFirstName},

  Please note you have been booked for a consultation with ${clientBooking.psychologistfullname} at ${format(new Date(clientBooking.clientDate ? clientBooking.clientDate: null), "dd-MM-yyyy hh:mm aa")}. 

  Please connect here: ${clientBooking.waitingroomurl} at that time.`);

  }, [clientBooking,newNote,setNewNote])

  const loadBooking = (thisBooking) => {
    setNewNote();
    dispatch(Admin.actions.fetchBooking({ bookingid: thisBooking }))
    setToggleMessage(false);
    setShowBooking(true);
  }

  function getActionButtons(id) {
    if (clientBooking.status === 'Cancelled') {
      return(
        <>
          <Button variant="danger" size="sm" onClick={() => deleteBooking(id)}>
            Delete Booking
          </Button>
        </>
      )
    }
    else if (clientBooking.status === 'Active') {
      return(
        <>
          <Button variant="warning" size="sm" onClick={() => cancelBooking(id)}>
            Cancel Booking
          </Button>
        </>
      )
    }
  }

  function cancelBooking(id) {
    dispatch(Admin.actions.putAppointment( 
      { id: id, 
        status: 'Cancelled', 
        refreshPayload: { key: 'client', id: currentClientID, bookingid : id }
      }));
    dispatch(Admin.actions.setBookingStatus({status: 'Cancelled'}));
  }

  function deleteBooking(id) {
    dispatch(Admin.actions.putAppointment( 
      { id: id, 
        status: 'Deleted', 
        refreshPayload: { key: 'client', id: currentClientID }
      }));
    dispatch(Admin.actions.setBookingStatus({status: 'Deleted'}));
    setShowBooking(false)
  }

  function sendMessage() {
    const formData = new FormData();
    // Add fields 
    formData.append('type','Client');
    formData.append('pkid', clientBooking.clientID);
    formData.append('subject','');
    formData.append('selector','SMS');
    formData.append('note',newNote);
    formData.append('faxdestination','');
    formData.append('faxFile','');
    formData.append('sendFormID',0);
    // Ok Time to Dispatch 
    process.env.REACT_APP_DEBUG && console.log(...formData);
    dispatch(Admin.actions.postNote({ formData:formData, type: 'Client', pkID: clientBooking.clientID}));
    setSentMsg('Message sent!')
    showMessageTimer();
  }

  function setTaskFlag(clientID, taskFlags, flagChange)
  {
    // console.log('In setTaskFlag')
    // console.log(clientID);
    // console.log(taskFlags);
    // console.log(flagChange);
    // Define new string 
    let newTaskFlags = [...taskFlags]
    // console.log(newTaskFlags)
    // Check for change - FLWUP
    if (flagChange === 'FLWUP')
    {
      // Check current setting
      if (taskFlags.length > 0)
      {
        // Ok something is already set
        if (taskFlags[0] === "1")
        {
          // Currently it is on - change to off
          newTaskFlags[0] = "0"
        }
        else
        {
          // Currently it is off - change to on
          newTaskFlags[0] = "1"
        }
      }
      else
      {
        // Ok nothing is set
        newTaskFlags[0] = "1"
      }
    }
    // Check for change - PPW
    if (flagChange === 'PPW')
    {
      // Check current setting
      if (taskFlags.length > 1)
      {
        // Ok something is already set
        if (taskFlags[1] === "1")
        {
          // Currently it is on - change to off
          newTaskFlags[1] = "0"
        }
        else
        {
          // Currently it is off - change to on
          newTaskFlags[1] = "1"
        }
      }
      else
      {
        // Check if anything is set
        if (taskFlags.length > 0)
        {
          // Ok nothing is set for PPW
          newTaskFlags[1] = "1"
        }
        else
        {
          // Ok nothing is set
          newTaskFlags[0] = "0"
          newTaskFlags[1] = "1"
        }
      }
    }
    // Check for change - LS
    if (flagChange === 'LS')
    {
      // Check current setting
      if (taskFlags.length > 2)
      {
        // Ok something is already set
        if (taskFlags[2] === "1")
        {
          // Currently it is on - change to off
          newTaskFlags[2] = "0"
        }
        else
        {
          // Currently it is off - change to on
          newTaskFlags[2] = "1"
        }
      }
      else
      {
        // Check if anything is set
        if (taskFlags.length > 1)
        {
          // Ok nothing is set for LS
          newTaskFlags[2] = "1"
        }
        else
        {
          if (taskFlags.length > 1)
          {
            newTaskFlags[1] = "0"
            newTaskFlags[2] = "1"
          }
          else
          {
            // Ok nothing is set
            newTaskFlags[0] = "0"
            newTaskFlags[1] = "0"
            newTaskFlags[2] = "1"
          }
        }
      }
    }
    const newTaskFlagString = newTaskFlags.join("")
    // console.log(newTaskFlags);
    // Update taskFlags 
    dispatch(Admin.actions.putClientTaskFlag({ id: clientID, taskflags: newTaskFlagString, type: props.clienttype, statusName: flagChange }));
    dispatch(Admin.actions.setProgressStatus({ statusClientID: clientID, statusName: flagChange, statusValue: 'In Progress' }));
  }

  function setFlag(event, id, flag) {
    if(event.target.value !== flag)
    {
      dispatch(Admin.actions.putClientFlag({ id: id, flag: event.target.value, type: props.clienttype }));
    }
  }

  function resetInterestedProviders(clientID)
  {
    dispatch(Admin.actions.putResetInterestedProviders({ clientID: clientID, type: props.clienttype }));
  }

  const showMessageTimer =() => {
    const timer = setTimeout(() => {
      setSentMsg(undefined);
      setShowBooking(false)
      }, 3000);
      return () => clearTimeout(timer);
  }

  const TaskFlags = ({clientID,taskFlags}) => {
    // Task Flags is part of the ever growing need for check boxes or buttons in this case
    // Define a few variables 
    let LS = '';
    let PPW = '';
    let FLWUP = '';
    // Slice and dice 
    if (taskFlags && taskFlags.length>0)
    {
      // Is FLWUP Set
      if (taskFlags[0] === '1')
      {
        FLWUP = '#f3e300'
      }
      else
      {
        FLWUP = "#eeeeee"
      }      
      // Is PPW Defined 
      if (taskFlags.length > 1)
      {
        // Is PPW Set
        if (taskFlags[1] === '1')
        {
          PPW = '#f3e300'
        }
        else
        {
          PPW = "#eeeeee"
        }      
        // Is LS Defined 
        if (taskFlags.length > 2)
        {
          // Is LS Set
          if (taskFlags[2] === '1')
          {
            LS = '#f3e300'
          }
          else
          {
            LS = "#eeeeee"
          }      
        }
      }
      else
      {
        PPW = "#eeeeee"
        LS = "#eeeeee"
      }
    }
    else
    {
      // All default 
      FLWUP = "#eeeeee"
      PPW = "#eeeeee"
      LS = "#eeeeee"
    }

    
    // <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setShowFormData(false)} >
    return(
      <>
        <Button size='sm' variant='contained' style={{ background: FLWUP, paddingLeft: 10, paddingRight: 10, minWidth: 36 }} onClick={() => setTaskFlag(clientID,taskFlags,'FLWUP')} >
          {progressStatus[clientID] && progressStatus[clientID].statusName === 'FLWUP' && progressStatus[clientID].statusValue === 'In Progress' ? <Spinner animation="border" style={{ marginRight: 0, marginTop: 0 }} size="sm" variant="primary" /> : 'P' }
        </Button>
        <Button size='sm' variant='contained' style={{ marginLeft: 5, background: PPW, paddingLeft: 10, paddingRight: 10, minWidth: 36 }} onClick={() => setTaskFlag(clientID,taskFlags,'PPW')} >
          {progressStatus[clientID] && progressStatus[clientID].statusName === 'PPW' && progressStatus[clientID].statusValue === 'In Progress' ? <Spinner animation="border" style={{ marginRight: 0, marginTop: 0 }} size="sm" variant="primary" /> : 'F' }
        </Button>
        <Button size='sm' variant='contained' style={{ marginLeft: 5, background: LS, paddingLeft: 10, paddingRight: 10, minWidth: 36 }} onClick={() => setTaskFlag(clientID,taskFlags,'LS')} >
          {progressStatus[clientID] && progressStatus[clientID].statusName === 'LS' && progressStatus[clientID].statusValue === 'In Progress' ? <Spinner animation="border" style={{ marginRight: 0, marginTop: 0 }} size="sm" variant="primary" /> : 'L' }
        </Button>
      </>
    )


  }

  const loadToDos = (clientID, fullName) => {
    setToDoClientID(clientID)
    setToDoTitle(fullName)
    setShowToDo(true)
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'fullname', headerName: 'Name', width: 280,
      renderCell: (params) => {
        return(
          <Grid container justifyContent="space-between">
            <Grid item onClick={() => loadClient(params.row.id)} className="cell-Link">
              <strong>
                { params.row.taskflags && params.row.taskflags > 0 && params.row.taskflags[0] === '1' ? 
                  <>
                    <span style={{ color: 'red' }}>{params.value}</span>
                  </>
                :
                  <>
                    { params.row.providerEAPContract ? 
                      <>
                        <span style={{ color: params.row.currentcontractcolour }}>{params.value}</span>
                      </>
                    :
                      <>
                        {params.value}
                      </>
                    }
                  </>
                }
                {params.row.clientRegistrationID === null ? <MoveToInboxIcon style={{ marginLeft: 8, color: 'green' }} /> : null}  
              </strong>
            </Grid>
            <Grid item>
              {params.row.toDoPPWFollowUp === 1 && <BookmarkIcon className='todo-flag-ppw' />}
              {params.row.toDoNeedsGPBooking === 1 && <BookmarkIcon className='todo-flag-gp' />}
              {params.row.toDoSentToProvider === 1 && <BookmarkIcon className='todo-flag-shared' />}
              {params.row.toDoRecentCancellation === 1 && <BookmarkIcon className='todo-flag-cancel' />}
              <Badge variant={params.row.toDoCount === 0 ? "secondary" : params.row.toDoWarning ? "warning" : "success"} onClick={()=>loadToDos(params.row.id, params.value)} style={{ marginRight: 2, cursor: 'pointer', minWidth: 21 }}>
                {params.row.toDoCount}
                {/* {params.row.toDoCount === 0 ?
                  <NoteAddIcon style={{ marginLeft: 8, color: 'green' }}  />
                 : params.row.toDoCount } */}
                </Badge>              
            </Grid>
          </Grid>
        )
      },  
    },
    { field: 'taskflags', headerName: 'Tasks', width: 150,
      renderCell: (params) => {
        return(
          <TaskFlags 
            clientID = {params.row.id} 
            taskFlags = {params.row.taskflags} 
          />
        )
      }
    },  
    { field: 'flag', headerName: 'Follow-up', width: 170,
      cellClassName: (params) => params.row.flag.length > 0 ? 'flag-full-cell' : 'flag-empty-cell',
      renderCell: (params) => (
        <>
        <TextField 
          variant="outlined"
          name={`flag`} 
          defaultValue={params.row.flag} 
          onBlur={(event) => setFlag(event, params.row.id, params.row.flag)}
          InputProps={{ classes: { notchedOutline:classes.noBorder, input: params.row.flag.length > 0 ? 'flag-full' : 'flag-empty' }, }}
          multiline
          maxRows={4}
          style= {{ width: '100%'}}
          onFocus={event => {
            event.target.select();
          }}>
        </TextField>
        </>
      )
    },
    { field: 'createdon', headerName: 'Created On', width: 135, sortable : true, renderCell: (params) => (format(new Date(params.row.createdon), "dd-MM-yyyy HH:mm")), cellClassName: 'grid-mono-font' },
    { field: 'nextBookingID', headerName: 'Next Booking ID', width: 120, hide: true },
    { field: 'topBookingClient', type: 'date', headerName: 'Booking (Client)', width: 155, sortable: true,
      // valueFormatter: ({ value }) => value ? format(new Date(value), "dd-MM-yyyy HH:mm") : null, 
      cellClassName: (params) => params.row.nextBookingID !== null ? 'future-booking' : 'grid-mono-font',
      renderCell: (params) => (
        <>
          {bookings && params.row.nextBookingID ?
            <div onClick={() => loadBooking(params.row.nextBookingID)} className="cell-Link"> 
              {format(new Date(params.row.topBookingClient), "dd-MM-yyyy HH:mm")}
            </div>
          :
            params.row.topBookingClient != null ? format(new Date(params.row.topBookingClient), "dd-MM-yyyy HH:mm") : null
          }
        </>
      )
    },
    { field: 'psychologistName', headerName: props.clienttype === 'Unassociated' ? 'Psychologist' : props.clienttype, width: 160 },
    { field: 'none', headerName: 'Actions', width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title="Book Appointment" aria-label="Book Appointment" placement="top" arrow>
            <TodayIcon onClick={() => loadAppointment(clients.find(client => client.id === params.row.id))} style={{ color: '#646c9a', cursor: 'pointer' }} />
          </Tooltip>
          
          <Tooltip title="Notes/Email/SMS" aria-label="Notes/Email/SMS" placement="top" arrow>
            <ForumIcon onClick={() => loadNotes(clients.find(client => client.id === params.row.id))} style={{ color: '#646c9a', cursor: 'pointer', marginLeft: 8, marginRight: 8 }} />
          </Tooltip>

          {params.row.filesAvailable ?
          <Tooltip title="Files" aria-label="Files" placement="top" arrow>
            <InsertDriveFileIcon onClick={() => loadFiles(clients.find(client => client.id === params.row.id))} style={{ color: '#646c9a', cursor: 'pointer', marginLeft: 8, marginRight: 8 }} />
          </Tooltip>
          :
            null
          }
        </>
      )
    },
    { field: 'referrercontactname', headerName: 'Referred by', width: 160},
    { field: 'referralGPPractice', headerName: 'GP Practice', width: 200 }
  ];

  useEffect( () => { dispatch(Admin.actions.fetchClients( { status: filters.clientsStatus, type: props.clienttype } )) }, [dispatch, props.clienttype, filters.clientsStatus] )

  useEffect( () => {
    if(filters.clientsListLoadClientID)
    {
      loadClient(filters.clientsListLoadClientID)
      dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: undefined }))
    }
  }, [loadClient, dispatch, filters.clientsListLoadClientID])

  const classes = useStyles();

  function listType(ltype)
  {
    switch(ltype)
    {
      case 'Financial':
       return('Financial')
      case 'Psychologist':
        return('Psychology')
      case 'Provisional':
        return('Provisional Psychology')
      case 'Social':
        return('Social Worker')
      case 'Nurse':
        return('Mental Health Nurse')
      case 'Occupational':
        return('Occupational Therapist')
      case 'Unassociated':
        return('Unassociated')
      case 'GP':
        return('GP')
      case 'NDIS':
        return('NDIS')
      case 'EAPContract':
        return('EAP/Contract')
      case 'Private':
        return('Private')
      default:
        return('Psychology')
    }
  }

  return (
    <>
    <h4>
      {listType(props.clienttype)} Clients
    </h4>
      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  type="text"
                  value={filters.clientsTerm}
                  onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsTerm', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.clientsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'clientsTerm', filterValue: '' }))}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.clientsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
          {/* filters.clientsPsychologist === 'All' ? 'All' :  */}
          <Grid item style={{ paddingRight: 10 }}>
            <Autocomplete
              id="searchPsychologist"
              variant="outlined"
              options={registeredWithClients}
              getOptionLabel={(option) => ((filters.clientsPsychologist === 'All' && !option.fullname) || !option.fullname) ? 'All' : option.fullname}
              value={filters.clientsPsychologist === 'All' ? null : filters.clientsPsychologist}
              onChange={(event, newValue) => dispatch(Admin.actions.setFilters({ filterName: 'clientsPsychologist', filterValue: newValue ? newValue : 'All' }))}
              style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Provider"
                  type="text"
                  variant="outlined"
                  margin="dense"
                  name="PsychologistSelector"
                  style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 200  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password'}}
                />}
            />
          </Grid>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  placeholder="Referrer Lookup"
                  type="text"
                  value={filters.clientsReferrer}
                  onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsReferrer', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.clientsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'clientsReferrer', filterValue: '' }))}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.clientsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
          {/* <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsAOI-label" className={classes.label}>Area of Interest</InputLabel>
              <Select
                labelId="clientsAOI-label"
                id="clientsAOI"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                value={filters.clientsAOI}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsAOI', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                {areasOfInterest.map(row => {
                  return <MenuItem key={`key-${row.id}`} value={row.issue}>{row.issue}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsStatus-label" className={classes.label}>Status</InputLabel>
              <Select
                labelId="clientsStatus-label"
                id="clientsStatus"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                value={filters.clientsStatus}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsStatus', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Followup'>Followup</MenuItem>
                <MenuItem value='ToDo'>With Messages</MenuItem>
                <MenuItem value='ToDoWarning'>With Deadline</MenuItem>
                <MenuItem value='ToDoPPW'>Needs PPW</MenuItem>
                <MenuItem value='ToDoGP'>Needs GP Appointment</MenuItem>
                <MenuItem value='ToDoCancel'>Recent Cancellation</MenuItem>
                <MenuItem value='ToDoSentToProvider'>Sent to Provider</MenuItem>
                <MenuItem value='Abandoned'>Abandoned</MenuItem>
                <MenuItem value='Opportunity'>Opportunity</MenuItem>
                <MenuItem value='EAP/Contract'>EAP/Contract</MenuItem>
                {props.clienttype === 'GP' ?
                  <MenuItem value='GPUnallocated'>GP Unallocated</MenuItem>
                :
                  null
                }
                <MenuItem value='Active'>Active</MenuItem>
                <MenuItem value='Archived'>Archived</MenuItem>
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsType-label" className={classes.label}>Type</InputLabel>
              <Select
                labelId="clientsType-label"
                id="clientsType"
                variant="outlined"
                margin="dense"
                value={filters.clientsType}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsType', filterValue: event.target.value }))}
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value='RHC'>Rural Health</MenuItem>
                <MenuItem value="Self-registered">Self-registered</MenuItem>
                <MenuItem value='Private'>Private</MenuItem>
                <MenuItem value='All'>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>  
            <Form.Control as="select" size="sm" style={{ height: 35 }} defaultValue={filters.clientsSpecialisationType} onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsSpecialisationType', filterValue: event.target.value }))}>
              <option value="All">All</option>
              <option value="nthqldphn">Nth QLD PHN</option>
            </Form.Control>
          </Grid>

        </Grid>
      </Paper>

      <Paper style={{ height: 700 }}>
        <DataGridPro 
          rows={filteredClients} 
          columns={columns} 
          pagination={false}
          checkboxSelection={false} 
          disableColumnMenu={true}
          rowHeight={38} 
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdon', sort: 'desc' }],
            },
          }}
          />
      </Paper>

      <Modal show={show} onHide={handleClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal" style={{ paddingBottom: 0 }}>
          <Modal.Title>
            <Grid container direction='row' spacing={0}>
              <Grid item >  
                {currentFirstName} {currentSurname}
              </Grid>
              <Grid item style={{ paddingLeft: 10 }}>  
                <Tags args={{ 
                    context: 'Client', 
                    fkID: currentClientID,
                    tagValues: currentTagValues,
                    display: { layout: 'title', header: false, box: false } ,
                  }}/>
              </Grid>
            </Grid>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <Form onSubmit={putClient}>
            <Tabs
              id="EditModal"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              mountOnEnter={true}
            >
              <Tab eventKey="personalDetails" title="Personal" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                  <Row>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="profileFirstName" defaultValue={currentFirstName} onBlur={(event) => setcurrentFirstName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Preferred Name</Form.Label>
                        <Form.Control type="text"  name="profilePreferredName" defaultValue={currentPreferredName} onBlur={(event) => setcurrentPreferredName(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Surname</Form.Label>
                        <Form.Control type="text"  name="profileSurname" defaultValue={currentSurname} onBlur={(event) => setcurrentSurname(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    {props.clienttype !== 'Supervision' ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>Gender</Form.Label>
                          <Form.Control as="select" defaultValue={currentGender} onBlur={(event) => setcurrentGender(event.target.value)}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                            <option value="Prefer not to say">Prefer not to say</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    :  
                      null
                    }
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Pronouns</Form.Label>
                        <Form.Control type="text"  name="profilePronouns" defaultValue={currentPronouns} onBlur={(event) => setcurrentPronouns(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    {props.clienttype !== 'Supervision' ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control type="date" name="profileDOB" defaultValue={currentDOB} onBlur={(event) => setcurrentDOB(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="profileEmail" defaultValue={currentEmail} onBlur={(event) => setcurrentEmail(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Contact Phone Number</Form.Label>
                        <Form.Control type="text" name="profileMobile" defaultValue={currentMobile} onBlur={(event) => setcurrentMobile(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                    <Col md="5">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>TimeZone</Form.Label>
                        <Autocomplete
                          id="profileTimeZone"
                          variant="outlined"
                          options={timeZone}
                          getOptionLabel={(option) => option.name}
                          value={timeZone.find(v => v.systemName === currentTimeZone) || ''}
                          onChange={(event, newValue) => handleTimeZoneAutocompleteChange('profileTimeZone', event, newValue)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="TimeZoneSelector"
                              style={{backgroundColor: "white", margin: 0, padding: 0}}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group style={{width: "100%"}}>
                        <Form.Label>Home Address</Form.Label>
                        <PlacesAutoComplete 
                          currentID={currentID}
                          currentfulladdress={currentfulladdress}
                          setcurrentfulladdress = {setcurrentfulladdress}
                          setcurrentLatitude = {setcurrentLatitude} 
                          setcurrentLongitude = {setcurrentLongitude}
                          setcurrentPostCode = {setcurrentPostCode}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <Form.Group >
                        <Form.Label>Reporting Postcode</Form.Label>
                        <Form.Control type="text" name="profilePostcode" defaultValue={currentPostCode} readOnly />
                      </Form.Group>
                    </Col>
                    {props.clienttype !== 'Supervision' ?
                      <Col md="2">
                        <Form.Group >
                          {currentPaymentType === 'EAP' ?
                            <Form.Label>Job Title (if relevant)</Form.Label>
                          :
                            <Form.Label>Occupation </Form.Label>
                          }
                          <Form.Control type="text" name="profileOccupation" defaultValue={currentOccupation} onBlur={(event) => setcurrentOccupation(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                    {(currentPaymentType === 'Contract' || currentPaymentType === 'EAP') ?
                      <Col md="2">
                        <Form.Group >
                          <Form.Label>EAP Program Code</Form.Label>
                          <Form.Control type="text" name="profileEAPWorkPlace" defaultValue={currentEAPWorkPlace} onBlur={(event) => setcurrentEAPWorkPlace(event.target.value)} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Do you identify as Aboriginal and/or Torres Strait islander</Form.Label>
                        <Form.Control as="select" defaultValue={currentIndigenous} onBlur={(event) => setcurrentIndigenous(event.target.value)}>
                          <option value="N">No</option>
                          <option value="Y">Yes</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group >
                        <Form.Label>Specialist Type</Form.Label>
                        <Form.Control as="select" defaultValue={currentSpecialistType} onBlur={(event) => setcurrentSpecialistType(event.target.value)}>
                          <option value="Standard">Standard</option>
                          <option value="nthqldphn">Nth QLD PHN</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {programPopulationOptions && currentProgram ? 
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Do you identify as any of the following populations that we are particularly supporting via this program? (select all that apply)</Form.Label>
                          <Autocomplete
                            freesolo
                            id="searchProgramPopulation"
                            variant='outlined'
                            multiple
                            options={programPopulationOptions}
                            getOptionLabel={(option) => option.name}
                            // isOptionEqualToValue={(option, value) => option.name === value.name}
                            value={currentProgramPopulation}
                            onChange={(event, newValue) => setcurrentProgramPopulation(newValue)}
                            //onChange={(event, newValue) => getTradeItems(newValue.bidTradeID)}
                            disableClearable={true}
                            className='flex flex-nowrap'
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}                            
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type='text'
                                variant='outlined'
                                size='small'
                                name="ProgramPopulationSelector"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'
                                }}
                              />
                            )}
                            // renderOption={(props, option) => (
                            //  <Box {...props} key={`trade-${option.id}`} component='li' sx={{'& > img': {mr: 2, flexShrink: 0}, fontFamily: "'Open Sans', sans-serif"}}>
                            //    {option.codeNo} - {option.title}
                            //  </Box>
                            // )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }
                  {props.clienttype !== 'Financial' && props.clienttype !== 'Supervision' ?
                    <>
                      <Row>
                        <Col md="3">
                          <Form.Group style={{width: "100%"}}>
                            <Form.Label style={{ color: 'green', fontWeight: 500 }}>Funding Type</Form.Label>                        
                            <Form.Control as="select" defaultValue={currentPaymentType} onChange={(event) => setcurrentPaymentType(event.target.value)}>
                              <option value="Bulk Billed">Bulk Billed</option>
                              <option value="Reduced Gap">Reduced Gap (+$20)</option>
                              <option value="Full Fee">Full Fee</option>
                              <option value="NDIS">NDIS</option>
                              <option value="Contract">Contract/EAP</option>
                              <option value="EAP" disabled>EAP Please Update</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        {currentPaymentType === 'Contract' ? 
                          <Col md="9">
                            <Form.Group style={{width: "100%"}}>
                              <Form.Label style={{ color: 'green', fontWeight: 500 }}>Contract</Form.Label>  
                                <Autocomplete
                                  id="currentContractID"
                                  variant="outlined"
                                  options={contracts}
                                  getOptionLabel={(option) => option.displayname}
                                  getOptionSelected={(option, value) => getContractOption(option, value)}
                                  value= {currentContract}
                                  onChange={(event, newValue) => loadContract(newValue)}
                                  style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                                  renderInput={(params) => 
                                    <TextField 
                                      {...params} 
                                      type="text"
                                      variant="outlined"
                                      margin="dense"
                                      name="currentContactID"
                                      style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 600  }}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password'}}
                                    />}
                                />
                            </Form.Group>
                          </Col>
                        :
                          null
                        }
                        </Row>
                        {currentPaymentType === 'Contract' ? 
                          <>
                            <Row>
                              <Col md="6">
                                <Form.Group >
                                  <Form.Label >Contract Bookings Managed by RHC</Form.Label>
                                  <Select 
                                    variant="outlined"
                                    margin="dense"
                                    id='RHCContractManagement'
                                    aria-label='Contract Managed by RHC'
                                    name='RHCContractManagement'
                                    value={currentRHCContractManagement} 
                                    onChange={(event) => handleRHCContractManagementChange(event.target.value)}
                                    style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                                    <MenuItem key='ContractManagementNo' value='0'>No</MenuItem>
                                    <MenuItem key='ContractManagementYes' value='1'>Yes</MenuItem>
                                  </Select>
                                </Form.Group>
                              </Col>                    
                              <Col md="2">
                              <Form.Group >
                                <Form.Label>Contract Total Fee</Form.Label>
                                <Form.Control type="text"  name="contracteaptotalfee" defaultValue={(currentContract && currentContract.cost) || ''} readOnly />
                              </Form.Group>
                              </Col>
                              <Col md="2">
                                <Form.Group >
                                  <Form.Label>Contract RHC Fee</Form.Label>
                                  <Form.Control type="text"  name="contracteaprhcfee" defaultValue={(currentContract && currentContract.rhcfee) || ''} readOnly />
                                </Form.Group>
                              </Col>
                              <Col md="2">
                                <Form.Group >
                                  <Form.Label>Contract Provider Fee</Form.Label>
                                  <Form.Control type="text"  name="contracteapproviderfee" defaultValue={(currentContract && currentContract.providerfee) || ''} readOnly />
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        :
                          null
                        }
                      </>  
                  :
                    null
                  }
                  <Row>
                    <Col md="6">
                      <Form.Group >
                        {props.clienttype === 'Financial' ?
                          <Form.Label>Selected Financial Counsellor </Form.Label>
                        :
                          <Form.Label>Selected Psychologist </Form.Label>
                        }
                        <Autocomplete
                          id="currentPsychologist"
                          variant="outlined"
                          options={props.clienttype === 'Financial' ? registeredFinancial : registeredPsychologists}
                          getOptionLabel={(option) => `${option.fullname} (${option.providertype})`}
                          value= {currentPsychologistID && actualRegistered.find(v => v.id === currentPsychologistID) ? actualRegistered.find(v => v.id === currentPsychologistID) : null}
                          onChange={(event, newValue) => handlePsychologistAutocompleteChange('profilecurrentpsychologistid', event, newValue)}
                          style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              type="text"
                              variant="outlined"
                              margin="dense"
                              name="currentPsychologist"
                              style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 300  }}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'}}
                            />}
                          />
                        </Form.Group>
                    </Col>
                    {props.clienttype !== 'Financial' && props.clienttype !== 'Supervision' ?  
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Selected GP </Form.Label>
                          <Autocomplete
                            id="currentGP"
                            variant="outlined"
                            options={registeredGP}
                            getOptionLabel={(option) => option.fullname}
                            value= {currentGPID && actualRegistered.find(v => v.id === currentGPID) ? actualRegistered.find(v => v.id === currentGPID) : null}
                            onChange={(event, newValue) => handleGPAutocompleteChange('profilecurrentgpid', event, newValue)}
                            style={{ minWidth: 200, fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="currentGP"
                                style={{ backgroundColor: "white", margin: 0, padding: 0, maxWidth: 300  }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    :
                      null
                    }
                  </Row>
                  {(props.clienttype !== 'GP' && props.clienttype !== 'Supervision') ?
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Areas of Interest  (Select all that apply) </Form.Label>
                          <Autocomplete
                            id="searchAOI"
                            variant="outlined"
                            multiple
                            options={areasOfInterest}
                            getOptionLabel={(option) => option.issue }
                            value={currentAreasOfInterest}
                            onChange={(event, newValue) => setcurrentAreasOfInterest(newValue)}
                            style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                            renderInput={(params) => 
                              <TextField 
                                {...params} 
                                type="text"
                                variant="outlined"
                                margin="dense"
                                name="AOISelector"
                                style={{backgroundColor: "", margin: 0, padding: 0}}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'}}
                              />}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }
                  <Row>
                    <Col md="12">
                      <Form.Group >
                      {props.clienttype !== 'Supervision' ?  
                          <Form.Label>What are you hoping to get out of your sessions?</Form.Label>
                        :
                          <Form.Label>Details on the type of supervision you are after, where  you are currently practicing and other relevant information.</Form.Label>
                        }
                        <Form.Control as="textarea" rows={4} type="text" name="profilehopeToGetFromTreatment" defaultValue={currenthopeToGetFromTreatment} onBlur={(event) => setcurrenthopeToGetFromTreatment(event.target.value)} autoComplete='new-password' />
                      </Form.Group>
                    </Col>
                  </Row>
                  {currentHandOverNotes && currentHandOverNotes.length > 2 ? 
                    <Row>
                      <Col md="12">
                        <Form.Group >
                          <Form.Label>Referrer Handover Notes</Form.Label>
                          <Form.Control as="textarea" rows={4} type="text" name="profilehandOverNotes" defaultValue={currentHandOverNotes} onBlur={(event) => setcurrentHandOverNotes(event.target.value)}  autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                  :
                    null
                  }
                  <Row>
                  </Row>
                  {/* <Row>
                    <Col md="10">
                      <Form.Group controlId="currentMedicalPractitioner.address">
                        <Form.Label>Address - may need to add this </Form.Label>
                          <Form.Control type="text" name="address" value={currentMedicalPractitioner ? currentMedicalPractitioner.address : ''} readOnly />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  {props.clienttype === 'Financial' ?
                    <>
                      <Row>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>Business Name</Form.Label>
                            <Form.Control type="text" name="businessName" readOnly defaultValue={currentBusinessName || ''}  />
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <Form.Group >
                            <Form.Label>ABN</Form.Label>
                            <Form.Control type="text"  name="abn" readOnly defaultValue={currentABN || ''} />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Business Address</Form.Label>
                            <Form.Control type="text"  name="businessAddress" readOnly defaultValue={currentBusinessAddress || ''} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>Background Information</Form.Label>
                            <Form.Control as="textarea" rows={4} type="text"  readOnly name="backgroundinformation" defaultValue={currentBackgroundInformation}  autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {props.clienttype !== 'Financial' && props.clienttype !== 'Supervision' ?
                    <>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Emergency Contact 1</Form.Label>
                            <Form.Control type="text" name="emergencycontactname1" defaultValue={currentEmergencyContactName1 || ''} onBlur={(event) => setcurrentEmergencyContactName1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control type="text" name="emergencycontactrelation1" defaultValue={currentEmergencyContactRelation1 || ''} onBlur={(event) => setcurrentEmergencyContactRelation1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text"  name="emergencycontactphone1" defaultValue={currentEmergencyContactPhone1 || ''} onBlur={(event) => setcurrentEmergencyContactPhone1(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Emergency Contact 2</Form.Label>
                            <Form.Control type="text" name="emergencycontactname2" defaultValue={currentEmergencyContactName2 || ''} onBlur={(event) => setcurrentEmergencyContactName2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Relationship</Form.Label>
                            <Form.Control type="text" name="emergencyrelation2" defaultValue={currentEmergencyContactRelation2 || ''} onBlur={(event) => setcurrentEmergencyContactRelation2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text"  name="emergencycontactphone2" defaultValue={currentEmergencyContactPhone2 || ''} onBlur={(event) => setcurrentEmergencyContactPhone2(event.target.value)} autoComplete='new-password' />
                          </Form.Group>
                        </Col>
                      </Row>
                      {currentPaymentType === 'NDIS' ?
                        <>
                          <Row>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>NDIS Number</Form.Label>
                                <Form.Control type="text"  name="ndisnumber" defaultValue={currentNDISNumber || ''} onBlur={(event) => setcurrentNDISNumber(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>NDIS Date From</Form.Label>
                                <Form.Control type="date" name="profileNDISDateFrom" defaultValue={currentNDISDateFrom} onBlur={(event) => setcurrentNDISDateFrom(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>To</Form.Label>
                                <Form.Control type="date" name="profileNDISDateTo" defaultValue={currentNDISDateTo} onBlur={(event) => setcurrentNDISDateTo(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>NDIS Total Fee</Form.Label>
                                <Form.Control type="text"  name="ndisbookingfee" defaultValue={currentNDISBookingFee || ''} onBlur={(event) => setcurrentNDISBookingFee(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>NDIS RHC Fee</Form.Label>
                                <Form.Control type="text"  name="ndisproviderfee" defaultValue={currentNDISProviderFee || ''} onBlur={(event) => setcurrentNDISProviderFee(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="2">
                              <Form.Group >
                                <Form.Label>Provider to Invoice</Form.Label>
                                <Form.Control type="text"  name="ndisactualproviderfee" defaultValue={currentNDISActualProviderFee || ''} readOnly />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <Form.Group >
                                <Form.Label>NDIS Plan Management Provider</Form.Label>
                                <Form.Control type="text" name="profileNDISPlanManagementProvider" defaultValue={currentNDISPlanManagementProvider} onBlur={(event) => setcurrentNDISPlanManagementProvider(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group >
                                <Form.Label>NDIS Invoice Email</Form.Label>
                                <Form.Control type="text" name="profileNDISEmailForInvoices" defaultValue={currentNDISEmailForInvoices} onBlur={(event) => setcurrentNDISEmailForInvoices(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>NDIS Support Coordinator</Form.Label>
                                <Form.Control type="text" name="profileNDISSupportCoordinator" defaultValue={currentNDISSupportCoordinator} onBlur={(event) => setcurrentNDISSupportCoordinator(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>NDIS Support Coordinator Phone</Form.Label>
                                <Form.Control type="text" name="profileNDISSupportCoordinatorPhone" defaultValue={currentNDISSupportCoordinatorPhone} onBlur={(event) => setcurrentNDISSupportCoordinatorPhone(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>NDIS Item Code</Form.Label>
                                <Form.Control type="text" name="profileRHCNDISItemCode" defaultValue={currentRHCNDISItemCode} onBlur={(event) => setcurrentRHCNDISItemCode(event.target.value)} autoComplete='new-password' />
                              </Form.Group>
                            </Col>

                            <Col md="3">
                              <Form.Group >
                                <Form.Label >Managed by RHC</Form.Label>
                                <Select 
                                  variant="outlined"
                                  margin="dense"
                                  id='RHCNDISManagement'
                                  aria-label='Managed by RHC'
                                  name='RHCNDISManagement'
                                  value={currentRHCNDISManagement} 
                                  onChange={(event) => handleRHCNDISManagementChange(event.target.value)}
                                  style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                                  <MenuItem key='Video' value='0'>No</MenuItem>
                                  <MenuItem key='Phone' value='1'>Yes</MenuItem>
                                </Select>
                              </Form.Group>
                            </Col>                    
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group >
                                <Form.Label>Medical Diagnoses/History</Form.Label>
                                <Form.Control as="textarea" rows={4} type="text"  readOnly name="currentNDISDiagnosisHistory" defaultValue={currentNDISDiagnosisHistory}  autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      :
                        null
                      }

                      {(currentPaymentType !== 'Contract' && currentPaymentType !== 'EAP' && currentPaymentType !== 'NDIS' && currentPaymentType !== 'Supervision') ?
                        <Row>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Medicare Card Number</Form.Label>
                              <Form.Control type="text" name="medicareno" defaultValue={currentmedicareno || ''} onBlur={(event) => setcurrentmedicareno(event.target.value)} autoComplete='new-password' isInvalid={!!medicarenoError} />
                              <Form.Control.Feedback type="invalid">{medicarenoError}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Order on Card</Form.Label>
                              <Form.Control type="text"  name="medicarereferenceno" defaultValue={currentmedicarereferenceno || ''} onBlur={(event) => setcurrentmedicarereferenceno(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Medicare Expiry Date</Form.Label>
                              <Form.Control type="text" name="medicareexpirydate" defaultValue={currentmedicareexpirydate || ''} onBlur={(event) => setcurrentmedicareexpirydate(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row>
                      :
                        null
                      }
                      {currentPaymentType === 'Bulk Billed' ||  currentPaymentType === 'Reduced Gap'?
                        <>                      
                          {currentPaymentType === 'Bulk Billed' ?
                            <>
                              <Row>
                                <Col md="3">
                                  <Form.Group >
                                    <Form.Label>Do you have a Health Care Card?</Form.Label>
                                    <Form.Control type="text" name="healthcarecard" readOnly defaultValue={currentHealthCareCard || ''}  />
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group >
                                    <Form.Label>Are you Unemployed?</Form.Label>
                                    <Form.Control type="text"  name="unemployed" readOnly defaultValue={currentUnemployed || ''} />
                                  </Form.Group>
                                </Col>
                                <Col md="6">
                                  <Form.Group >
                                    <Form.Label>Have you been impacted by drought, bushfire, flood or other natural disaster?</Form.Label>
                                    <Form.Control type="text"  name="naturaldisaster" readOnly defaultValue={currentNaturalDisaster || ''} />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </>
                          :
                            null
                          }
                          <Row>
                            <Col md="12">
                              <Form.Group >
                                <Form.Label>Please tell us why you need to be billed a reduced fee (or bulk billed)?</Form.Label>
                                <Form.Control as="textarea" rows={4} type="text"  readOnly name="buldbilledreason" defaultValue={currentBulkBilledReason}  autoComplete='new-password' />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      :
                        null
                      }
                    </>
                  :
                    null
                  }
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label style={{ color: 'green', fontWeight: 500 }}>Appointment Type</Form.Label>
                        <Select 
                          variant="outlined"
                          margin="dense"
                          id='appointmentType'
                          aria-label='Select Appointment Type'
                          name='appointmentType'
                          value={currentAppointmentType} 
                          onChange={(event) => handleAppointmentTypeChange(event.target.value)}
                          style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: '#fff' }}>
                          <MenuItem key='Video' value='0'>Video Call</MenuItem>
                          <MenuItem key='Phone' value='1'>Phone Call</MenuItem>
                        </Select>
                      </Form.Group>
                    </Col>                    
                  </Row>
                  <Row>
                    <Col md="3">
                        <Form.Group >
                          <Form.Label>Status</Form.Label>
                          <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setcurrentStatus(event.target.value)}>
                            <option value="Active">Active</option>
                            <option value="Archived">Archived</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Client Type</Form.Label>
                          <Form.Control as="select" defaultValue={currentClientType} onBlur={(event) => setcurrentClientType(event.target.value)}>
                            <option value="RHC">RHC</option>
                            <option value="Private">Private</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                  </Row>
              </Tab>
              {(props.clienttype !== 'Financial' && props.clienttype !== 'Supervision') ?
                <Tab eventKey="clientSource" title="Referrer Souce" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  <>
                    <h4>Source Referrer</h4>
                    <Form.Group style={{width: "100%"}}>
                      <Form.Label style={{ color: 'green', fontWeight: 500 }}>Select Referrer (or enter a new one below)</Form.Label>  
                      <Autocomplete
                        id="sourceReferrerLookUp"
                        variant="outlined"
                        options={source}
                        getOptionLabel={(option) => option.description }
                        getOptionSelected={(option, value) => getSourceOption(option, value)}
                        value={currentSource}
                        onChange={(event, newValue) => loadSource(newValue)}
                        style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            type="text"
                            variant="outlined"
                            margin="dense"
                            name="SourceSelector"
                            style={{backgroundColor: "", margin: 0, padding: 0}}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'}}
                          />}
                      />
                    </Form.Group>
                    <hr></hr>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                        <Form.Label>First Name</Form.Label>
                          <Form.Control type="text" name="currentSourceFirstname" value={currentSource ? currentSource.firstname : ''} onChange={(event) => setcurrentSource({...currentSource, firstname: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group >
                        <Form.Label>Surname</Form.Label>
                          <Form.Control type="text" name="currentSourceSurname" value={currentSource ? currentSource.surname : ''} onChange={(event) => setcurrentSource({...currentSource, surname: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Organisation</Form.Label>
                          <Form.Control type="text" name="currentSourceOrganisationName" value={currentSource ? currentSource.organisationname : ''} onChange={(event) => setcurrentSource({...currentSource, organisationname: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group >
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text" name="currentSourcePhone" value={currentSource ? currentSource.phone : ''} onChange={(event) => setcurrentSource({...currentSource, phone: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group >
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" name="currentSourceEmail" value={currentSource ? currentSource.email : ''} onChange={(event) => setcurrentSource({...currentSource, email: event.target.value})} autoComplete='new-password' />
                        </Form.Group>
                      </Col>
                    </Row> 
                  </>
                </Tab>
              :
                null
              }
              {(props.clienttype !== 'Financial' && props.clienttype !== 'Supervision') ?
                <Tab eventKey="clientGP" title="GP" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}> 
                  {/* Medical Practitioner */}
                  <>
                    {props.clienttype !== 'Provisional' && props.clienttype !== 'GP' && props.clienttype !== 'social' && props.clienttype !== 'occupational' && props.clienttype !== 'Nurse' ?
                      <>
                        <Row>
                          <Col md="4">
                            <h4>Client's GP</h4>
                          </Col>
                          <Col md="2" style={{ textAlign: "right"}}>
                            <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                          </Col>
                          <Col md="6">
                            <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                              <Autocomplete
                                id="profileMedicalPractitionerID"
                                variant="outlined"
                                options={medicalPractitioners}
                                getOptionLabel={(option) => option.fullname }
                                getOptionSelected={(option, value) => getMedicalPractitionerOption(option, value)}
                                value={currentMedicalPractitioner}
                                onChange={(event, newValue) => loadMedicalPractitioner(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="MedicalPractitionerSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerFirstName">
                              <Form.Label>Firstname</Form.Label>
                              <Form.Control type="text" name="firstName" value={currentMedicalPractitioner.firstName} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, firstName: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerSurname">
                              <Form.Label>Surname</Form.Label>
                              <Form.Control type="text" name="surname" value={currentMedicalPractitioner.surname} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, surname: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerMobilePhone">
                              <Form.Label>Mobile Phone</Form.Label>
                              <Form.Control type="text" name="mobilephone" value={currentMedicalPractitioner.mobilephone} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, mobilephone: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="currentMedicalPractitionerProviderNo">
                              <Form.Label>Provider Number</Form.Label>
                              <Form.Control type="text" name="providernumber" value={currentMedicalPractitioner.providernumber} onChange={(event) => setcurrentMedicalPractitioner({...currentMedicalPractitioner, providernumber: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row> 
                        {/* Medical Practice */}  
                        <Row>
                          <Col md="4">
                            <h4>Practice</h4>
                          </Col>
                          <Col md="2" style={{ textAlign: "right"}}>
                            <SearchIcon style={{ marginTop: 8, marginRight: -13 }} />
                          </Col>
                          <Col md="6">
                            <Form.Group style={{ width: "100%", marginBottom: 0 }}>
                              <Autocomplete
                                id="profileMedicalPractice"
                                variant="outlined"
                                options={medicalPractices}
                                getOptionLabel={(option) => option.practicename }
                                getOptionSelected={(option, value) => getMedicalPracticeOption(option, value)}
                                value={currentMedicalPractice}
                                onChange={(event, newValue) => loadMedicalPractitice(newValue)}
                                style={{ fullWidth: true, display: 'flex', wrap: 'nowrap',  }}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    type="text"
                                    variant="outlined"
                                    margin="dense"
                                    name="MedicalPracticeSelector"
                                    style={{backgroundColor: "", margin: 0, padding: 0}}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password'}}
                                  />}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col md="6">
                            <Form.Group >
                            <Form.Label>Clinic Name</Form.Label>
                              <Form.Control type="text" name="practicename" value={currentMedicalPractice ? currentMedicalPractice.practicename : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, practicename: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                            <Form.Label>ABN</Form.Label>
                              <Form.Control type="text" name="currentAbn" value={currentMedicalPractice ? currentMedicalPractice.abn : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, abn: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Contact Phone</Form.Label>
                              <Form.Control type="text" name="currentPhone" value={currentMedicalPractice ? currentMedicalPractice.phone : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, phone: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group >
                              <Form.Label>Clinic Address</Form.Label>
                              <Form.Control type="text" name="currentAddress1" value={currentMedicalPractice ? currentMedicalPractice.address1 : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, address1: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Clinic Fax</Form.Label>
                              <Form.Control type="text" name="currentFax" value={currentMedicalPractice ? currentMedicalPractice.fax : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, fax: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>Clinic Email</Form.Label>
                              <Form.Control type="text" name="currentEmail" value={currentMedicalPractice ? currentMedicalPractice.email : ''} onChange={(event) => setcurrentMedicalPractice({...currentMedicalPractice, email: event.target.value})} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                        </Row> 
                      </>
                    :
                      <>
                        <Row>
                          <Col md="4">
                            <h4>Client's GP</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>GP Name</Form.Label>
                              <Form.Control type="text" name="profilegpname" defaultValue={currentGPName} onBlur={(event) => setcurrentGPName(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group >
                              <Form.Label>GP Clinic Name</Form.Label>
                              <Form.Control type="text"  name="profilegpclinicname" defaultValue={currentGPClinicName} onBlur={(event) => setcurrentGPClinicName(event.target.value)} autoComplete='new-password' />
                            </Form.Group>
                          </Col>
                          {props.clienttype === 'GP' ?
                            <Col md="6">
                              <Form.Group >
                                <Form.Label>Client consent to share their Mental Health Treatment Plan with their GP</Form.Label>
                                <Form.Control as="select" name="profileconsentToShareWithGP" defaultValue={currentConsentToShareWithGP} onBlur={(event) => setcurrentConsentToShareWithGP(event.target.value)} >
                                  <option value=''></option>
                                  <option value='N'>No</option>
                                  <option value='Y'>Yes</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          :
                            null
                          }
                        </Row>
                      </>
                    }
                  </>
                </Tab>
              :
                null
              }
              <Tab eventKey="bookings" title="Bookings" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <BookingsList
                  type='client'
                  id={currentClientID}
                  userTZ={currentTimeZone}
                  view='admin'
                  providerType={props.type}
                />
              </Tab>
              {/* <Tab eventKey="todos" title="Messages" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Row>
                  <Col md="3">
                    {currentClientID && <ToDos clientID={currentClientID} setShowToDo={setShowToDo} tagValues={listfilters} clientType={props.clienttype} noForm={true} />}
                  </Col>
                </Row>
              </Tab> */}
              <Tab eventKey="notes" title="Notes" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <Notes type="Client" 
                       firstName={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName}
                       pkid={currentClientID} 
                       medicalPractice={currentMedicalPractice} 
                       waitingRoomURL={currentwaitingRoomURL}
                       faxGP={currentReferralGPPracticeFax}
                />
              </Tab>
              <Tab eventKey="files" title="Files" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Files type="Client" subtype={currentPaymentType} pkid={currentClientID} />
              </Tab>
              {(props.clienttype !== 'Financial' && props.clienttype !== 'Supervision') ?
                <Tab eventKey="referrals" title="Referrals" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                  <ReferralMaintenance 
                    viewerFullName={`${currentFirstName} ${currentSurname}`}
                    clientID={currentClientID}
                    psychologistID={currentPsychologistID}
                    type={'client'}
                  />
                </Tab>
              :
                null
              }
              <Tab eventKey="opportunity" title="Opportunity" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>                
                <Row>
                  <Col md="12">
                    <Form.Group >
                      <Form.Label>Opportunity Details (Max 500 characters)</Form.Label>
                      <Form.Control as="textarea" rows={4} type="text" name="profileOpportunityDescription" defaultValue={currentOpportunityDescription} onBlur={(event) => setcurrentOpportunityDescription(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Colour</Form.Label>
                      <Form.Control as="select" defaultValue={currentOpportunityColour} onBlur={(event) => setcurrentOpportunityColour(event.target.value)}>
                        <option value="#00B3BE">Aqua</option>
                        <option value="#A33383">Magenta</option>
                        <option value="#D2B12B">Yellow</option>
                        <option value="#93A33A">Green</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Status</Form.Label>
                      <Form.Control as="select" defaultValue={currentOpportunityStatus} onBlur={(event) => setcurrentOpportunityStatus(event.target.value)}>
                        <option value="Active">Active</option>
                        <option value="Closed">Closed</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" style={{ paddingTop: 26}}>
                    <Button variant="primary" onClick={() => resetInterestedProviders(currentClientID)}>
                      Reset Interested Providers
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Label>Interested Providers</Form.Label>
                    <Paper>
                      <TableContainer style={{ backgroundColor: '#FFF', height: 340, width: "100%", overflowY: "scroll" }}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Provider</TableCell>
                              <TableCell>Expressed Interest</TableCell>
                              <TableCell>Relevant Info</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentOpportunityInterested ? currentOpportunityInterested.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>
                                  {row.providerName}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(row.createdOn), "dd-MM-yyyy HH:mm")}
                                </TableCell>
                                <TableCell>
                                  {row.relevantInfo}
                                </TableCell>
                              </TableRow>
                            ))
                            : 
                              null
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="referrer" title="Referrer" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" name="referrerName" defaultValue={currentReferrerName}/>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Organisation</Form.Label>
                      <Form.Control type="text"  name="referrerOrganisation" defaultValue={currentReferrerOrganisation}  />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" name="referrerEmail" defaultValue={currentReferrerEmail}  />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Contact Phone Number</Form.Label>
                      <Form.Control type="text" name="referrerMobile" defaultValue={currentReferrerMobile}  />
                    </Form.Group>
                  </Col>
                </Row>  
                <Row>
                  <Col md="6">
                    <Form.Label>Client Invite Entered by (if not an Admin user)</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Contact Name</Form.Label>
                      <Form.Control type="text" name="referrerContactName" defaultValue={currentReferrerContactName}/>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Contact Phone Number</Form.Label>
                      <Form.Control type="text" name="referrerContactMobile" defaultValue={currentReferrerContactMobile}  />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Contact Email Address</Form.Label>
                      <Form.Control type="text" name="referrerContactEmail" defaultValue={currentReferrerContactEmail}  />
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
              {currentClientID && currentClientID > 0 ?
                <Tab eventKey="tags" title="Tags" className="tags-tab">
                    <Tags args={{ 
                      context: 'Client', 
                      fkID: currentClientID,
                      display: { layout: 'form', header: false, box: true } ,
                      autoSave: true,
                    }}/>
                </Tab>
              : null}
              {/* <Tab eventKey="bookings" title="Bookings" style={{backgroundColor: "rgb(252, 252, 252)", padding: 0}}>
                <>
                  <Grid container direction="row" justifyContent="space-between" style={{ padding: 16 }}>
                    <Grid item><h4 style={{ marginBottom: 0 }}>Bookings ({currentTimeZone})</h4></Grid>
                  </Grid>
                  <BookingsList
                    type='client'
                    id={currentClientID && currentClientID}
                    userTZ={currentTimeZone}
                  />
                </>
              </Tab> */}
              <Tab eventKey="security" title="Security" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
                <h4>Password Update (Leave blank unless resetting password)</h4>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type="password" name="newpassword" defaultValue={currentNewPassword} onBlur={(event) => setcurrentNewPassword(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>New Confirm Password</Form.Label>
                      <Form.Control type="password" name="newpasswordconfirmation" defaultValue={currentNewPasswordConfirmation} onBlur={(event) => setcurrentNewPasswordConfirmation(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Form>                      
        </Modal.Body>        
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putClient}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAppointment} onHide={handleAppointmentClose} dialogClassName="editappointment-modal">
        <Modal.Header closeButton className="editappointment-modal">
          <Modal.Title>Appointments - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="editappointment-modal">         
          <BookingWeek
            mode="admin"
            clientID={currentClientID}
            clientFullname={currentFirstName && (currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName + ' ' + currentSurname : currentFirstName + ' ' + currentSurname)}
            clientFirstName={currentFirstName}
            clientPreferredName={currentPreferredName}
            clientEmail={currentEmail}
            clientTZ={currentTimeZone}
            paymentType={currentPaymentType}
            bookingType={currentClientBookingType}
            providerType={props.clienttype}
            setShowAppointment={setShowAppointment}
            specialistType={currentSpecialistType}
          />
        </Modal.Body>
        <Modal.Footer className="editappointment-modal">
          <Button variant="secondary" onClick={handleAppointmentClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBooking} onHide={()=>setShowBooking(false)} dialogClassName="new-bookingDialog-modal" centered>
        <Modal.Header closeButton className="new-booking-modal">
          {clientBooking && clientBooking.id ?
            <Modal.Title>Booking - {clientBooking.status}</Modal.Title>
          :
            <Modal.Title>Retrieving Booking Information - Please wait </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body className="new-booking-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          {!toggleMessage ?
          <Grid container spacing={3} style={{ height: 247 }}>
            <Grid item md={3} xs={6}>
              Psychologist:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {clientBooking.psychologistfullname}
            </Grid>
            <Grid item md={3} xs={6}>
              Client:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {clientBooking.clientfullname}
            </Grid>
            <Grid item md={3} xs={6}>
              Date:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(clientBooking.psychologistDate ? clientBooking.psychologistDate: null), "dd-MM-yyyy")}
            </Grid>
            <Grid item md={3} xs={6}>
              Time:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              {format(new Date(clientBooking.psychologistDate ? clientBooking.psychologistDate: null), "h:mm aa")}
            </Grid>
            { clientBooking.clientDate !== clientBooking.psychologistDate ?
              <>
                <Grid item md={3} xs={6}>
                  Client's Time:
                </Grid>
                <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
                  {format(new Date(clientBooking.clientDate ? clientBooking.clientDate: null), "h:mm aa")}
                </Grid>
              </>
            : null
            }
            {/* <Grid item md={3} xs={6}>
              Session URL:
            </Grid>
            <Grid item md={9} xs={6} style={{ fontWeight: 600 }}>
              <a href={clientBooking.waitingroomurl} target="_blank" rel="noopener noreferrer">
                {clientBooking.waitingroomurl}
              </a>
            </Grid> */}
          </Grid>
          :
          sentMsg ?             
          <>
            <Row>
              <Col md="12" style={{ height: 222 }}>                  
              {sentMsg}
              </Col>
            </Row>
          </>
            :            
          <>
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={8} type="text" name="psychologistNote" defaultValue={newNote} onBlur={(event) => setNewNote(event.target.value)} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                  {/* {newNoteHelpText.length ? (<p className='error-help'>{newNoteHelpText}</p>) : ''} */}
                </Form.Group>
              </Col>
            </Row>
          </>
          }
        </Modal.Body>
        <Modal.Footer className="new-booking-modal">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
          {!toggleMessage ?
            <>
              {clientBooking && clientBooking.id ?
                <>
                  <Grid item>
                    {getActionButtons(clientBooking.id)}
                  </Grid>
                  <Grid item>
                    <Button variant="primary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} style={{ marginRight: 15 }}>
                      Message
                    </Button>
                    <Button variant="secondary" size="sm" onClick={() => setShowBooking(false)} className="lightButtonOutlinedLarge">
                      Close
                    </Button>
                  </Grid>
                </>
              :
                <Grid item>
                  <Button variant="secondary" size="sm" onClick={() => setShowBooking(false)} className="lightButtonOutlinedLarge">
                    Close
                  </Button>
                </Grid>
              }
            </>
          :
            <>
              <Grid item>
                <Button variant="secondary" size="sm" onClick={() => setToggleMessage(!toggleMessage)} className="lightButtonOutlinedLarge">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="success" size="sm" onClick={() => sendMessage()}>
                  Send &gt;
                </Button>
              </Grid>
            </>
          }
          </Grid>
        </Modal.Footer>
      </Modal>

      <Modal show={showNotes} onHide={()=>setShowNotes(false)} dialogClassName="notes-modal-dialog">
        <Modal.Header closeButton className="notes-modal">
          <Modal.Title>Notes - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="notes-modal">
          <Notes type="Client" pkid={currentClientID} waitingRoomURL={currentwaitingRoomURL} firstName ={currentPreferredName && currentPreferredName.length > 2 ? currentPreferredName : currentFirstName} faxGP={currentReferralGPPracticeFax} />
        </Modal.Body>
        <Modal.Footer className="notes-modal">
          <Button variant="secondary" onClick={() => setShowNotes(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFiles} onHide={()=>setShowFiles(false)} dialogClassName="notes-modal-dialog">
        <Modal.Header closeButton className="notes-modal">
          <Modal.Title>Files - {currentFirstName} {currentSurname}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="notes-modal">
          {registered ? 
            <Files type="Client" subtype={currentPaymentType} pkid={currentClientID} />
          : null}   
        </Modal.Body>
        <Modal.Footer className="notes-modal">
          <Button variant="secondary" onClick={() => setShowFiles(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showToDo} onHide={()=>setShowToDo(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>To Do - {toDoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          {toDoClientID && <ToDos clientID={toDoClientID} setShowToDo={setShowToDo} tagValues={listfilters} clientType={props.clienttype} />}
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )  
}

