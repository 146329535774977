
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Forms from "../../../store/ducks/forms.duck";
import { Formik } from 'formik';
import * as Yup from "yup";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Paper, Grid } from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';
import { Button, Modal, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import { ToastContainer, Flip } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import "react-toastify/dist/ReactToastify.css";
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export default function ManageForms({ setFormID, template }) {

  const forms = useSelector(state => state.forms.Forms);
  const dispatch = useDispatch();
  
  useEffect( () => { dispatch(Forms.actions.fetchForms()) }, [dispatch] )

  const [show, setShow] = useState(false);
  const [tagValues, setTagValues] = useState([]);
  const [tabKey, setTabKey] = useState('details');

  // const formTypes = ['General','Client Intake','Survey']
  const formStatuses = ['Active','Inactive']
  const formDataPlugins = ['DASS-21','DASS-Y','PSC-17','Referring GP Letter','Referring GP Ad-Hoc Letter','Referring GP No Contact Letter','Fax from Provider to GP','Fax to GP Referrer from Admin']

  const initForm = () => {
    return {
      id: 0,
      formID: 0,
      subscriberID: 0,
      ownerID: null,
      formType: 'General',
      formName: '',
      formDescription: '',
      formInstructions: '',
      confirmationText: 'Thank you - your form has been submitted.',
      inputOrientation: '',
      questionToAnswerWidth: '',
      formStyles: '',
      formStatus: 'Active',
      autoCreate: false,
      template: template ? true : false,
      formDataPlugin: ''
    }
  }
    
  const [form, setForm] = useState(initForm())

  const loadForm = (thisForm) => {
    setForm({ ...thisForm });
    setTagValues(thisForm.tagValues);
    setShow(true);
  }
    
  const validationSchema = Yup.object().shape({
	formName: Yup.string()
  .min(2,"Too Short")
  .max(255,"Too Long")
	.required("Form Name is required"),
	confirmationText: Yup.string()
  .min(2,"Too Short")
  .max(255,"Too Long")
	.required("Confirmation Message is required"),
  });

  const deleteForm = () => {
    setShow(false);
    dispatch(Forms.actions.deleteForm(form.formID))
  }

  const copyToProviders = (formID) => {
    dispatch(Forms.actions.copyToProviders(formID))
  }

  const submitForm = (values) => {
	var payload = {
		form: values,
		tagValues: tagValues
	}
    setShow(false);
    dispatch(Forms.actions.putForm(payload))
  }
 
  var columns = [
	{ field: "id", headerName: "Form Name", hide: false, width: 200,
    renderCell: (params) => (
      <strong>
        <div onClick={() => loadForm(forms.find(row => row.id === params.row.id))} className="cell-link">
          {params.row.formName}
        </div>
      </strong>
    ) },
	{ field: 'formDescription', type: 'string', headerName: 'Description', width: 300 },
    ]

  if(!template)
  {
    columns = [ ...columns,  { field: 'responseCount', type: 'string', headerName: 'Responses', width: 140 } ]
  }

  if(template)
  {
    columns = [ ...columns,  { field: 'autoCreate', type: 'boolean', headerName: 'Auto Create', width: 140 } ]
  }

  columns = [ ...columns,  
    { field: 'formStatus', type: 'string', headerName: 'Status', width: 110 },  
    { field: 'questionCount', headerName: "Questions", hide: false, width: 130,
      renderCell: (params) => (
          <div onClick={() => setFormID(params.row.id)} className="cell-link">
            {params.row.questionCount} Questions
          </div>
      ) },
      { field: 'formID', headerName: "Actions", hide: false, width: 150,
        renderCell: (params) => (
            <div>
                {template ?
                <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: '1px solid rgb(192 192 192)', background: "#ebf7f7", marginRight: 10 }} onClick={() => copyToProviders(params.row.id)}>
                    Copy to Providers 
                </Button>
                : null}
            </div>
        ) },
	]

return (
    <div className='container-fluid'>
      <h4>
          {template ? 'Form Templates' : 'Forms'}
      </h4>
     
      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
            <Grid item style={{ marginTop: 2, marginLeft: 2 }}> 
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => loadForm(initForm())}>
              New Form
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ height: 740 }}>
        <DataGridPro 
          rows={forms} 
          columns={columns} 
          checkboxSelection={false} 
          sortModel={[{ field: 'id', sort: 'asc' }]} 
          rowHeight={38}
          pagination={false} />
      </Paper>

		<Modal show={show} onHide={() => setShow(false)} dialogClassName="edit-md-modal">
			<Modal.Header className="edit-md-modal">
			  <Modal.Title>Edit Form</Modal.Title>
        <CloseIcon onClick={() => setShow(false)} style={{ cursor: 'pointer' }} />
			</Modal.Header>
                 
			<Formik
			  enableReinitialize={false}
			  initialValues={form}
			  validationSchema = {validationSchema}
			  onSubmit={(values, actions) => {
				submitForm(values);
				// actions.setSubmitting(false);
			  }}
			>
			{props => {
			const { values, errors, touched, handleChange, handleSubmit } = props; 
			const change = (name,e) => { e.persist();  handleChange(e); };
			return (
			<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>

			<Modal.Body className="edit-md-modal">
			  <Tabs
				id="EditModal"
				activeKey={tabKey}
				onSelect={(k) => setTabKey(k)}
				mountOnEnter={true}
			  > 
			    <Tab eventKey="details" title="Details" style={{backgroundColor: "rgb(245, 245, 245)", padding: 6}}>
			    <Row>

            <Col md="8">
              <Form.Group>
                <Form.Label>Form Name</Form.Label>
                <Form.Control type="text" id="formName" name="formName" value={values.formName} onChange={change.bind(null, "formName")} autoComplete='new-password' isInvalid={touched.formName && !!errors.formName} isValid={touched.formName && !errors.formName}/>
                <Form.Control.Feedback type="invalid">{errors.formName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" id="formDescription" name="formDescription" value={values.formDescription} onChange={change.bind(null, "formDescription")} autoComplete='new-password' isInvalid={touched.formDescription && !!errors.formDescription} isValid={touched.formDescription && !errors.formDescription}/>
                <Form.Control.Feedback type="invalid">{errors.formDescription}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <Form.Label>Confirmation Message</Form.Label>
                <Form.Control type="text" id="confirmationText" name="confirmationText" value={values.confirmationText} onChange={change.bind(null, "confirmationText")} autoComplete='new-password' isInvalid={touched.confirmationText && !!errors.confirmationText} isValid={touched.confirmationText && !errors.confirmationText}/>
                <Form.Control.Feedback type="invalid">{errors.confirmationText}</Form.Control.Feedback>
              </Form.Group>
            </Col>
				  </Row>
          <Row>            
            <Col md="8">
              <Form.Group >
                <Form.Label style={{ width: '100%'}}>Form Data Plugin</Form.Label>
                <Select 
                  variant="outlined"
                  margin="dense"
                  id='formDataPlugin'
                  aria-label='Type'
                  name='formDataPlugin'
                  value={values.formDataPlugin}
                  onChange={change.bind(null, "formDataPlugin")}
                  style={{ backgroundColor: "#ffffff", minWidth: 120 }} >
                    {formDataPlugins.map(row => {
                      return <MenuItem key={row} value={row}>{row}</MenuItem>
                  })}
                </Select>
              </Form.Group>
					  </Col>
          </Row>
          {values.template ? 
          <Row>
            <Col md="8">
              <Form.Group >
                <Form.Label style={{ width: '100%'}}>Auto create form for new providers?</Form.Label>
                <Select 
                  variant="outlined"
                  margin="dense"
                  id='autoCreate'
                  aria-label='Type'
                  name='autoCreate'
                  value={values.autoCreate}
                  onChange={change.bind(null, "autoCreate")}
                  style={{ backgroundColor: "#ffffff", minWidth: 120 }} >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
              </Form.Group>
            </Col>
          </Row>
          : null}
          <Row>
            <Col md="4">
              <Form.Group >
                <Form.Label style={{ width: '100%'}}>Status</Form.Label>
                <Select 
                  variant="outlined"
                  margin="dense"
                  id='formStatus'
                  aria-label='Type'
                  name='formStatus'
                  value={values.formStatus}
                  onChange={change.bind(null, "formStatus")}
                  style={{ backgroundColor: "#ffffff", minWidth: 120 }} >
                    {formStatuses.map(row => {
                      return <MenuItem key={row} value={row}>{row}</MenuItem>
                  })}
                </Select>
              </Form.Group>
					  </Col>
          </Row>
				</Tab>
			  </Tabs>
			</Modal.Body>
			<Modal.Footer className="edit-md-modal">
			<Grid container justifyContent="space-between">
        <Grid item>
          <Button variant="warning" onClick={() => deleteForm()}>
            {form.responseCount > 0 ? 'Archive' : 'Delete'}
          </Button>
        </Grid>
				<Grid item>
				<Button variant="secondary" onClick={()=>setShow(false)}>
					Cancel
				</Button>
				<Button variant="primary" type="submit" style={{ marginLeft: 8 }}>
					Save
				</Button>
				</Grid>
			</Grid>
			</Modal.Footer>
			</form>
			)
			}}
			</Formik>
		</Modal>

    <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )

}