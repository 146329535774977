import React, { useEffect, useState, useCallback } from "react";  
import { useSelector, useDispatch } from 'react-redux'
import ClientActions from "../../widgets/ClientActions";
import * as Admin from "../../store/ducks/admin.duck";
import * as Forms from "../../store/ducks/forms.duck";
import * as Auth from "../../store/ducks/auth.duck";
import BookingsList from "../../components/BookingsList";
import FormDataEntry from "../../pages/home/admin/FormDataEntry";
import { Formik } from 'formik';
import * as Yup from "yup";
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { format } from "date-fns";
import { Alert, Button, Modal, Form, Row, Col } from "react-bootstrap";
import VoiceChatOutlinedIcon from '@material-ui/icons/VoiceChatOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import '../../../_metronic/_assets/css/app2.css';
import UpdatePassword from '../../components/UpdatePassword'
import { ToastContainer, Flip } from "react-toastify";

export default function DashboardClient() {
  
  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: 200,
      // maxWidth: 200,
    },
    media: {
      height: 200,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch()
  const clients = useSelector(state => state.admin.Clients);
  const client = useSelector(state => state.admin.Client);
  // const client = useSelector(state => state.admin.Clients[0]);
  const userTZ = useSelector(state => state.auth.userTZ)
  const bookings = useSelector(state =>  state.admin.Bookings)
  const allFormData = useSelector(state => state.forms.AllFormData);
  const formDataToDo = allFormData.filter(row => row.formDataStatus === 'Not Started' || row.formDataStatus === 'In Progress')
  const notes = useSelector(state => state.admin.Notes['Client'])
  const isRecovery = useSelector(state => state.auth.recoveryStatus)

  useEffect( () => { dispatch(Admin.actions.fetchReferrals()) }, [dispatch] )
  useEffect( () => { dispatch(Forms.actions.fetchAllFormData(0)) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { clients.length > 0 && dispatch(Admin.actions.fetchClient( { clientID: clients[0].id, type: clients[0].clienttype } )) }, [dispatch, clients] )
  useEffect( () => { clients.length > 0 && dispatch(Admin.actions.fetchNotes({ type: 'Client', pkID: clients[0].id })) }, [dispatch, clients] )

  const [showFormData, setShowFormData] = useState(false);
  const [haveBookings, sethaveBookings] = useState(false);
  const [showNewNote, setShowNewNote] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)

  const [formData, setFormData] = useState()
  const loadFormDataEntry = (formData) => {
    setFormData(formData)
    setShowFormData(true);
  }

  const getBookings = useCallback(() => {
    if (!haveBookings && clients.length && client && client.id) {
      dispatch(Admin.actions.fetchBookings({ key: 'client', id: client.id, userTZ: userTZ }))
      sethaveBookings(true)
      // console.log('fetching bookings')
    }     
  },[dispatch,haveBookings,clients,client, userTZ])  

  useEffect( () => { getBookings() }, [clients, client, getBookings] )
 
  useEffect( () => {
    if (isRecovery === 'Recovery') {
      dispatch(Auth.actions.clearRecovery())
      setShowUpdatePassword(true)
    }
  }, [dispatch,isRecovery])


  const submitForm = (values) => {
    // Handle preferred name if it is set
    let fullname = ''
    if (client && client.preferredName && client.preferredName.length > 2)
    {
      fullname = client.preferredname + ' ' + client.surname
    }
    else
    {
      fullname = client.firstname + ' ' + client.surname
    }
    //
    var payload = {
      type : 'Client',
      pkid : client.id,
      providerid : client.currentpsychologistid,   
      gpid : client.currentgpid,
      name : fullname,
      note : values.noteBody
    }
    dispatch(Admin.actions.postClientNote(payload));
  }


  const validationSchema = Yup.object().shape({
    noteBody: Yup.string()
      .required("You haven't typed anything yet!"),	
  });


  if(!client)
  {
    return <div>Loading...</div>
  }

  if (client) 
  {
    return (
      <>
        {bookings && bookings[0] && bookings[0].waitingroomurl ? 
          <div className="row">
            <div className="col-xl-12">
              <Alert className="client-dashboard-top-alert">
                <VoiceChatOutlinedIcon className="client-dashboard-top-alert-icon" /> 
                  <div  className="client-dashboard-top-alert-message">To join your telehealth session, simply click here:  </div>
                  <a href={bookings[0].waitingroomurl} target="_blank" rel="noopener noreferrer">
                    <Button variant="primary" size="sm" style={{ marginLeft: 14 }}>Start Session</Button>
                  </a>
              </Alert>
            </div>
          </div>
        : 
          null
        }
        {formDataToDo.length > 0 ?
          <div className="row">
            <div className="col-xl-12">
              <Alert className="client-dashboard-form-alert">
                <AssignmentIcon className="client-dashboard-form-alert-icon" /> 
                  <div  className="client-dashboard-form-alert-message">You have forms to complete:  </div>
                  {formDataToDo.map(formData => {return(
                    <Button key={`key-${formData.id}`} variant="primary" size="sm" style={{ marginLeft: 14 }} onClick={()=>loadFormDataEntry(formData)}>{formData.formName}</Button>
                  )})}
              </Alert>
            </div>
          </div>
        : 
          null
        }
        <div className="row">
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item style={{ paddingTop: 19 }}>
                  <h4>My Bookings</h4>
                </Grid>
              </Grid>
            </div>              
            <div className="kt-portlet__body" style={{ padding: 0 }}>
              <div className="kt-widget4">
                {client && client.id > 0 ?
                  <BookingsList
                    type='client'
                    view='dashboard'
                    id={client.id}
                    providerType={client.paymentType === 'Supervision' ? 'Supervision' : client.paymentType === 'Provisional' ? 'Provisional' : 'psychologist'}
                    currentpsychologistid={client.currentpsychologistid}
                    currentgpid={client.currentgpid}
                    userTZ={userTZ}
                  />
                : 
                  '...loading Bookings' 
                }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-8">
            <div className="row">
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item style={{ paddingTop: 19 }}><h4 >Recent Correspondence</h4></Grid>
                    <Grid item style={{ paddingTop: 13 }}>
                      {!showNewNote ? 
                        <Button variant="primary" size="sm" onClick={()=>setShowNewNote(true)}>
                          {client.paymentType === 'Supervision' ? 
                            <>
                              Send message to your Supervisor
                            </>
                          :
                            <>
                              Send message to your Psychologist
                            </>
                          }
                        </Button>
                      : null }
                    </Grid>
                  </Grid>
                </div>              
                {client && client.id > 0 ?
                  <div className="kt-portlet__body" style={{ padding: 20 }}>
                    <div className="kt-widget4">
                      <Formik
                        enableReinitialize={false}
                        initialValues={{}}
                        validationSchema = {validationSchema}
                        onSubmit={(values, {resetForm}) => {
                          resetForm();
                          submitForm(values);
                          setShowNewNote(false);
                        }}
                      >
                        {props => {
                        const { values, errors, handleChange, setFieldValue, handleSubmit } = props; 
                        const change = (name,e) => { e.persist();  handleChange(e); };
                        return (
                          <>
                          {showNewNote ? 
                            <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                              <Grid container spacing={1}></Grid>
                              <Row>
                                <Col md="12">
                                  <Form.Group >
                                    {client.paymentType === 'Supervision' ? 
                                      <Form.Label>Send your supervisor a message <span style={{ fontStyle:'italic', fontWeight:'bold'}}>(for non urgent matters only)</span>:</Form.Label>
                                    :
                                      <Form.Label>Send your psychologist a message <span style={{ fontStyle:'italic', fontWeight:'bold'}}>(for non urgent matters only)</span>:</Form.Label>
                                    }
                                    <Form.Control as="textarea" rows={4} type="text" name="noteBody" value={values.noteBody} onChange={change.bind(null, "noteBody")} autoComplete='new-password' style={{ borderColor: '#D1D1D1'}} />
                                    {errors ? (<p className='error-help'>{errors.noteBody}</p>) : ''}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" style={{ marginBottom: 15 }}>
                                  <Grid container spacing={0} padding={6} justifyContent="space-between">
                                    <Button variant="light" size="sm" onClick={() => {
                                        setFieldValue("noteBody", ' ')
                                        setShowNewNote(false)
                                      }} style={{ borderColor: '#D1D1D1'}}>
                                      Cancel
                                    </Button>
                                    <Button variant="success" size="sm" type="submit">
                                      Send
                                    </Button>
                                  </Grid>
                                </Col>
                              </Row>
                            </form>
                          : null }
                          </>
                        )
                      }}
                    </Formik>
                    <TableContainer style={{ backgroundColor: '#FFF', height: '50vh', width: "100%", overflowY: "scroll", marginTop: 5 }}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ width: 130 }}>Date</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Note</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notes ? notes.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>
                                {format(new Date(row.createdOn), "dd-MM-yyyy HH:mm")}
                              </TableCell>
                              <TableCell>
                                {row.action}
                              </TableCell>
                              <TableCell>
                                {row.note}
                              </TableCell>
                            </TableRow>
                            ))
                          : 
                            null
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                : 
                  '...loading Correspondence' 
                }
              </div>
            </div>
          </div>
          <div className="col-xl-4" >
            <ClientActions 
              providerType={client.paymentType === 'Supervision' ? 'Supervision' : 'Psychologist'}
            />
          </div>
        </div>

        <ToastContainer
          position="bottom-right"
          transition={Flip}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />    

        <Modal show={showFormData} onHide={() => setShowFormData(false)} dialogClassName="edit-md-sm-dialog-modal">
          <Modal.Header className="edit-md-sm-modal">
            <Modal.Title>{formData && formData.formName}</Modal.Title>
            <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setShowFormData(false)} >
              <CloseIcon />
            </Button> 
          </Modal.Header>
          <Modal.Body className="edit-md-modal">
            {formData && <FormDataEntry formID={formData.formID} formDataID={formData.formDataID} setShowFormData={setShowFormData} />}
          </Modal.Body>
        </Modal>

        <Modal show={showUpdatePassword} onHide={()=>setShowUpdatePassword(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>You have recently recovered your password, please update your password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          <UpdatePassword setShowUpdatePassword={setShowUpdatePassword} />
        </Modal.Body>
      </Modal>

      </>
    )
  }
}
