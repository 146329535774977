import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import BookingsList from "../../../components/BookingsList";
import * as Admin from "../../../store/ducks/admin.duck";
import { Grid } from '@material-ui/core';

export default function BookingsClient() {

  const dispatch = useDispatch()
  
  const userTZ = useSelector(state => state.auth.userTZ)
  const clients = useSelector(state => state.admin.Clients);
  const client = useSelector(state => state.admin.Client);

  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { clients.length > 0 && dispatch(Admin.actions.fetchClient( { clientID: clients[0].id, type: clients[0].clienttype } )) }, [dispatch, clients] )


  
  if(client && client.id && client.id > 0) {
    return (
          <>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item><h4 style={{ marginBottom: 12 }}>Bookings</h4></Grid>
            </Grid>
            <BookingsList
              type='client'
              id={client.id}
              currentpsychologistid={client.currentpsychologistid}
              providerType={client.paymentType === 'Supervision' ? 'Supervision' : 'psychologist'}
              currentgpid={client.currentgpid}
              userTZ={userTZ}
              view='client'
            />
          </>
      )
  } 
  else 
  {
    return <div>...loading</div>
  }

}
