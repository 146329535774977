/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useSelector, useDispatch } from 'react-redux'
// import { ProgressBar } from "react-bootstrap";
// import * as Admin from "../store/ducks/admin.duck";

// import StatisticsProvider from "../components/StatisticsProvider"
// import { DataGrid } from '@material-ui/data-grid';
// import { Paper } from '@material-ui/core';
// import { Button, Modal } from "react-bootstrap";


export default function ClientStatistics() {

// const dispatch = useDispatch()
// useEffect( () => { dispatch(Admin.actions.fetchClientStatistics()) }, [dispatch] )
// const statistics = useSelector(state => state.admin.ClientStatistics )

// const [week,setWeek] = useState();
// const [showProviders, setShowProviders] = useState(false);
// const [providerStatistics,setProviderStatistics] = useState(false);

// const loadProviders = (thisWeek) => {
//   setWeek(thisWeek.weekStart);
//   setProviderStatistics(thisWeek.statisticsProvider);
//   setShowProviders(true);
// }

// function handleProvidersClose() {
//   setShowProviders(false);
// }  


// const columns = [
//   { field: 'id', headerName: 'ID', width: 100,  hide: true },
//   { field: 'weekSortStart', type:'date', headerName: 'Week Sort Starting', width: 200, hide: true }, 
//   { field: 'weekStart', type:'date', headerName: 'Week Starting', width: 200,  
//     renderCell: (params) => (
//       <strong>
//           <div onClick={() => loadProviders(statistics.find(weeks => weeks.weekStart === params.row.weekStart))} className="cell-Link" >
//             {params.value} 
//           </div>
//       </strong>
//     ),  
//   },
//   { field: 'weekBookingsTotal', headerName: 'Bookings', width: 200},
//   { field: 'weekSlotsTotal', headerName: 'Slots', width: 200},
//   { field: 'weekFreeSlotsTotal', headerName: 'Free Slots', width: 200}
// ];

  
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Client Statistics</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget5">
            Coming Soon
          </div>
        </div>
      </div>

      {/* <Modal show={showProviders} onHide={handleProvidersClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal">
          <Modal.Title>Provider Statistics for Week Starting - {week} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
          <StatisticsProvider
            week={week}
            closeProviders={setShowProviders}
            providerStatistics={providerStatistics}
          />
        </Modal.Body>
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleProvidersClose}>
            Cancel
          </Button>
           </Modal.Footer>
      </Modal> */}


    </>
  );

}
