/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
// import { ProgressBar } from "react-bootstrap";
import * as Admin from "../store/ducks/admin.duck";
import KTUtil from "../../_metronic/_assets/js/util";

export default function LatestNotes() {

const dispatch = useDispatch()
useEffect( () => { dispatch(Admin.actions.fetchReport({ reportname: 'latestNotes', output: 'query'})) }, [dispatch] )
const latestNotes = useSelector(state => state.admin.Reports['latestNotes'])
  
  return (
    <>
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Activity Feed</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-widget5">
            {KTUtil.isEmpty(latestNotes) ? 'No recent notes history.' : 
              latestNotes.map((row, index) => {
                return (
                  <div className="kt-widget5__item" key={`note_${index}`}>
                    <div className="kt-widget5__content">
                      <div className="kt-widget5__section">                        
                        <div className="kt-widget5__info">                                      
                          <span><Link to="/clients" style={{ fontWeight: 400, }}>Client: {row.clientFullName}</Link><br />{row.note}</span>
                          <span className="kt-font-info"></span>
                        </div>
                      </div>
                    </div>
                    <div className="kt-widget5__content">
                      <div className="kt-widget5__stats">
                        <span className="kt-widget5__number" style={{ fontSize: 12, fontWeight: 400 }}>{row.createdOnformatted}</span>
                        <span className="kt-widget5__votes">{row.psychologistFullName}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );

}
