import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import * as Forms from "../store/ducks/forms.duck";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import FormDataEntry from "../pages/home/admin/FormDataEntry";
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function SendLetter(props) {
    
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Forms.actions.fetchForms()) }, [dispatch] )
  const role = useSelector(state =>  state.auth.roleDesc)
  const forms = useSelector(state => state.forms.Forms);

  // Will need to add more to this filter as they are developed
  const pdfForms = forms.filter(form => (props.form ? form.formDataPlugin === props.form : form.formDataPlugin === 'Fax from Provider to GP'))
  const [sendFormID, setSendFormID] = useState(0);
  const [showCompleteForm, setShowCompleteForm] = useState(false);
  const [selectedForm, setSelectedForm] = useState();

  function completeForm() {
    setShowCompleteForm(true);
    setSelectedForm(forms.filter(form => form.id === sendFormID)[0].formName)
    // console.log(forms.filter(form => form.id === sendFormID));
  }

  useEffect( () => { 
    if (props.form && sendFormID === 0 && pdfForms.length > 0)
    {
      setSendFormID(pdfForms[0].formID)
    }
   }, [props.form, sendFormID, setSendFormID, pdfForms] )


  const handleSelectForm = (event,row) => {
    setSendFormID(event.target.value);  
  };

  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     // minWidth: 200,
  //     // maxWidth: 200,
  //   },
  //   media: {
  //     height: 200,
  //   },
  //   title: {
  //     fontSize: 14,
  //   },
  //   pos: {
  //     marginBottom: 12,
  //   },
  // }));
    
  // const classes = useStyles();
  
   return(
    <>
      <Grid container spacing={4}>
        <Grid item md={props.vertical ? 12 : 12}>  
          {role !== 'Client' && role !== 'Referrer'  ?
            <>
              {props.form ? 
                <FormDataEntry formID={sendFormID} formDataID={0} setShowFormData={props.closeModal} contextName={'Fax'} contextID={props.faxID} pdfFlag={true} pdfGen={true} gpFax={props.faxID} preview={true} />
              :
                <>
                  <hr />
                  {pdfForms && pdfForms.length > 0 ?
                    <Row>
                      <Col md="12">                  
                        <Form.Group >
                          <FormControl variant="outlined" margin="dense">
                            <Form.Label>Letter to Generate and Fax:</Form.Label>
                            <Select 
                              id='sendFormID'
                              aria-label='Select Action Type'
                              name='sendFormID'
                              value={sendFormID} 
                              onChange={handleSelectForm}
                              style={{ backgroundColor: 'white', minWidth: 150 }}>
                              <MenuItem key={0} value={0}></MenuItem>
                              {pdfForms.map(row => { return (
                                <MenuItem key={`form-${row.id}`} value={row.id}>{row.formName}</MenuItem>
                                )}
                              )}
                            </Select>
                          </FormControl> 
                        </Form.Group>  
                      </Col>
                          {sendFormID !== 0 ? 
                            <Col md="12"> 
                              <Grid container spacing={0} padding={6} justifyContent="space-between">
                                <Grid item>

                                </Grid>
                                <Grid item>
                                  <Button variant="success" size="sm" onClick={() => completeForm()}>
                                    Display Form
                                  </Button>
                                </Grid>
                              </Grid>
                            </Col>
                          :
                            null
                          }
                    </Row>
                  :
                    null
                  }
                </>
              }
            </>
          :
          null
          }
        </Grid>
      </Grid>
    
      <Modal show={showCompleteForm} onHide={() => setShowCompleteForm(false)} dialogClassName="edit-md-sm-dialog-modal">
        <Modal.Header className="edit-md-sm-modal">
          <Modal.Title>Generating {selectedForm && selectedForm} PDF Document</Modal.Title>
          <Button variant="outlined" size="sm" style={{ marginTop: 5, background: "#eeeeee", border: '1px solid #9da8e5', padding: 5, marginLeft: 10 }} onClick={() => setShowCompleteForm(false)} >
            <CloseIcon />
          </Button> 
        </Modal.Header>
        <Modal.Body className="edit-md-modal">
          <FormDataEntry formID={sendFormID} formDataID={0} setShowFormData={setShowCompleteForm} contextName={props.type} contextID={props.pkid} pdfFlag={true} pdfGen={true} preview={true}/>
        </Modal.Body>
      </Modal>

      <ToastContainer
        transition={Flip}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </>


  )
}
  
