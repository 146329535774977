import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux'
import * as Registration from "../../store/ducks/registration.duck";
import { Button, Form, Row, Col, Alert } from "react-bootstrap";
import { Paper, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useMediaQuery } from '@material-ui/core';
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../_metronic/_assets/css/app2.css';




export default function ReferrerInvitationPage() {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');

  const dispatch = useDispatch()

  const referrerInvite = {
    firstname: '', 
    surname: '',
    email: '', 
    phone: '',
    organisation: ''
  }

  const [status, setStatus] = useState('Not Started');
  
  // when the invite is completed, show the confirmation message
  if (status === 'Submitted') 
  {
    return <ReferrerConfirmation />
  }
  // otherwise, load the client registration form and all its hooplah...
  else 
  {
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
        return v.toString(16);
        });
    }
  
    function submitForm(data){
        dispatch(Registration.actions.postReferrerInvite({ ...data, invitetoken: uuidv4() }))
        setStatus('Submitted')
    }

    const validationSchema = Yup.object().shape({
        firstname:  Yup.string()
        .min(2,"Too Short")
        .max(255,"Too Long")
        .required("First Name is required"),
        surname:  Yup.string()
        .min(2,"Too Short")
        .max(255,"Too Long")
        .required("Surname is required"),
        email: Yup.string()
        .min(2,"Too Short")
        .max(200,"Too Long")
        .email("Enter a valid email")
        .required("Email is required"),
        phone:  Yup.string()
        .min(2,"Too Short")
        .max(12,"Too Long")
        .required("Phone Number is required"),
        organisation:  Yup.string()
        .min(2,"Too Short")
        .max(250,"Too Long")
        .required("Organisation is required"),
    });

    return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
            id="kt_login"
            className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{
                  padding: 20,
                  maxWidth: 320,
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
                }}
              >
                <div className="kt-grid__item">
                  <Link to="/auth/login" className="kt-login__logo">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl("/media/logos/logo-3.png")}
                      style={{ marginLeft: -10 }}
                    />
                  </Link>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
                  <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                    <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                      Welcome to the Referrer Registration page.
                    </h4>
                  </div>
                </div>
                <div className="kt-grid__item">
                  <div className="kt-login__info">
                    <div className="kt-login__copyright">
                      &copy; 2024 Rural Health Connect
                    </div>
                    <div className="kt-login__menu">
                    
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
                
                <Formik
                  enableReinitialize={true}
                  initialValues={referrerInvite}
                  validationSchema = {validationSchema}
                  onSubmit={(values, actions) => {
                    submitForm(values);
                    actions.setSubmitting(false);
                  }}
                >
                  {props => {
                    const { values, errors, touched, handleChange, handleSubmit } = props;
                    const change = (name,e) => { e.persist();  handleChange(e); };
                    return (
                      <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >
                        <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20, maxWidth: 800 }}>
                          {/* <Row>
                            <Col md="12">
                              <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                                <Grid item md={9} xs={12}>
                                  <h2>Referrer Registration of Interest Form</h2>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                                  * indicates required fields
                                </Grid>
                              </Grid>
                            </Col>
                          </Row>
                          <hr style={{ marginTop: 4 }}></hr> */}
                          <Alert  variant="light" style={{ backgroundColor: "#DAF3F4", borderColor: "#CEE6E7", marginBottom: 15 }}>
                            <p>Case managers/support workers/plan managers, or similar, we can create a login for you that will allow you to refer easily and securely through our system? 
                            You can also assist in selecting the psychologist and booking an appointment instantly. <br/><br/>
                            If so please enter your details here and we will be in touch:</p>
                          </Alert>
                          <hr style={{ marginTop: 4 }}></hr>
                          <Row>
                            <Col md="4">
                              <Form.Group >
                                <Form.Label>First Name *</Form.Label>
                                <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                                <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col md="4">
                              <Form.Group >
                                <Form.Label>Surname *</Form.Label>
                                <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="8">
                                <Form.Group >
                                <Form.Label>Email *</Form.Label>
                                <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <Form.Group >
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control type="text" name="phone" value={values.phone} onChange={change.bind(null, "phone")} isInvalid={touched.phone && !!errors.phone} isValid={touched.phone && !errors.phone} autoComplete='new-password' />
                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>                
                          </Row>
                          <Row>
                            <Col md="12">
                              <Form.Group>
                                <Form.Label>Organisation</Form.Label>
                                <Form.Control type="text" name="organisation" value={values.organisation} onChange={change.bind(null, "phone")} isInvalid={touched.organisation && !!errors.organisation} isValid={touched.organisation && !errors.organisation} autoComplete='new-password' />
                                <Form.Control.Feedback type="invalid">{errors.organisation}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <Grid container direction="row" justifyContent="space-between">
                                <Grid item md={3} xs={6}> 

                                </Grid>
                                <Grid item md={1} xs={6}>
                                  <Button variant="primary" type="submit" >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Col>
                          </Row>
                        </Paper>
                      </form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function ReferrerConfirmation() {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');
  return (

    <>
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
            }}
          >
            <div className="kt-grid__item">
              <Link to="/" className="kt-login__logo">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-3.png")}
                  style={{ marginLeft: -10 }}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                  Welcome to the Referrer Registration page.
                </h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info">
                <div className="kt-login__copyright">
                  &copy; 2024 Rural Health Connect
                </div>
              </div>
            </div>
          </div>

          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
            
          <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
            <h3>
              Registration of Interest successful.
            </h3>
            <p style={{ marginTop: 30 }}>
              Thank you for submitting your information. We will be in touch with you soon regarding your registration.
            </p>
          </Paper>
          </div>
          </div>
        </div>
      </div>
    </>

  );
}

