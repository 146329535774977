import React, { useEffect, useState } from "react";  
import { useSelector, useDispatch } from 'react-redux';
import * as Reference from "../../store/ducks/reference.duck";
import * as Admin from "../../store/ducks/admin.duck";
import * as Auth from "../../store/ducks/auth.duck";
import * as Forms from "../../store/ducks/forms.duck";
import { Scheduler, WeekView, MonthView, SchedulerItem, SchedulerItemContent } from "@progress/kendo-react-scheduler";
import { Modal } from "react-bootstrap";
import { FormWithCustomEditor } from "../../components/scheduler/custom-form";
import { IntlProvider, load } from "@progress/kendo-react-intl";
import { guid } from "@progress/kendo-react-common";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/en-AU/numbers.json";
import currencies from "cldr-numbers-full/main/en-AU/currencies.json";
import caGregorian from "cldr-dates-full/main/en-AU/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/en-AU/dateFields.json";
import { Day } from "@progress/kendo-date-math";
import { parseISO, differenceInMinutes } from "date-fns";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { ToastContainer, Flip } from "react-toastify";
import ToDos from '../../components/ToDos';
import UpdatePassword from '../../components/UpdatePassword'

import TileGrid from '../../components/DashStyle/TileGrid';						// RHC-DASH-STYLE
import InterestsPanels from '../../components/DashStyle/InterestsPanels';		// RHC-DASH-STYLE
import BookingApproval from '../../components/BookingApproval'

import '../../../_metronic/_assets/css/app2.css';

load(  likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields);

const displayDate = new Date();

export default function DashboardPsychologist(props) {
  
  const dispatch = useDispatch()
  
  const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
  const bookings = useSelector(state =>  state.admin.Bookings)
  const availability = useSelector(state =>  state.admin.Availability)
  const clients = useSelector(state => state.admin.Clients)
  const userTZ = useSelector(state => state.auth.userTZ)
  const isRecovery = useSelector(state => state.auth.recoveryStatus)
  const providerBookingApproval = useSelector(state => state.admin.ProviderBookingApproval)
  const [showToDo, setShowToDo] = useState(false)
  const [toDoClientID, setToDoClientID] = useState()
  const [toDoTitle, setToDoTitle] = useState('')
  const [showUpdatePassword,setShowUpdatePassword] = useState(false)
  
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  // May need to consider this - do providers need to see inactive clients? 
  useEffect( () => { dispatch(Admin.actions.fetchClients({status : 'Active'})) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractitioners()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchMedicalPractices({ type: 'GeneralPractitioner' })) }, [dispatch] )
  useEffect( () => { dispatch(Forms.actions.fetchForms()) }, [dispatch] )
  
  useEffect( () => { dispatch(Reference.actions.fetchTimeZone()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchHelpItems()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchResourceItems()) }, [dispatch] )
  useEffect( () => { dispatch(Admin.actions.fetchBookingTypeStatistics()) }, [dispatch] )
  useEffect( () => {dispatch(Admin.actions.fetchProviderBookingApproval())}, [dispatch] )

  useEffect( () => { if(registered && userTZ)
    {
      dispatch(Admin.actions.fetchBookings({ key: 'psychologist', id: registered.id })) 
      dispatch(Admin.actions.fetchAvailability({ psychologistid: registered.id, userTZ: userTZ }))
      // setBulkBillingAvailable(registered.bulkBillingAvailable)
      // console.log('setting')
      // console.log(registered && registered.bulkBillingAvailable)
    }
  }, [dispatch, registered, userTZ] )

  useEffect( () => {
    if (isRecovery === 'Recovery') {
      dispatch(Auth.actions.clearRecovery())
      setShowUpdatePassword(true)
    }
  }, [dispatch,isRecovery])

  const loadToDos = (clientID, fullName) => {
    // console.log(clientID)
    // console.log(fullName)
    setToDoClientID(clientID)
    setToDoTitle(fullName)
    setShowToDo(true)
  }

  function isTodayMonday() {
    //
    var response = false;
    var today = new Date();
    //
    var day = today.getDay() || 7; // Get current day number, converting Sun. to 7
    if  (day === 1)  
    {
      response = true;
    }
    // console.log(day)
    //
    return response;
  }


  // Assemble data from bookings and availability
  const newBookings = bookings.filter(r => r.status !== 'Cancelled').map(row => { 

    return {
      id: row.id,
      clientID: row.clientID,
      eventType: 'booking',
      title: row.clientfullname,
      start: parseISO(row.startDate),
      end: parseISO(row.endDate),
      note: row.note,
      paymentType: row.paymentType,
      bookingType: row.bookingType,
      status: row.status,
      contactMobilePhone: row.contactMobilePhone,
      showTodoIcon: row.toDoCount !== 0 ? true : false,
      showRHCManagementLabel: row.rhcmanagement === 'Y' ? true : false,
      appointmenttype: row.appointmenttype
    } 

  })
  const newAvailability = availability.map(row => { 
    //
    // Handle change of availabilityType for Display (i.e. Any = All)
    let availabilityTypeDisplay = 'Available - New Client Bookings';
    if (row.availabilityType !== 'Any' 
      && row.availabilityType !== 'New Client Bookings' 
      && row.availabilityType !== 'Other' 
      && row.availabilityType !== 'NDISContractEAP' 
      && row.availabilityType !== 'NDISContractEAPNew' 
      && row.availabilityType !== 'NDISContractEAPExisting' 
      && row.availabilityType !== 'PerinatalGriefLoss'
      && row.availabilityType !== 'NthQLDPHNNew'
      && row.availabilityType !== 'NthQLDPHNExisting'
      && row.availabilityType !== 'NthQLDPHNFacetoFace'
    )
    {
      availabilityTypeDisplay = 'Available - Existing';
    }
    if (row.availabilityType === 'Other')
    {
      availabilityTypeDisplay = 'Other (Not Available)';
    }
    if (row.availabilityType === 'Supervision')
    {
      availabilityTypeDisplay = 'Available - Supervision';
    }
    if (row.availabilityType === 'NDISContractEAP')
    {
      availabilityTypeDisplay = 'Available - NDIS/Contract/EAP';
    }
    if (row.availabilityType === 'NDISContractEAPNew')
    {
      availabilityTypeDisplay = 'Available - New Client or NDIS/Contract/EAP';
    }
    if (row.availabilityType === 'NDISContractEAPExisting')
    {
      availabilityTypeDisplay = 'Available - Existing Client or NDIS/Contract/EAP'
    }
    if (row.availabilityType === 'PerinatalGriefLoss')
    {
      availabilityTypeDisplay = 'Available - Perinatal Grief & Loss'
    }
    if (row.availabilityType === 'NthQLDPHNNew')
    {
      availabilityTypeDisplay = 'Available - Nth QLD PHN New Clients'
    }
    if (row.availabilityType === 'NthQLDPHNExisting')
    {
      availabilityTypeDisplay = 'Available - Nth QLD PHN Existing Clients'
    }
    if (row.availabilityType === 'NthQLDPHNFacetoFace')
    {
      availabilityTypeDisplay = 'Available - Nth QLD PHN Face to Face Clients'
    }

    //
    return {
      id: row.id,
      availabiltyType: row.availabilityType,
      eventType: 'availability' + row.availabilityType,
      psychologistID: row.psychologistID,
      availabilityType: row.availabilityType,
      title: availabilityTypeDisplay,
      start: new Date(row.startDate),
      end: new Date(row.endDate),
      recurrenceRule: row.rRule && row.rRule.length > 0 ? row.rRule.replace("RRULE:", "") : undefined,
      recurrenceExceptions: row.exDate.length > 0 ? row.exDate.split(',').map((item) => item.length === 16 ? parseISO(item) : new Date(item)) : []
    } 
  })
  const items = [...newBookings, ...newAvailability]

  // create ref to vars we need to submit with actions, so we can fetch the vales after they have been set by useEffect
  const payloadParams = {
    psychologistID: registered && registered.id,
    userTZ: userTZ
  }
  const payloadRef = React.useRef(payloadParams);
    React.useEffect(() => {
      payloadRef.current = payloadParams;
  }, [payloadParams]);

  // Custom item to apply colour for bookings/paymentType vs. availability
  const CustomItem = (props) => {
    var backgroundStyles = { backgroundColor: 'blue' }
    switch (props.dataItem.eventType)
    {
      case 'availability':
        backgroundStyles = { backgroundColor: 'blue'}
      break
      case 'availabilityExisting':
        backgroundStyles = { background: 'repeating-linear-gradient(45deg, #575ec2, #575ec2 10px, blue 10px, blue 20px)' }
      break
      case 'availabilityAny' :
        backgroundStyles = { backgroundColor: 'blue'}
      break
      case 'availabilityOther' :
        backgroundStyles = { backgroundColor: 'purple'}
      break
      case 'availabilitySupervision' :
        backgroundStyles = { backgroundColor: 'gray'}
      break
      case 'availabilityNDISContractEAP' : 
        backgroundStyles = { backgroundColor: '#21B6A8'}
      break
      case 'availabilityNDISContractEAPNew' :  
        backgroundStyles = { backgroundColor: '#CDB199'}
      break;
      case 'availabilityNDISContractEAPExisting' :   
        backgroundStyles = { backgroundColor: '#189AB4'}
      break;
      case 'availabilityPerinatalGriefLoss' :   
        backgroundStyles = { backgroundColor: '#E1C340'}
      break;
      case 'availabilityNthQLDPHNNew' :   
        backgroundStyles = { backgroundColor: '#D3B1C2'}
      break;
      case 'availabilityNthQLDPHNExisting' :   
        backgroundStyles = { backgroundColor: '#C197D2'}
      break;
      case 'availabilityNthQLDPHNFacetoFace' :   
        backgroundStyles = { backgroundColor: '#f6c324'}
      break;
      default:
        backgroundStyles = { backgroundColor: 'blue'}
    }
    switch (props.dataItem.paymentType) {
      case 'Provisional':
        backgroundStyles = { backgroundColor: 'pink'}
        break
      case 'Bulk Billed':
        backgroundStyles = { backgroundColor: 'green'}
        break
      case 'NDIS':
        backgroundStyles = { backgroundColor: 'rgb(103 39 113)'}
        break
      case 'Reduced Gap':
        backgroundStyles = { backgroundColor: '#6acccc'}
        break
      case 'Contract':
        backgroundStyles = { backgroundColor: '#256A87'}
        break
      case 'EAP':
        backgroundStyles = { backgroundColor: '#FFC5D0'}
        break
      case 'Full Fee':
        backgroundStyles = { backgroundColor: '#cfb94a'}
        break
      default:
        break
    }
    var customStyles = { ...backgroundStyles, fontSize: 12,  }
    return (
      <SchedulerItem
        {...props}
        style={{ ...props.style, ...customStyles }}
      >
        {props.children}
        {props.dataItem.eventType === 'booking' && props.dataItem.appointmenttype === "1" ?
          <SchedulerItemContent>
            <PhoneIcon />
          </SchedulerItemContent>
        : null}
        {props.dataItem.eventType === 'booking' && props.dataItem.showTodoIcon ?
          <SchedulerItemContent>
            <EmailIcon onClick={()=>loadToDos(props.dataItem.clientID,props.dataItem.title)} />
        </SchedulerItemContent>
        : null }
        {props.dataItem.showRHCManagementLabel ?
          <SchedulerItemContent>
            (RHC Managed)
          </SchedulerItemContent>
        : null }
      </SchedulerItem>
    )};


  // We manage both local state and redux state, to make the UI more responsive and not wait for redux.
  // data = local state, updated directly by change events - the calendar displays from this array
  // items = redux state, updated via actions. We only update local from this once the items != prevItems (i.e. redux has changed)
  // So, the sequence is update => update data => send action => items get updated from redux => is now different to prevItems => update data (again) with fresh copy from items
  const [data, setData] = React.useState([]);
  const [prevItems, setPrevItems] = useState([]);

  useEffect( () => {
    if((JSON.stringify(items) !== JSON.stringify(prevItems)) && items.length > 0){
      setPrevItems(items)
      setData(items)
    }
   }, [prevItems, items])

 function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  } 

  const getUpdateTitle = (item) => {
    switch (item.eventType)
    {
      case 'availability':
        return 'Availability'
      case 'availabilityAny' :
        return 'Availability - New Client (Medicare) Bookings'
      case 'availabilityOther' :
        return 'Availability - Other (Not Available)'
      case 'availabilityExisting':
        return 'Availability - Existing (Medicare)'
      case 'availabilitySupervision':
          return 'Availability - Supervision'
      case 'availabilityNDISContractEAP' :  
          return 'Availability - NDIS/Contract/EAP'
      case 'availabilityNDISContractEAPNew' :  
          return 'Availability - New Client or NDIS/Contract/EAP'
      case 'availabilityNDISContractEAPExisting' :  
          return 'Availability - Existing Client or NDIS/Contract/EAP'
      case 'availabilityPerinatalGriefLoss' :   
          return 'Availability - Perinatal Grief & Loss'
      case 'availabilityNthQLDPHNNew' :   
          return 'Availability - Nth QLD PHN New'
      case 'availabilityNthQLDPHNExisting' :   
          return 'Availability - Nth QLD PHN Existing'
      case 'availabilityNthQLDPHNFacetoFace' :   
          return 'Availability - Nth QLD PHN Face to Face Clients'
      default:
        return clients.find(row => row.id === parseInt(item.clientID)).fullname
    }
  }

  //  { created, updated, deleted }
  const handleDataChange = (props) => {
    setData((old) =>
      old
        .filter(
          (item) => (props.deleted.find( (current) => current.id === item.id ) === undefined) && (props.updated.find( (current) => current.id === item.id && current.status === 'Cancelled' ) === undefined )
        )
        .map(
          (item) => props.updated.find((current) => current.id === item.id) || item
        )
        .concat(
          props.created.map((item) =>
            Object.assign({}, item, {
              id: guid(),
              title: getUpdateTitle(item), // get the proper title to display (e.g. client fullname for bookings)
            })
          )
        )
    );
    var payload = {};
    var duration = 0;
    // Updated
    if(props.updated.length > 0)
    {
      var changed = props.updated[0];
      // console.log(changed.eventType);
      if(changed.eventType === 'availability' 
      || changed.eventType === 'availabilityAny' 
      || changed.eventType === 'availabilityExisting'  
      || changed.eventType === 'availabilityOther' 
      || changed.eventType === 'availabilitySupervision' 
      || changed.eventType === 'availabilityNDISContractEAP' 
      || changed.eventType === 'availabilityNDISContractEAPNew' 
      || changed.eventType === 'availabilityNDISContractEAPExisting'
      || changed.eventType === 'availabilityPerinatalGriefLoss'
      || changed.eventType === 'availabilityNthQLDPHNNew'
      || changed.eventType === 'availabilityNthQLDPHNExisting'
      || changed.eventType === 'availabilityNthQLDPHNFacetoFace'
      )   
      {
        payload = {
          slot: changed.id,
          id: changed.id,
          mainID: changed.psychologistID,
          startDate: changed.start,
          endDate: changed.end,
          title: changed.title,
          allDay: false,
          rRule: changed.recurrenceRule,
          availabilityType: changed.availabilityType,
          exDate: changed.recurrenceExceptions ? changed.recurrenceExceptions.join(", ") : '',
          userTZ: payloadRef.current.userTZ
        }
        dispatch(Admin.actions.chgAvailability(payload))
      }
      else if(changed.eventType === 'booking')
      {
        // If the event being changed has recently been created and has not saved, it has a GUID for an ID and cannot be changed by the API - thus bounce it
        if (isNumeric(changed.id))
        {
          duration = differenceInMinutes(new Date(changed.end), new Date(changed.start));
          var status = changed.status === 'Active' ? 'Updated' : changed.status;
          payload = { 
            id: changed.id, 
            status: status,
            appointment: {
              id: changed.id, 
              userTZ: 'UTC', //done
              startDate: changed.start, 
              duration: duration,
              clientID: changed.clientID, // done
              psychologistID: payloadRef.current.psychologistID, // done
              note: changed.note,
              paymentType: changed.paymentType,
              bookingType: changed.bookingType,
              status: status
            },
            refreshPayload: { key: 'psychologist', id: payloadRef.current.psychologistID } 
          }
          dispatch(Admin.actions.putAppointment(payload))
        }
      }      
    }
    // Added
    if(props.created.length > 0)
    {
      var added = props.created[0];
      if(added.title === 'a' 
      || (added.eventType === 'availability' 
      || added.eventType === 'availabilityAny' 
      || added.eventType === 'availabilityExisting' 
      || added.eventType === 'availabilityOther' 
      || added.eventType === 'availabilitySupervision' 
      || added.eventType === 'availabilityNDISContractEAP' 
      || added.eventType === 'availabilityNDISContractEAPNew' 
      || added.eventType === 'availabilityNDISContractEAPExisting'
      || added.eventType === 'availabilityPerinatalGriefLoss'
      || added.eventType === 'availabilityNthQLDPHNNew'
      || added.eventType === 'availabilityNthQLDPHNExisting'
      || added.eventType === 'availabilityNthQLDPHNFacetoFace'
      ))
      {
        // Need to determine availabilityType
        let availabilityType;
        switch (added.eventType)
        {
          case 'availability':
            availabilityType = 'Any'
          break
          case 'availabilityExisting':
            availabilityType = 'Existing'
          break
          case 'availabilityOther':
            availabilityType = 'Other'
          break
          case 'availabilitySupervision':
            availabilityType = 'Supervision'
          break
          case 'availabilityNDISContractEAP':
            availabilityType = 'NDISContractEAP'
          break
          case 'availabilityNDISContractEAPNew' :  
            availabilityType = 'NDISContractEAPNew'
          break;
          case 'availabilityNDISContractEAPExisting' :   
            availabilityType = 'NDISContractEAPExisting'
          break;
          case 'availabilityPerinatalGriefLoss' :
            availabilityType = 'PerinatalGriefLoss'
          break 
          case 'availabilityNthQLDPHNNew' :
            availabilityType = 'NthQLDPHNNew'
          break 
          case 'availabilityNthQLDPHNExisting' :
            availabilityType = 'NthQLDPHNExisting'
          break 
          case 'availabilityNthQLDPHNFacetoFace' :
            availabilityType = 'NthQLDPHNFacetoFace'
          break 
          case 'availabilityAny' :
            availabilityType = 'Any';
          break;
          default:
            availabilityType = 'Any';
        }
        payload = {
          mainID: payloadRef.current.psychologistID,
          startDate: added.start,
          endDate: added.end,
          title: added.title,
          allDay: false,
          rRule: added.recurrenceRule,
          availabilityType: availabilityType,
          exDate: '',
          userTZ: payloadRef.current.userTZ
        }
        dispatch(Admin.actions.addAvailability(payload))
      }
      else if(added.eventType === 'booking')
      {
        duration = differenceInMinutes(new Date(added.end), new Date(added.start));
        payload = { 
          timezone: 'UTC', //done
          newbookingdate: added.start, 
          duration: duration,
          clientID: added.clientID, // done
          psychologistID: payloadRef.current.psychologistID, // done
          note: added.note,
          paymentType: added.paymentType,
          bookingType: added.bookingType,
          refreshPayload: { key: 'psychologist', id: payloadRef.current.psychologistID },
          source: 'ProviderPortal'
        }
        dispatch(Admin.actions.bookAppointment(payload))
      }      
    }
    // Deleted
    if(props.deleted.length > 0)
    {
      var deleted = props.deleted[0];
      if(deleted.eventType === 'availability' 
      || deleted.eventType === 'availabilityAny' 
      || deleted.eventType === 'availabilityExisting'  
      || deleted.eventType === 'availabilityOther' 
      || deleted.eventType === 'availabilitySupervision' 
      || deleted.eventType === 'availabilityNDISContractEAP' 
      || deleted.eventType === 'availabilityNDISContractEAPNew' 
      || deleted.eventType === 'availabilityNDISContractEAPExisting'
      || deleted.eventType === 'availabilityPerinatalGriefLoss'
      || deleted.eventType === 'availabilityNthQLDPHNNew'
      || deleted.eventType === 'availabilityNthQLDPHNExisting'
      || deleted.eventType === 'availabilityNthQLDPHNFacetoFace'
    )
      {
        payload = {
          slot: deleted.id,
          mainID: payloadRef.current.psychologistID,
          userTZ: payloadRef.current.userTZ
        }
        dispatch(Admin.actions.delAvailability(payload))
      }
      else if(deleted.eventType === 'booking')
      {
        dispatch(Admin.actions.putAppointment({ 
          id: deleted.id, 
          status: 'Cancelled',
          appointment: {
            id: deleted.id, 
            userTZ: 'UTC', //done
            startDate: deleted.start, 
            clientID: deleted.clientID, // done
            psychologistID: payloadRef.current.psychologistID, // done
            status: status
          },
          refreshPayload: { key: 'psychologist', id: payloadRef.current.psychologistID } 
        }))
      }      
    }
  };
  

  return(
    <>
      <TileGrid history={props.history} />
      {isTodayMonday() ?
			  <InterestsPanels />
      :
        null
      }
      {providerBookingApproval && providerBookingApproval.length > 0 ?
        <BookingApproval />
      :
        null
      } 
      <div className="row">          
        <div className="col-xl-12">
            <div className="kt-portlet kt-portlet--height-fluid">             
              <div className="kt-portlet__body" style={{ padding: 0 }}>
                <div className="kt-widget4">
                <IntlProvider locale="en-AU">
                  <Scheduler 
                    data={data} 
                    onDataChange={handleDataChange}
                    item={CustomItem}
                    defaultDate={displayDate}    
                    editable={{ add: true, remove: true, drag: true, resize: true, edit: true }}                       
                    form={FormWithCustomEditor}
                    // header={CustomHeader}
                    height={900} >
                    <WeekView
                      title="Week"
                      workDayStart="06:00"
                      workDayEnd="22:00"
                      selectedDateFormat="{0:M}"
                      selectedShortDateFormat="{0:M}"
                      workWeekStart={Day.Monday}
                      
                    />
                    <MonthView />
                  </Scheduler>
                </IntlProvider>
                </div>
              </div>
            </div>
        </div>
      </div>
        
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      


      <Modal show={showToDo} onHide={()=>setShowToDo(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>To Do - {toDoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          {toDoClientID && <ToDos clientID={toDoClientID} setShowToDo={setShowToDo}   />}
        </Modal.Body>
      </Modal>


      <Modal show={showUpdatePassword} onHide={()=>setShowUpdatePassword(false)} dialogClassName="todos-modal-dialog">
        <Modal.Header closeButton className="todos-modal">
          <Modal.Title>You have recently recovered your password, please update your password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="todos-modal">
          <UpdatePassword setShowUpdatePassword={setShowUpdatePassword} />
        </Modal.Body>
      </Modal>


    </>

  )

}
