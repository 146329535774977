import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Admin from "../store/ducks/admin.duck";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Grid } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
// import { makeStyles } from '@material-ui/core';

export default function ProviderProgramBio(props) {
    
  const dispatch = useDispatch()
  const providerProgram = useSelector(state => state.admin.ProgramProviderBio)
  var [providerBio,setProviderBio] = useState(providerProgram.programbio)
  


  function addProviderBIO() {
    // Update BIO 
    dispatch(Admin.actions.putProviderProgramBio({ id: providerProgram.id, bio: providerBio }));
    props.onCloseCustomBio();
  }

  return(
    <Grid item md={12}>  
      <Row>
        <Col md="12">
          <Form.Group >
            <Form.Label>Custom Bio for Program - {providerProgram && providerProgram.name}</Form.Label>
              <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                value={providerBio || providerProgram.programbio}
                onEditorChange={(newValue, editor)=>setProviderBio(newValue)}
                init={{ branding: false, statusbar: false, height: 500 }}
              />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Grid container spacing={0} padding={6} justifyContent="space-between">
            <Button variant="light" onClick={()=>props.onCloseCustomBio()}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={() => addProviderBIO()}>
              Save
            </Button>
          </Grid>
        </Col>
      </Row>
    </Grid>
  )


}
  
