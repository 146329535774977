import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';

import * as Admin from "../../../store/ducks/admin.duck";
import { Grid, Paper } from '@material-ui/core';

import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function MedipassCodes() {

  const [searchTerm, setSearchTerm] = useState('')
  const medipassCodes = useSelector(state => state.admin.MedipassCodes)
  // const psychologistMedipassCodes = medipassCodes.filter(medipasscode => medipasscode.istemplate === 0)
  const filteredMedipassCodes = medipassCodes.filter(medipasscode => medipasscode.description.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchMedipassCodes()) }, [dispatch] )

  var [show, setShow] = useState(false);
  var [currentCodeID,setCurrentCodeID] = useState();
  var [currentCode,setCurrentCode] = useState();
  var [currentDescription,setCurrentDescription] = useState();
  var [currentCost,setCurrentCost] = useState();


  const loadCodeDetail = (thisCode) => {
    setCurrentCodeID(thisCode.id);
    setCurrentCode(thisCode.code);
    setCurrentDescription(thisCode.description);
    setCurrentCost(thisCode.cost);
    setShow(true)
  }

  const createNewCode = (thisCode) => {
    setCurrentCodeID(0);
    setCurrentCode();
    setCurrentDescription();
    setCurrentCost('0');
    setShow(true)
  }

  function putCode() {
    // Create payload to send to API 
    var payload = {
      id: currentCodeID,
      code: currentCode,
      description: currentDescription,
      cost: currentCost
    }
    //
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.putMedipassCode(payload));
    setShow(false);
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'code', headerName: 'Code', width: 100,  
      renderCell: (params) => (
        <strong>
          {params.row.istemplate === 0 ?
            <div onClick={() => loadCodeDetail(medipassCodes.find(medipasscode => medipasscode.code === params.row.code))} className="cell-Link" >
              {params.value}
            </div>
          :
            <div>
              {params.value}
            </div>
        }
        </strong>
      ),  
    },
    { field: 'description', headerName: 'Description', width: 500 },
    { field: 'cost', headerName: 'Cost', width: 120 },
    { field: 'istemplate', headerName: 'Template', width:150,
      renderCell: (params) => (
        <>
          {params.row.istemplate === 0 ?
            'N'
          :
            'Y'
          }
        </>
      ),  
    }
  ];

  
  function handleClose() {
    setShow(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Medicare Code Maintenance</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => createNewCode()}>
              Create Code
            </Button>
          </Grid>
        </Grid>
      </Paper>


      { medipassCodes ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredMedipassCodes} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} />
        </Paper>
      : ('None')
      }

      <Modal show={show} onHide={handleClose} dialogClassName="contracts-modal">
        <Modal.Header closeButton className="contracts-modal">
          <Modal.Title>Code Maintenance </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contracts-modal">
          <Row>
            <Col md="3">
              <Form.Group >
                <Form.Label>Medicare Code</Form.Label>
                <Form.Control type="text" name="Code" defaultValue={currentCode} onBlur={(event) => setCurrentCode(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Description</Form.Label>
                <textarea className="form-control" rows="6" name="Description" defaultValue={currentDescription} onBlur={(event) => setCurrentDescription(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Form.Group >
                <Form.Label>Cost</Form.Label>
                <Form.Control type="text" name="Cost" defaultValue={currentCost} onBlur={(event) => setCurrentCost(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="contracts-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putCode}>
            Save
          </Button>
        </Modal.Footer>

      </Modal>

      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
