import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import PlacesAutoComplete from "../../components/AddressAutoComplete";
import * as Reference from "../../store/ducks/reference.duck";
import * as Registration from "../../store/ducks/registration.duck";
import { Button, Modal, Form, Row, Col, InputGroup, Image, Badge } from "react-bootstrap";
import { Paper, Typography, makeStyles, Grid, Checkbox, FormControlLabel, Card, CardMedia, CardContent, Chip } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from "yup";
import Interweave from 'interweave'
import { Editor } from '@tinymce/tinymce-react'
import Alert from '@material-ui/lab/Alert';
import { useMediaQuery } from '@material-ui/core';
import { toAbsoluteUrl } from "../../../_metronic";
import Masonry from 'react-masonry-css';
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import '../../../_metronic/_assets/css/app2.css';


const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  media: {
    height: 200,
  },
  root: {
    "& .MuiInputBase-root": {
      height: 33,
    }
  }  
}));

export default function PsychologistRegistrationPage() {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');

  const { invitetoken } = useParams();
  const dispatch = useDispatch()
  const classes = useStyles()
  var [currentprofilepicture,setcurrentprofilepicture] = useState({ name: '' })
  
  // now init the fields with values so that Formik can manage the touched state
  const psychologistInit = {
    firstname: '', 
    surname: '', 
    gender: 'Female',
    email: '', 
    mobilephone: '',
    education: '',
    bio: '',

    practicename: '',
    abn: '',
    fulladdress: '',
    latitude: '',
    longitude: '',
    postcode: '',

    providernumber : '',
    insurancenumber: 'AS78FDS654',
    hourlyrate: '',
    reducedFee:'',

    startedpracticingyear: 2000,
    newInsuranceFile: '',
    
    areasOfInterest_focus: [],
    areasOfInterest_other: [],
    fundingTypes: [],
    mbsServices: [],
    newProfilePicture: currentprofilepicture,

    pdfpassword: Math.floor(1000 + Math.random() * 9000),
    profilePassword: '',
    profilePasswordConfirmation: '',
    timezone: 'AUS Eastern Standard Time',
    invitetoken: invitetoken,
    agreementAcceptance: false,
    psychologistid : null,
    providertype : '',
    apikey: '',
    ahpraRegistrationNo: '',
    providerdescription : 'Psychologist',
    specialistType : 'Standard'
  }

  useEffect( () => { dispatch(Registration.actions.fetchPsychologistInvite(invitetoken))  }, [dispatch, invitetoken] )
  useEffect( () => { dispatch(Reference.actions.fetchConstants()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchFundingTypes()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchSundayThisWeek()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchMBSServices()) }, [dispatch] )
  
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)
  var [previewFile, setPreviewFile] = useState('')
  var [currentprofilepicturetouched, setcurrentprofilepicturetouched] = useState(false);
  var [regStatus, setregStatus] = useState('Not Started')
  var [showRegistrationView, setShowRegistrationView] = useState(false)   
  var [showProviderBio,setShowProviderBio] = useState(false)
  var [providerBioFullName,setProviderBioFullName] = useState()
  var [providerBioBio,setProviderBioBio] = useState()
  var [currentPaymentType,setCurrentPaymentType] = useState('Reduced Gap')

  function handleRegistrationViewClose() {
    setShowRegistrationView(false);
  }  

  // get the invite (if any) from state and merge with the initialised fields
  const inviteInit = useSelector(state =>  state.registration.PsychologistInvite)
  var [providerRegistrationView,setProviderRegistrationView] = useState(psychologistInit)
  const psychologistInvite = {...psychologistInit, ...inviteInit};

  // based on the provider type, filter the AOI
  const aoi_focus = areasOfInterestReference.filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === psychologistInvite.providertype)
  const aoi_other = areasOfInterestReference.filter(aoi => aoi.focusAreaIndex === null && aoi.providertype === psychologistInvite.providertype)
  const [prevpsychologistInvite, setprevpsychologistInvite] = useState('');

  const registrationView = (values,feeType) => {
    // Populate Modal which contains preview of Provider Registration Information
    setCurrentPaymentType(feeType)
    //
    var calc_hourlyrate = 0.00
    var calc_standardFeeOutOfPocket = 0.00
    var calc_outOfPocketStandard = 0.00
    var calc_medicareRebate = values.medicarerebate
    var calc_reducedFee = 0.00
    var calc_reducedFeeOutOfPocket = 0.00
    var calc_outOfPocket = 0.00
    // Calculation of costs etc 
    // Check that something is configured for hourly rate 
    if (!values.hourlyrate || (values.hourlyrate && (values.hourlyrate === '0' || values.hourlyrate === '0.00')))
    {
      calc_hourlyrate = 'TBD'
      calc_standardFeeOutOfPocket = 'TBD'
    }
    else
    {
      calc_outOfPocketStandard = (parseFloat(values.hourlyrate) - calc_medicareRebate).toFixed(2)
      calc_hourlyrate = values.hourlyrate
      calc_standardFeeOutOfPocket =  calc_outOfPocketStandard.toString()
    }
    // Get Reduced Fee Amount 
    if (!values.reducedFee || (values.reducedFee && (values.reducedFee === '0' || values.reducedFee === '0.00')))
      {
        calc_reducedFee = 'TBD'
        calc_reducedFeeOutOfPocket = 'TBD'
      }
      else
      {
        calc_outOfPocket = (parseFloat(values.reducedFee) - calc_medicareRebate).toFixed(2)
        calc_reducedFee =  values.reducedFee
        calc_reducedFeeOutOfPocket = calc_outOfPocket.toString()
      }
    //   
    var newValues = {...values, 
      calc_hourlyrate : calc_hourlyrate, 
      calc_standardFeeOutOfPocket : calc_standardFeeOutOfPocket,  
      calc_outOfPocketStandard : calc_outOfPocketStandard,
      calc_medicareRebate : calc_medicareRebate,
      calc_reducedFee : calc_reducedFee,
      calc_reducedFeeOutOfPocket : calc_reducedFeeOutOfPocket,
      calc_outOfPocket : calc_outOfPocket
    }
    setProviderRegistrationView(newValues)
    setShowRegistrationView(true)
  }
  
  const moreProviderBio = (fullName, bio) => {
    setProviderBioFullName(fullName)
    setProviderBioBio(bio)
    setShowProviderBio(true)
  }

  useEffect( () => {
    if ((JSON.stringify(psychologistInvite.email) !== JSON.stringify(prevpsychologistInvite.email) && (areasOfInterestReference.length === 0) && psychologistInvite.providertype.length > 0))
    {
      setprevpsychologistInvite(psychologistInvite)
      // Check if provisional if so use AOI for Psychologist 
      dispatch(Reference.actions.fetchAreasOfInterest(psychologistInvite.providertype === 'Provisional' ? 'Psychologist' : psychologistInvite.providertype))
    }
  }, [prevpsychologistInvite, psychologistInvite, areasOfInterestReference, dispatch])
  
  // when the invite is converted to a client, show the confirmation message and link to the login page
  // Old invite status test via Redux (regstatus.form === 'psychologist' && regstatus.status === 'Success' && regstatus.newID) || (psychologistInvite && psychologistInvite.hasOwnProperty('psychologistid') && psychologistInvite.psychologistid)
  if(regStatus === 'Completed') {
    return <RegistrationConfirmation title="Registration Successful!" message="Congratulations, your Rural Health Connect account has been created." enableLogin={true} />
  }
  else if (!psychologistInvite.hasOwnProperty('id'))
  {
    return <RegistrationConfirmation title="Invalid token" message="The link to register with Rural Health Connect is incomplete, already been used or has expired. Please contact us to resolve this issue." enableLogin={false} />
  }
  // otherwise, load the client registration form and all its hooplah...
  else 
  {

  function submitForm(data){

    const formData = new FormData();

    formData.append('firstname',data['firstname'])
    formData.append('surname',data['surname'])
    formData.append('gender',data['gender'])
    formData.append('email',data['email'])
    formData.append('education',data['education'])
    formData.append('mobilephone',data['mobilephone'])
    formData.append('bio',data['bio'])
    formData.append('practicename',data['practicename'])
    formData.append('abn',data['abn'])
    formData.append('fulladdress',data['fulladdress'])
    formData.append('latitude',data['latitude'])
    formData.append('longitude',data['longitude'])
    formData.append('postcode',data['postcode'])
    formData.append('providernumber',data['providernumber'])
    formData.append('insurancenumber',data['insurancenumber'])
    formData.append('ahpraRegistrationNo',data['ahpraRegistrationNo'])
    formData.append('hourlyrate',data['hourlyrate'])
    formData.append('reducedFee',data['reducedFee'])
    if (data['isReducedFee'])
    {
      formData.append('isReducedFee','Y');
    }
    else
    {
      formData.append('isReducedFee','N');
    }
    formData.append('startedpracticingyear',data['startedpracticingyear'])
    formData.append('newInsuranceFile',data['newInsuranceFile'])
    formData.append('areasOfInterest', data['areasOfInterest_focus'].concat(data['areasOfInterest_other']).map(aoi => aoi.issue));
    formData.append('fundingTypes',data['fundingTypes'].map(ft => ft.id));
    formData.append('mbsServices',data['mbsServices'].map(mbs => mbs.id));
    formData.append('pdfpassword',data['pdfpassword'])
    formData.append('profilePassword',data['profilePassword'])
    formData.append('timezone',data['timezone'])
    formData.append('invitetoken',data['invitetoken'])
    formData.append('providertype',data['providertype'])
    formData.append('newProfilePicture', currentprofilepicture)
    formData.append('specialisttype', data['specialisttype'])
    
    setcurrentprofilepicturetouched(true);
    if(currentprofilepicture.name !== undefined) {
      dispatch(Registration.actions.postPsychologistData({formData}))
      setregStatus('Completed');
    }

  }

  const validationSchema = Yup.object().shape({
    firstname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("First Name is required"),
    surname:  Yup.string()
    .min(2,"Too Short")
    .max(255,"Too Long")
    .required("Surname is required"),
    mobilephone:  Yup.string()
    .min(2,"Too Short")
    .max(12,"Too Long")
    .required("Phone Number is required"),
    gender:  Yup.string()
    .min(2,"Too Short")
    .max(20,"Too Long")
    .required("Gender is required"),
    email: Yup.string()
    .min(2,"Too Short")
    .max(200,"Too Long")
    .email("Enter a valid email")
    .required("Email is required"),
    education: Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Education is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Education is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Education is required")
      }),
    practicename: Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Too Short")
                          .max(200,"Too Long")
                          .required("Practice Name is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Too Short")
                          .max(200,"Too Long")
                          .required("Practice Name is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.string().min(2,"Too Short")
                          .max(200,"Too Long")
                          .required("Practice Name is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.string().min(2,"Too Short")
                          .max(200,"Too Long")
                          .required("Practice Name is required")
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Financial',
        then: Yup.string().min(2,"Too Short")
                          .max(200,"Too Long")
                          .required("Organisation is required")
      }),
    fulladdress:  Yup.string()
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Psychologist',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Clinical',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Occupational',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'GP',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Address is required"),
      })
      .when(['providertype'], 
      {
        is: (providertype) => providertype === 'Financial',
        then: Yup.string().min(2,"Too Short")
                          .max(100,"Too Long")
                          .required("Address is required"),
      }),
    abn:   Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(8,"Too Short")
                        .max(20,"Too Long")
                        .required("ABN is required"),
    })
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.string().min(8,"Too Short")
                        .max(20,"Too Long")
                        .required("ABN is required"),
    })
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().min(8,"Too Short")
                        .max(20,"Too Long")
                        .required("ABN is required"),
    })
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'GP',
      then: Yup.string().min(8,"Too Short")
                        .max(20,"Too Long")
                        .required("ABN is required"),
    })
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Financial',
      then: Yup.string().min(8,"Too Short")
                        .max(20,"Too Long")
                        .required("ABN is required"),
    }),
    providernumber: Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Provider Number is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Provider Number is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Provider Number is required"),
      }),
    newInsuranceFile: Yup.string()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Insurance Document is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Insurance Document is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Insurance Document is required"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'GP',
      then: Yup.string().min(2,"Too Short")
                        .max(255,"Too Long")
                        .required("Insurance Document is required"),
                  
    }),    
    hourlyrate: Yup.number()
      .when(['providertype','specialisttype'], 
      {
        is: (providertype,specialisttype) => (providertype === 'Psychologist' && specialisttype !== 'nthqldphn'),
        then: Yup.number().max(1000,"Full Fee Hourly rate is too High")
                          .required("Hourly rate is required"),
        })
        .when(['providertype','specialisttype'], 
        {
        is: (providertype,specialisttype) => (providertype === 'Clinical' && specialisttype !== 'nthqldphn'),
        then: Yup.number().max(1000,"Full Fee Hourly rate is too High")
                          .required("Full Fee Hourly rate is required"),
      }),
    reducedFee: Yup.number()
    .when(['providertype','specialisttype'], 
    {
      is: (providertype,specialisttype) => (providertype === 'Psychologist' && specialisttype !== 'nthqldphn'),
      then: Yup.number().max(1000,"Reduced Fee Hourly rate is too High")
                        .min(89,"Reduced Fee Hourly rate is too Low")
                        .required("Reduced Fee Hourly rate is required"),
      })
      .when(['providertype','specialisttype'], 
      {
      is: (providertype,specialisttype) => (providertype === 'Clinical' && specialisttype !== 'nthqldphn'),
      then: Yup.number().max(1000,"Reduced Fee Hourly rate is too High")
                        .min(89,"Reduced Fee Hourly rate is too Low")
                        .required("Reduced Fee Hourly rate is required"),
    }),
    startedpracticingyear: Yup.number()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.number().max(2027)
                        .min(1960)
                        .required('Year First Practiced is required'),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.number().max(2027)
                        .min(1960)
                        .required('Year First Practiced is required'),
    })
    .when(['providertype'], 
    {
    is: (providertype) => providertype === 'Occupational',
    then: Yup.number().max(2027)
                      .min(1960)
                      .required('Year First Practiced is required'),
    }),
    pdfpassword: Yup.string()
    .min(4,"Too Short")
    .max(4,"Too Long")
    .required("PDF Password is required"),
    profilePassword:  Yup.string()
    .min(8,"Too Short")
    .required("Password is required"),
    profilePasswordConfirmation:  Yup.string()
    .min(8,"Too Short")
    .required("Password is required")
    .oneOf([Yup.ref('profilePassword'), null], 'Passwords must match'),
    areasOfInterest:  Yup.array()
    .when(['providertype'], 
    {
      is: (providertype) => providertype === 'Psychologist',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Clinical',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Occupational',
      then: Yup.array().min(1, "Please select at least one area of interest"),
      })
      .when(['providertype'], 
      {
      is: (providertype) => providertype === 'Financial',
      then: Yup.array().min(1, "Please select at least one area of interest"),
    }),    
    agreementAcceptance: Yup.boolean()
    .oneOf([true],'You must accept the Rural Health Connect Licence Agreement'),
  });

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
    })

  return (
    <>
    {psychologistInvite ? 
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                padding: 20,
                maxWidth: 320,
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
              }}
            >
              <div className="kt-grid__item">
                <Link to="/auth/login" className="kt-login__logo">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-3.png")}
                    style={{ marginLeft: -10 }}
                  />
                </Link>
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
                <div className="kt-grid__item kt-grid__item--middle">
                  <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                  <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                    Welcome to the {psychologistInvite.providerdescription} Registration page.
                  </h4>
                </div>
              </div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    &copy; 2024 Rural Health Connect
                  </div>
                  <div className="kt-login__menu">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
              
              <Formik
                enableReinitialize={false}
                initialValues={psychologistInvite}
                validationSchema = {validationSchema}
                onSubmit={(values, actions) => {                                           
                  submitForm(values);
                  actions.setSubmitting(false);
                }}
              >
                {props => {
                  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = props;
                  const change = (name,e) => { e.persist();  handleChange(e); }; 

                  // function for checkboxes                                  
                  function getIndex(value, arr, prop) {
                    for(var i = 0; i < arr.length; i++) {
                        if(arr[i][prop] === value) {
                            return i;
                        }
                    }
                    return -1; // where the value doesn't exist
                  }

                  const handleToggle = (value) => () => {
                    const currentIndex = getIndex(value.id, values.areasOfInterest_focus, 'id') //checked.find(r => r.id === value.id);
                    const newChecked = [...values.areasOfInterest_focus];
                    if (currentIndex === -1) {
                      newChecked.push(value);
                    } else {
                      newChecked.splice(currentIndex, 1);
                    }
                    setFieldValue("areasOfInterest_focus", newChecked)
                  };

                  const handleToggleOther = (value) => () => {
                    const currentIndex = getIndex(value.id, values.areasOfInterest_other, 'id') //checked.find(r => r.id === value.id);
                    const newChecked = [...values.areasOfInterest_other];
                    if (currentIndex === -1) {
                      newChecked.push(value);
                    } else {
                      newChecked.splice(currentIndex, 1);
                    }
                    setFieldValue("areasOfInterest_other", newChecked)
                  };

                  // function to create image preview by reading file into a URL object                
                  const makePreview = (file) => {
                    if(!file) {
                      setPreviewFile('');
                      return;
                    }
                    setcurrentprofilepicture(file);
                    fileToDataUri(file)
                      .then(newFile => {
                        setPreviewFile(newFile)
                    })                      
                  }                  
                  return (
                    <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} >

            <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
              <Row>
                <Col md="12">
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid item md={6} xs={12}>
                      <h2>{psychologistInvite.providerdescription} Registration Form</h2>
                    </Grid>
                    <Grid item md={3} xs={6} style={{ fontStyle: "italic", fontWeight: 400, textAlign: "right", paddingBottom: 4, fontSize: 12 }}>
                      * indicates required fields
                    </Grid>
                  </Grid>
                </Col>
              </Row>
              <Row>
                <Col md="12">                  
                  {values.message ? (
                    <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                      {values.message}
                    </Alert>) 
                    : null
                  }
                </Col>
              </Row>
              <hr style={{ marginTop: 4 }}></hr>
              <Row>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control type="text" name="firstname" value={values.firstname} onChange={change.bind(null, "firstname")} autoComplete='new-password' isInvalid={touched.firstname && !!errors.firstname} isValid={touched.firstname && !errors.firstname}/>
                    <Form.Control.Feedback type="invalid">{errors.firstname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>Surname *</Form.Label>
                    <Form.Control type="text"  name="surname" value={values.surname} onChange={change.bind(null, "surname")}  autoComplete='new-password' isInvalid={touched.surname && !!errors.surname} isValid={touched.surname && !errors.surname}/>
                    <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>Phone Number *</Form.Label>
                    <Form.Control type="text" name="mobilephone" value={values.mobilephone} onChange={change.bind(null, "mobilephone")} isInvalid={touched.mobilephone && !!errors.mobilephone} isValid={touched.mobilephone && !errors.mobilephone} autoComplete='new-password' />
                    <Form.Control.Feedback type="invalid">{errors.mobilephone}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group >
                    <Form.Label>Gender</Form.Label>
                    <Form.Control as="select" name="gender" value={values.gender} onChange={change.bind(null, "gender")} >
                      <option value="Prefer not to say">Prefer not to say</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group >
                        <Form.Label>Email *</Form.Label>
                        <Form.Control type="text" name="email" value={values.email} onChange={change.bind(null, "email")} isInvalid={touched.email && !!errors.email} isValid={touched.email && !errors.email} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational') ?
                      <>
                        <Col md="6">
                          <Form.Group >
                            <Form.Label>Education</Form.Label>
                            <Form.Control type="text" name="education" value={values.education} onChange={change.bind(null, "education")} autoComplete='new-password'  isInvalid={touched.education && !!errors.education} isValid={touched.education && !errors.education}/>
                            <Form.Control.Feedback type="invalid">{errors.education}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>                    
                      </>
                    :
                      null
                    }
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group >
                        <Form.Label>Bio</Form.Label>
                        <Editor apiKey='c4z4i9b4mceaq88ttctptl9xwx5awpgsck0mnp06urxm4dfc'
                            value={values.bio}
                            onEditorChange={(newValue, editor)=>setFieldValue("bio", newValue)}
                            init={{ branding: false, statusbar: false, height: 500 }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.bio}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {psychologistInvite && (psychologistInvite.providertype !== 'Provisional' ) && (psychologistInvite.providertype !== 'Social') && (psychologistInvite.providertype !== 'Nurse' ) ?                                                
                    <>
                      {psychologistInvite && (psychologistInvite.providertype === 'Financial' ) ?                                                
                        <h4>Organisation Details</h4>
                      :
                        <h4>Practice Details</h4>
                      }
                      <hr></hr>
                      <Row>
                        <Col md="4">
                          <Form.Group >
                            {psychologistInvite && (psychologistInvite.providertype === 'Financial' ) ?                                                
                              <Form.Label>Organisation Name</Form.Label>
                            :
                              <Form.Label>Practice Name</Form.Label>
                            }
                            <Form.Control type="text" name="practicename" value={values.practicename} onChange={change.bind(null, "abn")}  autoComplete='new-password' isInvalid={touched.practicename && !!errors.practicename} isValid={touched.practicename && !errors.practicename}/>
                            <Form.Control.Feedback type="invalid">{errors.practicename}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>ABN</Form.Label>
                            <Form.Control type="text" name="abn" value={values.abn} onChange={change.bind(null, "abn")}  autoComplete='new-password' isInvalid={touched.abn && !!errors.abn} isValid={touched.abn && !errors.abn}/>
                            <Form.Control.Feedback type="invalid">{errors.abn}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group style={{width: "100%"}}>
                            <Form.Label>Practice Address or Town (start typing to search) *</Form.Label>
                            <PlacesAutoComplete 
                              currentID={0}
                              currentfulladdress={values.fulladdress}
                              setcurrentfulladdress = {(value) => setFieldValue("fulladdress", value)}
                              setcurrentLatitude = {(value) => setFieldValue("latitude", value)} 
                              setcurrentLongitude = {(value) => setFieldValue("longitude", value)}
                              setcurrentPostCode = {(value) => setFieldValue("postcode", value)}
                              isInvalid={touched.fulladdress && !!errors.fulladdress} 
                              isValid={touched.fulladdress && !errors.fulladdress} 
                            />
                            {touched.fulladdress && !!errors.fulladdress ? (<p className='error-help'>{errors.fulladdress}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical'  || psychologistInvite.providertype === 'Occupational'  || psychologistInvite.providertype === 'GP' || psychologistInvite.providertype === 'Provisional') ?
                    <>
                      <h4>Professional Details</h4>
                      <hr></hr>
                    </>
                  :
                    null
                  }

                  {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational' || psychologistInvite.providertype === 'GP' || psychologistInvite.providertype === 'Provisional') ?
                    <>
                      <Row>
                        {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational' || psychologistInvite.providertype === 'Provisional') ?                        
                          <>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>AHPRA Registration Number</Form.Label>
                                <Form.Control type="text" name="ahpraRegistrationNo" value={values.ahpraRegistrationNo} onChange={change.bind(null, "ahpraRegistrationNo")}  autoComplete='new-password' isInvalid={touched.ahpraRegistrationNo && !!errors.ahpraRegistrationNo} isValid={touched.ahpraRegistrationNo && !errors.ahpraRegistrationNo}/>
                                <Form.Control.Feedback type="invalid">{errors.ahpraRegistrationNo}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational') ?                        
                          <>
                            <Col md="3">
                              <Form.Group >
                                <Form.Label>Provider Number *</Form.Label>
                                <Form.Control type="text" name="providernumber" value={values.providernumber} onChange={change.bind(null, "providernumber")}  autoComplete='new-password' isInvalid={touched.providernumber && !!errors.providernumber} isValid={touched.providernumber && !errors.providernumber}/>
                                <Form.Control.Feedback type="invalid">{errors.providernumber}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational') ?                        
                          <>
                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Year First Practiced</Form.Label>
                                <Form.Control type="text" name="startedpracticingyear" value={values.startedpracticingyear} onChange={change.bind(null, "startedpracticingyear")}  autoComplete='new-password' isInvalid={touched.startedpracticingyear && !!errors.startedpracticingyear} isValid={touched.startedpracticingyear && !errors.startedpracticingyear}/>
                                <Form.Control.Feedback type="invalid">{errors.startedpracticingyear}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                        {psychologistInvite &&  psychologistInvite.providertype !== 'Provisional' ?                        
                          <>
                            <Col md="2">
                              <Form.Group>
                                <Form.Label>Insurance Document:</Form.Label>
                                <Form.Control type="file" name="newInsuranceFile" onChange={(event) => setFieldValue('newInsuranceFile', event.currentTarget.files[0])} isInvalid={touched.newInsuranceFile && !!errors.newInsuranceFile} isValid={touched.newInsuranceFile && !errors.newInsuranceFile} className="form-control" style={{ paddingTop: 6, paddingLeft: 6 }} />
                                <Form.Control.Feedback type="invalid">{errors.newInsuranceFile}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </>
                        :
                          null
                        }
                      </Row>
                    </>
                  :
                    null
                  }  
                  {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' || psychologistInvite.providertype === 'Clinical' || psychologistInvite.providertype === 'Occupational') ?                        
                    <>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>Areas of Interest (Select all that apply) *</Form.Label>
                            <br />
                              {aoi_focus.map(row => {
                                return(
                                  <FormControlLabel
                                    control={<Checkbox checked={values.areasOfInterest_focus.find(r => r.id === row.id)} onClick={handleToggle(row)} value={row.id} name="areasOfInterest_focus" />}
                                    label={row.issue}
                                    style={{ marginRight: 30 }}
                                  />)
                              })
                              }
                            
                            {touched.areasOfInterest_focus && !!errors.areasOfInterest_focus ? (<p className='error-help'>{errors.areasOfInterest_focus}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {psychologistInvite && (psychologistInvite.providertype !== 'GP') && (psychologistInvite.providertype !== 'Social' ) && (psychologistInvite.providertype !== 'Nurse' ) && (psychologistInvite.providertype !== 'Occupational' ) ?                        
                    <>
                      <Row>
                        <Col md="12">
                          <Form.Group >
                            <Form.Label>I want to provide support in these key areas of need (Select all that apply) *</Form.Label>
                            <br />
                              {aoi_other.map(row => {
                                return(
                                  <FormControlLabel
                                    control={<Checkbox checked={values.areasOfInterest_other.find(r => r.id === row.id)} onClick={handleToggleOther(row)} value={row.id} name="areasOfInterest_other" />}
                                    label={row.issue}
                                    style={{ marginRight: 30 }}
                                  />)
                              })
                              }
                            
                            {touched.areasOfInterest_other && !!errors.areasOfInterest_other ? (<p className='error-help'>{errors.areasOfInterest_other}</p>) : ''}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  {psychologistInvite && (psychologistInvite.providertype === 'Psychologist' 
                  || psychologistInvite.providertype === 'Clinical'
                  || psychologistInvite.providertype === 'Social'
                  || psychologistInvite.providertype === 'Nurse'
                  || psychologistInvite.providertype ==='Occupational') 
                  && psychologistInvite.specialisttype !== 'nthqldphn'  ?                        
                    <>
                      <h4>Client Billing Settings </h4>
                      <Form.Label onClick={() => registrationView(values,'Full Fee')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Full Fee)</Form.Label><Form.Label onClick={() => registrationView(values,'Reduced Gap')} style={{ marginLeft:10, color: 'blue', cursor:'pointer' }}>(Preview Reduced Fee)</Form.Label>
                      <hr></hr>
                      <Alert severity="info" style={{ marginBottom: 10, marginTop: 8, fontSize: 14 }}>
                          <p>Please use the review button above to check client view.</p>
                      </Alert>
                      <Row>
                        <Col md="2">
                          <Form.Group>
                            <Form.Label >Full Fee Rate (Total)*</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" name="hourlyrate" value={values.hourlyrate} onChange={change.bind(null, "hourlyrate")}  autoComplete='new-password' isInvalid={touched.hourlyrate && !!errors.hourlyrate} isValid={touched.hourlyrate && !errors.hourlyrate}/>
                                <Form.Control.Feedback type="invalid">{errors.hourlyrate}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <Form.Group >
                            <Form.Label>Reduced Fee Rate (Total)*</Form.Label>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control type="text" name="reducedFee" value={values.reducedFee} onChange={change.bind(null, "reducedFee")}  autoComplete='new-password' isInvalid={touched.reducedFee && !!errors.reducedFee} isValid={touched.reducedFee && !errors.reducedFee}/>
                              <Form.Control.Feedback type="invalid">{errors.reducedFee}</Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label style={{ fontWeight:300, marginLeft:10 }}>*Set your Reduced Fee to 0 for client bulk billing as reduced fee option</Form.Label>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Label style={{ marginTop:20 }}>Check the box below if you want this text to appear alongside your fees</Form.Label>
                          <Form.Group>
                            <Checkbox aria-label='Offer Client Pricing Flexibility' name='isReducedFee' value={values.isReducedFee === 'Y' ? true : false} onChange={change.bind(null, 'isReducedFee')} style={{ paddingTop: 6, paddingLeft: 0 }} />
                              <span style={{ fontWeight:600 }} >Some flexibility, depending on client circumstances</span>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  :
                    null
                  }
                  <h4>Login Details</h4>
                  <hr></hr>
                  <Row>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Password *</Form.Label>
                        <Form.Control type="password" name="profilePassword" value={values.profilePassword} autoComplete='new-password' onChange={change.bind(null, "profilePassword")}  isInvalid={touched.profilePassword && !!errors.profilePassword} isValid={touched.profilePassword && !errors.profilePassword} />
                        <Form.Control.Feedback type="invalid">{errors.profilePassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>Confirm Password *</Form.Label>
                        <Form.Control type="password" name="profilePasswordConfirmation" value={values.profilePasswordConfirmation} onChange={change.bind(null, "profilePasswordConfirmation")} isInvalid={touched.profilePasswordConfirmation && !!errors.profilePasswordConfirmation} isValid={touched.profilePasswordConfirmation && !errors.profilePasswordConfirmation} autoComplete='new-password' />
                        <Form.Control.Feedback type="invalid">{errors.profilePasswordConfirmation}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group >
                        <Form.Label>PDF password</Form.Label>
                        <Form.Control type="text" name="pdfpassword" value={values.pdfpassword} onChange={change.bind(null, "pdfpassword")}  autoComplete='new-password' isInvalid={touched.pdfpassword && !!errors.pdfpassword} isValid={touched.pdfpassword && !errors.pdfpassword}/>
                        <Form.Control.Feedback type="invalid">{errors.pdfpassword}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h4>Profile Photo</h4>
                  <hr></hr>
                  <Row>
                  <Col md="10">
                    <Image src={previewFile} style={{ maxHeight: 200, marginBottom: 30 }} />
                  </Col>
                </Row>
                <Row>
                  <Col md="10">
                    <Form.Group>
                      <Form.Label>Upload New Profile Photo</Form.Label>
                      <Form.Control type="file" name="newProfilePicture" onChange={(event) => makePreview(event.target.files[0])} isInvalid={touched.newProfilePicture && !!errors.newProfilePicture} isValid={touched.newProfilePicture && !errors.newProfilePicture} />
                      {(JSON.stringify(errors).length > 2 || currentprofilepicturetouched) && currentprofilepicture.name === '' ? (<p className='error-help'>Please provide a profile picture.</p>) : ''}
                    </Form.Group>
                  </Col>
                </Row>
                <h4>License Agreement</h4>
                <hr></hr>
                <Row>
                  <Col md="10">
                    <Form.Group>
                      <Checkbox aria-label='Agreement Acceptance' name="agreementAcceptance" value={values.agreementAcceptance} onChange={change.bind(null, "agreementAcceptance")} style={{ paddingTop: 6, paddingLeft: 0 }} />
                      {psychologistInvite.specialisttype === 'nthqldphn' ?
                        <>
                          I agree to the <a href="https://files.ruralhealthconnect.com.au/fileStoreRHC/RuralHealthConnect-NQPHNServicesAgreement.pdf" target="_blank" rel="noopener noreferrer" className="cell-Link">Rural Health Connect Licence Agreement</a>
                        </>
                      :
                        <>
                        I agree to the <a href="https://files.ruralhealthconnect.com.au/fileStoreRHC/RuralHealthConnect-ServicesAgreement-2022-02-14.pdf" target="_blank" rel="noopener noreferrer" className="cell-Link">Rural Health Connect Licence Agreement</a>
                        </>
                      }
                      <div className="error-help">{errors.agreementAcceptance}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item md={3} xs={6}> 
                      
                      </Grid>
                      <Grid item md={1} xs={6}>
                        <Button variant="primary" type="submit" >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
               </Paper>
            </form>
          )
          }}
        </Formik>
            </div>
          </div>
        </div>
      </div>
      : 'Loading...' }

      <Modal show={showRegistrationView} onHide={handleRegistrationViewClose} dialogClassName="providerRegistrationView-modal">
        <Modal.Header closeButton className="providerRegistrationView-modal">
          <Modal.Title>Client Registration View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="providerRegistrationView-modal">
          <Grid item md={12} style={{ height: 710, width: "100%", overflowY: "scroll", paddingLeft: 20, backgroundColor: "rgb(255,255,255)" }}>
              <Masonry
                breakpointCols={1}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                <Card className={classes.root} variant="outlined" >
                  <CardMedia
                      className={classes.media}
                      image={previewFile}
                      title="Provider"
                  />
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item sm={12}>
                        <Typography variant="h5" component="h3">
                          {providerRegistrationView.firstname} {providerRegistrationView.surname}  (Next available: Next Available)
                        </Typography>
                      </Grid>
                    </Grid>
                    {(providerRegistrationView.providertype !== 'GP' && providerRegistrationView.providertype !== 'Provisional') ?
                      <>
                        {currentPaymentType === 'Full Fee' ?
                          <>
                            <Grid container style={{ marginTop: 10 }}>
                              <Grid item sm={6} >
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  Out of Pocket Cost
                                </Typography>
                              </Grid>
                              <Grid item sm={6} style={{ textAlign: "right" }}>
                                <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                  {'$' + providerRegistrationView.calc_standardFeeOutOfPocket }
                                </Typography>
                              </Grid>
                              <Grid item sm={12}>
                                <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                  You pay ${providerRegistrationView.calc_hourlyrate} on the day 
                                </Typography>
                                <Typography>
                                  Receive back {'$' + providerRegistrationView.calc_medicareRebate} from Medicare
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                          :
                          null
                        }
                        {currentPaymentType === 'Reduced Gap' ?
                          <>
                            {providerRegistrationView.isReducedFee === 'Y' ? 
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top"}}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {'$' + providerRegistrationView.calc_reducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { providerRegistrationView.reducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${providerRegistrationView.calc_reducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {'$' + providerRegistrationView.calc_medicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                  { providerRegistrationView.reducedFee !== '0' ?
                                    <Grid item sm={12}>
                                      <Typography variant="subtitle2" style={{ fontWeight:300, fontStyle: 'italic' }}>
                                        Note this provider offers some flexibility, depending on client circumstances
                                      </Typography>
                                    </Grid>
                                  :
                                    null
                                  }
                                </Grid>
                              </>
                            :
                              <>
                                <Grid container style={{ marginTop: 10 }}>
                                  <Grid item sm={6} >
                                    <Typography variant="subtitle1" style={{ fontWeight:600, textAlign: "top" }}>
                                      Out of Pocket Cost
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={6} style={{ textAlign: "right" }}>
                                    <Typography variant="subtitle1" style={{ fontWeight:600 }}>
                                      {'$' + providerRegistrationView.calc_reducedFeeOutOfPocket }
                                    </Typography>
                                  </Grid>
                                  <Grid item sm={12} >
                                    { providerRegistrationView.reducedFee === '0' ?
                                      <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                        You pay $0 on the day
                                      </Typography>
                                    :
                                      <>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          You pay ${providerRegistrationView.calc_reducedFee} on the day
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontWeight:300 }}>
                                          Receive back {'$' + providerRegistrationView.calc_medicareRebate} from Medicare
                                        </Typography>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </>
                            }
                          </>
                        :
                          null
                        } 
                        <Grid container style={{ marginBottom: 15, marginTop: 10 }}>
                          {providerRegistrationView.areasOfInterest_focus.map((row) => {return (<Chip size="small" key={`AOI${row.id}`} variant="outlined" label={row.issue} style={{ margin: 2 }} />)})}
                        </Grid>
                      </>
                    :
                      null
                    }
                    <Typography className={classes.pos} color="textSecondary">
                      <br />
                    </Typography>
                    <Grid container>
                      {currentPaymentType === 'Supervision' ?
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={providerRegistrationView.supervisorBio.substring(0,700)} />
                            {providerRegistrationView.supervisorBio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(providerRegistrationView.fullname, providerRegistrationView.supervisorBio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      :
                        <>
                          <Typography variant="body2" component="p">
                            <Interweave content={providerRegistrationView.bio.substring(0,700)} />
                            {providerRegistrationView.bio.length > 700 ?
                              <>
                                ...
                                <Badge variant="secondary" onClick={()=>moreProviderBio(providerRegistrationView.fullname, providerRegistrationView.bio)} style={{ marginRight: 5, cursor: 'pointer' }}>
                                  more
                                </Badge>
                              </>
                            :
                              null
                            }
                          </Typography>
                        </>
                      }
                    </Grid>
                  </CardContent>
                </Card>
              </Masonry>
            </Grid>

        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={handleRegistrationViewClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showProviderBio} onHide={()=>setShowProviderBio(false)} dialogClassName="invite-modal" >
        <Modal.Header closeButton className="invite-modal">
          <Modal.Title>{providerBioFullName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="invite-modal" style={{ backgroundColor: 'rgb(245,245,245)' }}>
          <Grid container >
            <Typography variant="body2" component="p">
              <Interweave content={providerBioBio} />
            </Typography>
          </Grid>
        </Modal.Body>
        <Modal.Footer className="invite-modal">
          <Button variant="secondary" onClick={() => setShowProviderBio(false)} className="lightButtonOutlinedLarge">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}
}

function RegistrationConfirmation(props) {

  // change width of sidebar for screensize
  const isMobile = useMediaQuery('(max-width: 760px)');
  return (

    <>
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div
            className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/rhc-farm.jpg")})`
            }}
          >
            <div className="kt-grid__item">
              <Link to="/auth/login" className="kt-login__logo">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo-3.png")}
                  style={{ marginLeft: -10 }}
                />
              </Link>
            </div>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid ">
              <div className="kt-grid__item kt-grid__item--middle">
                <h3 className="kt-login__title" style={{ marginTop: isMobile ? 0 : 80 }}>Rural Health Connect</h3>
                <h4 className="kt-login__subtitle" style={{ marginTop: 20 }}>
                  Welcome to the Provider Registration page.
                </h4>
              </div>
            </div>
            <div className="kt-grid__item">
              <div className="kt-login__info">
                <div className="kt-login__copyright">
                  &copy; 2024 Rural Health Connect
                </div>
              </div>
            </div>
          </div>

          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper login-bg">
            
          <Paper style={{ backgroundColor: "rgb(245,245,245)", padding: 20 }} >
            <h3>
              {props.title}
            </h3>
            <p style={{ marginTop: 30 }}>
              {props.message}
            </p>
            {props.enableLogin && 
              <>
                <p>
                  You may now login to the portal to make and manage your bookings and your profile.
                </p>
                <p style={{ marginTop: 20 }}>
                <Link to="/auth/login" className="kt-login__logo">            
                  <Button>
                    Portal Login
                  </Button>
                </Link>
                </p>
              </>}
          </Paper>
          </div>
          </div>
        </div>
      </div>
    </>

  );

}