import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Form, InputGroup } from "react-bootstrap";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { Grid, Paper, MenuItem, FormControl, Select, InputLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));



export default function SearchClientData(props) {

  const dispatch = useDispatch()
  const classes = useStyles();
  const filters = useSelector(state => state.admin.Filters)
  const searchResults = useSelector(state => state.admin.SearchResults)
  const areasOfInterestReference = useSelector(state =>  state.reference.AreasOfInterest)
  useEffect( () => { dispatch(Admin.actions.searchClientData() )}, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('Psychologist'))  }, [dispatch] )


  const searchSearchResults = (searchResult) => {
    if (searchResult.aoi && searchResult.aoi.length > 0) 
    {
      var aoiArray = searchResult.aoi.split(',')
    }
    else
    {
      var aoiArray = null
    }
    //
    if( true

        && (!filters.searchClientDataType || filters.searchClientDataType === 'All' || (filters.searchClientDataType && searchResult.entity === filters.searchClientDataType))
        && (
            !filters.searchClientData 
            || filters.searchClientData.length === 0 
            || (searchResult.searchString.length > 0 &&  searchResult.searchString.match(/^[a-zA-Z0-9&@ ]*$/) !== null && filters.searchClientData.split(" ").every(word => searchResult.searchString.toLowerCase().search(word.toLowerCase()) !== -1))
          )
        && (
          filters.clientsAOI === 'All' 
          || (aoiArray && aoiArray.length > 0 && aoiArray.some(issue => issue === filters.clientsAOI))
        )
      )
    {
      return searchResult
    }  
  }

  const filteredSearchResults = searchResults.filter(searchResult => searchSearchResults(searchResult))
  
  const linkPage = (uri,id,entity) => {
    //
    if (entity === 'Clients')
    {
      dispatch(Admin.actions.setFilters({ filterName: 'clientsListLoadClientID', filterValue: id }))  
      props.history.push(uri)                
    }
    if (entity === 'ClientInvites')
    {
      // Client Invites are an issue as the default display only displays the last month so a random call to particular id will lead to problems thus the creation date is stored in the URL 
      // console.log(uri)
      // console.log(new Date(uri))
      dispatch(Admin.actions.initialiseInvites())
      dispatch(Admin.actions.setFilters({ filterName: 'clientInviteStartDate', filterValue: new Date(uri) }))
      dispatch(Admin.actions.setFilters({ filterName: 'invitesListLoadInviteID', filterValue: id }))  
      props.history.push('clientinvites')                
    }
    // Note don't redirect to a particular id in faxes as faxes are not an entity (i.e. modal) 
    if (entity === 'Faxes')
    {
      props.history.push('faxes')                
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'clientName', headerName: 'Name', width: 200 },  
    { field: 'searchString', headerName: 'Matching Field', width: 300 },
    { field: 'entity', headerName: 'Area', width: 120 },
    { field: 'uri', headerName: 'Link', width: 120,
      renderCell: (params) => (
        <strong>
          <div onClick={() => linkPage(params.row.uri,params.row.id,params.row.entity)} className="cell-Link" >
            View 
          </div>
        </strong>
      ),  
    }
  ];


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Global Client Data Search</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container style={{ padding: 10 }}>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control 
                  type="text"
                  value={filters.searchClientData}
                  onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'searchClientData', filterValue: event.target.value }))}
                  autoComplete='OFF'
                  style={{ maxWidth: 170, height: 35, backgroundColor: filters.clientsTerm.length > 0 ? 'rgb(250 239 195)' : 'white' }}
                />
              <InputGroup.Append onClick={() => dispatch(Admin.actions.setFilters({ filterName: 'searchClientData', filterValue: '' }))}>
                <InputGroup.Text>
                  <ClearIcon fontSize="small" style={{ color: filters.clientsTerm.length > 0 ? '#222222' : 'silver', marginLeft: -3 }} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Grid>
          <Grid item style={{ paddingLeft: 2, paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsStatus-label" className={classes.label}>Area</InputLabel>
              <Select
                labelId="clientsStatus-label"
                id="searchClientDataType"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                value={filters.searchClientDataType}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'searchClientDataType', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                <MenuItem value='Clients'>Clients</MenuItem>
                <MenuItem value='ClientInvites'>Client Invites</MenuItem>
                <MenuItem value='Faxes'>Faxes</MenuItem>                
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="clientsAOI-label" className={classes.label}>Area of Interest</InputLabel>
              <Select
                labelId="clientsAOI-label"
                id="clientsAOI"
                margin="dense"
                style={{ minWidth: 120, backgroundColor: "#ffffff" }}
                value={filters.clientsAOI}
                onChange={(event) => dispatch(Admin.actions.setFilters({ filterName: 'clientsAOI', filterValue: event.target.value }))}
              >
                <MenuItem value='All'>All</MenuItem>
                {areasOfInterestReference.map(row => {
                  return <MenuItem key={`key-${row.id}`} value={row.id}>{row.issue}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>


      <Paper style={{ height: 700 }}>
        <DataGridPro rows={filteredSearchResults} columns={columns} checkboxSelection={false} rowHeight={40} />
      </Paper>

    </>
  )
}
