import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import * as Admin from "../../../store/ducks/admin.duck";
import * as Reference from "../../../store/ducks/reference.duck";
import { Grid, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

export default function Programs() {

  const [searchTerm, setSearchTerm] = useState('')
  const programs = useSelector(state => state.admin.Programs)
  const areasOfInterest = useSelector(state => state.reference.AreasOfInterest)
  const aoi_focus = areasOfInterest.filter(aoi => aoi.focusAreaIndex !== null && aoi.providertype === 'Psychologist')
  const filteredPrograms = programs.filter(programs => programs.name.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )
  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchPrograms()) }, [dispatch] )
  useEffect( () => { dispatch(Reference.actions.fetchAreasOfInterest('ALL')) }, [dispatch] )

  var [show, setShow] = useState(false);
  var [currentProgramID,setCurrentProgramID] = useState();
  var [currentName,setCurrentName] = useState();
  var [currentDescription,setCurrentDescription] = useState();
  var [currentIdentifier,setCurrentIdentifier] = useState();
  var [currentStatus,setCurrentStatus] = useState();
  var [currentAreaOfInterest,setCurrentAreaOfInterest] = useState()
  var [currentAvailabilityType,setCurrentAvailabilityType] = useState()

  const loadProgramDetail = (thisProgram) => {
    setCurrentProgramID(thisProgram.id)
    setCurrentName(thisProgram.name)
    setCurrentDescription(thisProgram.description)
    setCurrentIdentifier(thisProgram.identifier)
    setCurrentStatus(thisProgram.status)
    setCurrentAvailabilityType(thisProgram.availabilitytype)
    setCurrentAreaOfInterest(aoi_focus.find(aoi => aoi.id === thisProgram.aoi.toString()))
    setShow(true)
  }

  const createNewProgram = (thisProgram) => {
    setCurrentProgramID(0)
    setCurrentName()
    setCurrentDescription()
    setCurrentIdentifier()
    setCurrentStatus('Active')
    setCurrentAreaOfInterest()
    setCurrentAvailabilityType()
    setShow(true)
  }

  function putProgram() {
    // Create payload to send to API 
    var payload = {
        id : currentProgramID,
        name : currentName,
        description : currentDescription,
        identifier : currentIdentifier,
        status : currentStatus,
        availabilitytype : currentAvailabilityType,
        areaofinterest : currentAreaOfInterest && currentAreaOfInterest.id
    }
    //
    process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.putProgram(payload));
    setShow(false);
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'name', headerName: 'Name', width: 300,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => loadProgramDetail(programs.find(program => program.id === params.row.id))} className="cell-Link" >
              {params.value}
            </div>
        </strong>
      ),  
    },
    { field: 'identifier', headerName: 'URL Tag', width: 300 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'none', headerName: 'Actions', width: 120,
      renderCell: (params) => (
        <>
          <a href={`/clientregistration/${params.row.identifier}`} target="RHC">
            <Button variant="secondary" size="sm" style={{ color: "black", fontWeight: 500, border: 0, background: "#FFFACD", marginRight: 14 }} >
              Test Link
            </Button>
          </a>
        </>
      ) 
    }
  ];

  
  function handleClose() {
    setShow(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Program Maintenance</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => createNewProgram()}>
              Create Program
            </Button>
          </Grid>
        </Grid>
      </Paper>


      { programs ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredPrograms} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} />
        </Paper>
      : ('None')
      }

      <Modal show={show} onHide={handleClose} dialogClassName="contracts-modal" >
        <Modal.Header closeButton className="contracts-modal">
          <Modal.Title>Program Maintenance </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contracts-modal">
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="Name" defaultValue={currentName} onBlur={(event) => setCurrentName(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} name="Description" defaultValue={currentDescription} onBlur={(event) => setCurrentDescription(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Form.Group >
                <Form.Label>Identifier</Form.Label>
                <Form.Control type="text" name="Identifier" defaultValue={currentIdentifier} onBlur={(event) => setCurrentIdentifier(event.target.value)} autoComplete='new-password' />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Form.Group >
                <Form.Label>Linked Availabilty Type</Form.Label>
                <Form.Control as="select" defaultValue={currentAvailabilityType} onBlur={(event) => setCurrentAvailabilityType(event.target.value)}>
                  <option value='Any'>Any</option>
                  <option value= 'Existing'>Existing</option>
                  <option value= 'NDISContractEAP'>NDIS/Contract/EAP</option>
                  <option value= 'NDISContractEAPNew'>New Client or NDIS/Contract/EAP</option>
                  <option value= 'NDISContractEAPExisting'>Existing Client or NDIS/Contract/EAP</option>
                  <option value="PerinatalGriefLoss">Perinatal Grief & Loss</option>
                </Form.Control>
              </Form.Group>
            </Col>     
          </Row>
          {currentProgramID !== 0 ?
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Label>Please choose Area of Interest to link this program to</Form.Label>
                  <Autocomplete
                    id="aoi"
                    variant="outlined"
                    options={aoi_focus}
                    getOptionLabel={(option) => option.issue }
                    value={currentAreaOfInterest}
                    onChange={(event, newValue) => setCurrentAreaOfInterest(newValue)}
                    style={{ fullWidth: true, display: 'flex', wrap: 'nowrap', backgroundColor: "white", }}
                    renderInput={(params) => 
                      <TextField 
                        {...params} 
                        type="text"
                        variant="outlined"
                        margin="dense"
                        name="AOISelector"
                        style={{backgroundColor: "", margin: 0, padding: 0}}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password'}}
                      />}
                  />
                </Form.Group>
              </Col>
            </Row>          
          :
            null
          }
          <Row>
            <Col md="3">
              <Form.Group >
                <Form.Label>Status</Form.Label>
                <Form.Control as="select" defaultValue={currentStatus} onBlur={(event) => setCurrentStatus(event.target.value)}>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                </Form.Control>
              </Form.Group>
            </Col>     
          </Row>
          {currentProgramID !== 0 ?
            <Row>
              <Col md="12">
                <Form.Label>Link</Form.Label>
                <Form.Control as="textarea" rows={2} type="text" name="contracttoken" defaultValue={`https://portal.ruralhealthconnect/clientregistration/${currentIdentifier}`} />
              </Col>
            </Row>
          :
            null
          }
        </Modal.Body>
        <Modal.Footer className="contracts-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putProgram}>
            Save
          </Button>
        </Modal.Footer>

      </Modal>


      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
