import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ReferralsView from "../../../components/ReferralsView";
import * as Admin from "../../../store/ducks/admin.duck";

export default function ReferralsPsychologist() {

  const dispatch = useDispatch()
  
  useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )
  const registered = useSelector(state =>  state.admin.RegisteredPsychologists[0]);
  
  if(registered) {
    return (
      <>
        <h4>Referrals</h4>
        <ReferralsView 
          viewerFullName={registered.fullname}
          type="psychologist"
          id={registered.id}
        />
      </>
    )
  }
  else
  {
    return <div>Loading...</div>
  }

}
