import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { BookingStatusDropDownCell } from '../../../components/BookingStatusDropDownCell';
import { BookingServiceDropDownCell } from '../../../components/BookingServiceDropDownCell';

import * as Admin from "../../../store/ducks/admin.duck";
// import * as Reference from "../../../store/ducks/reference.duck";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';

// In the world of confusion - please note the following invoicing process 
//
// Today we are billing per booking using the Services Table to define the prices - each booking has a billable type that is set (or defaulted) when a booking is made
//
// Process 
// 
// GatherBillable.js  - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to email providers a list of those to be billed at the end of the month if still eliglible. 
//                    - Can toogle bookings to cancelled/noshow as needed 
// Invoices.js        - Lists providers (including client list) to be billed as at the end of the previous month (this may need to be configurable). 
//                    - Only list bookings that have a booking charged field of null and a booking of status Active
//                    - Gives user option to create Invoices in Xero for these providers. 
//                    - This essentially a copy of GatherBillable though expect this will change at client acceptance occurs. 
// InvoiceLog.js      - This is a searchable log of invoices/clients/providers 
// 
// PlatformPsychologist.js - Lists unbilled boookings 
//                         - Only list bookings that have a booking charged field of null and a booking of status Active
//                         - Can toogle bookings to cancelled/noshow as needed 
//

 

export default function PlatformInvoicesPsychologist() {

  const dispatch = useDispatch()
  useEffect( () => { dispatch(Admin.actions.fetchSingleBillable()) }, [dispatch] )
  const billableIntents = useSelector(state => state.admin.BillableSingleIntents);
  const editField = "inEdit";

  // Note these lines are temporary whilst the page is limited to the test psychologist - they can be removed when Xero goes live
  // const registered = useSelector(state => state.admin.RegisteredPsychologists[0]);
  // useEffect( () => { dispatch(Admin.actions.fetchRegistered()) }, [dispatch] )

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [{
      field: 'clientName',
      dir: 'asc'
    }],
  });

  function getlastDayofMonth()
  {
    var today = new Date();
    // Determine last day of the month 
    today.setDate(1); // going to 1st of the month
    today.setHours(-1); // going to last hour before this date even started.
    // All this code just to returnm dd/mm/yyyy - how primitve 
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if (dd < 10) 
    {
        dd = '0' + dd;
    }
    if (mm < 10) 
    {
      mm = '0' + mm;
    }
    var newToday = dd + '/' + mm + '/' + yyyy;
    return(newToday);
  }

  const MyCommandCell = (props) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    return inEdit ? (
      <td className="k-command-cell">
        <button className="k-primary k-button k-grid-save-command" onClick={() => props.update(dataItem)}>
          Update
        </button>
        <button className="k-button k-grid-cancel-command" onClick={() => props.cancel(dataItem)}>
          Cancel
        </button>
      </td>
    ) : (
      <td className="k-command-cell">
        <button className="k-primary k-button k-grid-edit-command" onClick={() => props.edit(dataItem)}>
          Edit
        </button>
      </td>
    );
  };

  const [dataResult, setDataResult] = useState(process(billableIntents, dataState)); 
  const [prevBillableIntents, setPrevBillableIntents] = useState();
  const [currentBillableIntents, setCurrentBillableIntents] = useState();  


  useEffect( () => {
    if ((JSON.stringify(billableIntents) !== JSON.stringify(prevBillableIntents)) && billableIntents.length > 0)
    {
      setPrevBillableIntents(billableIntents)
      setCurrentBillableIntents(billableIntents)
      setDataResult(process(billableIntents, dataState))
    }
  }, [prevBillableIntents, currentBillableIntents, billableIntents, dataResult, dataState])

  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  
  const update = (dataItem) => {
    dataItem.inEdit = false;
    // console.log('Update');
    // console.log(dataItem.status);
    dispatch(Admin.actions.putAppointment( 
      { id: dataItem.bookingid, 
        status: dataItem.status, 
        paymentType: dataItem.service,
        email: false,
        refreshPayload: { key: 'BillableSingle', id: dataItem.bookingid }
      }));
  };

  const cancel = (dataItem) => {
    const originalItem = billableIntents.find((p) => p.bookingid === dataItem.bookingid);
    const newData = billableIntents.map((item) => item.bookingid === originalItem.bookingid ? originalItem : item);
    setDataResult(process(newData,dataState));
  };

  const enterEdit = (dataItem) => {
    let newData = billableIntents.map((item) =>
    item.bookingid === dataItem.bookingid ? { ...item, inEdit: true } : item
    );
    setDataResult(process(newData,dataState));
  };

  const itemChange = event => {
    const field = event.field || '';
    let newData = billableIntents.map(item => item.bookingid === event.dataItem.bookingid ? { ...item,[field]: event.value} : item);

    let newerData = newData.map((item) => item.bookingid === event.dataItem.bookingid ? { ...item, inEdit: true } : item);

    setDataResult(process(newerData,dataState));
  };

  const dataStateChange = event => {
    setDataResult(process(billableIntents, event.dataState));
    setDataState(event.dataState);
  };

  return(
    <>
      <div>
        <h4>Bookings to be Invoiced (for month ending {getlastDayofMonth()})</h4>
        <Grid
          style={{height: "700px",}}
          sortable={true}
          reorderable={true}
          editField={editField}
          onItemChange={itemChange}
          pageable={{buttonCount: 4,pageSizes: true,}}
          data={dataResult} {...dataState} 
          dataItemKey={"bookingid"}
          onDataStateChange={dataStateChange}
        >
            <GridColumn field="clientName" title="Client Name" width="250px" editable={false} />
            <GridColumn field="bookingDate" title="Booking Date" width="200px" editable={false}  />
            <GridColumn field="service" cell={BookingServiceDropDownCell} title="Service" width="160px" editable={false}  />
            <GridColumn field="cost" filter={"numeric"} title="Cost (exGST)" width="120px" editable={false}  />
            <GridColumn field="status" cell={BookingStatusDropDownCell} title="Status" width="100px" />
            <GridColumn title="Actions" cell={CommandCell} width="240px" />
        </Grid>
      </div>
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />      
    </>
  )
}

