import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@material-ui/data-grid';
import { Button, Modal, Form, Row, Col, InputGroup } from "react-bootstrap";
import SearchIcon from '@material-ui/icons/Search';
import * as Admin from "../../../store/ducks/admin.duck";
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Grid, makeStyles } from '@material-ui/core';
import { ToastContainer, Flip } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../../_metronic/_assets/css/app.css';
import '../../../../_metronic/_assets/css/app2.css';


const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
	formControl: {
	  margin: 0, //theme.spacing(1),
	  minWidth: 120,
	},
	formControlWide: {
	  margin: 0, //theme.spacing(1),
	  minWidth: '100%',
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	label: {
	  backgroundColor: '#fff',
	  paddingLeft: 3,
	  paddingRight: 3,
	}
  }));

export default function Opportunity() {

  const [searchTerm, setSearchTerm] = useState('')
  const opportunities = useSelector(state => state.admin.Opportunities)
  const filteredOpportunities = opportunities.filter(opportunity => opportunity.description.toLowerCase().search(searchTerm.toLowerCase()) > -1 || searchTerm.length === 0 )
  const dispatch = useDispatch()
  const classes = useStyles()
  useEffect( () => { dispatch(Admin.actions.fetchOpportunities()) }, [dispatch] )

  const [show, setShow] = useState(false)
  const [currentOpportunityID, setcurrentOpportunityID] = useState()
  const [currentOpportunityDescription,setcurrentOpportunityDescription] = useState()
  const [currentOpportunityColour,setcurrentOpportunityColour] = useState()
  const [currentOpportunityStatus,setcurrentOpportunityStatus] = useState()
  const [currentOpportunityInterested, setcurrentOpportunityInterested] = useState()

  const loadOpportunityDetail = (thisOpportunity) => {
    setcurrentOpportunityID(thisOpportunity.id)
    setcurrentOpportunityDescription(thisOpportunity.description)
    setcurrentOpportunityStatus(thisOpportunity.status)
    setcurrentOpportunityColour(thisOpportunity.colour)
    setcurrentOpportunityInterested(thisOpportunity.opportunityinterested)
    setShow(true)
  }

  const createNewOpportunity = (thisOpportunity) => {
    setcurrentOpportunityID(0)
    setcurrentOpportunityDescription('')
    setcurrentOpportunityStatus('Active')
    setcurrentOpportunityColour('#00B3BE')
    setcurrentOpportunityInterested()
    setShow(true)
  }

  function putOpportunity() {
    // Create payload to send to API 
    var payload = {
      id : currentOpportunityID,
      description : currentOpportunityDescription,
      status : currentOpportunityStatus,
      colour : currentOpportunityColour
    }
    //
    // process.env.REACT_APP_DEBUG && console.log(payload);
    process.env.REACT_APP_DEBUG && console.log ('Saving data');
    // Save update 
    dispatch(Admin.actions.putOpportunity(payload));
    setShow(false);
  }

  function resetInterestedProviders()
  {
    dispatch(Admin.actions.putResetInterestedProviders({ clientID: currentOpportunityID, type: 'Admin' }))
    setShow(false)
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100,  hide: true },
    { field: 'description', headerName: 'Description', width: 300,  
      renderCell: (params) => (
        <strong>
            <div onClick={() => loadOpportunityDetail(opportunities.find(opportunity => opportunity.id === params.row.id))} className="cell-Link" >
              {params.value.substring(0,100)}
            </div>
        </strong>
      ),  
    },
    { field: 'created', headerName: 'Created On', width: 155, sortable : true, renderCell: (params) => (format(new Date(params.row.created), "dd-MM-yyyy HH:mm:ss a")), cellClassName: 'grid-mono-font' },
    { field: 'status', headerName: 'Status', width: 120 },
  ];

  
  function handleClose() {
    setShow(false);
  }  

  const handleSearch = (event) => {
    const searchTerm = event.target.value    
    setSearchTerm(searchTerm)
  }


  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item><h4 style={{ marginBottom: 12 }}>Opportunity Maintenance</h4></Grid>
      </Grid>

      <Paper> 
        <Grid container justifyContent="space-between" style={{ padding: 10 }}>
          <Grid item style={{ marginTop: 2, marginLeft: 10 }}>
            <InputGroup size="sm" >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <SearchIcon fontSize="small" style={{ marginLeft: -4 }} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control                  
                  type="text"
                  value={searchTerm}
                  onChange={(event) => handleSearch(event)}
                  autoComplete='OFF'
                />
              </InputGroup>
          </Grid>
          <Grid item style={{ marginLeft: 20, marginRight: 10 }}>
            <Button variant="primary" size="sm" onClick={() => createNewOpportunity()}>
              Create Opportunity
            </Button>
          </Grid>
        </Grid>
      </Paper>


      { opportunities ? 
        <Paper style={{ height: 700 }}>
          <DataGrid rows={filteredOpportunities} columns={columns} pageSize={10} checkboxSelection={false} rowHeight={40} />
        </Paper>
      : ('None')
      }


      <Modal show={show} onHide={handleClose} dialogClassName="editprofile-modal">
        <Modal.Header closeButton className="editprofile-modal" style={{ paddingBottom: 0 }}>
          <Modal.Title>
            {currentOpportunityID === 0 ? 'Create Opportunity' : 'Update Opportunity'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="editprofile-modal">
                <Row>
                  <Col md="12">
                    <Form.Group >
                      <Form.Label>Opportunity Details (Max 500 characters)</Form.Label>
                      <Form.Control as="textarea" rows={4} type="text" name="opportunityDescription" defaultValue={currentOpportunityDescription} onBlur={(event) => setcurrentOpportunityDescription(event.target.value)} autoComplete='new-password' />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Colour</Form.Label>
                      <Form.Control as="select" defaultValue={currentOpportunityColour} onBlur={(event) => setcurrentOpportunityColour(event.target.value)}>
                        <option value="#00B3BE">Aqua</option>
                        <option value="#A33383">Magenta</option>
                        <option value="#D2B12B">Yellow</option>
                        <option value="#93A33A">Green</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group >
                      <Form.Label>Status</Form.Label>
                      <Form.Control as="select" defaultValue={currentOpportunityStatus} onBlur={(event) => setcurrentOpportunityStatus(event.target.value)}>
                        <option value="Active">Active</option>
                        <option value="Closed">Closed</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" style={{ paddingTop: 26}}>
                    <Button variant="primary" onClick={() => resetInterestedProviders()}>
                      Reset Interested Providers
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="9">
                    <Form.Label>Interested Providers</Form.Label>
                    <Paper>
                      <TableContainer style={{ backgroundColor: '#FFF', height: 340, width: "100%", overflowY: "scroll" }}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Provider</TableCell>
                              <TableCell>Expressed Interest</TableCell>
                              <TableCell>Relevant Info</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentOpportunityInterested ? currentOpportunityInterested.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell>
                                  {row.providerName}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(row.createdOn), "dd-MM-yyyy HH:mm")}
                                </TableCell>
                                <TableCell>
                                  {row.relevantInfo}
                                </TableCell>
                              </TableRow>
                            ))
                            : 
                              null
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Col>
                </Row>
        </Modal.Body>        
        <Modal.Footer className="editprofile-modal">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={putOpportunity}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>




      <ToastContainer
        position="bottom-right"
        transition={Flip}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />     
    </>
  )
}
